import { SvgIcon, SvgIconProps } from '@mui/material'

export const ArrowDoubleRight = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.14645 13.8536C6.32001 14.0271 6.58944 14.0464 6.78431 
        13.9114L6.85355 13.8536L13.3536 7.35355C13.5271 7.17999 13.5464 
        6.91056 13.4114 6.71569L13.3536 6.64645L6.85355 0.146447C6.65829
         -0.0488155 6.34171 -0.0488155 6.14645 0.146447C5.97288 0.320013
          5.9536 0.589437 6.08859 0.784306L6.14645 0.853553L12.2929 
          7L6.14645 13.1464C5.95118 13.3417 5.95118 13.6583 6.14645 
          13.8536ZM0.146447 13.8536C0.320013 14.0271 0.589437 14.0464 
          0.784306 13.9114L0.853553 13.8536L7.35355 7.35355C7.52712 
          7.17999 7.5464 6.91056 7.41141 6.71569L7.35355 6.64645L0.853553 
          0.146447C0.658291 -0.0488155 0.341709 -0.0488155 0.146447 
          0.146447C-0.0271197 0.320013 -0.0464049 0.589437 0.0885912 
          0.784306L0.146447 0.853553L6.29289 7L0.146447 13.1464C-0.0488155 
          13.3417 -0.0488155 13.6583 0.146447 13.8536Z'
      />
    </SvgIcon>
  )
}
