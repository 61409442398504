import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import {
  DEMO_USER,
  DEMO_USER_KEY,
  IEmployee,
  IEmployeeResponse,
  IGenericEmployee,
  ROLE_NAME,
  SUPER_ADMIN_KEY,
} from 'features/manageAdmins/constants'
import baseApi from 'services/api/baseApi'

import { endpoints } from 'constants/endpoints'
import { IEmployeeSelected } from '../constants'
import { ManageAdminsSlice } from '../../manageAdmins/constants'
import { geoApi } from 'services/api/geoApi'
import { getUniqueById } from 'utils/getUniqueById'

const getUniqueEmployees = (employeesResponse: IEmployeeResponse[][]) => {
  const uniqueEmployees = employeesResponse.flat().reduce((employees, employee) => {
    if (employees[employee.data.id]) {
      const roles = employees[employee.data.id].roles!
      roles.push(...employee.data.roles!.filter((role) => !roles.includes(role)))
    } else {
      employees[employee.data.id] = { ...employee.data, allowedPermissions: employee.metadata.allowedActions }
    }
    return employees
  }, {} as { [key: string]: IEmployee })
  return Object.values(uniqueEmployees)
}

export const getEmployeesByJurisdiction = createAsyncThunk<
  IGenericEmployee[],
  { jurisdictionId: string; searchTerm: string },
  { rejectValue: AxiosError }
>('employees/getEmployeesByJurisdiction', async ({ jurisdictionId, searchTerm }, { rejectWithValue }) => {
  try {
    const jurCode = geoApi.jurMapping[jurisdictionId].code
    const url = endpoints.getEmployeesWithJurisdictionId
      .replace(':jurisdictionId', jurisdictionId)
      .replace(':searchTerm', searchTerm)
    return await geoApi.withJurisdictionCode(jurCode).get<IGenericEmployee[]>(url)
  } catch (err) {
    return rejectWithValue(err as AxiosError)
  }
})

export const getEmployeesByProductGroupIdAndJurisdictionId = createAsyncThunk<
  IGenericEmployee[],
  { productGroupId: string; jurisdictionId: string; searchTerm: string },
  { rejectValue: AxiosError }
>(
  'employees/getEmployeesByProductGroupIdAndJurisdictionId',
  async ({ productGroupId, jurisdictionId, searchTerm }, { rejectWithValue, getState }) => {
    try {
      const jurCode = geoApi.jurMapping[jurisdictionId].code
      const state = getState() as { manageAdmins: ManageAdminsSlice }
      const { roleSelected } = state.manageAdmins
      const endpointKey =
        roleSelected === DEMO_USER
          ? 'getDemoUsersWithProductGroupIdAndJurisdictionId'
          : 'getEmployeesWithProductGroupIdAndJurisdictionId'
      const url = endpoints[endpointKey]
        .replace(':productGroupId', productGroupId)
        .replace(':jurisdictionId', jurisdictionId)
        .replace(':searchTerm', searchTerm)
      return await geoApi.withJurisdictionCode(jurCode).get<IGenericEmployee[]>(url)
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const getEmployeesByName = createAsyncThunk<IGenericEmployee[], string, { rejectValue: AxiosError }>(
  'employees/getEmployeesByName',
  async (searchTerm, { rejectWithValue }) => {
    try {
      const url = endpoints.getEmployees.replace(':searchTerm', searchTerm)
      return await geoApi.getAllGeo<IGenericEmployee[]>(url).then((employees) => getUniqueById(employees.flat()))
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const getEmployeesByRole = createAsyncThunk<IEmployee[], string, { rejectValue: AxiosError }>(
  'employees/getEmployeesByRole',
  async (roleName, { rejectWithValue }) => {
    try {
      if (roleName === DEMO_USER_KEY) {
        return await geoApi.getAllGeo<IEmployee[]>(`global/demouser`).then((res) => res.flat())
      }
      const employeesResponse = await geoApi.getAllGeo<IEmployeeResponse[]>(`global/employees/${ROLE_NAME[roleName]}`)
      if (roleName === SUPER_ADMIN_KEY) {
        return getUniqueEmployees(employeesResponse)
      }
      return employeesResponse.flat().map(({ data, metadata }) => ({
        ...data,
        allowedPermissions: metadata.allowedActions,
      }))
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const getEmployeeByEmployeeRoleId = createAsyncThunk<
  IEmployeeSelected,
  { employeeRoleId: string; jurCode?: string },
  { rejectValue: AxiosError }
>('employees/getEmployeeByEmployeeRoleId', async ({ employeeRoleId, jurCode }, { rejectWithValue }) => {
  try {
    if (jurCode) {
      return await geoApi
        .withJurisdictionCode(jurCode)
        .get<IEmployeeSelected>(`global/employees/roles/${employeeRoleId}`)
    }
    return await baseApi.get<IEmployeeSelected>(`global/employees/roles/${employeeRoleId}`)
  } catch (err) {
    return rejectWithValue(err as AxiosError)
  }
})
