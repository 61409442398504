import { Fragment, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SearchParams, useSearchNavigate } from 'features/managePackage/hooks/useSearchNavigate'
import { CustomPagination } from 'components/CustomPagination/subscription'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { CustomArrowTooltip } from 'components/CustomTooltip'
import { searchParamToInt } from 'features/managePackage/utils/searchParamToInt'
import { SortAscIcon } from 'components/icons/SortAsc.icon'
import { SortDescIcon } from 'components/icons/SortDesc.icon'
import { CollapseAllIcon } from 'components/icons/CollapseAll.icon'
import colors from 'theme/colors'

import {
  ColumnDef,
  OnChangeFn,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

interface Props<T> {
  columns: ColumnDef<T>[]
  data: T[]
  actions?: (data: T) => JSX.Element | undefined
  details?: (data: T) => JSX.Element | undefined
  handleSort: OnChangeFn<SortingState>
  total: number
  rowKey: string
  state: {
    sorting: SortingState
    columnVisibility: Record<string, boolean>
  }
  getRowCanExpand?: (row: Row<T>) => boolean
}

export const CustomExpandTable = <T,>({
  columns,
  data,
  handleSort,
  getRowCanExpand,
  actions,
  details,
  total,
  rowKey,
  state = {
    sorting: [],
    columnVisibility: {
      valuations: true,
    },
  },
}: Props<T>) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const { searchNavigate } = useSearchNavigate()

  const table = useReactTable({
    columns,
    data,
    enableExpanding: true,
    rowCount: total,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand,
    onSortingChange: handleSort,
    state: state,
    manualPagination: true,
    manualSorting: true,
    enableSortingRemoval: false,
  })

  const toggleExpandedRows = (rows: Row<T>[]) => {
    const expandedRowsCount = rows.filter((row) => row.getIsExpanded()).length
    return expandedRowsCount > 1
  }

  const handlePagination = (params: { page?: number; size?: number }) => {
    const paramsTransformed = Object.entries(params).reduce((acc, [key, value]) => {
      if (value) acc[key] = value
      return acc
    }, {} as SearchParams)
    searchNavigate(paramsTransformed)
  }

  useEffect(() => {
    table.resetExpanded()
  }, [table, data])

  const ExpandedRow = ({
    row,
    actionsProp,
    detailsProp,
  }: {
    row: Row<T>
    actionsProp?: (data: T) => JSX.Element | undefined
    detailsProp?: (data: T) => JSX.Element | undefined
  }) => {
    return (
      <>
        <TableRow>
          {detailsProp && (
            <TableCell
              colSpan={row.getVisibleCells().length}
              sx={{
                py: '16px',
                pl: '60px',
                backgroundColor: colors.menuItemHover,
                tableLayout: 'fixed',
              }}
            >
              {detailsProp(row.original)}
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          {actionsProp && (
            <TableCell
              colSpan={row.getVisibleCells().length}
              sx={{
                p: 0,
                height: '48px',
                backgroundColor: colors.menuItemHover,
              }}
            >
              {actionsProp(row.original)}
            </TableCell>
          )}
        </TableRow>
      </>
    )
  }

  return (
    <Box
      sx={{
        flex: '0 0 100%',
        height: 'calc(100% - 106px)',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ flex: '0 1 100%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell
                      key={header.id}
                      sx={{
                        position: 'relative',
                        p: '11.5px',
                        width:
                          header.column.id === 'expand' || header.column.id === 'renew'
                            ? `${header.getSize()}px`
                            : `${header.getSize()}%`,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: header.column.getCanSort() ? 'pointer' : 'default',
                          }}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {header.column.id === 'expand' ? (
                            toggleExpandedRows(table.getCoreRowModel().rows) && (
                              <CustomArrowTooltip placement='top' title={t('tooltips.collapseAll')}>
                                <IconButton onClick={() => table.resetExpanded()} sx={{ p: 0 }}>
                                  <CollapseAllIcon />
                                </IconButton>
                              </CustomArrowTooltip>
                            )
                          ) : (
                            <OverflowTooltip
                              style={{ fontWeight: 600, mr: '8px', fontSize: '12px' }}
                              text={flexRender(header.column.columnDef.header, header.getContext()) as string}
                            />
                          )}
                          {(header.column.getCanSort() &&
                            { asc: <SortAscIcon />, desc: <SortDescIcon /> }[
                              header.column.getIsSorted() as string
                            ]) ?? <SortDescIcon sx={{ color: colors.silverSand }} />}
                        </Box>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <Fragment key={row.id}>
                  <TableRow sx={{ borderBottom: 'none' }}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        sx={{
                          position: 'relative',
                          p: cell.column.id === 'renew' ? '8px' : '11.5px',
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                  {rowKey && row.original[rowKey as keyof typeof row.original] && row.getIsExpanded() && (
                    <ExpandedRow row={row} actionsProp={actions} detailsProp={details} />
                  )}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CustomPagination
        changeCurrentPage={handlePagination}
        count={total}
        currentPage={searchParamToInt(searchParams.get('page'))}
        rowsPerPage={searchParamToInt(searchParams.get('size'), 10)}
      />
    </Box>
  )
}
