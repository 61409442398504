const colors = {
  blue: {
    main: '#007CB0',
    100: '#A0DCFF',
    200: '#62B5E5',
    300: '#00A3E0', // menuActive
    400: '#0076A8',
    500: '#005587',
    600: '#012169',
    700: '#041E42',
  },
  green: {
    main: '#86BC25',
    100: '#E3E48D',
    200: '#C4D600',
    300: '#86BC25',
    400: '#43B02A',
    500: '#009A44',
    600: '#046A38',
    700: '#2C5234',
  },
  teal: {
    main: '#DDEFE8',
    100: '#9DD4CF',
    200: '#86BC25',
    300: '#43B02A',
    400: '#009A44',
    500: '#046A38',
    600: '#2C5234',
  },
  grey: {
    100: '#E0E0E0',
    200: '#BBBCBC',
    300: '#A7A8AA',
    400: '#97999B',
    500: '#75787B',
    600: '#63666A',
    700: '#53565A',
    800: '#757575',
  },
  black: {
    main: '#000000',
    6: 'rgba(0, 0, 0, 0.06)',
    12: 'rgba(0, 0, 0, 0.12)',
    24: 'rgba(0, 0, 0, 0.24)',
    36: 'rgba(0, 0, 0, 0.36)',
    54: 'rgba(0, 0, 0, 0.54)',
    87: 'rgba(0, 0, 0, 0.87)',
  },
  yellow: {
    main: '#FFCD00',
    '100': '#FFFBEA',
    '200': '#FFE475',
  },
  red: '#DA291C',
  orange: '#ED8B00',
  white: '#FFFFFF',
  stTropez: '#2A5599',
  bostonBlue: '#3984B6',
  chromeWhite: '#DCECC9',
  lime: '#68BC25',
  doublePearlLusta: '#FCF4D5',
  dover: '#FCEBA3',
  kournikova: '#FFE67F',
  selectiveYellow: '#FFB700',
  orangePeel: '#FF9D00',
  tango: '#EC7016',
  christine: '#E35A0E',
  froly: '#F4706D',
  razzmatazz: '#EA075E',
  hollywoodCerise: '#EA078E',
  redViolet: '#C8158B',
  disco: '#992165',
  meteorite: '#3D2074',
  menuItemHover: '#F7F7F7',
  illustrativeView: '#EBEBEB',
  border: '#E0E0E0',
  mainGrey: '#757575',
  silverSand: '#C2C2C2',
  inputFieldBorder: '#D9D9D9',
  bgDisabled: '#F0F0F0',
  blueBorder: '#5DA1DA',
  lightBlue: '#F1F8FB',
  silverChalice: '#A3A3A3',
  desertStorm: '#F5F5F4',
  mineShaft: '#212121',
  quillGray: '#D0D0CE',
  aliceBlue: '#EBF8FD',
  shark: '#212529',
  dodgerBlue: '#23c0fa',
  gallery: '#eeeeee',
  doveGray: '#666666',
  deepCerulean: '#0076A8',
  concrete: '#F3F3F3',
  silver: '#BCBCBC',
  hummingBird: '#E0F4FB',
  btDisabled: 'rgba(0, 0, 0, 0.26)',
  foam: '#F0F9FD',
  mintTulip: '#BDE4F3',
  beautyBush: '#F1C7C4',
  wispPink: '#FDF2F1',
  astra: '#FAEEBD',
  islandSpice: '#FFFCF0',
  lightGreen: '#EBF7F8',
  intenseGreen: '#00ABAB',
  distantHorizon: '#F0F9FA',
  laPazSiesta: '#C2E6EA',
}

export default colors
