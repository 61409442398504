import { SvgIcon, SvgIconProps } from '@mui/material'

export const DocumentCheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width='14'
      height='16'
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.79289 0C7.9255 0 8.05268 0.0526784 8.14645 0.146447L11.8536 
        3.85355C11.9473 3.94732 12 4.0745 12 4.20711V10.6716L11 11.6716V5H8C7.48716
        5 7.06449 4.61396 7.00673 4.11662L7 4V0.999L1 1V15H6C6.27614 15 6.5 15.2239 
        6.5 15.5C6.5 15.7761 6.27614 16 6 16H0.5C0.223858 16 0 15.7761 0 15.5V0.5C0 
        0.223858 0.223858 0 0.5 0H7.79289ZM8 1.414V4H10.586L8 1.414Z'
      />
      <path
        d='M4.5 12C4.77614 12 5 12.2239 5 12.5C5 12.7761 4.77614 13 4.5 13H2.5C2.22386
       13 2 12.7761 2 12.5C2 12.2239 2.22386 12 2.5 12H4.5Z'
      />
      <path
        d='M7.5 10C7.77614 10 8 10.2239 8 10.5C8 10.7761 7.77614 11 7.5 11H2.5C2.22386
       11 2 10.7761 2 10.5C2 10.2239 2.22386 10 2.5 10H7.5Z'
      />
      <path
        d='M9.5 8C9.77614 8 10 8.22386 10 8.5C10 8.77614 9.77614 9 9.5 9H2.5C2.22386 9
       2 8.77614 2 8.5C2 8.22386 2.22386 8 2.5 8H9.5Z'
      />
      <path
        d='M10.3536 15.8536L13.8536 12.3536C14.0488 12.1583 14.0488 11.8417 13.8536 
        11.6464C13.6583 11.4512 13.3417 11.4512 13.1464 11.6464L10 14.7929L8.85355 
        13.6464C8.65829 13.4512 8.34171 13.4512 8.14645 13.6464C7.95118 13.8417 7.95118 
        14.1583 8.14645 14.3536L9.64645 15.8536C9.84171 16.0488 10.1583 16.0488 10.3536 15.8536Z'
      />
    </SvgIcon>
  )
}
