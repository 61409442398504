import colors from 'theme/colors'
import { CalendarChange, CalendarDirection, SelectedDate, StartWeek } from '../constants'

const isSameDay = (date1: Date, date2: Date) => date1.toDateString() === date2.toDateString()

const isCurrentMonth = (date: Date, currentMonth: Date) =>
  date.getMonth() === currentMonth.getMonth()

export const updateDate = (date: Date, change: CalendarChange, direction: CalendarDirection) => {
  const newDate = new Date(date)
  if (change === CalendarChange.Month) {
    newDate.setMonth(
      direction === CalendarDirection.Next ? newDate.getMonth() + 1 : newDate.getMonth() - 1
    )
  } else {
    newDate.setFullYear(
      direction === CalendarDirection.Next ? newDate.getFullYear() + 1 : newDate.getFullYear() - 1
    )
  }
  return newDate
}

export const getBorderStyle = (
  week: { day: Date },
  selectedDate: SelectedDate,
  currentMonth: Date
): React.CSSProperties => {
  if (
    isCurrentMonth(week.day, currentMonth) &&
    selectedDate.startDate.getTime() >= week.day.getTime() &&
    selectedDate.endDate.getTime() <= week.day.getTime()
  ) {
    return {
      backgroundColor: colors.hummingBird,
      borderEndStartRadius: isSameDay(selectedDate.endDate, week.day) ? '50%' : '0',
      borderStartStartRadius: isSameDay(selectedDate.endDate, week.day) ? '50%' : '0',
      borderEndEndRadius: isSameDay(selectedDate.startDate, week.day) ? '50%' : '0',
      borderStartEndRadius: isSameDay(selectedDate.startDate, week.day) ? '50%' : '0',
    }
  }

  if (
    (isCurrentMonth(week.day, currentMonth) &&
      selectedDate.startDate.getTime() <= week.day.getTime() &&
      selectedDate.endDate.getTime() >= week.day.getTime()) ||
    (isCurrentMonth(week.day, currentMonth) &&
      selectedDate.startDate.toDateString() === week.day.toDateString() &&
      selectedDate.endDate.toDateString() === week.day.toDateString())
  ) {
    return {
      backgroundColor: colors.hummingBird,
      borderEndStartRadius: isSameDay(selectedDate.startDate, week.day) ? '50%' : '0',
      borderStartStartRadius: isSameDay(selectedDate.startDate, week.day) ? '50%' : '0',
      borderEndEndRadius: isSameDay(selectedDate.endDate, week.day) ? '50%' : '0',
      borderStartEndRadius: isSameDay(selectedDate.endDate, week.day) ? '50%' : '0',
    }
  }

  return {}
}

export const getDayStyle = (
  week: { day: Date },
  currentMonth: Date,
  selectedDate: SelectedDate,
  dateDisabledThreshold?: Date
) => {
  if (
    currentMonth.getMonth() === new Date().getMonth() &&
    week.day.getMonth() === new Date().getMonth() &&
    new Date().getDate() === week.day.getDate() &&
    new Date().getFullYear() === week.day.getFullYear() &&
    selectedDate.endDate.getDate() !== week.day.getDate() &&
    selectedDate.startDate.getDate() !== week.day.getDate()
  ) {
    return {
      color: colors.white,
      backgroundColor: `${colors.deepCerulean}99`,
      borderRadius: '50%',
    }
  }

  const isTimeGreaterThanDateDisabled =
    dateDisabledThreshold && week.day.getTime() > dateDisabledThreshold.getTime()

  const isCurrent = isCurrentMonth(week.day, currentMonth)
  const isSelected =
    isSameDay(selectedDate.startDate, week.day) || isSameDay(selectedDate.endDate, week.day)

  const dayStyle: React.CSSProperties = {
    cursor: isCurrent ? 'pointer' : 'not-allowed',
    background:
      isCurrent && isSelected
        ? colors.deepCerulean
        : isCurrent && isTimeGreaterThanDateDisabled
        ? colors.concrete
        : 'transparent',
    color:
      isCurrent && isSelected
        ? colors.white
        : !isCurrent
        ? colors.grey[100]
        : isTimeGreaterThanDateDisabled
        ? colors.silver
        : colors.black.main,
    borderRadius: '50%',
  }

  return dayStyle
}

export const generateCalendar = (date: Date, startWeek: StartWeek) => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  const lastDayInPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0)
  const month = []
  let week = []
  const maxDays = 42

  for (let index = 0; index <= maxDays; index++) {
    if (index % 7 === 0 && week.length) {
      month.push({
        week: week,
      })
      week = []
    }

    week.push({
      day: new Date(
        new Date(lastDayInPreviousMonth).setDate(
          lastDayInPreviousMonth.getDate() -
            (startWeek === StartWeek.Mon ? lastDayInPreviousMonth.getDay() : firstDay.getDay()) +
            index +
            1
        )
      ),
    })
  }

  return month
}

export const generateDecades = (year: number) => {
  const decadeStart = Math.floor(year / 10) * 10
  const _decades: number[] = []
  for (let _year = decadeStart - 1; _year < decadeStart + 11; _year++) {
    _decades.push(_year)
  }

  return Array.from({ length: Math.ceil(_decades.length / 3) }, (_, index) =>
    _decades.slice(index * 3, (index + 1) * 3)
  )
}
