import { MenuItem } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { CustomSelect } from 'components/CustomSelect'
import { IAccountDetails, IState } from 'features/subscriptionRequest/constants/subscription'
import { useTranslation } from 'react-i18next'
import { customSelectStyles } from '../accountDetails/constants'

interface ICStateSelectProps {
  isAllFormDisabled?: boolean
  isFromBillingInfo?: boolean
  handleInvalidate: (key: keyof IAccountDetails) => boolean | undefined
  checkValidForm: (field: keyof IAccountDetails) => void
  updateFormDetails: (key: string, value: string | IState) => void
  value?: string
  selectedStateTemplate: () => JSX.Element
}

const StateSelect = ({
  isAllFormDisabled,
  handleInvalidate,
  checkValidForm,
  updateFormDetails,
  isFromBillingInfo,
  selectedStateTemplate,
  value,
}: ICStateSelectProps) => {
  const { t } = useTranslation()

  const {
    states: accountDetailsStates,
    subscription: { accountDetails, country: currentCountry },
    billingStates,
  } = useAppSelector((state) => state.subscriptionRequest)

  const states = isFromBillingInfo ? billingStates : accountDetailsStates

  return (
    <CustomSelect
      required
      placeholder={t('account.state.placeholder')!}
      disabled={!states.length || isAllFormDisabled}
      label={t('account.state.label')!}
      value={value}
      selectedTemplated={selectedStateTemplate}
      error={handleInvalidate('state')}
      onChange={(e) => {
        const state = states.find((_state: IState) => _state.id === Number(e.target.value))!
        updateFormDetails('state', state)
      }}
      onBlur={() => checkValidForm('state')}
      sx={isAllFormDisabled ? { ...customSelectStyles } : null}
    >
      {states.map((state) => {
        return (
          <MenuItem key={state.id} value={state.id}>
            {accountDetails.country
              ? state.name
              : t(`states.${currentCountry?.abbreviation.toLowerCase()}.${state.abbreviation.toLowerCase()}`)}
          </MenuItem>
        )
      })}
    </CustomSelect>
  )
}

export default StateSelect
