import { FC } from 'react'
import {
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  IPackage,
  IPackageRole,
  IProcessPackages,
  ISubscription,
  PackageProcess,
  PackageStatus,
  statusColor,
} from 'features/manageProduct/constants'
import { CustomSelect } from 'components/CustomSelect'
import { useAppSelector } from 'app/hooks'
import { ProductFeature } from 'features/subscriptionRequest/constants/subscription'
import { subscriptionDetailsStyle } from './constants'
import { Permissions } from 'constants/permissions'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { formatDate } from 'utils/date'
import { getDateFormat } from 'sharedSlices/auth'

interface Props {
  subscription: ISubscription
  processPackages: IProcessPackages
  onProcessSubmit: () => void
  onChangeRole: (role: IPackageRole) => void
  onChangePackageProcess: (process: PackageProcess | null, packageId: string, packageRole: IPackageRole | null) => void
  onApproveAll: () => void
  onRejectAll: () => void
}

export const SubscriptionDetails: FC<Props> = ({
  subscription,
  processPackages,
  onProcessSubmit,
  onChangeRole,
  onChangePackageProcess,
  onApproveAll,
  onRejectAll,
}) => {
  const { packageRoles, selectedProduct } = useAppSelector((state) => state.manageProduct)
  const dateFormat = useAppSelector(getDateFormat)
  const { t } = useTranslation()

  const havePermission = subscription.packages.some((_package) => {
    return _package.metadata!.allowedActions.includes(Permissions.ApproveRejectSubscriptions)
  })

  const durationValuations = (item: IPackage) => {
    if (item.term) {
      if (item.status === PackageStatus.Pending || item.status === PackageStatus.Rejected) {
        return `${item.term.duration} ${
          item.term.duration > 1 ? t('manageProduct.subscriptions.years') : t('manageProduct.subscriptions.year')
        }`
      }
      return `${formatDate(item.startDate, dateFormat)} – ${formatDate(item.endDate, dateFormat)}`
    }
    return `${item.availableLicenseQuantity + item.pendingLicenseQuantity} of ${
      item.licenseQuantity + item.pendingLicenseQuantity
    }`
  }

  const isSubmitDisabled = () => {
    return (
      !processPackages[subscription.id] ||
      (selectedProduct &&
        selectedProduct.features.includes(ProductFeature.Role) &&
        !processPackages[subscription.id].role &&
        !(
          processPackages[subscription.id].packages.every((processPackage) => processPackage.isApprove === false) ||
          processPackages[subscription.id].packages.some(
            (processPackage) => processPackage.isApprove === false && processPackages[subscription.id].role
          )
        )) ||
      !subscription.packages
        .filter((_package) => [PackageStatus.Pending, PackageStatus.PendingRenew].includes(_package.status))
        .every((p) =>
          processPackages[subscription.id].packages.some(
            (processPackage) => processPackage.id === p.id && processPackage.isApprove !== null
          )
        )
    )
  }

  const isActionAvailable = subscription.packages.some((item) =>
    [PackageStatus.PendingRenew, PackageStatus.Pending, PackageStatus.PendingReactivation].includes(item.status)
  )

  const packageProcessValue = (_package: IPackage) => {
    const packageProcess = processPackages[subscription.id]?.packages?.find(
      (processPackage) => processPackage.id === _package.id
    )
    return typeof packageProcess?.isApprove === 'boolean'
      ? packageProcess.isApprove
        ? PackageProcess.Approve
        : PackageProcess.Reject
      : null
  }

  const packageRole = (_package: IPackage) => {
    return (
      processPackages[subscription.id]?.role || packageRoles.find((role) => role.id === _package.productRoleId) || null
    )
  }

  const showRejectAndApproveButtons = () => {
    if (havePermission && isActionAvailable) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button sx={{ fontSize: '12px', lineHeight: 2.1, padding: '16px' }} onClick={onRejectAll}>
            {t('manageProduct.buttons.rejectAll')}
          </Button>
          <Button sx={{ fontSize: '12px', lineHeight: 2.1, padding: '16px' }} onClick={onApproveAll}>
            {t('manageProduct.buttons.approveAll')}
          </Button>
        </Box>
      )
    }
  }

  const showSubmitButton = () => {
    if (havePermission && isActionAvailable) {
      return (
        <TableRow>
          <TableCell sx={{ padding: '8px 16px' }} colSpan={8} align='right'>
            <Button disabled={isSubmitDisabled()} onClick={onProcessSubmit}>
              {t('button.submit')}
            </Button>
          </TableCell>
        </TableRow>
      )
    }
  }

  return (
    <TableContainer
      sx={{
        ...subscriptionDetailsStyle,
      }}
    >
      <Table sx={{ border: 'none', tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                width: '56px',
                minWidth: '56px',
              }}
            ></TableCell>
            <TableCell className='package'>
              <OverflowTooltip text={t('manageProduct.subscriptionDetails.package')} />
            </TableCell>
            <TableCell className='status'>
              <OverflowTooltip text={t('manageProduct.subscriptionDetails.status')} />
            </TableCell>
            <TableCell className='reviewed-by'>
              <OverflowTooltip text={t('manageProduct.subscriptionDetails.reviewedBy')} />
            </TableCell>
            <TableCell className='review-date'>
              <OverflowTooltip text={t('manageProduct.subscriptionDetails.reviewDate')} />
            </TableCell>
            <TableCell className='term'>
              <OverflowTooltip text={t('manageProduct.subscriptionDetails.term')} />
            </TableCell>
            <TableCell className='role'>
              {selectedProduct &&
                selectedProduct.features.includes(ProductFeature.Role) &&
                t('manageProduct.subscriptionDetails.role')}
            </TableCell>
            <TableCell className='approve-reject' sx={{ padding: 0, width: '207px', minWidth: '207px' }}>
              {showRejectAndApproveButtons()}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscription.packages.map((item) => (
            <TableRow key={item.id}>
              <TableCell
                sx={{
                  width: '56px',
                  minWidth: '56px',
                }}
              ></TableCell>
              <TableCell>
                <OverflowTooltip text={item.name} />
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      backgroundColor: statusColor[item.status],
                      borderRadius: '50%',
                      width: '8px',
                      height: '8px',
                      marginRight: '8px',
                    }}
                  ></Box>
                  <OverflowTooltip text={t(`manageProduct.status.${item.status}`)}></OverflowTooltip>
                </Box>
              </TableCell>
              <TableCell>
                <OverflowTooltip text={item.reviewedBy || '-'} />
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <OverflowTooltip text={item.reviewDate ? formatDate(item.reviewDate, dateFormat) : '-'} />
                </Box>
              </TableCell>
              <TableCell>
                <OverflowTooltip text={durationValuations(item)} />
              </TableCell>
              <TableCell>
                {selectedProduct && selectedProduct.features.includes(ProductFeature.Role) && (
                  <CustomSelect
                    sx={{
                      width: '110px',
                      '.MuiSelect-select': {
                        paddingLeft: 0,
                      },
                    }}
                    disabled={
                      item.status === PackageStatus.Rejected || packageProcessValue(item) === PackageProcess.Reject
                    }
                    placeholder={t('manageProduct.subscriptionDetails.rolePlaceholder')!}
                    outline={false}
                    value={packageRole(item)?.name}
                    onChange={(e) => {
                      const role = packageRoles.find((_role) => _role.name === e.target.value)!
                      if (isActionAvailable) {
                        onChangePackageProcess(packageProcessValue(item), item.id, role)
                      } else {
                        onChangeRole(role)
                      }
                    }}
                  >
                    {packageRoles.map((role) => (
                      <MenuItem key={role.id} value={role.name}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              </TableCell>
              <TableCell
                sx={{
                  padding: 0,
                }}
              >
                {havePermission &&
                  [PackageStatus.Pending, PackageStatus.PendingRenew, PackageStatus.PendingReactivation].includes(
                    item.status
                  ) && (
                    <RadioGroup
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        justifyContent: 'end',
                      }}
                      value={packageProcessValue(item)}
                      onChange={(e) =>
                        onChangePackageProcess(e.target.value as PackageProcess, item.id, packageRole(item))
                      }
                    >
                      <FormControlLabel
                        sx={{
                          margin: 0,
                          padding: '16px 14px',
                          '.MuiRadio-root': {
                            fontSize: '14px',
                            fontWeight: 400,
                            padding: '0 8px 0 0',
                          },
                          '.MuiTypography-root': {
                            fontSize: '14px',
                          },
                        }}
                        control={<Radio size='small' disableRipple />}
                        value={PackageProcess.Reject}
                        label={t('manageProduct.radioButtons.reject')}
                      />
                      <FormControlLabel
                        sx={{
                          margin: 0,
                          padding: '16px 14px',
                          '.MuiRadio-root': {
                            fontSize: '14px',
                            fontWeight: 400,
                            padding: '0 8px 0 0',
                          },
                          '.MuiTypography-root': {
                            fontSize: '14px',
                          },
                        }}
                        control={<Radio size='small' disableRipple />}
                        value={PackageProcess.Approve}
                        label={t('manageProduct.radioButtons.approve')}
                      />
                    </RadioGroup>
                  )}
              </TableCell>
            </TableRow>
          ))}
          {showSubmitButton()}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
