import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { UpdateAdminsModal } from '../updateAdminsModal'
import { DeleteAdminModal } from '../deleteAdminModal'
import AddAdminsModal from '../addAdminsModal'
import AdminsTable from '../adminsTable'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getEmployeesByRole } from 'features/manageAdmins/slices'
import { selectEmployeeList } from 'features/manageAdmins/slices/employees/selectors'

import { IEmployee } from 'features/manageAdmins/constants'
import { createColumns } from 'features/manageAdmins/utils/createColumns'

export const AdminContainer = () => {
  const dispatch = useAppDispatch()
  const { roleName } = useParams()
  const employees = useAppSelector(selectEmployeeList)
  const columns = useMemo(() => createColumns<IEmployee>(roleName!), [roleName])

  useEffect(() => {
    roleName && dispatch(getEmployeesByRole(roleName))
  }, [dispatch, roleName])

  return (
    <>
      <AddAdminsModal />
      <UpdateAdminsModal />
      <DeleteAdminModal />
      <AdminsTable<IEmployee> columns={columns} data={employees} />
    </>
  )
}
