import { IAddAdminFormData, IUpdateEmployeePermissions, headers, DEMO_USER, IEmployee } from '../constants'
import { geoApi } from 'services/api/geoApi'

export const addEmployee = ({
  roleId,
  jurisdictionId,
  employeeIds,
  permissionIds,
  productId,
}: IAddAdminFormData): Promise<void | void[]> => {
  const jurCode = jurisdictionId && geoApi.jurMapping[jurisdictionId]!.code
  if (roleId === DEMO_USER) {
    const payload = {
      jurisdictionId,
      productGroupId: productId,
      demoUserIds: employeeIds,
      roleEntityIds: employeeIds.map(() => crypto.randomUUID()),
    }
    return geoApi.withJurisdictionCode(jurCode!).post('global/demouser', payload, { ...headers })
  }
  const payload = {
    roleId,
    employeeIds,
    permissionIds,
    jurisdictionId,
    productGroupId: productId,
    roleEntityIds: employeeIds.map(() => crypto.randomUUID()),
  }
  if (jurCode) {
    return geoApi.withJurisdictionCode(jurCode).post('global/employees', payload, { ...headers })
  }
  return geoApi.postAllGeo('global/employees', payload, { ...headers })
}

export const updateEmployeePermissions = (params: IUpdateEmployeePermissions): Promise<void | void[]> => {
  const permissionIds = {
    permissionIds: [...params.permissions],
  }
  if (params.jurisdiction?.code) {
    return geoApi
      .withJurisdictionCode(params.jurisdiction.code)
      .patch(`global/employees/roles/${params.employeeRoleId}`, permissionIds, { ...headers })
  }
  return geoApi.patchAllGeo<void>(`global/employees/roles/${params.employeeRoleId}`, permissionIds, { ...headers })
}

export const deleteUser = {
  demo: (employeeInfo: IEmployee): Promise<void> => {
    return geoApi
      .withJurisdictionCode(employeeInfo.jurisdiction!.code)
      .delete(`global/demouser/${employeeInfo.id}`, { ...headers })
  },
  admin: (employeeInfo: IEmployee): Promise<void | void[]> => {
    if (employeeInfo.jurisdiction?.code) {
      return geoApi
        .withJurisdictionCode(employeeInfo.jurisdiction.code)
        .delete(`global/employees/roles/${employeeInfo.employeeRoleId}`, { ...headers })
    }
    return geoApi.deleteAllGeo<void>(`global/employees/roles/${employeeInfo.employeeRoleId}`, { ...headers })
  },
}
