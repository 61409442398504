import { SvgIcon, SvgIconProps } from '@mui/material'

export const ExcelIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox="0 0 24 24"
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.5858 4C13.851 4 14.1054 4.10536 14.2929 4.29289L17.7071
          7.70711C17.8946 7.89464 18 8.149 18 8.41421V19.5C18 19.7761 17.7761
          20 17.5 20H6.5C6.22386 20 6 19.7761 6 19.5V17H5.5C5.22386 17 5 16.7761
          5 16.5V10.5C5 10.2239 5.22386 10 5.5 10H6V4.5C6 4.22386 6.22386 4 6.5
          4H13.5858ZM13 4.999L7 5V10H11.5C11.7761 10 12 10.2239 12 10.5V16.5C12
          16.7761 11.7761 17 11.5 17H7V19H17V9H14C13.4872 9 13.0645 8.61396 13.0067
          8.11662L13 8V4.999ZM14 5.414V8H16.586L14 5.414ZM8 11V12.2929L8.5 12.792L9
          12.2929V11H10V12.5C10 12.6326 9.94732 12.7598 9.85355 12.8536L9.207
          13.499L9.85355 14.1464C9.92857 14.2215 9.97729 14.3179 9.99377 14.4213L10
          14.5V16H9V14.7071L8.5 14.207L8 14.7071V16H7V14.5C7 14.3674 7.05268 14.2402
          7.14645 14.1464L7.793 13.5L7.14645 12.8536C7.07143 12.7785 7.02271 12.6821
          7.00623 12.5787L7 12.5V11H8Z'
        />
      </SvgIcon>
    </>
  )
}
