import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

import { ManageAdminsSlice } from '../constants'
import { Permissions } from 'constants/permissions'
import { AuthSlice } from 'sharedSlices/auth/constants'
import { longRoleNames, permissionKeysMapping } from 'constants/languageKeys'
import {
  ADMIN_TAB_PATH,
  PRODUCT_ADMIN,
  IRole,
  IPermission,
  DEMO_USER,
  SUPER_ADMIN,
  JURISDICTION_ADMIN,
} from 'features/manageAdmins/constants'

import { removeRepeated } from 'features/manageAdmins/utils'

const selectManageAdmins = (store: RootState): ManageAdminsSlice => store.manageAdmins
const selectAuth = (store: RootState): AuthSlice => store.auth

export const filteredSelectProducts = createSelector(
  selectManageAdmins,
  selectAuth,
  ({ products, roleSelected }: ManageAdminsSlice, { employeeInformation }: AuthSlice) => {
    if (roleSelected) {
      if (roleSelected === DEMO_USER) return products
      const selectPermission =
        roleSelected === PRODUCT_ADMIN
          ? Permissions.AddRemProductAdmins
          : Permissions.AddRemJurisdictionAdmins

      const filteredRoles = employeeInformation!.roles
        .filter((role) =>
          role.permissions?.some((permission) => Number(permission.id) === selectPermission)
        )
        .map((role) => role.productGroupId)

      return products.filter((product) => filteredRoles.find((pGroupId) => pGroupId === product.id))
    }
    return []
  }
)

export const selectPermissions = createSelector(
  selectManageAdmins,
  ({ permissions }: ManageAdminsSlice) => {
    return removeRepeated(permissions).map((permission) => ({
      id: permission.id,
      name: permissionKeysMapping[permission.id],
    }))
  }
)

export const selectRolePaths = createSelector(
  selectManageAdmins,
  selectAuth,
  ({ roles, isLoading, employeeRoleId }: ManageAdminsSlice, { employeeInformation }: AuthSlice) => {
    const rolePaths = roles
      .map((role) => {
        const firstRoleNamePart = role.name.split(' ')[0].toLowerCase()
        const path = `${ADMIN_TAB_PATH}/${firstRoleNamePart}`
        return {
          ...role,
          path,
          name: longRoleNames[role.name.replace(/\s+/g, '')],
        }
      })
      .sort((a, b) => a.id - b.id)

    const allPermissions: IPermission[] = employeeInformation!.roles
      .map((role) => role.permissions!.map((permission) => permission))
      .flat()

    const permissionIds = removeRepeated(allPermissions).map((p) => String(p.id))

    if (permissionIds.includes(String(Permissions.AddRemDemoUser))) {
      !isLoading &&
        employeeRoleId &&
        rolePaths.push({
          id: DEMO_USER,
          name: 'demoUsers.name',
          path: `${ADMIN_TAB_PATH}/demouser`,
        })
    }

    return rolePaths
  }
)

export const filteredRoleList = createSelector(selectAuth, ({ employeeInformation }: AuthSlice) => {
  let roleList: IRole[] = []

  const allPermissions: IPermission[] = employeeInformation!.roles
    .map((role) => role.permissions!.map((permission) => permission))
    .flat()

  const permissionIds = removeRepeated(allPermissions).map((p) => String(p.id))

  if (permissionIds.includes(String(Permissions.AddRemSuperAdmins))) {
    roleList = [...roleList, { id: SUPER_ADMIN, name: 'roles.long.super' }]
  }
  if (permissionIds.includes(String(Permissions.AddRemJurisdictionAdmins))) {
    roleList = [...roleList, { id: JURISDICTION_ADMIN, name: 'roles.long.jurisdiction' }]
  }
  if (permissionIds.includes(String(Permissions.AddRemProductAdmins))) {
    roleList = [...roleList, { id: PRODUCT_ADMIN, name: 'roles.long.product' }]
  }
  if (permissionIds.includes(String(Permissions.AddRemDemoUser))) {
    roleList = [...roleList, { id: DEMO_USER, name: 'demoUser.name' }]
  }

  return roleList.map((p) => ({ id: p.id, name: p.name }))
})
