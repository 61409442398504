import { RootState } from 'app/store'
import { createSelector } from '@reduxjs/toolkit'

import { EmployeesSlice } from '../constants'
import { ManageAdminsSlice } from '../../manageAdmins/constants'
import { DEMO_USER } from 'features/manageAdmins/constants'

import { removeRepeated } from 'features/manageAdmins/utils'
import { longRoleNames } from 'constants/languageKeys'

const selectEmployees = (store: RootState): EmployeesSlice => store.employees
const selectManageAdmins = (store: RootState): ManageAdminsSlice => store.manageAdmins

export const selectEmployeeSelectedInfo = createSelector(
  selectEmployees,
  ({ employeeSelected }: EmployeesSlice) => employeeSelected?.employee
)

export const selectJurisdictionSelectedInfo = createSelector(
  selectEmployees,
  ({ employeeSelected }: EmployeesSlice) => employeeSelected?.jurisdiction
)

export const selectProductSelectInfo = createSelector(
  selectEmployees,
  ({ employeeSelected }: EmployeesSlice) => employeeSelected?.product
)

export const selectEmployeeSelectedPermissions = createSelector(
  selectEmployees,
  ({ employeeSelected }: EmployeesSlice) => {
    return employeeSelected && removeRepeated(employeeSelected.role.permissions)
  }
)

export const selectEmployeeSelectedRole = createSelector(
  selectEmployees,
  ({ employeeSelected }: EmployeesSlice) => {
    if (employeeSelected) {
      return {
        ...employeeSelected.role,
        name: longRoleNames[employeeSelected.role.name.replace(/ /g, '')],
      }
    }
  }
)

export const selectEmployeeList = createSelector(
  selectEmployees,
  selectManageAdmins,
  ({ employees }: EmployeesSlice, { employeeRoleId }: ManageAdminsSlice) => {
    return employees.map((employee) => {
      return {
        ...employee,
        roles: employee.roles?.filter((roleId) => roleId !== employeeRoleId)
      }
    })
  }
)

//employee.id for demoUser
export const selectEmployeeSelectedFromList = createSelector(
  selectEmployees,
  selectManageAdmins,
  ({ employees, employeeSelectedId }: EmployeesSlice, { employeeRoleId }: ManageAdminsSlice) => {
    if (employeeRoleId === DEMO_USER) {
      return employees.find((employee) => employee.id === employeeSelectedId)
    } else {
      return employees.find((employee) => employee.employeeRoleId === employeeSelectedId)
    }
  }
)
