import { Box } from '@mui/material'
import { CustomAlert } from 'components/CustomAlert'

interface IValidationErrorMessageProps {
  isMessageShown: boolean
  text: string
}

const ValidationErrorMessage = ({ isMessageShown, text }: IValidationErrorMessageProps) => {
  return (
    <>
      {isMessageShown && (
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <CustomAlert severity='error' text={text} />
        </Box>
      )}
    </>
  )
}

export default ValidationErrorMessage
