import { CSSProperties, FC } from 'react'
import { Trans } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'
import { CloseIcon } from 'components/icons/Close.icon'
import CustomModal from 'components/CustomModal'
import colors from 'theme/colors'

interface Props {
  onClose: () => void
  open: boolean
  link: string
  title: string
  text: string
  text2?: string
  buttonText: string
  customLink: string
  linkStyles?: CSSProperties
}

export const RestrictionPopup: FC<Props> = ({
  onClose,
  open,
  link,
  title,
  text,
  text2,
  buttonText,
  customLink,
  linkStyles,
}) => {
  return (
    <CustomModal open={open} onClose={onClose}>
      <CustomModal.Header>
        <Box sx={{ marginRight: 'auto' }}>{title}</Box>
        <Button
          sx={{
            minHeight: '24px',
            minWidth: '24px',
            padding: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </CustomModal.Header>
      <CustomModal.Body style={{ padding: '16px' }}>
        <Typography variant='subtitle2' lineHeight='24px'>
          <Trans i18nKey={text}>
            <a style={{ textDecoration: 'none', color: colors.blue.main, ...linkStyles }} href={link}>
              {customLink}
            </a>
          </Trans>
        </Typography>
        <Typography variant='subtitle2' lineHeight='24px'>
          {text2}
        </Typography>
      </CustomModal.Body>
      <CustomModal.Footer
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          padding: '16px',
        }}
      >
        <Button sx={{ width: '90px' }} variant='contained' onClick={onClose}>
          {buttonText}
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  )
}
