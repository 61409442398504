import { Avatar, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRef } from 'react'
import { useAppDispatch } from 'app/hooks'
import { useContainerScroll } from 'utils/scrollableFooter'
import { MockFooterBlock } from 'components/Footer/mockFooterBlock'
import colors from 'theme/colors'

interface IPolicyPreview {
  jurisdiction: string
  data: string
  countryCode: string
  title: string
  lng: string
}

export const AcceptableUsePolicyPreview = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const policyPreview: IPolicyPreview | null = localStorage.getItem('policyPreview')
    ? JSON.parse(localStorage.getItem('policyPreview')!)
    : null

  const containerRef = useRef<HTMLDivElement | null>(null)

  useContainerScroll(containerRef, dispatch)

  return policyPreview ? (
    <Box ref={containerRef} sx={{ height: '100%', overflowY: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          padding: '24px 0 24px 24px',
          position: 'relative',
          width: '918px',
          margin: '0 auto',
        }}
      >
        <Box padding='8px'>
          <Typography variant='h3' fontWeight='600' marginBottom='20px'>
            {policyPreview.title}
          </Typography>

          <Typography variant='subtitle2' color='grey.800' fontWeight='400'>
            {t('acceptableUsePolicy.header.country', { lng: policyPreview.lng })}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              margin: '10px 0',
            }}
          >
            <Avatar
              sx={{ width: 24, height: 24, mr: '10px', flexShrink: 0 }}
              src={`${process.env.PUBLIC_URL}/images/flags/${policyPreview.countryCode}.svg`}
            />
            <Typography fontSize='14px' fontWeight='400' color={colors.shark}>
              {t(`country.${policyPreview.countryCode.toLowerCase()}.name`, {
                lng: policyPreview.lng,
              })}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
          dangerouslySetInnerHTML={{ __html: policyPreview.data }}
        />
      </Box>
      <MockFooterBlock />
    </Box>
  ) : null
}
