import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getAvailableLanguages } from './actions'

import { LocalizationState } from './constants'

const _defaultLanguage = {
  localeCode: localStorage.getItem('i18nextLng') || window.APP_CONFIG.DEFAULT_LOCALE_CODE || '',
  nativeName: window.APP_CONFIG.DEFAULT_NATIVE_NAME || '',
}

export const LOCALIZATION_INITIAL_STATE: LocalizationState = {
  availableLanguages: [],
  languageSelected: _defaultLanguage,
  isLoading: false,
}

const _handlePending = (state: LocalizationState) => {
  state.isLoading = true
}

const localizationSlice = createSlice({
  name: 'localization',
  initialState: LOCALIZATION_INITIAL_STATE,
  reducers: {
    handleLanguageSelected: (state: LocalizationState, action: PayloadAction<string>) => {
      const localeCodeSelected = action.payload
      if (state.availableLanguages.length) {
        const [languageSelected] = state.availableLanguages.filter(
          (lang) => lang.localeCode === localeCodeSelected
        )
        document.documentElement.lang =  localeCodeSelected.split("-")[0] ?? 'en'

        state.languageSelected = {
          localeCode: languageSelected?.localeCode || _defaultLanguage.localeCode,
          nativeName: languageSelected?.nativeName || _defaultLanguage.nativeName,
        }
      }
    },
  },
  extraReducers: (builder) => {
    // getAvailableLanguages
    builder.addCase(getAvailableLanguages.pending, _handlePending)
    builder.addCase(getAvailableLanguages.fulfilled, (state: LocalizationState, action) => {
      state.availableLanguages = action.payload
      state.isLoading = false
      state.error = undefined
    })
    builder.addCase(getAvailableLanguages.rejected, (state: LocalizationState, action) => {
      state.availableLanguages = []
      state.isLoading = false
      state.error = action.payload?.response
    })
  },
})

export const { handleLanguageSelected } = localizationSlice.actions

export { getAvailableLanguages }

export default localizationSlice.reducer
