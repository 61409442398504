import baseApi from 'services/api/baseApi'

interface IBackendVersion {
  backendBuildId: string
  deploymentStartTime: string
  frontendBuildId: string
}

const logFrontendVersion = () => {
  console.log(
    `%cFrontend version: ${window.APP_CONFIG.BUILD_ID} | ${window.APP_CONFIG.DEPLOMENYMENT_START_TIME}`,
    'background-color: #86BC25; color: #000000; padding: 5px'
  )
}

const logBackendVersion = (backendVersion: IBackendVersion) => {
  console.log(
    `%cBackend version: ${backendVersion.backendBuildId} | ${backendVersion.deploymentStartTime}`,
    'background-color: #86BC25; color: #000000; padding: 5px'
  )
}

export const showVersions = async () => {
  try {
    const backendBuildVersion = await baseApi.get<IBackendVersion>('global/version/build')
    logFrontendVersion()
    logBackendVersion(backendBuildVersion)
  } catch (error) {
    console.error('Error fetching versions:', error)
  }
}
