import { SnackbarProvider } from 'notistack'

import SnackbarComponent from './SnackbarComponent'

export const CustomSnackbar = () => {
  return (
    <SnackbarProvider
      Components={{ mySnackbar: SnackbarComponent }}
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      disableWindowBlurListener={true}
    />
  )
}
