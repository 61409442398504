import { useCallback, useEffect, FC } from 'react'
import { Box, MenuItem, SelectChangeEvent, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getProductJurisdictions, setProdJuriSelected } from 'features/managePackage/slice'
import { useSearchNavigate } from 'features/managePackage/hooks/useSearchNavigate'
import { selectSortedProductJurisdictions } from 'features/managePackage/slice/selectors'
import { useGetSubscriptions } from 'features/managePackage/hooks/useGetSubscriptions'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { CustomSelect } from 'components/CustomSelect'
import colors from 'theme/colors'

export const SelectProductJurisdiction: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const productSelectedId = searchParams.get('productId')
  const productJurisdictions = useAppSelector(selectSortedProductJurisdictions)
  const { prodGroupSelected, prodJurisSelected } = useAppSelector((state) => state.managePackage)
  const { localeCode } = useAppSelector((state) => state.localization.languageSelected)

  const { searchFixedNavigate } = useSearchNavigate()
  const { getSubscriptionInfo } = useGetSubscriptions()

  const findProductJurisdictions = useCallback(
    (productId: string) => productJurisdictions.find((proJur) => proJur.productId === productId),
    [productJurisdictions.length]
  )

  const handleChange = (e: SelectChangeEvent) => {
    const productId = e.target.value
    const product = findProductJurisdictions(productId)!
    dispatch(setProdJuriSelected(product))
    searchFixedNavigate({
      name: searchParams.get('name')!,
      productId,
      code: product.code,
    })
    getSubscriptionInfo(product.productId, product.code)
  }

  useEffect(() => {
    if (prodGroupSelected) dispatch(getProductJurisdictions(prodGroupSelected))
  }, [dispatch, prodGroupSelected, localeCode])

  useEffect(() => {
    if (productSelectedId && !prodJurisSelected) {
      const product = findProductJurisdictions(productSelectedId)!
      product && dispatch(setProdJuriSelected(product))
    }
  }, [productSelectedId, findProductJurisdictions])

  return productJurisdictions.length ? (
    <Box
      sx={{
        maxHeight: '48px',
        display: 'flex',
        flex: '0 1 0',
        p: '5px',
        boxSizing: 'border-box',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Typography fontWeight={600} fontSize={'14px'}>
        {t('manageProduct.product.label')}:
      </Typography>
      <CustomSelect
        value={productSelectedId ? productSelectedId : ''}
        sx={{
          '&&& .MuiSelect-select': {
            color: colors.mineShaft,
          },
          '&&&& fieldset': {
            borderColor: 'transparent',
          },
        }}
        onChange={handleChange}
        placeholder={t('manageProduct.product.placeholder')}
        selectedTemplated={() => (
          <>{productJurisdictions.find((productJur) => productJur.productId === productSelectedId)?.name}</>
        )}
      >
        {productJurisdictions.map(({ name, productId }) => (
          <MenuItem key={productId} value={productId}>
            {name}
          </MenuItem>
        ))}
      </CustomSelect>
    </Box>
  ) : (
    <></>
  )
}
