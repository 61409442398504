import { ChangeEvent, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { CustomTextField } from 'components/CustomTextField'
import { WarningIcon } from 'components/icons/Warning.icon'
import FooterForm from 'features/manageAdmins/components/footerForm'
import HeaderForm from 'features/manageAdmins/components/headerForm'
import { showMessage } from 'utils/errorMessageHandler'

import { handleRejectSubModal } from 'sharedSlices/ui'
import { PackageStatus } from 'features/manageProduct/constants'
import { rejectSubscription } from 'features/managePackage/services'
import { setSelectedPackage } from 'features/managePackage/slice'
import { useGetSubscriptions } from 'features/managePackage/hooks/useGetSubscriptions'

import { useAppDispatch, useAppSelector } from 'app/hooks'

import colors from 'theme/colors'

const MAX_LENGTH = 250

export const RejectSubForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { getSubscriptionInfo } = useGetSubscriptions()

  const { selectedPackage } = useAppSelector((state) => state.managePackage)
  const jurCode = searchParams.get('code')

  const [comment, setComment] = useState('')

  const onClose = () => {
    dispatch(setSelectedPackage(undefined))
    dispatch(handleRejectSubModal(false))
  }

  const onRejectSub = () => {
    if (selectedPackage && jurCode) {
      rejectSubscription(
        selectedPackage.subscriptionId,
        selectedPackage.subscriptionPackageId,
        comment,
        jurCode
      )
        .then(() => {
          getSubscriptionInfo()
          showMessage.success('mySnackbar', 'manageProduct.subscriptionMessage.success')
          dispatch(handleRejectSubModal(false))
        })
        .catch(() => showMessage.error('mySnackbar', 'manageProduct.subscriptionMessage.error'))
    }
  }

  const handleComment = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const message = value.slice(0, MAX_LENGTH)
    setComment((prevState) => {
      const isCommentAtMaxLEngth = prevState.length === MAX_LENGTH
      const isMessageShorterThanMax = message.length < MAX_LENGTH

      if (isCommentAtMaxLEngth && isMessageShorterThanMax) return message

      if (!isCommentAtMaxLEngth) return message

      return prevState
    })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {selectedPackage?.status === PackageStatus.Pending ? (
        <HeaderForm title='managePackages.modals.title' onClose={onClose} />
      ) : (
        <HeaderForm
          icon={<WarningIcon sx={{ mr: '6px' }} />}
          title={`managePackages.modals.title${selectedPackage?.status}`}
          onClose={onClose}
        />
      )}
      <Box sx={{ p: '16px' }}>
        <Typography variant='body2'>{t('managePackages.modals.message')}</Typography>
        <CustomTextField
          placeholder={t('managePackages.modals.enterComment')}
          label={t('managePackages.modals.comment')}
          value={comment}
          multiline
          sx={{
            mt: '16px',
            '& textarea': {
              minHeight: '92px',
              '&::placeholder': { color: colors.silverChalice, opacity: 1 },
            },
          }}
          onChange={handleComment}
        />
        <Typography
          sx={{ textAlign: 'right', color: colors.grey[800], lineHeight: '16px', mt: '8px' }}
          variant='caption'
          component={Box}
        >
          {comment.length}/{MAX_LENGTH}
        </Typography>
      </Box>
      <FooterForm>
        <FooterForm.Button text='button.no' variant='outlined' onClick={onClose} />
        <FooterForm.Button text='button.yes' disabled={!comment} onClick={onRejectSub} />
      </FooterForm>
    </Box>
  )
}
