import { forwardRef } from 'react'
import { Box, Grid } from '@mui/material'
import { IProductGroup } from 'features/productConfiguration/constants'
import { MockFooterBlock } from 'components/Footer/mockFooterBlock'
import { ProductConfigurationCard } from '../ProductConfigurationCard'

export interface Props {
  products: IProductGroup[]
  onCopyRegisterLink: (product: IProductGroup) => void
}

const ProductsConfigurationGrid = forwardRef<HTMLDivElement, Props>(({ products, onCopyRegisterLink }, ref) => {
  return (
    <Box
      sx={{
        width: '100%',
      }}
      ref={ref}
    >
      <Grid
        container
        spacing={'25px'}
        sx={{
          padding: '24px',
        }}
      >
        {products.map((product) => {
          return (
            <Grid item xs={4} key={product.id}>
              <ProductConfigurationCard product={product} onCopyRegisterLink={onCopyRegisterLink} />
            </Grid>
          )
        })}
      </Grid>
      <MockFooterBlock />
    </Box>
  )
})

ProductsConfigurationGrid.displayName = 'ProductsConfigurationGrid'

export default ProductsConfigurationGrid
