import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, CircularProgress } from '@mui/material'
import { Footer } from 'components/Footer'
import { UpperMenu } from 'components/layouts/routerLayout/upperMenu'
import { useTranslation } from 'react-i18next'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { loginRequest } from 'services/auth/authConfig'
import { loginError } from 'sharedSlices/auth'
import { useAppDispatch } from 'app/hooks'
import { AuthError, InteractionStatus } from '@azure/msal-browser'
import LoginImageArea from 'assets/images/LoginImageArea.svg'
import colors from 'theme/colors'

export const Login = () => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { instance, inProgress } = useMsal()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/manage')
    }
  }, [isAuthenticated, navigate])

  const handleLogin = useCallback(() => {
    instance.loginRedirect(loginRequest).catch((e: AuthError) => {
      dispatch(loginError(e.errorMessage))
    })
  }, [instance, dispatch])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <UpperMenu title={'titlePage.globalSubService'} />
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '56px 120px',
          color: colors.mineShaft,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            marginRight: '56px',
            fontFamily: 'Open Sans',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              fontWeight: '400',
              fontSize: '32px',
              lineHeight: '48px',
              marginBottom: '16px',
              maxWidth: '524px',
            }}
          >
            {t('login.title')}
          </Box>
          <Box
            sx={{
              fontWeight: '400',
              fontSize: '18px',
              lineHeight: '32px',
              marginBottom: '32px',
            }}
          >
            {t('login.subTitle')}
          </Box>
          <Button
            sx={{
              marginRight: 'auto',
            }}
            variant='contained'
            onClick={handleLogin}
          >
            {t('button.login')}
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              minHeight: '460px',
              minWidth: '460px',
              background: colors.desertStorm,
              borderRadius: '16px',
            }}
          >
            <Box
              sx={{
                margin: 'auto',
              }}
              component='img'
              src={LoginImageArea}
            ></Box>
          </Box>
        </Box>
        {inProgress !== InteractionStatus.None && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Footer />
    </Box>
  )
}
