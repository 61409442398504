import { ChangeEvent, FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { IPackage, SelectedPackageValuation } from 'features/subscriptionRequest/constants/subscription'
import { setValuations } from 'features/subscriptionRequest/slice'
import { CustomArrowTooltip } from 'components/CustomTooltip'
import { InfoIcon } from 'components/icons/Info.icon'
import { DropdownIcon } from 'components/icons/Dropdown.icon'
import colors from 'theme/colors'

interface Props {
  subscriptionPackage: IPackage
  isDisabled?: boolean
  selectedValuation?: SelectedPackageValuation
}

export const Valuations: FC<Props> = ({ subscriptionPackage, isDisabled, selectedValuation }) => {
  const { valuations } = useAppSelector((state) => state.subscriptionRequest)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const limit = subscriptionPackage.licenseQuantityLimit!
  const minimum = subscriptionPackage.licenseQuantity || 0

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)
    const isValid = value % 1 === 0 && value >= minimum && value <= limit
    dispatch(
      setValuations({
        [subscriptionPackage.id]: isValid ? value : valuations[subscriptionPackage.id],
      })
    )
  }

  const isIncreaseEnabled = useMemo(() => {
    return valuations[subscriptionPackage.id]! < limit
  }, [valuations])

  const isDecreaseEnabled = useMemo(() => {
    return valuations[subscriptionPackage.id]! > minimum
  }, [valuations])

  const increase = () => {
    isIncreaseEnabled && dispatch(setValuations({ [subscriptionPackage.id]: valuations[subscriptionPackage.id]! + 1 }))
  }

  const decrease = () => {
    isDecreaseEnabled && dispatch(setValuations({ [subscriptionPackage.id]: valuations[subscriptionPackage.id]! - 1 }))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
      }}
      onClick={(event) => {
        event.stopPropagation()
      }}
    >
      <CustomArrowTooltip placement='top' title={t('subscriptionRequest.packages.valuations.tooltip')}>
        <Box sx={{ pointerEvents: 'all', width: '24px', height: '24px' }}>
          <InfoIcon sx={{ fill: colors.blue.main }} />
        </Box>
      </CustomArrowTooltip>
      <Typography
        sx={{
          m: '0 10px 0 4px',
          fontSize: '14px',
          fontWeight: '600',
          cursor: 'default',
          color: isDisabled ? 'grey.800' : 'common.black',
        }}
      >
        {t('subscriptionRequest.packages.valuations.label')}
      </Typography>
      <TextField
        sx={{
          width: '96px',
          '.MuiInputBase-adornedEnd': {
            pr: '14px',
          },
          '&&& fieldset': {
            borderColor: 'grey.100',
            borderRadius: '2px',
          },
          '&&& .Mui-focused fieldset': {
            borderWidth: '1px',
          },
        }}
        value={selectedValuation?.availableLicenseQuantity || valuations[subscriptionPackage.id]}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault()
            e.key === 'ArrowUp' ? increase() : decrease()
          }
        }}
        InputProps={{
          sx: { pr: '8px' },
          endAdornment: (
            <InputAdornment sx={{ flexDirection: 'column', justifyContent: 'center' }} position='end'>
              <DropdownIcon
                viewBox='6 9 12 10'
                sx={{
                  color: isDisabled ? 'grey.800' : 'common.black',
                  cursor: 'pointer',
                  width: '12px',
                  height: '10px',
                  transform: 'rotate(180deg)',
                  ...(!isIncreaseEnabled && {
                    color: 'grey.800',
                    cursor: 'default',
                  }),
                }}
                onClick={increase}
              />
              <DropdownIcon
                viewBox='6 8 12 10'
                sx={{
                  color: isDisabled ? 'grey.800' : 'common.black',
                  cursor: 'pointer',
                  width: '12px',
                  height: '10px',
                  ...(!isDecreaseEnabled && {
                    color: 'grey.800',
                    cursor: 'default',
                  }),
                }}
                onClick={decrease}
              />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}
