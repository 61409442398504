import { SvgIcon, SvgIconProps } from '@mui/material'

export const CopyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.5858 4C15.851 4 16.1054 4.10536 16.2929 4.29289L19.7071 7.70711C19.8946
        7.89464 20 8.149 20 8.41421V16.5C20 16.7761 19.7761 17 19.5 17H15V19.5C15
        19.7761 14.7761 20 14.5 20H5.5C5.22386 20 5 19.7761 5 19.5V7.5C5 7.22386 5.22386
        7 5.5 7H10V4.5C10 4.22386 10.2239 4 10.5 4H15.5858ZM10 8H6V19H14V12H11C10.4872
        12 10.0645 11.614 10.0067 11.1166L10 11V8ZM15 5H11L11.0011 7.09032C11.1087
        7.13942 11.2077 7.20767 11.2929 7.29289L14.7071 10.7071C14.8946 10.8946 15 11.149
        15 11.4142V16H19V9H16C15.4872 9 15.0645 8.61396 15.0067 8.11662L15 8V5ZM13.586
        11L11 8.415V11H13.586ZM16 5.415V8H18.586L16 5.415Z'
        fill='#007CB0'
      />
    </SvgIcon>
  )
}
