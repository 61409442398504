import { Box } from '@mui/material'
import { useLoader } from './useLoader'
import { CustomLoading } from 'components/CustomLoading'

export const RequestLoader = () => {
  const { isLoading } = useLoader()
  return isLoading ? (
    <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh' }}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          position: 'absolute',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 9999,
        }}
      >
        <CustomLoading
          message='common.loader.loading'
          textColor='white'
          sx={{
            display: 'flex',
            width: '100%',
            height: '120px',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
          }}
        />
      </Box>
    </Box>
  ) : null
}
