import { Box, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const ReportTabs = () => {
  const { t } = useTranslation()
  const handleActive = ({ isActive }: { isActive: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontWeight: 600,
    height: '100%',
    padding: '12px 16px 12px 16px',
    borderBottom: '2px solid',
    borderColor: isActive ? 'primary.main' : 'transparent',
    color: isActive ? 'primary.main' : 'common.black',
  })

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'stretch',
        minHeight: '48px',
        padding: '0 16px',
        boxShadow: 'inset 0px -1px 0px',
        fontWeight: 600,
        color: 'grey.100',
        'p:hover': {
          color: 'primary.main',
        },
      }}
    >
      <NavLink style={{ textDecoration: 'none' }} to='/manage/reports/myreports'>
        {({ isActive }) => (
          <Typography variant='body2' sx={() => ({ ...handleActive({ isActive }) })}>
            {t('reports.tabs.myReports')}
          </Typography>
        )}
      </NavLink>
    </Box>
  )
}
