import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend, { HttpBackendOptions, RequestCallback } from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { store } from '../app/store'

import localizationApi from 'services/localizationApi'

import { Dictionary } from 'sharedSlices/localization/constants'

const loadLanguageResources = async () => {
  let localeCode = localStorage.getItem('i18nextLng')

  if (!localeCode) {
    localeCode = store.getState().localization.languageSelected.localeCode
  }

  return await localizationApi
    .get<Dictionary>(`/locale/${localeCode}`)
    .then((res) => res.dictionary.replaceAll('${{', '{{'))
}

const backendOptions = {
  loadPath: '{{lng}}|{{ns}}',
  request: (options: HttpBackendOptions, url: string, payload: string, callback: RequestCallback) => {
    loadLanguageResources()
      .then((response) => {
        callback(null, {
          data: response,
          status: 200,
        })
      })
      .catch(() => {
        import(`./locales/en-US/translation.json`)
          .then((res) => {
            callback(null, {
              data: JSON.stringify(res.default),
              status: 200,
            })
          })
          .catch((err) => {
            console.error(err)
          })
      })
  },
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    fallbackLng: localStorage.getItem('i18nextLng') ?? 'en-US',
    lng: localStorage.getItem('i18nextLng') ?? 'en-US',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage']
    }
  })

export default i18n
