import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { IAcceptableUsePolicy } from 'features/subscriptionRequest/components/acceptableUsePolicy'
import {
  ICountry,
  IPackage,
  IPackageProduct,
  IPrivacy,
  IProduct,
  IProductGroup,
  IProductPayload,
  IState,
  ITaxDetailsPayload,
  ITaxes,
} from 'features/subscriptionRequest/constants/subscription'
import { ISubscriptionRequestState, IUserInfo } from 'features/subscriptionRequest/slice/interfaces'
import { LocalizationState } from 'sharedSlices/localization/constants'
import baseApi from 'services/api/baseApi'
import { geoApi } from 'services/api/geoApi'

export const fetchProductGroup = createAsyncThunk<IProductGroup[], string, { rejectValue: AxiosError }>(
  'subscriptionRequest/fetchProductGroup',
  async (productGroupShortName: string, { rejectWithValue }) => {
    try {
      return await geoApi.getAllGeo(`product-group/${productGroupShortName}?includes=Country`)
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchProduct = createAsyncThunk<IProduct, IProductPayload, { rejectValue: AxiosError }>(
  'subscriptionRequest/fetchProduct',
  async ({ productId, jurCode }, { rejectWithValue }) => {
    try {
      return await geoApi.withJurisdictionCode(jurCode).get<IProduct>(`product/${productId}`)
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchProductPackages = createAsyncThunk<IPackageProduct, IProductPayload, { rejectValue: AxiosError }>(
  'subscriptionRequest/fetchProductPackages',
  async ({ productId, jurCode }, { rejectWithValue }) => {
    try {
      const response = await geoApi
        .withJurisdictionCode(jurCode)
        .get<Promise<IPackage[]>>(`product/${productId}/package`)
      return {
        id: productId,
        packages: response,
      }
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchProducts = createAsyncThunk<IProduct[], IProductPayload[], { rejectValue: AxiosError }>(
  'subscriptionRequest/fetchProducts',
  async (jurisdictionProducts, { rejectWithValue }) => {
    try {
      return await Promise.all(
        jurisdictionProducts.map(({ jurCode, productId }) =>
          geoApi.withJurisdictionCode(jurCode).get<IProduct>(`product/${productId}`)
        )
      ).then((responses) => responses.flat())
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchProductPackageGroups = createAsyncThunk<
  IPackageProduct[],
  IProductPayload[],
  { rejectValue: AxiosError }
>('subscriptionRequest/fetchProductPackageGroups', async (jurisdictionProducts, { rejectWithValue }) => {
  try {
    return await Promise.all(
      jurisdictionProducts.map(({ jurCode, productId }) =>
        geoApi.withJurisdictionCode(jurCode).get<Promise<IPackage[]>>(`product/${productId}/package`)
      )
    ).then((responses) => responses.flatMap((response) => ({ id: response[0].productId, packages: response })))
  } catch (err) {
    return rejectWithValue(err as AxiosError)
  }
})

export const fetchAcceptableUsePolicy = createAsyncThunk<IAcceptableUsePolicy, string, { rejectValue: AxiosError }>(
  'subscriptionRequest/fetchAcceptableUsePolicy',
  async (productId: string, { rejectWithValue, getState }) => {
    try {
      const state = getState() as {
        localization: LocalizationState
        subscriptionRequest: ISubscriptionRequestState
      }
      const localeCode = state.localization.languageSelected.localeCode
      const jurCode = state.subscriptionRequest.subscription.country!.abbreviation
      return await geoApi.withJurisdictionCode(jurCode).get<IAcceptableUsePolicy>(`product/${productId}/termsOfUse`, {
        headers: {
          'x-locale-code': localeCode,
        },
      })
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchAcceptableUsePolicies = createAsyncThunk<IAcceptableUsePolicy[], void, { rejectValue: AxiosError }>(
  'subscriptionRequest/fetchAcceptableUsePolicies',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as {
        localization: LocalizationState
        subscriptionRequest: ISubscriptionRequestState
      }
      const localeCode = state.localization.languageSelected.localeCode
      const countries = state.subscriptionRequest.countries.filter((country) => {
        return state.subscriptionRequest.subscription.products.find((product) => product.id === country.productId)
      })
      const _jurisdictionProducts = countries.map((country) => ({
        productId: country.productId,
        jurCode: country.abbreviation,
      }))
      return await Promise.all(
        _jurisdictionProducts.map(({ jurCode, productId }) =>
          geoApi.withJurisdictionCode(jurCode).get<IAcceptableUsePolicy>(`product/${productId}/termsOfUse`, {
            headers: {
              'x-locale-code': localeCode,
            },
          })
        )
      ).then((responses) => responses.flat())
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchPrivacy = createAsyncThunk<IPrivacy, string, { rejectValue: AxiosError }>(
  'subscriptionRequest/fetchPrivacy',
  async (productId: string, { rejectWithValue, getState }) => {
    try {
      const state = getState() as {
        localization: LocalizationState
        subscriptionRequest: ISubscriptionRequestState
      }
      const localeCode = state.localization.languageSelected.localeCode
      const jurCode = state.subscriptionRequest.subscription.country!.abbreviation
      return await geoApi.withJurisdictionCode(jurCode).get<IPrivacy>(`product/${productId}/privacyText`, {
        headers: {
          'x-locale-code': localeCode,
        },
      })
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchStates = createAsyncThunk<IState[], number, { rejectValue: AxiosError }>(
  'subscriptionRequest/fetchStates',
  async (countryId: number, { rejectWithValue }) => {
    try {
      return await baseApi.get<IState[]>(`country/${countryId}/state`)
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchBillingStates = createAsyncThunk<IState[], number, { rejectValue: AxiosError }>(
  'subscriptionRequest/fetchBillingStates',
  async (countryId: number, { rejectWithValue }) => {
    try {
      return await baseApi.get<IState[]>(`country/${countryId}/state`)
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchCountries = createAsyncThunk<ICountry[], void, { rejectValue: AxiosError }>(
  'subscriptionRequest/fetchCountries',
  async (_, { rejectWithValue }) => {
    try {
      return await baseApi.get<ICountry[]>('country')
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const getUserInfo = createAsyncThunk<IUserInfo, string, { rejectValue: AxiosError }>(
  'subscriptionRequest/getUserInfo',
  async (email: string, { rejectWithValue }) => {
    try {
      const userData = await geoApi.getAllGeo<IUserInfo>(`users/email/${email}`)
      return userData[0]
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchTaxes = createAsyncThunk<
  ITaxes,
  {
    jurCode: string
    taxDetails: ITaxDetailsPayload
  },
  { rejectValue: AxiosError }
>('subscriptionRequest/fetchTaxes', async ({ taxDetails, jurCode }, { rejectWithValue }) => {
  try {
    const headers = {
      'x-product-id': taxDetails.productId,
    }
    // TODO: If account details' country does not belong to jur, don't send the request
    const response = await geoApi
      .withJurisdictionCode(jurCode)
      .post<ITaxes>('Checkout/calculation/tax', taxDetails, { headers })

    return {
      total: response.total,
      rate: response.rate,
      productId: taxDetails.productId,
    }
  } catch (err) {
    return rejectWithValue(err as AxiosError)
  }
})
