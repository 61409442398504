import { Box, Divider, Typography } from '@mui/material'
import { CalendarRange, CalendarType } from 'components/DateRangePicker/constants'
import { TFunction } from 'i18next'
import { Fragment } from 'react'
import colors from 'theme/colors'
interface Props<T> {
  date?: Date
  dateThreshold?: Date
  dateMemo?: Date
  data: T[][]
  type: CalendarType
  value: T
  range: CalendarRange
  onChange: (value: T, range: CalendarRange) => void
  translation?: TFunction<'translation', undefined>
}

export const Grid = <T extends string | number>({
  date,
  dateMemo,
  data,
  type,
  value,
  range,
  onChange,
  translation,
}: Props<T>) => {
  return (
    <Box
      sx={{
        padding: '0 10px',
        height: 'calc(100% - 64px)',
      }}
    >
      {data.map((row, index) => {
        return (
          <Fragment key={row[0]}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: 'calc(100% / 4)',
              }}
            >
              {row.map((item, innerIndex) => {
                let isDisabled = false
                if (type === CalendarType.Decades) {
                  const isFirstDate = index === 0 && innerIndex === 0
                  const isLastDate = index === 3 && innerIndex === 2

                  isDisabled = isFirstDate || isLastDate
                }
                return (
                  <Typography
                    key={item}
                    fontSize='12px'
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '64px',
                      height: '34px',
                      margin: '0 auto',
                      backgroundColor:
                        type === CalendarType.Months
                          ? !isDisabled &&
                            value === item &&
                            dateMemo!.getFullYear() === date!.getFullYear()
                            ? colors.deepCerulean
                            : ''
                          : !isDisabled && value === item
                          ? colors.deepCerulean
                          : '',
                      borderRadius: '4px 4px',
                      cursor: isDisabled ? 'not-allowed' : '',
                      color:
                        type === CalendarType.Months
                          ? value === item && dateMemo!.getFullYear() === date!.getFullYear()
                            ? colors.white
                            : ''
                          : isDisabled
                          ? colors.grey[100]
                          : value === item
                          ? colors.white
                          : '',

                      ':hover': !isDisabled
                        ? {
                            background: colors.blue[600],
                            color: colors.white,
                            cursor: 'pointer',
                          }
                        : {},
                    }}
                    onClick={() => {
                      if (isDisabled) {
                        return
                      }

                      onChange(item, range)
                    }}
                  >
                    {type === CalendarType.Months ? translation!(item as string) : item}
                  </Typography>
                )
              })}
            </Box>
            {index !== 3 && <Divider />}
          </Fragment>
        )
      })}
    </Box>
  )
}
