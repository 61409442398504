import React, { useCallback, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { goNext, setActivePolicy, setActiveStep } from '../../slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import {
  ProductFeature,
  ProductGroupType,
  SubscriptionAction,
} from 'features/subscriptionRequest/constants/subscription'
import { selectIsValidAccountDetails, selectRestrictedCountry } from 'features/subscriptionRequest/slice/selectors'
import { useSetBillingInfoAfterReset } from 'features/subscriptionRequest/hooks/useSetBillingInfoAfterReset'
import { usePaymentDetailsSteps } from 'features/subscriptionRequest/hooks/usePaymentDetailsSteps/usePaymentDetailsSteps'
import { StepStatus } from 'features/subscriptionRequest/constants/stepper'
import { zuoraService } from 'features/subscriptionRequest/services/zoura'

const enum Step {
  SubscriptionInit = 1,
  SelectPackage,
  AcceptableUsePolicy,
}

interface Props {
  onCancelSubscription: () => void
  onSubmitSubscription: () => void
  subscriptionAction: SubscriptionAction
  isContinueDisabled: boolean
  onValidateEmail?: (targetStep: number) => void
}

const footerStyles = {
  width: '100%',
  height: '68px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px',
  backgroundColor: 'common.white',
  boxShadow: 'inset 0 1px 0',
  color: 'grey.100',
}

export const Footer: React.FC<Props> = ({
  onCancelSubscription,
  onSubmitSubscription,
  subscriptionAction,
  onValidateEmail,
  isContinueDisabled,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [search] = useSearchParams()

  const subscribeToProduct = Boolean(search.get('subscribeToProduct'))

  const roles = useAppSelector((state) => state.auth.employeeInformation?.roles)

  const hasAdminRole = Boolean(roles?.length)

  const { productGroup, steps, activeStep, acceptableUsePolicies, activePolicyIndex, paymentDetailsSteps } =
    useAppSelector((state) => state.subscriptionRequest)
  const { endUserInformation } = useAppSelector((state) => state.auth)

  const isValidAccountDetails = useAppSelector(selectIsValidAccountDetails)
  const { handleRestoreBillingDetails } = useSetBillingInfoAfterReset()
  const { handleResetInitialPaymentSteps, stepsValidationMap } = usePaymentDetailsSteps()
  const restrictedCountry = useAppSelector(selectRestrictedCountry)
  const isSubmitBtnDisabled = useMemo(
    () =>
      restrictedCountry ||
      paymentDetailsSteps.some((step) => step.status === StepStatus.Disabled) ||
      Object.values(stepsValidationMap).includes(false),
    [stepsValidationMap, restrictedCountry, paymentDetailsSteps]
  )

  const onContinue = () => {
    if (
      subscriptionAction === SubscriptionAction.CreateSubscription &&
      activeStep === Step.SubscriptionInit &&
      !endUserInformation
    ) {
      onValidateEmail?.(Step.SelectPackage)
    } else if (activeStep === Step.AcceptableUsePolicy && activePolicyIndex + 1 < acceptableUsePolicies.length) {
      dispatch(setActivePolicy(activePolicyIndex + 1))
    } else {
      dispatch(goNext())
      handleRestoreBillingDetails()
      handleResetInitialPaymentSteps()
    }
  }

  const onBack = () => {
    if (activeStep === Step.AcceptableUsePolicy && activePolicyIndex > 0) {
      dispatch(setActivePolicy(activePolicyIndex - 1))
    } else {
      dispatch(setActiveStep(activeStep - 1))
    }
  }

  const onSubmit = () => {
    const features = productGroup.products.flatMap((_product) => _product.features)
    if (features.includes(ProductFeature.Payment)) {
      zuoraService.submit()
    } else {
      onSubmitSubscription()
    }
  }

  const onBackToSubscriptions = useCallback(() => navigate(-1), [navigate])

  const isSubmitButtonDisabled =
    productGroup.type === ProductGroupType.All ? isSubmitBtnDisabled : !isValidAccountDetails

  return (
    <Box sx={footerStyles}>
      <Box sx={{ display: 'flex' }}>
        {hasAdminRole && subscribeToProduct ? (
          <Button sx={{ marginRight: '16px' }} variant='text' onClick={onBackToSubscriptions}>
            {t('button.backToSubscriptions')}
          </Button>
        ) : null}
      </Box>

      {(subscriptionAction === SubscriptionAction.EditSubscription || activeStep !== Step.SubscriptionInit) &&
        (!hasAdminRole || !subscribeToProduct) && (
          <Button sx={{ marginRight: 'auto' }} variant='text' onClick={onCancelSubscription}>
            {t('button.cancel')}
          </Button>
        )}

      <Box sx={{ display: 'flex' }}>
        {activeStep !== Step.SubscriptionInit && (
          <Button sx={{ marginRight: '16px' }} variant='outlined' onClick={onBack}>
            {t('button.back')}
          </Button>
        )}

        {activeStep === steps.length ? (
          <Button disabled={isSubmitButtonDisabled} variant='contained' onClick={onSubmit}>
            {t('button.submit')}
          </Button>
        ) : (
          <Button disabled={isContinueDisabled} variant='contained' onClick={onContinue}>
            {t('button.continue')}
          </Button>
        )}
      </Box>
    </Box>
  )
}
