import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, MenuItem, Typography } from '@mui/material'
import {
  IPackage,
  IProductTerm,
  ITerm,
  SelectedPackageDuration,
} from 'features/subscriptionRequest/constants/subscription'
import { CustomArrowTooltip } from 'components/CustomTooltip'
import { InfoIcon } from 'components/icons/Info.icon'
import { CustomSelect } from 'components/CustomSelect'
import colors from 'theme/colors'

interface Props {
  subscriptionPackage: IPackage
  terms: ITerm[]
  selectedTerms: IProductTerm
  isDisabled?: boolean
  subscribedDuration?: SelectedPackageDuration
  onSelectDuration?: (subscriptionId: string, termId: string) => void
}

export const Duration: FC<Props> = ({
  subscriptionPackage,
  isDisabled,
  terms,
  selectedTerms,
  onSelectDuration,
  subscribedDuration,
}) => {
  const { t } = useTranslation()

  const selectedTermsId = useMemo(() => {
    if (subscribedDuration) {
      return subscribedDuration.term.id
    }
    if (selectedTerms[subscriptionPackage.id]) {
      return selectedTerms[subscriptionPackage.id].id
    }
    return terms?.length === 1 ? terms[0].id : null
  }, [isDisabled, selectedTerms, subscriptionPackage.id, terms])

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation()
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
      }}
    >
      <CustomArrowTooltip placement='top' title={t('subscriptionRequest.packages.term.tooltip')}>
        <Box sx={{ pointerEvents: 'all', width: '24px', height: '24px' }}>
          <InfoIcon sx={{ fill: colors.blue.main }} />
        </Box>
      </CustomArrowTooltip>
      <Typography
        sx={{
          m: '0 20px 0 4px',
          fontSize: '14px',
          fontWeight: '600',
          cursor: 'default',
          color: isDisabled ? 'grey.800' : 'common.black',
        }}
      >
        {t('subscriptionRequest.packages.term.label')}
      </Typography>
      <CustomSelect
        sx={{
          width: '96px',
          pointerEvents: 'auto',
          '&&& .Mui-disabled': {
            color: 'grey.800',
            WebkitTextFillColor: colors.mainGrey,
          },
          '&&& .MuiSelect-select': {
            paddingTop: '7px',
            paddingBottom: '7px',
            '&.Mui-disabled': {
              backgroundColor: 'menuItemHover.main',
            },
          },
        }}
        disabled={isDisabled || terms?.length === 1}
        value={selectedTermsId}
        onChange={(event) => onSelectDuration!(subscriptionPackage.id, event.target.value)}
        placeholder={t('subscriptionRequest.packages.term.placeholder')!}
        selectedTemplated={() => {
          const { duration } = selectedTerms[subscriptionPackage.id] || terms[0]
          const yearText = duration > 1 ? 'manageProduct.subscriptions.years' : 'manageProduct.subscriptions.year'
          return (
            <Box
              sx={{
                color: 'common.black',
                WebkitTextFillColor: colors.black.main,
              }}
            >
              {`${duration} ${t(yearText).toLowerCase()}`}
            </Box>
          )
        }}
      >
        {terms.map((term) => (
          <MenuItem key={term.id} value={term.id}>
            {term.duration} {t(`manageProduct.subscriptions.year${term.duration > 1 ? 's' : ''}`).toLowerCase()}
          </MenuItem>
        ))}
      </CustomSelect>
    </Box>
  )
}
