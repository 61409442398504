export const HttpErrorCodes = {
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  InternalServerError: 500,
} as const;

type HttpStatusCode = keyof typeof HttpErrorCodes;

type HttpStatusMessage = {
  [key in HttpStatusCode]: string;
};

export const HttpStatusText: HttpStatusMessage = {
  BadRequest: 'errors.httpStatus.badRequest',
  Unauthorized: 'errors.httpStatus.unauthorized',
  Forbidden: 'errors.httpStatus.forbidden',
  NotFound: 'errors.httpStatus.notFound',
  InternalServerError: 'errors.httpStatus.internalServerError',
};