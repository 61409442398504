import { ReactNode, useEffect, useState } from 'react'
import { Box, LinearProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ExcelIcon } from 'components/icons/Excel.icon'
import colors from 'theme/colors'
import { UploadStatus, UploadStatusType } from 'constants/uploadStates'

interface IProps {
  fileName: string
  icon?: ReactNode
  message?: ReactNode
  variant?: UploadStatusType
}

export const FileUploadStatus = ({ fileName, icon, message, variant }: IProps) => {
  const { t } = useTranslation()
  const [stateText, setStateText] = useState('')

  useEffect(() => {
    if (variant === UploadStatus.uploading) return setStateText('common.uploadStatus.uploading')
    if (variant === UploadStatus.error) return setStateText('common.uploadStatus.failed')
    if (variant === UploadStatus.success) return setStateText('common.uploadStatus.success')
    return setStateText('common.selectionStatus.selected')
  }, [variant])

  const handleProgressBar = () => {
    if (variant === UploadStatus.error)
      return <LinearProgress color='error' variant='determinate' value={100} />
    if (variant === UploadStatus.success)
      return <LinearProgress color='success' variant='determinate' value={100} />
    if (variant === UploadStatus.uploading)
      return <LinearProgress color='primary' variant='indeterminate' value={100} />
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: '12px' }}>
      <Box sx={{ display: 'flex', mb: '8px' }}>
        <ExcelIcon sx={{ color: colors.green[600] }} />
        <Typography variant='body2' ml={'4px'}>
          {fileName} {t(stateText)}
        </Typography>
        {icon}
      </Box>
      {handleProgressBar()}
      {message && message}
    </Box>
  )
}
