import { SvgIcon, SvgIconProps } from '@mui/material'

export const CollapseAllIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.2886 10.9533L12.3719 10.9834L12.4542 10.9979L12.5206 10.9996L12.5887 10.9921L12.691 10.9622L12.767
        10.9228L12.8221 10.8824L16.3536 7.35355C16.5488 7.15829 16.5488 6.84171 16.3536 6.64645C16.18 6.47288 15.9106
        6.4536 15.7157 6.58859L15.6464 6.64645L13 9.293V2.5C13 2.25454 12.8231 2.05039 12.5899 2.00806L12.5 2C12.2545 2
        12.0504 2.17688 12.0081 2.41012L12 2.5V9.293L9.35355 6.64645C9.17999 6.47288 8.91056 6.4536 8.71569
        6.58859L8.64645 6.64645C8.47288 6.82001 8.4536 7.08944 8.58859 7.28431L8.64645 7.35355L12.1779 10.8824L12.2153
        10.9112L12.2886 10.9533ZM12.4101 21.9919L12.5 22C12.7455 22 12.9496 21.8231 12.9919 21.5899L13
        21.5V14.708L15.6464 17.3536C15.8417 17.5488 16.1583 17.5488 16.3536 17.3536C16.5488 17.1583 16.5488 16.8417
        16.3536 16.6464L12.8536 13.1464L12.8117 13.109L12.7498 13.0667L12.6906 13.0376L12.6281 13.0166L12.5463
        13.0021L12.4796 13.0004L12.4112 13.0079L12.309 13.0378L12.233 13.0772L12.1779 13.1176L8.64645 16.6464C8.45118
        16.8417 8.45118 17.1583 8.64645 17.3536C8.84171 17.5488 9.15829 17.5488 9.35355 17.3536L12 14.708V21.5C12
        21.7455 12.1769 21.9496 12.4101 21.9919Z'
      />
    </SvgIcon>
  )
}
