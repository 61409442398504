import { pca, loginRequest } from 'services/auth/authConfig'
import { InteractionRequiredAuthError } from '@azure/msal-browser'

const getAccountInfo = () => {
  const accounts = pca.getAllAccounts()
  return accounts[0]
}

export const getToken = async () => {
  const account = getAccountInfo()
  try {
    if (account) {
      const token = await pca.acquireTokenSilent({
        account,
        scopes: loginRequest.scopes,
      })
      return token.idToken
    }
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      pca.acquireTokenRedirect({ scopes: loginRequest.scopes, loginHint: account.username })
    }
  }
}
