import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import RowActions from 'components/rowActions'

interface Props {
  isAccessGranted: boolean
  onDelete: () => void
  onEdit: () => void
  onExport: () => void
}

export const MyReportsActions: FC<Props> = ({ isAccessGranted,onDelete, onEdit, onExport }) => {
  const { t } = useTranslation()

  return (
    <RowActions>
      {isAccessGranted &&
        <>
          <RowActions.Export title={t('reports.myReports.tooltip.export')} onClick={onExport} />
          <RowActions.Edit title={t('reports.myReports.tooltip.edit')} onClick={onEdit} />
        </>
      }
      <RowActions.Remove title={t('reports.myReports.tooltip.delete')} onClick={onDelete} />
    </RowActions>
  )
}
