import { ChangeEvent, FC, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import CustomModal from 'components/CustomModal'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { CloseIcon } from 'components/icons/Close.icon'
import { UploadToCloudIcon } from 'components/icons/UploadToCloud.icon'
import { PdfIcon } from 'components/icons/Pdf.icon'
import { ReloadIcon } from 'components/icons/Reload.icon'
import { CheckThinIcon } from 'components/icons/CheckThin.icon'
import { DocumentIcon } from 'components/icons/Document.icon'
import { HtmlIcon } from 'components/icons/Html.icon'
import { CsvIcon } from 'components/icons/Csv.icon'
import { FileStatus, fileStatusColor, FileFormatType, IFile, FileType, fileFormats } from 'constants/file'
import colors from 'theme/colors'

interface Props {
  acceptedFileTypes: FileType[]
  open: boolean
  maxFileSizeMB?: number
  title?: string
  failedMessage?: string
  uploadFile?: (file: File) => Promise<void>
  uploadFileSuccess?: () => void
  uploadFileError?: () => void
  onClose?: () => void
  onFinish?: () => void
  onSave?: (file: IFile) => void
}

export const FileUploadPopup: FC<Props> = ({
  title,
  acceptedFileTypes,
  maxFileSizeMB,
  open,
  failedMessage,
  uploadFile,
  uploadFileSuccess,
  uploadFileError,
  onClose,
  onFinish,
  onSave,
}) => {
  const { t } = useTranslation()
  const reader = new FileReader()
  const [selectedFile, setSelectedFile] = useState<IFile | null>(null)
  const [fileLoading, setFileLoading] = useState(false)
  const [fileName, setFileName] = useState('')
  const [fileType, setFileType] = useState('')
  const [progress, setProgress] = useState(0)
  const [status, setStatus] = useState<FileStatus>(FileStatus.Uploading)
  const [isDragOver, setIsDragOver] = useState(false)
  const [progressVariant, setProgressVariant] = useState<'indeterminate' | 'determinate'>(
    uploadFile ? 'indeterminate' : 'determinate'
  )
  const dragAndDrop = useRef(null)

  const acceptedFileFormatTypes = useMemo(() => {
    return acceptedFileTypes.map((type) => fileFormats[type])
  }, [acceptedFileTypes])

  const inputAcceptedFileTypes = useMemo(() => {
    return acceptedFileTypes.map((type) => `.${type}`).join(', ')
  }, [acceptedFileTypes])

  const handleFileUpload = (file: File) => {
    const maxFileSize = maxFileSizeMB && maxFileSizeMB * 1024 * 1024

    setFileName(file.name)
    setFileType(file.type)
    setFileLoading(true)

    if (
      (maxFileSize && file.size > maxFileSize) ||
      !acceptedFileFormatTypes.includes(file.type as FileFormatType) ||
      file.type === FileFormatType.Pdf
    ) {
      setIsDragOver(false)
      setProgress(100)
      setStatus(FileStatus.Failed)
      setProgressVariant('determinate')
      uploadFileError && uploadFileError()
    } else {
      if (uploadFile) {
        setProgress(100)
        uploadFile(file)
          .then(() => {
            setStatus(FileStatus.Uploaded)
            setProgressVariant('determinate')
            uploadFileSuccess && uploadFileSuccess()
          })
          .catch(() => {
            setStatus(FileStatus.Failed)
            setProgressVariant('determinate')
            uploadFileError && uploadFileError()
          })
      } else {
        reader.addEventListener('progress', (e) => {
          setProgress(Math.round((e.loaded * 100) / e.total))
        })
        reader.addEventListener('loadend', () => {
          setSelectedFile({
            name: file.name,
            type: file.type,
            data: reader.result!,
          })
          setTimeout(() => setStatus(FileStatus.Uploaded), 500)
          setIsDragOver(false)
        })
        reader.readAsText(file)
      }
    }
  }

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]
      handleFileUpload(file)
    }
  }

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragOver(true)
  }

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (event.target === dragAndDrop.current) {
      setIsDragOver(false)
    }
  }

  const onDrop = (event: React.DragEvent) => {
    const file = event.dataTransfer.files[0]
    event.preventDefault()
    event.stopPropagation()
    handleFileUpload(file)
  }

  const backToInitialState = () => {
    setFileLoading(false)
    setStatus(FileStatus.Uploading)
    setProgress(0)
    uploadFile && setProgressVariant('indeterminate')
  }

  const handleSave = () => {
    onSave && onSave(selectedFile!)
    backToInitialState()
  }

  const handleOnClose = () => {
    onClose && onClose()
    backToInitialState()
  }

  const handleOnFinish = () => {
    onFinish && onFinish()
    backToInitialState()
  }

  return (
    <CustomModal width='640px' open={open}>
      <CustomModal.Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
          borderBottom: `1px solid ${colors.border}`,
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
          }}
        >
          {title || t('fileUploadPopup.title')}
        </Typography>
        {(!uploadFile || !fileLoading) && (
          <Button
            sx={{
              height: '24px',
              width: '24px !important',
              minWidth: '24px',
              padding: '0',
            }}
            onClick={handleOnClose}
          >
            <CloseIcon />
          </Button>
        )}
      </CustomModal.Header>
      <CustomModal.Body
        style={{
          padding: '16px',
        }}
      >
        {fileLoading ? (
          <Box
            sx={{
              height: '260px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                margin: '12px 0 8px 0',
              }}
            >
              {acceptedFileFormatTypes.includes(fileType as FileFormatType) ? (
                {
                  [FileFormatType.Pdf]: <PdfIcon />,
                  [FileFormatType.Html]: <HtmlIcon />,
                  [FileFormatType.Csv]: <CsvIcon />,
                }[fileType]
              ) : (
                <DocumentIcon />
              )}

              <Box display='flex'>
                <Box fontFamily='Open Sans' maxWidth='450px' fontSize='14px' margin='0 4px'>
                  <OverflowTooltip text={fileName} />
                </Box>
                <Typography fontSize='14px'>{t(`fileUploadPopup.status.${status}`)}</Typography>
              </Box>
              <Box sx={{ marginLeft: 'auto', height: '24px' }}>
                {
                  {
                    [FileStatus.Uploading]: !uploadFile && (
                      <Button sx={{ minWidth: '24px', padding: '0' }} onClick={backToInitialState}>
                        <CloseIcon />
                      </Button>
                    ),
                    [FileStatus.Uploaded]: <CheckThinIcon sx={{ display: 'block' }} />,
                    [FileStatus.Failed]: (
                      <Button sx={{ minWidth: '24px', padding: '0' }} onClick={backToInitialState}>
                        <ReloadIcon />
                      </Button>
                    ),
                  }[status]
                }
              </Box>
            </Box>
            <LinearProgress
              sx={{
                borderRadius: '4px',
                marginBottom: '4px',
                backgroundColor: colors.quillGray,
                '.MuiLinearProgress-bar': {
                  borderRadius: '4px',
                  backgroundColor: fileStatusColor[status],
                },
              }}
              variant={progressVariant}
              value={progress}
            />
            {status === FileStatus.Failed && (
              <Typography variant='caption' color={colors.red}>
                {fileType === FileFormatType.Pdf
                  ? 'PDF uploading is not implemented yet.'
                  : failedMessage || t('fileUploadPopup.failedMessage')}
              </Typography>
            )}
          </Box>
        ) : (
          <Box
            ref={dragAndDrop}
            sx={{
              backgroundColor: colors.lightBlue,
              padding: '8px',
            }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={onDrop}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '256px',
                pointerEvents: isDragOver ? 'none' : 'auto',
                borderWidth: isDragOver ? '2px' : '1px',
                borderStyle: isDragOver ? 'solid' : 'dashed',
                backgroundColor: isDragOver ? 'common.white' : 'transparent',
                borderColor: 'primary.main',
              }}
            >
              <UploadToCloudIcon
                sx={{
                  width: '48px',
                  height: '48px',
                  color: 'primary.main',
                  marginBottom: '16px',
                }}
              />
              <Typography variant='subtitle2' fontWeight='600' lineHeight='24px' marginBottom='4px'>
                {t('fileUploadPopup.dragAndDrop')}
              </Typography>
              <Typography variant='caption' color='grey.700' lineHeight='16px'>
                {t('fileUploadPopup.fileFormat', { formats: acceptedFileTypes.join(', ') })}
              </Typography>
              {!!maxFileSizeMB && (
                <Typography variant='caption' color='grey.700' lineHeight='16px'>
                  {t('fileUploadPopup.fileSize', { size: maxFileSizeMB })}
                </Typography>
              )}
              <Button sx={{ mt: '16px' }} variant='text' component='label'>
                {t('fileUploadPopup.selectFile')}
                <input hidden type='file' accept={inputAcceptedFileTypes} onChange={onUpload} />
              </Button>
            </Box>
          </Box>
        )}
      </CustomModal.Body>
      <CustomModal.Footer
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '16px',
          padding: '16px',
        }}
      >
        {(!uploadFile || !fileLoading) && (
          <Button variant='outlined' onClick={handleOnClose}>
            {t('button.cancel')}
          </Button>
        )}

        {uploadFile ? (
          <>
            {status === FileStatus.Failed && (
              <Button
                sx={{
                  paddingLeft: '12px',
                  '.MuiSvgIcon-root': {
                    fontSize: '24px',
                  },
                }}
                startIcon={<ReloadIcon color='primary' />}
                variant='outlined'
                onClick={backToInitialState}
              >
                {t('button.tryAgain')}
              </Button>
            )}
            <Button variant='contained' disabled={status !== FileStatus.Uploaded} onClick={handleOnFinish}>
              {t('button.finish')}
            </Button>
          </>
        ) : (
          <Button variant='contained' disabled={status !== FileStatus.Uploaded} onClick={handleSave}>
            {t('button.save')}
          </Button>
        )}
      </CustomModal.Footer>
    </CustomModal>
  )
}
