import { Box, IconButton } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { CustomAlert } from 'components/CustomAlert'
import { CustomFormLabel } from 'components/CustomFormLabel'
import { CustomTextField } from 'components/CustomTextField'
import { NewTabIcon } from 'components/icons/NewTab.icon'
import { ProductSectionHeader } from 'features/manageProduct/components/ProductSectionHeader'
import {
  setIntegrations as setDispatchIntegrations,
  setIsEdit,
} from 'features/productConfiguration/slice'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'
import { getValidExternalURL } from 'utils/externalURL'

export const Integrations = () => {
  const [integrations, setIntegrations] = useState({
    homePageUrl: '',
    appId: '',
  })
  const [isValid, setIsValid] = useState(false)
  const { t } = useTranslation()
  const { productData, isEdit } = useAppSelector((state) => state.productConfiguration)
  const dispatch = useAppDispatch()
  const error = useRef({
    homePageUrl: {
      isValid: false,
    },
    appId: {
      isValid: false,
    },
  })

  const onEdit = useCallback(() => {
    dispatch(setIsEdit(true))
  }, [])

  const onSave = useCallback(() => {
    const isIntegrationsValid = Object.values(integrations).some((value) => !value.trim())
    if (isIntegrationsValid) {
      setIsValid(true)
      error.current = {
        homePageUrl: { isValid: !integrations.homePageUrl.trim() },
        appId: { isValid: !integrations.appId.trim() },
      }
    } else {
      dispatch(setDispatchIntegrations(integrations))
      dispatch(setIsEdit(false))
      setIsValid(false)
    }
  }, [dispatch, integrations])

  useEffect(() => {
    if (Object.keys(productData.integrations).length) {
      setIntegrations(productData.integrations)
      dispatch(setIsEdit(false))
    }
  }, [])

  const isEditMode = useMemo(() => {
    return isEdit || (!productData.integrations.appId && !productData.integrations.homePageUrl)
  }, [isEdit, productData.integrations])

  const isIntegrationsValid = useMemo(
    () => !isValid && Object.values(integrations).every((field) => !!field.length),
    [integrations, isValid]
  )

  const onChangeFields = useCallback(
    (field: string, value: string) => {
      setIntegrations((state) => {
        const newState = {
          ...state,
          [field]: value,
        }
        return newState
      })

      error.current = {
        ...error.current,
        [field]: { isValid: false },
      }

      if (isValid && Object.values(error.current).every((form) => !form.isValid)) {
        setIsValid(false)
      }
    },
    [isValid]
  )

  return (
    <Box>
      <ProductSectionHeader
        isEditMode={isEditMode}
        isSaveDisabled={!isIntegrationsValid}
        onEdit={onEdit}
        onSave={onSave}
      />
      <Box
        sx={{
          border: `1px solid ${colors.border}`,
          borderRadius: '4px',
          margin: '16px',
        }}
      >
        <Box
          sx={{
            padding: '16px',
            borderBottom: '1px',
            borderBottomColor: colors.border,
            borderBottomStyle: 'solid',
          }}
        >
          {t('productConfiguration.sections.integrations')}
        </Box>
        {isValid && (error.current.homePageUrl.isValid || error.current.appId.isValid) && (
          <CustomAlert severity='error' text={t('productConfiguration.form.completeRequired')!} />
        )}
        <Box margin='16px'>
          {isEditMode ? (
            <CustomTextField
              sx={{
                maxWidth: '800px',
                marginTop: '16px',
                '& p': {
                  fontWeight: isEdit ? 400 : 600,
                },
              }}
              error={error.current.homePageUrl.isValid}
              readonly={!isEditMode}
              label={t('productConfiguration.integrations.homePageUrl.label')!}
              placeholder={t('productConfiguration.integrations.homePageUrl.placeholder')!}
              value={integrations.homePageUrl}
              onChange={(e) => {
                onChangeFields('homePageUrl', e.target.value)
              }}
              multiline
              highlightOnFocus
            />
          ) : (
            <Box
              sx={{
                wordBreak: 'break-word',
              }}
            >
              <CustomFormLabel
                sx={{ mb: 0 }}
                value={t('productConfiguration.integrations.homePageUrl.label')!}
              />
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    minWidth: '368px',
                    maxWidth: '100%',
                    marginRight: '10px',
                  }}
                >
                  {integrations.homePageUrl}
                </Box>
                <IconButton
                  sx={{ p: '0 5px' }}
                  onClick={() => {
                    window.open(getValidExternalURL(integrations.homePageUrl), '_blank')
                  }}
                >
                  <NewTabIcon color='action' />
                </IconButton>
              </Box>
            </Box>
          )}
          <CustomTextField
            sx={{
              maxWidth: isEditMode ? '800px' : '100%',
              marginTop: '16px',
              minWidth: '368px',

              '& p': {
                fontWeight: isEditMode ? 400 : 600,
              },
            }}
            error={error.current.appId.isValid}
            limitation={200}
            readonly={!isEditMode}
            label={t('productConfiguration.integrations.appID.label')!}
            placeholder={t('productConfiguration.integrations.appID.placeholder')!}
            value={integrations.appId}
            onChange={(e) => {
              onChangeFields('appId', e.target.value)
            }}
            highlightOnFocus
            multiline
          />
        </Box>
      </Box>
    </Box>
  )
}
