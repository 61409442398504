import { useCallback } from 'react'
import { getSubscriptions } from '../slice'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { useGetUrlParams } from './useGetUrlParams'

export const useGetSubscriptions = () => {
  const dispatch = useAppDispatch()
  const { column, direction, size, page, productId, jurCode } = useGetUrlParams()
  const { result, total, hasValuations } = useAppSelector((state) => state.managePackage.subscriptions)

  const getSubscriptionInfo = useCallback(
    (proId?: string, jurId?: string) =>
      dispatch(
        getSubscriptions({
          productId: proId || productId || '',
          jurCode: jurId || jurCode || '',
          sortingInfo: { column, direction },
          paginationInfo: { size: size as number, page: page as number },
        })
      ),
    [column, direction, dispatch, page, size, jurCode, productId]
  )

  return {
    getSubscriptionInfo,
    subscriptionList: result,
    hasValuations,
    total,
  }
}
