import { ReactElement } from 'react'
import { TAccordionDetailsExpanded } from '../types'
import { AccordionDetails } from '@mui/material'

interface IBillingInfoAccordionSummaryProps {
  block: ReactElement | TAccordionDetailsExpanded
}

const BillingInfoAccordionSummary = ({ block }: IBillingInfoAccordionSummaryProps) => {
  if ('component' && 'sx' in block) {
    return <AccordionDetails sx={{ ...block.sx }}>{block.component}</AccordionDetails>
  }

  return <AccordionDetails>{block as ReactElement}</AccordionDetails>
}

export default BillingInfoAccordionSummary
