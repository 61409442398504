import { ReactNode } from 'react'
import { Box, IconButton, Typography } from '@mui/material'

import { DropperSelectedFileProps } from 'components/dropper'
import { FileUploadStatus } from '../excelUploadStatus'

import { CheckThinIcon } from 'components/icons/CheckThin.icon'
import { CloseIcon } from 'components/icons/Close.icon'

import { UploadStatusType } from 'constants/uploadStates'
import colors from 'theme/colors'
import { ReloadIcon } from 'components/icons/Reload.icon'

interface IFileSelected extends DropperSelectedFileProps {
  uploadStatus?: UploadStatusType
  errorMessage?: string
}

export const FileSelected = ({ errorMessage, file, uploadStatus, unselectFile }: IFileSelected) => {
  const IconsType: Record<UploadStatusType, ReactNode> = {
    error: (
      <IconButton sx={{ p: 0, ml: 'auto' }} onClick={unselectFile} data-testid='errbtnIcon'>
        <ReloadIcon sx={{ color: colors.black.main }} />
      </IconButton>
    ),
    uploading: '',
    success: (
      <IconButton sx={{ p: 0, ml: 'auto' }}>
        <CheckThinIcon sx={{ color: colors.green[600] }} />
      </IconButton>
    ),
  }

  const RemoveFileButton = () => (
    <IconButton sx={{ p: 0, ml: 'auto' }} onClick={unselectFile}>
      <CloseIcon />
    </IconButton>
  )

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <FileUploadStatus
        variant={uploadStatus}
        fileName={file.name}
        message={
          <Typography fontSize='12px' color={colors.red}>
            {errorMessage}
          </Typography>
        }
        icon={uploadStatus ? IconsType[uploadStatus] : <RemoveFileButton />}
      />
    </Box>
  )
}
