import { ChangeEvent, useState } from 'react'
import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DeloitteClientType } from 'features/subscriptionRequest/constants/subscription'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { CustomFormLabel } from 'components/CustomFormLabel'
import { RestrictionPopup } from 'features/subscriptionRequest/components/shared-components/RestrictionPopUp'
import { DartSupport } from 'constants/links'
import colors from 'theme/colors'
import { updatePaymentDetails } from 'features/subscriptionRequest/slice'

export const EligibilityCheck = ({ productId }: { productId: string }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    subscription: { paymentDetailsData },
  } = useAppSelector((state) => state.subscriptionRequest)

  const radioButtonsData = [
    {
      value: DeloitteClientType.Yes,
      label: t('paymentDetails.clientCheck.yes'),
      id: 0,
    },
    {
      value: DeloitteClientType.No,
      label: t('paymentDetails.clientCheck.no'),
      id: 1,
    },
  ]

  const handleToggleModal = () => setIsModalOpen((prev) => !prev)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (value === DeloitteClientType.Yes) handleToggleModal()
    dispatch(updatePaymentDetails({ key: 'isDeloittePartner', value: e.target.value, productId }))
  }

  return (
    <>
      <Box sx={{ mb: '16px' }}>
        <FormControl>
          <CustomFormLabel
            value={t('paymentDetails.clientCheck.question')}
            sx={{ mb: 0, '&.Mui-focused': { color: colors.mainGrey } }}
          />
          <RadioGroup
            row
            aria-labelledby='client-check-radio-buttons-group-label'
            defaultValue={DeloitteClientType.No}
            name='client-check-radio-buttons-group'
            value={paymentDetailsData[productId]?.isDeloittePartner || DeloitteClientType.No}
            onChange={handleChange}
          >
            {radioButtonsData.map(({ value, label, id }) => (
              <FormControlLabel
                sx={{ '& span': { fontSize: '14px', fontWeight: 400 } }}
                value={value}
                control={<Radio size='small' />}
                label={label}
                key={id}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <RestrictionPopup
        onClose={handleToggleModal}
        open={isModalOpen}
        link={`mailto:${DartSupport}`}
        title={t('paymentDetails.clientCheck.modal.title')}
        text={'paymentDetails.clientCheck.modal.text'}
        buttonText={t('paymentDetails.clientCheck.modal.button')}
        customLink={'Dart Support'}
        linkStyles={{ textDecoration: 'underline' }}
      />
    </>
  )
}
