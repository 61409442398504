import { SvgIcon, SvgIconProps } from '@mui/material'

export const RemoveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 8V19H16V8H8ZM10.5 10C10.7761 10 11 10.2239 11 10.5V16.5C11 16.7761
          10.7761 17 10.5 17C10.2239 17 10 16.7761 10 16.5V10.5C10 10.2239 10.2239
          10 10.5 10ZM13.5 10C13.7761 10 14 10.2239 14 10.5V16.5C14 16.7761 13.7761
          17 13.5 17C13.2239 17 13 16.7761 13 16.5V10.5C13 10.2239 13.2239 10 13.5
          10ZM13.7192 5H10.2808L9.78078 7H14.2192L13.7192 5ZM17 8V19.5C17 19.7455
          16.8231 19.9496 16.5899 19.9919L16.5 20H7.5C7.25454 20 7.05039 19.8231
          7.00806 19.5899L7 19.5V8H5.5C5.22386 8 5 7.77614 5 7.5C5 7.25454 5.17688
          7.05039 5.41012 7.00806L5.5 7H8.75L9.40532 4.37873C9.46096 4.15615 9.66095
          4 9.89039 4H14.1096C14.339 4 14.539 4.15615 14.5947 4.37873L15.25
          7H18.5C18.7455 7 18.9496 7.17688 18.9919 7.41012L19 7.5C19 7.74546 18.8231
          7.94961 18.5899 7.99194L18.5 8H17Z'
      />
    </SvgIcon>
  )
}
