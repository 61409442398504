import {
  IPackage,
  IPackageGroup,
  IProduct,
  ITerm,
} from 'features/subscriptionRequest/constants/subscription'

interface ExtendsIPackage extends IPackage {
  term?: ITerm
}

export const getPackageGroup = (packages: ExtendsIPackage[], products: IProduct[]) => {
  if (!packages.length) {
    return {
      packageGroups: null,
      groupedTerms: null,
    }
  }

  const groupedTerms = products
    .flatMap((product) => product.terms)
    .reduce((acc, term) => {
      if (!acc[term.packageId]) {
        acc[term.packageId] = [term]
      } else {
        acc[term.packageId].push(term)
      }

      return acc
    }, {} as { [key: string]: ITerm[] })

  const groupTermsByPackageId: { [key: string]: ITerm[] } = {}

  packages.forEach((_package) => {
    const packageId = _package.term ? _package.term.packageId : _package.id
    if (groupedTerms[packageId] && !groupTermsByPackageId[_package.id]) {
      groupTermsByPackageId[_package.id] = groupedTerms[packageId]
    }
  })

  return {
    packageGroups: packages.reduce(
      (packageGroups: { [key: string]: IPackageGroup }, packageItem: IPackage) => ({
        ...packageGroups,
        [packageItem.packageGroupName]: {
          productId: packageItem.productId,
          name: packageItem.packageGroupName,
          soldBy: packageItem.soldBy,
          terms: groupTermsByPackageId,
          packages: (packageGroups[packageItem.packageGroupName]?.packages || []).concat(
            packageItem
          ),
        },
      }),
      {}
    ),
    groupedTerms: groupTermsByPackageId,
  }
}
