import { useTranslation } from 'react-i18next'

const NotFound = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div>
      <h1>{t('errors.notFound.title')}</h1>
      <p>{t('errors.notFound.description')}</p>
    </div>
  )
}

export default NotFound
