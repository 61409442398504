import { FC } from 'react'
import { Box, FormLabel, SxProps } from '@mui/material'
import { CustomArrowTooltip } from 'components/CustomTooltip'
import { InfoIcon } from 'components/icons/Info.icon'
import colors from 'theme/colors'

interface Props {
  value: string
  required?: boolean
  error?: boolean
  sx?: SxProps
  infoTooltip?: string
}

export const CustomFormLabel: FC<Props> = ({ sx, value, infoTooltip, required, error }) => {
  return (
    <FormLabel
      sx={{
        marginBottom: '8px',
        fontFamily: 'Open Sans SemiBold',
        fontSize: '12px',
        fontWeight: '600',
        color: error ? 'error.main' : 'grey.800',
        '.MuiFormLabel-asterisk': {
          color: 'error.main',
        },
        ...sx,
      }}
      required={required}
    >
      {infoTooltip ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>{value}</Box>
          <CustomArrowTooltip placement='top' title={infoTooltip}>
            <Box sx={{ pointerEvents: 'all', width: '24px', height: '24px', ml: '4px' }}>
              <InfoIcon sx={{ fill: colors.blue.main }} />
            </Box>
          </CustomArrowTooltip>
        </Box>
      ) : (
        value
      )}
    </FormLabel>
  )
}
