import { SvgIcon, SvgIconProps } from '@mui/material'
export const ReloadIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.293 6L13.1464 8.14645C12.9512 8.34171 12.9512 8.65829 13.1464 8.85355C13.32 9.02712
          13.5894 9.0464 13.7843 8.91141L13.8536 8.85355L16.8536 5.85355C17.0271 5.67999 17.0464
          5.41056 16.9114 5.21569L16.8536 5.14645L13.8536 2.14645C13.6583 1.95118 13.3417 1.95118
          13.1464 2.14645C12.9729 2.32001 12.9536 2.58944 13.0886 2.78431L13.1464 2.85355L15.293
          5H12C8.21455 5 5.1309 8.00478 5.00406 11.7593L5 12C5 15.866 8.13401 19 12 19C15.866 19 19
          15.866 19 12C19 11.7239 18.7761 11.5 18.5 11.5C18.2239 11.5 18 11.7239 18 12C18 15.3137
          15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.7616 8.56557 6.12243 11.7751 6.00414L12
          6H15.293L13.1464 8.14645L15.293 6Z'
        />
      </SvgIcon>
    </>
  )
}
