import { IProduct, IProductGroup } from 'features/subscriptionRequest/constants/subscription'
import { createAsyncThunk, createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { AxiosError } from 'axios'
import baseApi from 'services/api/baseApi'
import { geoApi } from 'services/api/geoApi'
import { IManageProductState, IPackageRole, DEFAULT_PAGINATION, ISubscription } from 'features/manageProduct/constants'
import { IPaginationInfo, ITableData } from 'components/CustomTable'
import { ISortingInfo } from 'constants/sorting'

export const initialManageProductState: IManageProductState = {
  productGroups: [],
  products: [],
  selectedProductGroup: null,
  selectedProduct: null,
  packageRoles: [],
  subscriptions: [],
  totalSubscriptions: 0,
  pagination: DEFAULT_PAGINATION,
  loading: false,
  error: null,
}

// TODO: Auth implementation will replace this line
const employeeEmail = localStorage.getItem('employeeEmail')!
const requestHeaders: RequestHeaders = {
  headers: {
    'x-employee-email': employeeEmail,
  },
}

export const fetchProductGroups = createAsyncThunk<IProductGroup[], void, { rejectValue: AxiosError }>(
  'manageProduct/fetchProductGroups',
  async (arg, { rejectWithValue }) => {
    try {
      return await geoApi.getAllGeo('product-group', requestHeaders)
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchProducts = createAsyncThunk<IProduct[], string, { rejectValue: AxiosError }>(
  'manageProduct/fetchProducts',
  async (productGroupId, { rejectWithValue }) => {
    try {
      return await baseApi.get(`global/employees/current/product-group/${productGroupId}/product`, requestHeaders)
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchSubscriptions = createAsyncThunk<
  ITableData<ISubscription>,
  {
    productId: string
    jurCode: string
    sortingInfo: ISortingInfo
    paginationInfo: IPaginationInfo
  },
  { rejectValue: AxiosError }
>(
  'manageProduct/fetchSubscriptions',
  async ({ productId, jurCode, sortingInfo, paginationInfo }, { rejectWithValue }) => {
    try {
      return await geoApi.withJurisdictionCode(jurCode).post(
        'subscriptions',
        {
          sort: sortingInfo,
          pagination: {
            size: paginationInfo.size,
            number: paginationInfo.page,
          },
        },
        {
          headers: { ...requestHeaders.headers, 'x-product-id': productId },
        }
      )
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchPackageRoles = createAsyncThunk<IPackageRole[], string, { rejectValue: AxiosError }>(
  'manageProduct/fetchPackageRoles',
  async (productId, { rejectWithValue }) => {
    try {
      return await baseApi.get(`product/${productId}/role`, requestHeaders)
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const manageProductSlice = createSlice({
  name: 'manageProduct',
  initialState: initialManageProductState,
  reducers: {
    setProduct: (state, action: PayloadAction<IProduct>) => {
      state.selectedProduct = action.payload
    },
    setProductGroup: (
      state,
      action: PayloadAction<{ selectedProductGroup: IProductGroup; isResetSubscriptions: boolean }>
    ) => {
      state.selectedProduct = null
      state.selectedProductGroup = action.payload.selectedProductGroup
      if (action.payload.isResetSubscriptions) {
        state.subscriptions = []
        state.totalSubscriptions = 0
      }
    },
    setPagination: (state, action: PayloadAction<IPaginationInfo>) => {
      state.pagination = {
        page: action.payload.page,
        size: action.payload.size,
      }
    },
    resetManageProductState: () => ({ ...initialManageProductState }),
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductGroups.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchProductGroups.fulfilled, (state, action) => {
        state.productGroups = action.payload.sort((a, b) => a.name.localeCompare(b.name))
        state.loading = false
        if (action.payload.length === 1) {
          state.selectedProductGroup = action.payload[0]
        }
      })
      .addCase(fetchProductGroups.rejected, (state, action) => {
        state.error = action.payload?.message
        state.loading = false
      })
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = action.payload.sort((a, b) => {
          return a.jurisdiction!.name.localeCompare(b.jurisdiction!.name)
        })
        state.loading = false
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.error = action.payload?.message
        state.loading = false
      })
      .addCase(fetchSubscriptions.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.subscriptions = action.payload.result
        state.totalSubscriptions = action.payload.total
        state.loading = false
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.error = action.payload?.message
        state.loading = false
      })
      .addCase(fetchPackageRoles.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchPackageRoles.fulfilled, (state, action) => {
        state.packageRoles = action.payload
        state.loading = false
      })
      .addCase(fetchPackageRoles.rejected, (state, action) => {
        state.error = action.payload?.message
        state.loading = false
      })
  },
})

export const { setProduct, setProductGroup, setPagination, resetManageProductState } = manageProductSlice.actions

export const selectPermissionsByProduct = createSelector(
  (state: RootState) => state.manageProduct.selectedProduct,
  (selectedProduct: IProduct | null) => (selectedProduct ? selectedProduct.permissions ?? [] : [])
)

export default manageProductSlice.reducer
