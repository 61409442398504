import { FC, useMemo, useCallback } from 'react'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomModal from 'components/CustomModal'
import { BusinessUnitForm } from 'features/subscriptionRequest/components/subscriptionInit/components/BusinessUnitForm'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { DeloitteBusinessUnitType, IDeloitteBusinessUnit } from 'features/subscriptionRequest/constants/subscription'
import { handleBusinessUnitModal } from 'sharedSlices/ui'
import { setBusinessUnits } from 'features/subscriptionRequest/slice'
import { CloseIcon } from 'components/icons/Close.icon'
import { CustomAlert } from 'components/CustomAlert'

interface Props {
  onClose: () => void
  open: boolean
  indexProp: number | null
  onResetIndex: () => void
}

export const BusinessUnitPopup: FC<Props> = ({ onClose, open, indexProp, onResetIndex }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const {
    subscription: { businessUnits },
  } = useAppSelector((state) => state.subscriptionRequest)

  const handleUnitFieldChange = useCallback(
    (index: number, field: keyof IDeloitteBusinessUnit, value: string | DeloitteBusinessUnitType) => {
      const updatedUnits = businessUnits.map((unit, i) =>
        i === index
          ? {
              ...unit,
              [field]: field === 'type' && value === '' ? null : value,
              errors: { ...unit.errors, [field]: false },
            }
          : unit
      )

      dispatch(setBusinessUnits(updatedUnits))
    },
    [businessUnits]
  )

  const handleBlurFieldValidate = useCallback(
    (index: number, field: keyof IDeloitteBusinessUnit['errors'], isValid: boolean) => {
      const updatedUnits = businessUnits.map((unit, i) =>
        i === index ? { ...unit, errors: { ...unit.errors, [field]: !isValid } } : unit
      )
      dispatch(setBusinessUnits(updatedUnits))
    },
    [businessUnits]
  )

  const isBusinessUnitsValid = useMemo(() => {
    return businessUnits.every((unit) => {
      return unit.name.trim() && unit.email.trim() && !unit.errors.name && !unit.errors.email
    })
  }, [businessUnits])

  const onConfirm = () => {
    dispatch(handleBusinessUnitModal(false))
    onResetIndex()
  }

  return (
    <CustomModal open={open} onClose={onClose}>
      <CustomModal.Header>
        <Box sx={{ marginRight: 'auto' }}>
          {indexProp !== null
            ? t('subscriptionInit.isDeloitteClient.businessUnitPopup.headerEdit')
            : t('subscriptionInit.isDeloitteClient.businessUnitPopup.headerAdd')}
        </Box>
        <Button
          sx={{
            minHeight: '24px',
            minWidth: '24px',
            padding: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </CustomModal.Header>
      {businessUnits.some((unit) => unit.errors.name || unit.errors.email) && (
        <CustomAlert
          sx={{ position: 'sticky', top: 0, zIndex: 1 }}
          severity='error'
          text={t(
            businessUnits.some((unit) => unit.errors.name)
              ? 'subscriptionInit.isDeloitteClient.name.error'
              : 'subscriptionInit.isDeloitteClient.email.error'
          )}
        />
      )}
      <CustomModal.Body style={{ p: '0 16px' }}>
        <Box sx={{ paddingBottom: '16px' }}>
          <BusinessUnitForm
            index={indexProp !== null ? indexProp : businessUnits.length - 1}
            data={indexProp !== null ? businessUnits[indexProp] : businessUnits[businessUnits.length - 1]}
            onChangeUnitField={handleUnitFieldChange}
            onBlur={handleBlurFieldValidate}
          />
        </Box>
      </CustomModal.Body>
      <CustomModal.Footer
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          padding: '16px',
        }}
      >
        <Button variant='outlined' onClick={onClose}>
          {t('button.cancel')}
        </Button>
        <Button
          sx={{
            marginLeft: '16px',
          }}
          variant='contained'
          disabled={!isBusinessUnitsValid}
          onClick={() => onConfirm()}
        >
          {t('manageProduct.users.confirm')}
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  )
}
