import colors from 'theme/colors'

export interface IFile {
  name: string
  type: string
  data: ArrayBuffer | string
}

export enum FileStatus {
  Failed = 'Failed',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
}

export enum FileType {
  Html = 'HTML',
  Pdf = 'PDF',
  Csv = 'CSV',
}

export enum FileFormatType {
  Html = 'text/html',
  Pdf = 'application/pdf',
  Csv = 'text/csv',
}

export const fileFormats = {
  [FileType.Html]: FileFormatType.Html,
  [FileType.Pdf]: FileFormatType.Pdf,
  [FileType.Csv]: FileFormatType.Csv,
}

export const fileStatusColor: Record<FileStatus, string> = {
  [FileStatus.Uploading]: 'primary.main',
  [FileStatus.Failed]: colors.red,
  [FileStatus.Uploaded]: 'success.600',
}