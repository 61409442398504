import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { IDeloitteBusinessUnit } from 'features/subscriptionRequest/constants/subscription'
import { BusinessUnitPopup } from 'features/subscriptionRequest/components/subscriptionInit/components/BusinessUnitPopup'
import ChipsList from 'features/subscriptionRequest/components/subscriptionInit/components/ChipsList'
import { PlusIcon } from 'components/icons/Plus.icon'

export const BusinessUnitManager: React.FC<{
  businessUnits: IDeloitteBusinessUnit[]
  isOpen: boolean
  onAdd: () => void
  onEdit: (index: number) => void
  onDelete: (index: number) => void
  onClose: () => void
  selectedIndex: number | null
  onResetIndex: () => void
}> = ({ businessUnits, isOpen, onAdd, onEdit, onDelete, onClose, selectedIndex, onResetIndex }) => {
  const { t } = useTranslation()

  return (
    <>
      <BusinessUnitPopup open={isOpen} onClose={onClose} indexProp={selectedIndex} onResetIndex={onResetIndex} />
      {!!businessUnits.length && (
        <ChipsList<IDeloitteBusinessUnit>
          list={businessUnits}
          onEdit={onEdit}
          onDelete={onDelete}
          getLabel={(item) => (item.type ? `${item.name} (${item.type})` : `${item.name}`)}
        />
      )}
      <Button
        sx={{
          m: '16px auto 0 0',
          pl: '4px',
          fontSize: '12px',
          '.MuiButton-startIcon>*:nth-of-type(1)': {
            fontSize: '24px',
          },
        }}
        startIcon={<PlusIcon />}
        onClick={onAdd}
      >
        {t('subscriptionInit.isDeloitteClient.businessUnitPopup.headerAdd')}
      </Button>
    </>
  )
}
