import { Getter } from '@tanstack/react-table'
import { useAppSelector } from 'app/hooks'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { IPackage } from 'features/managePackage/constants'
import { getDateFormat } from 'sharedSlices/auth'
import { formatDate } from 'utils/date'

export const SubmittedDate = ({ getValue }: { getValue: Getter<IPackage> }) => {
  const dateFormat = useAppSelector(getDateFormat)

  return <OverflowTooltip text={formatDate(getValue().submittedDate, dateFormat)} />
}
