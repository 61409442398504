import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IPackage } from 'features/managePackage/constants'
import { OverflowTooltip } from 'components/OverflowTooltip'

import { PackageStatus, statusColor } from 'features/manageProduct/constants'
import { Getter } from '@tanstack/react-table'

export const SubscriptionStatus = ({ getValue }: { getValue: Getter<IPackage> }) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }} data-testid={`status-${status}`}>
      <Box
        sx={{
          flexShrink: 0,
          backgroundColor: statusColor[getValue().status as PackageStatus],
          borderRadius: '50%',
          width: '8px',
          height: '8px',
          marginRight: '8px',
        }}
        data-testid='status-color'
      ></Box>
      <OverflowTooltip text={t(`manageProduct.status.${getValue().status}`)} />
    </Box>
  )
}
