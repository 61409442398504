import { useCallback } from 'react'
import { TAccordionData } from '../../components/paymentDetails/types'
import {
  BillingComponentsKeys,
  PaymentDetailsBlockTypes,
  STEPS_VALUES,
} from '../../components/paymentDetails/constants'
import { AccountDetails } from '../../components/accountDetails'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'app/hooks'
import { usePaymentDetailsSteps } from '../usePaymentDetailsSteps/usePaymentDetailsSteps'
import PaymentFooter from '../../components/paymentDetails/components/PaymentFooter'
import { StepStatus } from '../../constants/stepper'
import { TPaymentDetailsData } from './types'
import { billingInfoFooterStyles, startingStepForProducts } from './constants'
import { PaymentDetailsByProducts } from 'features/subscriptionRequest/components/paymentDetails/components/paymentDetailsByProducts'

export const usePaymentDetailsData = ({ setExpanded }: TPaymentDetailsData) => {
  const { t } = useTranslation()
  const {
    subscription: { country: selectedCountry },
    paymentDetailsSteps,
  } = useAppSelector((state) => state.subscriptionRequest)
  const { productsSortedBySoldBy, isButtonDisabled } = usePaymentDetailsSteps()
  const { fixedSteps } = STEPS_VALUES

  const isStepDisabled = useCallback(
    (currentStep: number) => {
      const stepStatus = paymentDetailsSteps.find((step) => step.stepNumber === currentStep)?.status
      return stepStatus === StepStatus.Disabled
    },
    [paymentDetailsSteps]
  )

  const paymentDetailsData = productsSortedBySoldBy.map(({ id, soldByTitle }, idx) => ({
    summary: t(`subscriptionPaymentDetailsAccordion.titleForProduct`, {
      soldByTitle,
    }),
    panel: idx + startingStepForProducts,
    details: [
      <PaymentDetailsByProducts
        key={id}
        setExpanded={setExpanded}
        isButtonDisabled={isButtonDisabled}
        id={id}
        idx={idx + startingStepForProducts}
      />,
      ...(idx + startingStepForProducts > fixedSteps && idx + startingStepForProducts < paymentDetailsSteps.length
        ? [
            {
              component: (
                <PaymentFooter
                  key={BillingComponentsKeys.PaymentFooter}
                  handleCollapseAccordion={setExpanded}
                  isClearBtnHidden
                  sx={{ justifyContent: 'flex-end' }}
                  isButtonDisabled={isButtonDisabled}
                />
              ),
              sx: billingInfoFooterStyles,
            },
          ]
        : []),
    ],
    disabled: isStepDisabled(idx + startingStepForProducts),
  }))

  const accordionData: TAccordionData = [
    {
      summary: t('subscriptionPaymentDetailsAccordion.accountDetails'),
      details: [
        <AccountDetails
          jurisdiction={selectedCountry}
          showPrivacyPolicy={false}
          isAllFormDisabled
          hasNoTitle
          key={BillingComponentsKeys.AccountDetails}
        />,
      ],
      panel: PaymentDetailsBlockTypes.AccountDetails,
    },
    {
      summary: t('subscriptionPaymentDetailsAccordion.billingInformation'),
      panel: PaymentDetailsBlockTypes.BillingDetails,
      details: [
        <AccountDetails
          jurisdiction={selectedCountry}
          showPrivacyPolicy={false}
          editMode
          isFromBillingInfo
          hasNoTitle
          customLastNameLabel={t('billingInfoDetails.lastName')}
          customFirstNameLabel={t('billingInfoDetails.firstName')}
          customEmailLabel={t('billingInfoDetails.email')}
          key={BillingComponentsKeys.BillingInfo}
        />,
        {
          component: (
            <PaymentFooter
              key={BillingComponentsKeys.PaymentFooter}
              handleCollapseAccordion={setExpanded}
              isButtonDisabled={isButtonDisabled}
            />
          ),
          sx: billingInfoFooterStyles,
        },
      ],
    },
    ...paymentDetailsData,
  ]
  return { accordionData }
}
