import { t } from 'i18next'
import { store } from 'app/store'

import { UserName } from '../components/userName'
import { IColumnType } from 'components/CustomTable'
import { ManageRowActions } from '../components/manageRowActions'

import { shortRoleNames } from 'constants/languageKeys'
import { ROLE_NAME, columnsByRole } from 'features/manageAdmins/constants'
import { IJurisdiction } from 'features/productConfiguration/constants'

import { formatDate } from 'utils/date'

interface ColumnInfo {
  employeeRoleId?: string
  firstName: string
  lastName: string
  fullPermissions: boolean
  email?: string
  jurisdiction?: IJurisdiction
  assignmentDate: string
  allowedPermissions: number[]
  roles?: number[]
  id: string
}

export const createColumns = <T extends ColumnInfo>(roleName: string): IColumnType<T>[] => {
  if (!ROLE_NAME[roleName]) return []
  return columnsByRole[roleName].columns.map((column: IColumnType<T>) => {
    const { key } = column
    if (key === 'userName') {
      return {
        ...column,
        render: (_: IColumnType<T>, item: T) => <UserName firstName={item.firstName} lastName={item.lastName} />,
      }
    }
    if (key === 'adminName') {
      return {
        ...column,
        render: (_: IColumnType<T>, item: T) => (
          <UserName firstName={item.firstName} lastName={item.lastName} fullPermissions={item.fullPermissions} />
        ),
      }
    }
    if (key === 'actions') {
      return {
        ...column,
        render: (_: IColumnType<T>, item: T) => (
          <ManageRowActions
            allowedPermissions={item.allowedPermissions}
            id={item.employeeRoleId || item.id}
            jurCode={item.jurisdiction?.code}
          />
        ),
      }
    }
    if (key === 'assignmentDate') {
      return {
        ...column,
        render: (_: IColumnType<T>, item: T) => {
          const dateFormat = store.getState().auth.employeeInformation?.profileConfig?.dateTimeFormat
          return formatDate(item.assignmentDate, dateFormat)
        },
      }
    }
    if (key === 'roles') {
      return {
        ...column,
        render: (_: IColumnType<T>, item: T) => item.roles?.map((roleId) => t(shortRoleNames[roleId])).join(', '),
      }
    }
    if (key === 'jurisdiction') {
      return {
        ...column,
        render: (_: IColumnType<T>, item: T) => item.jurisdiction?.name,
      }
    }
    return column
  })
}
