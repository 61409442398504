import { enqueueSnackbar } from 'notistack'

type Variant = 'default' | 'error' | 'success' | 'warning' | 'info' | 'mySnackbar' | undefined

interface MessageType {
  [key: string]: {
    message: string
  }
}

export const typeErrorMessage: MessageType = {
  manageAdmins: {
    message: 'manageAdmins.message.error',
  },
  subscriptions: {
    message: 'manageProduct.subscriptionMessage.error',
  },
}

export const showMessage = ({
  error: (variant: Variant, message: string, title?: string) =>
    enqueueSnackbar(message, {
      variant,
      title: title || 'manageAdmins.title.error',
      severity: 'error',
    }),
  success: (variant: Variant, message: string, title?: string) =>
    enqueueSnackbar(message, {
      variant,
      title: title || 'manageAdmins.title.success',
      severity: 'success',
    }),
})
