import { FC } from 'react'
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomModal from 'components/CustomModal'
import { IPackage, ISubscriptionDetails, statusColor } from 'features/manageProduct/constants'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { formatDate } from 'utils/date'
import { useAppSelector } from 'app/hooks'
import { getDateFormat } from 'sharedSlices/auth'
import { CloseIcon } from 'components/icons/Close.icon'
import { EditProductIcon } from 'components/icons/EditProduct.icon'

interface Props {
  onClose: () => void
  open: boolean
  subscriptionDetails: ISubscriptionDetails
}

export const SubscriptionDetailsPopup: FC<Props> = ({ onClose, open, subscriptionDetails }) => {
  const { selectedProduct } = useAppSelector((state) => state.manageProduct)
  const dateFormat = useAppSelector(getDateFormat)
  const { countryNameAbbreviation, stateNameAbbreviation } = subscriptionDetails
  const { t } = useTranslation()
  const navigate = useNavigate()

  const durationValuations = (item: IPackage) => {
    if (item.term) {
      return `${item.term.duration} ${
        item.term.duration > 1 ? t('manageProduct.subscriptions.years') : t('manageProduct.subscriptions.year')
      }`
    }
    return `${item.availableLicenseQuantity} of ${item.licenseQuantity}`
  }

  return (
    <CustomModal open={open} onClose={onClose}>
      <CustomModal.Header>
        <Box sx={{ marginRight: 'auto' }}>{t('subscriptionDetailsPopup.header')}</Box>
        <Button
          variant='text'
          sx={{ marginRight: '16px' }}
          startIcon={<EditProductIcon />}
          onClick={() => {
            navigate({
              pathname: `/subscribe/subscription/${subscriptionDetails.id}/account-details`,
              search: `?productId=${selectedProduct!.id}&jurCode=${selectedProduct!.jurisdiction!.code}`,
            })
          }}
        >
          {t('productConfiguration.buttons.edit')}
        </Button>
        <Button
          sx={{
            minHeight: '24px',
            minWidth: '24px',
            padding: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </CustomModal.Header>
      <CustomModal.Body style={{ padding: 0, overflowY: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '16px',
            padding: '16px',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            lineHeight: '20px',
            '&>div': {
              width: '31.5%',
              '.title': {
                color: 'grey.800',
                fontSize: '10px',
                letterSpacing: '.8px',
                lineHeight: '16px',
                textTransform: 'uppercase',
                fontWeight: 700,
                marginBottom: '4px',
              },
            },
          }}
        >
          <Box>
            <Box className='title'>{t('subscriptionDetailsPopup.id')}</Box>
            <Box>{subscriptionDetails.trackingId}</Box>
          </Box>
          <Box>
            <Box className='title'>{t('subscriptionDetailsPopup.companyName')}</Box>
            <OverflowTooltip text={subscriptionDetails.companyName} />
          </Box>
          <Box>
            <Box className='title'>{t('subscriptionDetailsPopup.subscriberName')}</Box>
            <OverflowTooltip text={`${subscriptionDetails.firstName} ${subscriptionDetails.lastName}`} />
          </Box>
          <Box>
            <Box className='title'>{t('subscriptionDetailsPopup.subscriberEmail')}</Box>
            <OverflowTooltip text={subscriptionDetails.email} />
          </Box>
          <Box>
            <Box className='title'>{t('subscriptionDetailsPopup.submissionDate')}</Box>
            <Box>{formatDate(subscriptionDetails.packages[0].submittedDate, dateFormat)}</Box>
          </Box>
          <Box>
            <Box className='title'>{t('subscriptionDetailsPopup.country')}</Box>
            <Box>{t(`country.${countryNameAbbreviation.toLowerCase()}.name`)}</Box>
          </Box>
          <Box>
            <Box className='title'>{t('subscriptionDetailsPopup.state')}</Box>
            <OverflowTooltip
              text={t(`states.${countryNameAbbreviation.toLowerCase()}.${stateNameAbbreviation.toLowerCase()}`)}
            />
          </Box>
          <Box>
            <Box className='title'>{t('subscriptionDetailsPopup.city')}</Box>
            <Box>
              <OverflowTooltip text={subscriptionDetails.city} />
            </Box>
          </Box>
          <Box>
            <Box className='title'>{t('subscriptionDetailsPopup.address')}</Box>
            <Box>
              <OverflowTooltip text={subscriptionDetails.address1} />
            </Box>
          </Box>
          <Box>
            <Box className='title'>{t('subscriptionDetailsPopup.zipCode')}</Box>
            <Box>
              <OverflowTooltip text={subscriptionDetails.zipCode} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: '16px',
            padding: '12px',
          }}
        >
          <Table
            sx={{
              tableLayout: 'fixed',
              'th, td': {
                padding: '4px',
                border: 0,
              },
              th: {
                color: 'grey.800',
                fontSize: '10px',
                letterSpacing: '.8px',
                lineHeight: '16px',
                textTransform: 'uppercase',
                fontWeight: 700,
              },
              td: {
                lineHeight: '20px',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell width='141px'>{t('subscriptionDetailsPopup.packageName')}</TableCell>
                <TableCell width='138px'>{t('subscriptionDetailsPopup.status')}</TableCell>
                <TableCell width='130px'>{t('subscriptionDetailsPopup.reviewedBy')}</TableCell>
                <TableCell width='90px'>{t('subscriptionDetailsPopup.reviewDate')}</TableCell>
                <TableCell width='117px'>{t('subscriptionDetailsPopup.term')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionDetails.packages.map((_package) => (
                <TableRow key={_package.id}>
                  <TableCell>
                    <OverflowTooltip text={_package.name} />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          backgroundColor: statusColor[_package.status],
                          borderRadius: '50%',
                          width: '8px',
                          height: '8px',
                          flexShrink: 0,
                          marginRight: '8px',
                        }}
                      ></Box>
                      <OverflowTooltip text={t(`manageProduct.status.${_package.status}`)} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <OverflowTooltip text={_package.reviewedBy || '-'} />
                  </TableCell>
                  <TableCell>{_package.reviewDate ? formatDate(_package.reviewDate, dateFormat) : '-'}</TableCell>
                  <TableCell>{durationValuations(_package)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </CustomModal.Body>
    </CustomModal>
  )
}
