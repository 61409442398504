import { useCallback, useEffect, useMemo } from 'react'
import { Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import RowActions from 'components/rowActions'
import { CustomTable, IColumnType } from 'components/CustomTable'

import { UserName } from 'features/manageAdmins/components/userName'
import { changeSelectedValue, setSelectAll } from 'features/manageUsers/slices'
import {
  selectAllRegularUsers,
  selectAllRegularUsersSelected,
} from 'features/manageUsers/slices/manageUsers/selectors'

import { handleEditPrimaryUserModal } from 'sharedSlices/ui'

import {
  DEFAULT_USER_LIST_SORTING,
  REGULAR_USER,
  PRIMARY_USER,
  User,
  UserRoleKey,
} from 'features/manageUsers/constants'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getDateFormat } from 'sharedSlices/auth'

import { formatDate } from 'utils/date'
import { useSorting } from 'hooks'
import { Direction, ISortingInfo } from 'constants/sorting'

interface Props {
  data: User[]
}

type SortingConfig = {
  column: keyof User
  direction: Direction
}

export const ManageUsersTable = ({ data }: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const regularUserList = useAppSelector(selectAllRegularUsers)
  const regularUserSelectedList = useAppSelector(selectAllRegularUsersSelected)
  const dateFormat = useAppSelector(getDateFormat)
  const [searchParams, setSearchParams] = useSearchParams()

  const defaultSortingConfig = useMemo(
    () => ({
      column: (searchParams.get('sortBy') || DEFAULT_USER_LIST_SORTING.column) as keyof User,
      direction: (searchParams.get('direction') ||
        DEFAULT_USER_LIST_SORTING.direction) as Direction,
    }),
    [searchParams]
  )

  const { sortingConfig, sortedData, setSortingConfig } = useSorting<User>(
    data,
    defaultSortingConfig
  )

  const tableColumns: IColumnType<User>[] = [
    {
      key: 'userName',
      title: 'columns.userName',
      sorting: true,
      columStyle: { width: '15%' },
      render: (_, { firstName, lastName }) => (
        <UserName firstName={firstName} lastName={lastName} />
      ),
    },
    {
      key: 'email',
      title: 'columns.email',
      sorting: true,
      columStyle: { width: '15%' },
    },
    {
      key: 'role',
      title: 'columns.userRole',
      sorting: true,
      columStyle: { width: '15%' },
      render: (_, { role }) => t(`${UserRoleKey[String(role)]}`),
    },
    {
      key: 'dateAdded',
      title: 'columns.dateAdded',
      sorting: true,
      columStyle: { width: '15%' },
      render: (_, { dateAdded }) => {
        return dateAdded && formatDate(dateAdded, dateFormat)
      },
    },
    {
      key: 'lastLogin',
      title: 'columns.lastLogin',
      sorting: true,
      render: (_, { lastLogin }) => {
        return lastLogin && formatDate(lastLogin, dateFormat, true)
      },
    },
  ]

  const handleRowActions = (user: User) => {
    if (user.role === REGULAR_USER) return <></>
    return (
      <RowActions>
        <RowActions.Edit
          title={t('manageProduct.users.editPrimaryUser')}
          onClick={() => dispatch(handleEditPrimaryUserModal(true))}
        />
      </RowActions>
    )
  }

  const checkboxes = {
    parent: () => (
      <Checkbox
        size='medium'
        color='primary'
        checked={regularUserSelectedList.length === regularUserList.length}
        indeterminate={
          regularUserSelectedList.length < regularUserList.length &&
          regularUserSelectedList.length > 0
        }
        sx={{ p: 0, m: 0 }}
        onChange={(e) => dispatch(setSelectAll(Boolean(e.target.checked)))}
      />
    ),
    child: (user: User) => (
      <Checkbox
        size='medium'
        color='primary'
        disabled={user.role === PRIMARY_USER}
        checked={user.selected}
        sx={{ p: 0, m: 0 }}
        onChange={() => dispatch(changeSelectedValue(user.id))}
      />
    ),
  }

  const updateSearchParams = useCallback(
    ({ column, direction }: SortingConfig) => {
      setSearchParams((prevSearchParams: URLSearchParams) => {
        prevSearchParams.get('productId')
        prevSearchParams.set('sortBy', column)
        prevSearchParams.set('direction', direction)
        return prevSearchParams
      })
    },
    [setSearchParams]
  )

  const handleSort = useCallback(
    (sortingInfo: ISortingInfo) => {
      setSortingConfig({
        column: sortingInfo.column as keyof User,
        direction: sortingInfo.direction,
      })
      updateSearchParams({
        column: sortingInfo.column as keyof User,
        direction: sortingInfo.direction,
      })
    },
    [setSortingConfig, updateSearchParams]
  )

  useEffect(() => {
    // Set url params by default
    updateSearchParams({
      column: defaultSortingConfig.column,
      direction: defaultSortingConfig.direction,
    })
  }, [defaultSortingConfig, updateSearchParams])

  return (
    <>
      <CustomTable<User>
        columns={tableColumns}
        data={sortedData}
        actions={handleRowActions}
        sorting={sortingConfig}
        onSort={handleSort}
        overflowLayout
        checkboxesSelection={checkboxes}
      />
    </>
  )
}
