import { geoApi } from 'services/api/geoApi'
import {
  IPackageRole,
  IProcessPackage,
  ISubscriptionDetails,
  headers,
  IComment,
} from 'features/manageProduct/constants'

export const submitPackageApprove = (
  productId: string,
  subscriptionId: string,
  payload: { productRole?: IPackageRole; note: string; packages: IProcessPackage[] },
  jurCode: string
): Promise<void> => {
  return geoApi
    .withJurisdictionCode(jurCode)
    .post(`subscriptions/${subscriptionId}/approve`, payload, { ...headers })
}

export const submitChangeRole = (
  productId: string,
  subscriptionId: string,
  packageRole: IPackageRole,
  jurCode: string
): Promise<void> => {
  return geoApi
    .withJurisdictionCode(jurCode)
    .post(`subscriptions/${subscriptionId}/changeRole`, packageRole)
}

export const getSubscriptionDetails = (
  productId: string,
  subscriptionId: string,
  jurCode: string
): Promise<ISubscriptionDetails> => {
  return geoApi.withJurisdictionCode(jurCode).get(`subscriptions/${subscriptionId}/view`)
}

export const getSubscriptionComment = (
  subscriptionId: string,
  jurCode: string
): Promise<IComment> => {
  return geoApi.withJurisdictionCode(jurCode).get(`subscriptions/${subscriptionId}/comment`)
}
