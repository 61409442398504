import { FC, memo, useEffect } from 'react'
import { Box, Typography, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomForm from 'components/CustomForm'

import { IProductGroup } from '../../constants/interfaces'

interface Props {
  products: IProductGroup[]
  onChange: (productGroup: IProductGroup) => void
  setValue: (fieldName: string, param: string | string[] | number) => void
  setLinkedField?: (productGroup: IProductGroup) => void
}

const ProductField: FC<Props> = ({ setValue, setLinkedField, onChange, products }) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (products.length === 1) {
      setValue('productId', products[0].id)
      setLinkedField && setLinkedField(products[0])
    }
  }, [setValue, setLinkedField, products])

  return (
    <>
      {products.length > 0 && (
        <>
          <CustomForm.Label value='manageAdmins.product' />
          <CustomForm.Autocomplete<IProductGroup>
            id='product-auto'
            options={products}
            value={products.length === 1 ? products[0] : undefined}
            getOptionLabel={(product) => product.name}
            onChange={(e, product) => {
              if (product && !Array.isArray(product)) {
                setValue('productId', product.id)
                onChange(product)
              }
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, product) => (
              <Box component='span' {...props} key={product.id}>
                <Typography variant='body2'>{product.name}</Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ p: 0, m: 0 }}
                fullWidth
                name='product'
                placeholder={t('manageAdmins.productsPlaceHolder')!}
              />
            )}
          />
        </>
      )}
    </>
  )
}

export default memo(ProductField)
