import { IEditAccountDetailsPayload } from '../constants'
import { geoApi } from 'services/api/geoApi'

export const updateAccountDetails = (
  accountDetails: IEditAccountDetailsPayload,
  jurCode: string
): Promise<void> => {
  return geoApi
    .withJurisdictionCode(jurCode)
    .post('subscriptions/account-details/update', accountDetails)
}
