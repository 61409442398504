import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { getTotalPackagePrice, getTotalPrice } from 'features/subscriptionRequest/utils/getTotalPrice'
import { DiscountInfo } from 'features/subscriptionRequest/components/SubscriptionSummary/components/DiscountInfo'
import { IPackage } from 'features/subscriptionRequest/constants/subscription'
import { getFormatedPrice } from 'features/subscriptionRequest/utils/getFormatedPrice'
import colors from 'theme/colors'
import { selectPackageProducts } from 'features/subscriptionRequest/slice/selectors'

export const SubscriptionSummary: React.FC = () => {
  const { t } = useTranslation()
  const { subscription, commonTerms, licenses, commonTermId, type, productGroup, currencies } = useAppSelector(
    (state) => state.subscriptionRequest
  )
  const discounts = productGroup.products[0].productBulkDiscounts!

  const selectedPackageProducts = useAppSelector(selectPackageProducts)

  const isShowPrices = useMemo(() => {
    return subscription.products.some((product) => {
      return product.packages.some((selectedPackage) => licenses[selectedPackage.packageGroup.name])
    })
  }, [subscription.products, licenses])

  const selectedPackages = useCallback(
    (packages: IPackage[]) => {
      return packages
        .filter((_package) => licenses[_package.packageGroup.name])
        .sort((a, b) => b.name.localeCompare(a.name))
    },
    [licenses]
  )

  return (
    <Box
      sx={{
        width: '314px',
        height: '100%',
        boxSizing: 'border-box',
        position: 'sticky',
        borderLeft: `1px solid ${colors.border}`,
        overflowY: 'auto',
        top: 0,
      }}
    >
      <Box sx={{ height: '100%', display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, fontFamily: 'Open Sans' }}>
          <Box
            sx={{
              padding: '12px 16px',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '24px',
              color: isShowPrices ? 'common.black' : colors.silverChalice,
              borderBottom: '1px solid',
              borderColor: 'grey.100',
            }}
          >
            {t('subscriptionSummary.title')}
          </Box>
          {isShowPrices && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {selectedPackageProducts.map((product) => {
                const currency = currencies[product.id]
                const soldBy = product.packages[0].packageGroup.legalName
                const productItem = productGroup.products.find((_product) => product.id === _product.id)!
                const commonTerm = commonTerms.find((term) => term.id === commonTermId)!
                const jurCode = productItem.countries![0].abbreviation
                const totalPrice = getTotalPrice(product.packages, licenses, commonTerm, currency, discounts)

                return (
                  <Box
                    sx={{
                      width: '100%',
                      borderBottom: '1px solid',
                      borderColor: 'grey.100',
                      fontSize: '12px',
                      fontFamily: 'Open Sans Semibold',
                      lineHeight: '16px',
                    }}
                    key={product.id}
                  >
                    <Box
                      sx={{
                        padding: '12px 16px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                      }}
                    >
                      {selectedPackages(product.packages).map((_package) => {
                        const price = _package.prices.find((_price) => _price.currencyId === currency.id)!
                        const totalPackagePrice = getTotalPackagePrice(
                          price.amount,
                          licenses[_package.packageGroup.name],
                          discounts,
                          commonTerm
                        )

                        return (
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }} key={_package.id}>
                            <Box>{_package.name}</Box>
                            <Box>
                              {currency.symbol} {getFormatedPrice(totalPackagePrice)}
                            </Box>
                          </Box>
                        )
                      })}
                      <Box
                        sx={{
                          fontSize: '12px',
                          fontFamily: 'Open Sans',
                          lineHeight: '16px',
                          color: 'grey.800',
                          marginTop: '4px',
                        }}
                      >
                        {t('subscriptionRequest.packages.soldBy')} {soldBy}
                      </Box>
                    </Box>
                    <Box sx={{ padding: '8px 16px', backgroundColor: 'menuItemHover.main' }}>
                      <Box
                        sx={{ display: 'flex', fontSize: '14px', lineHeight: '24px', justifyContent: 'space-between' }}
                      >
                        <Box>{t('subscriptionSummary.total.title', { jurCode })}</Box>
                        <Box>
                          {currency.symbol} {getFormatedPrice(totalPrice)}
                        </Box>
                      </Box>
                      <Box sx={{ color: 'grey.800', fontFamily: 'Open Sans' }}>
                        {t('subscriptionSummary.total.excludingTaxes')}
                      </Box>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          )}
          {!!(commonTermId && type) && <DiscountInfo />}
        </Box>
      </Box>
    </Box>
  )
}
