import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { getPackageGroup } from 'features/subscriptionRequest/components/packageGroup/services/getPackageGroup'
import {
  DeloitteBusinessUnitType,
  IAccountDetails,
  IPackage,
  IPackageLicenses,
  IPackageProduct,
  IPackageValuation,
  IPrivacy,
  IProduct,
  IProductPaymentType,
  IProductTerm,
  IProductZuoraRefId,
  ISubscription,
  ITerm,
  LicenseType,
  ProductFeature,
  requiredAccountDetails,
  requiredBillingDetails,
  restrictedCountries,
} from 'features/subscriptionRequest/constants/subscription'
import { PrivacyViewType } from 'features/productConfiguration/constants'
import { emailRegEx } from 'features/subscriptionRequest/constants/email'
import { PaymentType } from 'features/subscriptionRequest/constants/payment'

export const selectPackageGroups = createSelector(
  (state: RootState) => state.subscriptionRequest.packages,
  (state: RootState) => state.subscriptionRequest.products,
  (packages: IPackage[], products: IProduct[]) => getPackageGroup(packages, products)
)

export const selectSubscriptionDetails = createSelector(
  (state: RootState) => state.subscriptionRequest.subscription,
  (state: RootState) => state.subscriptionRequest.terms,
  (state: RootState) => state.subscriptionRequest.commonTermId,
  (state: RootState) => state.subscriptionRequest.valuations,
  (state: RootState) => state.subscriptionRequest.licenses,
  (state: RootState) => state.subscriptionRequest.paymentTypes,
  (state: RootState) => state.subscriptionRequest.zuoraRefIds,
  (state: RootState) => state.subscriptionRequest.products,
  (
    _subscription: ISubscription,
    terms: IProductTerm,
    commonTermId: string | null,
    valuations: IPackageValuation,
    licenses: IPackageLicenses,
    paymentTypes: IProductPaymentType,
    zuoraRefIds: IProductZuoraRefId,
    products: IProduct[]
  ) => {
    const { state, ...accountDetails } = _subscription.accountDetails
    const features = products.flatMap((product) => product.features)
    const packageTerms = products.length && products[0].terms
    const singleTermId = (id: string) => packageTerms && packageTerms.find((term) => term.packageId === id)?.id
    const commonTerm = packageTerms && packageTerms.find((packageTerm) => packageTerm.id === commonTermId)
    const getCommonPackageTermId = (_package: IPackage) =>
      products
        .find((product) => product.id === _package.productId)!
        .terms.find((term) => term.packageId === _package.id && (commonTerm as ITerm).duration === term.duration)!.id

    return {
      account: {
        ...accountDetails,
        email: _subscription.email,
        stateId: state?.id as number,
        ...(features.includes(ProductFeature.Payment) && {
          phoneNumber: Number(_subscription.accountDetails.phone),
          dunsNumber: Number(_subscription.accountDetails.duns),
          address2: _subscription.accountDetails.address2,
        }),
        countryId: (_subscription.country?.id || accountDetails.country?.id) as number,
        ...(features.includes(ProductFeature.AuditClient)
          ? { isDeloitteAuditClient: _subscription.isDeloitteClient }
          : { isDeloitteClient: 1 }),
      },
      ...(features.includes(ProductFeature.Payment) && {
        billingInfo: {
          ..._subscription.billingDetails,
          id: crypto.randomUUID(),
          stateId: _subscription.billingDetails.state?.id as number,
          countryId: _subscription.billingDetails.country?.id as number,
          companyFullLegalName: _subscription.billingDetails.companyFullName,
          phoneNumber: Number(_subscription.billingDetails.phone),
          engagements: _subscription.businessUnits.map((unit) => ({
            id: crypto.randomUUID(),
            contactName: unit.name,
            contactEmail: unit.email,
            vendorBusinessUnit: unit.type && Object.values(DeloitteBusinessUnitType).indexOf(unit.type),
          })),
        },
      }),
      products: _subscription.products.map((_product) => ({
        id: _product.id,
        packages: _product.packages.map((_package) => ({
          id: _package.id,
          ...(_package.prices && { price: _package.prices[0] }),
          ...(_package.licenseType === LicenseType.SingleLicense && {
            packageTermId: terms[_package.id]?.id || singleTermId(_package.id),
          }),
          ...(_package.licenseType === LicenseType.TokenBased && {
            licenseQuantity: valuations[_package.id],
          }),
          ...(_package.licenseType === LicenseType.LicenseBased && {
            licenseQuantity: licenses[_package.packageGroupName],
            packageTermId: getCommonPackageTermId(_package),
          }),
        })),
        ...(features.includes(ProductFeature.Payment) && {
          payment: {
            type: paymentTypes[_product.id],
            zuoraPaymentMethod: paymentTypes[_product.id] === PaymentType.CreditCard ? zuoraRefIds[_product.id] : null,
            invoiceContactName: _subscription.paymentDetailsData[_product.id]?.invoiceDetails?.contact,
            invoiceContactEmail: _subscription.paymentDetailsData[_product.id]?.invoiceDetails?.email,
          },
        }),
      })),
    }
  }
)

export const selectIsValidBillingDetails = (state: RootState) => {
  const details = state.subscriptionRequest.subscription.billingDetails
  const detailsValues = new Map(Object.entries(details))

  const iBillingDetailsComplete = requiredBillingDetails.every((field) => {
    const fieldValue = detailsValues.get(field)
    if (!fieldValue) return false
    return typeof fieldValue === 'string' ? Boolean(fieldValue?.trim()) : Boolean(fieldValue)
  })

  return iBillingDetailsComplete && emailRegEx.test(detailsValues.get('email'))
}

export const selectIsValidAccountDetails = createSelector(
  (state: RootState) => state.subscriptionRequest.subscription.accountDetails,
  (state: RootState) => state.subscriptionRequest.privacy,
  (state: RootState) => state.subscriptionRequest.isPrivacyAccepted,
  (details: IAccountDetails, privacy: IPrivacy, isPrivacyAccepted: boolean) =>
    requiredAccountDetails.every((key: string) => {
      let isEmpty = true
      const detailsField = details[key as keyof IAccountDetails]

      if (!(typeof detailsField === 'object')) {
        isEmpty = !!detailsField!.trim()
      }

      return detailsField && isEmpty
    }) &&
    /^(?:\d{9})?$/.test(details.duns || '') &&
    (!details.country || !restrictedCountries.includes(details.country.name)) &&
    (privacy.privacyViewTypeId === PrivacyViewType.Link || isPrivacyAccepted)
)

export const selectRestrictedCountrySupportLink = createSelector(
  (state: RootState) => state.subscriptionRequest.subscription.accountDetails,
  (state: RootState) => state.subscriptionRequest.subscription.products,
  (state: RootState) => state.subscriptionRequest.commonTerms,
  (state: RootState) => state.subscriptionRequest.commonTermId,
  (state: RootState) => state.subscriptionRequest.licenses,
  (
    details: IAccountDetails,
    products: IPackageProduct[],
    commonTerms: ITerm[],
    commonTermId: string | null,
    licenses: IPackageLicenses
  ) => {
    const toTxt = 'dart@deloitte.com'
    const subjectTxt = '?subject=DART%20purchase%20from%20' + details.country!.name
    const htmlHdrTxt = '&body=Hello,%0d%0dI%20am%20trying%20to%20purchase:%20%0d'
    const { duration } = commonTerms.find((term) => term.id === commonTermId)!
    const terms = `${duration} Year${duration > 1 ? 's' : ''}`
    let htmlBodyTxt = ''

    products.forEach((item) =>
      item.packages.forEach((_package) => {
        htmlBodyTxt =
          htmlBodyTxt + '%0d' + licenses[_package.packageGroup.name] + '%20license(s)%20of%20' + _package.name
      })
    )

    const htmlFtrTxt = '.%20%0d%0dPlease%20contact%20me%20to%20assist%20in%20completing%20my%20purchase.'
    const bodyTxt = htmlHdrTxt + htmlBodyTxt + '.%20%0d' + terms + htmlFtrTxt

    return 'mailto:' + toTxt + subjectTxt + bodyTxt
  }
)

export const selectRestrictedCountry = (state: RootState) => {
  const selectedCountryBillingInfo = state.subscriptionRequest.subscription.billingDetails.country?.name as string
  const selectedCountrySubscription = state.subscriptionRequest.subscription.country?.name as string
  return (
    restrictedCountries.includes(selectedCountryBillingInfo) ||
    restrictedCountries.includes(selectedCountrySubscription)
  )
}

export const selectPackageProducts = (state: RootState) => {
  const selectedProducts = state.subscriptionRequest.subscription.products
  const { licenses, productGroup } = state.subscriptionRequest

  return [...selectedProducts]
    .filter((product) => product.packages.some((_package) => licenses[_package.packageGroup.name]))
    .sort((a, b) => {
      const productA = productGroup.products.find((product) => product.id === a.id)!
      const productB = productGroup.products.find((product) => product.id === b.id)!
      return productA.countries![0].name.localeCompare(productB.countries![0].name)
    })
}

export const selectPaymentDetailsStepIdx = (state: RootState) => {
  return state.subscriptionRequest.paymentDetailsSteps.findIndex(
    (item) => item.stepNumber === state.subscriptionRequest.paymentDetailsActiveStep
  )
}
