export enum PaymentDetailsBlockTypes {
  AccountDetails = 1,
  BillingDetails,
  FlexibleDetails,
}

export enum BillingComponentsKeys {
  AccountDetails = 'account__details',
  BillingInfo = 'billing__info',
  PaymentFooter = 'billing__footer',
}

export const PAYMENT_DETAILS_FIXED_STEPS = [
  PaymentDetailsBlockTypes.AccountDetails,
  PaymentDetailsBlockTypes.BillingDetails,
]

export const STEPS_VALUES = {
  fixedSteps: 2,
  nextStep: 1,
  stepsForOneProductGroup: 3,
}
