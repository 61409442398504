import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'app/hooks'
import { getTotalPackagePrice } from 'features/subscriptionRequest/utils/getTotalPrice'
import { Box, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { IPackageProduct } from 'features/subscriptionRequest/constants/subscription'
import { useProductSummaryData } from 'features/subscriptionRequest/hooks/useProductSummaryData'
import { getAdjustedPrice } from 'features/subscriptionRequest/utils/getAdjustedPrice'
import colors from 'theme/colors'
import { paymentTypeOptions } from 'features/subscriptionRequest/constants/payment'
import { PriceAdjustment } from 'features/subscriptionRequest/components/SubscriptionOverview/componetns/PriceAdjustment/PriceAdjustment'

const tableStyles: SxProps = {
  tableLayout: 'fixed',
  border: '1px solid',
  borderColor: 'grey.100',
  'th, td': {
    fontSize: '12px',
    lineHeight: '16px',
    padding: '12px 16px',
    borderBottom: '1px solid',
    borderColor: 'grey.100',
    width: '151px',
  },
  'th, td:first-of-type, td:last-of-type': {
    fontWeight: 600,
  },
}

interface ProductSummaryProps {
  product: IPackageProduct
}

export const ProductSummary: FC<ProductSummaryProps> = ({ product }) => {
  const { t } = useTranslation()
  const { productGroup, licenses, paymentTypes } = useAppSelector((state) => state.subscriptionRequest)
  const { selectedPackages, currency, commonTerm, soldBy } = useProductSummaryData(product)
  const paymentType = paymentTypeOptions.find((option) => option.value === String(paymentTypes[product.id]))!.label

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Typography variant='subtitle2' fontWeight={700} lineHeight='24px'>
        {t('subscriptionRequest.packages.soldBy')} {soldBy}
      </Typography>
      <Table sx={tableStyles}>
        <TableHead>
          <TableRow>
            <TableCell>{t('subscriptionSummary.overview.package')}</TableCell>
            <TableCell>{t('subscriptionSummary.overview.term')}</TableCell>
            <TableCell>{t('subscriptionSummary.overview.licenses')}</TableCell>
            <TableCell>{t('subscriptionSummary.overview.total')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedPackages(product.packages).map(({ id, prices, name, packageGroup }) => {
            const price = prices.find((packagePrice) => packagePrice.currencyId === currency.id)
            const totalPackagePrice = getTotalPackagePrice(
              price?.amount ?? 0,
              licenses[packageGroup.name],
              productGroup.products[0]?.productBulkDiscounts ?? [],
              commonTerm
            )

            return (
              <TableRow key={id}>
                <TableCell>
                  <OverflowTooltip text={name} />
                </TableCell>
                <TableCell>
                  <OverflowTooltip
                    text={`${commonTerm.duration} ${t(
                      `subscriptionSummary.overview.${commonTerm.duration === 1 ? 'year' : 'years'}`
                    )}`}
                  />
                </TableCell>
                <TableCell>
                  <OverflowTooltip text={licenses[packageGroup.name]} />
                </TableCell>
                <TableCell>
                  <OverflowTooltip text={getAdjustedPrice(totalPackagePrice, currency)} />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '600',
              color: colors.mainGrey,
              mb: '4px',
            }}
          >
            {t('subscriptionSummary.overview.paymentType')}
          </Typography>
          <Typography fontSize='14px' p='0'>
            <OverflowTooltip text={t(paymentType)} />
          </Typography>
        </Box>
        <PriceAdjustment product={product} />
      </Box>
    </Box>
  )
}
