import { useCallback, useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import { ProductConfigurationHeader } from '../ProductConfigurationHeader'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getProductGroups } from 'features/productConfiguration/services'
import ProductsConfigurationGrid from '../ProductConfigurationGrid'
import { IProductGroup } from 'features/productConfiguration/constants'
import { resetProductGroups } from 'features/productConfiguration/slice'
import { NoProducts } from 'features/productConfiguration/component/NoProducts'
import { useContainerScroll } from 'utils/scrollableFooter'
import colors from 'theme/colors'

export const ProductConfigurationLayout = () => {
  const dispatch = useAppDispatch()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const {
    productConfiguration: { products, loading },
  } = useAppSelector((state) => state)

  useEffect(() => {
    if (!products.length) {
      dispatch(getProductGroups())
    }

    return () => {
      dispatch(resetProductGroups())
    }
  }, [dispatch])

  const handlerCopyRegisterLink = useCallback((product: IProductGroup) => {
    navigator.clipboard.writeText(`${location.host}/subscribe/${product.shortName}`)
  }, [])

  useContainerScroll(containerRef, dispatch)

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
    >
      <ProductConfigurationHeader />
      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          height: '100%',
          backgroundColor: colors.menuItemHover,
          overflowY: 'auto',
        }}
      >
        {products.length ? (
          <ProductsConfigurationGrid products={products} onCopyRegisterLink={handlerCopyRegisterLink} />
        ) : (
          !loading && <NoProducts />
        )}
      </Box>
    </Box>
  )
}
