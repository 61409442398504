import { FC } from 'react'
import { Avatar, Box, Typography } from '@mui/material'
import colors from 'theme/colors'

interface Props {
  name: string
  lastName: string
  value?: string
  children?: React.ReactNode
}

export const CardEmail: FC<Props> = ({ name, lastName, value, children }) => {
  const titleInitials = name.charAt(0) + lastName.charAt(0)

  return (
    <Box component='div' sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        sx={{ width: '32px', height: '32px', fontSize: '14px', backgroundColor: colors.black.main }}
      >
        {titleInitials.toUpperCase()}
      </Avatar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          marginLeft: '8px',
        }}
      >
        <Typography variant='body2' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
          {name} {lastName}
        </Typography>
        <Typography variant='caption' sx={{ color: 'grey.500' }}>
          {value}
        </Typography>
      </Box>
      {children}
    </Box>
  )
}
