import { RootState } from 'app/store'
import { createSelector } from '@reduxjs/toolkit'

import { ManageUsersSlice } from '../constants'
import { User } from 'features/manageUsers/constants'
import { byRegularUser } from 'features/manageUsers/utils'

const selectUsersFromStore = (store: RootState): ManageUsersSlice => store.manageUsers

export const selectAllRegularUsers = createSelector(
  selectUsersFromStore,
  ({ users }: ManageUsersSlice): User[] => users.filter(byRegularUser)
)

export const selectAllRegularUsersSelected = createSelector(
  selectUsersFromStore,
  ({ users }: ManageUsersSlice): User[] =>
    users.filter(byRegularUser).filter((user) => user.selected)
)
