import { SxProps, Theme } from '@mui/material'

export const subscriptionListStyle: SxProps<Theme> = {
  table: {
    thead: {
      tr: {
        th: {
            '&.company-name': {
              width: '12.57%'
            },
            '&.jurisdiction': {
              width: '13.25%',
            },
            '&.user-name': {
              width: '14.95%',
            },
            '&.email': {
              width: '15.63%',
            },
            '&.submission-date': {
              width: '56.41%',
            },
        },
      },
    },
  },
}
