import { FC, ReactNode } from 'react'
import { Alert, AlertColor, SxProps, AlertPropsVariantOverrides } from '@mui/material'

import { WarningIcon } from 'components/icons/Warning.icon'
import { ErrorIcon } from 'components/icons/Error.icon'
import { InfoIcon } from 'components/icons/Info.icon'
import { SuccessIcon } from 'components/icons/Success.icon'
import { TipIcon } from 'components/icons/TipIcon.icon'

interface Props {
  text?: string
  severity?: AlertColor
  custom?: 'tip'
  sx?: SxProps
  children?: ReactNode | ReactNode[] | null
}

export const CustomAlert: FC<Props> = ({ sx, text, severity = 'warning', custom, children }) => {
  const getIcon = () => {
    switch (custom || severity) {
      case 'error':
        return <ErrorIcon color='error' />
      case 'info':
        return <InfoIcon color='info' variant='contained' />
      case 'success':
        return <SuccessIcon color='success' variant='contained' />
      case 'warning':
        return <WarningIcon color='warning' />
      case 'tip':
        return <TipIcon />
      default:
        return <ErrorIcon color='error' />
    }
  }

  return (
    <Alert
      sx={{
        height: '40px',
        boxShadow: 'inset 0px -1px 0px',
        alignItems: 'center',
        borderRadius: 0,
        padding: '0 16px',
        '.MuiAlert-icon': {
          marginRight: '8px',
        },
        '.MuiAlert-message': {
          color: 'common.black',
        },
        a: {
          color: 'common.black',
          fontWeight: '600',
          textDecoration: 'none',
        },
        ...sx,
      }}
      icon={getIcon()}
      variant={custom || 'standard'}
      severity={severity}
    >
      {children || text}
    </Alert>
  )
}
