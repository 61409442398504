export const APP_CONFIG = {
  development: {
    baseApiUrl: window.APP_CONFIG.API_URL,
    geoApiUrls: window.APP_CONFIG.API_URLS,
  },
  production: {
    baseApiUrl: window.APP_CONFIG.API_URL,
    geoApiUrls: window.APP_CONFIG.API_URLS,
  },
}
