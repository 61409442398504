import axios, { AxiosError } from 'axios'
import { getToken } from '../../auth/getToken'

import { APP_CONFIG } from 'app/appConfig'
import { showMessage, typeErrorMessage } from 'utils/errorMessageHandler'

import { HttpErrorCodes, HttpStatusText } from 'constants/httpStatusCodes'

const baseApiUrl =
  window.location.hostname === 'localhost'
    ? APP_CONFIG.development.baseApiUrl
    : APP_CONFIG.production.baseApiUrl

const httpClient = axios.create({
  baseURL: baseApiUrl,
})

httpClient.interceptors.request.use(
  async (config) => {
    const token = await getToken()
    const params = new URLSearchParams(window.location.search)
    const productId = params.get('productId')

    if (token) {
      config.headers!['Authorization'] = `Bearer ${token}`
    }

    if (productId && config.headers && !config.headers['x-product-id']) {
      config.headers!['x-product-id'] = productId
    }

    config.headers!['x-locale-code'] = localStorage.getItem('i18nextLng')

    return config
  },
  (error) => Promise.reject(error)
)

httpClient.interceptors.response.use(
  (response) => {
    return response
  },
  function (error: AxiosError) {
    if (error.response) {
      const featureOrigin = error.config?.headers!['x-action-origin']
      const errorMessage = typeErrorMessage[featureOrigin as string]?.message

      if (error.response.status === HttpErrorCodes.Forbidden) {
        showMessage.error('mySnackbar', errorMessage || HttpStatusText.Forbidden)
      }
      if (error.response.status === HttpErrorCodes.NotFound) {
        showMessage.error('mySnackbar', errorMessage || HttpStatusText.NotFound)
      }
      if (error.response.status >= HttpErrorCodes.InternalServerError) {
        window.location.assign('/servererror')
      }
    }
    return Promise.reject(error)
  }
)

export default {
  get<T>(url: string, headers?: RequestHeaders): Promise<T> {
    return httpClient.get(url, headers).then((response) => response.data)
  },
  post<T, P>(url: string, payload: P, headers?: RequestHeaders): Promise<T> {
    return httpClient.post(url, payload, headers).then((response) => response.data)
  },
  patch<T, P>(url: string, payload: P, headers?: RequestHeaders): Promise<T> {
    return httpClient.patch(url, payload, headers).then((response) => response.data)
  },
  delete<T>(url: string, headers?: RequestHeaders): Promise<T> {
    return httpClient.delete(url, headers).then((response) => response.data)
  },
  put<T>(url: string, headers?: RequestHeaders): Promise<T> {
    return httpClient.put(url, headers).then((response) => response.data)
  },
  getWithHeaders(url: string, headers?: RequestHeaders) {
    return httpClient.get(url, headers)
  },
}
