export const endpoints = {
  getEmployeesWithJurisdictionId:
    'global/employees/jurisdiction/:jurisdictionId/searchText/:searchTerm',
  getEmployeesWithProductGroupIdAndJurisdictionId:
    'global/employees/productGroup/:productGroupId/jurisdiction/:jurisdictionId/searchText/:searchTerm',
  getEmployees: 'global/employees/searchText/:searchTerm',
  getPermissions: 'global/permissions/role/:roleId',
  //demo user
  getDemoUsersWithProductGroupIdAndJurisdictionId:
    'global/demouser/productGroup/:productGroupId/jurisdiction/:jurisdictionId/searchText/:searchTerm',

}
