import { useEffect } from 'react'

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'

import { appInsights, reactPlugin } from 'services/appInsights/appInsights'

export const AppInsightsContainer = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => appInsights.trackPageView(), [])
  return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
}
