import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { getFormatedPrice } from 'features/subscriptionRequest/utils/getFormatedPrice'
import { IPackageProduct } from 'features/subscriptionRequest/constants/subscription'
import { useProductSummaryData } from 'features/subscriptionRequest/hooks/useProductSummaryData'
import { useAppSelector } from 'app/hooks'
import { getAdjustedPrice } from 'features/subscriptionRequest/utils/getAdjustedPrice'

interface PriceAdjustmentProps {
  product: IPackageProduct
}

export const PriceAdjustment: FC<PriceAdjustmentProps> = ({ product }) => {
  const { t } = useTranslation()
  const { taxes } = useAppSelector((state) => state.subscriptionRequest)
  const { currency, totalPrice, jurCode } = useProductSummaryData(product)

  const adjustments = [
    {
      title: t('subscriptionSummary.priceAdjustment.subtotalBeforeTaxes'),
      content: getAdjustedPrice(totalPrice, currency),
    },
    {
      title: t('subscriptionSummary.priceAdjustment.taxes', { jurCode }),
      content: getAdjustedPrice(taxes[product.id]?.total ?? 0, currency),
    },
  ]

  return (
    <Box sx={{ width: '282px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {adjustments.map(({ title, content }, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontSize='12px'>{title}</Typography>
          <Typography fontSize='12px' fontWeight='600'>
            {content}
          </Typography>
        </Box>
      ))}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: '8px',
          fontSize: '14px',
          lineHeight: '24px',
          fontWeight: 600,
        }}
      >
        <Typography variant='inherit'>{t('subscriptionSummary.priceAdjustment.total', { jurCode })}</Typography>
        <Typography variant='inherit'>
          {currency.symbol} {getFormatedPrice(totalPrice)} {currency.name}
        </Typography>
      </Box>
    </Box>
  )
}
