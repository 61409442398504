import { format } from 'date-fns'
import { store } from 'app/store'

import { DateFormat } from 'constants/profileSettings'
import { Locales } from 'constants/languageKeys'

function customDateFormat(date: Date, localeCode: string, showHour?: boolean) {
  const day = format(date, 'dd', { locale: Locales[localeCode] })
  const month = format(date, 'MMM', { locale: Locales[localeCode] })
    .toLowerCase()
    .replace(/\b\w/g, (s) => s.toUpperCase())
    .slice(0, 3)
  const year = format(date, `yyyy${showHour ? ' h:mm a' : ''}`, { locale: Locales[localeCode] })

  return `${day} ${month} ${year}`
}
export const formatDate = (dateString: string, dateFormat?: DateFormat, showHour?: boolean) => {
  const languageSelected = store.getState().localization.languageSelected
  const date = new Date(dateString)

  switch (dateFormat) {
    case DateFormat.DDMMYYYY:
      return format(date, `dd/MM/yyyy ${showHour ? 'h:mm a' : ''}`, {
        locale: Locales[languageSelected.localeCode],
      })
    case DateFormat.MMDDYYYY:
      return format(date, `MM/dd/yyyy ${showHour ? 'h:mm a' : ''}`, {
        locale: Locales[languageSelected.localeCode],
      })
    case DateFormat.YYYYMMDD:
      return format(date, `yyyy/MM/dd ${showHour ? 'h:mm a' : ''}`, {
        locale: Locales[languageSelected.localeCode],
      })
    default:
      return customDateFormat(date, languageSelected.localeCode, showHour)
  }
}
