import { SvgIcon, SvgIconProps } from '@mui/material'

export const PdfIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.5858 4C13.851 4 14.1054 4.10536 14.2929 4.29289L17.7071 7.70711C17.8946 7.89464 18
          8.149 18 8.41421V10H19.5C19.7761 10 20 10.2239 20 10.5V16.5C20 16.7761 19.7761 17 19.5
          17H18V19.5C18 19.7761 17.7761 20 17.5 20H6.5C6.22386 20 6 19.7761 6 19.5V17H5.5C5.22386 17
          5 16.7761 5 16.5V10.5C5 10.2239 5.22386 10 5.5 10H6V4.5C6 4.22386 6.22386 4 6.5
          4H13.5858ZM17 17H7V19H17V17ZM13 4.999L7 5V10H17V9H14C13.4872 9 13.0645 8.61396 13.0067
          8.11662L13 8V4.999ZM16.586 8L14 5.414V8H16.586ZM18
          11V12H16V13H17.5V14H16V16H15V11H18ZM12.5 11C13.3284 11 14 11.6716 14 12.5V14.5C14 15.3284
          13.3284 16 12.5 16H11V11H12.5ZM12 12V15H12.5C12.7761 15 13 14.7761 13 14.5V12.5C13 12.2239
          12.7761 12 12.5 12H12ZM8 13V12H9V13H8ZM8 14H9C9.55228 14 10 13.5523 10 13V12C10 11.4477
          9.55228 11 9 11H7.5C7.22386 11 7 11.2239 7 11.5V16H8V14Z'
          fill='#DA291C'
        />
      </SvgIcon>
    </>
  )
}
