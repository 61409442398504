import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, SxProps } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { SubscriptionAction } from 'features/subscriptionRequest/constants/subscription'
import ChevronRight from 'assets/images/ChevronRightThin.svg'
import CircleTick from 'assets/images/CircleTick.svg'
import { setActiveStep } from '../../slice'
import { IStep, StepName, StepStatus } from '../../constants/stepper'

const containerStyles: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  fontSize: '14px',
  borderBottom: '1px solid',
  borderColor: 'grey.100',
}

const circleStyles = (step: IStep, isCurrent: boolean): SxProps => ({
  width: '24px',
  height: '24px',
  fontSize: '12px',
  marginRight: '12px',
  color: 'common.black',
  border: '1px solid',
  borderColor: 'grey.100',
  borderRadius: '50%',
  ...(step.status === StepStatus.Available && {
    borderColor: 'primary.main',
  }),
  ...(((step.status === StepStatus.Available && isCurrent) || step.status === StepStatus.Completed) && {
    borderColor: 'primary.main',
    backgroundColor: 'primary.main',
    color: 'common.white',
  }),
})

interface Props {
  steps: IStep[]
  activeStep: number
  subscriptionAction?: SubscriptionAction
  onValidateEmail?: (targetStep: number) => void
}

export const Stepper = ({ steps, subscriptionAction, activeStep, onValidateEmail }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const isCurrentStep = (i: number): boolean => activeStep === i + 1
  const { endUserInformation } = useAppSelector((state) => state.auth)
  const { activePolicyIndex, acceptableUsePolicies } = useAppSelector((state) => state.subscriptionRequest)

  const setCurrentStep = (step: number) => {
    if (!isCurrentStep(step)) {
      if (subscriptionAction === SubscriptionAction.CreateSubscription && activeStep === 1 && !endUserInformation) {
        onValidateEmail?.(step + 1)
      } else {
        dispatch(setActiveStep(step + 1))
      }
    }
  }

  return (
    <Box sx={containerStyles}>
      {steps.map((step, i) => (
        <React.Fragment key={step.name}>
          {!!i && <Box component='img' width='30px' padding='12px' src={ChevronRight} alt='' />}
          <Button
            sx={{ padding: '16px 24px', background: 'none!important' }}
            disabled={step.status === StepStatus.Disabled}
            onClick={() => setCurrentStep(i)}
          >
            <Box component='span' sx={circleStyles(step, isCurrentStep(i))}>
              {step.status === StepStatus.Completed && !isCurrentStep(i) ? (
                <Box component='img' marginTop='3px' src={CircleTick} alt='' />
              ) : (
                i + 1
              )}
            </Box>
            <Box
              sx={{ color: step.status !== StepStatus.Disabled ? 'common.black' : 'grey.800' }}
              textTransform='none'
              component='span'
            >
              {t(step.name)}
              {step.name === StepName.TermsOfUse && isCurrentStep(i) && acceptableUsePolicies.length > 1
                ? ` (${activePolicyIndex + 1} of ${acceptableUsePolicies.length})`
                : ''}
            </Box>
          </Button>
        </React.Fragment>
      ))}
    </Box>
  )
}
