import { Locale } from 'date-fns'
import { frCA, enUS, es } from 'date-fns/locale'

export const shortRoleNames: Record<number, string> = {
  1: 'roles.short.super',
  2: 'roles.short.jurisdiction',
  3: 'roles.short.product',
}

export const longRoleNames: Record<string, string> = {
  SuperAdmin: 'roles.long.super',
  JurisdictionAdmin: 'roles.long.jurisdiction',
  ProductAdmin: 'roles.long.product',
  DemoUser: 'demoUsers.name',
}

export const permissionKeysMapping: Record<number, string> = {
  1: 'permissions.approveRejectSubscriptions',
  2: 'permissions.addRemoveSuperAdmins',
  3: 'permissions.addRemoveJurisdictionAdmins',
  4: 'permissions.addRemoveProductAdmins',
  5: 'permissions.renewSubscriptions',
  6: 'permissions.inactivateSubscriptions',
  7: 'permissions.reactivateSubscriptions',
  8: 'permissions.addRemoveDemoUser',
  9: 'permissions.productConfiguration',
  10: 'permissions.AddSubscriptionOnBehalfOfClient',
}

export const Locales: Record<string, Locale> = {
  'en-US': enUS,
  'fr-CA': frCA,
  'es-ES': es,
}
