export const DEFAULT_PAGINATION = { number: 1, size: 10 }
export const DEFAULT_REPORT_PAGINATION = { page: 1, size: 10 }
export const REQUEST_REPORT_PAGINATION = { number: 1, size: 999 }

export enum ReportColumnType {
  Number = 1,
  String,
  Date,
  DurationValuations,
  Status,
}

export interface IReportColumn {
  id: number
  name: string
  propertyName: string
  type: ReportColumnType
}

export interface IReportType {
  id: number
  name: string
  columns: IReportColumn[]
}

export interface IJurisdiction {
  id: string
  name: string
  code: string
  geoCode: string
}

export interface IProduct {
  id: string
  name: string
  jurisdictionId: string
}

export interface IProductGroup {
  id: string
  name: string
  products: IProduct[]
}

export interface IPagination {
  number: number
  size: number
}

export interface IReportDetails {
  reportTypeId: number | null
  name: string
  columns: string[]
  jurisdictions: string[] | null
  productGroups: string[] | null
  dates: [string, string] | []
}

export interface IReportPayload {
  reportTypeId: number
  name: string
  columnIds: number[]
  startDate: string | null
  endDate: string | null
  jurisdictionIds: string[]
  productGroupIds: string[] | null
  pagination: IPagination
}

export interface IGeneratedReportFilters {
  name: string
  type: string
  period: {
    startDate: string | undefined
    endDate: string | undefined
  }
  productAndJurisdiction: string
}

export interface IMyReport {
  id: string
  employeeId: string
  reportTypeId: number
  name: string
  startDate: string | null
  endDate: string | null
  generatedDate: string
  reportType: string
  isAccessGranted: boolean
  jurisdictionIds: string[]
}

export interface IReport {
  id: string
  reportTypeId: number
  name: string
  startDate: string | null
  endDate: string | null
  reportType: string
  columnIds: number[]
  jurisdictionIds: string[]
  productGroupIds: string[]
  generatedDate?: string
}

export interface IGeneratedReportData {
  result: any[] | null
  total: number
  pagination: {
    size: number
    number: number
  }
  details: IReportDetails | null
  payload: IReportPayload | null
  filters: IGeneratedReportFilters | null
}

export interface IManageReportsState {
  generatedReport: IGeneratedReportData
  selectedReport: IReport | null
  reportTypes: IReportType[]
  jurisdictions: IJurisdiction[]
  productGroups: IProductGroup[]
  details: IReportDetails
  myReports: {
    data: IMyReport[]
    total: number
    pagination: {
      size: number
      page: number
    }
  }
  loading: boolean
  error: string | null | undefined
}
