import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { geoApi } from 'services/api/geoApi'
import { IJurisdiction, IProductGroup } from 'features/manageAdmins/constants'

export const getJurisdictions = createAsyncThunk<IJurisdiction[][], void, { rejectValue: AxiosError }>(
  'jurisdictions/getJurisdictions',
  async (_, { rejectWithValue }) => {
    try {
      return await geoApi.getAllGeo<IJurisdiction[]>('jurisdictions')
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const getJurisdictionsByProductGroupId = createAsyncThunk<
  IJurisdiction[][],
  { productGroup: IProductGroup },
  { rejectValue: AxiosError }
>('jurisdictions/getJurisdictionsByProductGroupId', async ({ productGroup }, { rejectWithValue }) => {
  try {
    const jurCodes = productGroup.products.map((product) => product.countries![0].abbreviation)
    return await geoApi.getSpecificGeo<IJurisdiction[]>(`jurisdictions/product-group/${productGroup.id}`, jurCodes)
  } catch (err) {
    return rejectWithValue(err as AxiosError)
  }
})
