import { SvgIcon, SvgIconProps } from '@mui/material'

export const CloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      color='action'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.08859 6.21569L6.14645 6.14645C6.32001 5.97288 6.58944 5.9536 6.78431 6.08859L6.85355
        6.14645L12 11.293L17.1464 6.14645C17.32 5.97288 17.5894 5.9536 17.7843 6.08859L17.8536
        6.14645C18.0271 6.32001 18.0464 6.58944 17.9114 6.78431L17.8536 6.85355L12.707 12L17.8536
        17.1464C18.0271 17.32 18.0464 17.5894 17.9114 17.7843L17.8536 17.8536C17.68 18.0271 17.4106
        18.0464 17.2157 17.9114L17.1464 17.8536L12 12.707L6.85355 17.8536C6.67999 18.0271 6.41056
        18.0464 6.21569 17.9114L6.14645 17.8536C5.97288 17.68 5.9536 17.4106 6.08859 17.2157L6.14645
        17.1464L11.293 12L6.14645 6.85355C5.97288 6.67999 5.9536 6.41056 6.08859 6.21569Z'
      />
    </SvgIcon>
  )
}
