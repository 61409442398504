export type Direction = 'asc' | 'desc'

export const SortingDirection: Record<Uppercase<Direction>, Direction> = {
  ASC: 'asc',
  DESC: 'desc',
}

export interface ISortingInfo {
  column: string
  direction: Direction
}
