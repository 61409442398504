import React, { useEffect } from 'react'
import { Box, MenuItem, SelectChangeEvent } from '@mui/material'
import { CustomSelect } from 'components/CustomSelect'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useTranslation } from 'react-i18next'
import { IProduct } from 'features/subscriptionRequest/constants/subscription'
import { setPagination, setProduct } from 'features/manageProduct/slice'
import { useSearchParams } from 'react-router-dom'
import { DEFAULT_PAGINATION, DEFAULT_SUBSCRIPTION_SORTING } from 'features/manageProduct/constants'
import colors from 'theme/colors'
import { geoApi } from 'services/api/geoApi'

export const SelectProduct: React.FC = () => {
  const { products, selectedProduct, selectedProductGroup } = useAppSelector(
    (state) => state.manageProduct
  )
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (products.length) {
      const _product = products.find(
        (product) => product.id === searchParams.get('productId')
      ) as IProduct
      _product && dispatch(setProduct(_product))
      setSearchParams({
        productGroupShortName: selectedProductGroup!.shortName,
        jurCode: _product?.jurisdiction?.code || '',
        ...(_product && { productId: _product?.id }),
        sortBy: searchParams.get('sortBy') || DEFAULT_SUBSCRIPTION_SORTING.column,
        direction: searchParams.get('direction') || DEFAULT_SUBSCRIPTION_SORTING.direction,
        page: searchParams.get('page') || DEFAULT_PAGINATION.page.toString(),
        size: searchParams.get('size') || DEFAULT_PAGINATION.size.toString(),
      })
      if (searchParams.get('jurCode')) {
        geoApi.withJurisdictionCode(searchParams.get('jurCode')!)
      }
    }
  }, [dispatch, products, searchParams])

  const onSelectProduct = (e: SelectChangeEvent) => {
    const productName = e.target.value
    const _product = products.find(
      (product) => product.jurisdiction!.name === productName
    ) as IProduct
    if (searchParams.toString() && searchParams.get('productId') !== _product.id) {
      setSearchParams({
        productGroupShortName: selectedProductGroup!.shortName,
        jurisdictionId: _product.jurisdiction!.code,
        productId: _product.id,
        sortBy: DEFAULT_SUBSCRIPTION_SORTING.column,
        direction: DEFAULT_SUBSCRIPTION_SORTING.direction,
        page: DEFAULT_PAGINATION.page.toString(),
        size: DEFAULT_PAGINATION.size.toString(),
      })
    }
    geoApi.withJurisdictionCode(_product.jurisdiction!.code)
    dispatch(setPagination(DEFAULT_PAGINATION))
    dispatch(setProduct(_product))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
      }}
    >
      {t('manageProduct.product.label')}:
      <CustomSelect
        sx={{ '.MuiSelect-select': { color: colors.mineShaft } }}
        value={selectedProduct?.jurisdiction!.name}
        outline={false}
        onChange={onSelectProduct}
        placeholder={t('manageProduct.product.placeholder')!}
      >
        {products.map((product) => (
          <MenuItem key={product.id} value={product.jurisdiction!.name}>
            {product.jurisdiction!.name}
          </MenuItem>
        ))}
      </CustomSelect>
    </Box>
  )
}
