import { ElementType, FC, ReactNode } from 'react'
import { Box, Button, ButtonProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface FooterFormProps {
  children: ReactNode | ReactNode[]
}
interface IFooterButton {
  icon?: ReactNode
  text: string
  type?: 'submit' | 'button'
  onClose?: () => void
}

const FooterButton = <C extends ElementType>({
  color,
  disabled,
  icon,
  text,
  type = 'button',
  variant = 'contained',
  sx,
  onClose,
  ...props
}: ButtonProps<C, { component?: C }> & IFooterButton) => {
  const { t } = useTranslation()
  return (
    <Button
      variant={variant}
      color={color}
      sx={{ marginLeft: '16px', ...sx }}
      type={type}
      onClick={onClose}
      disabled={disabled}
      startIcon={icon}
      {...props}
    >
      {t(text)}
    </Button>
  )
}

const FooterForm: FC<FooterFormProps> = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '16px',
        }}
      >
        <>{children}</>
      </Box>
    </>
  )
}

export default Object.assign(FooterForm, {
  Button: FooterButton,
})
