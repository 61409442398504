import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { Form, FormikProvider } from 'formik'

import HeaderForm from '../headerForm'
import FooterForm from '../footerForm'
import { UserName } from '../userName'
import CustomForm from 'components/CustomForm'
import PermissionsField from '../permissionsField'
import { StaticFormField } from 'components/staticFormField'

import { useUpdateAdminsForm } from 'features/manageAdmins/hooks'
import { useAppSelector } from 'app/hooks'

import { selectPermissions } from 'features/manageAdmins/slices/manageAdmins/selectors'
import { SUPER_ADMIN, PRODUCT_ADMIN } from '../../constants/interfaces'
import { CustomLoading } from 'components/CustomLoading'

export const UpdateAdminsForm = () => {
  const { t } = useTranslation()
  const { isLoading } = useAppSelector((state) => state.manageAdmins)
  const permissions = useAppSelector(selectPermissions)

  const { isSearching } = useAppSelector((state) => state.employees)

  const {
    onClose,
    setValue,
    employeeInfo,
    employeeRole,
    employeeProduct,
    employeeJurisdiction,
    formikProps,
  } = useUpdateAdminsForm()

  const { isValid, values, dirty } = formikProps
  const { employeeInformation: currentEmployeeInfo } = useAppSelector((state) => state.auth)

  const showJurisdictionValue = () => {
    if (employeeJurisdiction && employeeRole!.id !== SUPER_ADMIN) {
      return <StaticFormField label='manageAdmins.jurisdiction' value={employeeJurisdiction.name} />
    }
  }

  const showProductValue = () => {
    if (employeeProduct && employeeRole!.id === PRODUCT_ADMIN) {
      return (
        <StaticFormField label='manageAdmins.product' value={employeeProduct.productGroupName} />
      )
    }
  }

  return (
    <FormikProvider value={formikProps}>
      <Form>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <HeaderForm onClose={onClose} title='manageAdmins.manageAdmin' />
          <Box sx={{ maxHeight: '473px', flexGrow: 1, overflow: 'auto', p: '0 16px' }}>
            <CustomForm>
              <Typography variant='body2' sx={{ m: '16px 0' }}>
                {t('manageAdmins.updateAdmin')}
              </Typography>
              {isSearching || isLoading ? (
                <CustomLoading
                  message='common.loader.loading'
                  textColor='black'
                  sx={{
                    display: 'flex',
                    width: '100%',
                    height: '120px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1,
                  }}
                />
              ) : (
                <>
                  {employeeInfo && (
                    <Box sx={{ mt: '16px', mb: '16px' }}>
                      <UserName
                        firstName={employeeInfo.firstName}
                        lastName={employeeInfo.lastName}
                      />
                    </Box>
                  )}
                  {employeeRole && (
                    <>
                      <StaticFormField label='manageAdmins.roles' value={employeeRole.name} />
                      {showProductValue()}
                      {showJurisdictionValue()}
                    </>
                  )}
                  <PermissionsField>
                    <PermissionsField.SelectAll
                      permissions={permissions}
                      permissionsSelected={values.permissionIds.length}
                      setValue={setValue}
                      disabled={currentEmployeeInfo?.email === employeeInfo?.email}
                    />
                    <PermissionsField.List
                      permissions={permissions}
                      disabled={currentEmployeeInfo?.email === employeeInfo?.email}
                    />
                  </PermissionsField>
                </>
              )}
            </CustomForm>
          </Box>
          {employeeInfo && (
            <FooterForm>
              <FooterForm.Button onClose={onClose} variant='outlined' text='button.cancel' />
              {currentEmployeeInfo?.email !== employeeInfo.email && (
                <FooterForm.Button
                  type='submit'
                  disabled={!isValid || !dirty}
                  text='button.apply'
                />
              )}
            </FooterForm>
          )}
        </Box>
      </Form>
    </FormikProvider>
  )
}
