import { ChangeEvent, DragEvent, useState } from 'react'

interface IDrop {
  onChange?: (file?: File) => void
}

export const useDropper = ({ onChange }: IDrop = {}) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false)

  const stopEvent = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDropCapture = (e: DragEvent<HTMLDivElement>) => {
    stopEvent(e)
    onChange && onChange(e.dataTransfer.files[0])
    isDraggingOver && setIsDraggingOver(false)
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) onChange && onChange(e.target.files[0])
  }

  const unselectFile = () => onChange && onChange(undefined)

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    stopEvent(e)
    !isDraggingOver && setIsDraggingOver(true)
  }
  const handleDrageLeave = (e: DragEvent<HTMLDivElement>) => {
    stopEvent(e)
    isDraggingOver && setIsDraggingOver(false)
  }

  return {
    isDraggingOver,
    handleDrageLeave,
    handleDragEnter,
    handleDropCapture,
    handleFileChange,
    unselectFile,
    stopEvent,
  }
}
