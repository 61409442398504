import { Form, FormikProvider } from 'formik'
import { Box } from '@mui/material'

import { EmailField } from '../emailField'
import { CustomField } from '../customField'
import CustomForm from 'components/CustomForm'
import { CustomAlert } from 'components/CustomAlert'

import HeaderForm from 'features/manageAdmins/components/headerForm'
import FooterForm from 'features/manageAdmins/components/footerForm'
import { useAddUser } from 'features/manageUsers/hooks/useAddUser'

export const AddUserForm = () => {
  const {
    alertType,
    canEdit,
    canUserBeAdded,
    formikProps,
    message,
    handleCheckChange,
    handleInputChange,
    handleClose,
    setValue,
  } = useAddUser()
  const { isValid, dirty, values } = formikProps
  const { firstName, lastName, isSameOrganization } = values

  return (
    <FormikProvider value={formikProps}>
      <Form>
        <HeaderForm onClose={handleClose} title='manageProduct.users.addUser' />
        {alertType && <CustomAlert text={message} severity={alertType} />}
        <Box sx={{ maxHeight: '473px', flexGrow: 1, overflow: 'auto', p: ' 16px 0' }}>
          <CustomForm>
            <Box sx={{ maxHeight: '473px', flexGrow: 1, overflow: 'auto', p: '0 16px' }}>
              <EmailField setEmailInput={setValue} error={!canUserBeAdded} />
              <CustomForm.Label value='manageProduct.users.firstName' />
              <CustomField
                name={'firstName'}
                value={firstName}
                onChange={handleInputChange}
                isEditable={canEdit}
                placeholder='manageProduct.users.enterFirstName'
              />
              <CustomForm.Label value='manageProduct.users.lastName' />
              <CustomField
                name={'lastName'}
                value={lastName}
                onChange={handleInputChange}
                isEditable={canEdit}
                placeholder='manageProduct.users.enterLastName'
              />
              <CustomForm.CheckBox
                checked={isSameOrganization}
                onChange={handleCheckChange}
                name={'organization'}
                label={'manageProduct.users.confirmSameOrganization'}
              />
            </Box>
          </CustomForm>
        </Box>
        <FooterForm>
          <FooterForm.Button onClose={handleClose} variant='outlined' text='button.back' />
          <FooterForm.Button
            type='submit'
            disabled={!canUserBeAdded || !isValid || !dirty}
            text='manageProduct.users.confirm'
          />
        </FooterForm>
      </Form>
    </FormikProvider>
  )
}
