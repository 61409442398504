import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { CustomAlert } from 'components/CustomAlert'

export const NoData = () => {
  const { t } = useTranslation()
  const { generatedReport } = useAppSelector((state) => state.manageReports)

  return (
    <>
      {generatedReport.result && <CustomAlert severity='warning' text={t('reports.noResult')!} />}
      <Box
        sx={{
          height: 'calc(100% - 40px)',
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='body1' fontWeight={600} mt='30px'>
          {t('reports.noData.title')}
        </Typography>
        <Typography variant='body2' color={'grey.800'} mt='6px' mb='16px'>
          {t('reports.noData.description')}
        </Typography>
      </Box>
    </>
  )
}
