import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getSubscriptionDetails } from 'features/manageProduct/services/subscription/subscription'
import { renewSubscriptionSteps, StepRenewSubscription } from 'features/subscriptionRequest/constants/stepper'
import {
  setAllAccountDetails,
  setIsPrivacyAccepted,
  setValuations,
  setPackages,
  resetData,
  setSteps,
  setTerm,
} from 'features/subscriptionRequest/slice'
import {
  ICountry,
  IState,
  LicenseType,
  SubscriptionAction,
  RenewSubscriptionPayload,
} from 'features/subscriptionRequest/constants/subscription'
import { selectPackageGroups, selectSubscriptionDetails } from 'features/subscriptionRequest/slice/selectors'
import { fetchProduct, fetchStates } from 'features/subscriptionRequest/slice/services'
import { getCountries, renewSubscription } from 'features/subscriptionRequest/services/subscription'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { showMessage } from 'utils/errorMessageHandler'
import { SubscriptionContainer } from '../subscriptionContainer'
import { SelectPackages } from '../selectPackages'
import { AccountDetails } from '../accountDetails'

export const RenewSubscription = () => {
  const { steps, activeStep, terms, packages, valuations, products } = useAppSelector(
    (state) => state.subscriptionRequest
  )
  const subscriptionDetails = useAppSelector(selectSubscriptionDetails)
  const { groupedTerms, packageGroups } = useAppSelector(selectPackageGroups)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [search] = useSearchParams()
  const [jurisdiction, setJurisdiction] = useState<ICountry | null>(null)
  const groupId = useRef<string>('')
  const isShowInfoMessage = useRef<boolean>(false)
  const selectedPackage = useRef<string>('')
  const selectedSubscriptionId = params['subscriptionId']
  const selectedPackageId = params['packageId']
  const jurCode = search.get('jurCode')
  const productId = search.get('productId')

  useEffect(() => {
    dispatch(setSteps(renewSubscriptionSteps))

    if (selectedSubscriptionId && productId && jurCode) {
      Promise.all([
        getSubscriptionDetails(productId, selectedSubscriptionId, jurCode),
        getCountries(productId, jurCode),
        dispatch(fetchProduct({ productId, jurCode })),
      ]).then(([subscription, country]) => {
        const _selectedPackage = subscription.packages.find((_package) => _package.id === selectedPackageId)!

        dispatch(setPackages([_selectedPackage]))
        dispatch(
          setValuations({
            [_selectedPackage.id]: _selectedPackage.licenseQuantity,
          })
        )

        if (_selectedPackage) {
          selectedPackage.current = _selectedPackage.packageGroupName
        }

        groupId.current = subscription.groupId
        isShowInfoMessage.current = subscription.isMultiSubscriptionUser

        const filteredSelectedCountry: ICountry | undefined = country.find(
          (_country) => _country.name === subscription.countryName
        )

        if (filteredSelectedCountry) {
          const selectedCountryWithProductId: ICountry = { ...filteredSelectedCountry, productId }
          setJurisdiction(selectedCountryWithProductId)

          dispatch(fetchStates(filteredSelectedCountry.id)).then((state) => {
            let foundState = null
            if (state.payload && Array.isArray(state.payload)) {
              foundState = (state.payload.find((_state) => _state.name === subscription.stateName) as IState) ?? null
            }

            dispatch(
              setAllAccountDetails({
                country: selectedCountryWithProductId,
                email: subscription.email,
                address: subscription.address1,
                city: subscription.city,
                companyFullName: subscription.companyName,
                firstName: subscription.firstName,
                lastName: subscription.lastName,
                zipCode: subscription.zipCode,
                state: foundState,
                department: subscription.department,
                role: subscription.roleName,
                globalParentFullName: subscription.parentEntity,
              })
            )
            dispatch(setIsPrivacyAccepted(true))
          })
        }
      })
    }

    return () => {
      dispatch(resetData(renewSubscriptionSteps))
    }
  }, [])

  const onCancelSubscription = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const selectDuration = useCallback(
    (packageId: string, termId: string) => {
      const selectedTerm = groupedTerms && groupedTerms[packageId].find((term) => term.id === termId)

      if (selectedTerm) {
        dispatch(setTerm({ [packageId]: selectedTerm }))
      }
    },
    [groupedTerms, dispatch]
  )

  const isContinueDisabled = useCallback(() => {
    return !packages.some((_package) =>
      _package.licenseType === LicenseType.TokenBased
        ? valuations[_package.id]! > _package.licenseQuantity!
        : terms[_package.id] || products[0].terms.length === 1
    )
  }, [packages, terms, valuations, products])

  const onSubmitSubscriptionAction = useCallback(() => {
    if (selectedSubscriptionId) {
      const _packages = packages.map((_package) => ({
        id: _package.id,
        ...(_package.licenseType === LicenseType.TokenBased && {
          licenseQuantity: valuations[_package.id],
        }),
        ...(_package.licenseType === LicenseType.SingleLicense && {
          packageTermId: terms[_package.id]?.id || products[0].terms[0].id,
        }),
      }))
      const account = {
        ...subscriptionDetails.account,
        groupId: groupId.current,
      }
      const payload: RenewSubscriptionPayload = {
        note: 'Renew',
        packages: _packages,
        account,
      }

      renewSubscription(selectedSubscriptionId, jurCode!, payload).then(() => {
        navigate(-1)
        showMessage.success('mySnackbar', 'manageProduct.subscriptionMessage.success')
      })
    }
  }, [navigate, packages, selectedSubscriptionId, subscriptionDetails.account, terms, valuations, jurCode])

  const onSubmitSubscription = useCallback(() => {
    onSubmitSubscriptionAction()
  }, [onSubmitSubscriptionAction])

  return jurisdiction ? (
    <>
      <SubscriptionContainer
        steps={steps}
        activeStep={activeStep}
        productGroupName={selectedPackage.current}
        subscriptionAction={SubscriptionAction.EditSubscription}
        onCancelSubscription={onCancelSubscription}
        onSubmitSubscription={onSubmitSubscription}
        isContinueDisabled={isContinueDisabled()}
      >
        {
          {
            [StepRenewSubscription.SelectPackage]: (
              <SelectPackages
                packageGroups={packageGroups}
                subscriptionAction={SubscriptionAction.EditSubscription}
                selectDuration={selectDuration}
              />
            ),
            [StepRenewSubscription.AccountDetails]: (
              <AccountDetails
                showPrivacyPolicy={false}
                infoMessage={isShowInfoMessage.current ? 'account.associatedEmailMessage' : ''}
                jurisdiction={jurisdiction}
              />
            ),
          }[activeStep] as React.ReactElement
        }
      </SubscriptionContainer>
    </>
  ) : (
    <></>
  )
}
