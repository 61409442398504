import { Box, Checkbox, FormControlLabel, Link, Typography } from '@mui/material'
import { DownloadIcon } from 'components/icons/Download.icon'
import FooterForm from 'features/manageAdmins/components/footerForm'
import { useTranslation } from 'react-i18next'

interface Props {
  disable: boolean
  isSameOrganization: boolean
  file?: File
  handleChange: (name: string, value: boolean | File) => void
  handleClose: () => void
}

const DownloadTemplateButton = () => (
  <FooterForm.Button
    component={Link}
    href='/File-template.xlsx'
    variant='text'
    target='_blank'
    rel='noreferrer'
    text='manageProduct.users.downloadTemplate'
    icon={<DownloadIcon />}
    sx={{ ml: 0, pl: '8px' }}
  />
)
export const FormHandleView = ({
  handleClose,
  handleChange,
  isSameOrganization,
  disable,
  file,
}: Props) => {
  const { t } = useTranslation()
  return (
    <>
      {file && (
        <Box
          sx={{
            p: '16px',
            height: '228px',
            display: 'flex',
            alignContent: 'flex-end',
            boxSizing: 'border-box',
          }}
        >
          <FormControlLabel
            sx={{ m: 0, mt: 'auto', alignSelf: 'flex-start' }}
            control={
              <Checkbox
                checked={isSameOrganization}
                sx={{ p: 0, m: '-2px', mr: '8px' }}
                onChange={(e) => handleChange('isSameOrganization', e.currentTarget.checked)}
              />
            }
            label={
              <Typography variant='body2'>
                {t('manageProduct.users.confirmsSameOrganization')}
              </Typography>
            }
          />
        </Box>
      )}
      <FooterForm>
        {!file && <DownloadTemplateButton />}
        <Box flex={1}></Box>
        <FooterForm.Button text='button.cancel' variant='outlined' onClose={handleClose} />
        <FooterForm.Button type='submit' text='manageProduct.users.addUsers' disabled={disable} />
      </FooterForm>
    </>
  )
}
