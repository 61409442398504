import { Box, Checkbox, ClickAwayListener, SxProps } from '@mui/material'
import { NavLinkIcon } from '../navLinkIcon'
import { handleSideBar } from 'sharedSlices/ui'
import { useAppDispatch, useAppSelector } from 'app/hooks'

import { OptionsIcon } from '../../../icons/Options.icon'
import { ArrowDoubleRight } from '../../../icons/ArrowDoubleRight.icon'
import { DocumentCheckedIcon } from '../../../icons/DocumentChecked.icon'
import { EditProfileIcon } from '../../../icons/EditProfile.icon'
import { useEffect } from 'react'
import { SettingIcon } from 'components/icons/Setting.icon'
import { selectCurrentUserPermissions } from 'sharedSlices/auth'
import { Permissions } from 'constants/permissions'

const handleOpenButtonStyle: SxProps = {
  alignSelf: 'flex-start',
  width: '46px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}

const sidebarContainer: SxProps = {
  borderRight: '1px solid',
  borderRightColor: 'border.main',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  position: 'absolute',
  backgroundColor: 'common.white',
}

export const SideBar = () => {
  const dispatch = useAppDispatch()
  const { isSideBarOpen } = useAppSelector((state) => state.ui)
  const userPermissions = useAppSelector(selectCurrentUserPermissions)

  const handleSideBarOpen = () => {
    dispatch(handleSideBar(!isSideBarOpen))
  }

  useEffect(() => {
    return () => {
      dispatch(handleSideBar(false))
    }
  }, [dispatch])

  return (
    <Box sx={{ position: 'relative', flexBasis: '47px', zIndex: 3 }}>
      <ClickAwayListener
        onClickAway={() => {
          isSideBarOpen && dispatch(handleSideBar(false))
        }}
      >
        <Box sx={{ ...sidebarContainer, width: isSideBarOpen ? '348px' : '47px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <NavLinkIcon
              to={{
                pathname: '/manage/subscriptions',
                search: window.location.search
              }}
              text='sideBar.subscriptions'
              showText={isSideBarOpen}
            >
              <OptionsIcon />
            </NavLinkIcon>
            <NavLinkIcon to='/manage/admin' text='sideBar.manageAdmins' showText={isSideBarOpen}>
              <EditProfileIcon sx={{ width: '24px', height: '24px' }} />
            </NavLinkIcon>
            <NavLinkIcon to='/manage/reports' text='sideBar.createReport' showText={isSideBarOpen}>
              <DocumentCheckedIcon />
            </NavLinkIcon>
            {userPermissions?.includes(String(Permissions.ProductConfiguration)) && (
              <NavLinkIcon
                to='/manage/productConfiguration'
                text='sideBar.productConfiguration'
                showText={isSideBarOpen}
              >
                <SettingIcon
                  sx={{
                    fontSize: '24px !important',
                  }}
                />
              </NavLinkIcon>
            )}
          </Box>
          <Box sx={handleOpenButtonStyle}>
            <Checkbox
              checked={isSideBarOpen}
              onClick={handleSideBarOpen}
              sx={{
                svg: { color: 'common.black', fontSize: 16 },
                '&:hover': { svg: { color: 'primary.main' } },
              }}
              icon={<ArrowDoubleRight />}
              checkedIcon={<ArrowDoubleRight sx={{ transform: 'rotate(180deg)' }} />}
            />
          </Box>
        </Box>
      </ClickAwayListener>
    </Box>
  )
}
