import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Avatar, Box, Button, ClickAwayListener, Popper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'app/hooks'

import { handleProfile, handleProfileSettings } from 'sharedSlices/ui'

import { useLogout } from 'services/auth/useLogout'

import Settings from 'assets/icons/settings.svg'
import LogOut from 'assets/icons/logout.svg'
import { CloseIcon } from 'components/icons/Close.icon'
import { SelectLanguage } from 'components/SelectLanguage'

interface Props {
  open: boolean
  anchor: HTMLDivElement | null
  onClose: () => void
}

export const Profile: FC<Props> = ({ open, anchor, onClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const { handleLogout } = useLogout()

  const { isProfileOpen } = useAppSelector((state) => state.ui)
  const user = useAppSelector((state) => state.auth.employeeInformation)

  const openSettings = () => {
    dispatch(handleProfileSettings(true))
    dispatch(handleProfile(false))
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (isSelectOpen) return
        isProfileOpen && dispatch(handleProfile(false))
      }}
    >
      <Popper
        anchorEl={anchor}
        open={open}
        sx={{
          width: '298px',
          height: 'calc(100% - 71px)',
          marginLeft: '-16px!important',
          top: '8px!important',
          left: 'inherit!important',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.26)',
          fontFamily: 'Open Sans',
          backgroundColor: 'common.white',
          zIndex: 100,
          '.MuiDialog-container': {
            justifyContent: 'end',
          },
          '.MuiPaper-root': {
            margin: 0,
            height: '100%',
            maxHeight: '100%',
            borderRadius: 0,
          },
        }}
      >
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '16px 16px 40px 16px',
              borderBottom: '1px solid',
              borderColor: 'grey.100',
            }}
          >
            <Button
              sx={{
                width: '24px',
                height: '24px',
                minWidth: '24px',
                minHeight: '24px',
                padding: 0,
                marginLeft: 'auto',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                }}
                onClick={onClose}
              >
                <CloseIcon />
              </Box>
            </Button>
            <Avatar
              sx={{
                width: '96px',
                height: '96px',
                fontSize: '32px',
                fontWeight: '600',
                backgroundColor: 'common.black',
                marginBottom: '20px',
              }}
              onClick={() => dispatch(handleProfile(true))}
            >
              {user!.firstName.charAt(0) + user!.lastName.charAt(0)}
            </Avatar>
            <Box sx={{ fontWeight: '600' }}>
              {user!.firstName} {user!.lastName}
            </Box>
          </Box>
          <Box
            sx={{
              padding: '12px 16px',
              borderBottom: '1px solid',
              borderColor: 'grey.100',
            }}
          >
            <SelectLanguage
              onOpen={() => setIsSelectOpen(true)}
              MenuProps={{
                TransitionProps: {
                  onExited: () => setIsSelectOpen(false),
                },
              }}
            />
          </Box>
          <Box
            sx={{
              padding: '12px 16px',
              borderBottom: '1px solid',
              borderColor: 'grey.100',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              img: {
                marginRight: '8px',
              },
            }}
            onClick={openSettings}
          >
            <Box component='img' src={Settings} />
            <Box>{t('profile.settings')}</Box>
          </Box>
          <Box
            sx={{
              padding: '12px 16px',
              borderBottom: '1px solid',
              borderColor: 'grey.100',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              img: {
                marginRight: '8px',
              },
            }}
            onClick={() => handleLogout()}
          >
            <Box component='img' src={LogOut} />
            <Box>{t('profile.logout')}</Box>
          </Box>
        </>
      </Popper>
    </ClickAwayListener>
  )
}
