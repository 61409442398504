import React from 'react'
import { Chip, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DeleteChipIcon } from 'components/icons/DeleteChip.icon'

interface ChipsListProps<T> {
  list: T[]
  onEdit?: (index: number) => void
  onDelete?: (index: number) => void
  getLabel: (item: T) => string
  deleteIcon?: React.ReactElement
}

const ChipsList = <T extends { name: string }>({
  list,
  onEdit,
  onDelete,
  getLabel,
  deleteIcon = (
    <DeleteChipIcon
      data-testid='delete-chip-icon'
      sx={{
        width: '20px',
        height: '20px',
        marginRight: '4px',
        color: 'silverChalice',
        cursor: 'pointer',
        '&:hover': {
          color: 'grey.800',
        },
      }}
    />
  ),
}: ChipsListProps<T>) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography fontWeight={600} mt='6px' mb='8px' color={'grey.800'} lineHeight='14px' variant='caption'>
        {t('subscriptionInit.isDeloitteClient.businessUnitPopup.listTitle')}
      </Typography>
      <Box
        sx={{
          margin: '2px 0px',
        }}
      >
        {list?.map((item, index) => (
          <Chip
            sx={{
              margin: '0 8px 8px 0',
            }}
            label={getLabel(item)}
            key={index}
            onDelete={(e) => {
              e.stopPropagation()
              onDelete && onDelete(index)
            }}
            onClick={() => onEdit && onEdit(index)}
            deleteIcon={onDelete ? deleteIcon : <></>}
          />
        ))}
      </Box>
    </>
  )
}

export default ChipsList
