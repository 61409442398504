import { useNavigate } from 'react-router-dom'

export type SearchParams = { [key: string]: string | number }

export const useSearchNavigate = () => {
  const navigate = useNavigate()
  //This functions updates and adds the newParams, without removing the ones already in the search
  const searchNavigate = (newParams: SearchParams) => {
    const location = window.location
    const searchParams = new URLSearchParams(location.search || '')
    for (const key in newParams) {
      if (Object.prototype.hasOwnProperty.call(newParams, key)) {
        const param = newParams[key].toString()
        searchParams.set(key, param)
      }
    }

    const newSearchString = searchParams.toString()
    navigate({ search: newSearchString })
  }

  //This functions removes the current searchParamas and adds the newParamas
  const searchFixedNavigate = (newParams: SearchParams) => {
    const entries = Object.entries(newParams).map(([key, value]) => [key, value.toString()])
    const searchParams = new URLSearchParams(entries)
    const newSearchString = searchParams.toString()
    navigate({ search: newSearchString })
  }
  return { searchNavigate, searchFixedNavigate }
}
