import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IIntegrations,
  IPolicy,
  IPrivacy,
  IProductConfigurationState,
  Jurisdiction,
  ProductConfigurationSection,
  productConfigurationSections,
} from '../constants'
import { fetchJurisdictions, getProductGroups } from '../services'
import { Package } from '../component/ProductPackages/constants'
import { getUniqueById } from 'utils/getUniqueById'

export const initialProductConfigurationState: IProductConfigurationState = {
  products: [],
  productData: {
    name: '',
    shortName: '',
    askAreDeloitteClient: false,
    managingUsers: false,
    jurisdictions: [],
    roles: [],
    packages: {},
    policies: {},
    privacy: {},
    integrations: {} as IIntegrations,
  },
  jurisdictions: [],
  sectionsState: productConfigurationSections,
  currentSection: ProductConfigurationSection.Details,
  selectedJurisdiction: Jurisdiction.All,
  isEdit: false,
  loading: false,
  error: null,
}

export const productConfigurationSlice = createSlice({
  name: 'productConfiguration',
  initialState: initialProductConfigurationState,
  reducers: {
    resetProductGroups: (state) => {
      state.products = []
    },
    setCurrentSection: (state, action) => {
      if (action.payload === ProductConfigurationSection.Details) {
        state.selectedJurisdiction = Jurisdiction.All
      }
      state.currentSection = action.payload
    },
    setProductName: (state, action) => {
      state.productData.name = action.payload
    },
    setProductShortName: (state, action) => {
      state.productData.shortName = action.payload
    },
    setAskAreDeloitteClient: (state, action) => {
      state.productData.askAreDeloitteClient = action.payload
    },
    setEnableManagingUsers: (state, action) => {
      state.productData.managingUsers = action.payload
    },
    setSectionsState: (state, action) => {
      state.sectionsState = action.payload
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload
    },
    setSelectedJurisdictions: (state, action) => {
      if (Object.keys(state.productData.policies).length) {
        const policies: { [key: string]: IPolicy } = {}

        for (const jurisdiction in state.productData.policies) {
          if (jurisdiction === Jurisdiction.All || action.payload.includes(jurisdiction)) {
            policies[jurisdiction] = state.productData.policies[jurisdiction]
          }
        }
        state.productData.policies = policies
      }

      if (Object.keys(state.productData.privacy).length) {
        const privacy: { [key: string]: IPrivacy } = {}

        for (const jurisdiction in state.productData.privacy) {
          if (jurisdiction === Jurisdiction.All || action.payload.includes(jurisdiction)) {
            privacy[jurisdiction] = state.productData.privacy[jurisdiction]
          }
        }
        state.productData.privacy = privacy
      }

      if (Object.values(state.productData.packages).length) {
        const filteredPackages: { [key: string]: Package[] } = {}

        for (const key in state.productData.packages) {
          if (key === Jurisdiction.All || action.payload.includes(key)) {
            filteredPackages[key] = state.productData.packages[key]
          }
        }
        state.productData.packages = filteredPackages
      }

      state.productData.jurisdictions = action.payload
    },
    setRoles: (state, action) => {
      state.productData.roles = action.payload
    },
    setSelectedJurisdiction: (state, action) => {
      state.selectedJurisdiction = action.payload
    },
    setPackages: (state, action: PayloadAction<{ jurisdiction: string; packages: Package[] }>) => {
      state.productData.packages[action.payload.jurisdiction] = action.payload.packages
    },
    resetProductConfigurationData: (state) => {
      state.productData = initialProductConfigurationState.productData
      state.sectionsState = productConfigurationSections
      state.currentSection = ProductConfigurationSection.Details
      state.selectedJurisdiction = Jurisdiction.All
      state.jurisdictions = []
    },
    setPolicy: (state, action: PayloadAction<IPolicy>) => {
      state.productData.policies = {
        ...state.productData.policies,
        [state.selectedJurisdiction]: action.payload,
      }
    },
    setPrivacy: (state, action: PayloadAction<IPrivacy>) => {
      state.productData.privacy = {
        ...state.productData.privacy,
        [state.selectedJurisdiction]: action.payload,
      }
    },
    setIntegrations: (state, action: PayloadAction<IIntegrations>) => {
      state.productData.integrations = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProductGroups.pending, (state) => {
        state.loading = true
      })
      .addCase(getProductGroups.fulfilled, (state, action) => {
        state.loading = false
        state.products = getUniqueById(action.payload.flat())
      })
      .addCase(getProductGroups.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })
      .addCase(fetchJurisdictions.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchJurisdictions.fulfilled, (state, action) => {
        state.loading = false
        state.jurisdictions = getUniqueById(action.payload.flat()).sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
      })
      .addCase(fetchJurisdictions.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })
  },
})

export const {
  resetProductGroups,
  setProductName,
  setProductShortName,
  setSectionsState,
  setCurrentSection,
  setAskAreDeloitteClient,
  setEnableManagingUsers,
  setSelectedJurisdictions,
  setRoles,
  setSelectedJurisdiction,
  setPackages,
  resetProductConfigurationData,
  setPolicy,
  setPrivacy,
  setIntegrations,
  setIsEdit,
} = productConfigurationSlice.actions

export default productConfigurationSlice.reducer
