import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { FileUploadPopup } from 'components/FileUploadPopup'
import { UpperMenu } from 'components/layouts/routerLayout/upperMenu'
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { AuthError, InteractionStatus, RedirectRequest } from '@azure/msal-browser'
import { loginError } from 'sharedSlices/auth'
import { useAppDispatch } from 'app/hooks'
import { useParams } from 'react-router-dom'
import { getProductGroup, importSubscriptions } from 'features/subscriptionsImport/services'
import { IProductGroup } from 'features/productConfiguration/constants'
import { showMessage } from 'utils/errorMessageHandler'
import { geoApi } from 'services/api/geoApi'
import { FileType } from 'constants/file'

export const SubscriptionsImportPage = () => {
  const isAuthenticated = useIsAuthenticated()
  const { inProgress, instance } = useMsal()
  const { productGroupShortName } = useParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [showFileUploadPopup, setShowFileUploadPopup] = useState(false)
  const jurCodes = useRef<string[] | null>(null)
  const acceptedFileTypes = [FileType.Csv]

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      const loginRequest: RedirectRequest = {
        scopes: ['User.Read'],
        redirectUri: `${window.location.origin}/subscriptions-import`,
      }

      instance.loginRedirect(loginRequest).catch((e: AuthError) => {
        dispatch(loginError(e.errorMessage))
      })
    }
  }, [isAuthenticated, inProgress])

  useEffect(() => {
    if (productGroupShortName) {
      getProductGroup(productGroupShortName).then((productGroupResult) => {
        jurCodes.current = productGroupResult.reduce((_jurCodes: string[], curProductGroup: IProductGroup) => {
          const codes = curProductGroup.products?.map((product) => geoApi.jurMapping[product.jurisdictionId].code) || []
          return [..._jurCodes, ...codes]
        }, [] as string[])
      })
    }
  }, [])

  return (
    <AuthenticatedTemplate>
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <UpperMenu title={'Global Subscription Services'} />
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Box>{t('subscriptionsImport.title')}</Box>
            <Typography
              sx={{
                width: '394px',
                fontSize: '14px',
                textAlign: 'center',
                color: 'grey.800',
                marginTop: '6px',
              }}
            >
              {t('subscriptionsImport.description')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                marginTop: '16px',
              }}
            >
              <Button variant='contained' onClick={() => setShowFileUploadPopup(true)}>
                {t('subscriptionsImport.import')}
              </Button>
            </Box>
          </Box>
          <FileUploadPopup
            title={t('subscriptionsImport.import')}
            acceptedFileTypes={acceptedFileTypes}
            open={showFileUploadPopup}
            failedMessage={t('subscriptionsImport.popup.error')}
            uploadFile={(file) => importSubscriptions(productGroupShortName!, jurCodes.current!, file)}
            uploadFileSuccess={() => showMessage.success('mySnackbar', 'subscriptionsImport.snackbar.success')}
            uploadFileError={() => showMessage.error('mySnackbar', 'subscriptionsImport.snackbar.fail')}
            onFinish={() => setShowFileUploadPopup(false)}
            onClose={() => setShowFileUploadPopup(false)}
          />
        </Box>
        {![InteractionStatus.None, InteractionStatus.HandleRedirect].includes(inProgress) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </AuthenticatedTemplate>
  )
}
