import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import localizationApi from 'services/localizationApi'

import { LanguageInfo } from '../constants'

export const getAvailableLanguages = createAsyncThunk<
  LanguageInfo[],
  void,
  { rejectValue: AxiosError }
>('localization/getAvailableLanguages', async (_, { rejectWithValue }) => {
  try {
    return await localizationApi.get<LanguageInfo[]>('/locales')
  } catch (err) {
    return rejectWithValue(err as AxiosError)
  }
})
