import { IPackageGroup, IPackageCurrency } from 'features/subscriptionRequest/constants/subscription'
import i18n from 'i18next'

export const getFormattedPackages = (
  packages: { [key: string]: IPackageGroup } | null,
  termId: string | null,
  packageType: string | null,
  currenciesPackage: IPackageCurrency | null
): string => {
  const t = i18n.t.bind(i18n)

  if (!packages || !currenciesPackage) return ''

  const filteredPackages = Object.keys(packages)
    .filter((name) => {
      const productId = packages[name]?.productId
      return !(termId && packageType && currenciesPackage[productId])
    })
    .map((name) => name.split(' ')[0])

  return filteredPackages?.length === 2
    ? filteredPackages.join(` ${t('and')} `)
    : filteredPackages.length > 2
    ? `${filteredPackages.slice(0, 2).join(` ${t('and')} `)}, ${filteredPackages.slice(2).join(', ')}`
    : filteredPackages[0] || ''
}
