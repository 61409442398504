import { emailRegEx } from './email'
import { DeloitteClientType, ProductFeature } from './subscription'

export interface DigitalSignature {
  key: string
  locale: string
  pageId: string
  signature: string
  tenantId: string
  token: string
  uriPublicHosted: string
}

export interface ZuoraResponse {
  redirectUrl: string
  refId: string
  responseFrom: string
  signature: string
  success: string
  token: string
}

export enum PaymentType {
  CreditCard = 1,
  Invoice,
}

export const paymentTypeOptions = [
  {
    label: 'paymentDetails.paymentTypes.creditCard',
    value: String(PaymentType.CreditCard),
  },
  {
    label: 'paymentDetails.paymentTypes.invoice',
    value: String(PaymentType.Invoice),
  },
]

export const PAYMENT_PRODUCT_VALIDATION_CONFIG = {
  [ProductFeature.DeloittePartner]: {
    isDeloittePartner: { fieldType: 'string', value: DeloitteClientType.No },
  },
  [ProductFeature.Payment]: {
    invoiceDetails: {
      contact: { fieldType: 'string' },
      email: { fieldType: 'string', pattern: emailRegEx },
    },
  },
}
