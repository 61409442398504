import { SvgIcon, SvgIconProps } from '@mui/material'

export const CheckThinIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        color='action'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.1464 7.14645C19.3417 6.95118 19.6583 6.95118 19.8536 7.14645C20.0271 7.32001
          20.0464 7.58944 19.9114 7.78431L19.8536 7.85355L9.85355 17.8536C9.67999 18.0271 9.41056
          18.0464 9.21569 17.9114L9.14645 17.8536L5.14645 13.8536C4.95118 13.6583 4.95118 13.3417
          5.14645 13.1464C5.32001 12.9729 5.58944 12.9536 5.78431 13.0886L5.85355 13.1464L9.5
          16.793L19.1464 7.14645Z'
        />
      </SvgIcon>
    </>
  )
}
