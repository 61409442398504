import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import subscriptionRequestReducer from 'features/subscriptionRequest/slice'
import manageUsersReducer from 'features/manageUsers/slices/manageUsers'
import manageAdminsReducer from 'features/manageAdmins/slices/manageAdmins'
import employeesReducer from 'features/manageAdmins/slices/employees'
import jurisdictionsReducer from 'features/manageAdmins/slices/jurisdictions'
import managePackagesReducer from 'features/managePackage/slice'
import uiReducer from 'sharedSlices/ui'
import localizationReducer from 'sharedSlices/localization'
import manageProductReducer from 'features/manageProduct/slice'
import manageReportsReducer from 'features/manageReports/slice'
import ProductConfigurationReducer from 'features/productConfiguration/slice'
import authReducer from 'sharedSlices/auth'

export const reducer = {
  manageAdmins: manageAdminsReducer,
  employees: employeesReducer,
  manageUsers: manageUsersReducer,
  jurisdictions: jurisdictionsReducer,
  manageProduct: manageProductReducer,
  manageReports: manageReportsReducer,
  productConfiguration: ProductConfigurationReducer,
  subscriptionRequest: subscriptionRequestReducer,
  ui: uiReducer,
  auth: authReducer,
  localization: localizationReducer,
  managePackage: managePackagesReducer,
}

export const store = configureStore({ reducer, devTools: process.env.NODE_ENV === 'development' })

export const getStoreWithState = (preloadedState: RootState) => {
  return configureStore({ reducer, preloadedState })
}

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
