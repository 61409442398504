import { FC, ReactNode } from 'react'
import { Box, IconButton, IconButtonProps, SxProps, Tooltip } from '@mui/material'
import { ApproveIcon } from 'components/icons/Approve.icon'
import { EditProductIcon } from 'components/icons/EditProduct.icon'
import { EditProfileIcon } from 'components/icons/EditProfile.icon'
import { RejectIcon } from 'components/icons/Reject.icon'
import { TransactionLogIcon } from 'components/icons/TransactionLog.icon'
import { ReactivateIcon } from 'components/icons/Reactivate.icon'
import { InactivateIcon } from 'components/icons/Inactivate.icon'
import { RenewIcon } from 'components/icons/Renew.icon'

const actionStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  ml: '50px',
  mr: '16px',
  height: '100%',
}
interface IButtonActions {
  children: JSX.Element | ReactNode | ReactNode[]
}
interface IActionsProps extends IconButtonProps {
  title: string
  onClick: () => void
}
const componentsProps = { tooltip: { sx: { marginBottom: '4px!important' } } }
const iconStyle: SxProps = {
  p: '4px',
}

const TransactionLog: FC<IActionsProps> = ({ onClick, title, ...rest }) => (
  <Tooltip title={title} placement='top-end' arrow disableInteractive componentsProps={componentsProps}>
    <IconButton onClick={onClick} sx={iconStyle} color='inherit' disabled {...rest}>
      <TransactionLogIcon />
    </IconButton>
  </Tooltip>
)

const AccountDetails: FC<IActionsProps> = ({ onClick, title, ...rest }) => (
  <Tooltip title={title} placement='top-end' arrow disableInteractive componentsProps={componentsProps}>
    <IconButton onClick={onClick} sx={iconStyle} color='inherit' {...rest}>
      <EditProductIcon />
    </IconButton>
  </Tooltip>
)
const ManageUsers: FC<IActionsProps> = ({ onClick, title, ...rest }) => (
  <Tooltip title={title} placement='top-end' arrow disableInteractive componentsProps={componentsProps}>
    <IconButton onClick={onClick} sx={iconStyle} color='inherit' disabled {...rest}>
      <EditProfileIcon />
    </IconButton>
  </Tooltip>
)
const Approve: FC<IActionsProps> = ({ onClick, title, ...rest }) => (
  <Tooltip title={title} placement='top-end' arrow disableInteractive componentsProps={componentsProps}>
    <IconButton onClick={onClick} sx={iconStyle} color='inherit' {...rest}>
      <ApproveIcon />
    </IconButton>
  </Tooltip>
)
const Reject: FC<IActionsProps> = ({ onClick, title, ...rest }) => (
  <Tooltip title={title} placement='top-end' arrow disableInteractive componentsProps={componentsProps}>
    <IconButton onClick={onClick} sx={iconStyle} color='inherit' {...rest}>
      <RejectIcon />
    </IconButton>
  </Tooltip>
)
const Inactivate: FC<IActionsProps> = ({ onClick, title, ...rest }) => (
  <Tooltip title={title} placement='top-end' arrow disableInteractive componentsProps={componentsProps}>
    <IconButton onClick={onClick} sx={iconStyle} color='inherit' {...rest}>
      <InactivateIcon />
    </IconButton>
  </Tooltip>
)
const Reactivate: FC<IActionsProps> = ({ onClick, title, ...rest }) => (
  <Tooltip title={title} placement='top-end' arrow disableInteractive componentsProps={componentsProps}>
    <IconButton onClick={onClick} sx={iconStyle} color='inherit' {...rest}>
      <ReactivateIcon />
    </IconButton>
  </Tooltip>
)
const Renew: FC<IActionsProps> = ({ onClick, title, ...rest }) => (
  <Tooltip
    title={title}
    placement='top-end'
    arrow
    disableInteractive
    componentsProps={{ tooltip: { sx: { marginBottom: '8px!important' } } }}
  >
    <IconButton onClick={onClick} sx={{ p: 0 }} color='inherit' {...rest}>
      <RenewIcon sx={{ width: '30px', height: '30px' }} />
    </IconButton>
  </Tooltip>
)

const Action: FC<IButtonActions> = ({ children }) => {
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Box sx={actionStyles}>{children}</Box>
    </Box>
  )
}

export default Object.assign(Action, {
  Approve,
  Reject,
  Reactivate,
  Inactivate,
  ManageUsers,
  AccountDetails,
  TransactionLog,
  Renew,
})
