import { useMemo, useRef, useState } from 'react'
import { Box, Chip, FormControlLabel, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  setAskAreDeloitteClient,
  setEnableManagingUsers,
  setIsEdit,
  setProductName,
  setProductShortName,
  setRoles,
  setSectionsState,
  setSelectedJurisdictions,
} from 'features/productConfiguration/slice'
import { SectionName } from 'features/productConfiguration/constants'
import { ProductSectionHeader } from 'features/manageProduct/components/ProductSectionHeader'
import { checkProductGroupNameIsUnique, checkProductShortNameIsUnique } from 'features/productConfiguration/services'
import { CustomTextField } from 'components/CustomTextField'
import { SelectWithChips } from 'components/SelectWithChips'
import { CustomFormLabel } from 'components/CustomFormLabel'
import { CustomAlert } from 'components/CustomAlert'
import { MockFooterBlock } from 'components/Footer/mockFooterBlock'
import { CustomCheckbox } from 'components/CustomCheckbox'
import { useContainerScroll } from 'utils/scrollableFooter'
import colors from 'theme/colors'

const headerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '56px',
  padding: '10px 16px',
  borderBottom: '1px solid',
  borderColor: colors.border,
}

export const ProductDetails = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { productData, sectionsState, jurisdictions, isEdit } = useAppSelector((state) => state.productConfiguration)
  const [name, setName] = useState(productData.name)
  const [shortName, setShortName] = useState(productData.shortName)
  const [areDeloitteClient, setAreDeloitteClient] = useState(productData.askAreDeloitteClient)
  const [selectedJurisdictions, selectJurisdictions] = useState(productData.jurisdictions)
  const [addingRoles, setAddingRoles] = useState(!!productData.roles.length)
  const [managingUsers, setManagingUsers] = useState(productData.managingUsers)
  const [rolesString, setRolesString] = useState(productData.roles.join(', '))
  const [productNameIsNotUnique, setProductNameIsNotUnique] = useState(false)
  const [productShortNameIsNotUnique, setProductShortNameIsNotUnique] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [areEmptyRequired, setAreEmptyRequired] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const isProductDetailsInvalid = useMemo(() => {
    return (
      !productData.name ||
      !productData.shortName ||
      !productData.jurisdictions.length ||
      (addingRoles && !productData.roles.length)
    )
  }, [productData, addingRoles])

  const resetErrors = () => {
    setAreEmptyRequired(false)
    setProductNameIsNotUnique(false)
    setProductShortNameIsNotUnique(false)
  }

  const isSaveDisabled = useMemo(() => {
    return (
      !name ||
      !shortName ||
      productNameIsNotUnique ||
      productShortNameIsNotUnique ||
      !selectedJurisdictions.length ||
      (addingRoles && !rolesString) ||
      areEmptyRequired
    )
  }, [
    name,
    shortName,
    selectedJurisdictions,
    addingRoles,
    rolesString,
    productNameIsNotUnique,
    productShortNameIsNotUnique,
    areEmptyRequired,
  ])

  const isRolesStingValid = useMemo(() => {
    return /[^,\s]/.test(rolesString)
  }, [rolesString])

  const isEditMode = useMemo(() => {
    return isEdit || isProductDetailsInvalid
  }, [isEdit, isProductDetailsInvalid])

  const onSave = () => {
    setIsSaved(true)
    if (!name.trim() || !shortName.trim() || (addingRoles && !isRolesStingValid)) {
      setAreEmptyRequired(true)
    } else {
      setAreEmptyRequired(false)
      Promise.all([checkProductGroupNameIsUnique(name.trim()), checkProductShortNameIsUnique(shortName.trim())]).then(
        ([isNameUnique, isShortNameUnique]) => {
          if (isNameUnique && isShortNameUnique) {
            const roles = rolesString.split(/\s*,\s*/).filter((role) => role)
            setIsSaved(false)
            setProductNameIsNotUnique(false)
            dispatch(setProductName(name.trim()))
            dispatch(setProductShortName(shortName.trim()))
            setName(name.trim())
            setShortName(shortName.trim())
            dispatch(setAskAreDeloitteClient(areDeloitteClient))
            dispatch(setEnableManagingUsers(managingUsers))
            dispatch(setSelectedJurisdictions(selectedJurisdictions))
            dispatch(setRoles(roles))
            setRolesString(roles.join(', '))
            dispatch(setIsEdit(false))
          } else {
            !isNameUnique && setProductNameIsNotUnique(true)
            !isShortNameUnique && setProductShortNameIsNotUnique(true)
          }
        }
      )
    }
  }

  const onEdit = () => {
    dispatch(setIsEdit(true))
    dispatch(
      setSectionsState(
        sectionsState.map((section) => {
          return section.name !== SectionName.Details ? { ...section, available: false } : section
        })
      )
    )
  }

  useContainerScroll(containerRef, dispatch)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
    >
      <ProductSectionHeader isEditMode={isEditMode} isSaveDisabled={isSaveDisabled} onSave={onSave} onEdit={onEdit} />
      <Box
        ref={containerRef}
        sx={{
          padding: '16px',
          overflowY: 'auto',
          width: '100%',
        }}
      >
        <Box
          sx={{
            border: '1px solid',
            borderColor: colors.border,
            borderRadius: '4px',
          }}
        >
          <Typography sx={headerStyles} variant='h4' fontWeight='600'>
            {t('productConfiguration.productDetails.title')}
          </Typography>
          {(productNameIsNotUnique || productShortNameIsNotUnique) && (
            <CustomAlert text={t('productConfiguration.productDetails.name.isNotUnique')!} severity={'error'} />
          )}
          {areEmptyRequired && (
            <CustomAlert text={t('productConfiguration.form.completeRequired')!} severity={'error'} />
          )}
          <Box sx={{ padding: '16px', width: '832px' }}>
            <CustomTextField
              sx={{
                '& p': {
                  fontWeight: isEditMode ? 400 : 600,
                },
              }}
              readonly={!isEditMode}
              error={isSaved && (!name.trim() || productNameIsNotUnique)}
              limitation={200}
              helperText={t('productConfiguration.form.informationMessage')!}
              label={t('productConfiguration.productDetails.name.label')!}
              placeholder={t('productConfiguration.productDetails.name.placeholder')!}
              value={name}
              highlightOnFocus
              multiline
              onChange={(e) => {
                setName(e.target.value)
                resetErrors()
              }}
            />

            <CustomTextField
              sx={{
                marginTop: '16px',
                '& p': {
                  fontWeight: isEditMode ? 400 : 600,
                },
              }}
              readonly={!isEditMode}
              error={isSaved && (!shortName.trim() || productShortNameIsNotUnique)}
              limitation={200}
              helperText={t('productConfiguration.form.informationMessage')!}
              label={t('productConfiguration.productDetails.shortName.label')!}
              placeholder={t('productConfiguration.productDetails.shortName.placeholder')!}
              value={shortName}
              highlightOnFocus
              multiline
              onChange={(e) => {
                setShortName(e.target.value)
                resetErrors()
              }}
            />

            <FormControlLabel
              sx={{
                marginTop: '8px',
                marginBottom: '8px',
                marginLeft: '-9px',
                '& span': {
                  fontSize: 14,
                },
                '& .MuiFormControlLabel-label.Mui-disabled': {
                  color: 'common.black',
                },
              }}
              disabled={!isEditMode}
              control={
                <CustomCheckbox checked={areDeloitteClient} onChange={() => setAreDeloitteClient(!areDeloitteClient)} />
              }
              label={t('productConfiguration.productDetails.askAreDeloitteClient')}
            />

            <SelectWithChips
              values={selectedJurisdictions}
              label={t('productConfiguration.productDetails.jurisdictions.label')!}
              placeholder={t('productConfiguration.productDetails.jurisdictions.placeholder')}
              readonly={!isEditMode}
              onChange={(e) => selectJurisdictions(e.target.value as string[])}
              onDelete={(item) => {
                selectJurisdictions(selectedJurisdictions.filter((jur) => jur !== item))
              }}
            >
              {jurisdictions.map((jurisdiction) => (
                <MenuItem key={jurisdiction.id} value={jurisdiction.name}>
                  {t(`country.${jurisdiction.code.toLowerCase()}.name`)}
                </MenuItem>
              ))}
            </SelectWithChips>

            <FormControlLabel
              sx={{
                marginTop: '8px',
                marginLeft: '-9px',
                '& span': {
                  fontSize: 14,
                },
                '& .MuiFormControlLabel-label.Mui-disabled': {
                  color: 'common.black',
                },
              }}
              disabled={!isEditMode}
              control={
                <CustomCheckbox
                  checked={managingUsers}
                  onChange={() => {
                    setManagingUsers(!managingUsers)
                  }}
                />
              }
              label={t('productConfiguration.productDetails.enableManagingUsers')}
            />

            {isEditMode && (
              <FormControlLabel
                sx={{
                  marginTop: '8px',
                  '& span': {
                    fontSize: 14,
                  },
                  '& .MuiFormControlLabel-label.Mui-disabled': {
                    color: 'common.black',
                  },
                }}
                control={
                  <CustomCheckbox
                    checked={addingRoles}
                    onChange={() => {
                      setRolesString('')
                      dispatch(setRoles([]))
                      setAddingRoles(!addingRoles)
                    }}
                  />
                }
                label={t('productConfiguration.productDetails.addRoles')}
              />
            )}

            {addingRoles && (
              <Box
                sx={{
                  marginTop: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {isEditMode ? (
                  <CustomTextField
                    value={rolesString}
                    label={t('productConfiguration.productDetails.roles.label')!}
                    error={isSaved && !isRolesStingValid}
                    limitation={200}
                    helperText={t('productConfiguration.productDetails.roles.info')!}
                    placeholder={t('productConfiguration.productDetails.roles.placeholder')!}
                    highlightOnFocus
                    onChange={(e) => {
                      setRolesString(e.target.value)
                      resetErrors()
                    }}
                    multiline
                  />
                ) : (
                  <>
                    <CustomFormLabel value={t('productConfiguration.productDetails.roles.label')} />
                    <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                      {productData.roles.map((role) => (
                        <Chip sx={{ margin: 0 }} key={role} label={role} />
                      ))}
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
        <MockFooterBlock />
      </Box>
    </Box>
  )
}
