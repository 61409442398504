import { FC, useEffect } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TitleBadge } from '../TitleBadge'
import { PackagesTable } from '../PackagesTable'
import { RejectSubModal } from '../RejectSubModal'
import { ApproveSubModal } from '../ApproveSubModal'
import { SelectProductGroup } from '../SelectProductGroup'
import { SelectProductJurisdiction } from '../SelectProductJurisdiction'

import { resetAll } from 'features/managePackage/slice'

import { useAppDispatch } from 'app/hooks'
import { InactivateSubModal } from 'features/managePackage/components/InactivateSubModal'

export const PackageContainer: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    // clear state
    return () => {
      dispatch(resetAll())
    }
  }, [dispatch])

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
      }}
    >
      <Box sx={{ height: '48px', display: 'flex', width: '100%' }}>
        <SelectProductGroup />
        <SelectProductJurisdiction />
      </Box>
      <TitleBadge
        sx={{
          padding: '10px 16px',
          flex: '0 0 100%',
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor: 'border.main',
        }}
        title={t('manageProduct.tabs.subscriptions')}
      />
      <Box
        sx={{
          flex: '0 0 100%',
          height: 'calc(100% - 106px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ApproveSubModal />
        <RejectSubModal />
        <InactivateSubModal />
        <PackagesTable />
      </Box>
    </Box>
  )
}
