import { FC } from 'react'
import { Box, SxProps, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'

import { selectCurrentUserPermissions } from 'sharedSlices/auth'
import { selectPermissionIds } from 'features/managePackage/slice/selectors'

import { Permissions } from 'constants/permissions'

interface Props {
  title: string
  size?: number
  sx?: SxProps
  rowsPerPage?: JSX.Element
}

export const TitleBadge: FC<Props> = ({ title, size, sx, rowsPerPage }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const prodGroupShortNameSelected = searchParams.get('name')

  const { prodJurisSelected } = useAppSelector((state) => state.managePackage)
  const currentPermissions = useAppSelector(selectCurrentUserPermissions)
  const productPermissions = useAppSelector(selectPermissionIds)

  const showCreateSubscriptionButton = currentPermissions.includes(String(Permissions.AddSubscriptionOnBehalfOfClient))
  const enableCreateSubscriptionButton = productPermissions.includes(Permissions.AddSubscriptionOnBehalfOfClient)

  const handleNavigation = () => {
    navigate({
      pathname: `/subscribe/${prodGroupShortNameSelected}`,
      search: `?productId=${prodJurisSelected?.productId}&subscribeToProduct=true`,
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '58px',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.100',
        ...sx,
      }}
    >
      <Typography variant='h6' fontWeight={600} mr='9px'>
        {title}
      </Typography>
      {size && (
        <Box
          sx={{
            backgroundColor: 'border.main',
            height: '24px',
            borderRadius: '100px',
            textAlign: 'center',
            lineHeight: '22px',
            p: '0 8.6px',
          }}
        >
          <Typography fontWeight='600' variant='caption'>
            {size}
          </Typography>
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}></Box>
      {!!size && rowsPerPage && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          {rowsPerPage}
        </Box>
      )}
      {showCreateSubscriptionButton && (
        <Button
          disabled={!enableCreateSubscriptionButton}
          variant={'outlined'}
          sx={{
            marginLeft: '16px',
            flexShrink: 0,
            whiteSpace: 'nowrap',
          }}
          onClick={handleNavigation}
        >
          {t('manageProduct.subscriptionActions.create')}
        </Button>
      )}
    </Box>
  )
}
