import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { AuthSlice, IEmployeeInformation, IEndUserInformation } from './constants'
import { LocalizationState } from 'sharedSlices/localization/constants'
import { RootState } from 'app/store'
import { DateFormat } from 'constants/profileSettings'
import { IPermission } from 'features/manageAdmins/constants'
import { geoApi } from 'services/api/geoApi'

export const AUTH_INITIAL_STATE: AuthSlice = {
  employeeInformation: {
    email: '',
    firstName: '',
    lastName: '',
    roles: [],
    allProductsAccessible: false,
    profileConfig: null,
  },
  isLoading: false,
  isAuthenticated: false,
  error: undefined,
  endUserInformation: null,
}

const getUser = createAsyncThunk<IEmployeeInformation[], void, { rejectValue: AxiosError }>(
  'auth/getUser',
  async (_, { rejectWithValue }) => {
    try {
      return await geoApi.getAllGeo<IEmployeeInformation>('global/employees/current')
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

const getEndUser = createAsyncThunk<IEndUserInformation[], void, { rejectValue: AxiosError }>(
  'auth/getEndUser',
  async (_, { rejectWithValue }) => {
    try {
      return await geoApi.getAllGeo<IEndUserInformation>('users/view')
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

const auth = createSlice({
  name: 'auth',
  initialState: AUTH_INITIAL_STATE,
  reducers: {
    logout(state: AuthSlice) {
      state.error = undefined
      state.isLoading = false
      state.isAuthenticated = false
      state.employeeInformation = null
    },
    loginError: (state, action) => {
      state.isLoading = false
      state.isAuthenticated = false
      state.employeeInformation = null
      state.error = action.payload?.response
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.error = undefined
      state.isLoading = false
      if (action.payload.length) {
        state.isAuthenticated = true
        state.employeeInformation = action.payload.reduce((employee, curEmployee) => {
          return {
            ...curEmployee,
            roles: [...(employee.roles || []), ...curEmployee.roles],
          }
        }, {} as IEmployeeInformation)
      } else {
        state.isAuthenticated = false
      }
    })
    builder.addCase(getUser.rejected, (state, action) => {
      state.isLoading = false
      state.isAuthenticated = false
      state.error = action.payload?.response
    })
    builder.addCase(getEndUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getEndUser.fulfilled, (state, action) => {
      const endUser = action.payload.find((_endUser: IEndUserInformation) => _endUser)
      state.error = undefined
      state.isLoading = false
      if (endUser) {
        state.endUserInformation = { ...state.endUserInformation, ...endUser }
      }
    })
    builder.addCase(getEndUser.rejected, (state, action) => {
      state.isLoading = false

      if (!state.employeeInformation) {
        state.error = action.payload?.response
      }
    })
  },
})

const selectAuth = (store: RootState): AuthSlice => store.auth
const selectLocalization = (store: RootState): LocalizationState => store.localization

const getDateFormat = createSelector(
  selectAuth,
  ({ employeeInformation }: AuthSlice) => employeeInformation?.profileConfig?.dateTimeFormat ?? DateFormat.DDMonYYYY
)

const getMoneyFormat = createSelector(
  selectAuth,
  ({ employeeInformation }: AuthSlice) => employeeInformation?.profileConfig?.moneyFormat
)

const getPreferredLanguage = createSelector(
  [selectAuth, selectLocalization],
  ({ employeeInformation }: AuthSlice, { languageSelected }: LocalizationState) =>
    employeeInformation?.profileConfig?.localeCode
      ? employeeInformation?.profileConfig?.localeCode
      : languageSelected.localeCode
)

const selectCurrentUserPermissions = createSelector([selectAuth], ({ employeeInformation }: AuthSlice) => {
  if (employeeInformation) {
    const allPermissions: IPermission[] = employeeInformation.roles
      .map((role) => role.permissions?.map((permission) => permission) ?? [])
      .flat()

    return Array.from(new Set(allPermissions.map((p) => String(p.id))))
  }
  return []
})

export const { logout, loginError } = auth.actions
export { getUser, getEndUser, getDateFormat, getMoneyFormat, getPreferredLanguage, selectCurrentUserPermissions }
export default auth.reducer
