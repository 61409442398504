import { ChangeEvent, FC } from 'react'
import { Checkbox, SxProps } from '@mui/material'
import { CheckboxIcon } from 'components/icons/Checkbox.icon'
import { CheckboxCheckedIcon } from 'components/icons/CheckboxChecked.icon'
import colors from 'theme/colors'

interface Props {
  checked: boolean
  disabled?: boolean
  sx?: SxProps
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

export const CustomCheckbox: FC<Props> = ({ sx, disabled, checked, onChange }) => {
  return (
    <Checkbox
      sx={{
        color: 'common.white',
        '&.Mui-disabled': {
          color: 'menuItemHover.main',
          '.border': {
            stroke: colors.silverSand,
          },
          '.background': {
            fill: colors.silverSand,
          },
        },
        '& .MuiSvgIcon-root': {
          fontSize: '16px',
        },
        ...sx,
      }}
      disabled={disabled}
      checked={checked}
      icon={<CheckboxIcon />}
      checkedIcon={<CheckboxCheckedIcon />}
      onChange={onChange}
      data-testid='custom-checkbox'
    />
  )
}
