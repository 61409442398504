import { Box, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { UserName } from 'features/manageAdmins/components/userName'

import { formatDate } from 'utils/date'
import { getDateFormat } from 'sharedSlices/auth'
import { IPackageDetails } from 'features/managePackage/constants'
import { useAppSelector } from 'app/hooks'
import { OverflowTooltip } from 'components/OverflowTooltip'
import colors from 'theme/colors'

const cellStyles: SxProps = {
  display: 'grid',
  gridTemplateColumns: 'repeat(5,1fr)',
  rowGap: '16px',
  '& > div': {
    overflow: 'hidden',
    '& > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '&:first-of-type': {
        fontSize: '10px',
        textTransform: 'uppercase',
        fontWeight: '700',
        letterSpacing: 1,
        color: colors.mainGrey,
        mb: '4px',
      },
      '&:nth-of-type(2)': {
        fontSize: '14px',
        border: 'none',
        py: '1px',
        p: 0,
      },
    },
  },
}

interface Props {
  packageDetails: IPackageDetails
}

export const PackageDetails = ({ packageDetails }: Props) => {
  const dateFormat = useAppSelector(getDateFormat)
  const { t } = useTranslation()
  const { trackingId, firstName, lastName, subscriberEmail, processedDate, processedBy } =
    packageDetails

  return (
    <Box sx={cellStyles}>
      <Box>
        <Typography>{t('subscriptionsDetails.subscriptionId')}</Typography>
        <Typography>
          <OverflowTooltip text={trackingId} />
        </Typography>
      </Box>
      <Box>
        <Typography>{t('subscriptionsDetails.subscriberName')}</Typography>
        <UserName firstName={firstName} lastName={lastName} />
      </Box>
      <Box>
        <Typography>{t('subscriptionsDetails.subscriberEmail')}</Typography>
        <Typography>
          <OverflowTooltip text={subscriberEmail} />
        </Typography>
      </Box>
      <Box>
        <Typography>{t('subscriptionsDetails.processedDate')}</Typography>
        <Typography>
          <OverflowTooltip text={processedDate ? formatDate(processedDate, dateFormat) : '-'} />
        </Typography>
      </Box>
      <Box>
        <Typography>{t('subscriptionsDetails.processedBy')}</Typography>
        <Typography>
          <OverflowTooltip text={processedBy ?? '-'} />
        </Typography>
      </Box>
    </Box>
  )
}
