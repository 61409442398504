import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  fetchProductGroups,
  fetchProducts,
  resetManageProductState,
} from 'features/manageProduct/slice'
import { SelectProduct } from 'features/manageProduct/components/SelectProduct'
import { Outlet } from 'react-router-dom'
import { SelectProductGroup } from 'features/manageProduct/components/SelectProductGroup'

export const ProductLayout: React.FC = () => {
  const { products, productGroups, selectedProductGroup } = useAppSelector(
    (state) => state.manageProduct
  )
  const { languageSelected } = useAppSelector((state) => state.localization)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!productGroups.length) {
      dispatch(fetchProductGroups())
    }
  }, [dispatch, productGroups])

  useEffect(() => {
    if (selectedProductGroup && !products.length) {
      dispatch(fetchProducts(selectedProductGroup.id))
    }
  }, [dispatch, selectedProductGroup])

  useEffect(() => {
    if (selectedProductGroup) {
      dispatch(fetchProducts(selectedProductGroup.id))
    }
  }, [dispatch, selectedProductGroup, languageSelected])

  useEffect(() => {
    return () => {
      dispatch(resetManageProductState())
    }
  }, [dispatch])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: '0 16px',
          minHeight: '48px',
          borderBottom: '1px solid',
          borderBottomColor: 'grey.100',
        }}
      >
        {!!productGroups.length && <SelectProductGroup />}
        {selectedProductGroup && <SelectProduct />}
      </Box>
      <Outlet />
    </>
  )
}
