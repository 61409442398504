/**
 * Returns an array of unique objects based on the `id` property.
 * @param items - An array of objects each containing an `id` property.
 * @returns An array of unique objects by `id`.
 */
export const getUniqueById = <T extends { id: string | number }>(items: T[]): T[] => {
  return items.reduce((accumulator, currentItem) => {
    const isDuplicate = accumulator.some((item) => item.id === currentItem.id)
    if (!isDuplicate) {
      accumulator.push(currentItem)
    }
    return accumulator
  }, [] as T[])
}
