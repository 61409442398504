import { Box, CircularProgress } from '@mui/material'

export const Loader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 99,
      }}
    >
      <CircularProgress data-testid='loading' />
    </Box>
  )
}
