export const notDeloitteEmailRegEx =
  /^[a-zA-Z0-9_.+-]+@((?!(.*\.)?deloitte\.)((([a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?([a-zA-Z0-9-]+)((\.[a-zA-Z0-9-]+)?)))\.[a-zA-Z]+$/
export const deloitteEmailRegEx = /^[a-zA-Z0-9_.+-]+@(deloitte)(?:(?:\.[a-zA-Z0-9-]+)?\.[a-zA-Z]+)$/
export const emailRegEx =
  /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export enum EmailValidationError {
  SubscriptionNotCreated,
  UnderReview,
  Exists,
  Invalid,
  UnavailableCountry,
  ExistsForEndUser,
  SubscriptionReadyUpgrade,
  SubscriptionExistsForAnotherProduct,
}

export enum EmailValidationErrorMessage {
  'subscriptionInit.email.error.underReview' = 1,
  'subscriptionInit.email.error.exists',
  'subscriptionInit.email.error.invalid',
  'subscriptionInit.email.error.unavailableCountry',
  'subscriptionInit.email.error.existsForEndUser',
}

export const EmailValidationBehalfClientMsg = {
  [EmailValidationError.UnderReview]: 'subscriptionInit.behalfOfClient.messages.subscriptionExists',
  [EmailValidationError.UnavailableCountry]: 'subscriptionInit.behalfOfClient.messages.unavailableCountry',
  [EmailValidationError.SubscriptionExistsForAnotherProduct]:
    'subscriptionInit.behalfOfClient.messages.subscriptionExistsForAnotherProduct',
  [EmailValidationError.Exists]: 'subscriptionInit.behalfOfClient.messages.subscriptionExists',
  [EmailValidationError.SubscriptionReadyUpgrade]:
    'subscriptionInit.behalfOfClient.messages.subscriptionExistsForAnotherProduct',
}

export type TEmailValidationMsgKey = keyof typeof EmailValidationBehalfClientMsg
