import { PAYMENT_PRODUCT_VALIDATION_CONFIG, PaymentType } from 'features/subscriptionRequest/constants/payment'
import { IPaymentDetailsData, ProductFeature } from 'features/subscriptionRequest/constants/subscription'

export interface IValidationRule {
  fieldType: 'string' | 'number' | 'boolean'
  value?: string | number | boolean
}

export interface IValidationRules {
  [key: string]: IValidationRule | IValidationRules
}

export interface IInitialValue {
  [key: string]: unknown
}

export interface IInitialValues {
  [key: string]: string | IInitialValue
}

export interface IStepsValidMap {
  [key: number]: boolean
}

export const validateFields = (initialValues: IInitialValues, validationRules: IValidationRules) => {
  function isFieldValid(value: unknown, rule: IValidationRule) {
    if (value === null || value === undefined || !value) return false

    if (rule.fieldType) {
      if (typeof value !== rule.fieldType) return false
      if ('value' in rule) {
        if (Array.isArray(rule.value)) {
          return rule.value.includes(value)
        }

        return (rule.value as string) === value
      }

      if (typeof value === 'string') {
        if ('pattern' in rule) {
          return (rule.pattern as RegExp).test(value as string)
        }
        return !!value.trim()
      }
    }
    return true
  }

  const recursiveValidate = (initial: IInitialValues | IInitialValue, rules: IValidationRules | IValidationRule) => {
    for (const key in rules) {
      const rule = (rules as IValidationRules)[key]
      const value = initial[key]

      if (typeof rule === 'object' && !Array.isArray(rule) && !rule.fieldType) {
        if (!recursiveValidate(value as IInitialValue, rule)) {
          return false
        }
      } else {
        if (!isFieldValid(value, rule as IValidationRule)) {
          return false
        }
      }
    }
    return true
  }

  return recursiveValidate(initialValues, validationRules)
}

export const generateValidationRules = (productFeatures: ProductFeature[], selectedPaymentType: number) => {
  return productFeatures.reduce((acc, feature) => {
    if (feature === ProductFeature.Payment && selectedPaymentType === PaymentType.Invoice) {
      acc['invoiceDetails'] = PAYMENT_PRODUCT_VALIDATION_CONFIG[ProductFeature.Payment].invoiceDetails
    }

    if (feature === ProductFeature.DeloittePartner) {
      const validRulesByFeature = PAYMENT_PRODUCT_VALIDATION_CONFIG[feature]
      return { ...acc, ...validRulesByFeature }
    }

    return acc
  }, {} as Record<string, unknown>)
}

export const generateCurrentValues = (
  validationRules: ReturnType<typeof generateValidationRules>,
  paymentDetailsData: IPaymentDetailsData
) => {
  return Object.keys(validationRules).reduce((values, rule) => {
    return { ...values, [rule]: paymentDetailsData[rule as keyof typeof paymentDetailsData] }
  }, {})
}
