import { SvgIcon, SvgIconProps } from '@mui/material'

export const ExportIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='15'
        height='17'
        viewBox='-4.5 0 24 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        color='secondary'
        {...props}
      >
        <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.5 5H5V6H1V16H14V6H10V5H14.5C14.7455 5 14.9496 5.17688 14.9919 5.41012L15 5.5V16.5C15 16.7455 14.8231 16.9496
        14.5899 16.9919L14.5 17H0.5C0.25454 17 0.0503916 16.8231 0.00805567 16.5899L0 16.5V5.5C0 5.25454 0.176875 5.05039
        0.410124 5.00806L0.5 5ZM7.41012 0.00805567L7.5 0C7.74546 0 7.94961 0.176875 7.99194 0.410124L8 0.5V11.292L10.1464
        9.14645C10.32 8.97288 10.5894 8.9536 10.7843 9.08859L10.8536 9.14645C11.0271 9.32001 11.0464 9.58944 10.9114
        9.78431L10.8536 9.85355L7.85355 12.8536C7.67999 13.0271 7.41056 13.0464 7.21569 12.9114L7.14645 12.8536L4.14645
        9.85355C3.95118 9.65829 3.95118 9.34171 4.14645 9.14645C4.32001 8.97288 4.58944 8.9536 4.78431 9.08859L4.85355
        9.14645L7 11.292V0.5C7 0.25454 7.17688 0.0503916 7.41012 0.00805567Z'
        />
      </SvgIcon>
    </>
  )
}
