import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchPackageRoles, fetchSubscriptions, setPagination } from 'features/manageProduct/slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { CustomTable, IColumnType, IPaginationInfo } from 'components/CustomTable'
import { TitleBadge } from 'components/TitleBadge'
import { Avatar, Box } from '@mui/material'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { showMessage } from 'utils/errorMessageHandler'
import {
  DEFAULT_SUBSCRIPTION_SORTING,
  IComment,
  IPackageRole,
  IProcessPackages,
  ISubscription,
  ISubscriptionDetails,
  PackageProcess,
  PackageStatus,
} from 'features/manageProduct/constants'
import { SubscriptionDetails } from '../SubscriptionDetails'
import { ProcessSubscriptionPopup } from 'features/manageProduct/components/ProcessSubscriptionPopup'
import {
  getSubscriptionComment,
  getSubscriptionDetails,
  submitChangeRole,
  submitPackageApprove,
} from 'features/manageProduct/services/subscription/subscription'
import { CustomPagination } from 'components/CustomPagination/subscription'
import { ChangeRolePopup } from 'features/manageProduct/components/ChangeRolePopup'
import { ProductNotSelected } from 'features/manageProduct/components/ProductNotSelected'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { subscriptionListStyle } from './constants'
import { SubscriptionActions } from 'features/manageProduct/components/SubscriptionActions'
import { SubscriptionDetailsPopup } from 'features/manageProduct/components/SubscriptionDetailsPopup'
import { formatDate } from 'utils/date'
import { getDateFormat } from 'sharedSlices/auth'
import { Permissions } from 'constants/permissions'
import { RowsPerPage } from 'components/RowsPerPage'
import { CommentPopup } from 'features/manageProduct/components/CommentPopup'
import { Direction, ISortingInfo } from 'constants/sorting'

export const SubscriptionList: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { subscriptions, selectedProduct, selectedProductGroup, totalSubscriptions, pagination } = useAppSelector(
    (state) => state.manageProduct
  )
  const dateFormat = useAppSelector(getDateFormat)
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const [openProcessSubscriptionPopup, setOpenProcessSubscriptionPopup] = useState(false)
  const [openChangeRolePopup, setOpenChangeRolePopup] = useState(false)
  const [openCommentPopup, setOpenCommentPopup] = useState(false)
  const [openSubscriptionDetailsPopup, setOpenSubscriptionDetailsPopup] = useState(false)
  const [subscriptionDetails, setSubscriptionDetails] = useState<ISubscriptionDetails | null>(null)
  const [subscriptionComment, setSubscriptionComment] = useState<IComment | null>(null)
  const [activeSubscriptionId, setActiveSubscriptionId] = useState('')
  const [packageRole, setPackageRole] = useState<IPackageRole | null>(null)
  const [subscriptionProcessPackages, setSubscriptionProcessPackages] = useState<IProcessPackages>({})
  const [commentProcessSubscription, setCommentProcessSubscription] = useState('')
  const sorting = useRef<ISortingInfo>(DEFAULT_SUBSCRIPTION_SORTING)
  const subscriptionStatus = useRef<PackageStatus | undefined>()
  const packageProcess = useRef<PackageProcess>()
  const columns: IColumnType<ISubscription>[] = [
    {
      key: 'companyName',
      title: 'manageProduct.subscriptions.company',
      sorting: true,
      className: 'company-name',
    },
    {
      key: 'countryName',
      title: 'manageProduct.subscriptions.jurisdiction',
      sorting: true,
      className: 'jurisdiction',
      render: (_, { countryNameAbbreviation }) => t(`country.${countryNameAbbreviation.toLowerCase()}.name`),
    },
    {
      key: 'userName',
      title: 'manageProduct.subscriptions.userName',
      sorting: true,
      className: 'user-name',
      render: (_, { firstName, lastName }) => (
        <Box display='flex' alignItems='center'>
          <Avatar
            sx={{
              width: '24px',
              height: '24px',
              fontSize: '10px',
              backgroundColor: 'common.black',
              textTransform: 'uppercase',
              marginRight: '8px',
            }}
          >
            {firstName.charAt(0) + lastName.charAt(0)}
          </Avatar>
          <OverflowTooltip text={`${firstName} ${lastName}`} />
        </Box>
      ),
    },
    {
      key: 'email',
      title: 'manageProduct.subscriptions.email',
      sorting: true,
      className: 'email',
    },
    {
      key: 'submissionDate',
      title: 'manageProduct.subscriptions.submissionDate',
      sorting: true,
      className: 'submission-date',
      render: (_, { packages }) => {
        const submissionDate = packages[0].submittedDate
        return formatDate(submissionDate, dateFormat)
      },
    },
  ]
  const isFirstRender = useRef(false)
  const { pathname } = location

  useEffect(() => {
    const productId = searchParams.get('productId')
    const productGroupShortName = searchParams.get('productGroupShortName')
    const page = searchParams.get('page')
    const size = searchParams.get('size')
    const jurCode = searchParams.get('jurCode')

    if (productId && page && size && productGroupShortName && jurCode) {
      isFirstRender.current = true
      const sortBy = searchParams.get('sortBy')
      const direction = searchParams.get('direction')

      if (sortBy && direction) {
        sorting.current = {
          column: sortBy,
          direction: direction as Direction,
        }
      }

      dispatch(
        setPagination({
          page: parseInt(page),
          size: parseInt(size),
        })
      )

      dispatch(
        fetchSubscriptions({
          productId,
          jurCode,
          sortingInfo: sorting.current,
          paginationInfo: {
            page: parseInt(page),
            size: parseInt(size),
          },
        })
      ).finally(() => setTimeout(() => (isFirstRender.current = false)))

      dispatch(fetchPackageRoles(productId))
    }
  }, [])

  useEffect(() => {
    if (!isFirstRender.current) {
      sorting.current = DEFAULT_SUBSCRIPTION_SORTING
    }
  }, [selectedProductGroup?.id])

  useEffect(() => {
    const productId = selectedProduct?.id

    if (productId && !isFirstRender.current) {
      const params = new URLSearchParams({
        productGroupShortName: selectedProductGroup!.shortName,
        productId,
        sortBy: sorting.current.column,
        direction: sorting.current.direction,
        page: pagination.page.toString(),
        size: pagination.size.toString(),
      })
      setSearchParams(params)

      dispatch(
        fetchSubscriptions({
          productId,
          jurCode: selectedProduct.jurisdiction!.code,
          sortingInfo: sorting.current,
          paginationInfo: {
            page: pagination.page,
            size: pagination.size,
          },
        })
      )
      dispatch(fetchPackageRoles(productId))
    }
    setSubscriptionProcessPackages({})
  }, [dispatch, selectedProduct?.id])

  const onChangeCommentProcessSubscription = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setCommentProcessSubscription(event.target.value)
  }, [])

  const onSubmitProcessSubscription = useCallback(() => {
    const productRole = subscriptionProcessPackages[activeSubscriptionId].role
    const payload = {
      note: commentProcessSubscription,
      packages: subscriptionProcessPackages[activeSubscriptionId].packages,
      ...(productRole && { productRole }),
    }

    submitPackageApprove(selectedProduct!.id, activeSubscriptionId, payload, selectedProduct!.jurisdiction!.code).then(
      () => {
        dispatch(
          fetchSubscriptions({
            productId: selectedProduct!.id,
            jurCode: selectedProduct!.jurisdiction!.code,
            sortingInfo: sorting.current,
            paginationInfo: {
              page: pagination.page,
              size: pagination.size,
            },
          })
        )
        setOpenProcessSubscriptionPopup(false)
        setCommentProcessSubscription('')
        showMessage.success('mySnackbar', 'manageProduct.subscriptionMessage.success')
      }
    )
  }, [activeSubscriptionId, commentProcessSubscription])

  const onSubmitChangeRole = useCallback(() => {
    submitChangeRole(selectedProduct!.id, activeSubscriptionId, packageRole!, selectedProduct!.jurisdiction!.code).then(
      () => {
        dispatch(
          fetchSubscriptions({
            productId: selectedProduct!.id,
            jurCode: selectedProduct!.jurisdiction!.code,
            sortingInfo: sorting.current,
            paginationInfo: {
              page: pagination.page,
              size: pagination.size,
            },
          })
        )
        setSubscriptionProcessPackages({})
        setOpenChangeRolePopup(false)
      }
    )
  }, [activeSubscriptionId, packageRole])

  const onCloseProcessSubscriptionPopup = useCallback(() => {
    setOpenProcessSubscriptionPopup(false)
    setCommentProcessSubscription('')
  }, [])

  const processAll = (subscription: ISubscription, isApprove: boolean) => {
    packageProcess.current = isApprove ? PackageProcess.Approve : PackageProcess.Reject
    setSubscriptionProcessPackages({
      ...subscriptionProcessPackages,
      [subscription.id]: {
        role: !isApprove ? null : subscriptionProcessPackages[subscription.id]?.role || null,
        packages: subscription.packages
          .filter((_package) =>
            [PackageStatus.Pending, PackageStatus.PendingRenew, PackageStatus.PendingReactivation].includes(
              _package.status
            )
          )
          .map((item) => ({
            id: item.id,
            isApprove,
          })),
      },
    })
  }

  const changePackageProcess = (
    subscription: ISubscription,
    process: PackageProcess | null,
    packageId: string,
    role: IPackageRole | null
  ) => {
    if (process) {
      packageProcess.current = process
    }
    setSubscriptionProcessPackages({
      ...subscriptionProcessPackages,
      [subscription.id]: {
        role: process === PackageProcess.Reject ? null : role,
        packages: [
          ...(subscriptionProcessPackages[subscription.id]?.packages || []).filter(
            (_package) => _package.id !== packageId
          ),
          { id: packageId, isApprove: process && process === PackageProcess.Approve },
        ],
      },
    })
  }

  const rowTemplateCollapse = useCallback(
    (subscription: ISubscription) => (
      <SubscriptionDetails
        subscription={subscription}
        processPackages={subscriptionProcessPackages}
        onChangePackageProcess={(process, packageId, role) =>
          changePackageProcess(subscription, process, packageId, role)
        }
        onApproveAll={() => processAll(subscription, true)}
        onRejectAll={() => processAll(subscription, false)}
        onChangeRole={(role) => {
          setPackageRole(role)
          setActiveSubscriptionId(subscription.id)
          setOpenChangeRolePopup(true)
        }}
        onProcessSubmit={() => {
          setActiveSubscriptionId(subscription.id)
          setOpenProcessSubscriptionPopup(true)
          const packageStatus = subscription.packages.map((_package) => _package.status)
          subscriptionStatus.current = packageStatus.some((status) => status === PackageStatus.PendingRenew)
            ? PackageStatus.PendingRenew
            : PackageStatus.Pending
        }}
      ></SubscriptionDetails>
    ),
    [subscriptionProcessPackages]
  )

  const actions = useCallback(
    (subscription: ISubscription) => {
      const canEditSubscriptions = subscription.metadata!.allowedActions.includes(Permissions.ManageSubscriptions)
      const canManageUserList = subscription.metadata!.allowedActions.includes(Permissions.ManageUsers)

      return (
        <SubscriptionActions
          canEditSubscription={canEditSubscriptions}
          canManageUserList={canManageUserList}
          hasComment={subscription.hasComment}
          openComment={() => {
            getSubscriptionComment(subscription.id, selectedProduct!.jurisdiction!.code).then((comment: IComment) => {
              setSubscriptionComment(comment)
              setOpenCommentPopup(true)
            })
          }}
          goToManageUser={() => {
            localStorage.setItem('previousPath', `${pathname}${window.location.search}`)
            navigate({
              pathname: `/manage/user/subscriptions/${subscription.id}`,
              search: `?productId=${selectedProduct!.id}&jurCode=${selectedProduct!.jurisdiction!.code}`,
            })
          }}
          editSubscription={() => {
            navigate({
              pathname: `/subscribe/subscription/${subscription.id}`,
              search: `?productId=${selectedProduct!.id}&jurCode=${selectedProduct!.jurisdiction!.code}`,
            })
          }}
          openSubscriptionDetail={() => {
            getSubscriptionDetails(selectedProduct!.id, subscription.id, selectedProduct!.jurisdiction!.code).then(
              (_subscription) => {
                setSubscriptionDetails(_subscription)
                setOpenSubscriptionDetailsPopup(true)
              }
            )
          }}
        />
      )
    },
    [navigate, selectedProduct]
  )

  const getSubscriptions = useCallback(
    (_sorting: ISortingInfo, _pagination: IPaginationInfo) => {
      const params = new URLSearchParams({
        productGroupShortName: selectedProductGroup!.shortName,
        productId: selectedProduct!.id,
        sortBy: _sorting.column,
        direction: _sorting.direction,
        page: _pagination.page.toString(),
        size: _pagination.size.toString(),
      })
      setSearchParams(params)
      dispatch(
        fetchSubscriptions({
          productId: selectedProduct!.id,
          jurCode: selectedProduct!.jurisdiction!.code,
          sortingInfo: _sorting,
          paginationInfo: {
            page: _pagination.page,
            size: _pagination.size,
          },
        })
      )
    },
    [dispatch, selectedProduct, setSearchParams]
  )

  const onChangeSort = (sortingInfo: ISortingInfo) => {
    sorting.current = sortingInfo
    getSubscriptions(sortingInfo, pagination)
    setSubscriptionProcessPackages({})
  }

  const changeCurrentPage = useCallback(
    ({ page, size }: { page?: number; size?: number }) => {
      if (page) {
        const paginationProps = { page, size: size || pagination.size }
        dispatch(setPagination(paginationProps))
        getSubscriptions(sorting.current, paginationProps)
      }
    },
    [getSubscriptions, pagination.size, dispatch]
  )

  const onChangeRowPerPage = useCallback(
    (value: number) => {
      dispatch(
        setPagination({
          page: 1,
          size: value,
        })
      )
      getSubscriptions(sorting.current, {
        page: 1,
        size: value,
      })
    },
    [dispatch, getSubscriptions]
  )

  return (
    <>
      <TitleBadge
        sx={{ padding: '16px' }}
        title={t('manageProduct.tabs.subscriptions')}
        size={totalSubscriptions}
        rowsPerPage={
          <RowsPerPage totalItems={totalSubscriptions} value={pagination.size} onChange={onChangeRowPerPage} />
        }
      />
      {totalSubscriptions ? (
        <>
          <Box
            sx={{
              overflowY: 'auto',
              height: '100%',
              ...subscriptionListStyle,
            }}
          >
            <CustomTable<ISubscription>
              data={subscriptions}
              rowTemplateCollapse={rowTemplateCollapse}
              actions={actions}
              columns={columns}
              sorting={sorting.current}
              onSort={onChangeSort}
              overflowLayout={true}
            />
          </Box>
          <CustomPagination
            count={totalSubscriptions}
            rowsPerPage={pagination.size}
            currentPage={pagination.page}
            changeCurrentPage={changeCurrentPage}
          />
        </>
      ) : (
        <ProductNotSelected />
      )}
      <ProcessSubscriptionPopup
        header='processSubscriptionPopup.header'
        message='processSubscriptionPopup.message'
        label='processSubscriptionPopup.label'
        comment={commentProcessSubscription}
        onChangeComment={onChangeCommentProcessSubscription}
        onClose={onCloseProcessSubscriptionPopup}
        onSubmit={onSubmitProcessSubscription}
        open={openProcessSubscriptionPopup}
      ></ProcessSubscriptionPopup>
      <ChangeRolePopup
        onClose={() => setOpenChangeRolePopup(false)}
        onSubmit={onSubmitChangeRole}
        open={openChangeRolePopup}
      ></ChangeRolePopup>
      {subscriptionComment && (
        <CommentPopup
          commentInfo={subscriptionComment}
          onClose={() => setOpenCommentPopup(false)}
          open={openCommentPopup}
        ></CommentPopup>
      )}
      {subscriptionDetails && (
        <SubscriptionDetailsPopup
          subscriptionDetails={subscriptionDetails}
          onClose={() => setOpenSubscriptionDetailsPopup(false)}
          open={openSubscriptionDetailsPopup}
        ></SubscriptionDetailsPopup>
      )}
    </>
  )
}
