import { FC, useState, useEffect, memo } from 'react'
import { Box, Typography, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomForm from 'components/CustomForm'

import { useAppSelector } from 'app/hooks'

import { IJurisdiction } from '../../constants/interfaces'
import { CustomLoading } from 'components/CustomLoading'

interface Props {
  jurisdictions: IJurisdiction[]
  setValue: (fieldName: string, param: string | string[] | number) => void
  isFieldDisabled: boolean
}

const Loading = () => (
  <CustomLoading
    message='common.loader.loading'
    textColor='black'
    sx={{
      display: 'flex',
      width: '100%',
      height: '150px',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
    }}
  />
)

const JurisdictionField: FC<Props> = ({ setValue, isFieldDisabled, jurisdictions }) => {
  const { t } = useTranslation()
  const [isComponentDisabled, setIsComponentDisabled] = useState(true)
  const { isLoading } = useAppSelector((state) => state.jurisdictions)

  useEffect(() => {
    setIsComponentDisabled(isFieldDisabled)
  }, [isFieldDisabled])

  useEffect(() => {
    if (jurisdictions.length === 1) {
      setValue('jurisdictionId', jurisdictions[0].id)
    }
  }, [setValue, jurisdictions])

  if (isLoading) return <Loading />

  return (
    <>
      <CustomForm.Label value='manageAdmins.jurisdiction' />
      <CustomForm.Autocomplete<IJurisdiction>
        id='jurisdiction-auto'
        options={jurisdictions}
        disabled={isComponentDisabled}
        value={jurisdictions.length === 1 ? jurisdictions[0] : undefined}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(jurisdiction) => jurisdiction.name}
        onChange={(e, jurisdiction) => {
          if (jurisdiction && !Array.isArray(jurisdiction)) {
            setValue('jurisdictionId', jurisdiction.id)
          }
        }}
        renderOption={(props, jurisdiction) => (
          <Box component='span' {...props} key={jurisdiction.id}>
            <Typography variant='body2'>{jurisdiction.name}</Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ p: 0, m: 0 }}
            fullWidth
            name='jurisdictionId'
            placeholder={t('manageAdmins.jurisdictionPlaceHolder')!}
          />
        )}
      />
    </>
  )
}

export default memo(JurisdictionField)
