import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { ManageUsersTable } from '../manageUsersTable'

import { setSubscriptionId } from 'features/manageUsers/slices/manageUsers'
import { getUsers } from 'features/manageUsers/slices/manageUsers/actions'

import { useAppDispatch, useAppSelector } from 'app/hooks'

export const ManageUsersContainer = () => {
  const dispatch = useAppDispatch()
  const { subscriptionId } = useParams()
  const [searchParams] = useSearchParams()
  const jurCode = searchParams.get('jurCode')!
  const users = useAppSelector((state) => state.manageUsers.users)

  useEffect(() => {
    if (subscriptionId) {
      dispatch(getUsers({ jurCode, subscriptionId }))
      dispatch(setSubscriptionId(subscriptionId))
    }
  }, [dispatch, jurCode, subscriptionId])

  return <ManageUsersTable data={users} />
}
