import { SvgIcon, SvgIconProps } from '@mui/material'

export const CheckboxCheckedIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect className='background' width='16' height='16' rx='2' fill='#007CB0' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.3379 4.63143C12.5414 4.81802 12.5552 5.13431 12.3686 5.33787L6.86858 11.3379C6.77642
        11.4384 6.64721 11.4969 6.51086 11.4999C6.37452 11.5028 6.24288 11.45 6.14645
        11.3536L3.64645 8.85356C3.45118 8.65829 3.45118 8.34171 3.64645 8.14645C3.84171 7.95119
        4.15829 7.95119 4.35355 8.14645L6.48429 10.2772L11.6314 4.66214C11.818 4.45858 12.1343
        4.44483 12.3379 4.63143Z'
        fill='white'
      />
    </SvgIcon>
  )
}
