import { useSearchParams } from 'react-router-dom'

import { DEFAULT_SORTING } from '../constants'
import { Direction } from 'constants/sorting'

import { searchParamToInt } from '../utils/searchParamToInt'
import { useEffect, useState } from 'react'

export const useGetUrlParams = () => {
  const [searchParams] = useSearchParams()
  const [jurCode, setJurCode] = useState<string | null>('')
  const [page, setPage] = useState<number | null>(null)
  const [size, setSize] = useState<number | null>(null)
  const [direction, setDirection] = useState(DEFAULT_SORTING.direction)
  const [column, setColumn] = useState(DEFAULT_SORTING.column)
  const [productId, setProductId] = useState<string | null>('')

  useEffect(() => {
    setJurCode(searchParams.get('code'))
    setPage(searchParamToInt(searchParams.get('page')))
    setSize(searchParamToInt(searchParams.get('size'), 10))
    setDirection(searchParams.get('direction') as Direction)
    setProductId(searchParams.get('productId'))
    setColumn(searchParams.get('column') as string)
  }, [searchParams])

  return {
    page,
    size,
    column,
    direction,
    jurCode,
    productId,
  }
}
