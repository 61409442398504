import { SvgIcon, SvgIconProps } from '@mui/material'

export const TransactionLogIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.5009 19.9845C13.226 20.0016 13.0024 19.7767 13.0024 19.5012C13.0024
      19.2256 13.226 19.0042 13.5008 18.9845C14.9117 18.8839 16.2633 18.3578 17.3743
      17.4698C18.6155 16.4777 19.4824 15.093 19.8325 13.5431C20.1827 11.9932 19.9953
      10.3703 19.3012 8.94098C18.607 7.51166 17.4474 6.36093 16.0127 5.67782C14.5781
      4.99472 12.9538 4.81986 11.4066 5.18197C9.85947 5.54408 8.48148 6.42162
      7.49901 7.67045C6.51655 8.91928 5.98804 10.4651 6.0003 12.054C6.01077 13.4118
      6.41559 14.732 7.15813 15.8571L8.04957 13.0602C8.13343 12.7971 8.41448 12.6517
      8.67779 12.7356C8.94111 12.8196 9.08621 13.1008 9.00234 13.3639L7.78765
      17.175C7.70378 17.4381 7.42274 17.5835 7.15942 17.4995L3.34832 16.2848C3.085
      16.2009 2.93991 15.9197 3.02377 15.6566C3.10763 15.3935 3.38868 15.2481
      3.65199 15.3321L6.14975 16.1282C5.41338 14.9058 5.01374 13.5023 5.00262
      12.0617C4.98861 10.2464 5.59243 8.48033 6.71488 7.05356C7.83733 5.6268
      9.41166 4.62422 11.1793 4.21051C12.9469 3.79681 14.8026 3.99658 16.4416
      4.77702C18.0807 5.55746 19.4056 6.87215 20.1986 8.50512C20.9917 10.1381
      21.2058 11.9922 20.8057 13.763C20.4057 15.5337 19.4153 17.1157 17.9972 18.2492C16.7094 19.2785 15.1384 19.8823 13.5009 19.9845Z'
      />
      <path
        d='M13 8C13 7.72386 12.7761 7.5 12.5 7.5C12.2239 7.5 12 7.72386 12
      8V13.309L15.2764 14.9472C15.5234 15.0707 15.8237 14.9706 15.9472 14.7236C16.0707
      14.4766 15.9706 14.1763 15.7236 14.0528L13 12.691V8Z'
      />
    </SvgIcon>
  )
}
