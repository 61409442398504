import { useEffect, useCallback } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'

import { handleUpdateAdminsModal } from 'sharedSlices/ui'
import { getPermissions, resetPermissions, resetEmployeeSelected, getEmployeesByRole } from '../slices'
import {
  selectProductSelectInfo,
  selectEmployeeSelectedInfo,
  selectEmployeeSelectedRole,
  selectJurisdictionSelectedInfo,
  selectEmployeeSelectedPermissions,
} from '../slices/employees/selectors'

import { updateEmployeePermissions } from '../services'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { showMessage } from 'utils/errorMessageHandler'
import { IUpdateAdminFormData } from '../constants'

const initialValues = {
  permissionIds: [],
}

const validationSchema = Yup.object().shape({
  permissionIds: Yup.array().of(Yup.string()).required().min(1),
})

export const useUpdateAdminsForm = () => {
  const dispatch = useAppDispatch()
  const { roleName } = useParams()
  const { employeeSelected } = useAppSelector((state) => state.employees)
  const employeeInfo = useAppSelector(selectEmployeeSelectedInfo)
  const employeeRole = useAppSelector(selectEmployeeSelectedRole)
  const employeeProduct = useAppSelector(selectProductSelectInfo)
  const employeeJurisdiction = useAppSelector(selectJurisdictionSelectedInfo)
  const employeePermissions = useAppSelector(selectEmployeeSelectedPermissions)

  const updateAdminsFormData = ({ permissionIds }: IUpdateAdminFormData) => {
    if (employeeSelected) {
      updateEmployeePermissions({
        employeeRoleId: employeeSelected.employeeRoleId,
        permissions: permissionIds,
        jurisdiction: employeeSelected.jurisdiction,
      }).then(() => {
        dispatch(resetPermissions())
        dispatch(handleUpdateAdminsModal(false))
        dispatch(getEmployeesByRole(roleName!))
        showMessage.success('mySnackbar', 'manageAdmins.message.success')
      })
    }
  }

  const formikProps = useFormik<IUpdateAdminFormData>({
    initialValues,
    validationSchema,
    onSubmit: updateAdminsFormData,
  })

  const { setFieldValue } = formikProps

  const onClose = useCallback(() => {
    dispatch(resetPermissions())
    dispatch(handleUpdateAdminsModal(false))
    dispatch(resetEmployeeSelected())
  }, [dispatch])

  const setValue = useCallback(
    (fieldName: string, param: string | string[] | number) => setFieldValue(fieldName, param),
    [setFieldValue]
  )

  useEffect(() => {
    // loading modal
    employeeRole && dispatch(getPermissions({ roleId: employeeRole.id }))
  }, [dispatch, employeeRole])

  useEffect(() => {
    if (employeePermissions) {
      setValue(
        'permissionIds',
        employeePermissions.map((permission) => String(permission.id))
      )
    }
  }, [setValue, employeePermissions])

  return {
    onClose,
    setValue,
    employeeInfo,
    employeeRole,
    employeeProduct,
    employeeJurisdiction,
    formikProps,
  }
}
