import { SyntheticEvent, useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { getUsers } from '../slices/manageUsers'
import { handleEditPrimaryUserModal } from 'sharedSlices/ui'
import { updateUser } from '../services'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { showMessage } from 'utils/errorMessageHandler'
import { PRIMARY_USER, User } from '../constants'

interface IPrimaryUserForm {
  userId: string
}

const validationSchema = Yup.object().shape({
  userId: Yup.string().required(),
})

export const useEditPrimaryUser = () => {
  const dispatch = useAppDispatch()
  const { subscriptionId } = useAppSelector((state) => state.manageUsers)
  const [searchParams] = useSearchParams()
  const jurCode = searchParams.get('jurCode')!
  const userList = useAppSelector((state) => state.manageUsers.users)
  const primaryUser = userList.find((user) => user.role === PRIMARY_USER)!
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false)
  const initialValues = { userId: '' }

  const handleCloseModal = () => {
    dispatch(handleEditPrimaryUserModal(false))
  }

  const handleSubmitForm = async ({ userId }: IPrimaryUserForm) => {
    try {
      if (subscriptionId && primaryUser) {
        await updateUser({ subscriptionId, userId, jurCode })
        handleCloseModal()
        dispatch(getUsers({ jurCode, subscriptionId }))
        showMessage.success('mySnackbar', 'manageAdmins.message.success')
      }
    } catch (error) {
      showMessage.error('mySnackbar', 'manageAdmins.message.error')
    }
  }

  const formikProps = useFormik<IPrimaryUserForm>({
    validationSchema,
    initialValues,
    onSubmit: handleSubmitForm,
  })

  const { setFieldValue } = formikProps

  const setValue = useCallback(
    (fieldName: string, param: string | string[] | number | boolean) =>
      setFieldValue(fieldName, param),
    [setFieldValue]
  )

  const handleUserChange = (_: SyntheticEvent<Element, Event>, user: User | User[] | null) => {
    if (!Array.isArray(user) && user) {
      setIsConfirmButtonDisabled(user.id === primaryUser.id)
      setValue('userId', user.id)
    }
  }

  return {
    handleUserChange,
    handleCloseModal,
    formikProps,
    primaryUser,
    isConfirmButtonDisabled,
    userList,
  }
}
