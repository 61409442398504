import { createSelector } from '@reduxjs/toolkit'
import lodash from 'lodash'
import { RootState } from 'app/store'
import { ManagePackagesSlice } from '..'
import { AuthSlice } from 'sharedSlices/auth/constants'

import { removeRepeated } from 'features/manageAdmins/utils'

const selectPackage = (store: RootState): ManagePackagesSlice => store.managePackage
const selectAuth = (store: RootState): AuthSlice => store.auth

export const selectSortedProductJurisdictions = createSelector(
  selectPackage,
  ({ productJurisdictions }: ManagePackagesSlice) => {
    return lodash.cloneDeep(productJurisdictions).sort((a, b) => a.name.localeCompare(b.name))
  }
)

export const selectPermissionIds = createSelector(
  selectAuth,
  selectPackage,
  ({ employeeInformation }: AuthSlice, { prodJurisSelected }: ManagePackagesSlice) => {
    let permissionIds: number[] = []

    if (employeeInformation && prodJurisSelected) {
      const allPermissions = employeeInformation.roles
        .filter((role) => role.productId === prodJurisSelected.productId)
        .map((role) => role.permissions!)
        .flat()

      permissionIds = removeRepeated(allPermissions).map((p) => Number(p.id))
    }

    return permissionIds
  }
)
