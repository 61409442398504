import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import {
  IJurisdiction,
  IPagination,
  IProductGroup,
  IReportDetails,
  IReportPayload,
  IReportType,
} from 'features/manageReports/constants'

const selectReportTypes = (store: RootState): IReportType[] => store.manageReports.reportTypes
const selectDetails = (store: RootState): IReportDetails => store.manageReports.details
const selectJurisdictions = (store: RootState): IJurisdiction[] => store.manageReports.jurisdictions
const selectProductGroups = (store: RootState): IProductGroup[] => store.manageReports.productGroups
const selectPagination = (store: RootState): IPagination => store.manageReports.generatedReport.pagination

export const selectGenerationReportPayload = createSelector(
  selectReportTypes,
  selectJurisdictions,
  selectProductGroups,
  selectDetails,
  selectPagination,
  (reportTypes, jurisdictions, productGroups, details, pagination): IReportPayload => {
    const reportType = reportTypes.find((type) => type.id === details.reportTypeId)!
    return {
      reportTypeId: details.reportTypeId!,
      name: details.name,
      startDate: details.dates[0] || null,
      endDate: details.dates[1] || null,
      columnIds: details.columns.map((columnName) => {
        return reportType.columns.find((column) => column.name === columnName)!.id
      }),
      jurisdictionIds: details.jurisdictions || [],
      productGroupIds: details.productGroups || [],
      pagination,
    }
  }
)
