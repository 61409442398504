import { SvgIcon, SvgIconProps } from '@mui/material'

export const EditProfileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.8536 9.64645L20.8536 11.6464C21.0433 11.8362 21.0494 12.1419 20.8674
        12.3391L14.8674 18.8391C14.7727 18.9417 14.6395 19 14.5 19H12.5C12.2239 19
        12 18.7761 12 18.5V16.5C12 16.3743 12.0473 16.2532 12.1326 16.1609L18.1326
        9.66086C18.3252 9.45219 18.6527 9.44564 18.8536 9.64645ZM18.5144 10.7215L13
        16.6955V18H14.2811L19.8068 12.0139L18.5144 10.7215ZM14.86 11L13.94 12H9.5C8.17452
        12 7.08996 13.0315 7.00532 14.3356L7 14.5V17.5C7 17.7761 6.77614 18 6.5
        18C6.25454 18 6.05039 17.8231 6.00806 17.5899L6 17.5V14.5C6 12.6314
        7.46428 11.1049 9.30796 11.0052L9.5 11C11.5 11 12.86 11 14.86 11ZM12 4C13.6569
        4 15 5.34315 15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9
        5.34315 10.3431 4 12 4ZM12 5C10.8954 5 10 5.89543 10 7C10 8.10457 10.8954
        9 12 9C13.1046 9 14 8.10457 14 7C14 5.89543 13.1046 5 12 5Z'
      />
    </SvgIcon>
  )
}
