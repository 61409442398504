import { useCallback } from 'react'
import { Trans } from 'react-i18next'
import colors from 'theme/colors'
import { Link } from 'react-router-dom'
import { Button, Box } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { footerLinks } from 'constants/links'

export const Footer = () => {
  const { isFooterVisible } = useAppSelector((state) => state.ui)
  const { termsUrl, noticesUrl, cookiesUrl, generalInfoUrl } = footerLinks

  const cookieSettings = useCallback(() => {
    window.OneTrust?.ToggleInfoDisplay()
  }, [])

  return (
    <Box
      data-testid='main-footer'
      sx={{
        display: isFooterVisible ? 'block' : 'none',
        padding: '32px 48px 32px 48px',
        flexBasis: '133px',
        height: '133px',
        backgroundColor: colors.desertStorm,
        fontFamily: 'Open Sans',
        fontSize: '12px',
        lineHeight: '20px',
        marginTop: 'auto',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'grey.100',
        color: 'grey.600',

        a: {
          textDecoration: 'none',
          color: 'primary.main',
        },
      }}
    >
      <Box
        sx={{
          marginBottom: '16px',
          fontFamily: 'Open Sans SemiBold',
          height: '20px',
        }}
      >
        <Trans i18nKey='footer.copyright' values={{ year: new Date().getFullYear() }}>
          year <Link target='_blank' to={`${process.env.PUBLIC_URL}/${termsUrl}`}></Link>
          <Link target='_blank' to={`${process.env.PUBLIC_URL}/${cookiesUrl}`}></Link>
          <a target='_blank' rel='noreferrer noopener' href={noticesUrl}></a>
          <Button
            sx={{
              padding: '0',
              backgroundColor: 'transparent',
              border: 'none',
              textTransform: 'none',
              color: 'primary.main',
              fontFamily: 'Open Sans',
              fontSize: '12px',
              lineHeight: '20px',
              fontWeight: '600',

              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            onClick={cookieSettings}
          ></Button>
        </Trans>
      </Box>
      <Box
        sx={{
          fontSize: '10px',
          lineHeight: '16px',
        }}
      >
        <Trans i18nKey='footer.additionalText'>
          text
          <a href={generalInfoUrl} target='_blank' rel='noreferrer noopener'>
            link
          </a>
          text
        </Trans>
      </Box>
    </Box>
  )
}
