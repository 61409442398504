import { FC, ReactNode } from 'react'
import { FormGroup } from '@mui/material'

import SelectAll from './selectAll'
import PermissionsList from './permissionsList'

interface Props {
  children: ReactNode | ReactNode[]
}

const PermissionsField: FC<Props> = ({ children }) => {
  return (
    <FormGroup sx={{ m: '16px 0px 4px 0px', display: 'flex', alignItems: 'flex-start' }}>
      {children}
    </FormGroup>
  )
}

export default Object.assign(PermissionsField, {
  SelectAll: SelectAll,
  List: PermissionsList,
})
