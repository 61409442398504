import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Checkbox, MenuItem, SelectChangeEvent, Typography } from '@mui/material'
import isEqual from 'lodash.isequal'
import { NoData } from 'features/manageReports/component/NoData'
import {
  deleteColumn,
  deleteJurisdiction,
  deleteProductGroup,
  resetData,
  setColumns,
  setDates,
  setGeneratedData,
  setJurisdictions,
  setPagination,
  setProductGroups,
  setReportName,
  setReportType,
  setSelectedReport,
} from 'features/manageReports/slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ArrowDoubleRight } from 'components/icons/ArrowDoubleRight.icon'
import { CustomSelect } from 'components/CustomSelect'
import { CustomTextField } from 'components/CustomTextField'
import { CustomSelectChips } from 'components/CustomSelectChips'
import { ReportHeader } from '../ReportHeader'
import colors from 'theme/colors'
import DateRangePickerWithInput from 'components/DatePickerWithInput'
import {
  fetchJurisdictions,
  fetchReportTypes,
  fetchProductGroups,
  generateReport,
  createReport,
  getReport,
  updateReport,
} from 'features/manageReports/services'
import { GeneratedReport } from 'features/manageReports/component/GeneratedReport'
import { selectGenerationReportPayload } from 'features/manageReports/slice/selectors'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { DEFAULT_PAGINATION, IJurisdiction, IReport, IReportType } from 'features/manageReports/constants'
import { Loader } from 'components/Loader'
import { CustomArrowTooltip } from 'components/CustomTooltip'
import { InfoIcon } from 'components/icons/Info.icon'
import { getUniqueGeoCodes } from 'features/manageReports/utils/getUniqueGeoCodes'
import { areAllJurisdictionsSelected } from 'features/manageReports/utils/areAllJurisdictionsSelected'
import { geoApi } from 'services/api/geoApi'

export const ReportDetails = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const nameMaxLength = 100
  const [nameLength, setNameLength] = useState(0)
  const jurisdictionId = searchParams.get('jurId')
  const selectedReportId = params['reportId']
  const bottomElement = useRef<HTMLDivElement | null>()
  const [areDetailsOpen, setAreDetailsOpen] = useState(true)
  const [isSaveDisabled, setIsSaveDisabled] = useState(false)
  const [isJurisdictionSelectDisabled, setIsJurisdictionSelectDisabled] = useState(false)
  const [isJurisdictionMenuOpen, setIsJurisdictionMenuOpen] = useState(false)

  const { reportTypes, jurisdictions, productGroups, details, generatedReport, selectedReport } = useAppSelector(
    (state) => state.manageReports
  )

  const { allProductsAccessible } = useAppSelector((state) => state.auth.employeeInformation!)
  const reportPayload = useAppSelector(selectGenerationReportPayload)
  const detailsCounter =
    (details.jurisdictions || []).length +
    (details.productGroups || []).length +
    details.columns.length +
    Number(!!details.dates.length) +
    Number(!!details.name.trim()) +
    Number(!!details.reportTypeId)

  const selectedReportType = () => {
    return reportTypes.find((type) => type.id === details.reportTypeId)
  }

  const getReportTypeName = () => {
    return selectedReportType()?.name || ''
  }

  const changeReportType = (e: SelectChangeEvent) => {
    const reportType = reportTypes.find((type) => type.name === e.target.value)!
    dispatch(setReportType(reportType.id))
    dispatch(setColumns(reportType.columns.map((column) => String(column.name))))
  }

  const isGenerationDisabled = () => {
    return (
      !details.reportTypeId ||
      !details.columns.length ||
      !details.jurisdictions?.length ||
      (!generatedReport.result && !!selectedReport) ||
      (!!generatedReport.result && isEqual(details, generatedReport.details))
    )
  }

  const onGenerateReport = (_selectedReport?: IReport) => {
    const pagination = DEFAULT_PAGINATION
    const payload = {
      ...reportPayload,
      endDate: _selectedReport && !_selectedReport.endDate ? _selectedReport.generatedDate! : reportPayload.endDate,
    }
    dispatch(setPagination(pagination))
    dispatch(generateReport({ ...payload, pagination }))
    dispatch(
      setGeneratedData({
        payload,
        details,
        allProductsAccessible,
      })
    )
    setIsSaveDisabled(!!_selectedReport)
  }

  useEffect(() => {
    if (selectedReportId && jurisdictionId) {
      const jurCode = geoApi.jurMapping[jurisdictionId].code
      Promise.all([
        getReport(selectedReportId, jurCode),
        dispatch(fetchReportTypes()),
        dispatch(fetchProductGroups([])),
        dispatch(fetchJurisdictions([])),
      ]).then(([_selectedReport, _reportTypes]) => {
        dispatch(setProductGroups(_selectedReport.productGroupIds))
        dispatch(setJurisdictions(_selectedReport.jurisdictionIds))

        const reportType = (_reportTypes.payload as IReportType[])!.find(
          (type) => type.id === _selectedReport.reportTypeId
        )!
        const columnNames = _selectedReport.columnIds.map((columnId) => {
          return reportType.columns.find((column) => column.id === columnId)!.name
        })
        dispatch(setReportType(_selectedReport.reportTypeId))
        dispatch(setColumns(columnNames))
        dispatch(setReportName(_selectedReport.name))
        if (_selectedReport.startDate && _selectedReport.endDate) {
          dispatch(
            setDates([
              new Date(_selectedReport.startDate).toDateString(),
              new Date(_selectedReport.endDate).toDateString(),
            ])
          )
        }
        dispatch(setSelectedReport(_selectedReport))
        setIsSaveDisabled(true)
      })
    } else {
      if (!reportTypes.length) {
        dispatch(fetchReportTypes())
      }
      if (!jurisdictions.length) {
        dispatch(fetchJurisdictions([]))
      }
      if (!productGroups.length) {
        dispatch(fetchProductGroups([]))
      }
    }

    return () => {
      dispatch(resetData())
    }
  }, [dispatch])

  useEffect(() => {
    if (selectedReport) {
      onGenerateReport(selectedReport)
    }
  }, [selectedReport])

  useEffect(() => {
    const allSelected = areAllJurisdictionsSelected(jurisdictions, details.jurisdictions || [])
    setIsJurisdictionSelectDisabled(allSelected)
  }, [details.jurisdictions, jurisdictions])

  useEffect(() => {
    if (details.productGroups) {
      dispatch(fetchJurisdictions(details.productGroups))
    }
  }, [details.productGroups])

  useEffect(() => {
    if (details.jurisdictions) {
      dispatch(fetchProductGroups(details.jurisdictions))
    }
  }, [details.jurisdictions])

  const onChangeDate = useCallback(
    (value: [string, string] | []) => {
      const dates = value.map((date) => new Date(date).toDateString()) as [string, string]
      dispatch(setDates(dates))
    },
    [dispatch]
  )

  const onClear = () => {
    dispatch(resetData())
    dispatch(fetchJurisdictions([]))
    dispatch(fetchProductGroups([]))
  }

  const onSave = () => {
    const save = selectedReportId ? updateReport(selectedReportId, reportPayload) : createReport(reportPayload)
    save.then(() => {
      navigate('/manage/reports')
    })
  }

  const handleCharacterLimit = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target
    const name = value.substring(0, nameMaxLength)
    dispatch(setReportName(name))
    setNameLength(name.length)
  }

  const handleJurisdictionChange = (e: SelectChangeEvent<string[]>) => {
    const selectedJurisdictionIds = e.target.value as string[]
    const selectedGeoCodes = getUniqueGeoCodes(selectedJurisdictionIds, jurisdictions)

    const isAllJurisdictionsFromSingleGeoCodeSelected =
      selectedGeoCodes.length === 1 &&
      selectedJurisdictionIds.length === jurisdictions.filter((j) => j.geoCode === selectedGeoCodes[0]).length

    if (isAllJurisdictionsFromSingleGeoCodeSelected) {
      setIsJurisdictionSelectDisabled(true)
      setIsJurisdictionMenuOpen(false)
    } else {
      setIsJurisdictionSelectDisabled(false)
      setIsJurisdictionMenuOpen(true)
    }

    dispatch(setJurisdictions(selectedJurisdictionIds))
  }

  const isJurisdictionOptionDisabled = (jurisdiction: IJurisdiction): boolean => {
    const selectedJurisdictionIds = details.jurisdictions || []
    const uniqueGeoCodes = getUniqueGeoCodes(selectedJurisdictionIds, jurisdictions)
    const isSingleGeoCodeSelected = uniqueGeoCodes.length === 1

    return isSingleGeoCodeSelected && jurisdiction.geoCode !== uniqueGeoCodes[0]
  }

  const handleCloseJurisdictionSelect = () => setIsJurisdictionMenuOpen(false)

  const handleOpenJurisdictionSelect = () => setIsJurisdictionMenuOpen(true)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100% - 57px)',
        minHeight: '100%',
      }}
    >
      {selectedReport && !generatedReport.result && <Loader />}
      <ReportHeader
        isSaveDisabled={isSaveDisabled || !generatedReport.result?.length || !isEqual(details, generatedReport.details)}
        isCreateMode
        onClear={onClear}
        onSave={onSave}
      />
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          maxHeight: 'calc(100% - 57px)',
        }}
      >
        <Box
          sx={{
            width: areDetailsOpen ? '320px' : '52px',
            borderRight: '1px solid',
            borderRightColor: 'grey.100',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '7px 11px 7px 16px',
              borderBottom: '1px solid',
              borderBottomColor: 'grey.100',
              position: 'relative',
              ...(!areDetailsOpen && {
                paddingLeft: '9px',
              }),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                ...(!areDetailsOpen && {
                  position: 'absolute',
                  top: '58px',
                  left: '38px',
                  transform: 'rotate(90deg)',
                  transformOrigin: 'left top',
                }),
              }}
            >
              <Box>{t('reports.details.title')}</Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '4px 8.6px',
                  borderRadius: '24px',
                  backgroundColor: colors.bgDisabled,
                  marginLeft: '8px',
                }}
              >
                <Typography fontWeight='600' lineHeight='16px' variant='caption'>
                  {detailsCounter}
                </Typography>
              </Box>
            </Box>
            <Checkbox
              checked={areDetailsOpen}
              onClick={() => setAreDetailsOpen(!areDetailsOpen)}
              sx={{
                svg: { color: 'common.black', fontSize: 16 },
                '&:hover': { svg: { color: 'primary.main' } },
              }}
              icon={<ArrowDoubleRight />}
              checkedIcon={<ArrowDoubleRight sx={{ transform: 'rotate(180deg)' }} />}
            />
          </Box>

          <Box
            sx={{
              maxHeight: 'calc(100% - 49px)',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              ...(!areDetailsOpen && {
                display: 'none',
              }),
            }}
          >
            <Box
              sx={{
                height: '100%',
                padding: '24px 24px 0 24px',
                overflowY: 'auto',
                '& > div:not(:nth-last-of-type(-n+2))': {
                  marginBottom: '16px',
                },
              }}
            >
              <CustomSelect
                placeholder={t('reports.details.type.placeholder')!}
                placeholderColor={colors.silverChalice}
                label={t('reports.details.type.label')!}
                value={getReportTypeName()}
                required
                onChange={(e) => changeReportType(e)}
                selectedTemplated={() => <>{t(getReportTypeName())}</>}
              >
                {reportTypes.map((type) => (
                  <MenuItem key={type.id} value={type.name}>
                    {t(type.name)}
                  </MenuItem>
                ))}
              </CustomSelect>
              <CustomTextField
                placeholder={t('reports.details.name.placeholder')!}
                label={t('reports.details.name.label')!}
                value={details.name}
                sx={{ mb: '0!important' }}
                onChange={handleCharacterLimit}
              />
              <Box sx={{ display: 'flex', justifyContent: 'right', mt: '8px' }}>
                <Typography variant='caption' color={colors.grey[800]}>
                  {nameLength}/{nameMaxLength}
                </Typography>
              </Box>
              <DateRangePickerWithInput
                label={t('reports.details.date.label')!}
                onChange={onChangeDate}
                value={details.dates}
              />

              {selectedReportType() && (
                <CustomSelectChips
                  placeholder={t('reports.details.columns.placeholder')!}
                  label={t('reports.details.columns.label')!}
                  value={details.columns}
                  required
                  selectAll
                  isSelectedAll={selectedReportType()!.columns.length === details.columns.length}
                  onChange={(e: SelectChangeEvent<string[]>) => {
                    const value = e.target.value
                    if (value[value.length - 1] === 'all') {
                      dispatch(
                        setColumns(
                          selectedReportType()!.columns.length === details.columns.length
                            ? []
                            : selectedReportType()!.columns.map((column) => String(column.name))
                        )
                      )
                      return
                    }
                    dispatch(setColumns(value as string[]))
                  }}
                  onDelete={(item) => dispatch(deleteColumn(item))}
                >
                  {selectedReportType()!.columns.map((column) => (
                    <MenuItem key={column.id} value={column.name}>
                      {t(column.name)}
                    </MenuItem>
                  ))}
                </CustomSelectChips>
              )}

              {!!jurisdictions.length && (
                <CustomSelectChips
                  data-testid='jurisdiction-select-chips'
                  disabled={isJurisdictionSelectDisabled}
                  required
                  icon={
                    <CustomArrowTooltip placement='top' title={t('reports.details.jurisdiction.tooltip')}>
                      <Box sx={{ width: '24px', height: '24px', ml: '4px' }}>
                        <InfoIcon />
                      </Box>
                    </CustomArrowTooltip>
                  }
                  open={isJurisdictionMenuOpen}
                  onClose={handleCloseJurisdictionSelect}
                  onOpen={handleOpenJurisdictionSelect}
                  placeholder={t('reports.details.jurisdiction.placeholder')!}
                  label={t('reports.details.jurisdiction.label')!}
                  value={details.jurisdictions || []}
                  onChange={handleJurisdictionChange}
                  renderValue={(id) => jurisdictions.find((jurisdiction) => jurisdiction.id === id)!.name}
                  onDelete={(item) => dispatch(deleteJurisdiction(item))}
                >
                  {jurisdictions.map((jurisdiction) => (
                    <MenuItem
                      key={jurisdiction.id}
                      value={jurisdiction.id}
                      disabled={isJurisdictionOptionDisabled(jurisdiction)}
                    >
                      {t(jurisdiction.name)}
                    </MenuItem>
                  ))}
                </CustomSelectChips>
              )}

              {!!productGroups.length && (
                <CustomSelectChips
                  placeholder={t('reports.details.product.placeholder')!}
                  label={t('reports.details.product.label')!}
                  value={details.productGroups || []}
                  onChange={(e: SelectChangeEvent<string[]>) => dispatch(setProductGroups(e.target.value as string[]))}
                  onClose={() => {
                    bottomElement.current?.scrollIntoView({ behavior: 'smooth' })
                  }}
                  renderValue={(id) => productGroups.find((productGroup) => productGroup.id === id)!.name}
                  onDelete={(item) => dispatch(deleteProductGroup(item))}
                >
                  {productGroups.map((productGroup) => (
                    <MenuItem sx={{ paddingRight: '32px' }} key={productGroup.id} value={productGroup.id}>
                      {t(productGroup.name)}
                    </MenuItem>
                  ))}
                </CustomSelectChips>
              )}
              <Box ref={bottomElement} />
            </Box>

            <Box sx={{ padding: '24px' }}>
              <Button
                variant='contained'
                sx={{
                  width: '100%',
                }}
                disabled={isGenerationDisabled()}
                onClick={() => onGenerateReport()}
              >
                {t('reports.buttons.generateReport')}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: `calc(100% - ${areDetailsOpen ? '320px' : '52px'})`,
          }}
        >
          {generatedReport.total ? <GeneratedReport /> : <NoData />}
        </Box>
      </Box>
    </Box>
  )
}
