import { useAppSelector } from 'app/hooks'
import { ICountry } from 'features/managePackage/constants'
import { IState } from 'features/subscriptionRequest/constants/subscription'
import { useTranslation } from 'react-i18next'

type IStateTemplateProps = {
  selectedState?: IState | null
  selectedCountry?: ICountry | null
}

const StateTemplate = ({ selectedState, selectedCountry }: IStateTemplateProps) => {
  const { name: stateName, abbreviation: stateAbbreviation } = selectedState || {}
  const {
    subscription: { country: currentCountry },
  } = useAppSelector((state) => state.subscriptionRequest)
  const isStateCountrySelected = selectedState?.name && selectedCountry
  const { t } = useTranslation()

  return (
    <>
      {isStateCountrySelected
        ? stateName
        : t(`states.${currentCountry?.abbreviation.toLowerCase()}.${stateAbbreviation?.toLowerCase()}`)}
    </>
  )
}

export default StateTemplate
