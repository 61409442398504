import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Box, Button, Link, List, ListItem } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { IProductDiscount } from 'features/subscriptionRequest/constants/subscription'
import { setIsExpandedDiscountInfo } from 'features/subscriptionRequest/slice'
import { CollapseAllIcon } from 'components/icons/CollapseAll.icon'
import { ExpandAllIcon } from 'components/icons/ExpandAll.icon'
import { TipIcon } from 'components/icons/TipIcon.icon'
import colors from 'theme/colors'

export const DiscountInfo: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { packages, productGroup, isExpandedDiscountInfo } = useAppSelector((state) => state.subscriptionRequest)
  const licenseLimit = packages[0].licenseQuantityLimit!

  const discounts = useMemo(() => {
    return [...productGroup.products[0].productBulkDiscounts!].sort((a, b) => a.fromLicenseAmount - b.fromLicenseAmount)
  }, [productGroup.products])

  const discountOption = useCallback(
    ({ id, toLicenseAmount, fromLicenseAmount, percentage }: IProductDiscount) => {
      const toAmount = toLicenseAmount < licenseLimit ? `-${toLicenseAmount}` : '+'
      return (
        <ListItem key={id} sx={{ display: 'list-item', p: 0 }}>
          {`${fromLicenseAmount}${toAmount} ${t('licenses')} - ${percentage * 100}%`}
        </ListItem>
      )
    },
    [licenseLimit, t]
  )

  return (
    <Box sx={{ marginTop: 'auto', p: '12px 16px' }}>
      <Box
        sx={{
          p: '5px 0',
          backgroundColor: colors.distantHorizon,
          border: `1px solid ${colors.laPazSiesta}`,
          borderRadius: '2px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
          <TipIcon />
          <Box sx={{ fontFamily: 'Open Sans SemiBold', lineHeight: '16px' }}>
            {t('subscriptionSummary.discount.tiered')}
          </Box>
          <Button
            sx={{ p: '4px 8px', '.MuiButton-endIcon': { ml: '4px' } }}
            endIcon={
              isExpandedDiscountInfo ? (
                <CollapseAllIcon sx={{ width: '24px', height: '24px' }} />
              ) : (
                <ExpandAllIcon sx={{ width: '24px', height: '24px' }} />
              )
            }
            onClick={() => dispatch(setIsExpandedDiscountInfo(!isExpandedDiscountInfo))}
          >
            {t(`subscriptionSummary.discount.${isExpandedDiscountInfo ? 'collapse' : 'expand'}`)}
          </Button>
        </Box>
        {isExpandedDiscountInfo && (
          <Box sx={{ p: '8px 8px 12px 8px' }}>
            <Box sx={{ fontSize: '14px' }}>
              <Box sx={{ lineHeight: '24px' }}>{t('subscriptionSummary.discount.description')}</Box>
              <List sx={{ listStyleType: 'disc', pl: '24px' }}>
                {discounts.map((discount) => discountOption(discount))}
              </List>
              <Trans i18nKey='subscriptionSummary.discount.info'>
                <Link target='_blank' href='https://dart.deloitte.com/USDART/home/help/dart-help-1/dart-pricing' />
              </Trans>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
