import { SxProps, Theme } from '@mui/material'

export const subscriptionDetailsStyle: SxProps<Theme> = {
  table: {
    thead: {
      tr: {
        th: {
          '&.package': {
            width: '14.10%',
          },
          '&.status': {
            width: '11.85%',
          },
          '&.reviewed-by': {
            width: '15.63%',
          },
          '&.review-date': {
            width: '10.19%',
          },
          '&.term': {
            width: '17.41%',
          },
          '&.role': {
            width: '11.38%',
          },
          '&.approve-reject': {
            width: '19.70%',
          },
        },
      },
    },
  },
}
