import { FC, useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import { Stepper } from 'features/subscriptionRequest/components/stepper'
import { useAppDispatch } from 'app/hooks'
import { Footer as StepperFooter } from 'features/subscriptionRequest/components/footer'
import { SubscriptionSummary } from 'features/subscriptionRequest/components/SubscriptionSummary'
import { Footer as MainFooter } from 'components/Footer'
import { useContainerScroll } from 'utils/scrollableFooter'
import { IStep } from 'features/subscriptionRequest/constants/stepper'
import { UpperMenu } from 'components/layouts/routerLayout/upperMenu'
import { SubscriptionAction } from 'features/subscriptionRequest/constants/subscription'
import { MockFooterBlock } from 'components/Footer/mockFooterBlock'

interface Props {
  activeStep: number
  children: React.ReactElement
  isContinueDisabled: boolean
  onCancelSubscription: () => void
  onSubmitSubscription: () => void
  onValidateEmail?: (targetStep: number) => void
  productGroupName: string
  steps: IStep[]
  subscriptionAction: SubscriptionAction
  activePolicyIndex?: number
  isShowSubscriptionSummary?: boolean
}

export const SubscriptionContainer: FC<Props> = ({
  activeStep,
  activePolicyIndex,
  children,
  isContinueDisabled,
  onCancelSubscription,
  onSubmitSubscription,
  onValidateEmail,
  productGroupName,
  steps,
  subscriptionAction,
  isShowSubscriptionSummary,
}) => {
  const container = useRef<HTMLDivElement | null>()
  const dispatch = useAppDispatch()
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    container.current?.getElementsByClassName('step-container')[0].scrollTo(0, 0)
  }, [activeStep, activePolicyIndex])

  useContainerScroll(containerRef, dispatch)

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
      ref={container}
    >
      <UpperMenu title={productGroupName} />
      <Stepper
        subscriptionAction={subscriptionAction}
        steps={steps}
        activeStep={activeStep}
        onValidateEmail={onValidateEmail}
      />
      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          height: '100%',
          overflowY: 'auto',
          position: 'relative',
        }}
        className='step-container'
      >
        <Box sx={{ flex: 1 }}>
          {children}
          <MockFooterBlock />
        </Box>
        {isShowSubscriptionSummary && <SubscriptionSummary />}
      </Box>
      <StepperFooter
        isContinueDisabled={isContinueDisabled}
        subscriptionAction={subscriptionAction}
        onSubmitSubscription={onSubmitSubscription}
        onCancelSubscription={onCancelSubscription}
        onValidateEmail={onValidateEmail}
      />
      <MainFooter />
    </Box>
  )
}
