import { FormEvent, useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'

import { showMessage } from 'utils/errorMessageHandler'

import { addUserBulk } from '../services'
import { handleAddMultipleUsersModal } from 'sharedSlices/ui'
import { UploadResult } from '../constants/upload.interface'
import { Severity } from 'constants/severity'
import { getUsers } from '../slices'

const isFileValid = (fileSelected: File) => {
  const { name, size } = fileSelected
  if (size <= 10485760 && name.includes('xlsx')) {
    return true
  }
  return false
}

const mapLocalization: { [key: string]: string } = {
  'manageUsers.bulkAdd.success': 'manageUsers.bulkAdd.success',
  'manageUsers.bulkAdd.haveInfo': 'manageUsers.bulkAdd.haveInfo',
  'manageUsers.bulkAdd.missingInfo': 'manageUsers.bulkAdd.missingInfo',
  'response.message.invalidFormat': 'manageProduct.uploadMessage.sizeTypeError',
  'manageProduct.users.addUsersBulk.emailHasValidSubscription':
    'manageUsers.bulkAdd.hasSubscription',
  'manageProduct.users.addUsersBulk.malformedEmail': 'manageUsers.bulkAdd.invalidEmail',
  'manageUsers.bulkAdd.emptyUserList': 'manageProduct.uploadMessage.sizeTypeError',
  'manageUsers.bulkAdd.duplicated': 'manageUsers.bulkAdd.duplicated',
  'manageUsers.bulkAdd.rewriteUser': 'manageUsers.bulkAdd.rewriteUser',
  'manageProduct.users.addUsersBulk.emailHasValidSubscriptionAnotherJurisdiction':
    'manageProduct.users.userHasSubscriptionInAnotherJurRow',
}
interface IAddBulkUsers {
  file?: File
  isSameOrganization: boolean
}

const initialState: IAddBulkUsers = {
  isSameOrganization: false,
  file: undefined,
}

export const useAddMultipleUsers = () => {
  const dispatch = useAppDispatch()
  const { subscriptionId } = useAppSelector((state) => state.manageUsers)
  const [searchParams] = useSearchParams()
  const jurCode = searchParams.get('jurCode')!
  const [formData, setFormData] = useState<IAddBulkUsers>(initialState)
  const [uploadResponse, setUploadResponse] = useState<UploadResult[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { file, isSameOrganization } = formData

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (!file || !subscriptionId) return
    setIsLoading(true)

    if (!isFileValid(file)) {
      setFormData((lastFD) => ({ ...lastFD, isSameOrganization: false }))
      setIsLoading(false)
      showMessage.error('mySnackbar', 'manageProduct.uploadMessage.uploadError')
      setErrorMessage('manageProduct.uploadMessage.sizeTypeError')
      return
    }
    setErrorMessage('')
    const data = new FormData()
    data.append('file', file)

    addUserBulk({ subscriptionId, file: data, jurCode })
      .then((resp) => {
        setIsLoading(false)
        showMessage.success('mySnackbar', 'manageProduct.uploadMessage.uploadSuccess')
        setUploadResponse(
          resp.map((res) => ({
            ...res,
            type: res.type.toLowerCase() as Severity,
            key: mapLocalization[res.key],
          }))
        )
      })
      .catch((err) => {
        setIsLoading(false)
        setUploadResponse([])
        showMessage.error('mySnackbar', 'manageProduct.uploadMessage.uploadError')
        setErrorMessage(mapLocalization[err.key])
      })
  }

  const handleChange = useCallback((name: string, value: boolean | File | undefined) => {
    setFormData((last) => ({ ...last, [name]: value }))
  }, [])

  const resetForm = useCallback(() => {
    setIsLoading(false)
    setUploadResponse([])
    setFormData(initialState)
    setErrorMessage('')
  }, [])

  const handleClose = () => {
    uploadResponse.length > 0 && subscriptionId && dispatch(getUsers({ jurCode, subscriptionId }))
    dispatch(handleAddMultipleUsersModal(false))
    resetForm()
  }

  return {
    errorMessage,
    isSameOrganization,
    values: formData,
    uploadResponse,
    isLoading,
    handleChange,
    handleClose,
    handleSubmit,
    resetForm,
  }
}
