import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, MenuItem, SelectChangeEvent, Typography } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { Jurisdiction, ProductConfigurationSection } from 'features/productConfiguration/constants'
import { CustomSelect } from 'components/CustomSelect'
import { OverflowTooltip } from 'components/OverflowTooltip'
import colors from 'theme/colors'

interface Props {
  onChangeJurisdictions: (event: SelectChangeEvent) => void
}

export const ProductJurisdictionFilters: FC<Props> = ({ onChangeJurisdictions }) => {
  const { t } = useTranslation()
  const { productData, currentSection, selectedJurisdiction } = useAppSelector(
    (state) => state.productConfiguration
  )

  const jurisdictions = useMemo(
    () => [Jurisdiction.All, ...productData.jurisdictions],
    [productData.jurisdictions]
  )

  const isJurisdictionDisabled = useCallback(
    (jurisdiction: string) => {
      switch (currentSection) {
        case ProductConfigurationSection.AcceptableUsePolicy:
          return (
            !!Object.keys(productData.policies).length &&
            (jurisdiction === Jurisdiction.All
              ? !productData.policies[jurisdiction]
              : !!productData.policies[Jurisdiction.All])
          )
        case ProductConfigurationSection.Privacy:
          return (
            !!Object.keys(productData.privacy).length &&
            (jurisdiction === Jurisdiction.All
              ? !productData.privacy[jurisdiction]
              : !!productData.privacy[Jurisdiction.All])
          )
        case ProductConfigurationSection.Integrations:
          return jurisdiction !== Jurisdiction.All
        default:
          return false
      }
    },
    [currentSection, productData.policies, productData.privacy]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        minHeight: '48px',
        borderBottom: '1px solid',
        borderBottomColor: colors.border,
        fontSize: '14px',
      }}
    >
      <Box marginRight='8px'>{t('productConfiguration.filters.product.label')}:</Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            backgroundColor: 'grey.800',
            borderRadius: '50%',
            width: '8px',
            height: '8px',
            flexShrink: 0,
            marginRight: '4px',
          }}
        ></Box>
        <Typography sx={{ maxWidth: '300px' }} variant='subtitle2'>
          <OverflowTooltip
            text={productData.name || t('productConfiguration.filters.product.new')}
          />
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
          marginLeft: '16px',
        }}
      >
        {t('productConfiguration.filters.jurisdiction.label')}:
        <CustomSelect
          sx={{
            '&&& .Mui-disabled': {
              WebkitTextFillColor: colors.mineShaft,
            },
          }}
          value={selectedJurisdiction}
          outline={false}
          onChange={onChangeJurisdictions}
          disabled={currentSection === ProductConfigurationSection.Details}
          placeholder={t('manageProduct.product.placeholder')!}
        >
          {jurisdictions.map((value) => {
            return (
              <MenuItem disabled={isJurisdictionDisabled(value)} key={value} value={value}>
                {value === 'All' ? t('productConfiguration.filters.jurisdiction.all') : value}
              </MenuItem>
            )
          })}
        </CustomSelect>
      </Box>
    </Box>
  )
}
