import { useRef, useCallback } from 'react'

import { CustomTable, IColumnType } from 'components/CustomTable'
import { NoAssignment } from '../noAssignment'
import { ISortingInfo, SortingDirection } from 'constants/sorting'

interface Props<T> {
  data: T[]
  columns: IColumnType<T>[]
}

const AdminsTable = <T,>({ data, columns }: Props<T>) => {
  const sorting = useRef<ISortingInfo>({ column: 'adminName', direction: SortingDirection.ASC })
  const onChangeSort = useCallback((sortingInfo: ISortingInfo) => {
    console.log(sortingInfo)
  }, [])

  return (
    <>
      {data.length > 0 ? (
        <CustomTable<T> columns={columns} data={data} sorting={sorting.current} onSort={onChangeSort} overflowLayout />
      ) : (
        <NoAssignment />
      )}
    </>
  )
}

export default AdminsTable
