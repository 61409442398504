import { FC } from 'react'
import { Box, SxProps, Typography } from '@mui/material'
import { OverflowTooltip } from 'components/OverflowTooltip'
import colors from 'theme/colors'
import { useTranslation } from 'react-i18next'

const cellStyles: SxProps = {
  display: 'grid',
  borderBottom: '1px solid',
  borderColor: 'grey.100',
  marginTop: '24px',
  paddingBottom: '8px',
  '& > div': {
    overflow: 'hidden',
    '& > p': {
      '&:first-of-type': {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: '600',
        color: colors.mainGrey,
        mb: '4px',
      },
      '&:nth-of-type(2)': {
        fontSize: '14px',
        lineHeight: '24px',
      },
    },
  },
}

interface Column {
  header: string
  value: string
}

interface SubscriptionInfoProps {
  columns: Column[]
}

export const SubscriptionInfo: FC<SubscriptionInfoProps> = ({ columns }) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ ...cellStyles, gridTemplateColumns: `repeat(${columns.length}, 1fr)` }}>
      {columns.map((column, index) => (
        <Box key={index}>
          <Typography>{t(column.header)}</Typography>
          <Typography>
            <OverflowTooltip text={column.value} />
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
