import { Box, Typography } from '@mui/material'
import DownloadCloud from 'assets/images/DownloadCloud.svg'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'

const DropZone = () => {
  const { t } = useTranslation()
  return (
    <>
      <Box component='img' sx={{ alignSelf: 'center', mb: '16px' }} src={DownloadCloud} />
      <Typography align='center' fontWeight={600} variant='subtitle2'>
        {t('manageProduct.users.dragDropText')}
      </Typography>
      <Typography align='center' variant='caption' color={colors.grey[700]} mt={'4px'}>
        {t('manageProduct.users.fileFormat')}
      </Typography>
      <Typography align='center' variant='caption' color={colors.grey[700]}>
        {t('manageProduct.users.fileLimits')}
      </Typography>
    </>
  )
}

export default memo(DropZone)
