import { SvgIcon, SvgIconProps } from '@mui/material'

interface CustomSvgIconProps extends SvgIconProps {
  variant?: 'outlined' | 'contained'
}

export const InfoIcon = (props: CustomSvgIconProps = { variant: 'contained' }) => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        color='info'
        {...props}
      >
        {props.variant === 'contained' ? (
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4
            16.4183 4 12C4 7.58172 7.58172 4 12 4ZM12 10C12.5523 10 13 10.4477 13 11V15C13 15.5523
            12.5523 16 12 16C11.4477 16 11 15.5523 11 15V11C11 10.4477 11.4477 10 12 10ZM12
            7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11
            8C11 7.44772 11.4477 7 12 7Z'
            fill='#007CB0'
          />
        ) : (
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4
            12C4 7.58172 7.58172 4 12 4ZM12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12
            19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM12 10C12.5523 10 13 10.4477 13
            11V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V11C11 10.4477 11.4477 10
            12 10ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11
            8C11 7.44772 11.4477 7 12 7Z'
          />
        )}
      </SvgIcon>
    </>
  )
}
