import { FC, useCallback, useMemo } from 'react'
import { Box, Button, Chip, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CustomTextField } from 'components/CustomTextField'
import {
  PackageField,
  Package,
  packageDurations,
  PackageFormErrors,
  PackageTypeOptions,
  PackageType,
  PackageDuration,
} from '../../constants'
import { CustomAlert } from 'components/CustomAlert'
import { RemoveIcon } from 'components/icons/Remove.icon'
import { SelectWithChips } from 'components/SelectWithChips'
import { CustomFormLabel } from 'components/CustomFormLabel'
import { OverflowTooltip } from 'components/OverflowTooltip'
import colors from 'theme/colors'
import { CustomRadioGroup } from 'components/CustomRadioGroup'
import { CustomArrowTooltip } from 'components/CustomTooltip'
import { InfoIcon } from 'components/icons/Info.icon'

interface Props {
  errors: PackageFormErrors
  isEdit: boolean
  index: number
  data: Package
  onChangePackageField: (index: number, value: string | string[], field: PackageField) => void
  onDeletePackage: (index: number) => void
  isShowDeleteButton: boolean
  isPackageFormInValid: boolean
}

export const ProductPackagesForm: FC<Props> = ({
  errors,
  index,
  data,
  isEdit,
  onChangePackageField,
  onDeletePackage,
  isShowDeleteButton,
  isPackageFormInValid: isPackageInValid,
}) => {
  const { t } = useTranslation()

  const durationTranslation = useCallback(
    (duration: PackageDuration) => {
      return `${duration} ${t(
        Number(duration) > 1 ? 'manageProduct.subscriptions.years' : 'manageProduct.subscriptions.year'
      )}`
    },
    [t]
  )

  const selectedDurationsTranslationKey = useMemo(() => {
    return packageDurations.reduce((acc, curr) => {
      if (data.durations?.includes(curr)) {
        acc[curr] = durationTranslation(curr)
      }
      return acc
    }, {} as { [key: string]: string })
  }, [data.durations, durationTranslation])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '16px',
          width: '100%',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${colors.border}`,
        }}
        data-testid='package'
      >
        <Typography fontWeight='600'>{t('productConfiguration.package.label')}</Typography>
        {!isEdit && isShowDeleteButton && (
          <Button
            sx={{ padding: '0 !important', marginLeft: 'auto', minWidth: 'inherit' }}
            onClick={() => {
              onDeletePackage(index)
            }}
          >
            <RemoveIcon color='action' />
          </Button>
        )}
      </Box>
      {isPackageInValid &&
        (!errors.name.isValid || !errors.description.isValid || !errors.descriptionFrench.isValid) && (
          <CustomAlert severity='error' text={t('productConfiguration.form.completeRequired')!} />
        )}
      <Box
        sx={{
          padding: '16px',
        }}
      >
        <Box
          sx={{
            p: { marginBottom: isEdit ? '16px' : '10px' },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', height: '24px', mb: isEdit ? '8px' : '' }}>
            <CustomFormLabel sx={{ mb: 0 }} value={t('productConfiguration.package.type.label')!} />
            <CustomArrowTooltip placement='top' title={t('productConfiguration.package.type.tooltip')}>
              <Box sx={{ width: '24px', height: '24px' }}>
                <InfoIcon />
              </Box>
            </CustomArrowTooltip>
          </Box>
          <CustomRadioGroup
            row
            readonly={isEdit}
            sx={{ marginTop: '-4px', label: { marginRight: '30px' } }}
            value={data.type}
            options={PackageTypeOptions}
            onChange={(e) => onChangePackageField(index, e.target.value, PackageField.PackageType)}
          />
        </Box>
        <Box sx={{ maxWidth: '800px' }}>
          <CustomTextField
            sx={{
              '& div': {
                fontWeight: !isEdit ? 400 : 600,
              },
            }}
            limitation={200}
            helperText={t('productConfiguration.form.informationMessage')!}
            readonlyTemplate={() => (
              <Typography component='div' fontWeight='600' variant='body2'>
                <OverflowTooltip text={data.name} />
              </Typography>
            )}
            readonly={isEdit}
            error={isPackageInValid && !errors.name.isValid}
            label={t('productConfiguration.package.name.label')!}
            placeholder={t('productConfiguration.package.name.placeholder')!}
            value={data.name}
            onChange={(e) => {
              onChangePackageField(index, e.target.value, PackageField.Name)
            }}
            highlightOnFocus
            multiline
          />
          <CustomTextField
            sx={{
              marginTop: '16px',
              '& p': {
                fontWeight: !isEdit ? 400 : 600,
              },
            }}
            limitation={300}
            helperText={t('productConfiguration.form.informationMessage')!}
            readonly={isEdit}
            error={isPackageInValid && !errors.description.isValid}
            label={t('productConfiguration.package.description.label')!}
            placeholder={t('productConfiguration.package.description.placeholder')!}
            value={data.description}
            onChange={(e) => {
              onChangePackageField(index, e.target.value, PackageField.Description)
            }}
            highlightOnFocus
            multiline
          />
          <CustomTextField
            sx={{
              marginTop: '16px',
              '& p': {
                fontWeight: !isEdit ? 400 : 600,
              },
            }}
            limitation={300}
            helperText={t('productConfiguration.form.informationMessage')!}
            readonly={isEdit}
            error={isPackageInValid && !errors.descriptionFrench.isValid}
            label={t('productConfiguration.package.description.labelFrench')!}
            placeholder={t('productConfiguration.package.description.placeholder')!}
            value={data.descriptionFrench}
            onChange={(e) => {
              onChangePackageField(index, e.target.value, PackageField.DescriptionFrench)
            }}
            highlightOnFocus
            multiline
          />
          {data.type === PackageType.Duration &&
            data.durations &&
            (!isEdit ? (
              <Box
                sx={{
                  marginTop: '16px',
                }}
              >
                <SelectWithChips
                  label={t('productConfiguration.package.term.label')!}
                  placeholder={t('productConfiguration.package.term.placeholder')}
                  values={data.durations}
                  onChange={(e) => {
                    onChangePackageField(index, e.target.value, PackageField.Durations)
                  }}
                  onDelete={(item) => onChangePackageField(index, item, PackageField.DurationChip)}
                  translationKeys={selectedDurationsTranslationKey}
                >
                  {packageDurations.map((duration) => (
                    <MenuItem key={duration} value={duration}>
                      {durationTranslation(duration).toLowerCase()}
                    </MenuItem>
                  ))}
                </SelectWithChips>
              </Box>
            ) : (
              <Box sx={{ marginTop: '10px' }}>
                <CustomFormLabel value={t('productConfiguration.package.term.label')} />
                <Box sx={{ marginTop: '8px', display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {data.durations.map((duration) => (
                    <Chip key={duration} label={durationTranslation(duration).toLowerCase()} />
                  ))}
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </>
  )
}
