import { FC } from 'react'
import { handleDeleteAdminModal, handleUpdateAdminsModal } from 'sharedSlices/ui'
import { getEmployeeByEmployeeRoleId, setEmployeeSelectedId } from 'features/manageAdmins/slices'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import RowActions from 'components/rowActions'
import { useTranslation } from 'react-i18next'
import { Permissions } from 'constants/permissions'
import { SUPER_ADMIN, JURISDICTION_ADMIN, PRODUCT_ADMIN, DEMO_USER } from 'features/manageAdmins/constants'

interface Props {
  allowedPermissions: number[]
  id: string
  jurCode?: string
}

export const ManageRowActions: FC<Props> = ({ allowedPermissions, id, jurCode }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { employeeRoleId: roleId } = useAppSelector((state) => state.manageAdmins)

  const handleUpdateAdmin = () => {
    dispatch(handleUpdateAdminsModal(true))
    dispatch(getEmployeeByEmployeeRoleId({ employeeRoleId: id, jurCode }))
  }

  const handleDeleteAdmin = () => {
    dispatch(handleDeleteAdminModal(true))
    dispatch(setEmployeeSelectedId(id))
  }

  const canDeleteAdmin = () => {
    if (allowedPermissions) {
      if (roleId === SUPER_ADMIN && allowedPermissions.includes(Permissions.AddRemSuperAdmins)) {
        return <RowActions.Remove title={t('manageAdmins.removeAdmin')} onClick={handleDeleteAdmin} />
      }
      if (roleId === JURISDICTION_ADMIN && allowedPermissions.includes(Permissions.AddRemJurisdictionAdmins)) {
        return <RowActions.Remove title={t('manageAdmins.removeAdmin')} onClick={handleDeleteAdmin} />
      }
      if (roleId === PRODUCT_ADMIN && allowedPermissions.includes(Permissions.AddRemProductAdmins)) {
        return <RowActions.Remove title={t('manageAdmins.removeAdmin')} onClick={handleDeleteAdmin} />
      }
    }
    if (roleId === DEMO_USER) {
      return <RowActions.Remove title={t('manageAdmins.removeDemoUser')} onClick={handleDeleteAdmin} />
    }
  }

  return (
    <RowActions>
      {roleId !== DEMO_USER && (
        <RowActions.EditProfile title={t('manageAdmins.manageAdmin')} onClick={handleUpdateAdmin} />
      )}
      {canDeleteAdmin()}
    </RowActions>
  )
}
