import { SortingDirection } from "constants/sorting";

export const DEFAULT_MY_REPORTS_SORTING = {
  column: '',
  direction: SortingDirection.DESC,
}

export const enum FileType {
  CSV = "CSV"
}

export interface DownloadResponse {
  version: string;
  content: {
    headers: {
      key: string;
      value: string[];
    }[];
  };
  statusCode: string;
  reasonPhrase: string;
  headers: string[];
  trailingHeaders: string[];
  requestMessage: null;
  isSuccessStatusCode: boolean;
}
