import { useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { handleRemoveUsersModal } from 'sharedSlices/ui'

import { getUsers } from '../slices/manageUsers'
import { selectAllRegularUsersSelected } from '../slices/manageUsers/selectors'

import { deleteUser } from '../services'
import { showMessage } from 'utils/errorMessageHandler'

export const useRemoveUser = () => {
  const { subscriptionId } = useAppSelector((state) => state.manageUsers)
  const [searchParams] = useSearchParams()
  const jurCode = searchParams.get('jurCode')!
  const regularUserSelectedList = useAppSelector(selectAllRegularUsersSelected)
  const dispatch = useAppDispatch()
  const onClose = () => {
    dispatch(handleRemoveUsersModal(false))
  }

  const handleDeleteUser = () => {
    if (subscriptionId) {
      deleteUser({
        subscriptionId,
        userIds: regularUserSelectedList.map((user) => user.id),
        jurCode,
      })
        .then(() => {
          dispatch(handleRemoveUsersModal(false))
          dispatch(getUsers({ jurCode, subscriptionId }))
          showMessage.success('mySnackbar', 'manageAdmins.message.success')
        })
        .catch(() => {
          showMessage.error('mySnackbar', 'manageAdmins.message.error')
          dispatch(handleRemoveUsersModal(false))
        })
    }
  }
  return { onClose, handleDeleteUser }
}
