import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { ArrowDoubleRight } from 'components/icons/ArrowDoubleRight.icon'
import colors from 'theme/colors'

interface Props {
  year: number | string
  onPrevious: () => void
  onNext: () => void
  onShowDecades?: () => void
}

export const Header: FC<Props> = ({ year, onPrevious, onNext, onShowDecades }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '64px',
        backgroundColor: colors.black.main,
        color: colors.white,
      }}
    >
      <ArrowDoubleRight
        data-testid="previous date"
        sx={{
          transform: 'rotate(180deg) scale(0.6)',
          cursor: 'pointer',

          ':hover': {
            path: {
              fill: colors.dodgerBlue,
            },
          },
        }}
        onClick={onPrevious}
      />
      <Typography
        width='180px'
        textAlign='center'
        fontSize='1.5rem'
        onClick={onShowDecades}
        sx={{
          ':hover': onShowDecades
            ? {
                color: colors.dodgerBlue,
                cursor: 'pointer',
                textDecoration: 'underline',
              }
            : {},
        }}
      >
        {year}
      </Typography>
      <ArrowDoubleRight
        data-testid="next date"
        sx={{
          transform: 'scale(0.6)',
          cursor: 'pointer',

          ':hover': {
            path: {
              fill: colors.dodgerBlue,
            },
          },
        }}
        onClick={onNext}
      />
    </Box>
  )
}
