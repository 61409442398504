import {
  IAccountDetails,
  IBillingDetails,
  IDeloitteBusinessUnit,
  IPackageCurrency,
  IPackageLicenses,
  IPackageValuation,
  IPolicyAccepted,
  IPolicyOptionsAccepted,
  IPrivacy,
  IProductPaymentType,
  IProductZuoraRefId,
  LicenseType,
  ProductFeature,
  ProductGroupType,
  TBillingInfoErrors,
  IProductTaxes,
} from './../constants/subscription'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DeloitteClientType,
  ICountry,
  IProduct,
  IProductGroup,
  IProductTerm,
  IState,
  subscription,
  SubscriptionType,
} from '../constants/subscription'
import { IPackage } from 'features/subscriptionRequest/constants/subscription'
import { IStep, StepCreateSubscription, StepName, StepStatus } from '../constants/stepper'
import { IAcceptableUsePolicy } from '../components/acceptableUsePolicy'
import { extraReducersBuilder } from './utils/extraReducersBuilder'
import { ISubscriptionRequestState } from './interfaces'
import { EmailValidationError } from 'features/subscriptionRequest/constants/email'
import { sortedPackages } from 'utils/sortPackages'
import { PaymentDetailsBlockTypes } from '../components/paymentDetails/constants'
import { PaymentType } from '../constants/payment'
import { FormikErrors, FormikValues } from 'formik'

export const initialSubscriptionRequestState: ISubscriptionRequestState = {
  productGroup: {} as IProductGroup,
  products: [] as IProduct[],
  type: null,
  terms: {} as IProductTerm,
  commonTerms: [],
  commonTermId: null,
  valuations: {} as IPackageValuation,
  currencies: {} as IPackageCurrency,
  paymentTypes: {} as IProductPaymentType,
  licenses: {} as IPackageLicenses,
  subscription,
  packages: [],
  activeStep: 1,
  steps: [],
  countries: [],
  accountCountries: [],
  states: [],
  acceptableUsePolicy: {} as IAcceptableUsePolicy,
  acceptableUsePolicies: [],
  activePolicyIndex: 0,
  isPolicyOptionsAccepted: {} as IPolicyOptionsAccepted,
  privacy: {} as IPrivacy,
  isPolicyAccepted: {} as IPolicyAccepted,
  isPrivacyAccepted: false,
  loading: false,
  error: null,
  billingStates: [],
  billingInfoError: [],
  isExpandedDiscountInfo: true,
  paymentDetailsActiveStep: PaymentDetailsBlockTypes.BillingDetails,
  paymentDetailsSteps: [],
  zuoraRefIds: {} as IProductZuoraRefId,
  paymentInfoErrors: {},
  taxes: {} as IProductTaxes,
  isSubmissionSucceeded: false,
}

const resetDurationValuations = (state: ISubscriptionRequestState) => {
  state.valuations = {}
  state.terms = {}
}

const resetStepsAndAcceptableUsePolicy = (state: ISubscriptionRequestState) => {
  state.steps = state.steps.map((step) => ({
    ...step,
    status: step.name === StepName.SubscriptionInit ? StepStatus.Available : StepStatus.Disabled,
  }))
  state.isPolicyAccepted = {} as IPolicyAccepted
}

const resetAcceptableUsePolicy = (state: ISubscriptionRequestState) => {
  state.steps = state.steps.map((step) => ({
    ...step,
    status:
      step.name === StepName.SubscriptionInit
        ? StepStatus.Completed
        : step.name === StepName.SelectPackage
        ? StepStatus.Available
        : StepStatus.Disabled,
  }))
  state.acceptableUsePolicies = []
  state.isPolicyAccepted = {} as IPolicyAccepted
  state.isPolicyOptionsAccepted = state.acceptableUsePolicies.reduce(
    (acceptedPolicyOptions, policy, index) => ({
      ...acceptedPolicyOptions,
      [index]: policy.options.reduce((options, cur) => ({ ...options, [cur.id]: false }), {}),
    }),
    {} as IPolicyOptionsAccepted
  )
}

const disableStepPaymentDetails = (state: ISubscriptionRequestState) => {
  return state.steps.map((step) => {
    if (step.name === StepName.PaymentDetails) return { ...step, status: StepStatus.Disabled }
    return step
  })
}

const checkPackageCompletion = (state: ISubscriptionRequestState) => {
  if (
    !state.subscription.products.length ||
    state.subscription.products.some((selectedProduct) => {
      return selectedProduct.packages.some((selectedPackage) => {
        return selectedPackage.licenseType === LicenseType.TokenBased
          ? !state.valuations[selectedPackage.id]
          : selectedPackage.licenseType === LicenseType.LicenseBased
          ? !state.licenses[selectedPackage.packageGroup.name]
          : !state.terms[selectedPackage.id] && state.products[0].terms.length > 1
      })
    })
  ) {
    resetAcceptableUsePolicy(state)
  }
}

const changePaymentDetailsStepsStatus = (
  state: ISubscriptionRequestState,
  { stepIndex, newStep, currentStep }: { stepIndex: number; newStep?: StepStatus; currentStep: StepStatus }
) => {
  return state.paymentDetailsSteps.map((item, idx) => {
    let status = item.status

    if (idx < stepIndex) {
      status = currentStep
    }
    if (idx === stepIndex && newStep) {
      status = newStep
    }

    return { ...item, status }
  })
}

const disableNextPaymentDetailsSteps = (
  state: ISubscriptionRequestState,
  { stepIndex, newStep, currentStep }: { stepIndex: number; newStep: StepStatus; currentStep: StepStatus }
) => {
  return state.paymentDetailsSteps.map((item, idx) => {
    let status = item.status

    if (idx === stepIndex) {
      status = currentStep
    }

    if (idx > stepIndex) {
      status = newStep
    }

    return { ...item, status }
  })
}

const addPaymentDetailsFeatures = (
  features: ProductFeature[],
  productId: string,
  state: ISubscriptionRequestState,
  paymentTypes: PaymentType[]
) => {
  features?.forEach((feature) => {
    if (feature === ProductFeature.Payment) {
      state.subscription.paymentDetailsData[productId] = {
        ...state.subscription.paymentDetailsData[productId],
        invoiceDetails: {
          email: '',
          contact: '',
        },
      }
      if (!state.paymentTypes[productId]) state.paymentTypes[productId] = paymentTypes![0]
    }
    if (feature === ProductFeature.DeloittePartner) {
      state.subscription.paymentDetailsData[productId] = {
        ...state.subscription.paymentDetailsData[productId],
        isDeloittePartner: DeloitteClientType.No,
      }
    }
  })
}

const removePaymentDetailsFeatures = (productId: string, state: ISubscriptionRequestState) => {
  const { [productId]: removedKey, ...paymentDetailsUpdated } = state.subscription.paymentDetailsData
  state.subscription.paymentDetailsData = paymentDetailsUpdated
}

export const subscriptionRequestSlice = createSlice({
  name: 'subscriptionRequest',
  initialState: initialSubscriptionRequestState,
  reducers: {
    setCountry: (state, action: PayloadAction<ICountry>) => {
      state.subscription.country = action.payload
    },
    setProducts: (state, action: PayloadAction<IProduct[]>) => {
      state.products = action.payload
    },
    setTerm: (state, action: PayloadAction<IProductTerm>) => {
      state.terms = {
        ...state.terms,
        ...action.payload,
      }
    },
    setCurrency: (state, action: PayloadAction<IPackageCurrency>) => {
      state.currencies = {
        ...state.currencies,
        ...action.payload,
      }
    },
    setLicenses: (state, action: PayloadAction<IPackageLicenses>) => {
      state.licenses = {
        ...state.licenses,
        ...action.payload,
      }
      checkPackageCompletion(state)
    },
    setCommonTermId: (state, action: PayloadAction<string>) => {
      state.commonTermId = action.payload
    },
    // Temporary DART privacy handling
    setPrivacy: (state, action: PayloadAction<IPrivacy>) => {
      state.privacy = action.payload
    },
    setValuations: (state, action: PayloadAction<IPackageValuation>) => {
      state.valuations = {
        ...state.valuations,
        ...action.payload,
      }
      checkPackageCompletion(state)
    },
    setType: (state, action: PayloadAction<SubscriptionType>) => {
      state.type = action.payload
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      if (state.activeStep === StepCreateSubscription.AcceptableUsePolicy) {
        state.activePolicyIndex = 0
      }
      state.activeStep = action.payload
    },
    goNext: (state) => {
      state.steps = state.steps.map((step, index) => ({
        ...step,
        status:
          state.activeStep === index + 1
            ? StepStatus.Completed
            : state.activeStep === index && step.status === StepStatus.Disabled
            ? StepStatus.Available
            : step.status,
      }))
      state.activeStep = state.activeStep + 1
    },
    selectCountry: (state, action: PayloadAction<ICountry | null>) => {
      if (state.subscription.country) {
        state.states = []
        state.acceptableUsePolicy = {} as IAcceptableUsePolicy
        state.subscription.accountDetails.state = null
        state.packages = []
        state.products = []
        state.subscription.products = []
        state.privacy = {} as IPrivacy
        resetDurationValuations(state)
        resetStepsAndAcceptableUsePolicy(state)
      }
      state.subscription.country = action.payload
    },
    selectAccountCountry: (state, action: PayloadAction<ICountry>) => {
      state.subscription.accountDetails.country = action.payload
      state.subscription.accountDetails.state = null
    },
    selectPackage: (state, action: PayloadAction<IPackage>) => {
      const products = [...state.subscription.products]
      const packageGroupIndex = products.findIndex((product) => product.id === action.payload.productId)
      const features = state.products.find((product) => product.id === action.payload.productId)?.features
      const paymentTypes = state.products.find((product) => product.id === action.payload.productId)?.paymentTypes

      if (packageGroupIndex >= 0) {
        if (products[packageGroupIndex].packages.find((_package) => _package.id === action.payload.id)) {
          const filteredProducts = products[packageGroupIndex].packages.filter(
            (_package) => _package.id !== action.payload.id
          )
          if (filteredProducts.length) {
            state.subscription.products[packageGroupIndex].packages = filteredProducts
          } else {
            state.subscription.products = products.filter((_, i) => i !== packageGroupIndex)
            resetAcceptableUsePolicy(state)
          }

          removePaymentDetailsFeatures(action.payload.productId, state)
        } else {
          if (action.payload.packageGroup && !action.payload.packageGroup.isMultiple) {
            products[packageGroupIndex].packages = products[packageGroupIndex].packages.filter((_package) => {
              return _package.packageGroup.isMultiple || _package.packageGroup.name !== action.payload.packageGroup.name
            })
          }
          state.subscription.products[packageGroupIndex].packages = [
            ...products[packageGroupIndex].packages,
            action.payload,
          ].sort((a, b) => a.name.localeCompare(b.name))
        }
      } else {
        state.subscription.products = [
          ...products,
          {
            id: action.payload.productId,
            packages: [action.payload],
          },
        ]
        resetAcceptableUsePolicy(state)

        addPaymentDetailsFeatures(
          features as ProductFeature[],
          action.payload.productId,
          state,
          paymentTypes as PaymentType[]
        )
      }

      checkPackageCompletion(state)
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.subscription = {
        ...state.subscription,
        email: action.payload,
        emailError: 0,
      }

      if (state.productGroup.type === ProductGroupType.All) {
        state.subscription.billingDetails.email = action.payload
      }
      if (!action.payload) {
        resetStepsAndAcceptableUsePolicy(state)
        if (state.packages.length > 1) {
          state.subscription.products = []
        }
        resetDurationValuations(state)
        state.subscription.emailError = 0
      }
    },
    setEmailError: (state, action: PayloadAction<EmailValidationError>) => {
      state.subscription.emailError = action.payload
    },
    setIsPolicyAccepted: (state, action: PayloadAction<{ index: number; isAccepted: boolean }>) => {
      state.isPolicyAccepted = {
        ...state.isPolicyAccepted,
        [action.payload.index]: action.payload.isAccepted,
      }
      if (!action.payload.isAccepted) {
        state.steps = state.steps.map((step) => ({
          ...step,
          status: [StepName.SubscriptionInit, StepName.SelectPackage].includes(step.name)
            ? StepStatus.Completed
            : [StepName.AcceptableUsePolicy, StepName.TermsOfUse].includes(step.name)
            ? StepStatus.Available
            : StepStatus.Disabled,
        }))
      }
    },
    setIsPolicyOptionsAccepted: (
      state,
      action: PayloadAction<{ policyIndex: number; optionId: string; isAccepted: boolean }>
    ) => {
      state.isPolicyOptionsAccepted[action.payload.policyIndex][action.payload.optionId] = action.payload.isAccepted
    },
    setActivePolicy: (state, action: PayloadAction<number>) => {
      state.activePolicyIndex = action.payload
    },
    setIsPrivacyAccepted: (state, action: PayloadAction<boolean>) => {
      state.isPrivacyAccepted = action.payload
    },
    setIsExpandedDiscountInfo: (state, action: PayloadAction<boolean>) => {
      state.isExpandedDiscountInfo = action.payload
    },
    setIsDeloitteClient: (state, action: PayloadAction<DeloitteClientType>) => {
      state.subscription.isDeloitteClient = action.payload
      if (action.payload === DeloitteClientType.Yes) {
        resetStepsAndAcceptableUsePolicy(state)
        if (state.packages.length > 1) {
          state.subscription.products = []
        }
        resetDurationValuations(state)
      }
    },
    setBusinessUnits: (state, action: PayloadAction<IDeloitteBusinessUnit[]>) => {
      state.subscription.businessUnits = action.payload
      if (
        state.subscription.isDeloitteClient === DeloitteClientType.Yes &&
        !state.subscription.businessUnits.every((unit) => {
          return unit.type && unit.name && unit.email && !unit.errors.name && !unit.errors.email
        })
      ) {
        state.steps = state.steps.map((step) => ({
          ...step,
          status: step.name === StepName.SubscriptionInit ? StepStatus.Available : StepStatus.Disabled,
        }))
      }
    },
    updateAccountDetails: (state, action: PayloadAction<{ key: keyof IAccountDetails; value: string | IState }>) => {
      state.subscription.accountDetails = {
        ...state.subscription.accountDetails,
        [action.payload.key]: action.payload.value,
      }
    },
    setPackages: (state, action: PayloadAction<IPackage[]>) => {
      state.packages = sortedPackages(action.payload)
    },
    setAllAccountDetails: (
      state,
      action: PayloadAction<{
        country: ICountry
        email: string
        firstName: string
        lastName: string
        companyFullName: string
        address: string
        zipCode: string
        state: IState | null
        city: string
        department: string
        role: string
        globalParentFullName: string
      }>
    ) => {
      state.subscription.country = action.payload.country
      state.subscription.email = action.payload.email
      state.subscription.accountDetails.firstName = action.payload.firstName
      state.subscription.accountDetails.lastName = action.payload.lastName
      state.subscription.accountDetails.companyFullName = action.payload.companyFullName
      state.subscription.accountDetails.address = action.payload.address
      state.subscription.accountDetails.zipCode = action.payload.zipCode
      state.subscription.accountDetails.state = action.payload.state
      state.subscription.accountDetails.city = action.payload.city
      state.subscription.accountDetails.department = action.payload.department
      state.subscription.accountDetails.role = action.payload.role
      state.subscription.accountDetails.globalParentFullName = action.payload.globalParentFullName
    },
    resetData: (state, action: PayloadAction<IStep[]>) => {
      const products = state.productGroup?.products
      const hasClientFeature =
        products?.length &&
        (products[0].features.includes(ProductFeature.AuditClient) ||
          products[0].features.includes(ProductFeature.DeloitteClient))
      return {
        ...initialSubscriptionRequestState,
        subscription: {
          ...initialSubscriptionRequestState.subscription,
          isDeloitteClient: hasClientFeature ? DeloitteClientType.No : null,
        },
        productGroup: state.productGroup,
        countries: state.countries,
        steps: action.payload,
        ...(state.productGroup.type === ProductGroupType.All && {
          products: state.products,
          packages: state.packages,
          commonTerms: state.commonTerms,
          licenses: Object.keys(state.licenses).reduce((acc, key) => ({ ...acc, [key]: 0 }), {} as IPackageLicenses),
          currencies: Object.keys(state.currencies)
            .filter((id) => state.packages.find((_package) => _package.productId === id)?.prices.length === 1)
            .reduce((acc, id) => ({ ...acc, [id]: state.currencies[id] }), {} as IPackageCurrency),
        }),
      }
    },
    resetUserInfo: (state) => {
      state.userInfo = undefined
    },
    setSteps: (state, action: PayloadAction<IStep[]>) => {
      state.steps = action.payload
    },
    updateBillingDetails: (state, action: PayloadAction<{ key: keyof IBillingDetails; value: string | IState }>) => {
      state.subscription.billingDetails = {
        ...state.subscription.billingDetails,
        [action.payload.key]: action.payload.value,
      }
    },
    resetBillingDetails: (state) => {
      state.subscription.billingDetails = initialSubscriptionRequestState.subscription.billingDetails
    },
    selectBillingCountry: (state, action: PayloadAction<ICountry>) => {
      state.subscription.billingDetails.country = action.payload
      state.subscription.billingDetails.state = null
    },
    setBillingInfoError: (state, action: PayloadAction<TBillingInfoErrors>) => {
      state.billingInfoError = action.payload
    },
    setBillingDetails: (state, action) => {
      const isBillingDetailsEmpty = !!Object.values(state.subscription.billingDetails)?.length
      const billingDetailsUpdated = Object.keys(state.subscription.billingDetails).reduce(
        (acc, key) =>
          key === 'email' ? acc.set('email', state.subscription.email) : acc.set(key, action.payload[key]),
        new Map()
      )

      if (isBillingDetailsEmpty) {
        state.subscription.billingDetails = Object.fromEntries(billingDetailsUpdated)
      }
    },
    setIsPaymentDetailsStepDisabled: (state) => {
      state.steps = disableStepPaymentDetails(state)
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPaymentDetailsSteps: (state, action) => {
      state.paymentDetailsSteps = action.payload
    },
    setPaymentDetailsActiveStep: (state, action) => {
      state.paymentDetailsActiveStep = action.payload
    },
    updatePaymentDetailsSteps: (
      state,
      action: PayloadAction<{ stepIndex: number; newStep?: StepStatus; currentStep: StepStatus }>
    ) => {
      const updatedSteps = changePaymentDetailsStepsStatus(state, action.payload)
      state.paymentDetailsSteps = updatedSteps
    },
    disablePaymentDetailsSteps: (
      state,
      action: PayloadAction<{ stepIndex: number; newStep: StepStatus; currentStep: StepStatus }>
    ) => {
      const updatedSteps = disableNextPaymentDetailsSteps(state, action.payload)
      state.paymentDetailsSteps = updatedSteps
    },
    updatePaymentDetails: (
      state,
      action: PayloadAction<{
        productId: string
        key: string
        value: string | { [key: string]: string }
      }>
    ) => {
      state.subscription.paymentDetailsData = {
        ...state.subscription.paymentDetailsData,
        [action.payload.productId]: {
          ...state.subscription.paymentDetailsData[action.payload.productId],
          [action.payload.key]: action.payload.value,
        },
      }
    },
    setPaymentTypes: (state, action: PayloadAction<IProductPaymentType>) => {
      state.paymentTypes = {
        ...state.paymentTypes,
        ...action.payload,
      }
    },
    setPaymentDetailsInfo: (state, action) => {
      state.subscription.paymentDetailsData = {
        ...state.subscription.paymentDetailsData,
        ...action.payload,
      }
    },
    setPaymentInfoErrors: (state, action: PayloadAction<{ errors: FormikErrors<FormikValues>; productId: string }>) => {
      const { errors, productId } = action.payload

      if (Object.keys(errors).length) {
        state.paymentInfoErrors = {
          ...state.paymentInfoErrors,
          [productId]: errors,
        }
      } else {
        const { [productId]: _, ...rest } = state.paymentInfoErrors
        state.paymentInfoErrors = rest
      }
    },
    setZuoraRefIds: (state, action: PayloadAction<IProductZuoraRefId>) => {
      state.zuoraRefIds = {
        ...state.zuoraRefIds,
        ...action.payload,
      }
    },
    setIsSubmissionSucceed: (state, action) => {
      state.isSubmissionSucceeded = action.payload
    },
  },
  extraReducers(builder) {
    extraReducersBuilder(builder)
  },
})

export const {
  setProducts,
  setActiveStep,
  goNext,
  setTerm,
  setCurrency,
  setLicenses,
  setCommonTermId,
  setCountry,
  setValuations,
  setType,
  selectCountry,
  selectPackage,
  setEmail,
  setEmailError,
  setIsPolicyAccepted,
  setIsPolicyOptionsAccepted,
  setActivePolicy,
  setIsPrivacyAccepted,
  setIsDeloitteClient,
  setBusinessUnits,
  updateAccountDetails,
  resetData,
  resetUserInfo,
  setSteps,
  setAllAccountDetails,
  setPackages,
  setPrivacy,
  selectAccountCountry,
  updateBillingDetails,
  resetBillingDetails,
  selectBillingCountry,
  setBillingInfoError,
  setBillingDetails,
  setIsPaymentDetailsStepDisabled,
  setIsExpandedDiscountInfo,
  setPaymentDetailsSteps,
  setPaymentDetailsActiveStep,
  updatePaymentDetailsSteps,
  disablePaymentDetailsSteps,
  updatePaymentDetails,
  setPaymentDetailsInfo,
  setPaymentTypes,
  setError,
  setZuoraRefIds,
  setPaymentInfoErrors,
  setIsSubmissionSucceed,
} = subscriptionRequestSlice.actions

export default subscriptionRequestSlice.reducer
