import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import {
  CalendarRange,
  IMonth,
  CalendarDirection,
  monthsName,
  weekday,
  CalendarChange,
  SelectedDate,
  SelectedDateRange,
} from '../constants'
import { ArrowRight } from 'components/icons/ArrowRight.icon'
import { ArrowDoubleRight } from 'components/icons/ArrowDoubleRight.icon'
import { getDayStyle, getBorderStyle } from '../utils/index'
import colors from 'theme/colors'
import { TFunction } from 'i18next'

interface Props {
  selectedDate: SelectedDate
  dates: IMonth[]
  currentMonth: Date
  range: CalendarRange
  onChange: (calendar: CalendarRange, change: CalendarChange, direction: CalendarDirection) => void
  displayNextArrow: boolean
  displayPreviousArrow: boolean
  onSelectedDate: (date: Date) => void
  selectedDateRange: SelectedDateRange
  onHoverChange: (date: Date) => void
  onShowDecades: () => void
  onShowMonths: () => void
  dateDisabledThreshold?: Date
  translation: TFunction<"translation", undefined>
}

export const Calendar: FC<Props> = ({
  selectedDate,
  dates,
  currentMonth,
  range,
  onChange,
  displayNextArrow,
  displayPreviousArrow,
  onSelectedDate,
  selectedDateRange,
  onHoverChange,
  onShowDecades,
  onShowMonths,
  dateDisabledThreshold,
  translation
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '64px',
          backgroundColor: colors.black.main,
          color: colors.white,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '34px',
            width: '48px',
          }}
        >
          {displayPreviousArrow && (
            <>
              <ArrowDoubleRight
                data-testid="previous year"
                sx={{
                  transform: 'rotate(180deg) scale(0.6)',
                  cursor: 'pointer',

                  ':hover': {
                    path: {
                      fill: colors.dodgerBlue,
                    },
                  },
                }}
                onClick={() => onChange(range, CalendarChange.Year, CalendarDirection.Previous)}
              />
              <ArrowRight
                data-testid="previous month"
                sx={{
                  transform: 'rotate(180deg) scale(0.6)',
                  cursor: 'pointer',

                  ':hover': {
                    path: {
                      fill: colors.dodgerBlue,
                    },
                  },
                }}
                onClick={() => onChange(range, CalendarChange.Month, CalendarDirection.Previous)}
              />
            </>
          )}
        </Box>
        <Typography
          sx={{
            ':hover': {
              color: colors.dodgerBlue,
              cursor: 'pointer',
              textDecoration: 'underline',
            },
          }}
          textAlign='center'
          width='44px'
          fontSize='1.5rem'
          margin='0 8px'
          onClick={onShowMonths}
        >
          {translation(monthsName[currentMonth.getMonth()])}
        </Typography>
        <Typography
          sx={{
            ':hover': {
              color: colors.dodgerBlue,
              cursor: 'pointer',
              textDecoration: 'underline',
            },
          }}
          fontSize='1.5rem'
          margin='0 8px'
          onClick={onShowDecades}
        >
          {currentMonth.getFullYear()}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '34px',
            width: '48px',
          }}
        >
          {displayNextArrow && (
            <>
              <ArrowRight
                data-testid="next month"
                sx={{
                  transform: 'scale(0.6)',
                  cursor: 'pointer',

                  ':hover': {
                    path: {
                      fill: colors.dodgerBlue,
                    },
                  },
                }}
                onClick={() => onChange(range, CalendarChange.Month, CalendarDirection.Next)}
              />
              <ArrowDoubleRight
                data-testid="next year"
                sx={{
                  transform: 'scale(0.6)',
                  cursor: 'pointer',

                  ':hover': {
                    path: {
                      fill: colors.dodgerBlue,
                    },
                  },
                }}
                onClick={() => onChange(range, CalendarChange.Year, CalendarDirection.Next)}
              />
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: colors.gallery,
        }}
      >
        {dates[0].week.map((week) => (
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '36px',
              padding: '6px 2px',
              fontSize: '12px',
            }}
            key={week.day.getDay()}
          >
            {translation(weekday[week.day.getDay()])}
          </Typography>
        ))}
      </Box>
      {dates.map((month, index) => (
        <Box
          sx={{
            display: 'flex',
          }}
          key={index}
        >
          {month.week.map((week) => (
            <Box
              sx={{
                fontFamily: 'Open Sans',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '36px',
                height: '36px',
                padding: '2px',
                fontSize: '12px',
                ...getBorderStyle(week, selectedDate, currentMonth),
              }}
              key={`${week.day.getDay()}${week.day.getMonth()}`}
              onClick={() => {
                if (
                  week.day.getMonth() !== currentMonth.getMonth() ||
                  dateDisabledThreshold && week.day.getTime() > dateDisabledThreshold.getTime()
                ) {
                  return
                }

                onSelectedDate(week.day)
              }}
              onMouseMove={() => {
                if (
                  dateDisabledThreshold && week.day.getTime() > dateDisabledThreshold.getTime() ||
                  week.day.getMonth() !== currentMonth.getMonth() ||
                  [SelectedDateRange.None, SelectedDateRange.EndDate].includes(selectedDateRange)
                ) {
                  return
                }
                onHoverChange(week.day)
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '26px',
                  width: '26px',
                  ...getDayStyle(week, currentMonth, selectedDate, dateDisabledThreshold),

                  ':hover':
                    week.day.getMonth() === currentMonth.getMonth()
                      ? {
                          background: colors.white,
                          color: colors.doveGray,
                          border: `1px solid ${colors.deepCerulean}`,
                          borderRadius: '50%',
                        }
                      : {},
                }}
              >
                {week.day.getDate()}
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  )
}
