import {
  Button,
  Typography,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import CustomModal from 'components/CustomModal'
import { CloseIcon } from 'components/icons/Close.icon'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'
import { FileType } from '../MyReports/constants'

interface Props {
  open: boolean
  reportName: string | null
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClose: () => void
  onExport: () => void
}

export const ExportPopup: FC<Props> = ({
  open,
  reportName,
  value,
  onChange,
  onClose,
  onExport,
}) => {
  const { t } = useTranslation()
  return (
    <CustomModal width='640px' open={open}>
      <CustomModal.Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
          borderBottom: `1px solid ${colors.border}`,
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
          }}
        >
          {t('reports.myReports.exportPopup.title')}
        </Typography>
        <Button
          sx={{
            height: '24px',
            width: '24px !important',
            minWidth: '24px',
            padding: '0',
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </CustomModal.Header>
      <CustomModal.Body
        style={{
          padding: '16px',
        }}
      >
        <Box>
          <Typography
            variant='caption'
            sx={{
              fontWeight: 600,
              color: 'grey.800',
            }}
          >
            {t('reports.myReports.exportPopup.label')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              lineHeight: '24px',
              marginTop: '8px',
            }}
            variant='subtitle2'
          >
            {reportName}
          </Typography>
        </Box>
        <FormControl
          sx={{
            marginTop: '16px',
          }}
        >
          <FormLabel
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: '16px',
              letterSpacing: '0em',
              marginBottom: '8px',
            }}
          >
            {t('reports.myReports.exportPopup.fileType')}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby='demo-radio-buttons-group-label'
            defaultValue={FileType.CSV}
            name='radio-buttons-group'
            value={value}
            onChange={onChange}
          >
            <FormControlLabel value={FileType.CSV} control={<Radio size='small' />} label='CSV' />
          </RadioGroup>
        </FormControl>
      </CustomModal.Body>
      <CustomModal.Footer>
        <Button
          variant='outlined'
          sx={{
            marginRight: '16px',
            marginLeft: 'auto',
          }}
          onClick={onClose}
        >
          {t('button.cancel')}
        </Button>
        <Button variant='contained' onClick={onExport}>
          {t('button.export')}
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  )
}
