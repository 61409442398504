import { SvgIcon, SvgIconProps } from '@mui/material'

export const NewTabIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        color='action'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 5C12.2762 5 12.5 5.22386 12.5 5.5C12.5 5.74546 12.3231 5.94961 12.0899 5.99194L12
          6H8.50001V16H18.5V12.5C18.5 12.2239 18.7239 12 19 12C19.2455 12 19.4496 12.1769 19.492
          12.4101L19.5 12.5V16.5C19.5 16.78 19.28 17 19 17H16.5V19.5C16.5 19.78 16.28 20 16
          20H5.00001C4.72001 20 4.50001 19.78 4.50674 19.5L4.5 8.5C4.5 8.22 4.72 8 5 8.00673L7.50001
          8V5.5C7.50001 5.22012 7.72 5 8 5L12 5ZM7.50001 9H5.50001V19H15.5V17H8C7.72 17 7.5 16.78
          7.50674 16.5L7.50001 9ZM15 5L19 5.00182C19.28 5 19.5 5.22 19.5 5.5V9.5C19.5 9.77614
          19.2762 10 19 10C18.7546 10 18.5504 9.82312 18.5081 9.58988L18.5 9.5V6.706L13.3536
          11.8536C13.18 12.0271 12.9106 12.0464 12.7157 11.9114L12.6465 11.8536C12.4729 11.68
          12.4536 11.4106 12.5886 11.2157L12.6465 11.1464L17.792 6H15C14.7546 6 14.5504 5.82312
          14.5081 5.58988L14.5 5.5C14.5 5.22386 14.7239 5 15 5Z'
        />
      </SvgIcon>
    </>
  )
}
