import { useState } from 'react'
import { ISelectedPackages, ITerm } from '../constants/subscription'
import { getUserPackages, getUserPackagesAsAdmin } from '../services/subscription'
import { setError } from '../slice'
import { useAppDispatch } from 'app/hooks'

export const useGetSelectedPackages = () => {
  const dispatch = useAppDispatch()
  const [selectedPackages, setSelectedPackages] = useState<ISelectedPackages>({})

  const handleGetUserSubscriptions = async (
    productId: string,
    jurCode: string,
    userEmail?: string,
    isAdmin?: boolean
  ) => {
    dispatch(setError(null))

    try {
      const _selectedProductsIds = !isAdmin
        ? await getUserPackages(productId, jurCode)
        : await getUserPackagesAsAdmin(productId, jurCode, userEmail as string)

      const selectedPackagesData = _selectedProductsIds.reduce(
        (acc, curr) => {
          if (!acc[curr.id]) {
            if (curr.startDate && curr.endDate) {
              acc[curr.id] = {
                startDate: curr.startDate,
                endDate: curr.endDate,
                term: curr.term!,
              }
            }

            if (curr.availableLicenseQuantity) {
              acc[curr.id] = { availableLicenseQuantity: curr.availableLicenseQuantity }
            }

            if (curr.term) {
              acc[curr.id] = { term: curr.term }
            }
          }

          return acc
        },
        {} as {
          [key: string]:
            | {
                startDate?: string
                endDate?: string
                term: ITerm
              }
            | { availableLicenseQuantity: number }
        }
      )

      setSelectedPackages(selectedPackagesData)
    } catch (err) {
      dispatch(setError(err))
    }
  }

  return { handleGetUserSubscriptions, selectedPackages }
}
