import { SvgIcon, SvgIconProps } from '@mui/material'

export const PlusIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          clipRule='evenodd'
          fillRule='evenodd'
          d='M8.27346 3.33838L8.33337 3.33301C8.49701 3.33301 8.63311 3.45092 8.66134
          3.60642L8.66671 3.66634V7.99967H13C13.1637 7.99967 13.2998 8.11759 13.328
          8.27309L13.3334 8.33301C13.3334 8.49665 13.2155 8.63275 13.06 8.66097L13
          8.66634H8.66671V12.9997C8.66671 13.1633 8.54879 13.2994 8.39329 13.3276L8.33337
          13.333C8.16973 13.333 8.03364 13.2151 8.00541 13.0596L8.00004
          12.9997V8.66634H3.66671C3.50307 8.66634 3.36697 8.54842 3.33874 8.39293L3.33337
          8.33301C3.33337 8.16937 3.45129 8.03327 3.60679 8.00504L3.66671
          7.99967H8.00004V3.66634C8.00004 3.5027 8.11796 3.3666 8.27346 3.33838L8.33337
          3.33301L8.27346 3.33838Z'
        />
      </SvgIcon>
    </>
  )
}
