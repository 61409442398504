import { FC, MutableRefObject } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { useReactToPrint } from 'react-to-print'
import print from 'assets/icons/print.svg'

interface PrintHeaderProps {
  contentRef: MutableRefObject<HTMLDivElement | null>
  title: string
}

export const PrintHeader: FC<PrintHeaderProps> = ({ contentRef, title }) => {
  const printStyles =
    '@page{size: auto; margin: 0mm;} @media print {body {-webkit-print-color-adjust: exact; padding: 24px !important;}}'

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    pageStyle: printStyles,
    removeAfterPrint: true,
  })

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography fontSize='18px' fontWeight='600' lineHeight='24px'>
        {title}
      </Typography>
      <IconButton
        sx={{
          width: '36px',
          height: '36px',
          marginLeft: '10px',
          backgroundColor: 'primary.main',
          '&:hover': {
            backgroundColor: 'primary.600',
          },
          '&:active': {
            backgroundColor: 'primary.700',
          },
        }}
        onClick={handlePrint}
      >
        <img src={print} alt='' />
      </IconButton>
    </Box>
  )
}
