import { Dispatch, MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { handleFooter } from 'sharedSlices/ui'
import { AnyAction } from 'redux'
import { store } from 'app/store'

export const footerHeight = 133

export const useContainerScroll = (
  scrollContainerRef: MutableRefObject<HTMLDivElement | undefined | null>,
  dispatch: Dispatch<AnyAction>
) => {
  const prevScrollTop = useRef(0)
  const containerPadding = useRef(0)
  const isSkipScrollEvent = useRef(false)

  const checkChildrenContentOverflow = useCallback(
    (target: Element, scrollContainer: HTMLDivElement) => {
      let containerClientHeight = target.clientHeight
      const childrenHeight = Array.from(target.children).reduce((prev, curr) => {
        if (curr.children.length && curr.clientHeight === scrollContainer.clientHeight) {
          const _childrenHeight = Array.from(curr.children).reduce(
            (_prev, _curr) => _prev + _curr.clientHeight,
            0
          )
          return prev + _childrenHeight
        }
        return prev + curr.clientHeight
      }, 0)

      if (target.parentElement && target.parentElement.classList.contains('custom-container')) {
        containerClientHeight = target.parentElement.clientHeight
      }

      if (childrenHeight > containerClientHeight - containerPadding.current) {
        dispatch(handleFooter(
          Math.ceil(scrollContainer.scrollTop + scrollContainer.clientHeight) + 1 >= scrollContainer.scrollHeight)
        )
      } else if (childrenHeight < containerClientHeight - containerPadding.current) {
        dispatch(handleFooter(true))
      }
    },
    [dispatch]
  )

  const onScroll = useCallback(() => {
    if (isSkipScrollEvent.current) {
      return
    }

    if (scrollContainerRef && scrollContainerRef.current) {
      if (
        Math.ceil(scrollContainerRef.current.scrollTop + scrollContainerRef.current.clientHeight) +
          1 >=
          scrollContainerRef.current.scrollHeight &&
        Math.round(scrollContainerRef.current.scrollTop) > prevScrollTop.current &&
        !store.getState().ui.isFooterVisible
      ) {
        dispatch(handleFooter(true))
        requestAnimationFrame(() => {
          if (scrollContainerRef && scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight * 2
            prevScrollTop.current = Math.round(scrollContainerRef.current.scrollTop)
          }
        })
      }

      if (
        Math.ceil(scrollContainerRef.current.scrollTop + scrollContainerRef.current.clientHeight) <
          scrollContainerRef.current.scrollHeight &&
        Math.round(scrollContainerRef.current.scrollTop) < prevScrollTop.current &&
        store.getState().ui.isFooterVisible
      ) {
        dispatch(handleFooter(false))
        requestAnimationFrame(() => {
          if (scrollContainerRef && scrollContainerRef.current) {
            prevScrollTop.current = Math.round(scrollContainerRef.current.scrollTop)
          }
        })
      }

      prevScrollTop.current = Math.round(scrollContainerRef.current.scrollTop)
    }
  }, [dispatch, scrollContainerRef])

  useEffect(() => {
    if (scrollContainerRef && scrollContainerRef.current) {
      const { paddingTop, paddingBottom } = window.getComputedStyle(scrollContainerRef.current)
      const calculatePadding = parseFloat(paddingTop) + parseFloat(paddingBottom)

      if (!Number.isNaN(calculatePadding)) {
        containerPadding.current = calculatePadding
      }
    }
  }, [scrollContainerRef])

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    let resizeObserver: ResizeObserver
    let resizeContentObserver: ResizeObserver
    let mutationObserver: MutationObserver

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', onScroll)

      resizeObserver = new ResizeObserver((entries) => {
        if (
          scrollContainer.scrollTop > 0 &&
          Math.ceil(scrollContainer.scrollTop + scrollContainer.clientHeight) + 1 >=
            scrollContainer.scrollHeight
        ) {
          return
        }

        for (const entry of entries) {
          checkChildrenContentOverflow(entry.target, scrollContainer)
        }
      })

      resizeContentObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          checkChildrenContentOverflow(scrollContainer, scrollContainer)
        }
      })

      mutationObserver = new MutationObserver((mutationList: MutationRecord[]) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            checkChildrenContentOverflow(mutation.target as Element, scrollContainer)
            if (mutation.addedNodes.length && (mutation.addedNodes[0] as Element).classList.contains('content')) {
              resizeContentObserver.observe(mutation.addedNodes[0] as Element)
            } else if (mutation.removedNodes.length && (mutation.removedNodes[0] as Element).classList.contains('content')) {
              resizeContentObserver.disconnect()
            }
          }
        }
      })

      resizeObserver.observe(scrollContainer)
      mutationObserver.observe(scrollContainer, { childList: true })
      resizeContentObserver.disconnect()
    }

    return () => {
      if (scrollContainer) {
        dispatch(handleFooter(true))
        scrollContainer.removeEventListener('scroll', onScroll)
        if (resizeObserver && mutationObserver) {
          resizeObserver.disconnect()
          mutationObserver.disconnect()
        }
      }
    }
  }, [checkChildrenContentOverflow, dispatch, onScroll, scrollContainerRef])

  return isSkipScrollEvent
}
