import { LicenseType, ProductFeature } from 'features/subscriptionRequest/constants/subscription'
import { Package } from '../component/ProductPackages/constants'
import { FileType, IFile } from 'constants/file'

export const enum Jurisdiction {
  All = 'All',
}

export enum SectionName {
  Details = 'productConfiguration.sections.details',
  Packages = 'productConfiguration.sections.packages',
  AcceptableUsePolicy = 'productConfiguration.sections.acceptableUsePolicy',
  Privacy = 'productConfiguration.sections.privacy',
  Integrations = 'productConfiguration.sections.integrations',
}

export enum ProductConfigurationSection {
  Details = 1,
  Packages,
  AcceptableUsePolicy,
  Privacy,
  Integrations,
}

interface IProduct {
  id: string
  name: string
  productGroupName: string | null
  jurisdictionId: string
  countries: string[] | null
  features: ProductFeature[] | null
  jurisdiction: string | null
  permissions: number[] | null
}

export interface IJurisdiction {
  id: string
  name: string
  code: string
}

export interface IProductGroup {
  id: string
  name: string
  shortName: string
  url: string
  type: number
  products: IProduct[]
}

export interface IPolicy {
  title: string
  file: IFile
  titleFrench: string
  fileFrench: IFile
}

export interface IPrivacy {
  privacyViewType: PrivacyViewType
  title: string
  externalLink: string
  document: IFile | null
}

export interface IIntegrations {
  homePageUrl: string
  appId: string
}

export enum ProductGroupType {
  Single = 1,
  All = 2,
}

export interface IProductData {
  name: string
  shortName: string
  askAreDeloitteClient: boolean
  managingUsers: boolean
  jurisdictions: string[]
  roles: string[]
  packages: { [key: string]: Package[] }
  policies: { [key: string]: IPolicy }
  privacy: { [key: string]: IPrivacy }
  integrations: IIntegrations
}

export interface IProductConfigurationState {
  products: IProductGroup[]
  productData: IProductData
  jurisdictions: IJurisdiction[]
  sectionsState: ISection[]
  currentSection: ProductConfigurationSection
  selectedJurisdiction: string
  isEdit: boolean
  error: string | null | undefined
  loading: boolean
}

export interface IJurisdictionCreationRequest {
  jurisdictionId: string
  packages: {
    name: string
    description: { text: string; locale: string }[]
    durations: number[] | null
    licenseType: LicenseType
  }[]
  policies: {
    title: string
    text: string | ArrayBuffer
    locale: string
  }[]
  privacy: {
    privacyViewTypeId: PrivacyViewType
    localizations: {
      title: string
      text: string | ArrayBuffer | null
      link: string
      locale: string
    }[]
  }
}

export interface IProductCreationRequest {
  id: string
  name: string
  shortName: string
  features: ProductFeature[]
  jurisdictions: IJurisdictionCreationRequest[]
  roles: string[]
  applicationId: string
  url: string
  type: ProductGroupType
  locales: string[]
}

export interface ISection {
  name: SectionName
  available: boolean
}

export enum PrivacyViewType {
  Link = 1,
  CheckboxWithLink,
}

export const acceptedFileTypes = [FileType.Html, FileType.Pdf]

export const productConfigurationSections: ISection[] = [
  {
    name: SectionName.Details,
    available: true,
  },
  {
    name: SectionName.Packages,
    available: false,
  },
  {
    name: SectionName.AcceptableUsePolicy,
    available: false,
  },
  {
    name: SectionName.Privacy,
    available: false,
  },
  {
    name: SectionName.Integrations,
    available: false,
  },
]

export const privacyCheckboxOptions = [
  {
    label: 'productConfiguration.privacy.link.default',
    value: String(PrivacyViewType.Link),
  },
  {
    label: 'productConfiguration.privacy.link.checkbox',
    value: String(PrivacyViewType.CheckboxWithLink),
  },
]
