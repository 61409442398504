import { FC } from 'react'
import { Autocomplete, FormLabel, Checkbox, FormControlLabel, Typography, TextField } from '@mui/material'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { LabelProps, FieldProps, CustomFormProps, CheckBoxProps, TextFieldProps } from './interfaces'
import { DropdownIcon } from 'components/icons/Dropdown.icon'
import colors from '../../theme/colors'

const Label: FC<LabelProps> = ({ error, value, show = true, styles, required }) => {
  const { t } = useTranslation()
  return show ? (
    <FormLabel required={required} sx={styles || { fontSize: '12px', fontWeight: 600 }} error={error}>
      {t(value)}
    </FormLabel>
  ) : (
    <></>
  )
}

const List = <T,>({
  defaultValue,
  disabled = false,
  filterOptions,
  forcePopupIcon = true,
  id,
  inputValue,
  loading,
  multiple = false,
  onClose,
  onOpen,
  filterSelectedOptions,
  isOptionEqualToValue,
  open,
  options,
  popupIcon,
  show = true,
  slotProps,
  styles,
  value,
  noOptionsText = 'autocomplete.noOptions',
  onChange,
  onInputChange,
  getOptionLabel,
  groupBy,
  renderGroup,
  renderInput,
  renderOption,
}: FieldProps<T>) => {
  const { t } = useTranslation()
  return show ? (
    <Autocomplete
      data-testid='autocomplete'
      isOptionEqualToValue={isOptionEqualToValue}
      autoHighlight
      clearOnBlur={true}
      disableClearable
      disabled={disabled}
      filterSelectedOptions={filterSelectedOptions}
      forcePopupIcon={forcePopupIcon}
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      id={id}
      loading={loading}
      loadingText={t('autocomplete.loading')}
      multiple={multiple}
      onChange={onChange}
      onClose={onClose}
      onInputChange={onInputChange}
      onOpen={onOpen}
      open={open}
      options={options}
      renderGroup={renderGroup}
      renderInput={renderInput}
      renderOption={renderOption}
      sx={{
        width: '100%',
        '&&& fieldset': {
          borderColor: 'grey.100',
          borderWidth: '1px',
        },
        '.MuiSvgIcon-root': {
          color: 'common.black',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.blue.main,
        },
        ...(styles || { mb: '10px' }),
      }}
      value={value}
      defaultValue={defaultValue}
      filterOptions={filterOptions}
      noOptionsText={t(noOptionsText)}
      popupIcon={popupIcon || <DropdownIcon />}
      slotProps={{
        ...slotProps,
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: () => [0, 5],
              },
            },
          ],
        },
        paper: {
          sx: {
            '& .MuiAutocomplete-listbox': {
              padding: 0,
              '& .MuiAutocomplete-option': {
                lineHeight: '24px',
                fontSize: '14px',
                padding: '8px',
                '&[aria-selected="true"], &[aria-selected="true"] .MuiTypography-root': {
                  color: colors.blue.main,
                  fontWeight: '600',
                },
                '&[aria-selected="true"]': {
                  backgroundColor: colors.lightBlue,
                },
              },
              '& .MuiAutocomplete-option.Mui-focused': {
                backgroundColor: colors.bgDisabled,
              },
            },
          },
        },
      }}
      inputValue={inputValue}
    />
  ) : (
    <></>
  )
}

const CheckBox: FC<CheckBoxProps> = ({
  name,
  checked,
  error,
  indeterminate,
  touched,
  label,
  disabled,
  value,
  onChange,
}) => {
  const { t } = useTranslation()

  const chkControl = (
    <Checkbox
      indeterminate={indeterminate}
      size='small'
      sx={{
        m: '0px',
        pl: '0px',
        color: touched && error ? 'error.main' : '',
      }}
    />
  )

  const chkLabel = (
    <Typography variant='body2' color={touched && error ? 'error.main' : ''}>
      {t(label)}
    </Typography>
  )

  const commonProps = {
    type: 'checkbox',
    name,
    as: FormControlLabel,
    control: chkControl,
    label: chkLabel,
    disabled,
  }

  const ParentCheckbox = () => (
    <Field style={{ padding: 0, margin: 0 }} {...commonProps} checked={checked} onChange={onChange} />
  )
  const DefaultCheckBox = () => <Field style={{ padding: 0, margin: 0 }} {...commonProps} value={value} />

  return <>{onChange ? <ParentCheckbox /> : <DefaultCheckBox />}</>
}

const InputField: FC<TextFieldProps> = (props) => {
  return <TextField {...props} />
}

const CustomForm: FC<CustomFormProps> = ({ children }) => {
  return <>{children}</>
}

export default Object.assign(CustomForm, {
  Label: Label,
  Autocomplete: List,
  CheckBox: CheckBox,
  TextField: InputField,
})
