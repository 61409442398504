import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { IJurisdiction, IProductCreationRequest, IProductGroup } from '../constants'
import { geoApi } from 'services/api/geoApi'

export const checkProductGroupNameIsUnique = (name: string): Promise<boolean> => {
  return geoApi
    .postAllGeo<boolean>('product-group/is-unique-fullName', name, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((responses: boolean[]) => responses.every((response) => response))
}

export const checkProductShortNameIsUnique = (shortName: string): Promise<boolean> => {
  return geoApi
    .postAllGeo<boolean>('product-group/is-unique-shortName', shortName, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((responses: boolean[]) => responses.every((response) => response))
}

export const createProductGroup = (
  payload: IProductCreationRequest,
  jurisdictions: IJurisdiction[]
): Promise<void[]> => {
  const jurCodes = jurisdictions.map((jur) => jur.code)
  return geoApi.postSpecificGeos<void>('product-group/', jurCodes, payload)
}

export const getProductGroups = createAsyncThunk<IProductGroup[], void, { rejectValue: AxiosError }>(
  'productConfiguration/getProductGroups',
  async (_, { rejectWithValue }) => {
    try {
      return await geoApi.getAllGeo('product-group')
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchJurisdictions = createAsyncThunk<IJurisdiction[], void, { rejectValue: AxiosError }>(
  'productConfiguration/fetchJurisdictions',
  async (_, { rejectWithValue }) => {
    try {
      return await geoApi.getAllGeo('jurisdictions')
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)
