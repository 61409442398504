import { SvgIcon, SvgIconProps } from '@mui/material'

export const CheckboxIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect x='1' y='1' width='14' height='14' rx='1' fill='white' />
      <rect
        className='border'
        x='1'
        y='1'
        width='14'
        height='14'
        rx='1'
        stroke='#757575'
        strokeWidth='2'
        strokeMiterlimit='16'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}
