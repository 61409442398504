import { Box, Modal, SxProps } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { EditPrimaryUserForm } from '../editPrimaryUserForm'
const modalStyle: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '640px',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0 0 2px 2px',
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.12)',
}
export const EditPrimaryUserModal = () => {
  const { isEditPrimaryUserModalOpen } = useAppSelector(
    (state) => state.ui.manageUserSubscriptionModals
  )
  return (
    <Modal open={isEditPrimaryUserModalOpen}>
      <Box sx={modalStyle}>
        <EditPrimaryUserForm />
      </Box>
    </Modal>
  )
}
