import { Modal, Box, SxProps } from '@mui/material'
import { useParams } from 'react-router-dom'

import { handleDeleteAdminModal } from 'sharedSlices/ui'
import { getEmployeesByRole, resetEmployeeSelectedId } from '../../slices'

import { DeleteAdminForm } from '../deleteAdminForm'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { showMessage } from 'utils/errorMessageHandler'
import { deleteUser } from '../../services'
import { selectEmployeeSelectedFromList } from 'features/manageAdmins/slices/employees/selectors'
import { DEMO_USER } from 'features/manageAdmins/constants'

const modalStyle: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '480px',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0 0 2px 2px',
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.12)',
}
export const DeleteAdminModal = () => {
  const dispatch = useAppDispatch()
  const { isDeleteAdminModalOpen } = useAppSelector((state) => state.ui)
  const { employeeRoleId } = useAppSelector((state) => state.manageAdmins)
  const { roleName } = useParams()
  const employeeInfo = useAppSelector(selectEmployeeSelectedFromList)

  const handleSuccessfulDelete = () => {
    dispatch(resetEmployeeSelectedId())
    dispatch(handleDeleteAdminModal(false))
    dispatch(getEmployeesByRole(roleName!))
    showMessage.success('mySnackbar', 'manageAdmins.message.success')
  }

  const deleteAdminFormData = () => {
    if (employeeInfo) {
      if (employeeRoleId === DEMO_USER) {
        deleteUser.demo(employeeInfo).then(() => handleSuccessfulDelete())
      } else {
        deleteUser.admin(employeeInfo).then(() => handleSuccessfulDelete())
      }
    }
  }

  return (
    <Modal open={isDeleteAdminModalOpen}>
      <Box sx={modalStyle}>
        <DeleteAdminForm
          deleteAdminFormData={deleteAdminFormData}
          employeeInfo={employeeInfo}
          title={employeeRoleId === DEMO_USER ? 'removeAdmin.demoUserTitle' : 'removeAdmin.title'}
          description={employeeRoleId === DEMO_USER ? 'removeAdmin.demoUserDescription' : 'removeAdmin.description'}
        />
      </Box>
    </Modal>
  )
}
