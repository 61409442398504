import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, TextField, Typography } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import {
  DeloitteBusinessUnitType,
  deloitteBusinessUnitTypes,
  IDeloitteBusinessUnit,
  IDeloitteBusinessUnitErrors,
  OptionWithNullableValue,
} from 'features/subscriptionRequest/constants/subscription'
import { CustomTextField } from 'components/CustomTextField'
import { deloitteEmailRegEx } from 'features/subscriptionRequest/constants/email'
import CustomForm from 'components/CustomForm'

interface Props {
  index: number
  data: IDeloitteBusinessUnit
  onChangeUnitField: (
    index: number,
    field: keyof IDeloitteBusinessUnit,
    value: string | DeloitteBusinessUnitType
  ) => void
  onBlur: (index: number, field: keyof IDeloitteBusinessUnitErrors, isValid: boolean) => void
}

export const BusinessUnitForm: FC<Props> = ({ index, data, onChangeUnitField, onBlur }) => {
  const { t } = useTranslation()

  const handleBlur = (
    field: keyof IDeloitteBusinessUnit['errors'],
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value

    if (value) {
      const isValid = field === 'email' ? deloitteEmailRegEx.test(value) : !!value.trim()
      onBlur(index, field, isValid)
    } else {
      onBlur(index, field, true)
    }
  }

  return (
    <Fragment>
      <Box
        sx={{
          '& label': { marginTop: '16px' },
        }}
      >
        <CustomTextField
          error={data.errors.name}
          label={t('subscriptionInit.isDeloitteClient.name.label')!}
          placeholder={t('subscriptionInit.isDeloitteClient.name.placeholder')!}
          value={data.name}
          required
          onChange={(e) => onChangeUnitField(index, 'name', e.target.value)}
          onBlur={(e) => handleBlur('name', e)}
          multiline
        />
        <CustomTextField
          error={data.errors.email}
          label={t('subscriptionInit.isDeloitteClient.email.label')!}
          placeholder={t('subscriptionInit.isDeloitteClient.email.placeholder')!}
          value={data.email}
          required
          onChange={(e) => onChangeUnitField(index, 'email', e.target.value)}
          onBlur={(e) => handleBlur('email', e)}
          multiline
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <CustomForm.Label
          styles={{
            m: '16px 0 8px 0',
            fontSize: '12px',
            fontWeight: 600,
            color: 'grey.800',
          }}
          value='subscriptionInit.isDeloitteClient.businessUnit.label'
        />
        <Box display='flex'>
          <CustomForm.Autocomplete
            id='business-unit'
            test-id='business-unit'
            options={deloitteBusinessUnitTypes}
            value={deloitteBusinessUnitTypes.find((type) => type.value === data.type)}
            inputValue={data.type === null ? '' : data.type}
            getOptionLabel={(unit) => t(unit.label)}
            isOptionEqualToValue={(option, value) => option?.value === value?.value && value?.value !== ''}
            onChange={(_, unit) => {
              if ((unit as OptionWithNullableValue)?.value === '') {
                onChangeUnitField(index, 'type', '')
              }
            }}
            onInputChange={(_, value) => {
              if (value !== data.type && value !== t('subscriptionInit.isDeloitteClient.businessUnit.placeholder')!) {
                onChangeUnitField(index, 'type', value)
              }
            }}
            filterOptions={createFilterOptions({
              matchFrom: 'start',
              stringify: (option: OptionWithNullableValue) => option.value || '',
            })}
            renderOption={(props, unit) => (
              <Box component='span' {...props}>
                <Typography variant='body2'>{t(unit.label)}</Typography>
              </Box>
            )}
            styles={{
              mb: 0,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ p: 0, m: 0 }}
                fullWidth
                name='unit'
                placeholder={t('subscriptionInit.isDeloitteClient.businessUnit.placeholder')!}
              />
            )}
          />
        </Box>
      </Box>
    </Fragment>
  )
}
