import { FC } from 'react'
import { Box, SxProps, Typography } from '@mui/material'
import { NavLink, To } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const menuLeftButtonStyle: SxProps = {
  height: '40px',
  width: '46px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const menuItemContainer: SxProps = {
  minWith: '46px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  div: {
    ...menuLeftButtonStyle,
    svg: { color: 'common.black', fontSize: 16 },
  },
  '.MuiTypography-root.MuiTypography-body2': { color: 'common.black' },
  '&:hover': {
    div: {
      color: 'red',
      svg: { color: 'primary.main' },
    },
    '.MuiTypography-root.MuiTypography-body2': { color: 'primary.main' },
  },
}

const menuLeftButtonActive: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderLeftColor: 'primary.main',
  div: {
    ...menuLeftButtonStyle,
    borderLeft: '3px solid',
    borderColor: 'primary.main',
    svg: {
      marginRight: '3px',
      color: 'primary.main',
      fontSize: 16,
    },
  },
  '.MuiTypography-root.MuiTypography-body2': { color: 'primary.main' },
}

interface Props {
  to: To
  children: JSX.Element
  text?: string
  showText?: boolean
}

export const NavLinkIcon: FC<Props> = ({ children, to, text, showText = true }) => {
  const { t } = useTranslation()
  return (
    <NavLink
      to={to}
      style={{ textDecoration: 'none' }}
      ref={(node) => node && text && (node.title = t(text))}
    >
      {({ isActive }) => (
        <Box sx={isActive ? menuLeftButtonActive : menuItemContainer}>
          <Box>{children}</Box>
          {text ? (
            <Typography
              variant='body2'
              ml='3px'
              sx={{ display: showText ? 'inherit' : 'none', fontWeight: isActive ? 700 : 100 }}
            >
              {t(text)}
            </Typography>
          ) : null}
        </Box>
      )}
    </NavLink>
  )
}
