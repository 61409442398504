import { Typography, SxProps, Box } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { selectRolePaths } from 'features/manageAdmins/slices/manageAdmins/selectors'
import { setEmployeeRoleId } from 'features/manageAdmins/slices/manageAdmins'

const linkStyle: SxProps = {
  padding: '8px',
  color: 'common.white',
  border: '1px solid',
  borderRight: 0,
  borderColor: 'primary.main',
  textDecoration: 'none',
}
const tabsStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& a': {
    textDecoration: 'none',
    div: {
      border: '1px 0 1px 1px',
    },
  },

  '& a:first-of-type': {
    div: {
      borderRadius: ' 2px 0 0 2px ',
    },
  },
  '& a:last-child': {
    div: {
      borderRadius: '0 2px 2px 0',
      borderRight: '1px solid',
      borderRightColor: 'primary.main',
    },
  },
}

interface Tab {
  path: string
  id: number
  name: string
}

export const AdminTabsNavigation = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const roleTabs = useAppSelector(selectRolePaths)
  const handleActive = ({ isActive }: { isActive: boolean }) => ({
    ...linkStyle,
    color: isActive ? 'common.white' : 'primary.main',
    backgroundColor: isActive ? 'primary.main' : 'common.white',
  })

  const Tab = ({ path, id, name }: Tab) => {
    return (
      <NavLink to={path} key={id}>
        {({ isActive }) => (
          <Box
            sx={() => handleActive({ isActive })}
            onClick={() => dispatch(setEmployeeRoleId(id))}
          >
            <Typography variant='body2'>{t(name)}</Typography>
          </Box>
        )}
      </NavLink>
    )
  }

  return (
    <Box sx={tabsStyles} data-testid='adminTabs'>
      {roleTabs.map((tab) => (
        <Tab key={tab.id} {...tab} />
      ))}
    </Box>
  )
}
