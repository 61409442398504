import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { SubscriptionContainer } from '../subscriptionContainer'
import {
  goNext,
  resetData,
  resetUserInfo,
  selectCountry,
  setActiveStep,
  setAllAccountDetails,
  setEmail,
  setEmailError,
  setIsDeloitteClient,
  setIsSubmissionSucceed,
  setTerm,
} from 'features/subscriptionRequest/slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  IStep,
  NEXT_STEP,
  requestSubscriptionSteps,
  StepCreateSubscription,
  StepName,
} from 'features/subscriptionRequest/constants/stepper'
import { useTranslation } from 'react-i18next'
import {
  selectIsValidAccountDetails,
  selectPackageGroups,
  selectRestrictedCountry,
  selectSubscriptionDetails,
} from 'features/subscriptionRequest/slice/selectors'
import {
  submitSubscriptionRequest,
  submitSubscriptionRequestAsAdmin,
  upgradeSubscriptionRequest,
  validateSubscriptionRequest,
} from 'features/subscriptionRequest/services/subscription'
import { SubscriptionInit } from '../subscriptionInit'
import { SelectPackages } from '../selectPackages'
import { AcceptableUsePolicy } from '../acceptableUsePolicy'
import { AccountDetails } from '../accountDetails'
import {
  DeloitteClientType,
  LicenseType,
  ProductFeature,
  ProductGroupType,
  SubscriptionAction,
} from 'features/subscriptionRequest/constants/subscription'
import { isValidEmail, validateEmail } from 'features/subscriptionRequest/services/email'
import { EmailValidationError } from 'features/subscriptionRequest/constants/email'
import { getEndUser } from 'sharedSlices/auth'
import { useIsAuthenticated } from '@azure/msal-react'
import {
  fetchProduct,
  fetchProductPackageGroups,
  fetchProductPackages,
  fetchProducts,
  fetchStates,
  getUserInfo,
} from 'features/subscriptionRequest/slice/services'
import { ConfirmPopup, ConfirmPopupButton } from 'components/ConfirmPopup'
import { selectAvailableLanguageByProduct } from 'sharedSlices/localization/selectors'
import { handleLanguageSelected } from 'sharedSlices/localization'
import PaymentDetails from '../paymentDetails'
import { useGetSelectedPackages } from 'features/subscriptionRequest/hooks/useGetSelectedPackages'
import { PaymentType } from 'features/subscriptionRequest/constants/payment'

export const CreateSubscription = () => {
  const { i18n } = useTranslation()
  const [search] = useSearchParams()
  const { productGroupShortName } = useParams()
  const [isOpenCancelSubscription, setIsOpenCancelSubscription] = useState(false)
  const [isOpenSubmissionSubscription, setIsOpenSubmissionSubscription] = useState(false)
  const {
    steps,
    activeStep,
    productGroup,
    isPolicyAccepted,
    isPolicyOptionsAccepted,
    acceptableUsePolicies,
    activePolicyIndex,
    terms,
    valuations,
    licenses,
    products,
    countries,
    userInfo,
    paymentTypes,
    zuoraRefIds,
    subscription: {
      country: selectedCountry,
      products: selectedProducts,
      email,
      emailError,
      isDeloitteClient,
      businessUnits,
    },
    error: serverError,
  } = useAppSelector((state) => state.subscriptionRequest)
  const { endUserInformation } = useAppSelector((state) => state.auth)
  const isValidAccountDetails = useAppSelector(selectIsValidAccountDetails)
  const subscriptionDetails = useAppSelector(selectSubscriptionDetails)
  const { languageSelected } = useAppSelector((state) => state.localization)
  const { groupedTerms, packageGroups } = useAppSelector(selectPackageGroups)
  const productLanguages = useAppSelector(selectAvailableLanguageByProduct)
  const restrictedCountryIsSelected = useAppSelector(selectRestrictedCountry)
  const { handleGetUserSubscriptions, selectedPackages } = useGetSelectedPackages()
  const productIdFromParams = search.get('productId')

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const isAuthenticated = useIsAuthenticated()
  const isEmailValidationPending = useRef(false)
  const isShowSummary = useRef(false)
  const selectedProductId = useRef('')
  const subscribeToProduct = Boolean(search.get('subscribeToProduct'))

  const navigator = useNavigate()
  const { pathname } = useLocation()

  const hasDeloitteClientFeature = useMemo(() => {
    return (
      productGroup.products &&
      productGroup.products.flatMap((product) => product.features).includes(ProductFeature.DeloitteClient)
    )
  }, [productGroup])

  const hasAuditClientFeature = useMemo(() => {
    return (
      productGroup.products &&
      productGroup.products.flatMap((product) => product.features).includes(ProductFeature.AuditClient)
    )
  }, [productGroup])

  const isBusinessUnitsValid = useMemo(() => {
    return businessUnits.every((unit) => {
      return unit.name && unit.email && !unit.errors.name && !unit.errors.email
    })
  }, [businessUnits])

  const hasPaymentFeature = useMemo(() => {
    return (
      productGroup.products &&
      productGroup.products.flatMap((product) => product.features).includes(ProductFeature.Payment)
    )
  }, [productGroup])

  const isShowSubscriptionSummary = useMemo(() => {
    return (
      hasPaymentFeature &&
      [StepCreateSubscription.SelectPackage, StepCreateSubscription.AccountDetails].includes(activeStep)
    )
  }, [activeStep])

  const requestSubscriptionStepsByProductGroup = hasPaymentFeature
    ? [...requestSubscriptionSteps]
    : requestSubscriptionSteps.filter((step) => step.name !== StepName.PaymentDetails) //for subscription from the user

  const subscribeToProductSteps: IStep[] = requestSubscriptionStepsByProductGroup.filter(
    (step) => step.name !== StepName.AcceptableUsePolicy
  ) //for admin create subscription

  const stepsWithToU: IStep[] = requestSubscriptionStepsByProductGroup.map((step) => ({
    ...step,
    name: step.name === StepName.AcceptableUsePolicy ? StepName.TermsOfUse : step.name,
  }))

  const selectedProduct = productGroup.products.find((product) => product.id === selectedCountry?.productId)

  useEffect(() => {
    const newSteps = subscribeToProduct
      ? subscribeToProductSteps
      : productGroup.type === ProductGroupType.All
      ? stepsWithToU
      : requestSubscriptionStepsByProductGroup

    dispatch(resetData(newSteps))
    if (isAuthenticated) {
      dispatch(getEndUser())
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (selectedCountry) {
      dispatch(
        fetchProductPackages({
          productId: selectedCountry.productId,
          jurCode: selectedCountry.abbreviation,
        })
      )
      dispatch(
        fetchProduct({
          productId: selectedCountry.productId,
          jurCode: selectedCountry.abbreviation,
        })
      )

      if (selectedProduct) {
        selectedProductId.current = selectedProduct.id
        isShowSummary.current = selectedProduct.features.includes(ProductFeature.Summary)

        if (
          endUserInformation &&
          selectedProduct.features.includes(ProductFeature.Extendable) &&
          [
            EmailValidationError.SubscriptionReadyUpgrade,
            EmailValidationError.SubscriptionExistsForAnotherProduct,
          ].includes(emailError)
        ) {
          handleGetUserSubscriptions(selectedProduct.id, selectedCountry.abbreviation)
        }
      }
    }
  }, [selectedCountry, dispatch, languageSelected, emailError, selectedProduct])

  useEffect(() => {
    if (hasAuditClientFeature || hasDeloitteClientFeature) {
      dispatch(setIsDeloitteClient(DeloitteClientType.No))
    }
    if (productGroup.type === ProductGroupType.All) {
      const jurisdictionProducts = countries.map((country) => ({
        productId: country.productId,
        jurCode: country.abbreviation,
      }))
      dispatch(fetchProductPackageGroups(jurisdictionProducts))
      dispatch(fetchProducts(jurisdictionProducts))
    }
  }, [dispatch, productGroup])

  useEffect(() => {
    if (endUserInformation && countries.length) {
      const endUserCountry = countries.find((country) => country.id === endUserInformation.countryId)
      if (endUserCountry) {
        validateEmail.asEndUser(endUserInformation.email, endUserCountry.productId).then((error) => {
          dispatch(setEmail(endUserInformation.email))
          dispatch(selectCountry(endUserCountry))
          dispatch(
            setEmailError(
              error === EmailValidationError.UnderReview || error === EmailValidationError.Exists
                ? EmailValidationError.ExistsForEndUser
                : error
            )
          )

          const { id, countryId, stateId, ...accountDetails } = endUserInformation

          if (
            [EmailValidationError.SubscriptionNotCreated, EmailValidationError.SubscriptionReadyUpgrade].includes(error)
          ) {
            dispatch(fetchStates(endUserCountry.id)).then((states) => {
              const endUserState =
                (states.payload &&
                  Array.isArray(states.payload) &&
                  states.payload.find((_state) => _state.id === endUserInformation.stateId)) ||
                null

              dispatch(
                setAllAccountDetails({
                  ...accountDetails,
                  country: endUserCountry,
                  state: endUserState,
                })
              )
            })
          }
        })
      } else {
        dispatch(setEmail(endUserInformation.email))
        dispatch(setEmailError(EmailValidationError.UnavailableCountry))
      }
    }
  }, [endUserInformation, countries])

  useEffect(() => {
    const isLanguageSelectedAvailable = productLanguages.some((lang) => lang.localeCode === languageSelected.localeCode)
    if (!isLanguageSelectedAvailable && productLanguages.length > 0) {
      i18n.changeLanguage(productLanguages[0].localeCode)
      dispatch(handleLanguageSelected(productLanguages[0].localeCode))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetUserData = useCallback(() => {
    dispatch(resetUserInfo())
  }, [dispatch])

  useEffect(() => {
    return () => {
      resetUserData()
    }
  }, [resetUserData])

  const cancelSubscriptionModalButtons = useMemo((): ConfirmPopupButton[] => {
    return [
      {
        action: () => setIsOpenCancelSubscription(false),
        name: t('button.no'),
        style: {
          minWidth: '54px',
          borderRadius: '2px',
          marginRight: '16px',
        },
        type: 'outlined',
      },
      {
        action: () => {
          dispatch(
            resetData(
              subscribeToProduct
                ? subscribeToProductSteps
                : productGroup.type === ProductGroupType.All
                ? stepsWithToU
                : requestSubscriptionStepsByProductGroup
            )
          )
          setIsOpenCancelSubscription(false)
        },
        name: t('button.yes'),
        style: {
          minWidth: '56px',
          borderRadius: '2px',
        },
        type: 'contained',
      },
    ]
  }, [t])

  const submissionSubscriptionModalButton = useMemo((): ConfirmPopupButton[] => {
    return [
      {
        action: () => {
          if (subscribeToProduct) {
            dispatch(resetData(subscribeToProductSteps))
            navigator(-1)
          } else {
            productGroup.url && (location.href = productGroup.url)
          }
        },
        name: subscribeToProduct
          ? t('modalSubscriptionSubmission.buttons.backToSubscriptions.label')
          : t('modalSubscriptionSubmission.buttons.goHome.label', {
              productName: productGroup.shortName,
            }),
        style: {
          minWidth: '54px',
          borderRadius: '2px',
          marginLeft: '16px',
        },
        type: 'contained',
      },
    ]
  }, [t, productGroup.shortName])

  const onCancelSubscription = useCallback(() => {
    setIsOpenCancelSubscription(true)
  }, [])

  const onSubmitSubscription = useCallback(() => {
    if (subscribeToProduct && isValidAccountDetails) {
      validateSubscriptionRequest(subscriptionDetails).then(({ isValid, validationPayloads }) => {
        if (isValid) {
          submitSubscriptionRequestAsAdmin(subscriptionDetails, validationPayloads, selectedCountry!.abbreviation).then(
            () => {
              setIsOpenSubmissionSubscription(true)
              dispatch(setIsSubmissionSucceed(true))
              navigator(`/subscribe/${productGroupShortName}/success`)
            }
          )
        }
      })
    } else if (isValidAccountDetails && emailError === EmailValidationError.SubscriptionNotCreated) {
      validateSubscriptionRequest(subscriptionDetails).then(({ isValid, validationPayloads }) => {
        if (isValid) {
          const jurisdictionProducts = selectedProducts.map((packageProduct) => {
            const _product = productGroup.products.find((productItem) => productItem.id === packageProduct.id)!
            const jurCode = _product.countries![0].abbreviation
            return { productId: _product.id, jurCode }
          })
          Promise.all(
            jurisdictionProducts.map(({ jurCode, productId }) => {
              const payload = {
                ...subscriptionDetails,
                products: subscriptionDetails.products.filter((i) => i.id === productId),
              }
              return submitSubscriptionRequest(payload, validationPayloads, jurCode, endUserInformation)
            })
          ).then(() => {
            setIsOpenSubmissionSubscription(true)
            dispatch(setIsSubmissionSucceed(true))
            navigator(`/subscribe/${productGroupShortName}/success`)
          })
        }
      })
    } else if (isValidAccountDetails && emailError === EmailValidationError.SubscriptionReadyUpgrade) {
      upgradeSubscriptionRequest(
        subscriptionDetails,
        endUserInformation,
        selectedProductId.current,
        selectedCountry!.abbreviation
      ).then(() => {
        setIsOpenSubmissionSubscription(true)
      })
    }
  }, [isValidAccountDetails, subscriptionDetails])

  const isContinueDisabled: { [key: number]: boolean } = {
    [StepCreateSubscription.SubscriptionInit]:
      !!serverError ||
      !(subscribeToProduct
        ? selectedCountry &&
          email &&
          isDeloitteClient !== DeloitteClientType.Yes &&
          EmailValidationError.UnavailableCountry !== emailError &&
          EmailValidationError.UnderReview !== emailError &&
          EmailValidationError.Exists !== emailError &&
          EmailValidationError.Invalid !== emailError
        : (selectedCountry || productGroup.type === ProductGroupType.All) &&
          email &&
          [EmailValidationError.SubscriptionNotCreated, EmailValidationError.SubscriptionReadyUpgrade].includes(
            emailError
          ) &&
          (hasDeloitteClientFeature
            ? isDeloitteClient === DeloitteClientType.No || isBusinessUnitsValid
            : isDeloitteClient !== DeloitteClientType.Yes)),
    [StepCreateSubscription.SelectPackage]:
      !selectedProducts.length ||
      !products.length ||
      (productGroup.type === ProductGroupType.All
        ? selectedProducts.some((product) => {
            return product.packages.some((selectedPackage) => {
              return !licenses[selectedPackage.packageGroup.name]
            })
          })
        : selectedProducts[0].packages.some((selectedPackage) => {
            const filteredTerms = products[0].terms.filter((term) => term.packageId === selectedPackage.id)
            return selectedPackage.licenseType === LicenseType.TokenBased
              ? !valuations[selectedPackage.id]
              : !terms[selectedPackage.id] && filteredTerms.length > 1
          })),
    [StepCreateSubscription.AcceptableUsePolicy]:
      !isPolicyAccepted[activePolicyIndex] ||
      !acceptableUsePolicies.length ||
      (isPolicyOptionsAccepted[activePolicyIndex] &&
        Object.values(isPolicyOptionsAccepted[activePolicyIndex]).some((isAccepted) => !isAccepted)),
    [StepCreateSubscription.AccountDetails]:
      (productGroup.type === ProductGroupType.All && !isValidAccountDetails) || restrictedCountryIsSelected,
  }

  const selectDuration = useCallback(
    (packageId: string, termId: string) => {
      const selectedTerm = groupedTerms && groupedTerms[packageId].find((term) => term.id === termId)

      if (selectedTerm) {
        dispatch(setTerm({ [packageId]: selectedTerm }))
      }
    },
    [dispatch, groupedTerms]
  )
  const onValidateEmailAsAdmin = (targetStep: number) => {
    if (isValidEmail(email!)) {
      if (!isEmailValidationPending.current) {
        isEmailValidationPending.current = true
        validateEmail
          .asAdmin(email!, selectedProductId.current)
          .then((errorType) => {
            const productId = selectedProduct?.id as string
            const jurCode = selectedCountry?.abbreviation as string

            if (
              errorType === EmailValidationError.SubscriptionReadyUpgrade &&
              selectedProduct?.features.includes(ProductFeature.Extendable)
            ) {
              handleGetUserSubscriptions(productId as string, jurCode, email as string, true)
            }

            if (productIdFromParams !== selectedProduct?.id) {
              search.set('productId', productId)
              const url = `${pathname}?${search.toString()}`
              navigator(url, { replace: true })
            }

            dispatch(getUserInfo(email!))
            dispatch(setEmailError(errorType))

            if (!errorType) {
              activeStep === StepCreateSubscription.SubscriptionInit &&
              targetStep === StepCreateSubscription.SelectPackage
                ? dispatch(goNext())
                : dispatch(setActiveStep(targetStep))
            }

            if (
              [
                EmailValidationError.SubscriptionExistsForAnotherProduct,
                EmailValidationError.SubscriptionReadyUpgrade,
              ].includes(errorType)
            ) {
              if (userInfo) {
                const { id, countryId, stateId, ...userInfoDetails } = userInfo
                const userCountry = countries.find((country) => country.id === userInfo.countryId)
                dispatch(fetchStates(countryId)).then((states) => {
                  const userState =
                    (states.payload &&
                      Array.isArray(states.payload) &&
                      states.payload.find((_state) => _state.id === stateId)) ||
                    null
                  dispatch(
                    setAllAccountDetails({
                      ...userInfoDetails,
                      country: userCountry!,
                      state: userState,
                    })
                  )
                })
                targetStep === activeStep + NEXT_STEP ? dispatch(goNext()) : dispatch(setActiveStep(targetStep))
              }
            }
            isEmailValidationPending.current = false
          })
          .catch(() => {
            isEmailValidationPending.current = false
          })
      }
    } else {
      dispatch(setEmailError(EmailValidationError.Invalid))
    }
  }

  const onValidateEmail = (targetStep: number) => {
    if (!isValidEmail(email!)) {
      dispatch(setEmailError(EmailValidationError.Invalid))
    } else {
      if (!isEmailValidationPending.current) {
        isEmailValidationPending.current = true
        validateEmail
          .asAnonymousUser(email!)
          .then((error) => {
            dispatch(setEmailError(error))
            isEmailValidationPending.current = false
            if (!error) {
              activeStep === StepCreateSubscription.SubscriptionInit &&
              targetStep === StepCreateSubscription.SelectPackage
                ? dispatch(goNext())
                : dispatch(setActiveStep(targetStep))
            }
          })
          .catch(() => {
            isEmailValidationPending.current = false
          })
      }
    }
  }

  useEffect(() => {
    if (
      hasPaymentFeature &&
      subscriptionDetails.products
        .filter(({ id }) => paymentTypes[id] === PaymentType.CreditCard)
        .every(({ id }) => zuoraRefIds[id])
    ) {
      onSubmitSubscription()
    }
  }, [zuoraRefIds])

  return (
    <>
      <SubscriptionContainer
        steps={steps}
        activeStep={activeStep}
        activePolicyIndex={activePolicyIndex}
        productGroupName={productGroup.name}
        subscriptionAction={SubscriptionAction.CreateSubscription}
        onCancelSubscription={onCancelSubscription}
        onSubmitSubscription={onSubmitSubscription}
        onValidateEmail={subscribeToProduct ? onValidateEmailAsAdmin : onValidateEmail}
        isContinueDisabled={isContinueDisabled[activeStep]}
        isShowSubscriptionSummary={isShowSubscriptionSummary}
      >
        {
          {
            [StepCreateSubscription.SubscriptionInit]: <SubscriptionInit />,
            [StepCreateSubscription.SelectPackage]: (
              <SelectPackages
                packageGroups={packageGroups}
                subscriptionAction={SubscriptionAction.CreateSubscription}
                selectDuration={selectDuration}
                selectedPackages={selectedPackages}
              />
            ),
            [StepCreateSubscription.AcceptableUsePolicy]: !subscribeToProduct && <AcceptableUsePolicy />,
            [StepCreateSubscription.PaymentDetails]: <PaymentDetails />,

            [subscribeToProduct
              ? StepCreateSubscription.AccountDetails - NEXT_STEP
              : StepCreateSubscription.AccountDetails]: (
              <AccountDetails showSummary={isShowSummary.current} showPrivacyPolicy jurisdiction={selectedCountry} />
            ),
          }[activeStep] as React.ReactElement
        }
      </SubscriptionContainer>
      <ConfirmPopup
        buttons={cancelSubscriptionModalButtons}
        icon='warning'
        message={t('modalCancelSubscription.message')}
        onClose={() => setIsOpenCancelSubscription(false)}
        open={isOpenCancelSubscription}
        title={t('modalCancelSubscription.title')}
      />
      <ConfirmPopup
        buttons={submissionSubscriptionModalButton}
        hideCloseButton
        icon='success'
        message={t('modalSubscriptionSubmission.message')}
        onClose={() => setIsOpenSubmissionSubscription(false)}
        open={isOpenSubmissionSubscription}
        title={t('modalSubscriptionSubmission.title')}
      />
    </>
  )
}
