import { forwardRef, ReactElement, useCallback } from 'react'
import { SnackbarContent, CustomContentProps, closeSnackbar } from 'notistack'
import { Card, Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { PlusIcon } from 'components/icons/Plus.icon'
import { SuccessIcon } from 'components/icons/Success.icon'
import { ErrorIcon } from 'components/icons/Error.icon'
import { InfoIcon } from 'components/icons/Info.icon'
import { WarningIcon } from 'components/icons/Warning.icon'

import colors from 'theme/colors'
import { Severity } from 'constants/severity'


interface ReportToastProps extends CustomContentProps {
  title?: string
  severity: Severity
  message: string
}

const icons: Record<Severity, ReactElement> = {
  success: <SuccessIcon sx={{ pr: '5px', color: colors.green[400], fontSize: '32px' }} />,
  error: <ErrorIcon sx={{ pr: '5px', color: colors.red, fontSize: '32px' }} />,
  info: <InfoIcon sx={{ pr: '5px', color: colors.grey[200], fontSize: '32px' }} />,
  warning: <WarningIcon sx={{ pr: '5px', color: colors.orange, fontSize: '32px' }} />,
}

const SnackbarComponent = forwardRef<HTMLDivElement, ReportToastProps>(({ message, title, severity, id }, ref) => {
  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    closeSnackbar(id)
  }, [id])

  return (
    <SnackbarContent ref={ref} role='alert'>
      <Card sx={{ width: '400px', p: '15px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {icons[severity]}
            {title ? <Typography sx={{ fontWeight: 600, fontSize: '16px', alignSelf: 'center' }}>{t(title)}</Typography> :
              <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>
                {t(message)}
              </Typography>}
          </Box>
          <PlusIcon sx={{ transform: 'rotate(45deg)', cursor: 'pointer' }} onClick={handleClose} />
        </Box>
        {title && <Typography sx={{ fontWeight: 400, fontSize: '14px', ml: '5px', mt: '10px' }}>
          {t(message)}
        </Typography>}
      </Card>
    </SnackbarContent>
  )
})

SnackbarComponent.displayName = 'Toast'

export default SnackbarComponent
