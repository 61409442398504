import { FC } from 'react'
import { Box } from '@mui/material'
import { t } from 'i18next'
import CustomForm from 'components/CustomForm'
import { IPermission } from '../../../constants/interfaces'

interface Props {
  disabled?: boolean
  permissions: IPermission[]
}

const PermissionsList: FC<Props> = ({ disabled = false, permissions }) => {
  const checkboxHeight = 38
  const boxHeight = Math.ceil(permissions.length / 2) * checkboxHeight
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        maxHeight: boxHeight + 'px',
        width: '100%',
      }}
    >
      {permissions.map((permission) => (
        <Box key={permission.id} sx={{ flexBasis: '50%' }}>
          <CustomForm.CheckBox
            key={permission.id}
            name='permissionIds'
            value={String(permission.id)}
            label={t(permission.name)}
            disabled={disabled}
          />
        </Box>
      ))}
    </Box>
  )
}

export default PermissionsList
