import { useEffect, useCallback } from 'react'

import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// slices
import {
  resetAll,
  resetPermissions,
  resetProducts,
  setRoleSelected,
  getProducts,
  getPermissions,
} from '../slices/manageAdmins'
import { resetJurisdictions, getJurisdictions, getJurisdictionsByProductGroupId } from '../slices/jurisdictions'
import { getEmployeesByRole } from '../slices/employees'
import { filteredRoleList } from '../slices/manageAdmins/selectors'
import { handleAddAdminsModal } from 'sharedSlices/ui'

import { addEmployee } from '../services'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { showMessage } from 'utils/errorMessageHandler'
import {
  IAddAdminFormData,
  SUPER_ADMIN,
  JURISDICTION_ADMIN,
  PRODUCT_ADMIN,
  DEMO_USER,
  IProductGroup,
} from '../constants'

const requiredRoles = [PRODUCT_ADMIN, DEMO_USER]
const initialValues: IAddAdminFormData = {
  roleId: null,
  jurisdictionId: '',
  employeeIds: [],
  productId: '',
  permissionIds: [],
}

const validationSchema = Yup.object().shape({
  roleId: Yup.number().required(),
  jurisdictionId: Yup.string().when('roleId', (roleId, field) =>
    [...requiredRoles, JURISDICTION_ADMIN].includes(roleId) ? field.required() : field
  ),
  productId: Yup.string().when('roleId', (roleId, field) =>
    requiredRoles.includes(roleId) ? field.required() : field
  ),
  employeeIds: Yup.array().of(Yup.string()).required().min(1),
  permissionIds: Yup.array()
    .of(Yup.string())
    .when('roleId', (roleId, field) => (roleId !== DEMO_USER ? field.required().min(1) : field)),
})

export const useAddAdminsForm = () => {
  const dispatch = useAppDispatch()
  const { roleName } = useParams()
  const { roleSelected } = useAppSelector((state) => state.manageAdmins)
  const roles = useAppSelector(filteredRoleList)

  const submitFormData = (formData: IAddAdminFormData) => {
    addEmployee(formData).then(() => {
      dispatch(resetAll())
      dispatch(resetJurisdictions())
      dispatch(handleAddAdminsModal(false))
      dispatch(setRoleSelected(null))
      dispatch(getEmployeesByRole(roleName!))
      showMessage.success('mySnackbar', 'manageAdmins.message.success')
    })
  }

  const formikProps = useFormik<IAddAdminFormData>({
    initialValues,
    validationSchema,
    onSubmit: submitFormData,
  })

  const { setFieldValue, resetForm } = formikProps

  const setValue = useCallback(
    (fieldName: string, param: string | string[] | number) => setFieldValue(fieldName, param),
    [setFieldValue]
  )

  const handleRole = useCallback(
    (roleId: number) => {
      resetForm()
      dispatch(resetJurisdictions())
      dispatch(resetPermissions())
      dispatch(resetProducts())
      dispatch(setRoleSelected(roleId))
      roleId !== DEMO_USER && dispatch(getPermissions({ roleId }))
    },
    [dispatch, resetForm]
  )

  const handleProduct = useCallback(
    (productGroup: IProductGroup) => {
      setValue('jurisdictionId', '')
      dispatch(getJurisdictionsByProductGroupId({ productGroup }))
    },
    [dispatch, setValue]
  )

  const onClose = useCallback(() => {
    dispatch(setRoleSelected(null))
    dispatch(handleAddAdminsModal(false))
    dispatch(resetJurisdictions())
    dispatch(resetAll())
  }, [dispatch])

  const setLinkedField = useCallback(
    (productGroup: IProductGroup) => {
      dispatch(getJurisdictionsByProductGroupId({ productGroup }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (roleSelected === PRODUCT_ADMIN || roleSelected === DEMO_USER) {
      dispatch(getProducts())
    }
    if (roleSelected === JURISDICTION_ADMIN) {
      dispatch(getJurisdictions())
    }
  }, [dispatch, roleSelected])

  useEffect(() => {
    if (roles.length === 1) {
      setValue('roleId', roles[0].id)
      if (roles[0].id === PRODUCT_ADMIN) {
        dispatch(setRoleSelected(PRODUCT_ADMIN))
        dispatch(getProducts())
        dispatch(getPermissions({ roleId: roles[0].id }))
      }
      if (roles[0].id === JURISDICTION_ADMIN) {
        dispatch(setRoleSelected(JURISDICTION_ADMIN))
        dispatch(getJurisdictions())
        dispatch(getPermissions({ roleId: roles[0].id }))
      }
      if (roles[0].id === SUPER_ADMIN) {
        dispatch(setRoleSelected(SUPER_ADMIN))
        dispatch(getPermissions({ roleId: roles[0].id }))
      }
      if (roles[0].id === DEMO_USER) {
        dispatch(setRoleSelected(DEMO_USER))
        dispatch(getProducts())
      }
    }
  }, [dispatch, roles, setValue])

  return {
    onClose,
    setValue,
    setLinkedField,
    handleRole,
    handleProduct,
    formikProps,
  }
}
