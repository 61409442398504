import { Box } from '@mui/material'
import { CustomTable, IColumnType } from 'components/CustomTable'
import { CustomArrowTooltip } from 'components/CustomTooltip'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { WarningIcon } from 'components/icons/Warning.icon'
import { DateFormat } from 'constants/profileSettings'
import { ISortingInfo } from 'constants/sorting'
import { IMyReport } from 'features/manageReports/constants'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/date'

interface Props {
  data: IMyReport[]
  sorting: ISortingInfo
  dateFormat: DateFormat | undefined
  onChangeSort: (sortingInfo: ISortingInfo) => void
  actions: ((data: IMyReport) => JSX.Element) | undefined
}

export const MyReportsTable: FC<Props> = ({ data, sorting, dateFormat, onChangeSort, actions }) => {
  const { t } = useTranslation()
  const columns: IColumnType<IMyReport>[] = useMemo(
    () => [
      {
        key: 'name',
        title: 'reports.myReports.columns.reportName',
        sorting: false,
        render: (_, item) => {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
                <Box
                  sx={{
                    marginRight: !item.isAccessGranted ? '12px' : '',
                    maxWidth: !item.isAccessGranted ? 'calc(100% - 36px)' : '100%'
                  }}
                >
                  <OverflowTooltip text={item.name ? item.name : '-'}/>
                </Box>
              {!item.isAccessGranted && (
                <CustomArrowTooltip
                  placement='top-end'
                  title={t('reports.myReports.tooltip.permission')}
                >
                  <Box
                    sx={{
                      height: '24px',
                    }}
                  >
                    <WarningIcon />
                  </Box>
                </CustomArrowTooltip>
              )}
            </Box>
          )
        },
      },
      {
        key: 'reportType',
        title: 'reports.myReports.columns.reportType',
        sorting: false,
      },
      {
        key: 'reportPeriod',
        title: 'reports.myReports.columns.reportPeriod',
        sorting: false,
        render: (_, item) => {
          if (item.startDate && item.endDate) {
            return `${formatDate(item.startDate, dateFormat)} - ${formatDate(
              item.endDate,
              dateFormat
            )}`
          } else {
            return '–'
          }
        },
      },
      {
        key: 'dateGenerated',
        title: 'reports.myReports.columns.dateGenerated',
        sorting: false,
        render: (_, item) => {
          return formatDate(item.generatedDate, dateFormat)
        },
      },
    ],
    [dateFormat]
  )

  return (
    <CustomTable<IMyReport>
      data={data}
      columns={columns}
      sorting={sorting}
      onSort={onChangeSort}
      overflowLayout={true}
      actions={actions}
    />
  )
}
