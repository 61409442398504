import { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { EmptyReports } from '../EmptyReports'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { MyReportsTable } from '../MyReportsTable'
import { resetMyReports, setMyReportsPagination } from 'features/manageReports/slice'
import { CustomPagination } from 'components/CustomPagination/reports'
import { getDateFormat } from 'sharedSlices/auth'
import { DEFAULT_MY_REPORTS_SORTING, FileType } from './constants'
import { DEFAULT_REPORT_PAGINATION, IMyReport } from 'features/manageReports/constants'
import { MyReportsActions } from '../MyReportsActions'
import { useTranslation } from 'react-i18next'
import { ConfirmPopup as ConfirmDeletePopup, ConfirmPopupButton } from 'components/ConfirmPopup'
import { deleteMyReport, downloadCSVFile, fetchMyReports } from 'features/manageReports/services'
import { ExportPopup } from '../ExportPopup'
import { ISortingInfo } from 'constants/sorting'
import { geoApi } from 'services/api/geoApi'

export const MyReports = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [sorting, setSorting] = useState<ISortingInfo>(DEFAULT_MY_REPORTS_SORTING)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [showExportPopup, setShowExportPopup] = useState(false)
  const [selectedMyReport, setSelectedMyReport] = useState<IMyReport | null>(null)
  const [fileType, setFileType] = useState<FileType>(FileType.CSV)
  const { myReports } = useAppSelector((state) => state.manageReports)
  const dateFormat = useAppSelector(getDateFormat)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isFirstRender = useRef(true)
  const { t } = useTranslation()

  const onChangeSort = useCallback((sortingInfo: ISortingInfo) => {
    setSorting(sortingInfo)
  }, [])

  const onChangeCurrentPage = useCallback(
    ({ page, size }: { page?: number; size?: number }) => {
      if (page) {
        dispatch(
          setMyReportsPagination({
            page,
            size: size || myReports.pagination.size,
          })
        )
      }
    },
    [dispatch, myReports.pagination.size]
  )

  const onDelete = useCallback((myReport: IMyReport) => {
    setShowDeletePopup(true)
    setSelectedMyReport(myReport)
  }, [])

  const onExport = useCallback((myReport: IMyReport) => {
    setSelectedMyReport(myReport)
    setShowExportPopup(true)
  }, [])

  const onEdit = useCallback((myReport: IMyReport) => {
    navigate(`/manage/reports/edit/${myReport.id}?jurId=${myReport.jurisdictionIds[0]}`)
  }, [])

  const actions = useCallback(
    (myReport: IMyReport) => {
      return (
        <MyReportsActions
          isAccessGranted={myReport.isAccessGranted}
          onDelete={() => onDelete(myReport)}
          onExport={() => onExport(myReport)}
          onEdit={() => onEdit(myReport)}
        />
      )
    },
    [onDelete, onExport, onEdit]
  )

  const onCloseMyReportDeletePopup = useCallback(() => {
    setShowDeletePopup(false)
    setSelectedMyReport(null)
  }, [])

  const deleteReport = useCallback(() => {
    if (selectedMyReport) {
      const jurCode = geoApi.jurMapping[selectedMyReport.jurisdictionIds[0]].code
      dispatch(deleteMyReport({ id: selectedMyReport.id, jurCode })).then(() => {
        setShowDeletePopup(false)
        setSelectedMyReport(null)

        const size = myReports.pagination.size
        let page = myReports.pagination.page

        if (myReports.data?.length === 1) {
          if (page > 1) {
            page = page - 1
          }

          setSearchParams({
            page: page.toString(),
            size: size.toString(),
          })

          dispatch(
            setMyReportsPagination({
              page,
              size,
            })
          )
        }

        dispatch(fetchMyReports())
      })
    }
  }, [dispatch, myReports.pagination.page, myReports.pagination.size, myReports.data?.length, selectedMyReport])

  const deleteMyReportButtons = useMemo((): ConfirmPopupButton[] => {
    return [
      {
        action: onCloseMyReportDeletePopup,
        name: t('button.no'),
        style: {
          minWidth: '54px',
          borderRadius: '2px',
          marginRight: '16px',
        },
        type: 'outlined',
      },
      {
        action: deleteReport,
        name: t('button.yes'),
        style: {
          minWidth: '56px',
          borderRadius: '2px',
        },
        type: 'contained',
      },
    ]
  }, [t, onCloseMyReportDeletePopup, deleteReport])

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFileType((event.target as HTMLInputElement).value as FileType)
  }, [])

  const handleCloseExportPopup = useCallback(() => {
    setShowExportPopup(false)
    setSelectedMyReport(null)
  }, [])

  const handleExport = useCallback(() => {
    if (selectedMyReport) {
      if (fileType === FileType.CSV) {
        downloadCSVFile(selectedMyReport).then(() => {
          handleCloseExportPopup()
        })
      }
    }
  }, [selectedMyReport, fileType, handleCloseExportPopup])

  useEffect(() => {
    if (isFirstRender.current && searchParams.has('size') && searchParams.has('page')) {
      const page = parseInt(searchParams.get('page')!)
      const size = parseInt(searchParams.get('size')!)
      dispatch(setMyReportsPagination({ page, size }))
      dispatch(fetchMyReports())
        .then(() => (isFirstRender.current = false))
        .catch(() => (isFirstRender.current = false))
    } else {
      if (myReports.total > 10) {
        setSearchParams({
          page: myReports.pagination.page.toString(),
          size: myReports.pagination.size.toString(),
        })
      }
      dispatch(fetchMyReports())
        .then(() => {
          if (isFirstRender.current) {
            const { page, size } = DEFAULT_REPORT_PAGINATION
            setSearchParams({ page: page.toString(), size: size.toString() })
          }
          isFirstRender.current = false
        })
        .catch(() => (isFirstRender.current = false))
    }
  }, [myReports.pagination.size, myReports.pagination.page])

  useEffect(() => {
    return () => {
      if (!isFirstRender.current) dispatch(resetMyReports())
    }
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        position: 'relative',
        height: 'calc(100% - 55px - 58.8px - 48px)',
      }}
    >
      {myReports.data?.length ? (
        <MyReportsTable
          dateFormat={dateFormat}
          data={myReports.data}
          sorting={sorting}
          onChangeSort={onChangeSort}
          actions={actions}
        />
      ) : (
        <EmptyReports />
      )}

      {myReports.total > myReports.pagination.size && (
        <CustomPagination
          changeCurrentPage={onChangeCurrentPage}
          count={myReports.total}
          currentPage={myReports.pagination.page}
          rowsPerPage={myReports.pagination.size}
        />
      )}

      <ConfirmDeletePopup
        icon='warning'
        title={t('reports.myReports.deletePopup.title')}
        message={t('reports.myReports.deletePopup.message')}
        buttons={deleteMyReportButtons}
        open={showDeletePopup}
        onClose={onCloseMyReportDeletePopup}
      />
      <ExportPopup
        open={showExportPopup}
        reportName={selectedMyReport?.name || '-'}
        value={fileType}
        onChange={handleChange}
        onClose={handleCloseExportPopup}
        onExport={handleExport}
      />
    </Box>
  )
}
