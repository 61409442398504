import { AxiosError } from 'axios'
import { User } from 'features/manageUsers/constants'

export const NO_ACTIVE_SUBSCRIPTION = 0
export const ACTIVE_SUBSCRIPTION = 1
export const ACTIVE_SUBSCRIPTION_IN_JUR = 2

export type UserSubscriptionStatus =
  | typeof NO_ACTIVE_SUBSCRIPTION
  | typeof ACTIVE_SUBSCRIPTION
  | typeof ACTIVE_SUBSCRIPTION_IN_JUR

export type RegisteredUser = {
  userSubscriptionStatus: UserSubscriptionStatus
  firstName: string
  lastName: string
}

export interface ManageUsersSlice {
  users: User[]
  userSelectedId?: string
  subscriptionId?: string
  registeredUser?: RegisteredUser
  isLoading: boolean
  error?: AxiosError['response']
}
