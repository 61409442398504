import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { getUsers } from './actions'

import { ManageUsersSlice } from './constants'
import { byRegularUser } from 'features/manageUsers/utils'

export const MANAGE_USERS_INIT_STATE: ManageUsersSlice = {
  users: [],
  isLoading: false,
  error: undefined,
}

const _handlePending = (state: ManageUsersSlice) => {
  state.isLoading = true
}

const manageUsersSlice = createSlice({
  name: 'manageUsers',
  initialState: MANAGE_USERS_INIT_STATE,
  reducers: {
    changeSelectedValue: (state: ManageUsersSlice, action: PayloadAction<string>) => {
      state.users.map((user) => {
        const userId = action.payload
        if (user.id === userId) user.selected = !user.selected
      })
    },
    setSelectAll: (state: ManageUsersSlice, action: PayloadAction<boolean>) => {
      const users = state.users.filter(byRegularUser)
      const anyUserSelected = users.some((user) => user.selected)
      users.map((user) => (user.selected = anyUserSelected ? false : action.payload))
    },
    setSubscriptionId: (state: ManageUsersSlice, action) => {
      state.subscriptionId = action.payload
    },
    clearSubscription: (state: ManageUsersSlice) => {
      state.users = []
      state.subscriptionId = undefined
    },
  },
  extraReducers: (builder) => {
    // getUsers
    builder.addCase(getUsers.pending, _handlePending)
    builder.addCase(getUsers.fulfilled, (state: ManageUsersSlice, action) => {
      state.users = action.payload
      state.isLoading = false
      state.error = undefined
    })
    builder.addCase(getUsers.rejected, (state: ManageUsersSlice, action) => {
      state.isLoading = false
      state.users = []
      state.error = action.payload?.response
    })
  },
})

export const { setSubscriptionId, setSelectAll, changeSelectedValue, clearSubscription } =
  manageUsersSlice.actions

export { getUsers }

export default manageUsersSlice.reducer
