import { AccordionSummary, Box, Typography } from '@mui/material'
import { CustomAccordion } from 'components/CustomAccordion'
import { DropdownIcon } from 'components/icons/Dropdown.icon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BillingInfoAccordionSummary from './components/BillingInfoAccordionSummary'
import { useAppSelector } from 'app/hooks'
import { usePaymentDetailsData } from 'features/subscriptionRequest/hooks/usePaymentDetailsData/usePaymentDetailsData'
import ValidationErrorMessage from '../shared-components/ValidationErrorMessage'
import { accordionSummaryStyles } from 'constants/styles'
import { useDispatch } from 'react-redux'
import { setPaymentDetailsActiveStep } from 'features/subscriptionRequest/slice'
import { usePaymentDetailsSteps } from 'features/subscriptionRequest/hooks/usePaymentDetailsSteps/usePaymentDetailsSteps'
import { FormikErrors, FormikValues } from 'formik'

const PaymentDetails = () => {
  const { t } = useTranslation()
  const { billingInfoError, paymentDetailsActiveStep, paymentInfoErrors } = useAppSelector(
    (state) => state.subscriptionRequest
  )

  const dispatch = useDispatch()

  const [expanded, setExpanded] = useState<number | null>(paymentDetailsActiveStep)

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null)
    dispatch(setPaymentDetailsActiveStep(isExpanded ? panel : null))
  }

  const { accordionData } = usePaymentDetailsData({ setExpanded })
  const { stepsValidationMap } = usePaymentDetailsSteps()
  const isSomeFieldsInValid = Object.values(stepsValidationMap).includes(false)

  const paymentInfoErrorIsShown = !!Object.values(paymentInfoErrors).length
  const billingInfoIsShown = !!billingInfoError?.length

  const getErrorMsg = () => {
    if (billingInfoIsShown) {
      return billingInfoError?.includes('email') ? 'userEmail.error' : 'errors.required'
    }

    if (paymentInfoErrorIsShown) {
      const errorText = Object.values(paymentInfoErrors).reduce<FormikErrors<FormikValues>[]>((accumulator, item) => {
        const value = item?.email || item?.contact

        if (value) {
          accumulator.push(value as FormikErrors<FormikValues>)
        }

        return accumulator
      }, [])

      return errorText[errorText.length - 1]
    }
  }

  return (
    <>
      <ValidationErrorMessage
        isMessageShown={isSomeFieldsInValid && (billingInfoIsShown || paymentInfoErrorIsShown)}
        text={t(getErrorMsg() as string)!}
      />

      <Box
        sx={{
          padding: '24px',
        }}
      >
        {accordionData.map(({ summary, details, type, panel, disabled }) => (
          <CustomAccordion
            key={type}
            sx={{
              '&.MuiPaper-root.MuiAccordion-root': {
                boxShadow: 'none',
              },
            }}
            disableGutters
            elevation={0}
            expanded={expanded === panel}
            handleChange={handleChange(panel)}
          >
            <AccordionSummary
              disabled={disabled}
              sx={{
                ...accordionSummaryStyles,
                '&.Mui-expanded': {
                  borderBottom: '1px solid',
                  borderColor: 'grey.100',
                },
              }}
              expandIcon={<DropdownIcon />}
              aria-controls={`${panel}-content`}
              id={`${panel}-header`}
            >
              <Typography
                sx={{
                  lineHeight: '24px',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                {summary}
              </Typography>
            </AccordionSummary>
            {details.map((block, index) => (
              <BillingInfoAccordionSummary block={block} key={index} />
            ))}
          </CustomAccordion>
        ))}
      </Box>
    </>
  )
}

export default PaymentDetails
