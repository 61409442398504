import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, MenuItem } from '@mui/material'
import { SubscriptionType, subscriptionTypeOptions } from 'features/subscriptionRequest/constants/subscription'
import { setCommonTermId, setType } from 'features/subscriptionRequest/slice'
import { CustomSelect } from 'components/CustomSelect'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import colors from 'theme/colors'

interface Props {
  onComplete: () => void
}

export const PackageFilters: FC<Props> = ({ onComplete }) => {
  const { type, commonTerms, commonTermId } = useAppSelector((state) => state.subscriptionRequest)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '4px',
        mb: '24px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
        }}
      >
        {t('subscriptionRequest.subscriptionType.label')}:
        <CustomSelect
          sx={{
            width: '92px',
            '.MuiSelect-select': {
              color: colors.mineShaft,
            },
          }}
          outline={false}
          value={type}
          onChange={(e) => dispatch(setType(e.target.value as SubscriptionType))}
          placeholder={t('subscriptionRequest.subscriptionType.placeholder')!}
        >
          {subscriptionTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value} disabled={option.value === SubscriptionType.Trial}>
              {t(option.label)}
            </MenuItem>
          ))}
        </CustomSelect>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
          color: type ? colors.mineShaft : colors.mainGrey,
        }}
      >
        {t('subscriptionRequest.term.label')}:
        <CustomSelect
          sx={{
            '.MuiSelect-select': {
              color: colors.mineShaft,
            },
            '&&& .Mui-disabled': {
              WebkitTextFillColor: colors.mainGrey,
              '.MuiSelect-icon': {
                color: 'grey.800',
              },
            },
          }}
          value={commonTermId}
          outline={false}
          disabled={!type}
          selectedTemplated={() => {
            const { duration } = commonTerms.find((term) => term.id === commonTermId)!
            return (
              <Box>
                {duration} {t(`manageProduct.subscriptions.year${duration > 1 ? 's' : ''}`).toLowerCase()}
              </Box>
            )
          }}
          onChange={(e) => {
            dispatch(setCommonTermId(e.target.value))
            onComplete()
          }}
          placeholder={t('subscriptionRequest.term.placeholder')!}
        >
          {commonTerms.map((option) => {
            const { duration } = option
            return (
              <MenuItem key={option.id} value={option.id}>
                {duration} {t(`manageProduct.subscriptions.year${duration > 1 ? 's' : ''}`).toLowerCase()}
              </MenuItem>
            )
          })}
        </CustomSelect>
      </Box>
    </Box>
  )
}
