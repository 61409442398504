import { geoApi } from 'services/api/geoApi'
import { IProductGroup } from 'features/productConfiguration/constants'

export const getProductGroup = (productGroupShortName: string): Promise<IProductGroup[]> => {
  return geoApi.getAllGeo<IProductGroup>(`product-group/${productGroupShortName}`)
}

export const importSubscriptions = async (
  productGroupShortName: string,
  jurCodes: string[],
  file: File
): Promise<void> => {
  const formData = new FormData()
  formData.append('file', file)
  await geoApi.postSpecificGeos(`subscriptions/productGroup/${productGroupShortName}/import`, jurCodes, formData)
}
