import { Dialog, DialogActions, DialogContent, Typography, Box } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import HeaderForm from 'features/manageAdmins/components/headerForm'
import { WarningIcon } from 'components/icons/Warning.icon'
import FooterForm from 'features/manageAdmins/components/footerForm'
import { useTranslation } from 'react-i18next'
import { useRemoveUser } from 'features/manageUsers/hooks/useRemoveUser'

export const RemoveUserDialog = () => {
  const { isRemoveUserModalOpen } = useAppSelector((state) => state.ui.manageUserSubscriptionModals)
  const { onClose, handleDeleteUser } = useRemoveUser()

  const { t } = useTranslation()

  return (
    <Dialog open={isRemoveUserModalOpen} hideBackdrop onClose={onClose}>
      <Box sx={{ width: '480px' }}>
        <HeaderForm
          icon={<WarningIcon />}
          title='manageProduct.users.deleteTitle'
          onClose={onClose}
        />
        <DialogContent sx={{ my: 0, py: 0 }}>
          <Typography variant='body2' sx={{ my: '8.5px' }}>
            {t('manageProduct.users.deleteUsersDescription')}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ m: 0, p: 0 }}>
          <FooterForm>
            <FooterForm.Button onClose={onClose} variant='outlined' text='manageProduct.users.no' />
            <FooterForm.Button onClose={handleDeleteUser} text='manageProduct.users.yes' />
          </FooterForm>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
