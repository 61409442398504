import {
  ICurrency,
  IPackage,
  IPackageLicenses,
  IProductDiscount,
  ITerm,
} from 'features/subscriptionRequest/constants/subscription'

export const getTotalPackagePrice = (
  originalPrice: number,
  quantity: number,
  discounts: IProductDiscount[],
  term: ITerm
) => {
  let totalPrice = 0
  let previousThreshold = 0
  const discountRules = [...discounts].sort((a, b) => a.fromLicenseAmount - b.fromLicenseAmount)

  for (const rule of discountRules) {
    if (quantity >= rule.fromLicenseAmount) {
      const start = Math.max(rule.fromLicenseAmount, previousThreshold + 1)
      const end = Math.min(quantity, rule.toLicenseAmount)
      const numItemsInRange = end - start + 1

      if (numItemsInRange > 0) {
        totalPrice += numItemsInRange * originalPrice * (1 - rule.percentage)
        previousThreshold = rule.toLicenseAmount
      }
    }
  }

  if (quantity <= discountRules[0].fromLicenseAmount) {
    totalPrice = quantity * originalPrice
  }

  return totalPrice * term.duration
}

export const getTotalPrice = (
  packages: IPackage[],
  licenses: IPackageLicenses,
  commonTerm: ITerm,
  currency: ICurrency,
  discounts: IProductDiscount[]
) => {
  return packages.reduce((total, currPackage) => {
    const licenseAmount = licenses[currPackage.packageGroup.name]
    const packagePrice = currPackage.prices.find((price) => price.currencyId === currency.id)!.amount

    return total + getTotalPackagePrice(packagePrice, licenseAmount, discounts, commonTerm)
  }, 0)
}
