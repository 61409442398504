import { useTranslation } from 'react-i18next'
import { UploadResult } from '../constants/upload.interface'

export const useClipboardCopy = (uploadResult: UploadResult[]) => {
  const { t } = useTranslation()

  const showCopyButton = uploadResult.map((e) => e.type).some((e) => e === 'error' || e === 'info')
  const totalUserErrors = uploadResult.reduce(
    (acc, cur) => (cur.type === 'error' ? acc + cur.userCount : acc),
    0
  )

  const copyToClipboard = () => {
    const textToCopy = uploadResult.filter((e) => e.type !== 'success')
    let infoText = ''
    let errorText = ''

    if (totalUserErrors > 0) {
      errorText += totalUserErrors + ' '
      errorText += t('manageProduct.uploadMessage.usersNoAdded') + '\n'
    }
    textToCopy.forEach((text) => {
      if (text.type === 'error') {
        errorText += t(text.key)
        errorText += text.rows && text.rows.join(', ').trim() + '\n'
      } else {
        infoText += t(text.key, { count: text.userCount }).replace(/<[^>]*>/g, '') + ' '
        infoText += text.rows.join(', ').trim()
      }
    })
    navigator.clipboard.writeText(infoText + '\n' + errorText)
  }

  return { showCopyButton, copyToClipboard }
}
