import { createSlice } from '@reduxjs/toolkit'

import { getJurisdictions, getJurisdictionsByProductGroupId } from './actions'

import { JurisdictionsSlice } from './constants'

export const JURISDICTIONS_INIT_STATE: JurisdictionsSlice = {
  jurisdictions: [],
  isLoading: false,
  error: undefined,
}

const _handlePending = (state: JurisdictionsSlice) => {
  state.isLoading = true
}

const jurisdictionsSlice = createSlice({
  name: 'jurisdictions',
  initialState: JURISDICTIONS_INIT_STATE,
  reducers: {
    resetJurisdictions(state: JurisdictionsSlice) {
      state.jurisdictions = []
    },
  },
  extraReducers: (builder) => {
    // getJurisdictions
    builder.addCase(getJurisdictions.pending, _handlePending)
    builder.addCase(getJurisdictions.fulfilled, (state: JurisdictionsSlice, action) => {
      state.jurisdictions = action.payload.flat()
      state.isLoading = false
      state.error = undefined
    })
    builder.addCase(getJurisdictions.rejected, (state: JurisdictionsSlice, action) => {
      state.isLoading = false
      state.jurisdictions = []
      state.error = action.payload?.response
    })
    // getJurisdictionsByProductGroupId
    builder.addCase(getJurisdictionsByProductGroupId.pending, _handlePending)
    builder.addCase(getJurisdictionsByProductGroupId.fulfilled, (state: JurisdictionsSlice, action) => {
      state.jurisdictions = action.payload.flat()
      state.isLoading = false
      state.error = undefined
    })
    builder.addCase(getJurisdictionsByProductGroupId.rejected, (state: JurisdictionsSlice, action) => {
      state.isLoading = false
      state.jurisdictions = []
      state.error = action.payload?.response
    })
  },
})

export const { resetJurisdictions } = jurisdictionsSlice.actions

export { getJurisdictions, getJurisdictionsByProductGroupId }

export default jurisdictionsSlice.reducer
