import { Box } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { footerHeight } from 'utils/scrollableFooter'

export const MockFooterBlock = () => {
  const { isFooterVisible } = useAppSelector((state) => state.ui)

  return (
    <Box
      sx={{
        display: !isFooterVisible ? 'block' : 'none',
        height: `${footerHeight}px`,
        width: '100%',
      }}
      data-testid='mock-footer-block'
    ></Box>
  )
}
