import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { paymentTypeOptions } from 'features/subscriptionRequest/constants/payment'
import { setPaymentTypes } from 'features/subscriptionRequest/slice'
import { CustomRadioGroup } from 'components/CustomRadioGroup'
import { Box } from '@mui/material'
import { CustomFormLabel } from 'components/CustomFormLabel'

export const PaymentTypes: React.FC<{ productId: string }> = ({ productId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { paymentTypes, products } = useAppSelector((state) => state.subscriptionRequest)
  const product = products.find((_product) => _product.id === productId)!
  const paymentType = String(paymentTypes[productId])

  return product.paymentTypes?.length ? (
    product.paymentTypes.length > 1 ? (
      <CustomRadioGroup
        row
        value={paymentType}
        infoTooltip={t('paymentDetails.paymentTypes.tooltip')}
        label={t('paymentDetails.paymentTypes.label')}
        sx={{ mT: '-4px', mb: '16px', label: { marginRight: '30px' } }}
        options={paymentTypeOptions.filter((option) => product.paymentTypes!.includes(Number(option.value)))}
        onChange={(e) => dispatch(setPaymentTypes({ [productId]: Number(e.target.value) }))}
      />
    ) : (
      <>
        <CustomFormLabel
          infoTooltip={t('paymentDetails.creditCard.tooltip')}
          value={t('paymentDetails.paymentTypes.label')}
        />
        <Box sx={{ fontSize: '14px', mb: '16px' }}>
          {t(`${paymentTypeOptions.find((option) => option.value === paymentType)?.label}`)}
        </Box>
      </>
    )
  ) : (
    <></>
  )
}
