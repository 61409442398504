import colors from 'theme/colors'

export const accordionSummaryStyles = {
  height: '48px',
  flexDirection: 'row-reverse',
  padding: '0 16px 0 12px',
  m: '0',
  '&.Mui-disabled': {
    opacity: 1,
    backgroundColor: `${colors.menuItemHover}`,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'common.white',
  },
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '&&.Mui-disabled .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    color: 'grey.800',
  },
  '.MuiAccordionSummary-expandIconWrapper *': {
    color: 'common.black',
  },
  '&.Mui-disabled .MuiAccordionSummary-expandIconWrapper *': {
    color: `${colors.silverChalice}!important`,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
}
