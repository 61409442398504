export const getValidExternalURL = (urlString: string) => {
  let url: URL
  try {
    url = new URL(urlString)
    if (!url.hostname) {
      url = new URL('https://' + urlString)
    }
  } catch (e) {
    url = new URL('https://' + urlString)
  }
  return url
}