import { Box, Divider } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import { ITerm, LicenseType } from 'features/subscriptionRequest/constants/subscription'
import { Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'
import { sortedPackages } from 'utils/sortPackages'

export const Summary = () => {
  const { t } = useTranslation()
  const { subscription, terms, valuations, products } = useAppSelector((state) => state.subscriptionRequest)

  const showDuration = useCallback((licenseType: LicenseType) => {
    return [LicenseType.LicenseBased, LicenseType.SingleLicense].includes(licenseType)
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',

        '&::after': {
          content: '""',
          position: 'fixed',
          display: 'block',
          width: '100%',
          height: '100%',
          backgroundColor: colors.menuItemHover,
          borderLeft: `1px solid ${colors.border}`,
          zIndex: '-1',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '314px',
          backgroundColor: colors.menuItemHover,
          padding: '24px',
          fontFamily: 'Open Sans',
          borderLeft: `1px solid ${colors.border}`,
        }}
      >
        <Box
          sx={{
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0em',
          }}
        >
          {t('accountDetails.summary.title')}
        </Box>
        <Divider sx={{ margin: '12px 0', borderColor: 'grey.100' }} />
        <Box
          sx={{
            display: 'flex',
          }}
        >
          {subscription.products.map((product) => {
            return (
              <Box
                sx={{
                  width: '100%',
                }}
                key={product.id}
              >
                {sortedPackages(product.packages).map((_package, index) => {
                  let term: ITerm | undefined = terms[_package.id]
                  const valuation = valuations[_package.id]

                  if (!term && showDuration(_package.licenseType)) {
                    term = products.find((_product) => _product.id === product.id)?.terms[0]
                  }

                  return (
                    <Fragment key={_package.id}>
                      <Box
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          lineHeight: '24px',
                        }}
                      >
                        {_package.name}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          margin: '12px 0',
                        }}
                      >
                        <Box fontSize='14px'>
                          {t(`accountDetails.summary.${showDuration(_package.licenseType) ? 'term' : 'valuations'}`)}
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            fontSize: '14px',
                            fontWeight: 600,
                            lineHeight: '24px',
                          }}
                        >
                          {term && showDuration(_package.licenseType) && (
                            <>
                              {term.duration}
                              <Box sx={{ marginLeft: '5px', fontWeight: 600 }}>
                                {t(`manageProduct.subscriptions.${term.duration === 1 ? 'year' : 'years'}`)}
                              </Box>
                            </>
                          )}
                          {valuation && _package.licenseType === LicenseType.TokenBased && <>{valuation}</>}
                        </Box>
                      </Box>
                      {index !== product.packages.length - 1 && <Divider sx={{ margin: '12px 0' }} />}
                    </Fragment>
                  )
                })}
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}
