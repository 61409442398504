import { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import { Box } from '@mui/material'
import {
  PackageField,
  Package,
  initialPackageState,
  formValidationErrors,
  PackageFormErrors,
  PackageType,
} from './constants'
import { ProductSectionHeader } from 'features/manageProduct/components/ProductSectionHeader'
import { ProductPackagesForm } from './components/ProductPackageForm'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setSectionsState, setPackages as setStorePackages, setIsEdit } from '../../slice/index'
import { SectionName } from 'features/productConfiguration/constants'
import { useContainerScroll } from 'utils/scrollableFooter'
import { ConfirmPopup as DeletePackagePopup, ConfirmPopupButton } from 'components/ConfirmPopup'
import { MockFooterBlock } from 'components/Footer/mockFooterBlock'
import { useTranslation } from 'react-i18next'
import { RemoveIcon } from 'components/icons/Remove.icon'
import colors from 'theme/colors'

export const ProductPackages = () => {
  const [packages, setPackages] = useState<Package[]>([initialPackageState])
  const [allPackageFormInvalid, setAllPackageFormInvalid] = useState<boolean[]>([false])
  const [confirmDeletePackagePopup, setConfirmDeletePackagePopup] = useState(false)
  const scrollContainerRef = useRef<HTMLElement>()
  const { t } = useTranslation()
  const { productData, sectionsState, selectedJurisdiction, isEdit } = useAppSelector(
    (state) => state.productConfiguration
  )
  const dispatch = useAppDispatch()
  const deletePackageIndex = useRef<number>()
  const packagesFormErrors = useRef<PackageFormErrors[]>([formValidationErrors])
  const containerRef = useRef<HTMLDivElement | null>(null)

  const confirmDeletePackageButtonsPopup = useMemo((): ConfirmPopupButton[] => {
    return [
      {
        action: () => setConfirmDeletePackagePopup(false),
        name: t('button.no'),
        style: {
          minWidth: '54px',
          borderRadius: '2px',
          marginRight: '16px',
        },
        type: 'outlined',
      },
      {
        action: () => {
          setPackages((data) => {
            return data.filter((_, packageIndex) => packageIndex !== deletePackageIndex.current)
          })
          packagesFormErrors.current = packagesFormErrors.current.filter(
            (_, index) => index !== deletePackageIndex.current
          )
          setAllPackageFormInvalid((errors) =>
            errors.filter((_, index) => index !== deletePackageIndex.current)
          )
          setConfirmDeletePackagePopup(false)
        },
        name: t('button.yes'),
        style: {
          minWidth: '56px',
          borderRadius: '2px',
          backgroundColor: colors.red,

          '&:hover': {
            backgroundColor: colors.red,
            filter: 'brightness(0.85)',
          },
        },
        type: 'contained',
        icon: <RemoveIcon sx={{ marginRight: '5px' }} />,
      },
    ]
  }, [t])

  useEffect(() => {
    const hasPackages = productData.packages[selectedJurisdiction]

    setPackages(
      hasPackages ? [...productData.packages[selectedJurisdiction]] : [initialPackageState]
    )

    if (hasPackages) {
      packagesFormErrors.current = productData.packages[selectedJurisdiction].map(() => {
        return {
          description: { isValid: true },
          descriptionFrench: { isValid: true },
          name: { isValid: true },
        }
      })
      setAllPackageFormInvalid(
        new Array(productData.packages[selectedJurisdiction].length).fill(false)
      )
    }
  }, [isEdit, selectedJurisdiction])

  const handleChangePackageField = useCallback(
    (index: number, value: string | string[], field: PackageField) => {
      if (field === PackageField.DurationChip) {
        setPackages((data) => {
          data[index] = {
            ...data[index],
            durations: data[index].durations!.filter((d) => d !== value),
          }
          return [...data]
        })
      } else {
        setPackages((data) => {
          data[index] = {
            ...data[index],
            [field]: value,
          }

          if (value === PackageType.Valuation) {
            delete data[index]['durations']
          } else if (value === PackageType.Duration && !data[index]['durations']) {
            data[index]['durations'] = []
          }

          if (allPackageFormInvalid[index] && field !== PackageField.PackageType) {
            const fieldKey = field as keyof PackageFormErrors
            if (!Array.isArray(value)) {
              packagesFormErrors.current[index] = {
                ...packagesFormErrors.current[index],
                [field]: {
                  isValid: packagesFormErrors.current[index][fieldKey].isValid
                    ? packagesFormErrors.current[index][fieldKey].isValid
                    : !packagesFormErrors.current[index][fieldKey].isValid,
                },
              }
            }

            const packageFormValid = Object.values(packagesFormErrors.current[index]).every(
              (_value) => _value.isValid
            )

            if (packageFormValid) {
              setAllPackageFormInvalid((errors) => {
                errors[index] = !packageFormValid
                return [...errors]
              })
            }
          }

          return [...data]
        })
      }
    },
    [allPackageFormInvalid]
  )

  const handlerAddPackage = useCallback(() => {
    setPackages((data) => {
      return [...data, initialPackageState]
    })
    packagesFormErrors.current = [...packagesFormErrors.current, formValidationErrors]
    setAllPackageFormInvalid((data) => [...data, false])
  }, [])

  const handlerDeletePackage = useCallback((index: number) => {
    setConfirmDeletePackagePopup(true)
    deletePackageIndex.current = index
  }, [])

  const isDisabledSaveButton = useMemo(() => {
    return (
      allPackageFormInvalid.every((value) => !value) &&
      packages.every((obj) =>
        Object.values(obj).every((value) => (Array.isArray(value) ? !!value.length : !!value))
      )
    )
  }, [packages, allPackageFormInvalid])

  const isEditMode = useMemo(() => {
    return isEdit || !productData.packages[selectedJurisdiction]
  }, [isEdit, productData.packages, selectedJurisdiction])

  const handlerSavePackage = useCallback(() => {
    const allPackagesFormInvalid = packages.map((pkg, index) => {
      for (const key in pkg) {
        if (Object.prototype.hasOwnProperty.call(packagesFormErrors.current[index], key)) {
          const value = pkg[key as keyof Package]
          packagesFormErrors.current[index] = {
            ...packagesFormErrors.current[index],
            [key]: {
              isValid: Array.isArray(value) ? !!value.length : !!value!.trim(),
            },
          }
        }
      }

      return !Object.values(pkg).every((value) =>
        Array.isArray(value) ? !!value.length : !!value.trim()
      )
    })

    if (allPackagesFormInvalid.some((value) => value)) {
      setAllPackageFormInvalid(allPackagesFormInvalid)

      Promise.resolve().then(() => {
        const invalidField = document.querySelector('.invalid-field') as HTMLElement
        if (scrollContainerRef.current && invalidField) {
          const scrollPosition = invalidField.offsetTop - scrollContainerRef.current.offsetTop - 15
          scrollContainerRef.current.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
          })
        }
      })
      return
    }

    dispatch(
      setStorePackages({
        jurisdiction: selectedJurisdiction,
        packages,
      })
    )
    dispatch(setIsEdit(false))
  }, [dispatch, packages, selectedJurisdiction])

  const handlerEdit = useCallback(() => {
    dispatch(setIsEdit(true))
    dispatch(
      setSectionsState(
        sectionsState.map((section) => {
          return [
            SectionName.AcceptableUsePolicy,
            SectionName.Privacy,
            SectionName.Integrations,
          ].includes(section.name)
            ? { ...section, available: false }
            : section
        })
      )
    )
  }, [dispatch, sectionsState])

  useContainerScroll(containerRef, dispatch)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
    >
      <ProductSectionHeader
        isEditMode={isEditMode}
        isSaveDisabled={!isDisabledSaveButton}
        onAddPackage={handlerAddPackage}
        onSave={handlerSavePackage}
        onEdit={handlerEdit}
      />
      <Box
        ref={containerRef}
        sx={{
          padding: '16px',
          overflowY: 'auto',
          width: '100%',

          '& > div:not(:last-child)': {
            marginBottom: '16px',
          },
        }}
      >
        {packages.map((data, index) => (
          <Box
            sx={{
              border: `1px solid ${colors.border}`,
              borderRadius: '4px',
            }}
            key={index}
          >
            <ProductPackagesForm
              isShowDeleteButton={packages.length > 1}
              isEdit={!isEditMode}
              data={data}
              index={index}
              isPackageFormInValid={allPackageFormInvalid[index]}
              errors={packagesFormErrors.current[index]}
              onChangePackageField={handleChangePackageField}
              onDeletePackage={handlerDeletePackage}
            />
          </Box>
        ))}
        <MockFooterBlock />
      </Box>
      <DeletePackagePopup
        icon='error'
        title={t('productConfiguration.package.popupDelete.title')!}
        message={t('productConfiguration.package.popupDelete.message')!}
        open={confirmDeletePackagePopup}
        onClose={() => {
          setConfirmDeletePackagePopup(false)
          deletePackageIndex.current = undefined
        }}
        buttons={confirmDeletePackageButtonsPopup}
      />
    </Box>
  )
}
