import { Box } from '@mui/material'
import { IProductGroup } from 'features/productConfiguration/constants'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'

export interface IProductConfigurationCardProps {
  product: IProductGroup
  onCopyRegisterLink: (product: IProductGroup) => void
}

export const ProductConfigurationCard = ({ product, onCopyRegisterLink }: IProductConfigurationCardProps) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        minWidth: '200px',
        height: '188px',
        padding: '24px',
        borderRadius: '8px',
        border: `1px solid ${colors.border}`,
        backgroundColor: colors.white,
      }}
    >
      <Box
        sx={{
          fontFamily: 'Open Sans',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '24px',
          minHeight: '48px',
        }}
      >
        {product.name}
      </Box>
      <Box
        sx={{
          marginTop: '16px',
          color: 'primary.main',
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontWeight: 400,
          height: ' 76px',
        }}
      >
        <Box
          sx={{
            lineHeight: '24px',
            textDecoration: 'underline',
            cursor: 'pointer',
            display: 'inline-block',
          }}
          onClick={() => onCopyRegisterLink(product)}
          data-testid={product.id}
        >
          {t('productConfiguration.copyLink')}
        </Box>
      </Box>
    </Box>
  )
}
