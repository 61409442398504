import {
  FocusEvent,
  ReactElement,
  forwardRef,
  ReactNode,
  SyntheticEvent,
  ElementType,
  FunctionComponent,
  SVGProps,
} from 'react'
import { Box, MenuProps, Select, SelectChangeEvent, SxProps, Theme } from '@mui/material'
import { DropdownIcon } from 'components/icons/Dropdown.icon'
import { CustomFormLabel } from 'components/CustomFormLabel'
import colors from 'theme/colors'

interface Props {
  sx?: SxProps<Theme>
  placeholder?: string
  placeholderColor?: string
  children: ReactElement | ReactElement[]
  value?: string | null
  name?: string
  required?: boolean
  disabled?: boolean
  error?: boolean
  label?: string
  outline?: boolean
  onChange: (event: SelectChangeEvent, child?: ReactNode) => void
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  selectedTemplated?: () => JSX.Element | null
  menuProps?: Partial<MenuProps>
  onOpen?: (event: SyntheticEvent<Element, Event>) => void
  iconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>
}

export const CustomSelect = forwardRef<HTMLElement, Props>(
  (
    {
      sx,
      value,
      name,
      required,
      disabled,
      error,
      label,
      selectedTemplated,
      placeholder,
      placeholderColor,
      outline = true,
      onChange,
      onBlur,
      children,
      menuProps,
      onOpen,
      iconComponent,
    },
    ref
  ) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '&& .MuiSelect-select': {
            paddingRight: '38px',
          },
          '&&& .MuiSelect-icon': {
            color: disabled ? colors.silverSand : 'common.black',
          },
          ...sx,
        }}
      >
        {label && <CustomFormLabel value={label} required={required} error={error} />}
        <Select
          ref={ref}
          data-testid='custom-select'
          sx={{
            lineHeight: '20px',
            borderRadius: '2px',

            '&&& fieldset': {
              borderColor: outline ? 'grey.100' : 'transparent',
              borderWidth: '1px',

              ':hover': {
                borderColor: 'inherit',
              },
            },
            '&&&.Mui-error fieldset': {
              borderColor: 'error.main',
            },
          }}
          name={name}
          required={required}
          error={error}
          displayEmpty
          disabled={disabled}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: '2px',
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.12)',
              },
            },
            autoFocus: false,
            sx: {
              top: '5px',
            },
            ...menuProps,
          }}
          renderValue={(selected: string) =>
            !selected ? (
              <Box
                sx={{
                  color: placeholderColor || 'grey.800',
                  WebkitTextFillColor: placeholderColor || colors.mainGrey,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {placeholder || ''}
              </Box>
            ) : selectedTemplated ? (
              selectedTemplated()
            ) : (
              selected
            )
          }
          IconComponent={iconComponent || DropdownIcon}
          onOpen={onOpen}
        >
          {children}
        </Select>
      </Box>
    )
  }
)

CustomSelect.displayName = 'CustomSelect'
