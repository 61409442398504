import { Direction, SortingDirection } from 'constants/sorting'
import { useEffect, useState } from 'react'

type SortingConfig<T> = {
  column: keyof T
  direction: Direction
}

export const useSorting = <T>(initialData: T[], initialConfig?: SortingConfig<T>) => {
  const [sortedData, setSortedData] = useState(initialData)
  const [sortingConfig, setSortingConfig] = useState<SortingConfig<T>>(
    initialConfig || {
      column: '' as keyof T,
      direction: SortingDirection.ASC,
    }
  )

  useEffect(() => {
    const sortByColumn = (a: T, b: T) => {
      const valueA = a[sortingConfig.column]
      const valueB = b[sortingConfig.column]

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase(), undefined)
      }

      return 0
    }

    const sorted = [...initialData].sort((a, b) => {
      return sortingConfig.direction === SortingDirection.ASC
        ? sortByColumn(a, b)
        : sortByColumn(b, a)
    })
    setSortedData(sorted)
  }, [initialData, sortingConfig])

  return { sortingConfig, sortedData, setSortingConfig }
}
