import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TitleBadge } from 'components/TitleBadge'
import { AdminTabsNavigation } from './adminTabsNavigation'

import { handleAddAdminsModal } from 'sharedSlices/ui'
import { getRoles } from 'features/manageAdmins/slices'
import {
  filteredRoleList,
  selectRolePaths,
} from 'features/manageAdmins/slices/manageAdmins/selectors'
import { setEmployeeRoleId } from 'features/manageAdmins/slices/manageAdmins'

import { useAppDispatch, useAppSelector } from 'app/hooks'

const roleTab: { [x: string]: number } = {
  super: 1,
  jurisdiction: 2,
  product: 3,
  demouser: 4,
}

export const AdminLayout: FC = () => {
  const { t } = useTranslation()
  const { roleName } = useParams()
  const dispatch = useAppDispatch()
  const roleTabs = useAppSelector(selectRolePaths)
  const roleList = useAppSelector(filteredRoleList)
  const { isLoading, employeeRoleId } = useAppSelector((state) => state.manageAdmins)
  const navigate = useNavigate()
  const isRedirectedToManageAdminByTabName = !!roleTabs.length && !isLoading && !employeeRoleId

  useEffect(() => {
    isRedirectedToManageAdminByTabName && navigate(roleTabs[0].path)
  }, [isRedirectedToManageAdminByTabName, navigate, roleTabs])

  useEffect(() => {
    dispatch(getRoles())
  }, [dispatch])

  useEffect(() => {
    !isLoading && dispatch(setEmployeeRoleId(roleTab[roleName!]))
  }, [dispatch, roleName, isLoading])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} className='main'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: '0 16px',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderBottomColor: 'grey.100',
          div: {
            borderBottom: '0!important',
          },
        }}
      >
        <TitleBadge
          title={t('titlePage.manageAdmin')}
          size={3}
          sx={{ flex: 1, minHeight: '55px', '.MuiBox-root': { display: 'none' } }}
        />
        {roleList.length > 0 && (
          <Button variant='contained' onClick={() => dispatch(handleAddAdminsModal(true))}>
            {t('button.assignAdmin')}
          </Button>
        )}
      </Box>
      {!!roleTabs.length && (
        <Box
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            borderBottom: '1px solid',
            borderBottomColor: 'border.main',
            minHeight: '55px',
            display: 'flex',
            alignItems: 'center',
            p: '0 16px',
            justifyContent: 'space-between',
          }}
        >
          <AdminTabsNavigation />
        </Box>
      )}
      <Outlet />
    </Box>
  )
}
