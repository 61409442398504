import { CheckThinIcon } from 'components/icons/CheckThin.icon'
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { autocompleteStyles } from '../accountDetails/constants'
import { Avatar, Box, createFilterOptions, InputAdornment, TextField } from '@mui/material'
import CustomForm from 'components/CustomForm'
import { ICountry, ProductGroupType } from 'features/managePackage/constants'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { selectAccountCountry, selectBillingCountry } from 'features/subscriptionRequest/slice'
import { useTranslation } from 'react-i18next'
import { restrictedCountries } from 'features/subscriptionRequest/constants/subscription'
import { fetchBillingStates, fetchStates } from 'features/subscriptionRequest/slice/services'

interface ICountrySelectProps {
  isAllFormDisabled?: boolean
  isFromBillingInfo?: boolean
  selectedCountry?: ICountry
  setOpenRestrictedCountryPopup: Dispatch<SetStateAction<boolean>>
}

export const CountrySelect = ({
  isAllFormDisabled,
  isFromBillingInfo,
  selectedCountry,
  setOpenRestrictedCountryPopup,
}: ICountrySelectProps) => {
  const { t } = useTranslation()

  const { accountCountries, productGroup } = useAppSelector((state) => state.subscriptionRequest)
  const dispatch = useAppDispatch()

  const filterOptions = useCallback(
    () =>
      createFilterOptions({
        matchFrom: 'start',
        stringify: (option: ICountry) => option.name,
      }),
    []
  )

  const handleUpdateCountryInfo = useCallback(
    (country: ICountry) => {
      const updateBillingInfo = () => dispatch(selectBillingCountry(country))
      const updateAccountInfo = () => dispatch(selectAccountCountry(country))

      if (isFromBillingInfo) {
        return updateBillingInfo()
      }

      if (productGroup.type === ProductGroupType.All) {
        updateAccountInfo()
        updateBillingInfo()
        return
      }

      updateAccountInfo()
    },
    [dispatch, productGroup.type, isFromBillingInfo]
  )

  const onSelectCountry = useCallback(
    (country: ICountry) => handleUpdateCountryInfo(country),
    [handleUpdateCountryInfo]
  )

  useEffect(() => {
    if (selectedCountry) {
      isFromBillingInfo ? dispatch(fetchBillingStates(selectedCountry.id)) : dispatch(fetchStates(selectedCountry.id))

      if (restrictedCountries.includes(selectedCountry.name)) {
        setOpenRestrictedCountryPopup(true)
      }
    }
  }, [selectedCountry, isFromBillingInfo, setOpenRestrictedCountryPopup, dispatch])

  return (
    <CustomForm.Autocomplete<ICountry>
      filterOptions={filterOptions()}
      getOptionLabel={(country) => country.name}
      id='select-country'
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id
      }}
      onChange={(e, country) => {
        if (country && !Array.isArray(country)) {
          onSelectCountry(country)
        }
      }}
      options={accountCountries}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: selectedCountry && (
              <InputAdornment sx={{ marginLeft: '8px', marginRight: 0 }} position='start'>
                <Avatar sx={{ width: 24, height: 24, flexShrink: 0 }}>
                  <svg width='24' height='24'>
                    <use href={`/images/flags/sprite.svg#${selectedCountry.abbreviation}`} />
                  </svg>
                </Avatar>
              </InputAdornment>
            ),
          }}
          placeholder={t('account.countryPlaceholder')!}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
          data-testid='country-select'
        />
      )}
      renderOption={(props, country) => (
        <Box component='li' {...props}>
          <Avatar sx={{ width: 24, height: 24, mr: '8px', flexShrink: 0 }}>
            <svg width='24' height='24'>
              <use href={`/images/flags/sprite.svg#${country.abbreviation}`} />
            </svg>
          </Avatar>
          {country.name}
        </Box>
      )}
      styles={autocompleteStyles}
      value={selectedCountry}
      disabled={isAllFormDisabled}
    />
  )
}
