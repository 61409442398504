import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'
import globalSearch from 'assets/images/dsgGlobalSearch.svg'

export const NoProducts = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: 'menuItemHover.main',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        borderTop: '1px solid',
        borderTopColor: 'grey.100',
      }}
    >
      <img src={globalSearch} alt='' />
      <Typography variant='body1' fontWeight={600} mt='30px'>
        {t('productConfiguration.noProducts.title')}
      </Typography>
      <Typography variant='body2' color={'grey.800'} mt='6px' mb='16px'>
        {t('productConfiguration.noProducts.subtitle')}
      </Typography>
      <Button
        variant='contained'
        onClick={() => {
          navigate('/manage/productConfiguration/create')
        }}
        data-testid={'button-create-config'}
      >
        {t('productConfiguration.buttons.addProduct')}
      </Button>
    </Box>
  )
}
