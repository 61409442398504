import { FC, useCallback, useRef, useState } from 'react'
import { Avatar, Box, Popover, SxProps, Tooltip, Typography } from '@mui/material'
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react'
import { useTranslation } from 'react-i18next'
import { AuthError, RedirectRequest } from '@azure/msal-browser'

import { Profile } from 'components/layouts/routerLayout/Profile'

import { handleProfile } from 'sharedSlices/ui'

import { loginError } from 'sharedSlices/auth'
import { useLogout } from 'services/auth/useLogout'

import { useAppDispatch, useAppSelector } from 'app/hooks'

import Login from 'assets/icons/login.svg'
import LogOut from 'assets/icons/logout.svg'
import deloitteLogo from 'assets/images/deloitte-logo.svg'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { SelectLanguage } from 'components/SelectLanguage'

const avatarStyles: SxProps = {
  fontWeight: 600,
  color: 'common.black',
  background: 'none',
  border: '1px solid',
  borderColor: 'border.main',
  margin: '8px',
  fontSize: '12px',
  cursor: 'pointer',
}

interface Props {
  title: string
}

export const UpperMenu: FC<Props> = ({ title }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { instance } = useMsal()
  const elementRef = useRef(null)
  const { handleLogout } = useLogout()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const { isProfileOpen } = useAppSelector((state) => state.ui)
  const { employeeInformation: user, isAuthenticated, endUserInformation } = useAppSelector((state) => state.auth)

  const isLogoutOpened = Boolean(anchorEl)

  const handleLogin = useCallback(() => {
    const { origin, pathname, search } = window.location
    // Temporary inactive login for DART subscription request
    if (pathname.toUpperCase().includes('DART')) return
    const loginRequest: RedirectRequest = {
      scopes: ['User.Read'],
      redirectUri: `${origin}${pathname}${search}`,
    }

    instance.loginRedirect(loginRequest).catch((e: AuthError) => {
      dispatch(loginError(e.errorMessage))
    })
  }, [instance, dispatch])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '55px',
        borderBottom: '1px solid ',
        borderBottomColor: 'border.main',
        backgroundColor: 'common.white',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        padding: '16px 48px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginRight: 'auto',
          maxWidth: `calc(100% - ${isAuthenticated ? 56 : 250}px)`,
        }}
      >
        <img src={deloitteLogo} alt='Deloitte logo.' style={{ marginRight: '32px' }} />
        <Typography overflow='hidden' variant='h6' fontWeight={600}>
          <OverflowTooltip text={t(title)} />
        </Typography>
      </Box>
      {isProfileOpen && <Profile anchor={elementRef.current} open onClose={() => dispatch(handleProfile(false))} />}
      {!isAuthenticated && (
        <SelectLanguage
          variant='standard'
          disableUnderline
          sx={{
            '&.MuiBox-root': {
              flexDirection: 'row',
            },
            '&&& .MuiInputBase-input': {
              paddingRight: '45px',
            },
            '&&&& fieldset': {
              borderColor: 'transparent',
            },
          }}
        />
      )}
      {isAuthenticated ? (
        <AuthenticatedTemplate>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '130px',
            }}
          >
            <Avatar ref={elementRef} sx={avatarStyles} onClick={() => dispatch(handleProfile(true))}>
              {user!.firstName.charAt(0) + user!.lastName.charAt(0)}
            </Avatar>
          </Box>
        </AuthenticatedTemplate>
      ) : endUserInformation ? (
        <AuthenticatedTemplate>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginLeft: '24px',
            }}
          >
            <Avatar
              ref={elementRef}
              sx={{
                ...avatarStyles,
              }}
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              {endUserInformation.firstName.charAt(0) + endUserInformation.lastName.charAt(0)}
            </Avatar>
            <Popover
              sx={{
                top: '36px',
                '.MuiPopover-paper': {
                  borderRadius: 0,
                },
              }}
              open={isLogoutOpened}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              <Typography
                variant='subtitle2'
                sx={{
                  width: '120px',
                  padding: '12px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setAnchorEl(null)
                  handleLogout(window.location.pathname)
                }}
              >
                <Box sx={{ marginRight: '8px' }} component='img' src={LogOut} />
                <Box>{t('profile.logout')}</Box>
              </Typography>
            </Popover>
          </Box>
        </AuthenticatedTemplate>
      ) : (
        <Tooltip
          title={t('tooltips.login')}
          placement='bottom'
          disableInteractive
          arrow
          componentsProps={{
            tooltip: {
              style: { marginTop: '5px' },
            },
          }}
        >
          <Box
            sx={{
              height: '24px',
              width: '24px',
              marginLeft: '24px',
              cursor: 'pointer',
            }}
            component='img'
            src={Login}
            onClick={handleLogin}
          />
        </Tooltip>
      )}
    </Box>
  )
}
