import { SvgIcon, SvgIconProps } from '@mui/material'

export const DeleteChipIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <g clipPath='url(#clip0_2643_559)'>
          <rect width='20' height='20' rx='10'/>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            fill='white'
            d='M5.07383 5.17975L5.12204 5.12204C5.26668 4.9774 5.4912 4.96133 5.65359
            5.07383L5.71129 5.12204L10 9.41083L14.2887 5.12204C14.4333 4.9774 14.6579 4.96133
            14.8203 5.07383L14.878 5.12204C15.0226 5.26668 15.0387 5.4912 14.9262 5.65359L14.878
            5.71129L10.5892 10L14.878 14.2887C15.0226 14.4333 15.0387 14.6579 14.9262 14.8203L14.878
            14.878C14.7333 15.0226 14.5088 15.0387 14.3464 14.9262L14.2887 14.878L10 10.5892L5.71129
            14.878C5.56666 15.0226 5.34214 15.0387 5.17975 14.9262L5.12204 14.878C4.9774 14.7333
            4.96133 14.5088 5.07383 14.3464L5.12204 14.2887L9.41083 10L5.12204 5.71129C4.9774
            5.56666 4.96133 5.34214 5.07383 5.17975Z'
          />
        </g>
        <defs>
          <clipPath id='clip0_2643_559'>
            <rect width='20' height='20' rx='10' fill='white'/>
          </clipPath>
        </defs>
      </SvgIcon>
    </>
  )
}
