import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUiState } from './constants'

export const UI_INITIAL_STATE: IUiState = {
  isSideBarOpen: false,
  isProfileOpen: false,
  isProfileSettingsOpen: false,
  isBusinessUnitModalOpen: false,
  isAddAdminsModalOpen: false,
  isUpdateAdminsModalOpen: false,
  isDeleteAdminModalOpen: false,
  isFooterVisible: true,
  isApproveSubModalOpen: false,
  isRejectSubModalOpen: false,
  isInactivateModalOpen: false,
  manageUserSubscriptionModals: {
    isManageUsersModalOpen: false,
    isAddUserModalOpen: false,
    isRemoveUserModalOpen: false,
    isEditPrimaryUserModalOpen: false,
    isAddMultipleUserOpen: false,
  },
}

const uiSlice = createSlice({
  name: 'ui',
  initialState: UI_INITIAL_STATE,
  reducers: {
    handleAddAdminsModal: (state, action: PayloadAction<boolean>) => {
      state.isAddAdminsModalOpen = action.payload
    },
    handleSideBar: (state, action: PayloadAction<boolean>) => {
      state.isSideBarOpen = action.payload
    },
    handleProfile: (state, action: PayloadAction<boolean>) => {
      state.isProfileOpen = action.payload
    },
    handleProfileSettings: (state, action: PayloadAction<boolean>) => {
      state.isProfileSettingsOpen = action.payload
    },
    handleBusinessUnitModal: (state, action: PayloadAction<boolean>) => {
      state.isBusinessUnitModalOpen = action.payload
    },
    handleUpdateAdminsModal: (state, action: PayloadAction<boolean>) => {
      state.isUpdateAdminsModalOpen = action.payload
    },
    handleDeleteAdminModal: (state, action: PayloadAction<boolean>) => {
      state.isDeleteAdminModalOpen = action.payload
    },
    handleFooter: (state, action: PayloadAction<boolean>) => {
      state.isFooterVisible = action.payload
    },
    handleAddUserModal: (state, action: PayloadAction<boolean>) => {
      state.manageUserSubscriptionModals.isAddUserModalOpen = action.payload
    },
    handleRemoveUsersModal: (state, action: PayloadAction<boolean>) => {
      state.manageUserSubscriptionModals.isRemoveUserModalOpen = action.payload
    },
    handleEditPrimaryUserModal: (state, action: PayloadAction<boolean>) => {
      state.manageUserSubscriptionModals.isEditPrimaryUserModalOpen = action.payload
    },
    handleAddMultipleUsersModal: (state, action: PayloadAction<boolean>) => {
      state.manageUserSubscriptionModals.isAddMultipleUserOpen = action.payload
    },
    closeUserSubscriptionModals: (state) => {
      state.manageUserSubscriptionModals.isAddUserModalOpen = false
      state.manageUserSubscriptionModals.isManageUsersModalOpen = false
      state.manageUserSubscriptionModals.isRemoveUserModalOpen = false
      state.manageUserSubscriptionModals.isEditPrimaryUserModalOpen = false
      state.manageUserSubscriptionModals.isAddMultipleUserOpen = false
    },
    handleApproveSubModal: (state, action: PayloadAction<boolean>) => {
      state.isApproveSubModalOpen = action.payload
    },
    handleInactivateSubModal: (state, action: PayloadAction<boolean>) => {
      state.isInactivateModalOpen = action.payload
    },
    handleRejectSubModal: (state, action: PayloadAction<boolean>) => {
      state.isRejectSubModalOpen = action.payload
    },
  },
})

export const {
  closeUserSubscriptionModals,
  handleAddAdminsModal,
  handleAddUserModal,
  handleDeleteAdminModal,
  handleEditPrimaryUserModal,
  handleFooter,
  handleProfile,
  handleProfileSettings,
  handleBusinessUnitModal,
  handleRemoveUsersModal,
  handleSideBar,
  handleAddMultipleUsersModal,
  handleUpdateAdminsModal,
  handleApproveSubModal,
  handleRejectSubModal,
  handleInactivateSubModal,
} = uiSlice.actions

export default uiSlice.reducer
