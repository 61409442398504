import { geoApi } from 'services/api/geoApi'

import { RegisteredUser } from '../slices/manageUsers/constants'
import { UserRequest } from '../constants'
import { UploadResult } from '../constants/upload.interface'

type RequestInfo = {
  subscriptionId: string
  userId: string
  jurCode: string
}

export const addUser = (
  user: UserRequest,
  subscriptionId: string,
  jurCode: string
): Promise<void> => {
  return geoApi.withJurisdictionCode(jurCode).post(`subscriptions/${subscriptionId}/user`, user)
}

export const deleteUser = ({
  subscriptionId,
  userIds,
  jurCode,
}: {
  subscriptionId: string
  userIds: string[]
  jurCode: string
}): Promise<void> => {
  return geoApi.withJurisdictionCode(jurCode).patch(`subscriptions/${subscriptionId}`, {
    ids: userIds,
  })
}

export const updateUser = ({ subscriptionId, userId, jurCode }: RequestInfo): Promise<void> => {
  return geoApi
    .withJurisdictionCode(jurCode)
    .put(`subscriptions/${subscriptionId}/primary/user/${userId}`)
}

export const getUserByEmail = (
  email: string,
  jurCode: string
): Promise<RegisteredUser | undefined> => {
  return geoApi.withJurisdictionCode(jurCode).get(`subscriptions/user/email/${email}`)
}

export const addUserBulk = ({
  file,
  subscriptionId,
  jurCode,
}: {
  file: FormData
  subscriptionId: string
  jurCode: string
}): Promise<UploadResult[]> => {
  return geoApi
    .withJurisdictionCode(jurCode)
    .post<UploadResult[], FormData>(`subscriptions/${subscriptionId}/users/bulkadd`, file, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
}
