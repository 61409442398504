import { useAppSelector } from 'app/hooks'
import { IPackage, IPackageProduct } from 'features/subscriptionRequest/constants/subscription'
import { getTotalPrice } from 'features/subscriptionRequest/utils/getTotalPrice'

export const useProductSummaryData = (product: IPackageProduct) => {
  const { productGroup, commonTerms, licenses, commonTermId, currencies } = useAppSelector(
    (state) => state.subscriptionRequest
  )

  const selectedPackages = (packages: IPackage[]) =>
    packages.filter((_) => licenses[_.packageGroup.name]).sort((a, b) => b.name.localeCompare(a.name))

  const currency = currencies[product.id]
  const soldBy = product.packages[0].packageGroup.legalName
  const productItem = productGroup.products.find((_) => product.id === _.id)
  const commonTerm = commonTerms.find((term) => term.id === commonTermId)!
  const jurCode = productItem?.countries?.[0]?.abbreviation

  const totalPrice = getTotalPrice(
    product.packages,
    licenses,
    commonTerm,
    currency,
    productGroup.products?.[0]?.productBulkDiscounts ?? []
  )

  return { selectedPackages, currency, productItem, commonTerm, totalPrice, jurCode, soldBy }
}
