export enum StepName {
  SubscriptionInit = 'steps.subscriptionInit',
  SelectPackage = 'steps.selectPackage',
  AcceptableUsePolicy = 'steps.acceptableUsePolicy',
  TermsOfUse = 'steps.termsOfUse',
  AccountDetails = 'steps.accountDetails',
  PaymentDetails = 'steps.paymentDetails',
}

export enum StepStatus {
  Available = 'available',
  Disabled = 'disabled',
  Completed = 'completed',
}

export const enum StepCreateSubscription {
  SubscriptionInit = 1,
  SelectPackage,
  AcceptableUsePolicy,
  AccountDetails,
  PaymentDetails,
}

export const enum StepRenewSubscription {
  SelectPackage = 1,
  AccountDetails,
}

export interface IStep {
  name: StepName
  status: StepStatus
}

export const requestSubscriptionSteps: IStep[] = [
  {
    name: StepName.SubscriptionInit,
    status: StepStatus.Available,
  },
  {
    name: StepName.SelectPackage,
    status: StepStatus.Disabled,
  },
  {
    name: StepName.AcceptableUsePolicy,
    status: StepStatus.Disabled,
  },
  {
    name: StepName.AccountDetails,
    status: StepStatus.Disabled,
  },
  {
    name: StepName.PaymentDetails,
    status: StepStatus.Disabled,
  },
]

export const renewSubscriptionSteps: IStep[] = [
  {
    name: StepName.SelectPackage,
    status: StepStatus.Available,
  },
  {
    name: StepName.AccountDetails,
    status: StepStatus.Disabled,
  },
]

export const NEXT_STEP = 1
