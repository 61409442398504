import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { CustomSnackbar } from 'components/CustomSnackbar'
import { store } from 'app/store'
import { theme } from 'theme'
import App from './app/App'
import { CustomLoading } from 'components/CustomLoading'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Suspense>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <CssBaseline />
            <CustomSnackbar />
            <Suspense
              fallback={
                <CustomLoading
                  message='common.loader.loading'
                  textColor='white'
                  sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1,
                    backgroundColor: 'rgba(0,0,0,0.7)',
                  }}
                />
              }
            >
              <App />
            </Suspense>
          </Provider>
        </I18nextProvider>
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>
)
