import { SvgIcon, SvgIconProps } from '@mui/material'

export const UploadToCloudIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M25.7071 16.2929L31.7071 22.2929C32.0976 22.6834 32.0976 23.3166 31.7071
          23.7071C31.3166 24.0976 30.6834 24.0976 30.2929 23.7071L26 19.414V36C26 36.5523 25.5523
          37 25 37C24.4477 37 24 36.5523 24 36V19.414L19.7071 23.7071C19.3166 24.0976 18.6834
          24.0976 18.2929 23.7071C17.9024 23.3166 17.9024 22.6834 18.2929 22.2929L24.2929
          16.2929C24.6834 15.9024 25.3166 15.9024 25.7071 16.2929ZM25 8C30.5394 8 33.9054 10.7392
          34.8588 16H35C38.866 16 42 19.134 42 23C42 26.866 38.866 30 35 30H31C30.4477 30 30 29.5523
          30 29C30 28.4477 30.4477 28 31 28H35C37.7614 28 40 25.7614 40 23C40 20.2386 37.7614 18 35
          18H34C33.4957 18 33.0703 17.6245 33.0077 17.124C32.4034 12.2897 29.8275 10 25 10C22.1583
          10 20.0645 10.9984 18.5551 12.6701C18.026 13.2562 17.608 13.8857 17.2913 14.5131L17.1051
          14.9073L16.9892 15.1946C16.9755 15.2325 16.9646 15.2652 16.9563 15.2924C16.8278 15.7128
          16.4397 16 16 16H14C10.6863 16 8 18.6863 8 22C8 25.3137 10.6863 28 14 28H19C19.5523 28 20
          28.4477 20 29C20 29.5523 19.5523 30 19 30H14C9.58172 30 6 26.4183 6 22C6 17.5817 9.58172
          14 14 14H15.3085C15.6516 13.2159 16.22 12.2721 17.0707 11.3299C18.947 9.25161 21.5718 8 25
          8Z'
        />
      </SvgIcon>
    </>
  )
}
