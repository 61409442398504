import { FC, ChangeEvent, useMemo, useState } from 'react'
import { Box, Typography, MenuItem, SelectChangeEvent, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { CustomTextField } from 'components/CustomTextField'
import { CustomFormLabel } from 'components/CustomFormLabel'
import { WarningIcon } from 'components/icons/Warning.icon'
import FooterForm from 'features/manageAdmins/components/footerForm'
import HeaderForm from 'features/manageAdmins/components/headerForm'

import { approveSubscription } from 'features/managePackage/services'

import { ProductFeature } from 'features/subscriptionRequest/constants/subscription'
import { PackageStatus } from 'features/manageProduct/constants'
import { setSelectedPackage } from 'features/managePackage/slice'
import { useGetSubscriptions } from 'features/managePackage/hooks/useGetSubscriptions'
import { handleApproveSubModal } from 'sharedSlices/ui'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import colors from 'theme/colors'
import { showMessage } from 'utils/errorMessageHandler'
import { CustomSelect } from '../../../../components/CustomSelect'

const MAX_LENGTH = 250

const selectStyle: SxProps = {
  mt: '5px',
  width: '100%',
  '.MuiSelect-select': { color: colors.mineShaft },
  lineHeight: '22px',
  borderRadius: '2px',
  '&&& .MuiSelect-icon': {
    color: 'common.black',
  },
  '&&& fieldset': {
    borderColor: 'grey.100',
    borderWidth: '1px',
    borderRadius: '2px',

    ':hover': {
      borderColor: 'inherit',
    },
  },
}

export const ApproveSubForm: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { getSubscriptionInfo } = useGetSubscriptions()

  const { productRoles, selectedPackage, prodJurisSelected } = useAppSelector((state) => state.managePackage)

  const jurCode = searchParams.get('code')

  const [{ roleSelectedId, comment }, setFormInputs] = useState({
    roleSelectedId: '',
    comment: '',
  })

  const isPendingStatus = useMemo(() => {
    return selectedPackage?.status === PackageStatus.Pending
  }, [selectedPackage])

  const hasProductRoleFeature = useMemo(() => {
    return prodJurisSelected!.features.includes(ProductFeature.Role)
  }, [prodJurisSelected])

  const showProductRole = !!productRoles.length && isPendingStatus && hasProductRoleFeature

  const isSubmitDisabled = useMemo(() => {
    return showProductRole ? !(roleSelectedId && comment) : !comment
  }, [showProductRole, roleSelectedId, comment])

  const onClose = () => {
    dispatch(setSelectedPackage(undefined))
    dispatch(handleApproveSubModal(false))
  }

  const onApproveSub = () => {
    const productRole = productRoles.find((proRole) => proRole.id === Number(roleSelectedId))
    if (selectedPackage && jurCode) {
      approveSubscription(
        selectedPackage.subscriptionId,
        selectedPackage.subscriptionPackageId,
        comment,
        jurCode,
        productRole
      )
        .then(() => {
          getSubscriptionInfo(selectedPackage.productId, jurCode)
          showMessage.success('mySnackbar', 'manageProduct.subscriptionMessage.success')
          dispatch(handleApproveSubModal(false))
        })
        .catch(() => showMessage.error('mySnackbar', 'manageProduct.subscriptionMessage.error'))
    }
  }

  const handleRoleSelected = (e: SelectChangeEvent) => {
    setFormInputs((prevState) => ({
      ...prevState,
      roleSelectedId: e.target.value,
    }))
  }

  const handleComment = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const message = value.slice(0, MAX_LENGTH)
    setFormInputs((prevState) => {
      const isCommentAtMaxLength = prevState.comment.length === MAX_LENGTH
      const isMessageShorterThanMax = message.length < MAX_LENGTH

      if (isCommentAtMaxLength && isMessageShorterThanMax) return { ...prevState, comment: message }

      if (!isCommentAtMaxLength) return { ...prevState, comment: message }

      return prevState
    })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
      {isPendingStatus ? (
        <HeaderForm title='managePackages.modals.title' onClose={onClose} />
      ) : (
        <HeaderForm
          icon={<WarningIcon sx={{ mr: '6px' }} />}
          title={`managePackages.modals.title${selectedPackage?.status}`}
          onClose={onClose}
        />
      )}
      <Box sx={{ p: '16px' }}>
        <Typography variant='body2' mb={'16px'}>
          {showProductRole ? t('managePackages.modals.messageWithRole') : t('managePackages.modals.message')}
        </Typography>
        {showProductRole && (
          <>
            <CustomFormLabel value={t('managePackages.modals.role')} />
            <CustomSelect
              sx={selectStyle}
              value={roleSelectedId}
              onChange={handleRoleSelected}
              placeholder={t('managePackages.modals.selectRole')}
              selectedTemplated={() => (
                <>{productRoles.find((productRole) => productRole.id === Number(roleSelectedId))?.name}</>
              )}
            >
              {productRoles.map((e) => (
                <MenuItem key={e.id} value={e.id} data-testid={'select-option'}>
                  {e.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </>
        )}
        <CustomTextField
          placeholder={t('managePackages.modals.enterComment')}
          label={t('managePackages.modals.comment')}
          value={comment}
          multiline
          sx={{
            mt: '16px',
            '& textarea': {
              minHeight: '92px',
              '&::placeholder': { color: colors.silverChalice, opacity: 1 },
            },
          }}
          onChange={handleComment}
        />
        <Typography
          sx={{ textAlign: 'right', color: colors.grey[800], lineHeight: '16px', mt: '8px' }}
          variant='caption'
          component={Box}
        >
          {comment.length}/{MAX_LENGTH}
        </Typography>
      </Box>
      <FooterForm>
        <FooterForm.Button text='button.no' variant='outlined' onClick={onClose} />
        <FooterForm.Button text='button.yes' disabled={isSubmitDisabled} onClick={onApproveSub} />
      </FooterForm>
    </Box>
  )
}
