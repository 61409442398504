import { SvgIcon, SvgIconProps } from '@mui/material'

export const SettingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4C15.6462 4 16.2762 4.1229 16.8638 4.35908C17.1965 4.49282 17.2845 4.92302 17.0309
        5.17658L14.5537 7.6534L14.9123 9.08769L16.3467 9.44628L18.8239 6.97004C19.0775 6.71652
        19.5077 6.80454 19.6414 7.13731C19.8773 7.72458 20 8.35418 20 9C20 11.7614 17.7614
        14 15 14C14.5584 14 14.1242 13.9426 13.7056 13.8307L8.26777 19.2678C7.29146 20.2441
        5.70854 20.2441 4.73223 19.2678C3.79837 18.3339 3.75777 16.845 4.61042 15.8629L4.73223
        15.7322L10.1691 10.2938C10.0573 9.87541 10 9.4414 10 9C10 6.23858 12.2386 4 15 4ZM15.4875
        5.02935C15.3266 5.00984 15.1639 5 15 5C12.7909 5 11 6.79086 11 9C11 9.35262 11.0455 9.6999
        11.1352 10.0356C11.2274 10.3805 11.1287 10.7483 10.8763 11.0008L5.46375 16.4141L5.36555
        16.5185C4.85223 17.1098 4.88188 18.0032 5.43934 18.5607C6.02513 19.1464 6.97487 19.1464
        7.56071 18.5606L12.9985 13.1235C13.251 12.8711 13.619 12.7724 13.9639 12.8646C14.2998
        12.9544 14.6472 13 15 13C17.2091 13 19 11.2091 19 9C19 8.75445 18.9779 8.51161 18.9343
        8.27363L17.0537 10.1535C16.8053 10.4018 16.4449 10.5016 16.1042 10.4164L14.6698
        10.0578C14.3115 9.96826 14.0317 9.68851 13.9422 9.33022L13.5836 7.89593C13.4984 7.55513
        13.5983 7.19462 13.8467 6.94624L15.7273 5.06586L15.4875 5.02935Z"/>
    </SvgIcon>
  )
}
