import { Box, Typography } from '@mui/material'
import { Trans } from 'react-i18next'

import { CustomAlert } from 'components/CustomAlert'
import { CopyIcon } from 'components/icons/Copy.icon'
import { AlertError } from '../../alertError'
import FooterForm from 'features/manageAdmins/components/footerForm'

import { useClipboardCopy } from 'features/manageUsers/hooks/useClipboardCopy'
import { UploadResult } from 'features/manageUsers/constants/upload.interface'
import { SeverityType } from 'constants/severity'

interface Props {
  disable: boolean
  handleClose: () => void
  uploadResult: UploadResult[]
}

export const SuccessView = ({ disable, handleClose, uploadResult }: Props) => {
  const { showCopyButton, copyToClipboard } = useClipboardCopy(uploadResult)
  const errorResponse = uploadResult.filter((e) => e.type === SeverityType.error)
  const successInfoResponse = uploadResult.filter(
    (e) => e.type === SeverityType.info || e.type === SeverityType.success
  )
  return (
    <>
      <Box
        sx={{
          p: '0 16px 16px 16px',
          height: '226px',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 'auto',
            justifyContent: 'flex-end',
            minHeight: '100%',
          }}
        >
          {successInfoResponse.map((e) => {
            return (
              <CustomAlert severity={e.type} key={e.key} sx={{ height: 'auto', mb: '8px' }}>
                <Typography variant='body2'>
                  <Trans
                    i18nKey={e.key}
                    values={{ count: e.userCount }}
                    components={{
                      bold: <Typography component={'span'} variant='body2' fontWeight={700} />,
                    }}
                  />{' '}
                  {e.rows.length > 0 && e.rows.join(', ').trim()}
                </Typography>
              </CustomAlert>
            )
          })}
          {errorResponse.length > 0 && <AlertError errorResults={errorResponse} />}
        </Box>
      </Box>
      <FooterForm>
        {showCopyButton && (
          <FooterForm.Button
            text='button.copy'
            icon={<CopyIcon />}
            variant='outlined'
            disabled={disable}
            onClick={copyToClipboard}
          />
        )}
        <FooterForm.Button text='button.finish' disabled={disable} onClick={handleClose} />
      </FooterForm>
    </>
  )
}
