import { useAccount, useMsal } from '@azure/msal-react'

import { useAppDispatch } from 'app/hooks'
import { logout } from 'sharedSlices/auth'

export const useLogout = () => {
  const dispatch = useAppDispatch()
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})

  const handleLogout = (logoutRedirectUrl?: string) => {
    instance.logoutRedirect({ account, postLogoutRedirectUri: logoutRedirectUrl || '/login' }).then(() => {
      dispatch(logout())
    })  
  }

  return { handleLogout }
}
