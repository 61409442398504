import { Accordion, SxProps } from '@mui/material'
import React, { ReactNode } from 'react'

interface TCustomAccordion {
  children: NonNullable<ReactNode>
  sx?: SxProps
  disableGutters?: boolean
  elevation?: number
  square?: boolean
  defaultExpanded?: boolean
  expanded?: boolean
  handleChange?: ((event: React.SyntheticEvent, isExpanded: boolean) => void) | (() => void)
}

export const CustomAccordion = ({
  children,
  sx,
  disableGutters,
  elevation,
  square,
  defaultExpanded = false,
  expanded,
  handleChange,
}: TCustomAccordion) => {
  return (
    <Accordion
      sx={{
        border: '1px solid',
        borderColor: 'grey.100',
        overflow: 'hidden',
        width: '918px',
        margin: '0 auto',
        '&:last-child': {
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
        },
        '&:first-child': {
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        },
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&::before': {
          display: 'none',
        },
        ...sx,
      }}
      expanded={expanded}
      onChange={handleChange}
      disableGutters={disableGutters}
      elevation={elevation}
      square={square}
      defaultExpanded={defaultExpanded}
    >
      {children}
    </Accordion>
  )
}
