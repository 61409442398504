import { SvgIcon, SvgIconProps } from '@mui/material'

export const UploadIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6 8H10.5V9H6.5V18H19.5V9H15.5V8H20C20.2455 8 20.4496 8.17688 20.4919 8.41012L20.5
          8.5V18.5C20.5 18.7455 20.3231 18.9496 20.0899 18.9919L20 19H6C5.75454 19 5.55039 18.8231
          5.50806 18.5899L5.5 18.5V8.5C5.5 8.25454 5.67688 8.05039 5.91012 8.00806L6 8ZM12.9261
          2.00546L12.9577 2.00178L13.0428 2.00182L13.1281 2.01661L13.191 2.03779L13.267
          2.07719L13.3221 2.11759L16.3536 5.14645C16.5488 5.34171 16.5488 5.65829 16.3536
          5.85355C16.18 6.02712 15.9106 6.0464 15.7157 5.91141L15.6464 5.85355L13.5 3.707V13.5C13.5
          13.7455 13.3231 13.9496 13.0899 13.9919L13 14C12.7545 14 12.5504 13.8231 12.5081
          13.5899L12.5 13.5V3.707L10.3536 5.85355C10.18 6.02712 9.91056 6.0464 9.71569
          5.91141L9.64645 5.85355C9.47288 5.67999 9.4536 5.41056 9.58859 5.21569L9.64645
          5.14645L12.6779 2.11758L12.7153 2.08884L12.7886 2.04674L12.8719 2.01661L12.895
          2.01109C12.9287 2.00383 12.9639 2 13 2L12.9261 2.00546Z'
        />
      </SvgIcon>
    </>
  )
}
