import { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import CustomForm from 'components/CustomForm'
import { Box, Typography } from '@mui/material'

interface Props {
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  value: string
  isEditable: boolean
  name?: string
  placeholder: string
}

export const CustomField: FC<Props> = ({ onChange, value, isEditable, name, placeholder }) => {
  const { t } = useTranslation()
  return (
    <>
      {isEditable ? (
        <CustomForm.TextField
          sx={{ p: 0, m: 0, mt: '8px', mb: '16px' }}
          fullWidth
          name={name}
          onChange={onChange}
          value={value}
          placeholder={t(placeholder)!}
        />
      ) : (
        <Box sx={{ p: '8px 0 16px 0' }}>
          <Typography variant={'body2'} fontWeight={600} sx={{ py: '8px 16px' }}>
            {value as string}
          </Typography>
        </Box>
      )}
    </>
  )
}
