import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, InputAdornment, TextField } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { ICountry } from 'features/subscriptionRequest/constants/subscription'
import CustomForm from 'components/CustomForm'

export const CountrySelector: React.FC<{
  countries: ICountry[]
  selectedCountry: ICountry | null
  onSelectCountry: (country: ICountry | null) => void
  disabled: boolean
}> = ({ countries, selectedCountry, onSelectCountry, disabled }) => {
  const { t } = useTranslation()

  const filterOptions = useCallback(
    () =>
      createFilterOptions({
        matchFrom: 'start',
        stringify: (option: ICountry) => option.name,
      }),
    []
  )

  return (
    <CustomForm.Autocomplete<ICountry>
      filterOptions={filterOptions()}
      getOptionLabel={(country) => t(`country.${country.abbreviation.toLowerCase()}.name`)}
      id='select-country'
      disabled={disabled}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id
      }}
      onChange={(_, country) => {
        if (country && !Array.isArray(country)) {
          onSelectCountry(country)
        }
      }}
      onClose={(_, reason) => {
        if (reason === 'blur' && !selectedCountry) {
          onSelectCountry(null)
        }
      }}
      options={countries}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: selectedCountry && (
              <InputAdornment sx={{ marginLeft: '8px', marginRight: 0 }} position='start'>
                <Avatar
                  sx={{ width: 24, height: 24, mr: '8px', flexShrink: 0 }}
                  src={`${process.env.PUBLIC_URL}/images/flags/${selectedCountry.abbreviation}.svg`}
                />
              </InputAdornment>
            ),
          }}
          placeholder={t('subscriptionInit.country.placeholder')!}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
        />
      )}
      renderOption={(props, country) => (
        <Box component='li' {...props}>
          <Avatar
            sx={{ width: 24, height: 24, mr: '8px', flexShrink: 0 }}
            src={`${process.env.PUBLIC_URL}/images/flags/${country.abbreviation}.svg`}
          />
          {t(`country.${country.abbreviation.toLowerCase()}.name`)}
        </Box>
      )}
      styles={{
        mb: 0,
      }}
      value={selectedCountry as ICountry}
    />
  )
}
