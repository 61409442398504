import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

const reactPlugin = new ReactPlugin()
const connectionString = window.APP_CONFIG.INSIGHTS_CONNECTION_STRING
const instrumentationPart = connectionString.split(';')[0]
const instrumentationKey = instrumentationPart.split('=')[1]
const appInsights = new ApplicationInsights({
  config: {
    connectionString,
    instrumentationKey,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
})

appInsights.loadAppInsights()

export { reactPlugin, appInsights }
