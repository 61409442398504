import { IconButton } from '@mui/material'
import { ColumnDef, OnChangeFn, SortingState, functionalUpdate } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'app/hooks'
import { Duration } from '../Duration'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { ManagePackageActions } from '../ManagePackageActions'
import { PackageDetails } from '../PackageDetails'
import { SubmittedDate } from '../SubmitedDate'
import { SubscriptionStatus } from '../SubscriptionStatus'
import { Valuations } from '../Valuations'

import { CaretRightIcon } from 'components/icons/CaretRight.icon'

import { useSearchNavigate } from 'features/managePackage/hooks/useSearchNavigate'
import { useGetUrlParams } from 'features/managePackage/hooks/useGetUrlParams'
import { useGetSubscriptions } from 'features/managePackage/hooks/useGetSubscriptions'
import { CustomExpandTable } from 'features/managePackage/components/CustomExpandTable'
import { IPackageInfo, IPackageInfoGroupedView, IPackageDetails } from 'features/managePackage/constants'
import {
  ProductGroupType,
  PaymentTypeSubscription,
  paymentTypeLabels,
} from 'features/subscriptionRequest/constants/subscription'
import { NoSubscriptions } from '../NoSubscriptions'
import Actions from '../PackageActions'

import { Permissions } from 'constants/permissions'
import { CustomArrowTooltip } from 'components/CustomTooltip'

export const PackagesTable = () => {
  const { searchNavigate } = useSearchNavigate()
  const navigate = useNavigate()
  const { direction, column, jurCode, productId } = useGetUrlParams()
  const { prodGroupSelected } = useAppSelector((state) => state.managePackage)
  const { total, hasValuations, subscriptionList, getSubscriptionInfo } = useGetSubscriptions()

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: column ?? 'submittedDate',
      desc: direction ? direction === 'desc' : false,
    },
  ])

  const handleSort: OnChangeFn<SortingState> = (sortingUpdate) => {
    const val = functionalUpdate(sortingUpdate, sorting)
    searchNavigate({ column: val[0].id, direction: val[0].desc ? 'desc' : 'asc' })
    setSorting(sortingUpdate)
  }

  const onRenew = (packageInfo: IPackageInfo) => {
    navigate({
      pathname: `/subscribe/subscription/${packageInfo.subscriptionId}/package/${packageInfo.subscriptionPackageId}`,
      search: `?productId=${packageInfo.productId}&jurCode=${jurCode}`,
    })
  }

  const { t } = useTranslation()

  const columns = useMemo<ColumnDef<IPackageInfo>[]>(
    () => [
      {
        id: 'expand',
        accessorFn: (row) => row.packageDetails,
        enableSorting: false,
        header: '',
        size: 48,
        cell: ({ row, getValue }) =>
          getValue() &&
          row.getCanExpand() && (
            <CustomArrowTooltip
              placement='top'
              title={t(row.getIsExpanded() ? 'tooltips.collapse' : 'tooltips.expand')}
            >
              <IconButton onClick={row.getToggleExpandedHandler()} sx={{ p: 0 }}>
                <CaretRightIcon sx={{ transform: row.getIsExpanded() ? 'rotate(90deg)' : 'none' }} />
              </IconButton>
            </CustomArrowTooltip>
          ),
        footer: (props) => props.column.id,
      },
      {
        size: 50,
        accessorKey: 'companyName',
        header: () => t('manageProduct.subscriptions.company'),
        cell: (info) => <OverflowTooltip text={info.getValue<string>()} />,
        footer: (props) => props.column.id,
      },
      {
        size: 45,
        accessorKey: 'packageName',
        header: () => t('manageProduct.subscriptionDetails.package'),
        cell: (info) => <OverflowTooltip text={info.getValue<string>()} />,
        footer: (props) => props.column.id,
      },
      {
        size: 40,
        id: 'status',
        accessorFn: (row) => row,
        header: () => t('manageProduct.subscriptionDetails.status'),
        cell: SubscriptionStatus,
        footer: (props) => props.column.id,
      },
      {
        size: 50,
        id: 'valuations',
        accessorFn: (row) => row,
        header: () => t('manageProduct.subscriptionDetails.valuation'),
        cell: Valuations,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        size: 50,
        id: 'duration',
        accessorFn: (row) => row,
        header: () => t('manageProduct.subscriptionDetails.term'),
        cell: Duration,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        size: 100,
        id: 'submittedDate',
        accessorFn: (row) => row,
        header: () => t('manageProduct.subscriptions.submissionDate'),
        cell: SubmittedDate,
        footer: (props) => props.column.id,
      },
      {
        id: 'renew',
        accessorFn: (row) => row.packageDetails,
        enableSorting: false,
        header: '',
        size: 54,
        cell: ({ row }) =>
          row.original.permissions!.includes(Permissions.RenewSubscriptions) && (
            <Actions.Renew
              title={t('managePackages.tooltip.renew')}
              onClick={() => onRenew(row?.original as IPackageInfo)}
            />
          ),
        footer: (props) => props.column.id,
      },
    ],
    [t, jurCode]
  )

  const additionalColumns = useMemo<ColumnDef<IPackageInfoGroupedView>[]>(
    () => [
      {
        id: 'expand',
        accessorFn: (row) => row.subscriptionDetails,
        enableSorting: false,
        header: '',
        size: 48,
        cell: ({ row, getValue }) =>
          getValue() &&
          row.getCanExpand() && (
            <CustomArrowTooltip
              placement='top'
              title={t(row.getIsExpanded() ? 'tooltips.collapse' : 'tooltips.expand')}
            >
              <IconButton onClick={row.getToggleExpandedHandler()} sx={{ p: 0 }}>
                <CaretRightIcon sx={{ transform: row.getIsExpanded() ? 'rotate(90deg)' : 'none' }} />
              </IconButton>
            </CustomArrowTooltip>
          ),
        footer: (props) => props.column.id,
      },
      {
        size: 50,
        accessorKey: 'companyName',
        header: () => t('manageProduct.subscriptions.company'),
        cell: (info) => <OverflowTooltip text={info.getValue() as string} />,
        footer: (props) => props.column.id,
      },
      {
        size: 45,
        accessorKey: 'packageName',
        accessorFn: (row) => row.packageName,
        header: () => t('manageProduct.subscriptionDetails.package'),
        cell: (info) => <OverflowTooltip text={info.getValue() as string} />,
        footer: (props) => props.column.id,
      },
      {
        size: 40,
        id: 'status',
        accessorFn: (row) => row,
        header: () => t('manageProduct.subscriptionDetails.status'),
        cell: SubscriptionStatus,
        footer: (props) => props.column.id,
      },
      {
        size: 40,
        id: 'licenseQuantity',
        accessorFn: (row) => row.licenseQuantity,
        header: () => t('manageProduct.subscriptionDetails.licenses'),
        cell: (info) => {
          return info.getValue()
        },
        footer: (props) => props.column.id,
      },
      {
        size: 50,
        id: 'duration',
        accessorFn: (row) => row,
        header: () => t('manageProduct.subscriptionDetails.term'),
        cell: Duration,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        size: 100,
        id: 'submittedDate',
        accessorFn: (row) => row,
        header: () => t('manageProduct.subscriptions.submissionDate'),
        cell: SubmittedDate,
        footer: (props) => props.column.id,
      },
      {
        size: 100,
        id: 'paymentType',
        accessorFn: (row) => row.paymentType,
        header: () => t('manageProduct.subscriptionDetails.paymentType'),
        cell: (info) => {
          const paymentType = info.getValue() as PaymentTypeSubscription
          return t(paymentTypeLabels[paymentType]) || null
        },
        footer: (props) => props.column.id,
      },
      {
        id: 'renew',
        accessorFn: (row) => row?.subscriptionDetails,
        enableSorting: false,
        header: '',
        size: 54,
        cell: ({ row }) =>
          (row?.original as IPackageInfoGroupedView)?.permissions.includes(Permissions.RenewSubscriptions) && <></>,
        footer: (props) => props.column.id,
      },
    ],
    [t, jurCode]
  )

  const isProdGroupTypeAll = prodGroupSelected?.type === ProductGroupType.All

  useEffect(() => {
    // if a user refreshes page
    if (jurCode && productId) {
      getSubscriptionInfo(productId, jurCode)
      const initialSort = {
        id: column ?? 'submittedDate',
        desc: direction ? direction === 'desc' : true,
      }
      setSorting([initialSort])
      searchNavigate({ column: initialSort.id, direction: initialSort.desc ? 'desc' : 'asc' })
    }
  }, [getSubscriptionInfo, jurCode, productId])

  type TFormDataType = typeof isProdGroupTypeAll extends true ? IPackageInfoGroupedView : IPackageInfo

  return subscriptionList.length ? (
    <>
      <CustomExpandTable<TFormDataType>
        columns={(isProdGroupTypeAll ? additionalColumns : columns) as ColumnDef<TFormDataType>[]}
        data={subscriptionList}
        state={{
          sorting,
          columnVisibility: {
            valuations: hasValuations,
          },
        }}
        handleSort={handleSort}
        getRowCanExpand={(row) =>
          isProdGroupTypeAll
            ? (row.original as IPackageInfoGroupedView).subscriptionDetails !== undefined &&
              !!(row.original as IPackageInfoGroupedView).subscriptionDetails
            : !!(row.original as IPackageInfo).packageDetails
        }
        actions={isProdGroupTypeAll ? undefined : (data) => <ManagePackageActions packageInfo={data as IPackageInfo} />}
        details={
          isProdGroupTypeAll
            ? undefined
            : (data) => <PackageDetails packageDetails={data.packageDetails as IPackageDetails} />
        }
        total={total}
        rowKey={isProdGroupTypeAll ? 'subscriptionDetails' : 'packageDetails'}
      />
    </>
  ) : (
    <NoSubscriptions />
  )
}
