import { generateDigitalSignature } from 'features/subscriptionRequest/services/subscription'
import { ZuoraResponse } from 'features/subscriptionRequest/constants/payment'
import { Dispatch } from 'react'
import { AnyAction } from 'redux'
import { setZuoraRefIds } from 'features/subscriptionRequest/slice'

const zuoraApi = window.Z

export class ZuoraService {
  private productId = ''
  private dispatch: Dispatch<AnyAction> | null = null

  init(productId: string, currencyId: number, jurCode: string, dispatch: Dispatch<AnyAction>): void {
    this.dispatch = dispatch
    this.productId = productId

    const iframe = document.getElementById('zuora_payment')

    const errorMessageCallback = (key: string, _: any, message: string) => {
      console.log(key, message)
    }

    const zuoraCallback = (response: ZuoraResponse) => {
      if (response.success) {
        this.dispatch!(setZuoraRefIds({ [this.productId]: response.refId }))
      }
    }

    if (iframe) {
      iframe.innerHTML = ''
    }

    generateDigitalSignature(this.productId, currencyId, jurCode).then((digitalSignature) => {
      const params = {
        tenantId: digitalSignature.tenantId,
        id: digitalSignature.pageId,
        token: digitalSignature.token,
        signature: digitalSignature.signature,
        key: digitalSignature.key,
        locale: digitalSignature.locale,
        url: digitalSignature.uriPublicHosted,
        submitEnabled: 'true',
        style: 'inline',
      }
      zuoraApi.renderWithErrorHandler(params, {}, zuoraCallback, errorMessageCallback)
    })
  }

  submit(): void {
    zuoraApi.submit()
  }
}

export const zuoraService = new ZuoraService()
