import { ChangeEvent, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import FooterForm from 'features/manageAdmins/components/footerForm'
import HeaderForm from 'features/manageAdmins/components/headerForm'
import { inactivateSubscription } from 'features/managePackage/services'
import { setSelectedPackage } from 'features/managePackage/slice'
import { useGetSubscriptions } from 'features/managePackage/hooks/useGetSubscriptions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { CustomTextField } from 'components/CustomTextField'
import { WarningIcon } from 'components/icons/Warning.icon'
import { handleInactivateSubModal } from 'sharedSlices/ui'
import { showMessage } from 'utils/errorMessageHandler'
import colors from 'theme/colors'

const MAX_LENGTH = 250

export const InactivateSubForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { getSubscriptionInfo } = useGetSubscriptions()

  const { selectedPackage } = useAppSelector((state) => state.managePackage)
  const jurCode = searchParams.get('code')

  const [comment, setComment] = useState('')

  const onClose = () => {
    dispatch(setSelectedPackage(undefined))
    dispatch(handleInactivateSubModal(false))
  }

  const onInactivateSub = () => {
    if (selectedPackage && jurCode) {
      const payload = { note: comment, subscriptionPackageIds: [selectedPackage.subscriptionPackageId] }
      inactivateSubscription(selectedPackage.subscriptionId, jurCode, selectedPackage.productId, payload)
        .then(() => {
          getSubscriptionInfo(selectedPackage.productId, jurCode)
          showMessage.success('mySnackbar', 'manageProduct.subscriptionMessage.success')
          dispatch(handleInactivateSubModal(false))
        })
        .catch(() => showMessage.error('mySnackbar', 'manageProduct.subscriptionMessage.error'))
    }
  }

  const handleComment = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // Refactor
    const message = value.slice(0, MAX_LENGTH)
    setComment((prevState) => {
      const isCommentAtMaxLength = prevState.length === MAX_LENGTH
      const isMessageShorterThanMax = message.length < MAX_LENGTH
      if (isCommentAtMaxLength && isMessageShorterThanMax) return message
      if (!isCommentAtMaxLength) return message
      return prevState
    })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <HeaderForm
        title='managePackages.modals.inactivate.title'
        icon={<WarningIcon sx={{ mr: '6px' }} />}
        onClose={onClose}
      />
      <Box sx={{ p: '16px' }}>
        <Typography variant='body2' sx={{ lineHeight: '24px' }}>
          {t('managePackages.modals.inactivate.message')}
        </Typography>
        <Typography  variant='body2' sx={{ m: '8px 0', fontFamily: 'Open Sans Semibold', lineHeight: '24px' }}>
          {selectedPackage?.packageName}
        </Typography>
        <CustomTextField
          placeholder={t('managePackages.modals.enterComment')}
          label={t('managePackages.modals.comment')}
          value={comment}
          multiline
          sx={{ '& textarea': { minHeight: '92px' } }}
          onChange={handleComment}
        />
        <Typography
          sx={{ textAlign: 'right', color: colors.grey[800], lineHeight: '16px', mt: '8px' }}
          variant='caption'
          component={Box}
        >
          {comment.length}/{MAX_LENGTH}
        </Typography>
      </Box>
      <FooterForm>
        <FooterForm.Button text='button.no' variant='outlined' onClick={onClose} />
        <FooterForm.Button
          text='button.yes'
          disabled={!comment.trim().length}
          onClick={onInactivateSub}
        />
      </FooterForm>
    </Box>
  )
}
