import { SvgIcon, SvgIconProps } from '@mui/material'

export const OptionsIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          clipRule='evenodd'
          fillRule='evenodd'
          d='M5.5 8C5.78 8 6 8.2199 6 8.4999V13.4999C6 13.78 5.78 14 5.5 
        14H0.5C0.220002 14 0 13.7799 0 13.4999V8.4999C0 8.2199 0.220002 
        8 0.5 8H5.5ZM13.5 8C13.78 8 14 8.2199 14 8.4999V13.4999C14 13.78 13.78 
        14 13.5 14H8.5C8.22 14 8 13.78 8 13.4999V8.4999C8 8.2199 8.22 8 8.5 8H13.5ZM5 
        9H1V13H5V9ZM13 9H9V13H13V9ZM5.5 0C5.78 0 6 0.219896 6 0.5V5.4999C6 
        5.7799 5.78 6 5.5 6H0.5C0.220002 6 2.41585e-06 5.7799 2.41585e-06 
        5.4999L0 0.5C0 0.219896 0.220002 0 0.5 0H5.5ZM13.5 0C13.78 0 
        14 0.219896 14 0.5L14 5.4999C14 5.78 13.78 6 13.5 6H8.5C8.22 6 
        8 5.78 8 5.4999L8 0.5C8 0.219896 8.22 0 8.5 0H13.5ZM5 1H1V5H5V1ZM13 1H9V5H13V1Z'
        />
      </SvgIcon>
    </>
  )
}
