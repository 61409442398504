import { Box, CircularProgress, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  sx: SxProps
  message: string
  textColor: string
}

const getAnimation = ({ color }: { color: string }): SxProps => ({
  color,
  clipPath: 'inset(0 1ch 0 0)',
  animation: 'l 2s steps(4) infinite',
  ml: '8px',
  '@keyframes l': {
    to: {
      clipPath: 'inset(0 -1ch 0 0)',
    },
  },
})

export const CustomLoading = ({ sx, textColor, message }: Props) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={
        sx || {
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          zIndex: 99,
        }
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <CircularProgress data-testid='loading' size={24} />
        <Typography variant='body2' sx={getAnimation({ color: textColor })}>
          {t(message)}...
        </Typography>
      </Box>
    </Box>
  )
}
