import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  IJurisdiction,
  IMyReport,
  IProductGroup,
  IReport,
  IReportType,
  REQUEST_REPORT_PAGINATION,
} from 'features/manageReports/constants'
import { AxiosError } from 'axios'
import { ITableData } from 'components/CustomTable'
import { IReportPayload } from 'features/manageReports/constants'
import { geoApi } from 'services/api/geoApi'
import { getUniqueById } from 'utils/getUniqueById'

export const fetchReportTypes = createAsyncThunk<IReportType[], void, { rejectValue: AxiosError }>(
  'manageReports/fetchReportTypes',
  async (_, { rejectWithValue }) => {
    try {
      const reportTypesList = await geoApi.getAllGeo<IReportType[]>('global/report/type')
      return getUniqueById(reportTypesList.flat()).sort((a, b) => a.name.localeCompare(b.name))
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchJurisdictions = createAsyncThunk<IJurisdiction[], string[], { rejectValue: AxiosError }>(
  'manageReports/fetchJurisdictions',
  async (productGroupIds, { rejectWithValue }) => {
    try {
      return await geoApi.postAllGeo('global/report/jurisdiction', { productGroupIds })
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchProductGroups = createAsyncThunk<IProductGroup[], string[], { rejectValue: AxiosError }>(
  'manageReports/fetchProductGroups',
  async (jurisdictionIds, { rejectWithValue }) => {
    try {
      return await geoApi.postAllGeo('global/report/productGroup', { jurisdictionIds })
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const fetchMyReports = createAsyncThunk<ITableData<IMyReport>[], void, { rejectValue: AxiosError }>(
  'manageReports/fetchMyReports',
  async (_, { rejectWithValue }) => {
    try {
      return await geoApi.postAllGeo('global/report/search', { pagination: REQUEST_REPORT_PAGINATION })
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const deleteMyReport = createAsyncThunk<string, { id: string; jurCode: string }, { rejectValue: AxiosError }>(
  'manageReports/deleteMyReports',
  async ({ id, jurCode }, { rejectWithValue }) => {
    try {
      return await geoApi.withJurisdictionCode(jurCode).delete(`global/report/${id}`)
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const generateReport = createAsyncThunk<
  { result: any[]; total: number },
  IReportPayload,
  { rejectValue: AxiosError }
>('manageReports/generateReport', async (payload, { rejectWithValue }) => {
  try {
    const jurCode = geoApi.jurMapping[payload.jurisdictionIds![0]].code
    return await geoApi.withJurisdictionCode(jurCode).post('global/report/generate', payload)
  } catch (err) {
    return rejectWithValue(err as AxiosError)
  }
})

export const updateReport = (reportId: string, payload: IReportPayload): Promise<void> => {
  const jurCode = geoApi.jurMapping[payload.jurisdictionIds[0]].code
  return geoApi.withJurisdictionCode(jurCode).post(`global/report/update`, { ...payload, id: reportId })
}

export const createReport = (payload: IReportPayload): Promise<void> => {
  const jurCode = geoApi.jurMapping[payload.jurisdictionIds![0]].code
  return geoApi.withJurisdictionCode(jurCode).post(`global/report`, payload)
}

export const getReport = (id: string, jurCode: string): Promise<IReport> => {
  return geoApi.withJurisdictionCode(jurCode).get(`global/report/${id}`)
}

export const downloadCSVFile = (report: IMyReport) => {
  const jurCode = geoApi.jurMapping[report.jurisdictionIds[0]].code
  return geoApi
    .withJurisdictionCode(jurCode)
    .getWithHeaders(`global/Report/export?reportId=${report.id}`)
    .then((response) => {
      const blob = new Blob([response.data], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url

      if (response.headers['content-disposition']) {
        const filenameRegex = /filename=["']?([^;"']+)/
        const extractedFilenameMatch = response.headers['content-disposition'].match(filenameRegex)
        if (extractedFilenameMatch && extractedFilenameMatch[1]) {
          link.download = extractedFilenameMatch[1]
        }
      } else {
        link.download = 'report.csv'
      }

      link.click()
      window.URL.revokeObjectURL(url)
    })
    .catch((error) => {
      console.error('Error downloading CSV:', error)
    })
}
