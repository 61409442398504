import { createTheme } from '@mui/material'
import setCustomButton from './customButtons'
import colors from './colors'

// the structure is declared in global.d.ts

export const theme = createTheme({
  palette: {
    common: {
      black: colors.black.main,
      white: colors.white,
    },
    primary: {
      main: colors.blue.main,
      '100': colors.blue[100],
      '200': colors.blue[200],
      '300': colors.blue[300],
      '400': colors.blue[400],
      '500': colors.blue[500],
      '600': colors.blue[600],
      '700': colors.blue[700],
    },
    success: {
      main: colors.green.main,
      '100': colors.green[100],
      '200': colors.green[200],
      '300': colors.green[300],
      '400': colors.green[400],
      '500': colors.green[500],
      '600': colors.green[600],
      '700': colors.green[700],
    },
    secondary: {
      main: colors.teal.main,
      '100': colors.teal[100],
      '200': colors.teal[200],
      '300': colors.teal[300],
      '400': colors.teal[400],
      '500': colors.teal[500],
      '600': colors.teal[600],
    },
    grey: {
      '100': colors.grey[100],
      '200': colors.grey[200],
      '300': colors.grey[300],
      '400': colors.grey[400],
      '500': colors.grey[500],
      '600': colors.grey[600],
      '700': colors.grey[700],
      '800': colors.grey[800],
    },
    error: {
      main: colors.red,
    },
    warning: {
      main: colors.yellow.main,
      '100': colors.yellow[100],
      '200': colors.yellow[200],
    },
    border: {
      main: colors.border,
    },
    menuItemHover: {
      main: colors.menuItemHover,
    },
  },
  fontWeight: {
    400: '400',
    500: '500',
    600: '600',
    700: '700',
  },
  borderRadius: {
    none: '0px',
    xs: '2px',
    sm: '5px',
    m: '7px',
    l: '10px',
    xl: '12px',
  },
  typography: { fontFamily: 'Open Sans, sans-serif' },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        * {
          padding: 0;
          margin: 0;
          box-sizing: border-box;
        }
        body {
          font-family: 'Open Sans SemiBold', sans-serif;

        }
      `,
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: 'Open Sans SemiBold, sans-serif',
          borderRadius: '2px',
          padding: '6px 16px',
          ...setCustomButton(ownerState.variant || '', ownerState.color || ''),
        }),
      },
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.mineShaft,
          fontSize: '12px',
        },
        arrow: {
          color: colors.mineShaft,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          fontSize: '14px',
          lineHeight: '22px',
          textTransform: 'capitalize',
        },
      },
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTypography: {
      styleOverrides: {
        h2: {
          fontSize: '21px',
        },
        h3: {
          fontSize: '18px',
        },
        h4: {
          fontSize: '16px',
        },
        subtitle2: {
          fontSize: '14px',
          fontWeight: 400,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          '&::before, &::after': {
            borderColor: colors.border,
          },
          '.MuiDivider-wrapper': {
            paddingLeft: '12px',
            paddingRight: '12px',
          },
        },
        textAlignLeft: {
          '&::before': {
            display: 'none',
          },
          '&::after': {
            width: '100%',
          },
          '.MuiDivider-wrapper': {
            paddingLeft: 0,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          backgroundColor: colors.quillGray,
          '& .MuiLinearProgress-barColorSuccess': {
            backgroundColor: colors.green[600],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          margin: '2px',
          height: '27px',
          fontSize: '12px',
          fontWeight: '600',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '2px',
            padding: 0,
          },
          '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: colors.black[6],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&&& input': {
            padding: '8px',
            fontSize: '14px',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          position: 'absolute',
          top: 'auto',
          right: 0,
          display: 'flex',
          justifyContent: 'center',
          marginRight: '8px',
          transition: 'transform 200ms ease-in-out',
          transformOrigin: 'center',
        },
        iconOpen: {
          transform: 'rotate(180deg)',
        },
        select: {
          fontSize: '14px',
          padding: '8px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '8px',
          lineHeight: '24px',
          '&.Mui-selected': {
            backgroundColor: colors.lightBlue,
            color: colors.blue.main,
            fontWeight: '600',
          },

          '&:hover': {
            backgroundColor: colors.bgDisabled,
          },
          fontSize: '14px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorAction: {
          color: colors.black.main,
        },
        colorSecondary: {
          color: colors.white,
        },
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { variant: 'tip' },
          style: {
            backgroundColor: `${colors.lightGreen}`,
            color: colors.intenseGreen,
          },
        },
      ],
      styleOverrides: {
        standardSuccess: {
          color: colors.green.main,
        },
        standardError: {
          backgroundColor: colors.wispPink,
          color: colors.beautyBush,
        },
        standardWarning: {
          backgroundColor: colors.islandSpice,
          color: colors.astra,
        },
        standardInfo: {
          backgroundColor: colors.foam,
          color: colors.mintTulip,
        },
      },
    },
  },
})
