import { SvgIcon, SvgIconProps } from '@mui/material'

export const ApproveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 4C16.4183 4 20 7.58172 20 12C20 16.4183
        16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172
        7.58172 4 12 4ZM12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401
        19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM10.9809
        13.7738L15.1284 9.16552C15.3131 8.96026 15.6292 8.94362 15.8345
        9.12835C16.0169 9.29256 16.0504 9.5606 15.9258 9.76229L15.8716
        9.83448L11.3716 14.8345C11.2013 15.0238 10.9208 15.0517 10.7184
        14.9133L10.6464 14.8536L8.64645 12.8536C8.45118 12.6583 8.45118
        12.3417 8.64645 12.1464C8.82001 11.9729 9.08944 11.9536 9.28431
        12.0886L9.35355 12.1464L10.9809 13.7738Z'
      />
    </SvgIcon>
  )
}
