import { Configuration, RedirectRequest, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser'
import { LogLevel } from '@azure/msal-browser'

export const configuration: Configuration = {
  auth: {
    authority: `${window.APP_CONFIG.AUTHORITY_URL}/${window.APP_CONFIG.AAD_TENANT_ID}`,
    clientId: `${window.APP_CONFIG.AAD_CLIENT_ID}`,
    redirectUri: `${window.location.protocol}//${window.location.host}`,
  },
  cache: {
    storeAuthStateInCookie: true,
    cacheLocation: BrowserCacheLocation.LocalStorage
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      },
    },
  },
}

export const loginRequest: RedirectRequest = {
  scopes: ['User.Read'],
}

export const pca = new PublicClientApplication(configuration)
