import { Box } from '@mui/material'
import { CardEmail } from 'features/manageAdmins/components/cardEmail'
import { ReactNode } from 'react'

interface Props {
  icon: ReactNode
  user: {
    firstName: string
    lastName: string
    email: string
    id: string
  }
}

export const UserCard = ({ icon, user }: Props) => {
  const { firstName, lastName, email, id } = user
  return (
    <Box sx={{ mt: '8px', width: '100%' }} key={id}>
      <CardEmail name={firstName} lastName={lastName} value={email}>
        {icon}
      </CardEmail>
    </Box>
  )
}
