import { APP_CONFIG } from 'app/appConfig'
import axios, { AxiosInstance } from 'axios'
import { getToken } from 'services/auth/getToken'

interface GeoMapping {
  code: string
  geoCode: string
  id: string
  name: string
}

type JurisdictionMapping = Record<string, GeoMapping>

const baseApiUrl: Record<string, string> =
  window.location.hostname === 'localhost' ? APP_CONFIG.development.geoApiUrls : APP_CONFIG.production.geoApiUrls

// Temporary filtering while environments are being configured
const geoApiUrls = Array.from(new Set(Object.values(baseApiUrl)))

interface GeoMapping {
  code: string
  geoCode: string
  id: string
  name: string
}

class ApiClient {
  private readonly httpClient: AxiosInstance
  private static _geoMapping: Record<string, string>
  private static _jurMapping: JurisdictionMapping

  public get geoMapping(): Record<string, string> {
    return ApiClient._geoMapping
  }

  public get jurMapping(): JurisdictionMapping {
    return ApiClient._jurMapping
  }

  constructor() {
    this.httpClient = axios.create()

    this.httpClient.interceptors.request.use(
      async (config) => {
        const token = await getToken()
        const params = new URLSearchParams(window.location.search)
        const productId = params.get('productId')

        if (token) {
          config.headers!['Authorization'] = `Bearer ${token}`
        }

        if (productId && config.headers && !config.headers['x-product-id']) {
          config.headers!['x-product-id'] = productId
        }

        config.headers!['x-locale-code'] = localStorage.getItem('i18nextLng')

        return config
      },
      (error) => Promise.reject(error)
    )
  }

  private generateURl(url: string, jurCode: string): string {
    let baseUrl = baseApiUrl.EMA

    if (this.geoMapping && jurCode) {
      baseUrl = baseApiUrl[this.geoMapping[jurCode]]
    }

    return baseUrl + url
  }

  /**
   * The main purpose of this function, before calling any request, first set the jurisdiction to correctly get the URL
   * @param jurCode is jurisdiction abbreviation
   * @returns
   *   - get: method to perform GET requests with generic type T for the response data
   *   - post: method to perform POST requests with generic type T for the response data
   *   - put: method to perform PUT requests with generic type T for the response data
   *   - delete: method to perform DELETE requests with generic type T for the response data
   **/
  public withJurisdictionCode(jurCode: string) {
    const generateUrl = this.generateURl.bind(this)
    const httpClient = this.httpClient

    return {
      get: <T>(url: string, headers?: RequestHeaders): Promise<T> =>
        httpClient.get(generateUrl(url, jurCode), headers).then((response) => response.data),
      post: <T, D = any>(url: string, data?: D, headers?: RequestHeaders): Promise<T> =>
        httpClient.post(generateUrl(url, jurCode), data, headers).then((response) => response.data),
      put: <T, D = any>(url: string, data?: D, headers?: RequestHeaders): Promise<T> =>
        httpClient.put(generateUrl(url, jurCode), data, headers).then((response) => response.data),
      patch: <T, D = any>(url: string, payload: D, headers?: RequestHeaders): Promise<T> =>
        httpClient.patch(generateUrl(url, jurCode), payload, headers).then((response) => response.data),
      delete: <T>(url: string, headers?: RequestHeaders): Promise<T> =>
        httpClient.delete(generateUrl(url, jurCode), headers).then((response) => response.data),
      getWithHeaders: <T>(url: string, headers?: RequestHeaders) => httpClient.get(generateUrl(url, jurCode), headers),
    }
  }

  /**
   * @param geoMapping is an object where the key is the abbreviation of the jurisdiction and the value is the geocode.
   */
  private setGeoMapping(geoMapping: Record<string, string>): void {
    ApiClient._geoMapping = geoMapping
  }

  /**
   * @param jurMapping is an object where the key is jurisdiction id and the value is jurisdiction with geocode.
   */
  private setJurMapping(jurMapping: JurisdictionMapping): void {
    ApiClient._jurMapping = jurMapping
  }

  public getUniqueGeoJurCodes = (jurCodeList: string[]) => {
    const _jurCodes = jurCodeList.reduce((jurCodes, jurCode) => {
      const geoCode = this.geoMapping[jurCode]
      if (!jurCodes[geoCode]) {
        jurCodes[geoCode] = jurCode
      }
      return jurCodes
    }, {} as { [key: string]: string })
    return Object.values(_jurCodes)
  }

  public async getGeoMapping() {
    const response = await this.getAllGeo<GeoMapping[]>('jurisdictions/geo/mappings')
    const jurMapping = {} as JurisdictionMapping
    const geoMapping = response.flat().reduce((acc, curr) => {
      jurMapping[curr.id] = curr
      return Object.assign(acc, { [curr.code]: curr.geoCode })
    }, {} as { [key: string]: string })
    this.setGeoMapping(geoMapping)
    this.setJurMapping(jurMapping)
  }

  public async getAllGeo<T>(url: string, headers?: RequestHeaders): Promise<T[]> {
    const responses = await Promise.all(geoApiUrls.map((geoApiUrl) => this.httpClient.get(geoApiUrl + url, headers)))
    return responses.filter((resp) => resp.status !== 204).map((response) => response.data)
  }

  public async postAllGeo<T, D = any>(url: string, data?: D, headers?: RequestHeaders): Promise<T[]> {
    const responses = await Promise.all(
      geoApiUrls.map((geoApiUrl) => this.httpClient.post<T>(geoApiUrl + url, data, headers))
    )
    return responses.filter((resp) => resp.status !== 204).map((response) => response.data)
  }

  public deleteAllGeo<T>(url: string, headers?: RequestHeaders): Promise<T[]> {
    return Promise.all(
      geoApiUrls.map((geoApiUrl) => this.httpClient.delete(geoApiUrl + url, headers).then((response) => response.data))
    )
  }

  public async patchAllGeo<T, D = any>(url: string, data?: D, headers?: RequestHeaders): Promise<T[]> {
    const responses = await Promise.all(
      geoApiUrls.map((geoApiUrl) => this.httpClient.patch<T>(geoApiUrl + url, data, headers))
    )
    return responses.map((response) => response.data)
  }

  public async getSpecificGeo<T>(url: string, jurCodes: string[], headers?: RequestHeaders): Promise<T[]> {
    const responses = await Promise.all(
      this.getUniqueGeoJurCodes(jurCodes).map((jurCode) =>
        this.httpClient.get<T>(this.generateURl(url, jurCode), headers)
      )
    )
    return responses.map((response) => response.data)
  }

  public async postSpecificGeos<T, D = any>(
    url: string,
    jurCodes: string[],
    data?: D,
    headers?: RequestHeaders
  ): Promise<T[]> {
    const responses = await Promise.all(
      this.getUniqueGeoJurCodes(jurCodes).map((jurCode) =>
        this.httpClient.post<T>(this.generateURl(url, jurCode), data, headers)
      )
    )
    return responses.map((response) => response.data)
  }
}

export const geoApi = new ApiClient()
