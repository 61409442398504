import { FC } from 'react'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomModal from 'components/CustomModal'
import { WarningIcon } from 'components/icons/Warning.icon'
import { CloseIcon } from 'components/icons/Close.icon'

interface Props {
  onClose: () => void
  onSubmit: () => void
  open: boolean
}

export const ChangeRolePopup: FC<Props> = ({
  onClose,
  onSubmit,
  open,
}) => {
  const { t } = useTranslation()
  return (
    <CustomModal width='480px' open={open} onClose={onClose}>
      <CustomModal.Header>
        <Box
          sx={{
            marginRight: '8px',
            display: 'flex',
          }}
        >
          <WarningIcon />
        </Box>
        <Box sx={{ marginRight: 'auto' }}>{t('changeRolePopup.header')}</Box>
        <Button
          sx={{
            minHeight: '24px',
            minWidth: '24px',
            padding: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon/>
        </Button>
      </CustomModal.Header>
      <CustomModal.Body
        style={{
          fontFamily: 'Open Sans',
          fontSize: 14,
          padding: '16px',
        }}
      >
        <Box>{t('changeRolePopup.message')}</Box>
      </CustomModal.Body>
      <CustomModal.Footer
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          padding: '16px',
        }}
      >
        <Button variant='outlined' onClick={onClose}>
          {t('button.no')}
        </Button>
        <Button
          sx={{
            marginLeft: '16px',
          }}
          variant='contained'
          onClick={onSubmit}
        >
          {t('button.yes')}
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  )
}
