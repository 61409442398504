import { SvgIcon, SvgIconProps } from '@mui/material'

export const ExpandAllIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4101 13.0081L12.5 13C12.7455 13 12.9496 13.1769 12.9919 13.4101L13 13.5V19.292L15.6464 16.6464C15.8417
        16.4512 16.1583 16.4512 16.3536 16.6464C16.5488 16.8417 16.5488 17.1583 16.3536 17.3536L12.8536 20.8536L12.8117
        20.891L12.7498 20.9333L12.6906 20.9624L12.6281 20.9834L12.5463 20.9979L12.4796 20.9996L12.4112 20.9921L12.309
        20.9622L12.233 20.9228L12.1779 20.8824L8.64645 17.3536C8.45118 17.1583 8.45118 16.8417 8.64645 16.6464C8.84171
        16.4512 9.15829 16.4512 9.35355 16.6464L12 19.292V13.5C12 13.2545 12.1769 13.0504 12.4101 13.0081ZM12.4261
        3.00546L12.4577 3.00178L12.5428 3.00182L12.6281 3.01661L12.691 3.03779L12.767 3.07719L12.8221 3.11759L16.3536
        6.64645C16.5488 6.84171 16.5488 7.15829 16.3536 7.35355C16.18 7.52712 15.9106 7.5464 15.7157 7.41141L15.6464
        7.35355L13 4.707V10.5C13 10.7455 12.8231 10.9496 12.5899 10.9919L12.5 11C12.2545 11 12.0504 10.8231 12.0081
        10.5899L12 10.5V4.707L9.35355 7.35355C9.17999 7.52712 8.91056 7.5464 8.71569 7.41141L8.64645 7.35355C8.47288
        7.17999 8.4536 6.91056 8.58859 6.71569L8.64645 6.64645L12.1779 3.11758L12.2153 3.08884L12.2886 3.04674L12.3719
        3.01661L12.395 3.01109C12.4287 3.00383 12.4639 3 12.5 3L12.4261 3.00546Z'
      />
    </SvgIcon>
  )
}
