import colors from './colors'
import { CSSProperties } from '@mui/material/styles/createMixins'

const setCustomButton = (variant: string, color: string): CSSProperties | undefined => {
  if (color === 'primary') {
    if (variant === 'contained') {
      return {
        '&:hover': {
          backgroundColor: colors.blue[600],
        },
        '&:active': {
          backgroundColor: colors.blue[700],
        },
        '&:disabled': {
          backgroundColor: colors.bgDisabled,
        },
      }
    }
    if (variant === 'outlined' || variant === 'text') {
      return {
        borderColor: colors.blue.main,
        '&:hover': {
          backgroundColor: colors.bgDisabled,
        },
        '&:active': {
          backgroundColor: colors.border,
        },
      }
    }
  }
}

export type ColorButtonType =
  | 'primary'
  | 'inherit'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning'

export default setCustomButton
