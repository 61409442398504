import { FormikErrors, FormikTouched } from 'formik'
import { validationFieldMessages } from './validation'

export const createErrorsData = <T>(errors: FormikErrors<T>, touched: FormikTouched<T>) => {
  return Object.keys(touched).reduce((acc, key) => {
    if (errors[key as keyof T]) {
      acc[key as keyof T] = validationFieldMessages[key as keyof typeof validationFieldMessages]
    }
    return acc
  }, {} as Record<keyof T, string>)
}

export const tarnsformFieldsToTouchedFormat = <T>(errors: FormikErrors<T>) => {
  const errorFields = Object.keys(errors)
  return errorFields.reduce((acc, field) => {
    acc[field] = true
    return acc
  }, {} as Record<string, boolean>)
}
