import { geoApi } from 'services/api/geoApi'

export const getIsTermsAccepted = (productId: string, termsLinkId: string, jurCode: string) => {
  return geoApi
    .withJurisdictionCode(jurCode)
    .get<{ isPolicyAccepted: boolean; isPolicyUpdated: boolean }>(
      `/product/${productId}/isTermsAccepted/${termsLinkId}123`
    )
}

export const acceptTerms = (productId: string, termsLinkId: string, jurCode: string) => {
  return geoApi
    .withJurisdictionCode(jurCode)
    .post<string, null>(`product/${productId}/acceptTerms/${termsLinkId}123`, null)
}

export const getProductId = (
  productGroupId: string,
  termsLinkId: string
): Promise<string | undefined> => {
  return geoApi
    .getAllGeo<string>(`product/productGroupId/${productGroupId}/termsLinkId/${termsLinkId}`)
    .then((productIds) => {
      const regex = /^0{8}-0{4}-0{4}-0{4}-0{12}$/
      return productIds.find((id) => !regex.test(id))
    })
}
