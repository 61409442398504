import { AxiosError } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { User, UserResponse, UserRoleType } from 'features/manageUsers/constants'

import { geoApi } from 'services/api/geoApi'

export const getUsers = createAsyncThunk<
  User[],
  { jurCode: string; subscriptionId: string },
  { rejectValue: AxiosError }
>('manageUsers/getUsers', async ({ jurCode, subscriptionId }, { rejectWithValue }) => {
  try {
    const userlist = await geoApi
      .withJurisdictionCode(jurCode)
      .get<UserResponse[]>(`subscriptions/${subscriptionId}/users`)
    return userlist.map((user: UserResponse) => ({
      ...user,
      role: UserRoleType[user.role],
      userName: `${user.firstName} ${user.lastName}`,
      selected: false,
    }))
  } catch (err) {
    return rejectWithValue(err as AxiosError)
  }
})
