import { Box, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Form, FormikProvider } from 'formik'
import HeaderForm from 'features/manageAdmins/components/headerForm'
import FooterForm from 'features/manageAdmins/components/footerForm'
import CustomForm from 'components/CustomForm'
import { useEditPrimaryUser } from 'features/manageUsers/hooks/useEditPrimaryUser'
import { UserCard } from '../userCard'
import { CheckThinIcon } from 'components/icons/CheckThin.icon'
import colors from 'theme/colors'

export const EditPrimaryUserForm = () => {
  const {
    handleUserChange,
    handleCloseModal,
    formikProps,
    primaryUser,
    isConfirmButtonDisabled,
    userList,
  } = useEditPrimaryUser()
  const { t } = useTranslation()
  const { isValid, dirty } = formikProps
  return (
    <FormikProvider value={formikProps}>
      <Form>
        <HeaderForm onClose={handleCloseModal} title='manageProduct.users.editPrimaryUser' />
        <Box sx={{ maxHeight: '473px', flexGrow: 1, overflow: 'auto', p: ' 16px 0' }}>
          <CustomForm>
            <Box sx={{ maxHeight: '473px', flexGrow: 1, overflow: 'auto', p: '0 16px' }}>
              <CustomForm.Label value='manageProduct.users.primaryUser' />
              <CustomForm.Autocomplete
                id={'employeeIds'}
                options={userList}
                defaultValue={primaryUser}
                onChange={handleUserChange}
                getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
                renderOption={(props, user, { selected }) => (
                  <Box component='span' {...props} key={user.id}>
                    <UserCard
                      user={user}
                      icon={selected ? <CheckThinIcon sx={{ color: colors.blue.main }} /> : null}
                    />
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ p: 0, m: 0, mt: '8px', mb: '16px' }}
                    fullWidth
                    name='employeeIds'
                    placeholder={t('manageProduct.users.email')!}
                  />
                )}
              />
            </Box>
          </CustomForm>
        </Box>
        <FooterForm>
          <FooterForm.Button
            onClose={handleCloseModal}
            variant='outlined'
            text='manageProduct.users.cancel'
          />
          <FooterForm.Button
            type='submit'
            disabled={isConfirmButtonDisabled || !isValid || !dirty}
            text='manageProduct.users.confirm'
          />
        </FooterForm>
      </Form>
    </FormikProvider>
  )
}
