import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Collapse,
  IconButton,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { CustomArrowTooltip } from 'components/CustomTooltip'
import CaretRightIcon from 'assets/images/CaretRight.svg'
import CaretDownIcon from 'assets/images/CaretDown.svg'
import { SortDescIcon } from 'components/icons/SortDesc.icon'
import { SortAscIcon } from 'components/icons/SortAsc.icon'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { MockFooterBlock } from 'components/Footer/mockFooterBlock'
import { useContainerScroll } from 'utils/scrollableFooter'
import colors from 'theme/colors'
import { store } from 'app/store'
import { handleFooter } from 'sharedSlices/ui'
import { ISortingInfo, SortingDirection } from 'constants/sorting'
import { CollapseAllIcon } from 'components/icons/CollapseAll.icon'
export interface IColumnType<T> {
  key: string
  title: string
  sorting?: boolean
  render?: (column: IColumnType<T>, item: T) => void
  className?: string
  columStyle?: SxProps
}

export interface ITableData<T> {
  result: T[]
  total: number
}

export interface IPaginationInfo {
  size: number
  page: number
}

interface Props<T> {
  data: T[]
  sx?: SxProps
  sorting: ISortingInfo
  overflowLayout?: boolean
  columns: IColumnType<T>[]
  checkboxesSelection?: {
    parent: () => JSX.Element
    child: (data: T) => JSX.Element
  }
  actions?: (data: T) => JSX.Element
  onSort: (sortingInfo: ISortingInfo) => void
  rowTemplateCollapse?: (data: T) => JSX.Element
}

interface Row<T> {
  item: T
  countExpandRows: number
  overflowLayout?: boolean
  columns: IColumnType<T>[]
  onCollapseExited?: () => void
  onCollapseEntered?: () => void
  actions?: (data: T) => JSX.Element
  rowTemplateCollapse?: (data: T) => JSX.Element
  checkboxSelectionRow?: (data: T) => JSX.Element
  onCountCollapseRow: (isOpen: boolean | null) => void
  onExitedAndEntered: (node: HTMLElement) => void
  onExitAndEnter: (node: HTMLElement) => void
}

const Row = <T,>({
  item,
  columns,
  countExpandRows,
  overflowLayout,
  actions,
  checkboxSelectionRow,
  onCountCollapseRow,
  rowTemplateCollapse,
  onExitedAndEntered,
  onExitAndEnter,
}: Row<T>) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!countExpandRows && open) {
      setOpen(false)
    }
  }, [countExpandRows, open])

  return (
    <Fragment>
      <TableRow
        sx={{
          '&:hover': {
            backgroundColor: colors.bgDisabled,
            '.options': { visibility: 'visible' },
          },
        }}
      >
        {checkboxSelectionRow && <TableCell>{checkboxSelectionRow(item)}</TableCell>}
        {rowTemplateCollapse && (
          <TableCell sx={{ width: '56px' }}>
            <IconButton
              sx={{
                padding: 0,
              }}
              aria-label='expand row'
              size='small'
              onClick={() => {
                setOpen(!open)
                onCountCollapseRow && onCountCollapseRow(!open)
              }}
            >
              <CustomArrowTooltip placement='top' title={t(open ? 'tooltips.collapse' : 'tooltips.expand')}>
                <Box component='img' src={open ? CaretDownIcon : CaretRightIcon} />
              </CustomArrowTooltip>
            </IconButton>
          </TableCell>
        )}
        {columns.map((column, cIndex) => (
          <TableCell key={cIndex} sx={{ position: 'relative' }}>
            <>
              {column.render ? (
                column.render(column, item)
              ) : overflowLayout ? (
                <OverflowTooltip text={item[column.key as keyof T] as string} />
              ) : (
                (item[column.key as keyof T] as string)
              )}
            </>
          </TableCell>
        ))}
        {actions && (
          <TableCell key={'options'} sx={{ position: 'relative' }}>
            {actions(item)}
          </TableCell>
        )}
      </TableRow>
      {rowTemplateCollapse && (
        <TableRow sx={{ '& > *': { borderBottom: 'unset', padding: 0 } }}>
          <TableCell style={{ padding: 0, borderBottom: 'none' }} colSpan={6}>
            <Collapse
              in={open}
              timeout='auto'
              onEnter={onExitAndEnter}
              onEntered={onExitedAndEntered}
              onExit={onExitAndEnter}
              onExited={onExitedAndEntered}
              unmountOnExit
            >
              {rowTemplateCollapse(item)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  )
}

export const CustomTable = <T,>({
  columns,
  data,
  sorting,
  onSort,
  rowTemplateCollapse,
  overflowLayout,
  actions,
  checkboxesSelection,
}: Props<T>) => {
  const { t } = useTranslation()
  const [countExpandRows, setCountExpandRows] = useState(0)
  const dispatch = useDispatch()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const isSkipScrollEvent = useContainerScroll(containerRef, dispatch)

  const onCountCollapseRow = useCallback(
    (isOpen: boolean | null) => {
      let count = 0
      if (isOpen !== null) {
        count = isOpen ? countExpandRows + 1 : countExpandRows - 1
      }

      setCountExpandRows(count)

      if (isOpen && store.getState().ui.isFooterVisible) {
        const containerElement = containerRef.current
        if (containerElement) {
          const table = containerElement.firstElementChild
          if (table && table.clientHeight > containerElement.clientHeight) {
            dispatch(handleFooter(false))
          }
        }
      }
    },
    [countExpandRows, setCountExpandRows, dispatch]
  )

  const onSortChange = (column: IColumnType<T>, sortingInfo: ISortingInfo) => {
    onSort({
      column: column.key,
      direction:
        sortingInfo.column !== column.key || sortingInfo.direction !== SortingDirection.ASC
          ? SortingDirection.ASC
          : SortingDirection.DESC,
    })
  }

  useEffect(() => {
    onCountCollapseRow(null)
  }, [data, sorting])

  const onExited = useCallback(() => {
    isSkipScrollEvent.current = false
    if (!store.getState().ui.isFooterVisible) {
      const containerElement = containerRef.current
      if (containerElement) {
        if (
          Math.ceil(containerElement.scrollTop + containerElement.clientHeight) + 1 >=
          containerElement.scrollHeight
        ) {
          dispatch(handleFooter(true))
        }
      }
    }
  }, [dispatch, isSkipScrollEvent])

  const onExit = useCallback(() => {
    isSkipScrollEvent.current = true
  }, [isSkipScrollEvent])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
      }}
      className='custom-container'
    >
      <TableContainer className='container-table-container' ref={containerRef}>
        <Table
          sx={{
            tableLayout: overflowLayout ? 'fixed' : 'auto',
            '& th': {
              fontSize: '12px',
              fontWeight: 600,
            },
          }}
        >
          <TableHead>
            <TableRow>
              {checkboxesSelection && <TableCell sx={{ width: '56px' }}>{checkboxesSelection.parent()}</TableCell>}
              {rowTemplateCollapse && (
                <TableCell
                  style={{
                    width: overflowLayout ? '56px' : 'auto',
                  }}
                >
                  {countExpandRows >= 2 && (
                    <CustomArrowTooltip placement='top' title={t('tooltips.collapseAll')}>
                      <IconButton onClick={() => onCountCollapseRow(null)} sx={{ p: 0 }}>
                        <CollapseAllIcon />
                      </IconButton>
                    </CustomArrowTooltip>
                  )}
                </TableCell>
              )}
              {columns.map((column: IColumnType<T>) => (
                <TableCell
                  key={column.key}
                  sx={{
                    cursor: column.sorting ? 'pointer' : 'inherit',
                    ...column.columStyle,
                  }}
                  className={column.className ? column.className : undefined}
                  {...(column.sorting && { onClick: () => onSortChange(column, sorting) })}
                >
                  <Box display='flex'>
                    <OverflowTooltip text={t(column.title)} />
                    {column.sorting &&
                      (sorting.column === column.key && sorting.direction === SortingDirection.ASC ? (
                        <SortAscIcon />
                      ) : (
                        <SortDescIcon
                          sx={{
                            color: sorting.column === column.key ? 'common.black' : colors.silverSand,
                          }}
                        />
                      ))}
                  </Box>
                </TableCell>
              ))}
              {actions && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <Row
                columns={columns}
                countExpandRows={countExpandRows}
                item={item}
                key={`${item['id' as keyof T]}-${index}`}
                onCountCollapseRow={onCountCollapseRow}
                rowTemplateCollapse={rowTemplateCollapse}
                actions={actions}
                overflowLayout={overflowLayout}
                checkboxSelectionRow={checkboxesSelection?.child}
                onExitedAndEntered={onExited}
                onExitAndEnter={onExit}
              />
            ))}
          </TableBody>
        </Table>
        <MockFooterBlock />
      </TableContainer>
    </Box>
  )
}
