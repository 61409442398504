import { ReactNode, FC } from 'react'
import { Box, IconButton, SxProps, Tooltip } from '@mui/material'

import { EditProfileIcon } from 'components/icons/EditProfile.icon'
import { RemoveIcon } from 'components/icons/Remove.icon'
import { DetailsIcon } from 'components/icons/Details.icon'
import { EditIcon } from 'components/icons/Edit.icon'
import { ExportIcon } from 'components/icons/Export.icon'
import { CommentIcon } from 'components/icons/Comment.icon'

const actionsStyle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  position: 'absolute',
  top: 0,
  bottom: 0,
  m: '0 auto',
  right: 0,
  heigth: '100%',
  zIndex: '99',
}

interface IActionsProps {
  title: string
  onClick: () => void
}

interface IRowActions {
  children: JSX.Element | ReactNode | ReactNode[]
}

const Edit: FC<IActionsProps> = ({ title, onClick }) => (
  <Tooltip title={title} placement='top-end' arrow disableInteractive>
    <IconButton onClick={onClick} data-testid='edit-icon' sx={{ p: '0 5px' }}>
      <EditIcon sx={{ color: 'common.white' }} />
    </IconButton>
  </Tooltip>
)

const EditProfile: FC<IActionsProps> = ({ title, onClick }) => (
  <Tooltip title={title} placement='top-end' arrow disableInteractive>
    <IconButton onClick={onClick} data-testid='edit-profile-icon' sx={{ p: '0 5px' }}>
      <EditProfileIcon sx={{ color: 'common.white' }} />
    </IconButton>
  </Tooltip>
)

const Remove: FC<IActionsProps> = ({ title, onClick }) => (
  <Tooltip title={title} placement='top-end' arrow disableInteractive>
    <IconButton onClick={onClick} data-testid='removeicon' sx={{ p: '0 5px' }}>
      <RemoveIcon sx={{ color: 'common.white' }} />
    </IconButton>
  </Tooltip>
)

const Details: FC<IActionsProps> = ({ title, onClick }) => (
  <Tooltip title={title} placement='top-end' arrow disableInteractive>
    <IconButton onClick={onClick} data-testid='detailsicon' sx={{ p: '0 5px' }}>
      <DetailsIcon sx={{ color: 'common.white' }} />
    </IconButton>
  </Tooltip>
)

const Export: FC<IActionsProps> = ({ title, onClick }) => (
  <Tooltip
    placement='top-end'
    title={title}
    arrow
    disableInteractive
  >
    <IconButton sx={{ p: '0 5px' }} onClick={onClick}>
      <ExportIcon />
    </IconButton>
  </Tooltip>
)

const Comment: FC<IActionsProps> = ({ title, onClick }) => (
  <Tooltip title={title} placement='top-end' arrow disableInteractive>
    <IconButton onClick={onClick} data-testid='commenticon' sx={{ p: '0 5px' }}>
      <CommentIcon sx={{ color: 'common.white' }} />
    </IconButton>
  </Tooltip>
)


const RowActions: FC<IRowActions> = ({ children }) => {
  return (
    <Box sx={actionsStyle}>
      <Box
        className='options'
        sx={{
          backgroundColor: 'grey.800',
          borderRadius: '100px 0 0 100px',
          display: 'flex',
          height: '100%',
          visibility: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            m: '0 20px',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default Object.assign(RowActions, {
  Details,
  Edit,
  EditProfile,
  Export,
  Remove,
  Comment,
})
