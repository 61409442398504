import { useAppSelector } from 'app/hooks'

export const useLoader = () => {
  const { employees } = useAppSelector((state) => state)
  const { manageReports } = useAppSelector((state) => state)
  const { manageProduct } = useAppSelector((state) => state)
  const { managePackage } = useAppSelector((state) => state)
  const { productConfiguration } = useAppSelector((state) => state)
  const { manageUsers } = useAppSelector((state) => state)

  const loadingStates = {
    employees: employees.isLoading,
    manageReports: manageReports.loading,
    manageProduct: manageProduct.loading,
    productConfiguration: productConfiguration.loading,
    manageUsers: manageUsers.isLoading,
    managePackage: managePackage.isLoading,
  }
  const isLoading = Object.values(loadingStates).some((loading) => loading)
  return { isLoading }
}
