import { SvgIcon, SvgIconProps } from '@mui/material'

export const EditProductIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4092 5C11.6854 5 11.9092 5.22386 11.9092 5.5C11.9092 5.74546 11.7324 5.94961 11.4991
        5.99194L11.4092 6H6.00015V18H18.0001V12.5909C18.0001 12.3148 18.224 12.0909 18.5001
        12.0909C18.7456 12.0909 18.9498 12.2678 18.9921 12.501L19.0001 12.5909V18.5C19.0001 18.78
        18.7801 19 18.5001 18.9933L5.50016 19C5.22016 19 4.99342 18.78 5.00015 18.5V5.5C5.00015 5.22
        5.22015 5 5.50015 5H11.4092ZM17.8537 4.14645L19.8537 6.14645C20.049 6.34171 20.049 6.65829
        19.8537 6.85355L13.3537 13.3536C13.2839 13.4234 13.195 13.4709 13.0982 13.4903L10.5982
        13.9903C10.2483 14.0603 9.93988 13.7518 10.0099 13.4019L10.5099 10.9019C10.5292 10.8051
        10.5768 10.7162 10.6466 10.6464L17.1466 4.14645C17.3419 3.95118 17.6584 3.95118 17.8537
        4.14645ZM17.5001 5.20711L11.4607 11.2465L11.1375 12.8626L12.7536 12.5394L18.793 6.5L17.5001
        5.20711Z'
      />
    </SvgIcon>
  )
}
