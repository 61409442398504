import { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Box, MenuItem, SelectChangeEvent, Typography } from '@mui/material'
import { resetAll, getProductGroups, setProdGroupSelected, getProductJurisdictions } from 'features/managePackage/slice'
import { useSearchNavigate } from 'features/managePackage/hooks/useSearchNavigate'
import { CustomSelect } from '../../../../components/CustomSelect'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { IProductGroup } from 'features/managePackage/constants'
import colors from 'theme/colors'

export const SelectProductGroup: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const prodGroupShortNameSelected = searchParams.get('name')
  const { searchFixedNavigate } = useSearchNavigate()
  const { productGroups } = useAppSelector((state) => state.managePackage)

  const findProductGroup = useCallback(
    (shortName: string) => productGroups.find((proGroup: IProductGroup) => proGroup.shortName === shortName),
    [productGroups.length]
  )

  const handleChange = (e: SelectChangeEvent) => {
    dispatch(resetAll())
    const shortName = e.target.value
    searchFixedNavigate({ name: shortName })
    const productGroup = findProductGroup(shortName)!
    dispatch(setProdGroupSelected(productGroup))
    dispatch(getProductJurisdictions(productGroup))
  }

  useEffect(() => {
    dispatch(getProductGroups())
  }, [dispatch])

  useEffect(() => {
    // if a user refreshes page
    if (prodGroupShortNameSelected) {
      dispatch(setProdGroupSelected(findProductGroup(prodGroupShortNameSelected)))
    }
  }, [dispatch, prodGroupShortNameSelected, findProductGroup])

  return productGroups.length > 0 ? (
    <Box
      sx={{
        maxHeight: '48px',
        display: 'flex',
        flex: '0 1 0',
        boxSizing: 'border-box',
        p: '5px 0',
        flexDirection: 'row',
        alignItems: 'center',
        ml: '16px',
      }}
    >
      <Typography fontWeight={600} fontSize={'14px'}>
        {t('manageProduct.productGroup.label')}:
      </Typography>
      <CustomSelect
        value={prodGroupShortNameSelected ? prodGroupShortNameSelected : ''}
        sx={{
          '&&& .MuiSelect-select': {
            color: colors.mineShaft,
          },
          '&&&& fieldset': {
            borderColor: 'transparent',
          },
        }}
        onChange={handleChange}
        placeholder={t('manageProduct.productGroup.placeholder')!}
        selectedTemplated={() => (
          <>{productGroups.find((productGroup) => productGroup.shortName === prodGroupShortNameSelected)?.name}</>
        )}
      >
        {productGroups.map((productGroup) => (
          <MenuItem key={productGroup.id} value={productGroup.shortName}>
            {productGroup.name}
          </MenuItem>
        ))}
      </CustomSelect>
    </Box>
  ) : (
    <></>
  )
}
