import React from 'react'
import { Button, Typography, Box, SxProps } from '@mui/material'
import CustomModal from 'components/CustomModal'
import { WarningIcon } from 'components/icons/Warning.icon'
import { ErrorIcon } from 'components/icons/Error.icon'
import { InfoIcon } from 'components/icons/Info.icon'
import { SuccessIcon } from 'components/icons/Success.icon'
import { CloseIcon } from 'components/icons/Close.icon'

export interface ConfirmPopupButton {
  action: () => void
  name: string
  style?: SxProps
  type: 'outlined' | 'contained'
  icon?: React.ReactElement
}

interface Props {
  buttons: ConfirmPopupButton[]
  hideCloseButton?: boolean
  icon?: 'warning' | 'error' | 'info' | 'success'
  message: string
  onClose: () => void
  open: boolean
  title: string
}

export const ConfirmPopup: React.FC<Props> = ({
  buttons,
  hideCloseButton = false,
  icon,
  message,
  onClose,
  open,
  title,
}) => {
  return (
    <CustomModal width='500px' open={open}>
      <CustomModal.Header>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {icon && (
              <Box
                sx={{
                  display: 'flex',
                  marginRight: '8px',
                }}
              >
                {
                  {
                    warning: <WarningIcon />,
                    error: <ErrorIcon />,
                    info: <InfoIcon />,
                    success: <SuccessIcon />,
                  }[icon]
                }
              </Box>
            )}
            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              {title}
            </Typography>
          </Box>
          {!hideCloseButton && (
            <Button
              sx={{
                minHeight: '24px',
                minWidth: '24px',
                padding: 0,
              }}
              onClick={() => onClose()}
            >
              <CloseIcon />
            </Button>
          )}
        </Box>
      </CustomModal.Header>
      <CustomModal.Body
        style={{
          padding: '8px 16px',
          fontFamily: 'Open Sans',
          fontSize: 14,
        }}
      >
        <>{message}</>
      </CustomModal.Body>
      <CustomModal.Footer
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '16px',
        }}
      >
        <>
          {buttons.map((button) => (
            <Button
              key={button.name}
              variant={button.type}
              sx={button.style}
              onClick={button.action}
            >
              {button.icon}
              {button.name}
            </Button>
          ))}
        </>
      </CustomModal.Footer>
    </CustomModal>
  )
}
