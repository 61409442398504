import { SvgIcon, SvgIconProps } from '@mui/material'

export const RenewIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='30'
      height='30'
      viewBox='0 0 30 30'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='30' height='30' rx='15' fill='#007CB0' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.293 9L16.1464 11.1464C15.9512 11.3417 15.9512 11.6583 16.1464 11.8536C16.32 12.0271
        16.5894 12.0464 16.7843 11.9114L16.8536 11.8536L19.8536 8.85355C20.0271 8.67999 20.0464
        8.41056 19.9114 8.21569L19.8536 8.14645L16.8536 5.14645C16.6583 4.95118 16.3417 4.95118
        16.1464 5.14645C15.9729 5.32001 15.9536 5.58944 16.0886 5.78431L16.1464 5.85355L18.293
        8H15C11.2145 8 8.1309 11.0048 8.00406 14.7593L8 15C8 18.866 11.134 22 15 22C18.866 22 22
        18.866 22 15C22 14.7239 21.7761 14.5 21.5 14.5C21.2239 14.5 21 14.7239 21 15C21 18.3137
        18.3137 21 15 21C11.6863 21 9 18.3137 9 15C9 11.7616 11.5656 9.12243 14.7751 9.00414L15
        9H18.293L16.1464 11.1464L18.293 9Z'
        fill='white'
      />
    </SvgIcon>
  )
}
