import { ChangeEvent, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setPaymentInfoErrors, updatePaymentDetails } from 'features/subscriptionRequest/slice'
import { CustomTextField } from 'components/CustomTextField'
import colors from 'theme/colors'
import { useFormik } from 'formik'
import { emailRegEx } from 'features/subscriptionRequest/constants/email'
import { emailErrorMsg, requiredErrorMsg } from './validation'
import { createErrorsData, tarnsformFieldsToTouchedFormat } from './helpers'

export const InvoiceDetails = ({ productId }: { productId: string }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const {
    subscription: { paymentDetailsData },
    paymentInfoErrors,
  } = useAppSelector((state) => state.subscriptionRequest)
  const [isReinitialized, setIsReinit] = useState(false)

  const validationFieldRules = Yup.object().shape({
    email: Yup.string()
      .nullable()
      .matches(emailRegEx, emailErrorMsg)
      .test('is-email-present', requiredErrorMsg, (value) => {
        if (value === undefined || value?.length) return true
        return false
      }),
    contact: Yup.string()
      .nullable()
      .transform((value) => value.trim())
      .test('is-contact-valid', requiredErrorMsg, (value) => {
        if (value === undefined || value?.length) return true
        return false
      }),
  })

  const formInitialValues = {
    ...{ contact: '', email: '' },
    email: paymentDetailsData[productId]?.invoiceDetails?.email || '',
    contact: paymentDetailsData[productId]?.invoiceDetails?.contact || '',
  }

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: (values) => console.log(values),
    validationSchema: validationFieldRules,
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
    enableReinitialize: isReinitialized,
  })

  const [invoiceDetails, setInvoiceDetails] = useState(paymentDetailsData[productId]?.invoiceDetails || {})

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const {
      target: { value, name },
    } = e
    const currentValue = { ...invoiceDetails, [name]: value }

    formik.handleChange(e)
    setInvoiceDetails(currentValue)
    dispatch(updatePaymentDetails({ key: 'invoiceDetails', value: currentValue, productId }))
  }

  const getFieldError = (key: string) => {
    if (formik.touched[key as keyof typeof formik.errors]) return !!formik.errors[key as keyof typeof formik.errors]
  }

  useEffect(() => {
    const isFormEdited = !!Object.keys(formik.touched)?.length
    if (isFormEdited) {
      const errors = createErrorsData(formik.errors, formik.touched)
      dispatch(setPaymentInfoErrors({ errors, productId }))

      isReinitialized && setIsReinit(false)
    }
  }, [formik.errors, formik.touched])

  useEffect(() => {
    const touchedFields = paymentInfoErrors[productId] || {}
    const transformedToucedFields = tarnsformFieldsToTouchedFormat(touchedFields)
    formik.setTouched(transformedToucedFields)
  }, [])

  const handleBlur = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | FocusEvent) => {
    formik.handleBlur(e)
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <CustomTextField
            sx={{ width: '290px' }}
            value={formik.values.contact}
            label={t('subscriptionPaymentDetailsAccordion.invoice.name.label')}
            placeholder={t('subscriptionPaymentDetailsAccordion.invoice.name.placeholder')}
            required
            name='contact'
            onChange={handleChange}
            error={getFieldError('contact')}
            onBlur={handleBlur}
          />
          <CustomTextField
            sx={{ width: '290px' }}
            label={t('subscriptionPaymentDetailsAccordion.invoice.email.label')}
            placeholder={t('subscriptionPaymentDetailsAccordion.invoice.email.placeholder')}
            required
            value={formik.values.email}
            name='email'
            onChange={handleChange}
            error={getFieldError('email')}
            onBlur={handleBlur}
          />
        </Box>
      </form>

      <Box
        sx={{
          color: 'grey.800',
          lineHeight: '24px',
          fontFamily: 'Open Sans',
          fontSize: '14px',
          pt: '24px',
          mt: '24px',
          borderTop: `1px solid ${colors.quillGray}`,
        }}
      >
        <Box>{t('paymentDetails.invoice.thirdParty')}</Box>
        <Box sx={{ mt: '24px' }}>{t('paymentDetails.invoice.note')}</Box>
      </Box>
    </>
  )
}
