import {
  getEmployeesByName,
  resetEmployeesFound,
  getEmployeesByJurisdiction,
  getEmployeesByProductGroupIdAndJurisdictionId,
} from '../slices/employees'

import { useAppDispatch } from 'app/hooks'

import { IAddAdminFormData } from '../constants'

interface Props {
  formValues: IAddAdminFormData
}

export const useSearchUsers = ({ formValues }: Props) => {
  const dispatch = useAppDispatch()

  const searchEmployees = (searchTerm: string) => {
    dispatch(resetEmployeesFound())
    if (formValues.jurisdictionId && formValues.productId) {
      dispatch(
        getEmployeesByProductGroupIdAndJurisdictionId({
          productGroupId: formValues.productId,
          jurisdictionId: formValues.jurisdictionId,
          searchTerm,
        })
      )
    } else if (formValues.jurisdictionId) {
      dispatch(
        getEmployeesByJurisdiction({ jurisdictionId: formValues.jurisdictionId, searchTerm })
      )
    } else {
      dispatch(getEmployeesByName(searchTerm))
    }
  }

  return { searchEmployees }
}
