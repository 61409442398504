import { FC } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'app/hooks'
import {
  IPackageGroup,
  ISelectedPackages,
  ProductGroupType,
  SubscriptionAction,
} from 'features/subscriptionRequest/constants/subscription'
import { PackageGroups } from 'features/subscriptionRequest/components/PackageGroups'
import { PackageGroup } from 'features/subscriptionRequest/components/packageGroup'
import { getFormattedPackages } from 'features/subscriptionRequest/utils/getFormattedPackages'
import { CustomAlert } from 'components/CustomAlert'

interface Props {
  subscriptionAction: SubscriptionAction
  selectDuration?: (subscriptionId: string, termId: string) => void
  selectedPackages?: ISelectedPackages
  packageGroups: { [key: string]: IPackageGroup } | null
}

export const SelectPackages: FC<Props> = ({ subscriptionAction, selectDuration, selectedPackages, packageGroups }) => {
  const { productGroup, type, commonTermId, currencies } = useAppSelector((state) => state.subscriptionRequest)
  const { t } = useTranslation()

  const isShowAlert =
    !packageGroups ||
    (Object.keys(packageGroups).some((name) => !(commonTermId && type && currencies[packageGroups![name].productId])) &&
      productGroup.type === ProductGroupType.All)

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flex: 1 }}>
        {isShowAlert && (
          <CustomAlert
            sx={{ position: 'sticky', top: 0, zIndex: 1 }}
            custom='tip'
            text={
              !(commonTermId && type)
                ? t('subscriptionRequest.packages.alert.selectTypeTerm')
                : t('subscriptionRequest.packages.alert.selectCurrency', {
                    packages: getFormattedPackages(packageGroups, commonTermId, type, currencies),
                  })
            }
          />
        )}
        <Box padding='24px' className='content'>
          {packageGroups &&
            (productGroup.type === ProductGroupType.All ? (
              <PackageGroups subscriptionAction={subscriptionAction} packageGroups={packageGroups} />
            ) : (
              Object.keys(packageGroups).map((name: string) => (
                <PackageGroup
                  subscriptionAction={subscriptionAction}
                  key={name}
                  packageGroup={packageGroups[name]}
                  selectDuration={selectDuration}
                  productTerms={packageGroups[name].terms}
                  selectedPackages={selectedPackages as ISelectedPackages}
                />
              ))
            ))}
        </Box>
      </Box>
    </Box>
  )
}
