import { DateFormat } from 'constants/profileSettings'
import { TFunction } from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Getter } from '@tanstack/react-table'

import { OverflowTooltip } from 'components/OverflowTooltip'

import { formatDate } from 'utils/date'
import { getDateFormat } from 'sharedSlices/auth'
import { IPackage } from 'features/managePackage/constants'
import { PackageStatus } from 'features/manageProduct/constants'
import { useAppSelector } from 'app/hooks'

export const calculateDuration = (item: IPackage, t: TFunction<'translation', undefined>, dateFormat?: DateFormat) => {
  const statusList = [PackageStatus.Pending, PackageStatus.Rejected]
  if (item.term) {
    if (statusList.includes(item.status)) {
      return `${item.term.duration} ${
        item.term.duration > 1 ? t('manageProduct.subscriptions.years') : t('manageProduct.subscriptions.year')
      }`
    }
    if (item.startDate && item.endDate)
      return `${formatDate(item.startDate, dateFormat)} - ${formatDate(item.endDate, dateFormat)}`
  }
  return '-'
}

export const Duration = ({ getValue }: { getValue: Getter<IPackage> }) => {
  const { t } = useTranslation()

  const dateFormat = useAppSelector(getDateFormat)
  const durationText = useMemo(() => calculateDuration(getValue(), t, dateFormat), [getValue, dateFormat, t])

  return <OverflowTooltip text={durationText} />
}
