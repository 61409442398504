import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem, SelectChangeEvent, SelectProps } from '@mui/material'
import { handleLanguageSelected } from 'sharedSlices/localization'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { submitProfileSettings } from 'services/profile'
import { selectAvailableLanguageByProduct } from 'sharedSlices/localization/selectors'
import { CustomSelect } from '../CustomSelect'

export const SelectLanguage: FC<SelectProps<string>> = ({ MenuProps, sx, onOpen }) => {
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()
  const user = useAppSelector((state) => state.auth.employeeInformation)
  const { languageSelected } = useAppSelector((state) => state.localization)
  const availableLanguages = useAppSelector(selectAvailableLanguageByProduct)

  const onSelectLang = useCallback(
    (lang: SelectChangeEvent) => {
      dispatch(handleLanguageSelected(lang.target.value))
      i18n.changeLanguage(lang.target.value)
      submitProfileSettings(user!, { localeCode: lang.target.value })
    },
    [dispatch, i18n, user]
  )

  return (
    <CustomSelect
      value={languageSelected.localeCode}
      onChange={onSelectLang}
      sx={sx}
      menuProps={MenuProps}
      onOpen={onOpen}
      selectedTemplated={() => <>{languageSelected.nativeName}</>}
    >
      {availableLanguages.map(({ localeCode, nativeName }, i) => (
        <MenuItem key={localeCode + i} value={localeCode}>
          {nativeName}
        </MenuItem>
      ))}
    </CustomSelect>
  )
}
