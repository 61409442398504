import { SvgIcon, SvgIconProps } from '@mui/material'

export const ReactivateIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.3087 5.03807C14.1218 5.11547 14 5.29778 14 5.50001V8H10C7.23858 8 5 10.2386 5 13C5
        15.7614 7.23858 18 10 18H14.5C14.7761 18 15 17.7761 15 17.5C15 17.2239 14.7761 17 14.5
        17H10C7.79086 17 6 15.2091 6 13C6 10.7909 7.79086 9 10 9H14V11.5C14 11.7022 14.1218 11.8846
        14.3087 11.962C14.4955 12.0393 14.7106 11.9966 14.8536 11.8536L17.8536 8.85357C18.0488
        8.65831 18.0488 8.34172 17.8536 8.14646L14.8536 5.14646C14.7106 5.00346 14.4955 4.96068
        14.3087 5.03807Z'
      />
    </SvgIcon>
  )
}
