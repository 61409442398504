import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { FormikProvider, Form } from 'formik'
// components
import CustomForm from 'components/CustomForm'
import RoleField from '../roleField'
import FooterForm from '../footerForm'
import HeaderForm from '../headerForm'
import ProductField from '../productField'
import EmployeeField from '../employeeField'
import JurisdictionField from '../jurisdictionField'
import PermissionsField from '../permissionsField'
import { CustomLoading } from 'components/CustomLoading'
// selectors
import {
  filteredSelectProducts,
  selectPermissions,
} from 'features/manageAdmins/slices/manageAdmins/selectors'
import { filteredRoleList } from 'features/manageAdmins/slices/manageAdmins/selectors'
import { useAppSelector } from 'app/hooks'
// hooks
import { useAddAdminsForm } from 'features/manageAdmins/hooks'

import { SUPER_ADMIN, JURISDICTION_ADMIN } from 'features/manageAdmins/constants'

const Loading = () => (
  <CustomLoading
    message='common.loader.loading'
    textColor='black'
    sx={{
      display: 'flex',
      width: '100%',
      height: '120px',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
    }}
  />
)

const AddAdminsForm = () => {
  const { t } = useTranslation()
  const roles = useAppSelector(filteredRoleList)
  const permissions = useAppSelector(selectPermissions)
  const products = useAppSelector(filteredSelectProducts)
  const { isLoading } = useAppSelector((state) => state.manageAdmins)
  const { jurisdictions } = useAppSelector((state) => state.jurisdictions)
  const { employeesFound, isSearching } = useAppSelector((state) => state.employees)

  const { setValue, onClose, setLinkedField, handleRole, handleProduct, formikProps } =
    useAddAdminsForm()
  const { values, isValid, dirty } = formikProps

  const showProductField = () => {
    return (
      values.roleId !== SUPER_ADMIN && (
        <ProductField
          products={products}
          setValue={setValue}
          setLinkedField={setLinkedField}
          onChange={handleProduct}
        />
      )
    )
  }
  const showJurisdictionField = () => {
    if (values.roleId && values.roleId !== SUPER_ADMIN) {
      return (
        <JurisdictionField
          setValue={setValue}
          isFieldDisabled={!(values.productId !== '' || values.roleId === JURISDICTION_ADMIN)}
          jurisdictions={jurisdictions}
        />
      )
    }
  }

  useEffect(() => {
    if (values.jurisdictionId || values.productId) {
      setValue('employeeIds', [])
      setValue('permissionIds', [])
    }
  }, [setValue, values.jurisdictionId, values.productId])

  return (
    <FormikProvider value={formikProps}>
      <Form>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <HeaderForm onClose={onClose} title='manageAdmins.addAdmins' />
          <Box sx={{ maxHeight: '473px', flexGrow: 1, overflow: 'auto', p: '0 16px' }}>
            <CustomForm>
              <Typography variant='body2' sx={{ m: '16px 0' }}>
                {t('manageAdmins.searchUsers')}
              </Typography>
              <RoleField roles={roles} setValue={setValue} onChange={handleRole} />
              {showProductField()}
              {isLoading ? <Loading /> : showJurisdictionField()}
              <EmployeeField
                setValue={setValue}
                formValues={values}
                employees={employeesFound}
                isSearching={isSearching}
                isDisabled={!(values.jurisdictionId !== '')}
              />
              <PermissionsField>
                <PermissionsField.SelectAll
                  permissions={permissions}
                  permissionsSelected={values.permissionIds.length}
                  setValue={setValue}
                />
                <PermissionsField.List permissions={permissions} />
              </PermissionsField>
            </CustomForm>
          </Box>
          <FooterForm>
            <FooterForm.Button onClose={onClose} variant='outlined' text='button.cancel' />
            <FooterForm.Button type='submit' disabled={!isValid || !dirty} text='button.assign' />
          </FooterForm>
        </Box>
      </Form>
    </FormikProvider>
  )
}

export default memo(AddAdminsForm)
