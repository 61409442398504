import { useMemo } from 'react'

import { OverflowTooltip } from 'components/OverflowTooltip'
import { IPackage } from 'features/managePackage/constants'
import { Getter } from '@tanstack/react-table'

export const getValuation = (item: IPackage) =>
  item.term
    ? '-'
    : `${item.availableLicenseQuantity + item.pendingLicenseQuantity} of ${
        Number(item.licenseQuantity) + item.pendingLicenseQuantity
      }`

export const Valuations = ({ getValue }: { getValue: Getter<IPackage> }) => {
  const durationText = useMemo(() => getValuation(getValue()), [getValue])

  return <OverflowTooltip text={durationText} />
}
