import { ChangeEvent, FC } from 'react'
import { Box, InputBaseComponentProps, SxProps, TextField, Typography } from '@mui/material'
import { CustomFormLabel } from 'components/CustomFormLabel'
import colors from 'theme/colors'

interface Props {
  value?: string
  label?: string
  placeholder?: string
  name?: string
  sx?: SxProps
  required?: boolean
  inputProps?: InputBaseComponentProps
  error?: boolean
  readonly?: boolean
  highlightOnFocus?: boolean
  readonlyTemplate?: () => JSX.Element | null
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  onBlur?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  multiline?: boolean
  limitation?: number
  helperText?: JSX.Element | string
  disabled?: boolean
}

export const CustomTextField: FC<Props> = ({
  sx,
  value,
  label,
  name,
  placeholder,
  readonly,
  readonlyTemplate,
  inputProps,
  required,
  error,
  highlightOnFocus,
  onChange,
  onBlur,
  multiline = false,
  limitation,
  helperText,
  disabled,
}) => {
  return (
    <Box
      data-testid='field-content'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
      className={error ? 'invalid-field' : ''}
    >
      {label && <CustomFormLabel value={label} required={required} error={error} />}

      {readonly ? (
        readonlyTemplate ? (
          readonlyTemplate()
        ) : (
          <Typography sx={{ wordBreak: 'break-word' }} variant='body2'>
            {value}
          </Typography>
        )
      ) : (
        <>
          <TextField
            sx={{
              '&&& fieldset': {
                borderColor: 'grey.100',
                borderWidth: '1px',
                borderRadius: '2px',

                ':hover': {
                  borderColor: 'inherit',
                },
              },
              '&&& .Mui-focused fieldset': {
                borderColor: highlightOnFocus ? 'primary.main' : 'grey.100',
              },
              '&&& .Mui-disabled fieldset': {
                borderStyle: 'dashed',
              },
              '&&& .Mui-disabled input': {
                background: colors.bgDisabled,
              },
              '&&& .Mui-error fieldset': {
                borderColor: 'error.main',
              },

              '& > div': {
                padding: multiline ? '8px' : '',
                fontSize: multiline ? '14px' : '',
              },
            }}
            value={value || ''}
            name={name}
            inputProps={{
              ...(limitation && { maxLength: limitation }),
              ...inputProps,
            }}
            placeholder={placeholder}
            disabled={disabled}
            error={error}
            onChange={onChange}
            onBlur={onBlur}
            multiline={multiline}
          ></TextField>
          {(limitation || helperText) && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '6px' }}>
              <Typography variant='caption'>{helperText}</Typography>
              {limitation && (
                <Typography color='grey.800' variant='caption'>
                  {value?.length}/{limitation}
                </Typography>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
