import { Box, Button, TextField, Typography } from '@mui/material'
import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import {
  CurrentPagePosition,
  customPaginationStyles,
  ICustomPaginationProps,
  Key,
  numericValues,
  PaginationDirection,
} from '../constants'
import colors from 'theme/colors'
import { RowsPerPage } from 'components/RowsPerPage'
import { ReactComponent as CaretRight } from 'assets/images/CaretRight.svg'
import { ReactComponent as Chevron } from 'assets/images/Chevron.svg'

export const CustomPagination = ({ count, rowsPerPage, changeCurrentPage, currentPage }: ICustomPaginationProps) => {
  const maxPage = Math.ceil(count / rowsPerPage)
  const { t } = useTranslation()
  const [selectedPage, setSelectedPage] = useState<number | null>(null)
  const { oneItem, itemsToShow, initialValue, twoItems, initialPage } = numericValues

  const [pages, setPages] = useState<{
    numberPages: number[]
    page: number
  }>({
    numberPages: [],
    page: initialValue,
  })

  const itemsMaxIndex = currentPage * rowsPerPage
  const pageStartItemIndex = (currentPage - oneItem) * rowsPerPage + oneItem
  const pageEndItemIndex = Math.min(count, itemsMaxIndex)
  const isOnFirstPages = currentPage <= itemsToShow
  const isOnLastPages = Math.ceil(currentPage / itemsToShow) === Math.ceil(maxPage / itemsToShow)

  const { containerStyles, navigationButtonStyles, flexStyles, rightContainerStyles, pageInputStyles } =
    customPaginationStyles

  const getCurrentPagePosition = useCallback((page: number) => {
    switch (page % itemsToShow) {
      case 0:
        return CurrentPagePosition.End
      case 1:
        return CurrentPagePosition.Start
      default:
        return CurrentPagePosition.Middle
    }
  }, [])

  const generatePagination = useCallback(
    (totalItems: number, itemsPerPage: number, page: number): number[] => {
      const totalPages = Math.ceil(totalItems / itemsPerPage)

      if (page > totalPages) {
        page = totalPages
      }

      const position = getCurrentPagePosition(page)
      const pagination: number[] = []
      const offset =
        position === CurrentPagePosition.Start
          ? initialValue
          : position === CurrentPagePosition.End
          ? -twoItems
          : -oneItem
      for (let i = initialValue; i < itemsToShow; i++) {
        const _page = page + offset + i
        if (_page >= oneItem && _page <= totalPages) {
          pagination.push(_page)
        }
      }

      return pagination
    },
    [getCurrentPagePosition]
  )

  useEffect(() => {
    const _pages = generatePagination(count, rowsPerPage, currentPage)
    setPages({
      numberPages: _pages,
      page: currentPage,
    })

    if (currentPage > maxPage) {
      changeCurrentPage({ page: maxPage })
    }
  }, [rowsPerPage, count, currentPage, generatePagination, maxPage, changeCurrentPage])

  const handleResetInputSelectedPage = () => {
    if (selectedPage) {
      setSelectedPage(null)
    }
  }

  const changesPages = useCallback(
    (direction: PaginationDirection) => {
      handleResetInputSelectedPage()
      let page = initialValue

      if ([PaginationDirection.NextPages, PaginationDirection.PreviousPage].includes(direction)) {
        page =
          direction === PaginationDirection.NextPages
            ? pages.numberPages[pages.numberPages.length - oneItem] + oneItem
            : pages.numberPages[initialValue] - itemsToShow
      } else {
        page = direction === PaginationDirection.LastPages ? maxPage : oneItem
      }

      changeCurrentPage({ page })
    },
    [changeCurrentPage, maxPage, pages.numberPages]
  )

  const onChangeInputValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.target.value)

      if (value >= oneItem && value <= maxPage) {
        setSelectedPage(value)
      } else {
        setSelectedPage(null)
      }
    },
    [maxPage]
  )

  const onChangeKeyDown = useCallback(
    (event: KeyboardEvent<HTMLImageElement>) => {
      if (event.key === Key.Enter && selectedPage) {
        changeCurrentPage({ page: selectedPage })
      }
    },
    [selectedPage, changeCurrentPage]
  )

  return (
    <Box id='pagination' sx={containerStyles}>
      <Box sx={flexStyles}>
        <RowsPerPage
          totalItems={count}
          value={rowsPerPage}
          onChange={(number) => {
            handleResetInputSelectedPage()
            changeCurrentPage({ size: number, page: initialPage })
          }}
          showText={false}
          iconComponent={Chevron}
        />
        <Box sx={{ fontSize: '14px' }}>
          {pageStartItemIndex} &mdash; {pageEndItemIndex} {t('pagination.of')}{' '}
          <span style={{ fontWeight: 600 }}>{count}</span>
        </Box>
      </Box>

      <Box sx={{ ...flexStyles, gap: '24px' }}>
        <Box sx={{ ...flexStyles, gap: '8px' }}>
          <Button
            onClick={() => {
              changesPages(PaginationDirection.FistPages)
            }}
            disabled={currentPage === oneItem || count === oneItem}
            variant='text'
          >
            {t('pagination.first')}
          </Button>
          <Button
            onClick={() => {
              changesPages(PaginationDirection.PreviousPage)
            }}
            disabled={maxPage <= itemsToShow || isOnFirstPages}
            sx={{
              rotate: '180deg',
              ...navigationButtonStyles,
            }}
          >
            <CaretRight />
          </Button>

          {pages.numberPages.map((page) => {
            return (
              <Button
                key={page}
                variant={currentPage === page ? 'outlined' : 'text'}
                onClick={() => {
                  changeCurrentPage({ page })
                  handleResetInputSelectedPage()
                }}
                sx={{ color: currentPage === page ? colors.black.main : colors.blue.main }}
              >
                {page}
              </Button>
            )
          })}

          <Button
            onClick={() => {
              changesPages(PaginationDirection.NextPages)
            }}
            disabled={maxPage <= itemsToShow || isOnLastPages}
            sx={{
              ...navigationButtonStyles,
            }}
          >
            <CaretRight />
          </Button>
          <Button
            onClick={() => {
              changesPages(PaginationDirection.LastPages)
            }}
            disabled={currentPage === maxPage}
            variant='text'
          >
            {t('pagination.last')}
          </Button>
        </Box>

        <Box sx={rightContainerStyles}>
          {t('pagination.page')}
          <TextField
            sx={pageInputStyles}
            value={selectedPage ?? ''}
            placeholder='1'
            type='number'
            onKeyDown={onChangeKeyDown}
            onChange={onChangeInputValue}
            inputProps={{ inputMode: 'numeric', min: oneItem, max: maxPage }}
          ></TextField>
          <Typography>{t('pagination.of')}</Typography>
          <Box sx={{ color: colors.blue.main }}>{maxPage}</Box>
        </Box>
      </Box>
    </Box>
  )
}
