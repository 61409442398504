import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

import { Dropper } from 'components/dropper'
import DropZone from './dropZone'
import { ErrorView } from './views/errorView'
import { FileSelected } from './fileSelected'
import { FormHandleView } from './views/formHandleView'
import { SuccessView } from './views/successView'
import { useAddMultipleUsers } from 'features/manageUsers/hooks/useAddMultipleUsers'
import HeaderForm from 'features/manageAdmins/components/headerForm'

import { UploadStatusType } from 'constants/uploadStates'

export const AddMultipleUsersForm = () => {
  const { t } = useTranslation()
  const {
    handleChange,
    handleSubmit,
    handleClose,
    resetForm,
    uploadResponse,
    errorMessage,
    values,
    isLoading,
    isSameOrganization,
  } = useAddMultipleUsers()

  const FormViews = () => {
    if (errorMessage) return <ErrorView resetForm={() => resetForm()} />
    if (uploadResponse.length > 0 || isLoading)
      return (
        <SuccessView
          uploadResult={uploadResponse}
          disable={isLoading}
          handleClose={() => {
            handleClose()
            resetForm()
          }}
        />
      )
    return (
      <FormHandleView
        disable={!(values.file && isSameOrganization)}
        file={values.file}
        handleChange={handleChange}
        handleClose={handleClose}
        isSameOrganization={values.isSameOrganization}
      />
    )
  }

  const handleUploadStatus = (): UploadStatusType | undefined => {
    if (errorMessage) return 'error'
    if (isLoading) return 'uploading'
    if (uploadResponse.length > 0) return 'success'
    return undefined
  }

  return (
    <form onSubmit={handleSubmit} style={{ outline: 0 }}>
      <HeaderForm onClose={handleClose} title='manageProduct.users.bulkUpload' />
      <Box
        sx={{ height: !values.file ? '304px' : 'auto', flexGrow: 1, overflow: 'auto', p: '16px' }}
      >
        <Dropper
          name='file'
          file={values.file}
          onChange={(file) => handleChange('file', file)}
          noFileView={<DropZone />}
          fileSelecteView={({ file, unselectFile }) => (
            <FileSelected
              uploadStatus={handleUploadStatus()}
              file={file}
              errorMessage={errorMessage ? t(errorMessage) : undefined}
              unselectFile={() => {
                unselectFile()
                resetForm()
              }}
            />
          )}
        />
      </Box>
      <FormViews />
    </form>
  )
}
