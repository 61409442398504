import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import { ReportHeader } from '../ReportHeader'
import { ReportTabs } from '../ReportTabs'

export const ReportLayout = () => {
  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }}>
    <ReportHeader/>
    <ReportTabs/>
    <Outlet/>
  </Box>
}
