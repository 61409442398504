import { SvgIcon, SvgIconProps } from '@mui/material'

export const TipIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4ZM14.7683 12.6543C14.4736 13.0873 14.1732 13.5288 13.9652 14H10.0348C9.82682 13.5288 9.52633 13.0872 9.23163 12.6542L9.2307 12.6528C9.1857 12.5867 9.1404 12.5201 9.09603 12.4543C8.71969 11.8962 8.5 11.2238 8.5 10.5C8.5 8.567 10.067 7 12 7C13.933 7 15.5 8.567 15.5 10.5C15.5 11.2238 15.2803 11.8962 14.904 12.4543C14.8599 12.5196 14.8152 12.5854 14.7705 12.651L14.7683 12.6543ZM10.41 16.0995L10.3 15H13.7L13.59 16.0995C13.5389 16.6107 13.1088 17 12.595 17H11.405C10.8912 17 10.4611 16.6107 10.41 16.0995Z'
        fill='#00ABAB'
      />
    </SvgIcon>
  )
}
