import { SvgIcon, SvgIconProps } from '@mui/material'

export const ErrorIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        color='error'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.3536 3.14645L20.8536 11.6464C21.0488 11.8417 21.0488 12.1583 20.8536
          12.3536L12.3536 20.8536C12.1583 21.0488 11.8417 21.0488 11.6464 20.8536L3.14645
          12.3536C2.95118 12.1583 2.95118 11.8417 3.14645 11.6464L11.6464 3.14645C11.8417 2.95118
          12.1583 2.95118 12.3536 3.14645ZM12 14C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16
          12 16C11.4477 16 11 15.5523 11 15C11 14.4477 11.4477 14 12 14ZM12 7C12.5082 7 12.9201
          7.41196 12.9201 7.92013L12.917 7.99655L12.5382 12.5415C12.5166 12.8007 12.3 13 12.0399
          13H11.9601C11.7 13 11.4834 12.8007 11.4618 12.5415L11.083 7.99655C11.0408 7.49013 11.4172
          7.04538 11.9236 7.00318C11.949 7.00106 11.9745 7 12 7Z'
        />
      </SvgIcon>
    </>
  )
}
