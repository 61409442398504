import { FC, useState } from 'react'
import { Box, Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomModal from 'components/CustomModal'
import { DATE_FORMATS, DateFormat, NUMBER_FORMATS, MoneyFormat } from 'constants/profileSettings'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { submitProfileSettings } from 'services/profile'
import { handleProfileSettings } from 'sharedSlices/ui'
import { getDateFormat, getMoneyFormat, getUser } from 'sharedSlices/auth'
import { CloseIcon } from 'components/icons/Close.icon'

interface Props {
  onClose: () => void
  open: boolean
}

export const ProfileSettingsPopup: FC<Props> = ({ onClose, open }) => {
  const user = useAppSelector((state) => state.auth.employeeInformation)
  const dateTimeFormat = useAppSelector(getDateFormat)
  const moneyFormat = useAppSelector(getMoneyFormat)
  const [moneyFormatState, setMoneyFormatState] = useState<MoneyFormat>(moneyFormat || NUMBER_FORMATS[0])
  const [dateFormat, setDateFormat] = useState<DateFormat>(dateTimeFormat || DATE_FORMATS[0])
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const onSubmit = () => {
    submitProfileSettings(user!, { dateTimeFormat: dateFormat, moneyFormat: moneyFormatState }).then(() => {
      dispatch(handleProfileSettings(false))
      dispatch(getUser())
    })
  }

  return (
    <CustomModal open={open} onClose={onClose}>
      <CustomModal.Header>
        <Box sx={{ marginRight: 'auto' }}>{t('profile.settings')}</Box>
        <Button
          sx={{
            minHeight: '24px',
            minWidth: '24px',
            padding: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </CustomModal.Header>
      <CustomModal.Body>
        <Box sx={{ paddingBottom: '32px' }}>
          <Box
            sx={{
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '24px',
            }}
          >
            {t('profile.dateFormat')}
          </Box>
          <RadioGroup
            defaultValue={dateTimeFormat || DATE_FORMATS[0]}
            onChange={(e) => setDateFormat(e.target.value as DateFormat)}
          >
            {DATE_FORMATS.map((_dateFormat) => (
              <FormControlLabel
                sx={{
                  marginLeft: '-2px',
                  padding: '4px 0',
                  '.MuiRadio-root': {
                    height: '24px',
                    padding: '0 8px 0 0',
                  },
                  '.MuiTypography-root': {
                    fontSize: '14px',
                  },
                }}
                key={_dateFormat}
                value={_dateFormat}
                control={<Radio size='small' disableRipple />}
                label={_dateFormat}
              />
            ))}
          </RadioGroup>
          <Box
            sx={{
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '24px',
              marginTop: '24px',
            }}
          >
            {t('profile.moneyFormat')}
          </Box>
          <RadioGroup
            defaultValue={moneyFormat || NUMBER_FORMATS[0]}
            onChange={(e) => setMoneyFormatState(e.target.value as MoneyFormat)}
          >
            {NUMBER_FORMATS.map((_moneyFormat) => (
              <FormControlLabel
                sx={{
                  marginLeft: '-2px',
                  padding: '4px 0',
                  '.MuiRadio-root': {
                    height: '24px',
                    padding: '0 8px 0 0',
                  },
                  '.MuiTypography-root': {
                    fontSize: '14px',
                  },
                }}
                key={_moneyFormat}
                value={_moneyFormat}
                control={<Radio size='small' disableRipple />}
                label={_moneyFormat}
              />
            ))}
          </RadioGroup>
        </Box>
      </CustomModal.Body>
      <CustomModal.Footer
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          padding: '16px',
        }}
      >
        <Button variant='outlined' onClick={onClose}>
          {t('button.cancel')}
        </Button>
        <Button
          sx={{
            marginLeft: '16px',
          }}
          variant='contained'
          onClick={() => onSubmit()}
        >
          {t('button.apply')}
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  )
}
