import React, { useEffect } from 'react'
import { Box, MenuItem, SelectChangeEvent } from '@mui/material'
import { CustomSelect } from 'components/CustomSelect'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useTranslation } from 'react-i18next'
import { IProductGroup } from 'features/subscriptionRequest/constants/subscription'
import { fetchProducts, setPagination, setProductGroup } from 'features/manageProduct/slice'
import { useSearchParams } from 'react-router-dom'
import { DEFAULT_PAGINATION, DEFAULT_SUBSCRIPTION_SORTING } from 'features/manageProduct/constants'
import colors from 'theme/colors'

export const SelectProductGroup: React.FC = () => {
  const { productGroups, selectedProductGroup } = useAppSelector((state) => state.manageProduct)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const productGroupShortName = searchParams.get('productGroupShortName')
    if (productGroupShortName) {
      const productGroup = productGroups.find(
        (_productGroup) => _productGroup.shortName === productGroupShortName
      ) as IProductGroup
      dispatch(setProductGroup({ selectedProductGroup: productGroup, isResetSubscriptions: false }))
    }
  }, [dispatch, productGroups])

  const onSelectProductGroup = (e: SelectChangeEvent) => {
    const productGroupName = e.target.value
    const productGroup = productGroups.find(
      (_productGroup) => _productGroup.name === productGroupName
    ) as IProductGroup
    if (
      searchParams.toString() &&
      searchParams.get('productGroupShortName') !== productGroup.shortName
    ) {
      setSearchParams({
        productGroupShortName: productGroup.shortName,
        sortBy: DEFAULT_SUBSCRIPTION_SORTING.column,
        direction: DEFAULT_SUBSCRIPTION_SORTING.direction,
        page: DEFAULT_PAGINATION.page.toString(),
        size: DEFAULT_PAGINATION.size.toString(),
      })
    }
    dispatch(setPagination(DEFAULT_PAGINATION))
    dispatch(setProductGroup({ selectedProductGroup: productGroup, isResetSubscriptions: true }))
    dispatch(fetchProducts(productGroup.id))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '12px',
        fontSize: '14px',
      }}
    >
      {t('manageProduct.productGroup.label')}:
      <CustomSelect
        sx={{ '.MuiSelect-select': { color: colors.mineShaft } }}
        value={selectedProductGroup?.name}
        outline={false}
        onChange={onSelectProductGroup}
        placeholder={t('manageProduct.productGroup.placeholder')!}
      >
        {productGroups.map((productGroup) => (
          <MenuItem key={productGroup.id} value={productGroup.name}>
            {productGroup.name}
          </MenuItem>
        ))}
      </CustomSelect>
    </Box>
  )
}
