import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setBillingDetails } from '../slice'
import { ProductGroupType } from 'features/productConfiguration/constants'

export const useSetBillingInfoAfterReset = () => {
  const {
    productGroup,
    subscription: { accountDetails },
  } = useAppSelector((state) => state.subscriptionRequest)
  const dispatch = useAppDispatch()

  const handleRestoreBillingDetails = () => {
    if (productGroup.type === ProductGroupType.All) dispatch(setBillingDetails(accountDetails))
  }

  return { handleRestoreBillingDetails }
}
