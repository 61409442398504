import { AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { CustomAccordion } from 'components/CustomAccordion'
import { DropdownIcon } from 'components/icons/Dropdown.icon'
import { PackageGroupDetails } from 'features/subscriptionRequest/components/PackageGroups/components/PackageGroupDetails'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'
import { IPackageGroup, ISelectedPackages, ITerm, SubscriptionAction } from '../../constants/subscription'
import { accordionSummaryStyles } from 'constants/styles'

interface Props {
  packageGroup: IPackageGroup
  subscriptionAction: SubscriptionAction
  selectDuration?: (subscriptionId: string, termId: string) => void
  productTerms: { [key: string]: ITerm[] }
  selectedPackages?: ISelectedPackages
}

export const PackageGroup: FC<Props> = ({ packageGroup, subscriptionAction, selectDuration, selectedPackages }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(true)

  const handleChange = () => setExpanded((prev) => !prev)

  return (
    <CustomAccordion
      key={packageGroup.name}
      disableGutters
      elevation={0}
      square
      expanded={expanded}
      handleChange={handleChange}
    >
      <AccordionSummary
        sx={accordionSummaryStyles}
        expandIcon={<DropdownIcon />}
        aria-controls='panel1d-content'
        id='panel1d-header'
      >
        <Typography
          sx={{
            ml: '12px',
            fontSize: '14px',
            fontWeight: '600',
            '.Mui-disabled &': {
              color: colors.silverChalice,
            },
          }}
        >
          {t('subscriptionRequest.packages.title')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderTop: '1px solid',
          borderColor: 'grey.100',
          padding: 0,
        }}
      >
        <PackageGroupDetails
          isSingle
          selectedPackages={selectedPackages}
          packageGroup={packageGroup}
          subscriptionAction={subscriptionAction}
          onSelectDuration={selectDuration}
        />
      </AccordionDetails>
    </CustomAccordion>
  )
}
