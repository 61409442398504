import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const EmptyReports = () => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" sx={{
        fontWeight: 600,
        marginBottom: '6px'
      }}>
        {t('reports.emptyReports.title')}
      </Typography>
      <Typography variant="subtitle2" sx={{
        color: 'grey.800'
      }}>
        {t('reports.emptyReports.subTitle')}
      </Typography>
    </Box>
  )
}
