import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import RowActions from 'components/rowActions'

interface Props {
  canEditSubscription: boolean
  canManageUserList: boolean
  goToManageUser: () => void
  hasComment: boolean
  openSubscriptionDetail: () => void
  openComment: () => void
  editSubscription: () => void
}

export const SubscriptionActions: FC<Props> = ({
  canEditSubscription,
  canManageUserList,
  goToManageUser,
  hasComment,
  openSubscriptionDetail,
  openComment,
  editSubscription,
}) => {
  const { t } = useTranslation()

  return (
    <RowActions>
      {hasComment && (
        <RowActions.Comment
          title={t('manageProduct.subscriptionActions.comment')}
          onClick={openComment}
        ></RowActions.Comment>
      )}
      {canManageUserList && (
        <RowActions.EditProfile
          title={t('manageProduct.subscriptionActions.manageUser')}
          onClick={goToManageUser}
        ></RowActions.EditProfile>
      )}
      {canEditSubscription && (
        <RowActions.Edit
          title={t('manageProduct.subscriptionActions.edit')}
          onClick={editSubscription}
        ></RowActions.Edit>
      )}
      <RowActions.Details
        title={t('manageProduct.subscriptionActions.details')}
        onClick={openSubscriptionDetail}
      />
    </RowActions>
  )
}
