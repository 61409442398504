import {
  GeoValidationResponse,
  ICountry,
  ISubscriptionRequest,
  IUserPackagesResponse,
  RenewSubscriptionPayload,
  ValidationPayload,
  ValidationSubmit,
} from '../../constants/subscription'
import { IEndUserInformation } from 'sharedSlices/auth/constants'
import { geoApi } from 'services/api/geoApi'
import { DigitalSignature } from 'features/subscriptionRequest/constants/payment'

export const submitSubscriptionRequestAsAdmin = (
  subscriptionDetails: ISubscriptionRequest,
  validationPayloads: ValidationPayload[],
  jurCode: string
): Promise<void> => {
  return geoApi
    .withJurisdictionCode(jurCode)
    .post(`subscriptions/submit/admin`, { ...subscriptionDetails, validationPayloads })
}

export const submitSubscriptionRequest = (
  subscriptionDetails: ISubscriptionRequest,
  validationPayloads: ValidationPayload[],
  jurCode: string,
  endUser: IEndUserInformation | null
): Promise<void> => {
  if (endUser) {
    subscriptionDetails.account.groupId = endUser.groupId
  }
  return geoApi
    .withJurisdictionCode(jurCode)
    .post(`subscriptions/submit${endUser ? '' : '/anonymous'}`, { ...subscriptionDetails, validationPayloads })
}

export const validateSubscriptionRequest = (
  subscriptionDetails: ISubscriptionRequest
): Promise<{ isValid: boolean; validationPayloads: ValidationPayload[] }> => {
  return geoApi
    .postAllGeo<GeoValidationResponse>('subscriptions/submit/validation', subscriptionDetails)
    .then((validationResponses) => {
      const isValid = validationResponses.every((response) => response.status === ValidationSubmit.Success)
      const validationPayloads = validationResponses.map((response) => response.payload)
      return { isValid, validationPayloads }
    })
}

export const upgradeSubscriptionRequest = (
  subscriptionDetails: ISubscriptionRequest,
  endUser: IEndUserInformation | null,
  productId: string,
  jurCode: string
): Promise<void> => {
  if (endUser) {
    subscriptionDetails.account.groupId = endUser.groupId
  }
  const headers = {
    'x-product-id': productId,
  }
  return geoApi.withJurisdictionCode(jurCode).post(`subscriptions/upgrade`, subscriptionDetails, { headers })
}

export const getUserPackages = (productId: string, jurCode: string) => {
  const headers = {
    'x-product-id': productId,
  }
  return geoApi.withJurisdictionCode(jurCode).get<IUserPackagesResponse[]>(`package/userPackages`, { headers })
}

export const getUserPackagesAsAdmin = (productId: string, jurCode: string, userEmail: string) => {
  const headers = {
    'x-product-id': productId,
    'Content-type': 'application/json',
  }
  return geoApi
    .withJurisdictionCode(jurCode)
    .post<IUserPackagesResponse[]>(`package/userPackages/admin`, JSON.stringify(userEmail), { headers })
}

export const getCountries = (productId: string, jurCode: string): Promise<ICountry[]> => {
  return geoApi.withJurisdictionCode(jurCode).get(`product/${productId}/country`)
}

export const renewSubscription = (subscriptionId: string, jurCode: string, payload: RenewSubscriptionPayload) => {
  return geoApi
    .withJurisdictionCode(jurCode)
    .post(`subscriptions/${subscriptionId}/renew`, payload, { headers: { 'x-action-origin': 'subscriptions' } })
}

export const generateDigitalSignature = (
  productId: string,
  currencyId: number,
  jurCode: string
): Promise<DigitalSignature> => {
  return geoApi.withJurisdictionCode(jurCode).post(`checkout/generateDigitalSignature`, { productId, currencyId })
}
