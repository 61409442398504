import { FC, useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { Box, Button, Divider } from '@mui/material'
import {
  CalendarRange,
  IMonth,
  CalendarDirection,
  StartWeek,
  CalendarChange,
  SelectedDate,
  SelectedDateRange,
  monthsName,
} from './constants'
import { Calendar } from './components/Calendar'
import { generateCalendar, updateDate } from './utils'
import { useTranslation } from 'react-i18next'
import { Decades } from './components/Decades'
import { Months } from './components/Months'
import colors from 'theme/colors'

interface Props {
  value?: SelectedDate
  onChange?: (value: SelectedDate) => void
  dateDisabledThreshold?: Date
}

export const DateRangePicker: FC<Props> = ({
  value = { endDate: '', startDate: '' },
  onChange,
  dateDisabledThreshold,
}) => {
  const [selectedDate, setSelectedDate] = useState<SelectedDate>({
    startDate: new Date(),
    endDate: new Date(),
  })
  const [leftDate, setLeftDate] = useState<Date>(new Date())
  const [rightDate, setRightDate] = useState<Date>(() => {
    const date = new Date()
    date.setMonth(leftDate.getMonth() + 1)
    return date
  })
  const [leftCalendarDate, setLeftCalendarDate] = useState<IMonth[]>([{ week: [] }])
  const [rightCalendarDate, setRightCalendarDate] = useState<IMonth[]>([{ week: [] }])
  const [showRightDecades, setShowRightDecades] = useState(false)
  const [showLeftDecades, setShowLeftDecades] = useState(false)
  const [showLeftMonths, setShowLeftMonths] = useState(false)
  const [showRightMonths, setShowRightMonths] = useState(false)
  const { t } = useTranslation()
  const calendarRange = useRef<null | CalendarRange>(null)

  useEffect(() => {
    if (value.startDate && value.endDate) {
      const startDate = new Date(value.startDate)
      const endDate = new Date(value.endDate)
      const currentDate = new Date()

      if (startDate.getTime() > endDate.getTime()) {
        return
      }

      if (endDate.getMonth() !== startDate.getMonth()) {
        setLeftDate(startDate)
        setRightDate(endDate)
      } else if (
        endDate.getMonth() === currentDate.getMonth() &&
        startDate.getMonth() === currentDate.getMonth() &&
        endDate.getMonth() != startDate.getMonth()
      ) {
        const newDate = new Date(endDate)
        newDate.setMonth(newDate.getMonth() - 1)
        setLeftDate(newDate)
        setRightDate(endDate)
      } else if (
        endDate.getMonth() === startDate.getMonth() &&
        endDate.getMonth() < currentDate.getMonth()
      ) {
        setLeftDate(endDate)
        setRightDate(currentDate)
      }

      setSelectedDate({
        startDate,
        endDate,
      })
    }
  }, [value.startDate, value.endDate])

  const selectedDateRange = useRef(SelectedDateRange.None)

  useEffect(() => {
    setRightCalendarDate(generateCalendar(rightDate, StartWeek.Sun))
  }, [rightDate])

  useEffect(() => {
    setLeftCalendarDate(generateCalendar(leftDate, StartWeek.Sun))
  }, [leftDate])

  useEffect(() => {
    if (calendarRange.current === CalendarRange.Right) {
      if (
        rightDate.getFullYear() < leftDate.getFullYear() &&
        rightDate.getMonth() > leftDate.getMonth()
      ) {
        const newDate = new Date(rightDate)
        newDate.setFullYear(newDate.getFullYear())
        newDate.setMonth(leftDate.getMonth())
        setLeftDate(newDate)
      }

      if (
        rightDate.getFullYear() === leftDate.getFullYear() &&
        rightDate.getMonth() <= leftDate.getMonth()
      ) {
        const newDate = new Date(rightDate)
        newDate.setMonth(rightDate.getMonth() - 1)
        setLeftDate(newDate)
      }

      if (rightDate.getFullYear() < leftDate.getFullYear()) {
        const newDate = new Date(leftDate)
        newDate.setFullYear(rightDate.getFullYear() - 1)
        setLeftDate(newDate)
      }
    } else if (calendarRange.current === CalendarRange.Left) {
      if (
        leftDate.getFullYear() > rightDate.getFullYear() &&
        leftDate.getMonth() < rightDate.getMonth()
      ) {
        const newDate = new Date(rightDate)
        newDate.setFullYear(leftDate.getFullYear())
        setRightDate(newDate)
      }

      if (
        leftDate.getFullYear() === rightDate.getFullYear() &&
        leftDate.getMonth() >= rightDate.getMonth()
      ) {
        const newDate = new Date(leftDate)
        newDate.setMonth(newDate.getMonth() + 1)
        setRightDate(newDate)
      }

      calendarRange.current = null
    }
  }, [leftDate, rightDate])

  useEffect(() => {
    if (selectedDateRange.current === SelectedDateRange.EndDate) {
      onChange && onChange(selectedDate)
      selectedDateRange.current = SelectedDateRange.None
    }
  }, [onChange, selectedDate])

  const handlerChange = useCallback(
    (range: CalendarRange, change: CalendarChange, direction: CalendarDirection) => {
      if (range === CalendarRange.Left) {
        setLeftDate((date) => updateDate(date, change, direction))
      } else {
        setRightDate((date) => updateDate(date, change, direction))
      }
      calendarRange.current = range
    },
    []
  )

  const handlerSelectedDate = useCallback((date: Date) => {
    if (selectedDateRange.current === SelectedDateRange.None) {
      setSelectedDate({
        startDate: date,
        endDate: date,
      })
      selectedDateRange.current = SelectedDateRange.StartDate
    } else {
      selectedDateRange.current = SelectedDateRange.EndDate
      setSelectedDate((_date) => {
        let endDate = date
        let startDate = _date.startDate
        if (endDate.getTime() < startDate.getTime()) {
          startDate = date
          endDate = _date.startDate
        }

        return {
          startDate,
          endDate,
        }
      })
    }
  }, [])

  const handlerHoverChange = useCallback((date: Date) => {
    setSelectedDate((_date) => {
      return {
        startDate: _date.startDate,
        endDate: date,
      }
    })
  }, [])

  const handlerShowRightDecades = useCallback(() => {
    setShowRightDecades(true)
  }, [])

  const handlerShowLeftDecades = useCallback(() => {
    setShowLeftDecades(true)
  }, [])

  const handlerShowRightMonths = useCallback(() => {
    setShowRightMonths(true)
  }, [])

  const handlerShowLeftMonths = useCallback(() => {
    setShowLeftMonths(true)
  }, [])

  const handlerChangeDecadesYear = useCallback((year: number, range: CalendarRange) => {
    const setDate = range === CalendarRange.Left ? setLeftDate : setRightDate
    calendarRange.current = range
    setDate((date) => {
      const newDate = new Date(date)
      newDate.setFullYear(year)
      return newDate
    })

    if (range === CalendarRange.Left) {
      setShowLeftDecades(false)
    } else {
      setShowRightDecades(false)
    }
  }, [])

  const handlerChangeMonthYear = useCallback(
    (range: CalendarRange, direction: CalendarDirection) => {
      const setDate = range === CalendarRange.Left ? setLeftDate : setRightDate
      calendarRange.current = range

      setDate((date) => {
        const newDate = new Date(date)
        newDate.setFullYear(
          direction === CalendarDirection.Next
            ? newDate.getFullYear() + 1
            : newDate.getFullYear() - 1
        )
        return newDate
      })
    },
    []
  )

  const handlerChangeMonth = useCallback((month: string, range: CalendarRange) => {
    const monthIndex = monthsName.findIndex((monthName) => monthName === month)
    const setDate = range === CalendarRange.Left ? setLeftDate : setRightDate
    calendarRange.current = range

    setDate((date) => {
      const newDate = new Date(date)
      newDate.setMonth(monthIndex)
      return newDate
    })

    if (range === CalendarRange.Left) {
      setShowLeftMonths(false)
    } else {
      setShowRightMonths(false)
    }
  }, [])

  const showPreviousArrow = useMemo(() => {
    const newDate = new Date(leftDate.toDateString())
    newDate.setMonth(newDate.getMonth() + 1)
    return newDate.getFullYear() === rightDate.getFullYear()
      ? newDate.getMonth() < rightDate.getMonth()
      : newDate.getTime() < rightDate.getTime()
  }, [leftDate, rightDate])

  const showNextArrow = useMemo(() => {
    const newDate = new Date(rightDate.toDateString())
    newDate.setMonth(newDate.getMonth() - 1)
    return newDate.getFullYear() === leftDate.getFullYear()
      ? leftDate.getMonth() < newDate.getMonth()
      : leftDate.getTime() < newDate.getTime()
  }, [rightDate, leftDate])

  const handlerToToday = useCallback(() => {
    const currentDate = new Date()
    setLeftDate(currentDate)
    setRightDate(() => {
      const date = new Date()
      date.setMonth(currentDate.getMonth() + 1)
      return date
    })
    const _selectedDate = {
      startDate: currentDate,
      endDate: currentDate,
    }
    setSelectedDate(_selectedDate)
    setShowRightDecades(false)
    setShowLeftDecades(false)
    setShowRightMonths(false)
    setShowLeftMonths(false)
  }, [])

  return (
    <Box
      sx={{
        display: 'inline-block',
      }}
    >
      <Divider
        sx={{
          backgroundColor: colors.deepCerulean,
          height: '3px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {showLeftDecades ? (
          <Decades
            date={leftDate}
            dateThreshold={rightDate}
            range={CalendarRange.Left}
            onChangeYear={handlerChangeDecadesYear}
          />
        ) : showLeftMonths ? (
          <Months
            range={CalendarRange.Left}
            onShowDecades={handlerShowLeftDecades}
            date={leftDate}
            dateThreshold={rightDate}
            month={monthsName[leftDate.getMonth()]}
            onChangeYear={handlerChangeMonthYear}
            onChangeMonth={handlerChangeMonth}
          />
        ) : (
          <Calendar
            range={CalendarRange.Left}
            dates={leftCalendarDate}
            currentMonth={leftDate}
            onChange={handlerChange}
            displayNextArrow={showNextArrow}
            displayPreviousArrow={true}
            selectedDate={selectedDate}
            onSelectedDate={handlerSelectedDate}
            selectedDateRange={selectedDateRange.current}
            onHoverChange={handlerHoverChange}
            onShowDecades={handlerShowLeftDecades}
            onShowMonths={handlerShowLeftMonths}
            dateDisabledThreshold={dateDisabledThreshold}
            translation={t}
          />
        )}
        <Divider orientation='vertical' flexItem />
        {showRightDecades ? (
          <Decades
            date={rightDate}
            range={CalendarRange.Right}
            onChangeYear={handlerChangeDecadesYear}
          />
        ) : showRightMonths ? (
          <Months
            range={CalendarRange.Right}
            onShowDecades={handlerShowRightDecades}
            date={rightDate}
            month={monthsName[rightDate.getMonth()]}
            onChangeYear={handlerChangeMonthYear}
            onChangeMonth={handlerChangeMonth}
          />
        ) : (
          <Calendar
            range={CalendarRange.Right}
            dates={rightCalendarDate}
            currentMonth={rightDate}
            onChange={handlerChange}
            displayNextArrow={true}
            displayPreviousArrow={showPreviousArrow}
            selectedDate={selectedDate}
            onSelectedDate={handlerSelectedDate}
            selectedDateRange={selectedDateRange.current}
            onHoverChange={handlerHoverChange}
            onShowDecades={handlerShowRightDecades}
            onShowMonths={handlerShowRightMonths}
            dateDisabledThreshold={dateDisabledThreshold}
            translation={t}
          />
        )}
      </Box>
      <Divider orientation='horizontal' />
      <Box padding='4px 10px'>
        <Button onClick={handlerToToday}>{t('reports.buttons.today')}</Button>
      </Box>
    </Box>
  )
}
