import { SvgIcon, SvgIconProps } from '@mui/material'

export const LockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 4C14.2091 4 16 5.79086 16 8V11H17.5C17.7761 11 18 11.2239 18 11.5V19.5C18 19.7761
        17.7761 20 17.5 20H6.5C6.22386 20 6 19.7761 6 19.5V11.5C6 11.2239 6.22386 11 6.5 11H8V8C8
        5.79086 9.79086 4 12 4ZM17 12H7V19H17V12ZM12 5C10.3431 5 9 6.34315 9 8V11H15V8C15 6.34315
        13.6569 5 12 5Z'
      />
    </SvgIcon>
  )
}
