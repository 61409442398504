import { SvgIcon, SvgIconProps } from '@mui/material'

export const RejectIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.64645 8.64645C8.84171 8.45118 9.15829 8.45118 9.35355
        8.64645L12 11.2929L14.6464 8.64645C14.8417 8.45118 15.1583
        8.45118 15.3536 8.64645C15.5488 8.84171 15.5488 9.15829 15.3536
        9.35355L12.7071 12L15.3536 14.6464C15.5488 14.8417 15.5488 15.1583
        15.3536 15.3536C15.1583 15.5488 14.8417 15.5488 14.6464 15.3536L12
        12.7071L9.35355 15.3536C9.15829 15.5488 8.84171 15.5488 8.64645
        15.3536C8.45118 15.1583 8.45118 14.8417 8.64645 14.6464L11.2929
        12L8.64645 9.35355C8.45118 9.15829 8.45118 8.84171 8.64645 8.64645Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12
        20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4ZM12 5C8.13401
        5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866
        19 12C19 8.13401 15.866 5 12 5Z'
      />
    </SvgIcon>
  )
}
