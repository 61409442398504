import { Box } from '@mui/material'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { generateDecades } from '../utils'
import { CalendarRange, CalendarType } from '../constants'
import { Header } from './common/Header'
import { Grid } from './common/Grid'

interface Props {
  date: Date
  dateThreshold?: Date
  range: CalendarRange
  onChangeYear: (year: number, calendar: CalendarRange) => void
}

export const Decades: FC<Props> = ({date, dateThreshold, range, onChangeYear }) => {
  const [decades, setDecades] = useState<number[][]>([])

  useEffect(() => {
    setDecades(generateDecades(date.getFullYear()))
  }, [date])

  const displayDecades = useMemo(() => {
    if (!decades[0]) {
      return ''
    }
    return `${decades[0][1]} - ${decades[decades.length - 1][1]}`
  }, [decades])

  const previousDecades = useCallback(() => {
    setDecades((_decades) => {
      return generateDecades(_decades[0][0] - 1)
    })
  }, [])

  const nextDecades = useCallback(() => {
    setDecades((_decades) => {
      return generateDecades(_decades[_decades.length - 1][2] + 1)
    })
  }, [])

  return (
    <Box
      sx={{
        width: '252px',
        height: '310px',
      }}
    >
      <Header year={displayDecades} onNext={nextDecades} onPrevious={previousDecades}></Header>
      <Grid<number>
        date={date}
        dateThreshold={dateThreshold}
        type={CalendarType.Decades}
        range={range}
        data={decades}
        value={date.getFullYear()}
        onChange={onChangeYear}
      />
    </Box>
  )
}
