import { FC } from 'react'
import { Avatar, Box, FormLabel, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
  label: string
  value: string
  imgPath?: string
}

export const StaticFormField: FC<Props> = ({ label, value, imgPath }) => {
  const { t } = useTranslation()

  return (
    <>
      <FormLabel sx={{ fontSize: '12px', fontWeight: 600 }}>{t(label)}</FormLabel>
      <Box sx={{ display: 'flex' }}>
        {imgPath ? (
          <Avatar
            sx={{ width: 24, height: 24, mr: '8px', my: '8px', flexShrink: 0 }}
            src={imgPath}
          />
        ) : null}
        <Typography sx={{ py: '8px' }} variant='subtitle2' fontWeight={600}>
          {t(value)}
        </Typography>
      </Box>
    </>
  )
}
