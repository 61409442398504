import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, Divider } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RowsPerPage } from 'components/RowsPerPage'
import { setMyReportsPagination } from 'features/manageReports/slice'

interface Props {
  isSaveDisabled?: boolean
  isCreateMode?: boolean
  onClear?: () => void
  onSave?: () => void
}

export const ReportHeader: FC<Props> = ({ isSaveDisabled, isCreateMode, onClear, onSave }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { myReports } = useAppSelector((state) => state.manageReports)
  const dispatch = useAppDispatch()
  const { generatedReport } = useAppSelector((state) => state.manageReports)

  const isMyReports = useMemo(() => !!matchPath('/manage/reports/myreports', pathname), [pathname])

  const onChangeRowePerPage = useCallback(
    (value: number) => {
      dispatch(
        setMyReportsPagination({
          page: 1,
          size: value,
        })
      )
    },
    [dispatch]
  )

  return (
    <Box
      sx={{
        padding: '10px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'grey.100',
      }}
    >
      <Box
        sx={{
          fontFamily: 'Open Sans',
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '0em',
        }}
      >
        {t(`reports.title.${isCreateMode ? 'createReport' : 'reports'}`)}
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        {isMyReports && myReports.total > 10 && (
          <>
            <RowsPerPage
              totalItems={myReports.total}
              value={myReports.pagination.size}
              onChange={onChangeRowePerPage}
            />
            <Divider
              sx={{
                margin: '5px 0',
              }}
              orientation='vertical'
              flexItem
            />
          </>
        )}
        {isCreateMode ? (
          <Box>
            <Button
              sx={{
                marginRight: '16px',
              }}
              disabled={!generatedReport.result}
              onClick={onClear}
            >
              {t('reports.buttons.clear')}
            </Button>
            <Button disabled={isSaveDisabled} variant='contained' onClick={onSave}>
              {t('reports.buttons.save')}
            </Button>
          </Box>
        ) : (
          <Button
            variant='contained'
            onClick={() => {
              navigate('/manage/reports/create')
            }}
          >
            {t('reports.buttons.create')}
          </Button>
        )}
      </Box>
    </Box>
  )
}
