import React, { FC, ReactNode } from 'react'
import { Box, Chip, Select, SelectChangeEvent } from '@mui/material'
import { DropdownIcon } from 'components/icons/Dropdown.icon'
import { CustomFormLabel } from 'components/CustomFormLabel'
import { DeleteChipIcon } from 'components/icons/DeleteChip.icon'
import colors from 'theme/colors'

interface Props {
  placeholder: string
  children: React.ReactElement[]
  values: string[]
  readonly?: boolean
  label?: string
  error?: boolean
  onChange: (event: SelectChangeEvent<string[]>, child: ReactNode) => void
  onDelete?: (item: string) => void
  translationKeys?: { [key: string]: string }
}

export const SelectWithChips: FC<Props> = ({
  label,
  children,
  placeholder,
  error,
  values,
  readonly,
  onChange,
  onDelete,
  translationKeys,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '4px',
        '&&& .MuiSelect-select': {
          padding: `${values.length ? 3 : 6}px`,
          paddingRight: '38px',
        },
        '&&& .MuiSelect-icon': {
          color: 'common.black',
        },
      }}
    >
      {label && <CustomFormLabel value={label} error={error} />}
      {readonly ? (
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            flexWrap: 'wrap',
          }}
        >
          {values.map((value) => (
            <Chip sx={{ margin: 0 }} key={value} label={value} />
          ))}
        </Box>
      ) : (
        <Select
          sx={{
            width: '100%',
            lineHeight: '22px',
            borderRadius: '2px',

            '&&& fieldset': {
              borderColor: 'grey.100',
              borderWidth: '1px',

              ':hover': {
                borderColor: 'inherit',
              },

              legend: {
                maxWidth: '100%',
              },

              'legend > span': {
                color: 'black',
                opacity: 1,
              },
            },
            '&&&.Mui-error fieldset': {
              borderColor: 'error.main',
            },
          }}
          multiple
          value={values}
          onChange={onChange}
          IconComponent={DropdownIcon}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: '2px',
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.12)',
              },
            },
            autoFocus: false,
            sx: {
              top: '5px',
            },
          }}
          displayEmpty
          renderValue={(selected) => {
            if (!selected.length) {
              return (
                <Box
                  sx={{
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: colors.silverChalice,
                  }}
                >
                  {placeholder}
                </Box>
              )
            }

            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {
                  return (
                    <Chip
                      onMouseDown={(event) => {
                        event.stopPropagation()
                      }}
                      key={value}
                      label={translationKeys ? translationKeys[value] : value}
                      onDelete={() => onDelete && onDelete(value)}
                      deleteIcon={
                        <DeleteChipIcon
                          sx={{
                            width: '20px',
                            height: '20px',
                            marginRight: '4px',
                            color: colors.silverChalice,
                            cursor: 'pointer',
                            '&:hover': {
                              color: 'grey.800',
                            },
                          }}
                        />
                      }
                    />
                  )
                })}
              </Box>
            )
          }}
          data-testid='select-wrapper'
        >
          {children}
        </Select>
      )}
    </Box>
  )
}
