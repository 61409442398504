import { SortingDirection } from 'constants/sorting'
import {
  IPackageGroupModel,
  IPrice,
  IProduct,
  IProductGroup,
  ITerm,
  LicenseType,
} from 'features/subscriptionRequest/constants/subscription'
import colors from 'theme/colors'

export const DEFAULT_SUBSCRIPTION_SORTING = {
  column: 'submissionDate',
  direction: SortingDirection.DESC,
}

export const headers = {
  headers: {
    'x-action-origin': 'subscriptions',
  },
}

export const DEFAULT_PAGINATION = {
  page: 1,
  size: 10,
}

export enum PackageProcess {
  Approve = 'approve',
  Reject = 'reject',
}

export enum PackageStatus {
  Pending = 'Pending',
  PendingRenew = 'PendingRenew',
  PendingReactivation = 'PendingReactivation',
  Active = 'Active',
  Consumed = 'Consumed',
  Expired = 'Expired',
  Rejected = 'Rejected',
  Inactive = 'Inactive',
  Future = 'Future',
}

export const statusColor: Record<PackageStatus, string> = {
  [PackageStatus.Active]: colors.green['500'],
  [PackageStatus.Expired]: colors.grey['800'],
  [PackageStatus.Pending]: colors.orange,
  [PackageStatus.PendingRenew]: colors.orange,
  [PackageStatus.Consumed]: colors.grey['800'],
  [PackageStatus.PendingReactivation]: colors.orange,
  [PackageStatus.Rejected]: colors.red,
  [PackageStatus.Inactive]: colors.red,
  [PackageStatus.Future]: colors.redViolet,
}

export interface IPackageRole {
  id: number
  name: string
  productId: string
}

export interface IProcessPackage {
  id: string
  isApprove: boolean | null
}

export interface IProcessPackages {
  [key: string]: {
    packages: IProcessPackage[]
    role: IPackageRole | null
  }
}

export interface IPackage {
  id: string
  subscriptionId: string
  submittedDate: string
  name: string
  description: string
  packageGroupName: string
  packageGroup: IPackageGroupModel
  term: ITerm
  status: PackageStatus
  productRoleId: null
  licenseQuantity: number
  availableLicenseQuantity: number
  pendingLicenseQuantity: number
  reviewDate: string
  reviewedBy: string
  duration: number
  startDate: string
  endDate: string
  productId: string
  title: string
  longDescription: string
  soldBy: string
  licenseType: LicenseType
  prices: IPrice[]
  metadata?: { allowedActions: number[] }
  shortName?: string
}

export interface IComment {
  email: string
  firstName: string
  lastName: string
  comment: string
}

export interface ISubscription {
  id: string
  companyName: string
  countryName: string
  firstName: string
  lastName: string
  email: string
  department: string
  roleName: string
  parentEntity: string
  packages: IPackage[]
  stateNameAbbreviation: string
  countryNameAbbreviation: string
  hasComment: boolean
  metadata?: { allowedActions: number[] }
}

export interface ISubscriptionDetails extends ISubscription {
  trackingId: number
  address1: string
  city: string
  stateName: string
  zipCode: string
  groupId: string
  isMultiSubscriptionUser: boolean
}

export interface IManageProductState {
  selectedProduct: IProduct | null
  selectedProductGroup: IProductGroup | null
  products: IProduct[]
  productGroups: IProductGroup[]
  packageRoles: IPackageRole[]
  subscriptions: ISubscription[]
  totalSubscriptions: number
  pagination: {
    page: number
    size: number
  }
  loading: boolean
  error: string | null | undefined
}

export interface EditSubscriptionPayload {
  note: string
  subscriptionPackageIds?: string[]
}
