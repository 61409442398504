import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ProductGroupType } from 'features/productConfiguration/constants'
import {
  IAccountDetails,
  IBillingDetails,
  TBillingInfoErrors,
  IState,
} from 'features/subscriptionRequest/constants/subscription'
import {
  setBillingInfoError,
  setIsPaymentDetailsStepDisabled,
  updateAccountDetails,
  updateBillingDetails,
} from 'features/subscriptionRequest/slice'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  TValidForm,
  TForm,
  TFormFieldValid,
  TFormValidation,
} from 'features/subscriptionRequest/components/accountDetails/types'
import {
  defineFormValidationValues,
  filterFormFieldsByErrors,
  handleValidateForm,
} from 'features/subscriptionRequest/components/accountDetails/helpers'
import { selectIsValidAccountDetails } from '../slice/selectors'

export const useAccountDetailsFormHandlers = <T,>(
  formValues: T,
  requiredFields: string[],
  isFromBillingInfo: boolean
) => {
  const {
    subscription: { accountDetails, billingDetails },
  } = useAppSelector((state) => state.subscriptionRequest)
  const showErrorMessage = useRef(false)
  const stepContainerRef = useRef<Element | undefined>()
  const errorMessageHeight = 40
  const offset = 10
  const statusForm = useRef<{
    isDirtied: boolean
    isTyped: boolean
  }>({
    isDirtied: false,
    isTyped: false,
  })
  const dispatch = useAppDispatch()

  const [validForm, setValidForm] = useState<Partial<TValidForm<T>>>({})
  const isAccountDetailsValid = useAppSelector(selectIsValidAccountDetails)

  useEffect(() => {
    if (!isAccountDetailsValid) dispatch(setIsPaymentDetailsStepDisabled())
  }, [isAccountDetailsValid])

  useEffect(() => {
    const fieldsForValidation = ProductGroupType.All ? [...requiredFields, 'duns'] : [...requiredFields]
    const validationForm: Partial<T> = handleValidateForm(fieldsForValidation, formValues, statusForm)

    showErrorMessage.current = Object.keys(validationForm).some((key) => {
      const formField: TFormFieldValid = validationForm[key as keyof Partial<TValidForm<T>>]!

      return !formField.valid
    })

    stepContainerRef.current = document.getElementsByClassName('step-container')[0]

    setValidForm(validationForm as Partial<TValidForm<T>>)
    const errorsFilteredData = filterFormFieldsByErrors(validationForm as TFormValidation<T>)

    dispatch(setBillingInfoError(errorsFilteredData as TBillingInfoErrors))
  }, [])

  const scrollToInvalidField = useCallback(() => {
    if (showErrorMessage.current) {
      const invalidField = document.querySelector('.invalid-field') as HTMLElement
      if (stepContainerRef.current && invalidField) {
        stepContainerRef.current.scrollTo({
          left: 0,
          top: invalidField.offsetTop - errorMessageHeight - offset,
          behavior: 'smooth',
        })
      }
    }
  }, [])

  const isInvalid = (key: string) => {
    return validForm && validForm[key as keyof T] && statusForm.current.isDirtied && !validForm[key as keyof T]!.valid
  }

  const checkValidForm = useCallback(
    (field: string) => {
      if (Object.keys(formValues as TForm).length && statusForm.current.isTyped) {
        if (Object.prototype.hasOwnProperty.call(formValues, field)) {
          const form = {
            ...validForm,
            [field]: {
              valid: defineFormValidationValues(formValues[field as keyof T], field as string),
            },
          }

          if (!formValues[field as keyof T]) {
            delete form[field]
          }

          showErrorMessage.current = Object.keys(form).some((_key) => {
            return !form[_key as keyof T]!.valid
          })

          setValidForm(form)
          const errorsFilteredData = filterFormFieldsByErrors(form)

          dispatch(setBillingInfoError(errorsFilteredData as TBillingInfoErrors))

          Promise.resolve().then(() => scrollToInvalidField())

          statusForm.current.isTyped = false
        }
      }
    },
    [accountDetails, billingDetails]
  )

  const handleUpdateDetails = (key: string, value: string | IState) => {
    const updateBilling = () =>
      dispatch(updateBillingDetails({ key, value } as { key: keyof IBillingDetails; value: string | IState }))
    const updateAccount = () =>
      dispatch(updateAccountDetails({ key, value } as { key: keyof IAccountDetails; value: string | IState }))

    if (isFromBillingInfo) {
      return updateBilling()
    }

    updateAccount()
  }

  const updateFormDetails = (key: string, value: string | IState) => {
    statusForm.current.isDirtied = true
    statusForm.current.isTyped = true
    handleUpdateDetails(key, value)

    if (!value && validForm && validForm[key as keyof T]) {
      const transformedValidForm = { ...validForm }
      delete transformedValidForm[key as keyof T]
      showErrorMessage.current = Object.keys(transformedValidForm).some((_key) => {
        return !transformedValidForm[_key as keyof T]!.valid
      })
      statusForm.current.isTyped = false

      setValidForm(transformedValidForm)
    }
  }

  return {
    isInvalid,
    checkValidForm,
    statusForm,
    validForm,
    setValidForm,
    stepContainerRef,
    updateFormDetails,
    showErrorMessage,
  }
}
