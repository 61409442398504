import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import NotFound from 'components/NotFound'
import { AdminLayout } from 'components/layouts'

import { ProductLayout } from 'features/manageProduct/components/ProductLayout'
import { AdminContainer } from 'features/manageAdmins/components/adminContainer'
import { SubscriptionList } from 'features/manageProduct/components/SubscriptionList'
import { AuthContainer } from './AuthContainer'
import { Login } from 'components/Login'
import { RouterGuard } from 'components/RouterGuard'
import { CreateSubscription } from 'features/subscriptionRequest/components/createSubscription'
import { store } from './store'
import { fetchProductGroup } from 'features/subscriptionRequest/slice/services'
import { ServerError } from 'components/ServerError'
import { ReportLayout } from 'features/manageReports/component/ReportLayout'
import { MyReports } from 'features/manageReports/component/MyReports'
import { ReportDetails } from 'features/manageReports/component/ReportDetails'

import { getAvailableLanguages } from 'sharedSlices/localization'
import { ProductConfigurationLayout } from 'features/productConfiguration/component/ProductConfigurationLayout'
import { ProductConfigurationContainer } from 'features/productConfiguration/component/ProductConfigurationContainer'
import { AcceptableUsePolicyPreview } from 'features/productConfiguration/component/AcceptableUsePolicyPreview'
import { EditAccountDetails } from 'features/subscriptionRequest/components/editAccountDetails'
import { PrivacyPreview } from 'features/subscriptionRequest/components/PrivacyPreview'
import { showVersions } from 'utils/showVersions'
import { AppInsightsContainer } from './AppInsightsContainer'
import { RequestLoader } from 'components/RequestLoader'
import { ProfileSettingsPopup } from 'components/layouts/routerLayout/ProfileSettingsPopup'
import { useAppDispatch, useAppSelector } from './hooks'
import { handleProfileSettings } from 'sharedSlices/ui'
import { ManageUsersLayout } from 'components/layouts/manageUsersLayout'
import { ManageUsersContainer } from 'features/manageUsers/components/manageUsersContainer'
import { AcceptableUsePolicyForEndUser } from 'components/AcceptableUsePolicyForEndUser'
import { SubscriptionsImportPage } from 'features/subscriptionsImport/component/SubscriptionsImportPage'
import { geoApi } from 'services/api/geoApi'
import { PackageContainer } from 'features/managePackage/components/PackageContainer'
import { RenewSubscription } from 'features/subscriptionRequest/components/RenewSubscription'
import { SubscriptionOverview } from 'features/subscriptionRequest/components/SubscriptionOverview'

const router = createBrowserRouter([
  {
    path: '',
    element: <AuthContainer />,
    children: [
      {
        path: '',
        element: <Navigate to='subscribe/ibr' />,
      },
      {
        path: 'subscribe',
        errorElement: <NotFound />,
        children: [
          {
            path: '',
            element: <Navigate to='/subscribe/ibr' />,
          },
          {
            path: ':productGroupShortName',
            element: <CreateSubscription />,
            loader: async ({ params }: { params: { productGroupShortName?: string } }) => {
              return (
                params.productGroupShortName &&
                store.dispatch(fetchProductGroup(params.productGroupShortName as string))
              )
            },
          },
          {
            path: ':productGroupShortName/success',
            element: <SubscriptionOverview />,
          },
          {
            path: 'subscription/:subscriptionId',
            children: [
              {
                path: 'package/:packageId',
                element: <RenewSubscription />,
              },
              {
                path: 'account-details',
                element: <EditAccountDetails />,
              },
            ],
          },
        ],
      },
      {
        path: '/:productGroupShortName/acceptableUsePolicy',
        element: <AcceptableUsePolicyForEndUser />,
      },
      {
        path: 'privacy',
        element: <PrivacyPreview />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'manage',
        element: <RouterGuard />,
        children: [
          {
            path: '',
            element: <Navigate to='subscriptions' />,
          },
          {
            path: 'subscriptions',
            element: <PackageContainer />,
          },
          {
            path: 'product/',
            element: <ProductLayout />,
            children: [
              {
                path: '',
                element: <Navigate to='subscriptions' />,
              },
              {
                path: 'subscriptions',
                element: <SubscriptionList />,
              },
            ],
          },
          {
            path: 'user/',
            element: <ManageUsersLayout />,
            children: [
              {
                path: 'subscriptions/:subscriptionId',
                element: <ManageUsersContainer />,
              },
            ],
          },
          {
            path: 'productConfiguration',
            children: [
              {
                path: '',
                element: <ProductConfigurationLayout />,
              },
              {
                path: 'create',
                children: [
                  {
                    path: '',
                    element: <ProductConfigurationContainer />,
                  },
                  {
                    path: 'policy-preview',
                    element: <AcceptableUsePolicyPreview />,
                  },
                ],
              },
            ],
          },
          {
            path: 'admin/',
            element: <AdminLayout />,
            children: [
              {
                path: ':roleName',
                element: <AdminContainer />,
              },
            ],
          },
          {
            path: 'reports/create',
            element: <ReportDetails />,
          },
          {
            path: 'reports/edit/:reportId',
            element: <ReportDetails />,
          },
          {
            path: 'reports/',
            element: <ReportLayout />,
            children: [
              {
                path: '',
                element: <Navigate to='/manage/reports/myreports' />,
              },
              {
                path: 'myreports',
                element: <MyReports />,
              },
            ],
          },
        ],
      },
      {
        path: 'subscriptions-import/:productGroupShortName',
        element: <SubscriptionsImportPage />,
      },
      {
        path: 'servererror',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
])

function App(): JSX.Element {
  const dispatch = useAppDispatch()
  const { isProfileSettingsOpen } = useAppSelector((state) => state.ui)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    store.dispatch(getAvailableLanguages())
    geoApi.getGeoMapping().then(() => setLoading(false))
    showVersions()
  }, [])

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
    script.setAttribute('data-domain-script', window.APP_CONFIG.COOKIE_ID)
    document.body.appendChild(script)

    if (!/OptanonAlertBoxClosed/.test(document.cookie)) {
      const observer = new MutationObserver((mutationsList, _observer) => {
        for (const mutation of mutationsList) {
          const addedNodes = Array.from(mutation.addedNodes)
          for (const node of addedNodes) {
            if ((node as Element).id === 'onetrust-consent-sdk') {
              _observer.disconnect()
              const cookieNoticeContainer = document.getElementById('onetrust-policy-text')

              if (cookieNoticeContainer) {
                const link = document.createElement('a')
                link.href = `${process.env.PUBLIC_URL}/pdf/Cookie Notice Template.pdf`
                link.textContent = 'Cookie Notice.'
                link.target = '_blank'
                link.rel = 'noopener'

                const existingLink = cookieNoticeContainer.querySelector('a')
                if (existingLink) {
                  cookieNoticeContainer.replaceChild(link, existingLink)
                }
              }
              return
            }
          }
        }
      })

      observer.observe(document.body, { childList: true })
    }
  }, [])

  return !loading ? (
    <Box sx={{ height: '100vh' }}>
      <AppInsightsContainer>
        <RouterProvider router={router} />
        <RequestLoader />
      </AppInsightsContainer>
      <ProfileSettingsPopup open={isProfileSettingsOpen} onClose={() => dispatch(handleProfileSettings(false))} />
    </Box>
  ) : (
    <></>
  )
}

export default App
