import { Avatar, Box } from '@mui/material'
import { ICountry } from 'features/managePackage/constants'
import { useTranslation } from 'react-i18next'

type ICountryTemplateProps = {
  country?: ICountry | null
}

const CountryTemplate = ({ country }: ICountryTemplateProps) => {
  const { abbreviation } = country || {}

  const { t } = useTranslation()

  if (country === null || country === undefined) {
    return null
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', pl: '8px' }}>
      <Avatar
        sx={{ width: 24, height: 24, mr: '8px', flexShrink: 0 }}
        src={`${process.env.PUBLIC_URL}/images/flags/${abbreviation}.svg`}
      />
      <Box fontSize='14px'>{t(`country.${abbreviation?.toLowerCase()}.name`)}</Box>
    </Box>
  )
}

export default CountryTemplate
