import {
  EmailValidationError,
  notDeloitteEmailRegEx,
} from 'features/subscriptionRequest/constants/email'
import { geoApi } from 'services/api/geoApi'

export const isValidEmail = (email: string): boolean => {
  return notDeloitteEmailRegEx.test(email)
}

type ValidateEmailParams = {
  email: string
  productId?: string
  url: string
}

export function validateEmailWithProductId({
  email,
  productId,
  url,
}: ValidateEmailParams): Promise<EmailValidationError> {
  const headers = {
    'x-product-id': productId,
  }
  return geoApi
    .postAllGeo<EmailValidationError>(url, { email }, { headers })
    .then((emailValidationStatus) => Math.max(...emailValidationStatus))
}

export const validateEmail = {
  asEndUser: (email: string, productId: string) =>
    validateEmailWithProductId({ email, productId, url: 'global/user/email/validation' }),
  asAnonymousUser: (email: string) =>
    validateEmailWithProductId({ email, url: 'global/user/email/validation/anonymous' }),
  asAdmin: (email: string, productId: string) =>
    validateEmailWithProductId({ email, productId, url: 'global/user/email/validation/admin' }),
}
