import { Modal, Box, SxProps } from '@mui/material'
import { UpdateAdminsForm } from '../updateAdminsForm'
import { useAppSelector } from 'app/hooks'

const modalStyle: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '640px',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0 0 2px 2px',
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.12)',
}

export const UpdateAdminsModal = () => {
  const { isUpdateAdminsModalOpen } = useAppSelector((state) => state.ui)

  return (
    <Modal open={isUpdateAdminsModalOpen}>
      <Box sx={modalStyle}>
        <Box sx={{ p: 0 }}>
          <UpdateAdminsForm />
        </Box>
      </Box>
    </Modal>
  )
}
