import { Box, Button, TextField } from '@mui/material'
import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react'

import NextPageIcon from 'assets/images/NextPage.svg'
import LastPageIcon from 'assets/images/LastPage.svg'
import { useTranslation } from 'react-i18next'
import { CurrentPagePosition, Key, PaginationDirection, ICustomPaginationProps } from '../constants'
import colors from 'theme/colors'

export const CustomPagination = ({ count, rowsPerPage, changeCurrentPage, currentPage }: ICustomPaginationProps) => {
  const maxPage = Math.ceil(count / rowsPerPage)
  const { t } = useTranslation()
  const [selectedPage, setSelectedPage] = useState<number | null>(null)
  const [pages, setPages] = useState<{
    numberPages: number[]
    page: number
  }>({
    numberPages: [],
    page: 0,
  })

  const getCurrentPagePosition = useCallback((page: number) => {
    switch (page % 3) {
      case 0:
        return CurrentPagePosition.End
      case 1:
        return CurrentPagePosition.Start
      default:
        return CurrentPagePosition.Middle
    }
  }, [])

  const generatePagination = useCallback(
    (totalItems: number, itemsPerPage: number, page: number): number[] => {
      const totalPages = Math.ceil(totalItems / itemsPerPage)

      if (page > totalPages) {
        page = totalPages
      }

      const position = getCurrentPagePosition(page)
      const pagination: number[] = []
      const offset = position === CurrentPagePosition.Start ? 0 : position === CurrentPagePosition.End ? -2 : -1
      for (let i = 0; i < 3; i++) {
        const _page = page + offset + i
        if (_page >= 1 && _page <= totalPages) {
          pagination.push(_page)
        }
      }

      return pagination
    },
    [getCurrentPagePosition]
  )

  useEffect(() => {
    const _pages = generatePagination(count, rowsPerPage, currentPage)
    setPages({
      numberPages: _pages,
      page: currentPage,
    })

    if (currentPage > maxPage) {
      changeCurrentPage({ page: maxPage })
    }
  }, [rowsPerPage, count, currentPage, generatePagination, maxPage, changeCurrentPage])

  const changesPages = useCallback(
    (direction: PaginationDirection) => {
      setSelectedPage(null)
      let page = 0

      if ([PaginationDirection.NextPages, PaginationDirection.PreviousPage].includes(direction)) {
        page =
          direction === PaginationDirection.NextPages
            ? pages.numberPages[pages.numberPages.length - 1] + 1
            : pages.numberPages[0] - 3
      } else {
        page = direction === PaginationDirection.LastPages ? maxPage : 1
      }

      changeCurrentPage({ page })
    },
    [changeCurrentPage, maxPage, pages.numberPages]
  )

  const onChangeInputValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.target.value)

      if (value >= 1 && value <= maxPage) {
        setSelectedPage(value)
      } else {
        setSelectedPage(null)
      }
    },
    [maxPage]
  )

  const onChangeKeyDown = useCallback(
    (event: KeyboardEvent<HTMLImageElement>) => {
      if (event.key === Key.Enter && selectedPage) {
        changeCurrentPage({ page: selectedPage })
      }
    },
    [selectedPage, changeCurrentPage]
  )

  return (
    <Box
      id='pagination'
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        minHeight: '48px',
        paddingRight: '15px',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'grey.100',

        fontFamily: 'Open Sans',

        button: {
          maxHeight: '24px',
          minWidth: '24px',
          padding: '0',
          marginRight: '8px',
        },
      }}
    >
      {!pages.numberPages.includes(1) && (
        <>
          <Button
            onClick={() => {
              changesPages(PaginationDirection.FistPages)
            }}
          >
            <Box
              component='img'
              sx={{
                transform: 'rotate(180deg)',
              }}
              src={LastPageIcon}
            ></Box>
          </Button>
          <Button
            onClick={() => {
              changesPages(PaginationDirection.PreviousPage)
            }}
          >
            <Box
              component='img'
              sx={{
                transform: 'rotate(180deg)',
              }}
              src={NextPageIcon}
            ></Box>
          </Button>
        </>
      )}
      {pages.numberPages.map((page) => {
        return (
          <Button
            key={page}
            variant={currentPage === page ? 'contained' : 'text'}
            onClick={() => {
              changeCurrentPage({ page })
              if (selectedPage) {
                setSelectedPage(null)
              }
            }}
          >
            {page}
          </Button>
        )
      })}
      {!pages.numberPages.includes(maxPage) && (
        <>
          <Button
            onClick={() => {
              changesPages(PaginationDirection.NextPages)
            }}
          >
            <Box component='img' src={NextPageIcon}></Box>
          </Button>
          <Button
            onClick={() => {
              changesPages(PaginationDirection.LastPages)
            }}
          >
            <Box component='img' src={LastPageIcon}></Box>
          </Button>
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',

          fontSize: '14px',
          color: colors.mineShaft,
        }}
      >
        {t('pagination.page')}
        <TextField
          sx={{
            width: '48px',
            margin: '0 10px',

            '&&& ::-webkit-inner-spin-button': {
              MozAppearance: 'none',
              WebkitAppearance: 'none',
            },

            '&&& fieldset': {
              borderColor: 'grey.100',
              borderWidth: '1px',
              borderRadius: '2px',

              ':hover': {
                borderColor: 'inherit',
              },
            },
          }}
          value={selectedPage ?? ''}
          placeholder='1'
          type='number'
          onKeyDown={onChangeKeyDown}
          onChange={onChangeInputValue}
          inputProps={{ inputMode: 'numeric', min: 1, max: maxPage }}
        ></TextField>
        {t('pagination.of')}
        <Box sx={{ marginLeft: '8px' }}>{maxPage}</Box>
      </Box>
    </Box>
  )
}
