import { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHref, useSearchParams } from 'react-router-dom'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { fetchCountries, fetchPrivacy, fetchStates } from 'features/subscriptionRequest/slice/services'
import { setIsPrivacyAccepted, setPrivacy, updateAccountDetails } from 'features/subscriptionRequest/slice'
import {
  IAccountDetails,
  IBillingDetails,
  ICountry,
  ProductGroupType,
  requiredAccountDetails,
  requiredBillingDetails,
} from 'features/subscriptionRequest/constants/subscription'
import { PrivacyViewType } from 'features/productConfiguration/constants'
import { RestrictedCountryPopup } from 'features/subscriptionRequest/components/RestrictedCountryPopup'
import { CustomTextField } from 'components/CustomTextField'
import { CustomAlert } from 'components/CustomAlert'
import NewWindowIcon from 'assets/images/NewWindow.svg'
import { Summary } from './component/summary'
import { getValidExternalURL } from 'utils/externalURL'
import CustomForm from 'components/CustomForm'
import colors from 'theme/colors'
import StateSelect from '../shared-components/StateSelect'
import { CountrySelect } from '../shared-components/CountrySelect'
import { emptyFieldValue, phoneFieldStyles } from './constants'
import StateTemplate from '../shared-components/StateTemplate'
import CountryTemplate from '../shared-components/CountryTemplate'
import ValidationErrorMessage from '../shared-components/ValidationErrorMessage'
import { useAccountDetailsFormHandlers } from 'features/subscriptionRequest/hooks/useAccountDetailsFormHandlers'

export interface IAccountDetailsProps {
  jurisdiction?: ICountry | null
  infoMessage?: string
  showPrivacyPolicy: boolean
  showSummary?: boolean
  editMode?: boolean
  isAllFormDisabled?: boolean
  isFromBillingInfo?: boolean
  hasNoTitle?: boolean
  customEmailLabel?: string
  customFirstNameLabel?: string
  customLastNameLabel?: string
}

export const AccountDetails = ({
  jurisdiction,
  infoMessage,
  showPrivacyPolicy = true,
  showSummary = false,
  editMode = false,
  isAllFormDisabled = false,
  isFromBillingInfo = false,
  hasNoTitle = false,
  customEmailLabel,
  customFirstNameLabel,
  customLastNameLabel,
}: IAccountDetailsProps) => {
  const {
    states,
    isPrivacyAccepted,
    privacy,
    productGroup,
    subscription: { email: subscriptionEmail, accountDetails, country: currentCountry, billingDetails },
  } = useAppSelector((state) => state.subscriptionRequest)
  const { endUserInformation } = useAppSelector((state) => state.auth)
  const { t } = useTranslation()
  const [search] = useSearchParams()
  const dispatch = useAppDispatch()
  const previewUrl = useHref('/privacy')
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [openRestrictedCountryPopup, setOpenRestrictedCountryPopup] = useState(false)

  const subscribeToProduct = Boolean(search.get('subscribeToProduct'))
  const isInitialized = useRef(false)
  const phoneAndCompanyBoxReducedWidth = isFromBillingInfo ? 'calc((100% - 24px) / 2)' : '50%'
  const phoneFieldBillingFormStyle = isFromBillingInfo && phoneFieldStyles
  const departAddressFieldBillingStyle = isFromBillingInfo && { marginTop: '-24px' }

  const formValues = isFromBillingInfo ? billingDetails : accountDetails
  const requiredFields = isFromBillingInfo ? requiredBillingDetails : requiredAccountDetails
  type TFormDataType = typeof isFromBillingInfo extends true ? IBillingDetails : IAccountDetails
  const { isInvalid, checkValidForm, updateFormDetails, showErrorMessage } =
    useAccountDetailsFormHandlers<TFormDataType>(formValues as TFormDataType, requiredFields, isFromBillingInfo)

  const emailValue = !isFromBillingInfo ? subscriptionEmail : billingDetails?.email
  const isErrorValidationMessageShown = showErrorMessage.current && !isFromBillingInfo
  const isFromProdGroupTypeAll = productGroup.type === ProductGroupType.All
  const isTitleShown = (isFromProdGroupTypeAll || editMode) && !hasNoTitle
  const isPhoneFieldDisbled = !!endUserInformation || (subscribeToProduct ? isReadOnly : false) || isAllFormDisabled
  const policyPreviewLink = privacy.link ? getValidExternalURL(privacy.link).href : previewUrl

  const getOptionalFieldValue = (value?: string) => {
    return isAllFormDisabled && !value ? emptyFieldValue : value
  }

  useEffect(() => {
    if (!privacy.text && !privacy.link && currentCountry) {
      dispatch(fetchPrivacy(currentCountry!.productId))
    }

    // Temporary DART privacy handling
    if (productGroup.type === ProductGroupType.All) {
      dispatch(fetchCountries())
      dispatch(
        setPrivacy({
          privacyViewTypeId: PrivacyViewType.CheckboxWithLink,
          title: 'Deloitte Privacy Statement',
          link: `${window.location.origin}/pdf/Empty.pdf`,
          text: '',
        })
      )
    }
  }, [])

  useEffect(() => {
    if (!states.length && jurisdiction) {
      dispatch(fetchStates(jurisdiction.id))
    }
  }, [states.length, jurisdiction?.id, dispatch])

  useEffect(() => {
    if (!isInitialized.current) {
      setIsReadOnly(!!accountDetails.companyFullName)
      isInitialized.current = true
    }
  }, [accountDetails.companyFullName])

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ValidationErrorMessage
              isMessageShown={isErrorValidationMessageShown}
              text={t(isInvalid('duns') ? 'account.duns.error' : 'errors.required')!}
            />

            {infoMessage && <CustomAlert severity='info' text={t(infoMessage)!} />}
            <Box
              sx={{
                width: '652px',
                display: 'flex',
                flexDirection: 'column',
                padding: '24px',
                margin: '0 auto',
                gap: '24px',
                '& > *': {
                  display: 'flex',
                  gap: '24px',
                  '& > *': { width: '50%' },
                },
              }}
            >
              {isTitleShown && <Box sx={{ fontSize: '18px', lineHeight: '24px' }}>{t('steps.accountDetails')}</Box>}
              <Box>
                {!isFromProdGroupTypeAll && (
                  <CustomTextField
                    sx={{ width: '100%' }}
                    label={t('account.country')!}
                    value={jurisdiction!.name}
                    readonly
                    readonlyTemplate={() => <CountryTemplate country={jurisdiction} />}
                  />
                )}
                <CustomTextField
                  sx={{
                    width: '100%',
                    '.MuiTypography-body2': { p: '8px 0' },
                  }}
                  label={customEmailLabel || t('userEmail.label')!}
                  value={emailValue as string}
                  readonly={!isFromBillingInfo}
                  onChange={(e) => {
                    isFromProdGroupTypeAll && updateFormDetails('email', e.target.value)
                  }}
                  placeholder={t('userEmail.placeholder')}
                  onBlur={() => isFromBillingInfo && 'email' in formValues && checkValidForm('email')}
                  error={isFromBillingInfo && isInvalid('email')}
                  required={isFromBillingInfo || isAllFormDisabled}
                />
                {isFromProdGroupTypeAll && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <CustomForm>
                      <CustomForm.Label
                        required
                        styles={{
                          marginBottom: '8px',
                          fontFamily: 'Open Sans SemiBold',
                          fontSize: '12px',
                          color: 'grey.800',
                          '.MuiFormLabel-asterisk': {
                            color: 'error.main',
                          },
                        }}
                        value={'account.country'}
                      />
                      <CountrySelect
                        isAllFormDisabled={isAllFormDisabled}
                        isFromBillingInfo={isFromBillingInfo}
                        selectedCountry={formValues?.country as ICountry}
                        setOpenRestrictedCountryPopup={setOpenRestrictedCountryPopup}
                      />
                    </CustomForm>
                  </Box>
                )}
              </Box>
              <Box>
                <CustomTextField
                  required
                  placeholder={t('account.firstName.placeholder')!}
                  label={customFirstNameLabel || t('account.firstName.label')!}
                  value={formValues.firstName}
                  error={isInvalid('firstName')}
                  onChange={(e) => {
                    updateFormDetails('firstName', e.target.value)
                  }}
                  onBlur={() => checkValidForm('firstName')}
                  readonly={isAllFormDisabled}
                />
                <CustomTextField
                  required
                  placeholder={t('account.lastName.placeholder')!}
                  label={customLastNameLabel || t('account.lastName.label')!}
                  value={formValues.lastName}
                  error={isInvalid('lastName')}
                  onChange={(e) => {
                    updateFormDetails('lastName', e.target.value)
                  }}
                  onBlur={() => checkValidForm('lastName')}
                  readonly={isAllFormDisabled}
                />
              </Box>
              {isFromProdGroupTypeAll && (
                <Box>
                  {!isFromBillingInfo && (
                    <CustomTextField
                      placeholder={t('account.role.placeholder')!}
                      label={t('account.role.label')!}
                      value={getOptionalFieldValue(accountDetails.role)}
                      onChange={(e) => dispatch(updateAccountDetails({ key: 'role', value: e.target.value }))}
                      readonly={isAllFormDisabled}
                    />
                  )}
                  <CustomTextField
                    placeholder={t('account.phone.placeholder')!}
                    label={t('account.phone.label')!}
                    value={getOptionalFieldValue(formValues.phone)}
                    onChange={({ target: { value } }) => {
                      if (/^(?:\d+)?$/.test(value)) {
                        updateFormDetails('phone', value)
                      }
                    }}
                    readonly={isAllFormDisabled}
                    sx={{ width: phoneAndCompanyBoxReducedWidth }}
                  />
                </Box>
              )}
              <Box>
                <CustomTextField
                  sx={{
                    width: phoneAndCompanyBoxReducedWidth,
                    '& p': {
                      fontWeight: endUserInformation ? 600 : 400,
                    },
                    ...phoneFieldBillingFormStyle,
                  }}
                  required
                  placeholder={t('account.companyName.placeholder')!}
                  readonly={isPhoneFieldDisbled}
                  label={t('account.companyName.label')!}
                  value={formValues.companyFullName}
                  error={isInvalid('companyFullName')}
                  onChange={(e) => {
                    updateFormDetails('companyFullName', e.target.value)
                  }}
                  onBlur={() => checkValidForm('companyFullName')}
                />
                {!isFromBillingInfo && (
                  <CustomTextField
                    placeholder={t('account.parentName.placeholder')!}
                    label={t('account.parentName.label')!}
                    value={getOptionalFieldValue(accountDetails.globalParentFullName)}
                    onChange={(e) =>
                      dispatch(updateAccountDetails({ key: 'globalParentFullName', value: e.target.value }))
                    }
                    readonly={isAllFormDisabled}
                  />
                )}
              </Box>
              {isFromProdGroupTypeAll && !isFromBillingInfo && (
                <Box sx={{ flexDirection: 'column' }}>
                  <CustomTextField
                    sx={{
                      width: '100%',
                      '.MuiTypography-caption': {
                        color: 'grey.800',
                        lineHeight: '16px',
                      },
                    }}
                    error={isInvalid('duns')}
                    placeholder={t('account.duns.placeholder')!}
                    label={t('account.duns.label')!}
                    value={getOptionalFieldValue(accountDetails.duns)}
                    helperText={
                      <Trans i18nKey='account.duns.note'>
                        <a
                          style={{ textDecoration: 'none', color: colors.blue.main }}
                          href='https://www.dnb.com/duns-number/lookup.html'
                          target='blank'
                        >
                          here
                        </a>
                      </Trans>
                    }
                    onChange={({ target: { value } }) => {
                      if (/^(?:\d{1,9})?$/.test(value)) {
                        updateFormDetails('duns', value)
                      }
                    }}
                    onBlur={() => checkValidForm('duns')}
                    readonly={isAllFormDisabled}
                  />
                </Box>
              )}
              <Box sx={{ ...departAddressFieldBillingStyle }}>
                <CustomTextField
                  placeholder={t('account.department.placeholder')!}
                  label={t('account.department.label')!}
                  value={getOptionalFieldValue(formValues.department)}
                  onChange={({ target: { value } }) => updateFormDetails('department', value)}
                  readonly={isAllFormDisabled}
                />
                {isFromProdGroupTypeAll ? (
                  <CustomTextField
                    required
                    sx={{ width: '50%' }}
                    placeholder={t('account.address.placeholder')!}
                    label={t('account.address.label')!}
                    value={formValues.address}
                    error={isInvalid('address')}
                    onChange={(e) => {
                      updateFormDetails('address', e.target.value)
                    }}
                    onBlur={() => checkValidForm('address')}
                    readonly={isAllFormDisabled}
                  />
                ) : (
                  <CustomTextField
                    placeholder={t('account.role.placeholder')!}
                    label={t('account.role.label')!}
                    value={accountDetails.role}
                    onChange={(e) => dispatch(updateAccountDetails({ key: 'role', value: e.target.value }))}
                  />
                )}
              </Box>
              <Box>
                {isFromProdGroupTypeAll ? (
                  <CustomTextField
                    placeholder={t('account.address2.placeholder')!}
                    label={t('account.address2.label')!}
                    value={getOptionalFieldValue(formValues.address2)}
                    onChange={(e) => updateFormDetails('address2', e.target.value)}
                    readonly={isAllFormDisabled}
                  />
                ) : (
                  <CustomTextField
                    required
                    sx={{ width: '396px!important' }}
                    placeholder={t('account.address.placeholder')!}
                    label={t('account.address.label')!}
                    value={accountDetails.address}
                    error={isInvalid('address')}
                    onChange={(e) => {
                      updateFormDetails('address', e.target.value)
                    }}
                    onBlur={() => checkValidForm('address')}
                  />
                )}
                <CustomTextField
                  required
                  sx={{ width: isFromProdGroupTypeAll ? '50%' : '184px!important' }}
                  placeholder={t('account.zipCode.placeholder')!}
                  label={t('account.zipCode.label')!}
                  value={formValues.zipCode}
                  error={isInvalid('zipCode')}
                  onChange={(e) => {
                    updateFormDetails('zipCode', e.target.value)
                  }}
                  onBlur={() => checkValidForm('zipCode')}
                  readonly={isAllFormDisabled}
                />
              </Box>
              <Box>
                <StateSelect
                  isAllFormDisabled={isAllFormDisabled}
                  isFromBillingInfo={isFromBillingInfo}
                  handleInvalidate={isInvalid}
                  checkValidForm={checkValidForm}
                  updateFormDetails={updateFormDetails}
                  value={formValues.state?.id.toString()}
                  selectedStateTemplate={() => (
                    <StateTemplate selectedState={formValues.state} selectedCountry={formValues.country} />
                  )}
                />
                <CustomTextField
                  required
                  placeholder={t('account.city.placeholder')!}
                  label={t('account.city.label')!}
                  value={formValues.city}
                  error={isInvalid('city')}
                  onChange={(e) => {
                    updateFormDetails('city', e.target.value)
                  }}
                  onBlur={() => checkValidForm('city')}
                  readonly={isAllFormDisabled}
                />
              </Box>
              {showPrivacyPolicy && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '12px',
                      color: 'primary.main',
                      width: '100%',

                      a: {
                        textDecoration: 'none',
                        color: 'primary.main',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '20px',

                        a: {
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          fontFamily: 'Open Sans',
                          fontSize: '14px',
                        },
                      }}
                    >
                      <a
                        target='_blank'
                        href={policyPreviewLink}
                        rel='noreferrer'
                        onClick={() => {
                          privacy.text && localStorage.setItem('privacyPreview', JSON.stringify(privacy.text))
                        }}
                      >
                        {privacy.title}
                        <Box component='img' src={NewWindowIcon} alt='new window icon' />
                      </a>
                    </Box>
                  </Box>
                  {privacy.privacyViewTypeId === PrivacyViewType.CheckboxWithLink && (
                    <FormControlLabel
                      sx={{
                        '& span': {
                          fontSize: '14px',
                        },
                        width: '100%',
                        '.MuiCheckbox-root': {
                          padding: '0 9px',
                        },
                        a: {
                          textDecoration: 'none',
                          color: 'primary.main',
                        },
                      }}
                      control={
                        <Checkbox
                          disableRipple
                          size='small'
                          checked={isPrivacyAccepted}
                          onChange={() => dispatch(setIsPrivacyAccepted(!isPrivacyAccepted))}
                        />
                      }
                      label={t('account.privacy')}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
          {showSummary && <Summary />}
          {accountDetails.country && (
            <RestrictedCountryPopup
              open={openRestrictedCountryPopup}
              onClose={() => setOpenRestrictedCountryPopup(false)}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
