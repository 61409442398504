import { SvgIcon, SvgIconProps } from '@mui/material'

export const SortDescIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.41012 4.50806L7.5 4.5C7.74546 4.5 7.94961 4.67688 7.99194 4.91012L8 5V17.792L10.1464
           15.6464C10.32 15.4729 10.5894 15.4536 10.7843 15.5886L10.8536 15.6464C11.0271 15.82
           11.0464 16.0894 10.9114 16.2843L10.8536 16.3536L7.85355 19.3536C7.67999 19.5271 7.41056
           19.5464 7.21569 19.4114L7.14645 19.3536L4.14645 16.3536C3.95118 16.1583 3.95118 15.8417
           4.14645 15.6464C4.32001 15.4729 4.58944 15.4536 4.78431 15.5886L4.85355 15.6464L7
           17.792V5C7 4.75454 7.17688 4.55039 7.41012 4.50806ZM14.5 17C14.7761 17 15 17.2239 15
           17.5C15 17.7761 14.7761 18 14.5 18H13.5C13.2239 18 13 17.7761 13 17.5C13 17.2239 13.2239
           17 13.5 17H14.5ZM15.5 14C15.7761 14 16 14.2239 16 14.5C16 14.7761 15.7761 15 15.5
           15H13.5C13.2239 15 13 14.7761 13 14.5C13 14.2239 13.2239 14 13.5 14H15.5ZM16.5 11C16.7761
           11 17 11.2239 17 11.5C17 11.7761 16.7761 12 16.5 12H13.5C13.2239 12 13 11.7761 13 11.5C13
           11.2239 13.2239 11 13.5 11H16.5ZM18.5 8C18.7761 8 19 8.22386 19 8.5C19 8.77614 18.7761 9
           18.5 9H13.5C13.2239 9 13 8.77614 13 8.5C13 8.22386 13.2239 8 13.5 8H18.5ZM20.5 5C20.7761
           5 21 5.22386 21 5.5C21 5.77614 20.7761 6 20.5 6H13.5C13.2239 6 13 5.77614 13 5.5C13
           5.22386 13.2239 5 13.5 5H20.5Z"
        />
      </SvgIcon>
    </>
  )
}
