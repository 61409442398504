import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import {
  fetchAcceptableUsePolicies,
  fetchAcceptableUsePolicy,
  fetchCountries,
  fetchPrivacy,
  fetchProduct,
  fetchProductGroup,
  fetchProductPackageGroups,
  fetchProductPackages,
  fetchProducts,
  fetchStates,
  getUserInfo,
  fetchBillingStates,
  fetchTaxes,
} from '../services'
import { ISubscriptionRequestState } from '../interfaces'
import {
  DeloitteClientType,
  ICountry,
  IPolicyOptionsAccepted,
  IProductGroup,
  LicenseType,
  notApplicableState,
  ProductFeature,
} from 'features/subscriptionRequest/constants/subscription'
import { sortedPackages } from 'utils/sortPackages'
import { getPackageGroup } from 'features/subscriptionRequest/components/packageGroup/services/getPackageGroup'

export const extraReducersBuilder = (builder: ActionReducerMapBuilder<ISubscriptionRequestState>) => {
  return (
    builder
      .addCase(fetchProductGroup.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchProductGroup.fulfilled, (state, action) => {
        state.loading = false
        const productGroup = action.payload
          .filter((_productGroup) => _productGroup)
          .reduce(
            (prodGroups, prodGroup) => ({
              ...prodGroup,
              products: [...(prodGroups.products || []), ...prodGroup.products],
            }),
            {} as IProductGroup
          )

        if (productGroup) {
          state.productGroup = productGroup
          if (
            productGroup.products.length > 0 &&
            productGroup.products[0].features.includes(ProductFeature.AuditClient)
          ) {
            state.subscription.isDeloitteClient = DeloitteClientType.No
          }
        }

        state.countries = action.payload
          .filter((_productGroup) => _productGroup)
          .flatMap((item) => item.products)
          .flatMap((product) =>
            product.countries ? product.countries.map((country) => ({ ...country, productId: product.id })) : []
          )
          .reduce((acc, country) => {
            if (!acc.find((_country) => _country.id === country.id)) {
              acc.push(country)
            }
            return acc
          }, [] as ICountry[])
          .sort((a, b) => a.name.localeCompare(b.name))
      })
      .addCase(fetchProductGroup.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })
      .addCase(fetchProduct.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.loading = false
        state.products = [action.payload]
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false
        state.products = action.payload

        const features = state.products[0].features
        const terms = state.products[0].terms
        if (features.includes(ProductFeature.CommonTerm)) {
          state.commonTerms = terms
            .filter((term) => term.packageId === terms[0].packageId)
            .sort((a, b) => a.duration - b.duration)
        }
        if (features.includes(ProductFeature.Payment)) {
          action.payload.forEach((product) => {
            state.paymentTypes[product.id] = product.paymentTypes![0]
          })
        }
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })
      .addCase(fetchProductPackages.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchProductPackages.fulfilled, (state, action) => {
        state.loading = false
        state.packages = sortedPackages(
          action.payload.packages.map((_package) => ({
            ..._package,
            packageGroupName: _package.productId,
          }))
        )

        action.payload.packages.forEach((_package) => {
          if (_package.licenseType === LicenseType.TokenBased) state.valuations[_package.id] = 0
        })

        if (action.payload.packages.length === 1) {
          state.subscription.products = [action.payload]
        }
      })
      .addCase(fetchProductPackages.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })
      .addCase(fetchProductPackageGroups.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchProductPackageGroups.fulfilled, (state, action) => {
        state.loading = false
        state.packages = action.payload.flatMap((packageProduct) => {
          return packageProduct.packages.map((_package) => ({
            ..._package,
            packageGroupName: _package.packageGroup.name,
          }))
        })

        if (state.packages.length === 1) {
          state.subscription.products = action.payload
        }
        const { packageGroups } = getPackageGroup(state.packages, state.products)
        Object.keys(packageGroups!).forEach((packageGroupName: string) => {
          state.licenses[packageGroupName] = 0
          if (packageGroups![packageGroupName].packages[0].prices.length === 1) {
            const currency = packageGroups![packageGroupName].packages[0].prices[0].currency
            state.currencies[packageGroups![packageGroupName].productId] = currency
          }
        })
      })
      .addCase(fetchProductPackageGroups.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })
      .addCase(fetchAcceptableUsePolicy.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAcceptableUsePolicy.fulfilled, (state, action) => {
        state.loading = false
        state.acceptableUsePolicies = [action.payload]
      })
      .addCase(fetchAcceptableUsePolicy.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })
      .addCase(fetchAcceptableUsePolicies.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAcceptableUsePolicies.fulfilled, (state, action) => {
        state.loading = false
        state.acceptableUsePolicies = action.payload.sort((a, b) => a.title.localeCompare(b.title))
        state.isPolicyOptionsAccepted = action.payload.reduce(
          (acceptedPolicyOptions, policy, index) => ({
            ...acceptedPolicyOptions,
            [index]: policy.options.reduce((options, cur) => ({ ...options, [cur.id]: false }), {}),
          }),
          {} as IPolicyOptionsAccepted
        )
        state.isPolicyAccepted = action.payload.reduce(
          (acceptedPolicyOptions, policy, index) => ({
            ...acceptedPolicyOptions,
            [index]: false,
          }),
          {}
        )
        state.activePolicyIndex = 0
      })
      .addCase(fetchAcceptableUsePolicies.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })
      .addCase(fetchPrivacy.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchPrivacy.fulfilled, (state, action) => {
        state.loading = false
        state.privacy = action.payload
      })
      .addCase(fetchPrivacy.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })
      .addCase(fetchCountries.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.loading = false
        state.accountCountries = action.payload
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })
      .addCase(fetchStates.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.loading = false
        state.states = action.payload
        if (!state.states.length) {
          state.subscription.accountDetails.state = notApplicableState
        }
      })
      .addCase(fetchStates.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })

      .addCase(fetchBillingStates.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchBillingStates.fulfilled, (state, action) => {
        state.loading = false
        state.billingStates = action.payload
        if (!state.billingStates.length) {
          state.subscription.billingDetails.state = notApplicableState
        }
      })
      .addCase(fetchBillingStates.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })
      // getUserInfo
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.loading = false
        state.userInfo = action.payload
      })
      .addCase(getUserInfo.rejected, (state) => {
        state.loading = false
        state.userInfo = undefined
      })
      .addCase(fetchTaxes.fulfilled, (state, action) => {
        state.loading = false
        const { productId, rate, total } = action.payload

        if (!state.taxes[productId]) {
          state.taxes[productId] = { rate: 0, total: 0 }
        }

        state.taxes[productId].rate = rate
        state.taxes[productId].total = total
      })
      .addCase(fetchTaxes.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchTaxes.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload?.message
      })
  )
}
