import { SvgIcon, SvgIconProps } from '@mui/material'

interface CustomSvgIconProps extends SvgIconProps {
  variant?: 'outlined' | 'contained'
}
export const SuccessIcon = (props: CustomSvgIconProps): React.ReactElement => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        color='success'
        {...props}
      >
        {props.variant !== 'contained' ? (
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4
          12C4 16.4183 7.58172 20 12 20ZM16.7372 9.67573C17.1103 9.26861 17.0828 8.63604 16.6757
          8.26285C16.2686 7.88966 15.636 7.91716 15.2628 8.32428L10.4686 13.5544L8.70711
          11.7929C8.31658 11.4024 7.68342 11.4024 7.29289 11.7929C6.90237 12.1834 6.90237 12.8166
          7.29289 13.2071L9.79289 15.7071C9.98576 15.9 10.249 16.0057 10.5217 15.9998C10.7944
          15.9938 11.0528 15.8768 11.2372 15.6757L16.7372 9.67573Z'
          />
        ) : (
          <>
            <path
              d='M16.6757 8.26285C17.0828 8.63604 17.1103 9.26861 16.7372 9.67573L11.2372
          15.6757C11.0528 15.8768 10.7944 15.9938 10.5217 15.9998C10.249 16.0057 9.98576
          15.9 9.79289 15.7071L7.29289 13.2071C6.90237 12.8166 6.90237 12.1834 7.29289
          11.7929C7.68342 11.4024 8.31658 11.4024 8.70711 11.7929L10.4686 13.5544L15.2628
          8.32428C15.636 7.91716 16.2686 7.88966 16.6757 8.26285Z'
              fill='white'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172
          4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM16.7372 9.67573C17.1103 9.26861 17.0828 8.63604
          16.6757 8.26285C16.2686 7.88966 15.636 7.91716 15.2628 8.32428L10.4686 13.5544L8.70711
          11.7929C8.31658 11.4024 7.68342 11.4024 7.29289 11.7929C6.90237 12.1834 6.90237 12.8166
          7.29289 13.2071L9.79289 15.7071C9.98576 15.9 10.249 16.0057 10.5217 15.9998C10.7944
          15.9938 11.0528 15.8768 11.2372 15.6757L16.7372 9.67573Z'
              fill='#009A44'
            />
          </>
        )}
      </SvgIcon>
    </>
  )
}
