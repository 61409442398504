import { EmailValidationError } from 'features/subscriptionRequest/constants/email'
import { PrivacyViewType } from 'features/productConfiguration/constants'
import { PaymentType } from 'features/subscriptionRequest/constants/payment'
import { Option } from 'components/CustomRadioGroup'
import { StepStatus } from './stepper'

export enum SubscriptionType {
  Annual = 'Annual',
  Trial = 'Trial',
}

export enum LicenseType {
  SingleLicense = 1,
  LicenseBased,
  TokenBased,
}

export enum ValidationSubmit {
  Success,
  Fail,
}

export interface ValidationPayload {
  geo: string
  payload: string
}

export interface GeoValidationResponse {
  status: ValidationSubmit
  payload: ValidationPayload
}

export enum ProductGroupType {
  Single = 1,
  All,
}

export enum DeloitteClientType {
  Yes = 'Yes',
  No = 'No',
  DontKnow = 'DontKnow',
}

export enum DeloittePaymentType {
  Card = 'Card',
  Invoice = 'Invoice',
}

export enum TermType {
  Year = 'Year',
}

export enum TermTypeDropdown {
  year = 'year',
}

export enum DeloitteBusinessUnitType {
  Assurance = 'Assurance',
  Tax = 'Tax',
  Other = 'Other',
}

export enum PaymentTypeSubscription {
  CreditCard = 1,
  Invoice = 2,
}

export const paymentTypeLabels: Record<PaymentTypeSubscription, string> = {
  [PaymentTypeSubscription.CreditCard]: 'paymentDetails.paymentTypes.creditCard',
  [PaymentTypeSubscription.Invoice]: 'paymentDetails.paymentTypes.invoice',
}

export enum ProductFeature {
  Role = 'ProductRole',
  AuditClient = 'AuditClient',
  DeloitteClient = 'DeloitteClient',
  Summary = 'Summary',
  Extendable = 'Extendable',
  UserList = 'UserList',
  CommonTerm = 'CommonTerm',
  Payment = 'Payment',
  Discount = 'Discount',
  DeloittePartner = 'DeloittePartner',
  InvoiceDetails = 'InvoiceDetails',
}

export type PartialProduct = Pick<IProduct, 'id' | 'name' | 'countries' | 'features' | 'productBulkDiscounts'>

export interface ITerm {
  id: string
  packageId: string
  duration: number
  type: TermType | TermTypeDropdown
}

export interface IProductTerm {
  [key: string]: ITerm
}

export interface IProductPaymentType {
  [key: string]: PaymentType
}

export interface IProductZuoraRefId {
  [key: string]: string
}

export interface IPackageValuation {
  [key: string]: number | null
}

export interface IPackageCurrency {
  [key: string]: ICurrency
}

export interface IPolicyAccepted {
  [key: number]: boolean
}

export interface IPolicyOptionsAccepted {
  [key: number]: { [key: string]: boolean }
}

export interface IPackageLicenses {
  [key: string]: number
}

export interface ICurrency {
  id: number
  name: string
  shortName: string
  symbol: string
}
export interface IPrice {
  id: string
  currencyId: number
  amount: number
  currency: ICurrency
}

export interface IProductGroup {
  id: string
  name: string
  shortName: string
  type: ProductGroupType
  products: PartialProduct[]
  url?: string
  languages?: string[]
}

export interface IDeloitteBusinessUnitErrors {
  name: boolean
  email: boolean
}

export interface IDeloitteBusinessUnit {
  name: string
  email: string
  type: DeloitteBusinessUnitType | null
  errors: IDeloitteBusinessUnitErrors
}

export interface IJurisdiction {
  id: string
  name: string
  code: string
}

export interface IProductDiscount {
  id: string
  productId: string
  fromLicenseAmount: number
  toLicenseAmount: number
  percentage: number
}

export interface IProduct {
  id: string
  name: string
  hasTrial: boolean
  terms: ITerm[]
  alias: string
  features: ProductFeature[]
  countries: ICountry[] | null
  permissions: number[] | null
  jurisdiction?: IJurisdiction
  productBulkDiscounts?: IProductDiscount[]
  paymentTypes?: PaymentType[]
}

export interface IPackageGroup {
  productId: string
  name: string
  terms: { [key: string]: ITerm[] }
  packages: IPackage[]
}

export interface IPackageGroupModel {
  id: string
  name: string
  legalName: string
  isMultiple: boolean
}

export interface IPackage {
  id: string
  productId: string
  name: string
  description: string
  longDescription: string
  packageGroupName: string
  packageGroup: IPackageGroupModel
  soldBy: string
  licenseType: LicenseType
  licenseQuantity?: number
  licenseQuantityLimit?: number
  prices: IPrice[]
  shortName?: string
}

export interface IPackageRequest {
  id: string
  licenseQuantity?: number | null
  price: IPrice
}

export interface IPackageProduct {
  id: string
  packages: IPackage[]
}

export interface IPackageProductRequest {
  id: string
  packages: IPackageRequest[]
}

export const enum SubscriptionAction {
  CreateSubscription,
  EditSubscription,
}

export interface ICountry {
  id: number
  name: string
  productId: string
  abbreviation: string
}

export interface IState {
  id: number
  name: string
  abbreviation: string
}

export interface RenewSubscriptionPayload {
  note: string
  packages?: {
    id: string
    packageTermId?: string
    licenseQuantity?: number | null
  }[]
  account: {
    address: string
    city: string
    companyFullName: string
    countryId: number
    department: string
    email: string | null
    firstName: string
    globalParentFullName: string
    isDeloitteAuditClient?: DeloitteClientType | number | null
    isDeloitteClient?: DeloitteClientType | number | null
    lastName: string
    role: string
    stateId: number
    zipCode: string
    groupId: string
  }
}

export interface IAccountDetails {
  firstName: string
  lastName: string
  companyFullName: string
  globalParentFullName: string
  department: string
  role: string
  address: string
  zipCode: string
  state: IState | null
  city: string
  country?: ICountry
  phone?: string
  address2?: string
  duns?: string
}

export interface IBillingDetails {
  email: string
  firstName: string
  lastName: string
  country: ICountry | null
  companyFullName: string
  phone?: string
  department?: string
  address: string
  address2?: string
  zipCode: string
  state: IState | null
  city: string
}

//TODO:add a correct data structure and types
export interface IPaymentDetailsData {
  isDeloittePartner: DeloitteClientType
  invoiceDetails: {
    [key: string]: string
  }
}

export interface ISubscriptionRequest {
  account: ISubscriptionAccountRequest
  products: IPackageProductRequest[]
}

export interface ISubscriptionAccountRequest {
  firstName: string
  lastName: string
  companyFullName: string
  globalParentFullName: string
  department: string
  role: string
  groupId?: string
  countryId: number
  stateId: number
  address: string
  zipCode: string
  city: string
  isDeloitteAuditClient?: DeloitteClientType | number | null
  isDeloitteClient?: DeloitteClientType | number | null
}

export interface ISubscription {
  products: IPackageProduct[]
  country: ICountry | null
  email: string | null
  emailError: EmailValidationError
  isDeloitteClient: DeloitteClientType | null
  businessUnits: IDeloitteBusinessUnit[]
  accountDetails: IAccountDetails
  billingDetails: IBillingDetails
  paymentDetailsData: {
    [key: string]: { isDeloittePartner?: string; invoiceDetails?: { contact: string; email: string } }
  }
}

export interface IPrivacy {
  privacyViewTypeId: PrivacyViewType
  title: string
  link: string
  text: string
}

export const auditClientOptions = [
  {
    label: 'subscriptionInit.isDeloitteAuditClient.no',
    value: DeloitteClientType.No,
  },
  {
    label: 'subscriptionInit.isDeloitteAuditClient.yes',
    value: DeloitteClientType.Yes,
  },
  {
    label: 'subscriptionInit.isDeloitteAuditClient.idk',
    value: DeloitteClientType.DontKnow,
  },
]

export type OptionWithNullableValue = {
  value: string | null
  label: string
}

export const deloitteBusinessUnitTypes: OptionWithNullableValue[] = [
  {
    label: 'subscriptionInit.isDeloitteClient.businessUnit.placeholder',
    value: '',
  },
  {
    label: 'subscriptionInit.isDeloitteClient.businessUnit.options.assurance',
    value: DeloitteBusinessUnitType.Assurance,
  },
  {
    label: 'subscriptionInit.isDeloitteClient.businessUnit.options.tax',
    value: DeloitteBusinessUnitType.Tax,
  },
  {
    label: 'subscriptionInit.isDeloitteClient.businessUnit.options.other',
    value: DeloitteBusinessUnitType.Other,
  },
]

export const subscriptionTypeOptions: Option[] = [
  {
    label: 'packageSelection.annual',
    value: SubscriptionType.Annual,
  },
  {
    label: 'packageSelection.trial',
    value: SubscriptionType.Trial,
  },
]

export const accountDetails: IAccountDetails = {
  firstName: '',
  lastName: '',
  companyFullName: '',
  globalParentFullName: '',
  department: '',
  role: '',
  address: '',
  zipCode: '',
  state: null,
  city: '',
}

export const billingDetails = {
  email: '',
  firstName: '',
  lastName: '',
  country: null,
  companyFullName: '',
  phone: '',
  department: '',
  address: '',
  address2: '',
  zipCode: '',
  state: null,
  city: '',
}

export const requiredAccountDetails = [
  'firstName',
  'lastName',
  'companyFullName',
  'address',
  'zipCode',
  'state',
  'city',
]

export const requiredBillingDetails = [...requiredAccountDetails, 'email', 'country']

export const initialBusinessUnitState: IDeloitteBusinessUnit = {
  name: '',
  email: '',
  type: null,
  errors: {} as IDeloitteBusinessUnitErrors,
}

export const subscription: ISubscription = {
  products: [],
  country: null,
  email: null,
  emailError: 0,
  isDeloitteClient: null,
  accountDetails: accountDetails,
  businessUnits: [],
  billingDetails,
  paymentDetailsData: {},
}

export interface IUserPackagesResponse {
  id: string
  availableLicenseQuantity: number
  endDate: null | string
  startDate: null | string
  term: ITerm | null
}

export interface SelectedPackageDuration {
  startDate?: string
  endDate?: string
  term: ITerm
}

export interface SelectedPackageValuation {
  availableLicenseQuantity: number
}

export interface ISelectedPackages {
  [key: string]: SelectedPackageDuration | SelectedPackageValuation
}

export const restrictedCountries = ['Russian Federation', 'South Africa']

export const notApplicableState = {
  id: 0,
  name: 'N/A',
  abbreviation: 'N/A',
}

export type TBillingInfoErrors = string[]

export interface IServiceGetStatesPayload {
  countryId: number
}

export interface IProductPayload {
  productId: string
  jurCode: string
}

export type TPaymentDetailsSteps = {
  stepNumber: number
  status: StepStatus
}[]

export interface ITaxDetailsPayload {
  chargeAmount: number
  city: string
  state?: string
  zipCode: string
  country?: string
  productId: string
}

export interface ITaxes {
  total: number
  rate: number
  productId: string
}

export interface IProductTaxes {
  [key: string]: Omit<ITaxes, 'productId'>
}
