import { FC, FormEvent } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import FooterForm from '../footerForm'
import CustomForm from 'components/CustomForm'
import { UserName } from '../userName'
import { useAppDispatch } from 'app/hooks'
import {  resetEmployeeSelectedId } from '../../slices'
import { handleDeleteAdminModal } from 'sharedSlices/ui'

import HeaderForm from '../headerForm'
import { RemoveIcon } from 'components/icons/Remove.icon'
import { RedWarningIcon } from 'components/icons/RedWarning.icon'
import { useFormik } from 'formik'
import { IEmployee } from 'features/manageAdmins/constants'

interface Props {
  deleteAdminFormData: (event: FormEvent<HTMLFormElement>) => void
  employeeInfo: IEmployee | undefined
  title: string
  description: string
}

export const DeleteAdminForm: FC<Props> = ({
  deleteAdminFormData,
  employeeInfo,
  title,
  description,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(handleDeleteAdminModal(false))
    dispatch(resetEmployeeSelectedId())
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formik = useFormik<any>({
    initialValues: {},
    onSubmit: deleteAdminFormData,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <HeaderForm
          onClose={onClose}
          title={t(title)}
          icon={<RedWarningIcon sx={{ mr: '5px' }} />}
        />
        <Box sx={{ maxHeight: '473px', flexGrow: 1, overflow: 'auto', p: '0 16px' }}>
          <CustomForm>
            {employeeInfo && (
              <Box sx={{ p: '8px 0px' }}>
                <Typography variant='body2'>{t(description)}</Typography>
                <Box sx={{ mt: '10px' }}>
                  <UserName firstName={employeeInfo.firstName} lastName={employeeInfo.lastName} />
                </Box>
              </Box>
            )}
          </CustomForm>
        </Box>
        <FooterForm>
          <FooterForm.Button onClose={onClose} variant='outlined' text='button.no' />
          <FooterForm.Button
            type='submit'
            color='error'
            disabled={false}
            text='button.yes'
            icon={<RemoveIcon />}
          />
        </FooterForm>
      </Box>
    </form>
  )
}
