import { geoApi } from 'services/api/geoApi'

import { EditSubscriptionPayload } from 'features/manageProduct/constants'
import { IProductRole } from '../constants'

export const headers = {
  headers: {
    'x-action-origin': 'subscriptions',
  },
}

export const approveSubscription = (
  subId: string,
  packageSubId: string,
  note: string,
  jurCode: string,
  productRole?: IProductRole
): Promise<void> => {
  return geoApi.withJurisdictionCode(jurCode).post(
    `subscriptions/${subId}/packageapprove`,
    {
      note,
      productRole,
      packageId: packageSubId,
    },
    { ...headers }
  )
}

export const rejectSubscription = (
  subId: string,
  packageSubId: string,
  note: string,
  jurCode: string,
  productRole?: IProductRole
): Promise<void> => {
  return geoApi.withJurisdictionCode(jurCode).post(
    `subscriptions/${subId}/packagereject`,
    {
      note,
      productRole,
      packageId: packageSubId,
    },
    { ...headers }
  )
}

export const inactivateSubscription = (
  subscriptionId: string,
  jurCode: string,
  productId: string,
  payload: EditSubscriptionPayload
) => {
  return geoApi
    .withJurisdictionCode(jurCode)
    .post(`subscriptions/${subscriptionId}/cancel`, payload, {
      headers: { 'x-product-id': productId },
    })
}

export const reactivateSubscription = (
  subscriptionId: string,
  jurCode: string,
  productId: string,
  payload: EditSubscriptionPayload
) => {
  return geoApi
    .withJurisdictionCode(jurCode)
    .post(`subscriptions/${subscriptionId}/reactivate`, payload, {
      headers: { 'x-product-id': productId },
    })
}
