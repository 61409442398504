import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

const productGroup = (store: RootState) => store.subscriptionRequest
const localization = (store: RootState) => store.localization

export const selectAvailableLanguageByProduct = createSelector(
  productGroup,
  localization,
  ({ productGroup: { languages: productLanguages } }, { availableLanguages }) => {
    if (!productLanguages) return availableLanguages
    return availableLanguages.filter((lang) => productLanguages.includes(lang.localeCode))
  }
)
