import { ChangeEvent, FC, FocusEvent } from 'react'
import { useTranslation } from 'react-i18next'
import CustomForm from 'components/CustomForm'

interface Props {
  setEmailInput: (fieldName: string, param: string | string[] | number | boolean) => void
  error: boolean
}
export const EmailField: FC<Props> = ({ setEmailInput, error }) => {
  const { t } = useTranslation()

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.currentTarget.value
    setEmailInput('email', email)
  }

  const handleEmailTrim = (e: FocusEvent<HTMLInputElement>) => {
    const email = e.currentTarget.value.trim()
    setEmailInput('email', email)
  }

  return (
    <>
      <CustomForm.Label value='manageProduct.users.email' error={error} />
      <CustomForm.TextField
        onChange={onInputChange}
        onBlur={handleEmailTrim}
        error={error}
        sx={{ p: 0, m: 0, mt: '8px', mb: '16px' }}
        fullWidth
        textstyle={{ m: '16px 0' }}
        name='email'
        placeholder={t('manageProduct.users.enterEmail')!}
      />
    </>
  )
}
