import React, { useRef, useEffect, useState, FC } from 'react'
import { Box, PopperPlacementType, SxProps, Tooltip } from '@mui/material'

interface Props {
  text: React.ReactNode
  style?: SxProps
}

export const OverflowTooltip: FC<Props> = ({ text, style }) => {
  const [hoverStatus, setHover] = useState(false)
  const textElementRef = useRef<HTMLElement>(null)
  const [popperPlacement, setPopperPlacement] = useState<PopperPlacementType>('top')

  const compareSize = () => {
    setHover(textElementRef.current!.scrollWidth > textElementRef.current!.clientWidth)
  }

  useEffect(() => {
    compareSize()
    window.addEventListener('resize', compareSize)

    return () => window.removeEventListener('resize', compareSize)
  }, [])

  useEffect(() => {
    compareSize()
  }, [text])

  return (
    <Tooltip
      title={text}
      disableHoverListener={!hoverStatus}
      arrow
      PopperProps={{
        ref: (node) => {
          if (node && node.clientHeight > 400) {
            setPopperPlacement('auto')
          }
        },
        placement: popperPlacement,
      }}
      componentsProps={{
        tooltip: {
          sx: {
            marginBottom: '8px!important',
            maxHeight: '640px',
            maxWidth: '600px',
          },
        },
      }}
    >
      <Box
        component={'span'}
        ref={textElementRef}
        sx={{
          ...style,
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&::after': {
            content: `''`,
            display: 'block',
          },
        }}
      >
        {text}
      </Box>
    </Tooltip>
  )
}
