import { SortingDirection } from "constants/sorting"

export const PRIMARY_USER = 'primary'
export const REGULAR_USER = 'regular'
export type UserRole = typeof PRIMARY_USER | typeof REGULAR_USER

export const UserRoleKey: Record<string, string> = {
  primary: 'manageProduct.users.primaryUser',
  regular: 'manageProduct.users.registeredUser',
}

export const DEFAULT_USER_LIST_SORTING = {
  column: 'dateAdded',
  direction: SortingDirection.DESC,
}

export const UserRoleType: Record<number, UserRole> = {
  2: PRIMARY_USER,
  1: REGULAR_USER,
}

export interface IAddUserFormData {
  id: string
  email: string
  lastName: string
  firstName: string
  isSameOrganization: boolean
  role: number
}

export interface UserInfo {
  id: string
  email: string
  selected?: boolean
  userName?: string
  firstName: string
  lastName: string
  dateAdded?: string
  lastLogin?: string
}

export interface User extends UserInfo {
  role: UserRole
}

export interface UserRequest extends UserInfo {
  role: number
}

export interface UserResponse extends UserInfo {
  role: number
}
