import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IManageReportsState,
  IReportDetails,
  IReportPayload,
  DEFAULT_PAGINATION,
  IReport,
  DEFAULT_REPORT_PAGINATION,
} from 'features/manageReports/constants'
import isEqual from 'lodash.isequal'
import {
  deleteMyReport,
  fetchJurisdictions,
  fetchMyReports,
  fetchProductGroups,
  fetchReportTypes,
  generateReport,
} from '../services'
import { getUniqueById } from 'utils/getUniqueById'
import { getJoinedReports, getPaginatedReports } from 'features/manageReports/utils/transformReportData'

export const initialManageReportsState: IManageReportsState = {
  generatedReport: {
    result: null,
    total: 0,
    pagination: DEFAULT_PAGINATION,
    details: null,
    payload: null,
    filters: null,
  },
  selectedReport: null,
  reportTypes: [],
  jurisdictions: [],
  productGroups: [],
  details: {
    reportTypeId: null,
    name: '',
    columns: [],
    jurisdictions: null,
    productGroups: null,
    dates: [],
  },
  myReports: {
    data: [],
    total: 0,
    pagination: DEFAULT_REPORT_PAGINATION,
  },
  error: null,
  loading: false,
}

export const manageReportsSlice = createSlice({
  name: 'manageReports',
  initialState: initialManageReportsState,
  reducers: {
    setReportType: (state, action: PayloadAction<number>) => {
      state.details.reportTypeId = action.payload
    },
    setReportName: (state, action: PayloadAction<string>) => {
      state.details.name = action.payload
    },
    setColumns: (state, action: PayloadAction<string[]>) => {
      state.details.columns = action.payload
    },
    deleteColumn: (state, action: PayloadAction<string>) => {
      state.details.columns = state.details.columns.filter((column) => column !== action.payload)
    },
    setJurisdictions: (state, action: PayloadAction<string[]>) => {
      state.details.jurisdictions = action.payload
    },
    deleteJurisdiction: (state, action: PayloadAction<string>) => {
      state.details.jurisdictions = state.details.jurisdictions!.filter(
        (jurisdiction) => jurisdiction !== action.payload
      )
    },
    setProductGroups: (state, action: PayloadAction<string[]>) => {
      state.details.productGroups = action.payload
    },
    deleteProductGroup: (state, action: PayloadAction<string>) => {
      state.details.productGroups = state.details.productGroups!.filter(
        (productGroup) => productGroup !== action.payload
      )
    },
    setDates: (state, action: PayloadAction<[string, string] | []>) => {
      state.details.dates = action.payload
    },
    resetData: (state) => {
      state.details = { ...initialManageReportsState.details }
      state.generatedReport = { ...initialManageReportsState.generatedReport }
      state.selectedReport = null
      state.jurisdictions = []
      state.productGroups = []
    },
    setPagination: (state, action: PayloadAction<{ number: number; size: number }>) => {
      state.generatedReport.pagination = {
        number: action.payload.number,
        size: action.payload.size,
      }
    },
    setSelectedReport: (state, action: PayloadAction<IReport>) => {
      state.selectedReport = action.payload
    },
    setGeneratedData: (
      state,
      action: PayloadAction<{
        details: IReportDetails
        payload: IReportPayload
        allProductsAccessible: boolean
      }>
    ) => {
      const reportTypeName = state.reportTypes.find((type) => type.id === state.details.reportTypeId)!.name
      const [startDate, endDate] = state.details.dates
      const productAndJurisdiction: string[] = []
      const { productGroups, jurisdictions } = state.details!

      if (!action.payload.allProductsAccessible || productGroups?.length || jurisdictions?.length) {
        state.productGroups
          .filter((_productGroup) => {
            return !productGroups?.length || productGroups.includes(_productGroup.id)
          })
          .forEach((productGroup) => {
            productAndJurisdiction.push(
              ...productGroup.products
                .filter((productItem) => {
                  return !jurisdictions?.length || jurisdictions!.includes(productItem.jurisdictionId)
                })
                .map((_product) => _product.name)
            )
          })
      }

      state.generatedReport.filters = {
        name: state.details.name || reportTypeName,
        type: reportTypeName,
        period: {
          startDate: startDate,
          endDate: endDate,
        },
        productAndJurisdiction: productAndJurisdiction.sort((a, b) => a.localeCompare(b)).join(', '),
      }
      state.generatedReport.details = action.payload.details
      state.generatedReport.payload = action.payload.payload
    },
    resetMyReports: (state) => {
      state.myReports = {
        ...initialManageReportsState.myReports,
        pagination: initialManageReportsState.myReports.pagination,
      }
    },
    setMyReportsPagination: (state, action: PayloadAction<{ page: number; size: number }>) => {
      state.myReports.pagination = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReportTypes.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchReportTypes.fulfilled, (state, action) => {
        state.reportTypes = action.payload
        state.loading = false
      })
      .addCase(fetchReportTypes.rejected, (state, action) => {
        state.error = action.payload?.message
        state.loading = false
      })
      .addCase(fetchJurisdictions.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchJurisdictions.fulfilled, (state, action) => {
        state.jurisdictions = action.payload.flat().sort((a, b) => a.name.localeCompare(b.name))

        if (state.details.jurisdictions) {
          const jurisdictions = state.details.jurisdictions.filter((jurisdictionId) => {
            return action.payload.flat().find((jurisdiction) => jurisdiction.id === jurisdictionId)
          })
          if (!isEqual(state.details.jurisdictions, jurisdictions)) {
            state.details.jurisdictions = jurisdictions
          }
        }
        state.loading = false
      })
      .addCase(fetchJurisdictions.rejected, (state, action) => {
        state.error = action.payload?.message
        state.loading = false
      })
      .addCase(fetchProductGroups.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchProductGroups.fulfilled, (state, action) => {
        state.productGroups = getUniqueById(action.payload.flat()).sort((a, b) => a.name.localeCompare(b.name))

        if (state.details.productGroups) {
          const productGroups = state.details.productGroups.filter((productGroupId) => {
            return action.payload.flat().find((productGroup) => productGroup.id === productGroupId)
          })
          if (!isEqual(state.details.productGroups, productGroups)) {
            state.details.productGroups = productGroups
          }
        }
        state.loading = false
      })
      .addCase(fetchProductGroups.rejected, (state, action) => {
        state.error = action.payload?.message
        state.loading = false
      })
      .addCase(generateReport.pending, (state) => {
        state.loading = true
      })
      .addCase(generateReport.fulfilled, (state, action) => {
        state.generatedReport.result = action.payload.result
        state.generatedReport.total = action.payload.total
        state.loading = false
      })
      .addCase(generateReport.rejected, (state, action) => {
        state.error = action.payload?.message
        state.loading = false
      })
      .addCase(fetchMyReports.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchMyReports.fulfilled, (state, action) => {
        const { page, size } = state.myReports.pagination
        const { result, total } = getJoinedReports(action.payload)
        state.myReports.data = getPaginatedReports(result, page, size)
        state.myReports.total = total
        state.loading = false
      })
      .addCase(fetchMyReports.rejected, (state, action) => {
        state.error = action.payload?.message
        state.loading = false
      })
      .addCase(deleteMyReport.fulfilled, (state) => {
        state.loading = true
      })
      .addCase(deleteMyReport.rejected, (state, action) => {
        state.error = action.payload?.message
        state.loading = false
      })
  },
})

export const {
  deleteColumn,
  deleteJurisdiction,
  deleteProductGroup,
  resetData,
  resetMyReports,
  setColumns,
  setDates,
  setJurisdictions,
  setMyReportsPagination,
  setProductGroups,
  setReportName,
  setReportType,
  setPagination,
  setGeneratedData,
  setSelectedReport,
} = manageReportsSlice.actions

export default manageReportsSlice.reducer
