import { Box, Button } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { UpperMenu } from 'components/layouts/routerLayout/upperMenu'
import { AcceptableUsePolicy } from 'features/subscriptionRequest/components/acceptableUsePolicy'
import { Footer as MainFooter } from 'components/Footer'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useContainerScroll } from 'utils/scrollableFooter'
import { MockFooterBlock } from 'components/Footer/mockFooterBlock'
import { useTranslation } from 'react-i18next'
import { useLogout } from 'services/auth/useLogout'
import { useParams, useSearchParams } from 'react-router-dom'
import { CustomAlert } from 'components/CustomAlert'
import {
  acceptTerms,
  getProductId,
  getIsTermsAccepted,
} from 'features/subscriptionRequest/services/acceptableUsePolicy'
import { fetchProductGroup } from 'features/subscriptionRequest/slice/services'
import { selectCountry } from 'features/subscriptionRequest/slice'
import colors from 'theme/colors'
import { Loader } from 'components/Loader'

export const AcceptableUsePolicyForEndUser = () => {
  const { t } = useTranslation()
  const [acceptableUsePolicy, setAcceptableUsePolicy] = useState<{ isPolicyUpdated: boolean }>({
    isPolicyUpdated: false,
  })
  const {
    ui: { isFooterVisible },
    subscriptionRequest: { loading, productGroup, isPolicyAccepted, countries },
  } = useAppSelector((state) => state)
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { productGroupShortName } = useParams()
  const product = useRef<{
    id: string
    jurCode: string
  }>()
  const containerRef = useRef<HTMLDivElement | null>(null)

  useContainerScroll(containerRef, dispatch)

  const { handleLogout } = useLogout()

  const handleRedirectSubscriptionRequest = useCallback(() => {
    const termsLinkId = searchParams.get('termsLinkId')
    if (product.current && termsLinkId) {
      acceptTerms(product.current.id, termsLinkId, product.current.jurCode).then((url) => {
        window.location.replace(url)
      })
    }
  }, [searchParams])

  useEffect(() => {
    if (productGroupShortName) {
      dispatch(fetchProductGroup(productGroupShortName))
    }
  }, [dispatch, productGroupShortName])

  useEffect(() => {
    const termsLinkId = searchParams.get('termsLinkId')
    if (Object.keys(productGroup).length && Object.keys(countries).length) {
      const { id: productGroupId } = productGroup
      if (termsLinkId) {
        getProductId(productGroupId, termsLinkId).then((_productId) => {
          if (_productId) {
            product.current = { id: _productId, jurCode: '' }
            const country = countries.find((_country) => _country.productId === _productId)
            if (country) {
              dispatch(selectCountry({ ...country, productId: _productId }))
              product.current.jurCode = country.abbreviation
            }
            if (termsLinkId && country) {
              getIsTermsAccepted(_productId, termsLinkId, country.abbreviation).then((termsAccepted) => {
                setAcceptableUsePolicy({
                  isPolicyUpdated: termsAccepted.isPolicyUpdated,
                })
              })
            }
          }
        })
      }
    }
  }, [productGroup, countries, dispatch])

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <UpperMenu title={productGroup.name} />
      {acceptableUsePolicy.isPolicyUpdated && (
        <CustomAlert severity='warning' text={t('acceptableUsePolicy.warning')} />
      )}
      <Box
        ref={containerRef}
        sx={{
          height: 'calc(100% - 55px)',
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        <AcceptableUsePolicy />
        {!loading && (
          <Box
            sx={{
              padding: '16px',
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: `1px solid ${colors.grey[100]}`,
              borderBottom: !isFooterVisible ? `1px solid ${colors.grey[100]}` : '',
            }}
          >
            <Button variant='outlined' onClick={() => handleLogout()}>
              {t('button.decline')}
            </Button>
            <Button
              disabled={!isPolicyAccepted}
              variant='contained'
              sx={{ marginLeft: '16px' }}
              onClick={handleRedirectSubscriptionRequest}
            >
              {t('button.accept')}
            </Button>
          </Box>
        )}
        <MockFooterBlock />
        {loading && <Loader />}
      </Box>
      <MainFooter />
    </Box>
  )
}
