export const enum Permissions {
  ApproveRejectSubscriptions = 1,
  AddRemSuperAdmins = 2,
  AddRemJurisdictionAdmins = 3,
  AddRemProductAdmins = 4,
  RenewSubscriptions = 5,
  InactivateSubscriptions = 6,
  ReactivateSubscriptions = 7,
  AddRemDemoUser = 8,
  ProductConfiguration = 9,
  AddSubscriptionOnBehalfOfClient = 10,
  ManageSubscriptions = 11,
  ManageUsers = 12,
}
