import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ExcelFile } from 'components/icons/ExcelFile.icon'
import { RemoveIcon } from 'components/icons/Remove.icon'

import { selectAllRegularUsersSelected } from 'features/manageUsers/slices/manageUsers/selectors'

import {
  handleAddUserModal,
  handleRemoveUsersModal,
  handleAddMultipleUsersModal,
} from 'sharedSlices/ui'
import { useAppDispatch, useAppSelector } from 'app/hooks'

export const HeaderButtons = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const regularUserSelectedList = useAppSelector(selectAllRegularUsersSelected)

  return (
    <>
      <Button
        disabled={regularUserSelectedList.length <= 0}
        startIcon={<RemoveIcon sx={{ height: '24px', width: '24px' }} />}
        variant='text'
        color='error'
        sx={{ marginLeft: '16px' }}
        onClick={() => dispatch(handleRemoveUsersModal(true))}
      >
        {t('button.deleteSelected')}
        {regularUserSelectedList.length ? `  (${regularUserSelectedList.length})` : ''}
      </Button>
      <Button
        variant='outlined'
        color='primary'
        sx={{ marginLeft: '16px' }}
        onClick={() => {
          const path = localStorage.getItem('previousPath')
          path ? navigate(path) : navigate('/manage/product/subscriptions')
        }}
      >
        {t('button.cancel')}
      </Button>
      <Button
        startIcon={<ExcelFile sx={{ height: '24px', width: '24px' }} />}
        variant='outlined'
        color='primary'
        sx={{ marginLeft: '16px' }}
        onClick={() => dispatch(handleAddMultipleUsersModal(true))}
      >
        {t('button.bulkUserUpload')}
      </Button>
      <Button
        variant='contained'
        color='primary'
        sx={{ marginLeft: '16px' }}
        onClick={() => dispatch(handleAddUserModal(true))}
      >
        {t('button.addUser')}
      </Button>
    </>
  )
}
