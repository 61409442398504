import { Box } from '@mui/system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { SideBar } from './sideBar'
import { UpperMenu } from './upperMenu'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Footer as MainFooter } from 'components/Footer'
import { handleLanguageSelected } from 'sharedSlices/localization'
import { getPreferredLanguage } from 'sharedSlices/auth'
import { NoAuthorized } from 'components/NoAuthorized'

export const RouterLayout = () => {
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()
  const preferredLanguage = useAppSelector(getPreferredLanguage)
  const { isFooterVisible } = useAppSelector((state) => state.ui)
  const { error: employeesError } = useAppSelector((state) => state.employees)
  const { error: authError } = useAppSelector((state) => state.auth)

  useEffect(() => {
    i18n.changeLanguage(preferredLanguage)
    dispatch(handleLanguageSelected(preferredLanguage))
  }, [dispatch, preferredLanguage, i18n])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UpperMenu title={'Global Subscription Services'} />
      <Box
        sx={{
          flex: '1 0 0',
          backgroundColor: 'common.white',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {authError || employeesError ? <></> : <SideBar />}
        <Box sx={{ flex: '1 0 0' }}>
          <Box
            sx={{
              height: `calc(100vh - ${isFooterVisible ? '188' : '55'}px)`,
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {authError || employeesError ? <NoAuthorized /> : <Outlet />}
          </Box>
          <MainFooter />
        </Box>
      </Box>
    </Box>
  )
}
