import { FC } from 'react'
import { Box, SxProps, Button, Typography } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { selectCurrentUserPermissions } from 'sharedSlices/auth'
import { selectPermissionIds } from 'features/manageProduct/slice/selectors'
import { Permissions } from '../../constants/permissions'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  size?: number
  sx?: SxProps
  rowsPerPage?: JSX.Element
}

export const TitleBadge: FC<Props> = ({ title, size, sx, rowsPerPage }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const currentPermissions = useAppSelector(selectCurrentUserPermissions)
  const productPermissions = useAppSelector(selectPermissionIds)
  const pathName = window.location.pathname
  const showCreateSubscriptionButton = currentPermissions.includes(
    String(Permissions.AddSubscriptionOnBehalfOfClient)
  )
  const enableCreateSubscriptionButton = productPermissions.includes(
    Permissions.AddSubscriptionOnBehalfOfClient
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.100',
        ...sx,
      }}
    >
      <Typography variant='h6' fontWeight={600} mr='9px'>
        {title}
      </Typography>
      {size && (
        <Box
          sx={{
            backgroundColor: 'border.main',
            height: '24px',
            borderRadius: '100px',
            textAlign: 'center',
            lineHeight: '22px',
            p: '0 8.6px',
          }}
        >
          <Typography fontWeight='600' variant='caption'>
            {size}
          </Typography>
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}></Box>
      {!!size && rowsPerPage && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          {rowsPerPage}
        </Box>
      )}
      {pathName.includes('subscriptions') && showCreateSubscriptionButton && (
        <Button
          disabled={enableCreateSubscriptionButton ? false : true}
          variant={'contained'}
          color={'primary'}
          sx={{ marginLeft: '16px', flexShrink: 0, whiteSpace: 'nowrap' }}
          onClick={() => {
            navigate({
              pathname: `/subscribe/${search.get('productGroupShortName')}`,
              search: `?productId=${search.get('productId')}&subscribeToProduct=true`,
            })
          }}
        >
          {t('manageProduct.subscriptionActions.create')}
        </Button>
      )}
    </Box>
  )
}
