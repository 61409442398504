import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import { HeaderButtons } from './headerButtons'

import { AddUserModal } from 'features/manageUsers/components/addUserModal'
import { RemoveUserDialog } from 'features/manageUsers/components/removeUserDialog'
import { EditPrimaryUserModal } from 'features/manageUsers/components/editPrimaryUserModal'
import { AddMultipleUsersModal } from 'features/manageUsers/components/addMultipleUsersModal'

export const ManageUsersLayout = () => {
  const { t } = useTranslation()

  return (
    <>
      <header>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',

            height: '56px',
            padding: '0px 16px',
            borderBottom: '1px solid',
            borderBottomColor: 'grey.100',
          }}
        >
          <Box>
            <Typography variant='h6' fontWeight={600} mr='9px'>
              {t('manageProduct.users.title')}
            </Typography>
          </Box>
          <Box>
            <HeaderButtons />
          </Box>
        </Box>
      </header>
      <AddUserModal />
      <RemoveUserDialog />
      <EditPrimaryUserModal />
      <AddMultipleUsersModal />
      <Outlet />
    </>
  )
}
