import { Box } from '@mui/material'

export const PrivacyPreview = () => {
  const privacyPreview: string | null = localStorage.getItem('privacyPreview')
    ? JSON.parse(localStorage.getItem('privacyPreview')!)
    : null

  return privacyPreview ? (
    <Box dangerouslySetInnerHTML={{ __html: privacyPreview }} />
  ) : (
    <></>
  )
}
