import { FC } from 'react'
import { Avatar, Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IComment } from 'features/manageProduct/constants'
import { CloseIcon } from 'components/icons/Close.icon'
import { OverflowTooltip } from 'components/OverflowTooltip'
import CustomModal from 'components/CustomModal'

interface Props {
  commentInfo: IComment
  onClose: () => void
  open: boolean
}

export const CommentPopup: FC<Props> = ({ commentInfo, onClose, open }) => {
  const { t } = useTranslation()

  return (
    <CustomModal width='640px' open={open} onClose={onClose}>
      <CustomModal.Header>
        <Box sx={{ marginRight: 'auto' }}>{t('manageProduct.subscriptionActions.comment')}</Box>
        <Button
          sx={{
            minHeight: '24px',
            minWidth: '24px',
            padding: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </CustomModal.Header>
      <CustomModal.Body
        style={{
          fontFamily: 'Open Sans',
          fontSize: 14,
          padding: '16px',
        }}
      >
        <Box display='flex' alignItems='center'>
          <Avatar
            sx={{
              width: '24px',
              height: '24px',
              fontSize: '10px',
              backgroundColor: 'common.black',
              textTransform: 'uppercase',
              marginRight: '8px',
            }}
          >
            {commentInfo.firstName.charAt(0) + commentInfo.lastName.charAt(0)}
          </Avatar>
          <OverflowTooltip text={commentInfo.email} />
        </Box>
        <Box sx={{ mt: '8px' }}>{commentInfo.comment}</Box>
      </CustomModal.Body>
      <CustomModal.Footer
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          padding: '16px',
        }}
      >
        <Button
          sx={{
            width: '90px',
          }}
          variant='contained'
          onClick={onClose}
        >
          {t('button.ok')}
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  )
}
