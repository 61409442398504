import { Box, List, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ErrorIcon } from 'components/icons/Error.icon'

import { UploadResult } from 'features/manageUsers/constants/upload.interface'

export const AlertError = ({ errorResults }: { errorResults: UploadResult[] }) => {
  const { t } = useTranslation()

  const totalUsers = errorResults.reduce((acc, cur) => acc + cur.userCount, 0)

  return (
    <Box
      sx={{
        height: 'auto',
        p: '8px 16px',
        bgcolor: '#FDF2F1',
        borderBottom: '1px solid #F1C7C4',
      }}
    >
      <Box display={'flex'} sx={{ alignItems: 'center' }}>
        <ErrorIcon />
        <Typography variant='body2' ml={'8px'}>
          <Typography component={'span'} variant='body2' fontWeight={700}>
            {totalUsers + ' '}
          </Typography>
          {t('manageProduct.uploadMessage.usersNoAdded')}
        </Typography>
      </Box>
      <List
        sx={{
          listStyleType: 'disc',
          pl: '10px',
          mt: '8px',
          listStylePosition: 'inside',
        }}
      >
        {errorResults.map((err) => (
          <Typography
            variant='body2'
            key={err.key}
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'list-item',
              p: 0,
              textOverflow: 'ellipsis',
            }}
          >
            {t(err.key)} {err.rows.length > 0 && err.rows.join(', ').trim()}
          </Typography>
        ))}
      </List>
    </Box>
  )
}
