import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { PAYMENT_DETAILS_FIXED_STEPS, PaymentDetailsBlockTypes } from '../../components/paymentDetails/constants'
import { disablePaymentDetailsSteps, setPaymentDetailsActiveStep, setPaymentDetailsSteps } from '../../slice'
import { StepStatus } from '../../constants/stepper'
import {
  selectIsValidBillingDetails,
  selectPaymentDetailsStepIdx,
  selectPackageProducts,
  selectRestrictedCountry,
  selectIsValidAccountDetails,
} from '../../slice/selectors'
import { generateValidationRules, IStepsValidMap, IValidationRules, validateFields } from './helpers'

export const usePaymentDetailsSteps = () => {
  const {
    paymentDetailsActiveStep,
    products,
    subscription: { paymentDetailsData },
    paymentTypes,
  } = useAppSelector((state) => state.subscriptionRequest)

  const selectedPackageProducts = useAppSelector(selectPackageProducts)
  const isValidBillingDetails = useAppSelector(selectIsValidBillingDetails)
  const isValidAccountDetails = useAppSelector(selectIsValidAccountDetails)

  const restrictedCountryIsSelected = useAppSelector(selectRestrictedCountry)
  const stepToExpand = useAppSelector(selectPaymentDetailsStepIdx)

  const dispatch = useAppDispatch()

  const productsSortedBySoldBy = useMemo(
    () =>
      selectedPackageProducts.map((product) => ({
        id: product.id,
        soldByTitle: product.packages[0].packageGroup.legalName,
      })),
    [selectedPackageProducts]
  )

  const paymentDetailsPermanentSteps = useMemo(
    () =>
      PAYMENT_DETAILS_FIXED_STEPS.map((stepType, idx) => ({
        stepNumber: idx + 1,
        status: StepStatus.Available,
        stepType,
        id: idx,
      })),
    []
  )

  const paymentDetailsFlexibleSteps = useMemo(
    () =>
      productsSortedBySoldBy.map((product, idx) => ({
        stepNumber: idx + paymentDetailsPermanentSteps?.length + 1,
        status: StepStatus.Disabled,
        stepType: PaymentDetailsBlockTypes.FlexibleDetails,
        id: product.id,
      })),
    [productsSortedBySoldBy]
  )

  const paymentDetailsActualSteps = useMemo(
    () => [...paymentDetailsPermanentSteps, ...paymentDetailsFlexibleSteps],
    [paymentDetailsFlexibleSteps]
  )

  const stepsValidationMap = useMemo(() => {
    const validationMap = {
      [PaymentDetailsBlockTypes.AccountDetails]: () => isValidAccountDetails,
      [PaymentDetailsBlockTypes.BillingDetails]: () => isValidBillingDetails,
      [PaymentDetailsBlockTypes.FlexibleDetails]: (id: string | number) => {
        const productFeatures = products.find((product) => product.id === id)?.features
        const selectedPaymentType = paymentTypes[id]

        if (!productFeatures || !selectedPaymentType) return false

        const validRules = generateValidationRules(productFeatures, selectedPaymentType)

        const currentValues = paymentDetailsData[id]
        return validateFields(currentValues, validRules as IValidationRules)
      },
    }

    return paymentDetailsActualSteps.reduce((acc, { stepType, stepNumber, id }) => {
      const validateStep = validationMap[stepType]
      acc[stepNumber] = validateStep ? validateStep(id) : false
      return acc
    }, {} as IStepsValidMap)
  }, [
    isValidBillingDetails,
    isValidAccountDetails,
    paymentDetailsActualSteps,
    paymentDetailsData,
    products,
    paymentTypes,
  ])

  const isButtonDisabled = useCallback(() => {
    if (restrictedCountryIsSelected) {
      return true
    }

    return !stepsValidationMap[paymentDetailsActiveStep as keyof typeof stepsValidationMap]
  }, [stepsValidationMap, paymentDetailsActiveStep, restrictedCountryIsSelected])

  const isCurrentStepValid = useMemo(() => {
    return stepsValidationMap[paymentDetailsActiveStep as keyof typeof stepsValidationMap]
  }, [paymentDetailsActiveStep, stepsValidationMap, paymentDetailsData])

  const handleResetInitialPaymentSteps = () => {
    dispatch(setPaymentDetailsSteps(paymentDetailsActualSteps))
    dispatch(setPaymentDetailsActiveStep(PaymentDetailsBlockTypes.BillingDetails))
  }

  //disable further steps if a current step becomes invalid
  useEffect(() => {
    if (
      (paymentDetailsActiveStep > stepToExpand && stepToExpand !== -1) ||
      (!isCurrentStepValid && paymentDetailsActiveStep !== null && isValidAccountDetails)
    ) {
      const payload = { stepIndex: stepToExpand, currentStep: StepStatus.Available, newStep: StepStatus.Disabled }
      dispatch(disablePaymentDetailsSteps(payload))
    }
  }, [
    isValidBillingDetails,
    stepToExpand,
    dispatch,
    isCurrentStepValid,
    isValidAccountDetails,
    paymentDetailsActiveStep,
  ])

  return {
    selectedPackageProducts,
    productsSortedBySoldBy,
    paymentDetailsPermanentSteps,
    isButtonDisabled,
    handleResetInitialPaymentSteps,
    stepsValidationMap,
  }
}
