const errorMessages = {
  requiredErrorMsg: 'errors.required',
  dunsFieldErrorMsg: 'account.duns.error',
  emailErrorMsg: 'userEmail.error',
  emailPaymentErrorMsg: 'paymentDetails.invoiceDetails.email.error',
}

export const { requiredErrorMsg, dunsFieldErrorMsg, emailErrorMsg, emailPaymentErrorMsg } = errorMessages

export const validationFieldMessages = {
  email: emailPaymentErrorMsg,
  firstName: requiredErrorMsg,
  lastName: requiredErrorMsg,
  companyFullName: requiredErrorMsg,
  duns: dunsFieldErrorMsg,
  address: requiredErrorMsg,
  zipCode: requiredErrorMsg,
  city: requiredErrorMsg,
  country: requiredErrorMsg,
  state: requiredErrorMsg,
  contact: requiredErrorMsg,
}
