import { Fragment, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { PaymentTypes } from 'features/subscriptionRequest/components/paymentDetails/components/PaymentTypes'
import { CreditCard } from 'features/subscriptionRequest/components/paymentDetails/components/CreditCard'
import { getTotalPackagePrice, getTotalPrice } from 'features/subscriptionRequest/utils/getTotalPrice'
import { TPaymentDetailsData } from 'features/subscriptionRequest/hooks/usePaymentDetailsData/types'
import { getFormatedPrice } from 'features/subscriptionRequest/utils/getFormatedPrice'
import { selectPackageProducts } from 'features/subscriptionRequest/slice/selectors'
import { PaymentType } from 'features/subscriptionRequest/constants/payment'
import { ProductFeature } from 'features/managePackage/constants'
import { STEPS_VALUES } from '../../constants'
import { EligibilityCheck } from './EligibilityCheck'
import { InvoiceDetails } from './InvoiceDetails'
import PaymentFooter from '../PaymentFooter'
import colors from 'theme/colors'
import { fetchTaxes } from 'features/subscriptionRequest/slice/services'

type TPaymentDetailsByProducts = TPaymentDetailsData & { isButtonDisabled: () => boolean; id: string; idx: number }

export const PaymentDetailsByProducts = ({ setExpanded, isButtonDisabled, id, idx }: TPaymentDetailsByProducts) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    paymentDetailsSteps,
    paymentDetailsActiveStep,
    paymentTypes,
    productGroup,
    currencies,
    licenses,
    commonTerms,
    commonTermId,
    subscription,
    taxes,
  } = useAppSelector((state) => state.subscriptionRequest)
  const selectedPackageProducts = useAppSelector(selectPackageProducts)
  const { fixedSteps } = STEPS_VALUES

  const product = productGroup.products.find((_product) => _product.id === id)!
  const productFeatures = product.features
  const commonTerm = commonTerms.find((term) => term.id === commonTermId)!

  const discounts = useMemo(() => {
    return [...productGroup.products[0].productBulkDiscounts!].sort((a, b) => a.fromLicenseAmount - b.fromLicenseAmount)
  }, [productGroup.products])

  const currency = currencies[id]
  const paymentType = paymentTypes[id]

  const packageProduct = selectedPackageProducts.find((_packageProduct) => _packageProduct.id === id)!

  const selectedPackages = useMemo(() => {
    return packageProduct.packages
      .filter((_package) => licenses[_package.packageGroup.name])
      .sort((a, b) => b.name.localeCompare(a.name))
  }, [licenses, packageProduct.packages])

  const totalBeforeTaxes = getTotalPrice(selectedPackages, licenses, commonTerm, currency, discounts)
  const jurCode = product.countries![0].abbreviation

  const totalPrice = getTotalPrice(
    selectedPackages,
    licenses,
    commonTerm,
    currency,
    productGroup.products?.[0]?.productBulkDiscounts ?? []
  )
  const productTaxes = taxes[product.id]?.total ?? 0
  const totalWithTaxes = totalBeforeTaxes + productTaxes

  useEffect(() => {
    const taxDetailsPayload = {
      chargeAmount: totalPrice,
      city: subscription.accountDetails.city,
      state: subscription.accountDetails.state?.name,
      zipCode: subscription.accountDetails.zipCode,
      country: subscription.accountDetails.country?.name,
      productId: product.id,
    }

    dispatch(fetchTaxes({ taxDetails: taxDetailsPayload, jurCode: jurCode! }))
  }, [dispatch, product])

  return (
    <Box sx={{ p: '16px 32px 8px 32px', fontFamily: 'Open Sans' }}>
      <Box
        sx={{
          color: 'grey.800',
          lineHeight: '16px',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          pb: '24px',
          mb: '24px',
          borderBottom: `1px solid ${colors.quillGray}`,
        }}
      >
        <Trans i18nKey='subscriptionPaymentDetailsAccordion.note'>
          <span style={{ color: colors.blue.main }}>note</span>
        </Trans>
      </Box>

      <Table
        sx={{
          mb: '24px',
          border: '1px solid',
          borderColor: 'grey.100',
          '.MuiTableCell-root': {
            p: '12px 8px',
            lineHeight: '16px',
            fontSize: '12px',
            '&.price, &.packageName': { fontFamily: 'Open Sans SemiBold' },
            '&.licenses, &.discount': { textAlign: 'center' },
            '&:first-of-type': { pl: '16px' },
            '&:last-of-type': { pr: '16px' },
          },
          '.MuiTableCell-head': {
            fontFamily: 'Open Sans SemiBold',
            '&:first-of-type': { width: '216px' },
          },
          '.packageRow .MuiTableCell-body': {
            '&:first-of-type': {
              borderBottom: 0,
              '&.packageName': { borderTop: '1px solid', borderColor: 'grey.100' },
            },
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>{t('subscriptionPaymentDetailsAccordion.columns.package')}</TableCell>
            <TableCell>{t('subscriptionPaymentDetailsAccordion.columns.term')}</TableCell>
            <TableCell>{t('subscriptionPaymentDetailsAccordion.columns.licenses')}</TableCell>
            <TableCell>{t('subscriptionPaymentDetailsAccordion.columns.pricePerLicense')}</TableCell>
            <TableCell>{t('subscriptionPaymentDetailsAccordion.columns.volumeDiscount')}</TableCell>
            <TableCell>{t('subscriptionPaymentDetailsAccordion.columns.netPricePerLicense')}</TableCell>
            <TableCell>{t('subscriptionPaymentDetailsAccordion.columns.total')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedPackages.map((_package) => {
            const price = _package.prices.find((_price) => _price.currencyId === currency.id)!
            const licenseAmount = licenses[_package.packageGroupName]
            const totalPackagePrice = getTotalPackagePrice(price.amount, licenseAmount, discounts, commonTerm)
            const applicableDiscounts = discounts?.filter(
              ({ toLicenseAmount, fromLicenseAmount }) =>
                licenseAmount >= toLicenseAmount || licenseAmount >= fromLicenseAmount
            )

            return (
              <Fragment key={_package.id}>
                {applicableDiscounts.map(({ id: _id, percentage, fromLicenseAmount, toLicenseAmount }, index) => {
                  const maxDiscountLicenseAmount = toLicenseAmount - fromLicenseAmount + 1
                  const discountLicenseAmount =
                    licenseAmount > toLicenseAmount ? maxDiscountLicenseAmount : licenseAmount - fromLicenseAmount + 1

                  return (
                    <TableRow className='packageRow' key={_id}>
                      <TableCell className={index === 0 ? 'packageName' : 'empty'}>
                        {index === 0 && _package.name}
                      </TableCell>
                      <TableCell>
                        {commonTerm.duration}{' '}
                        {t(`manageProduct.subscriptions.year${commonTerm.duration > 1 ? 's' : ''}`).toLowerCase()}
                      </TableCell>
                      <TableCell className='licenses'>{discountLicenseAmount}</TableCell>
                      <TableCell className='price'>
                        {currency.symbol} {getFormatedPrice(price.amount)} {currency.name}
                      </TableCell>
                      <TableCell className='discount'>{percentage * 100}%</TableCell>
                      <TableCell className='price'>
                        {currency.symbol} {getFormatedPrice(price.amount * (1 - percentage))} {currency.name}
                      </TableCell>
                      <TableCell className='price'>
                        {currency.symbol} {getFormatedPrice(price.amount * (1 - percentage) * discountLicenseAmount)}{' '}
                        {currency.name}
                      </TableCell>
                    </TableRow>
                  )
                })}
                {applicableDiscounts?.length > 1 && (
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell className='licenses'>{licenseAmount}</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell className='price'>
                      {currency.symbol} {getFormatedPrice(totalPackagePrice)} {currency.name}
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            )
          })}
        </TableBody>
      </Table>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          fontSize: '12px',
          pb: '24px',
          mb: '24px',
          borderBottom: `1px solid ${colors.quillGray}`,
        }}
      >
        <Box sx={{ width: '400px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>{t('subscriptionPaymentDetailsAccordion.subtotalBeforeTaxes')}</Box>
            <Box sx={{ fontFamily: 'Open Sans SemiBold' }}>
              {currency.symbol} {getFormatedPrice(totalBeforeTaxes)} {currency.name}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '8px' }}>
            <Box>{t('subscriptionPaymentDetailsAccordion.taxes')}</Box>
            <Box sx={{ fontFamily: 'Open Sans SemiBold' }}>
              {currency.symbol} {getFormatedPrice(productTaxes)} {currency.name}
            </Box>
          </Box>
          <Box
            sx={{
              fontSize: '14px',
              fontFamily: 'Open Sans SemiBold',
              display: 'flex',
              justifyContent: 'space-between',
              mt: '16px',
            }}
          >
            <Box>{t('subscriptionPaymentDetailsAccordion.total', { jurCode: product.countries![0].abbreviation })}</Box>
            <Box>
              {currency.symbol} {getFormatedPrice(totalWithTaxes)} {currency.name}
            </Box>
          </Box>
        </Box>
      </Box>

      {productFeatures.includes(ProductFeature.DeloittePartner) && <EligibilityCheck productId={id} />}

      <PaymentTypes productId={id} />

      {
        {
          [PaymentType.CreditCard]: paymentDetailsActiveStep === idx && (
            <CreditCard productId={id} currencyId={currency.id} jurCode={product.countries![0].abbreviation} />
          ),
          [PaymentType.Invoice]: <InvoiceDetails productId={id} />,
        }[paymentType]
      }
    </Box>
  )
}
