import { emailRegEx } from 'features/subscriptionRequest/constants/email'
import { TFormValidation } from './types'

export const filterFormFieldsByErrors = <T>(object: TFormValidation<T>) => {
  const errorsFiltered = Object.entries(object)
    .filter(([, fieldValid]) => !fieldValid.valid)
    .map(([formFieldName]) => formFieldName)

  return errorsFiltered
}

export const defineFormValidationValues = <T>(formValue: T[keyof T], field: string) => {
  const dunsLengthValidation = typeof formValue === 'string' && formValue!.length === 9
  const emailValidation = typeof formValue === 'string' && emailRegEx.test(formValue)

  return typeof formValue === 'object'
    ? true
    : field === 'duns'
    ? formValue && dunsLengthValidation
    : field === 'email'
    ? formValue && emailValidation
    : !!(formValue as string).trim()
}

export const handleValidateForm = <T>(
  requiredValues: string[],
  formFields: T,
  statusForm: React.MutableRefObject<{
    isDirtied: boolean
    isTyped: boolean
  }>
) => {
  const validationForm: Partial<T> = {}

  for (const requiredField of requiredValues) {
    if (formFields[requiredField as keyof T]) {
      const formValue = formFields[requiredField as keyof T]

      if (typeof formValue === 'string' && formValue.trim()) {
        statusForm.current.isTyped = false
        statusForm.current.isDirtied = true
      }

      validationForm[requiredField as keyof T] = {
        valid: defineFormValidationValues(formValue, requiredField),
      } as T[keyof T]
    }
  }

  return validationForm
}
