import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { geoApi } from 'services/api/geoApi'
import {
  IPackageByProductId,
  IPackageResponse,
  IPackageInfo,
  IPackageInfoGroupedView,
  IPackageGroupedViewResponse,
  IProductGroup,
  IProduct,
  IProductJurisdiction,
  IProductRole,
} from 'features/managePackage/constants'
import { ISortingInfo } from 'constants/sorting'

export interface IPagination {
  size: number
  page: number
}

export const getSubscriptions = createAsyncThunk<
  IPackageByProductId<IPackageInfo | IPackageInfoGroupedView>,
  {
    productId: string
    jurCode: string
    sortingInfo: ISortingInfo
    paginationInfo: IPagination
  },
  { rejectValue: AxiosError }
>(
  'managePackages/getSubscriptions',
  async ({ productId, jurCode, sortingInfo, paginationInfo }, { rejectWithValue }) => {
    try {
      const response = await geoApi
        .withJurisdictionCode(jurCode)
        .post<IPackageByProductId<IPackageResponse | IPackageGroupedViewResponse>>(
          'package/packagesByProductId',
          {
            sort: sortingInfo,
            pagination: {
              size: paginationInfo.size,
              number: paginationInfo.page,
            },
          },
          {
            headers: { 'x-product-id': productId },
          }
        )

      const mapPermissions = response.result.map(({ managePermissions, packagePermissions, ...rest }) => ({
        ...rest,
        permissions: packagePermissions.concat(managePermissions),
      }))
      return { ...response, result: mapPermissions }
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const getProductGroups = createAsyncThunk<IProductGroup[], void, { rejectValue: AxiosError }>(
  'managePackages/getProductGroups',
  async (_, { rejectWithValue }) => {
    try {
      return await geoApi.getAllGeo('product-group')
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

export const getProductJurisdictions = createAsyncThunk<
  IProductJurisdiction[],
  IProductGroup,
  { rejectValue: AxiosError }
>('managePackages/getProductJurisdictions', async (productGroup, { rejectWithValue }) => {
  try {
    const jurCodes = productGroup.products.map((product) => product.countries![0].abbreviation)
    const productList = await geoApi.getSpecificGeo<IProduct[]>(
      `global/employees/current/product-group/${productGroup.id}/product`,
      jurCodes
    )
    return productList.flat().map((product) => ({
      productId: product.id,
      jurisdictionId: product.jurisdiction.id,
      name: product.jurisdiction.name,
      code: product.jurisdiction.code,
      geoCode: product.jurisdiction.geoCode,
      features: product.features,
    }))
  } catch (err) {
    return rejectWithValue(err as AxiosError)
  }
})

export const getProductRoles = createAsyncThunk<
  IProductRole[],
  {
    productId: string
    jurCode: string
  },
  { rejectValue: AxiosError }
>('manageProduct/getProductRoles', async ({ productId, jurCode }, { rejectWithValue }) => {
  try {
    return await geoApi.withJurisdictionCode(jurCode).get(`product/${productId}/role`)
  } catch (err) {
    return rejectWithValue(err as AxiosError)
  }
})
