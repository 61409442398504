import { useEffect, useMemo, useRef, useState } from 'react'
import { useHref } from 'react-router-dom'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setIsEdit, setPrivacy, setSectionsState, setSelectedJurisdiction } from 'features/productConfiguration/slice'
import {
  acceptedFileTypes,
  Jurisdiction,
  privacyCheckboxOptions,
  PrivacyViewType,
  SectionName,
} from 'features/productConfiguration/constants'
import { ProductSectionHeader } from 'features/manageProduct/components/ProductSectionHeader'
import { FileUploadPopup } from 'components/FileUploadPopup'
import { CustomTextField } from 'components/CustomTextField'
import { CustomFormLabel } from 'components/CustomFormLabel'
import { CustomAlert } from 'components/CustomAlert'
import { UploadIcon } from 'components/icons/Upload.icon'
import { RemoveIcon } from 'components/icons/Remove.icon'
import { UploadToCloudIcon } from 'components/icons/UploadToCloud.icon'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { NewTabIcon } from 'components/icons/NewTab.icon'
import { InfoIcon } from 'components/icons/Info.icon'
import { CustomArrowTooltip } from 'components/CustomTooltip'
import { CustomRadioGroup } from 'components/CustomRadioGroup'
import { useContainerScroll } from 'utils/scrollableFooter'
import colors from 'theme/colors'
import { getValidExternalURL } from 'utils/externalURL'
import { IFile } from 'constants/file'
import { MockFooterBlock } from 'components/Footer/mockFooterBlock'

const headerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '56px',
  padding: '10px 16px',
  borderBottom: '1px solid',
  borderColor: colors.border,
}

export const Privacy = () => {
  const { t } = useTranslation()
  const previewUrl = useHref('/privacy')
  const dispatch = useAppDispatch()
  const { productData, selectedJurisdiction, sectionsState, isEdit } = useAppSelector(
    (state) => state.productConfiguration
  )
  const [privacyTitle, setPrivacyTitle] = useState(productData.privacy[selectedJurisdiction]?.title || '')
  const [externalLink, setExternalLink] = useState(productData.privacy[selectedJurisdiction]?.externalLink || '')
  const [privacyFile, setPrivacyFile] = useState<IFile | null>(
    productData.privacy[selectedJurisdiction]?.document || null
  )
  const [isSaved, setIsSaved] = useState(false)
  const [areEmptyRequired, setAreEmptyRequired] = useState(false)
  const [showFileUploadPopup, setShowFileUploadPopup] = useState(false)
  const [isExternalLinkSaved, setIsExternalLinkSaved] = useState(false)
  const [privacyViewType, setPrivacyViewType] = useState(
    productData.privacy[selectedJurisdiction]?.privacyViewType || PrivacyViewType.Link
  )
  const maxFileSizeMB = 20
  const containerRef = useRef<HTMLDivElement | null>(null)

  const isPrivacyInvalid = useMemo(() => {
    return !productData.privacy[selectedJurisdiction]
  }, [productData.privacy, selectedJurisdiction])

  const isSaveDisabled = useMemo(() => {
    return !privacyTitle || (!privacyFile && !externalLink) || areEmptyRequired
  }, [privacyTitle, privacyFile, externalLink, areEmptyRequired])

  const isEditMode = useMemo(() => {
    return isEdit || isPrivacyInvalid
  }, [isEdit, isPrivacyInvalid])

  const onSave = () => {
    setIsSaved(true)

    if (!privacyTitle.trim() || (externalLink && !externalLink.trim())) {
      setAreEmptyRequired(true)
    } else {
      setIsSaved(false)
      setAreEmptyRequired(false)
      dispatch(
        setPrivacy({
          title: privacyTitle,
          document: privacyFile!,
          privacyViewType,
          externalLink,
        })
      )
      if (externalLink) setIsExternalLinkSaved(true)
      dispatch(setIsEdit(false))
    }
  }

  const onEdit = () => {
    dispatch(setIsEdit(true))
    dispatch(
      setSectionsState(
        sectionsState.map((section) => {
          return section.name === SectionName.Integrations ? { ...section, available: false } : section
        })
      )
    )
  }

  const onSaveDocument = (file: IFile) => {
    setPrivacyFile(file)
    setShowFileUploadPopup(false)
  }

  const openPrivacyPreview = (file: IFile) => {
    localStorage.setItem('privacyPreview', JSON.stringify(file.data))
    window.open(previewUrl, '_blank')?.focus()
  }

  const openExternalLink = () => {
    window.open(getValidExternalURL(externalLink), '_blank')?.focus()
  }

  useContainerScroll(containerRef, dispatch)

  useEffect(() => {
    if (Object.keys(productData.privacy).length) {
      if (selectedJurisdiction === Jurisdiction.All && !productData.privacy[Jurisdiction.All]) {
        dispatch(setSelectedJurisdiction(productData.jurisdictions[0]))
      } else if (selectedJurisdiction !== Jurisdiction.All && !!productData.privacy[Jurisdiction.All]) {
        dispatch(setSelectedJurisdiction(Jurisdiction.All))
      }
    }
  }, [])

  useEffect(() => {
    setAreEmptyRequired(false)
  }, [privacyTitle, privacyFile, externalLink])

  useEffect(() => {
    if (productData.privacy[selectedJurisdiction]) {
      setIsEdit(false)
    } else {
      setIsSaved(false)
      setIsExternalLinkSaved(false)
      setIsEdit(true)
    }
    setPrivacyTitle(productData.privacy[selectedJurisdiction]?.title || '')
    setPrivacyFile(productData.privacy[selectedJurisdiction]?.document || null)
    setExternalLink(productData.privacy[selectedJurisdiction]?.externalLink || '')
    setPrivacyViewType(productData.privacy[selectedJurisdiction]?.privacyViewType || PrivacyViewType.Link)
  }, [selectedJurisdiction])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
    >
      <ProductSectionHeader isEditMode={isEditMode} isSaveDisabled={isSaveDisabled} onSave={onSave} onEdit={onEdit} />
      <Box
        ref={containerRef}
        sx={{
          padding: '16px',
          overflowY: 'auto',
          width: '100%',
        }}
      >
        <Box
          sx={{
            border: '1px solid',
            borderColor: colors.border,
            borderRadius: '4px',
          }}
        >
          <Typography sx={headerStyles} variant='h4' fontWeight='600'>
            {t('productConfiguration.privacy.header')}
          </Typography>
          {areEmptyRequired && (
            <CustomAlert text={t('productConfiguration.form.completeRequired')!} severity={'error'} />
          )}
          <Box sx={{ padding: '12px 16px 16px 16px', width: '832px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '24px' }}>
              <CustomFormLabel sx={{ mb: 0 }} value={t('productConfiguration.privacy.view.label')!} />
              {isEditMode && (
                <CustomArrowTooltip placement='top' title={t('productConfiguration.privacy.view.tooltip')}>
                  <Box sx={{ width: '24px', height: '24px' }}>
                    <InfoIcon />
                  </Box>
                </CustomArrowTooltip>
              )}
            </Box>
            <CustomRadioGroup
              row
              readonly={!isEditMode}
              sx={{ marginTop: '-4px', label: { marginRight: '30px' } }}
              value={String(privacyViewType)}
              options={privacyCheckboxOptions}
              onChange={(e) => setPrivacyViewType(Number(e.target.value))}
            />

            <CustomTextField
              sx={{
                marginTop: '16px',
                '& p': {
                  fontWeight: isEditMode ? 400 : 600,
                  marginBottom: '16px',
                },
              }}
              value={privacyTitle}
              onChange={(e) => setPrivacyTitle(e.target.value)}
              label={t('productConfiguration.privacy.title.label')!}
              placeholder={t('productConfiguration.privacy.title.placeholder')!}
              readonly={!isEditMode}
              error={isSaved && !privacyTitle.trim()}
              limitation={200}
              highlightOnFocus
              multiline
            />

            <Box sx={{ display: 'flex', marginTop: '12px' }}>
              {isEditMode && (
                <>
                  {externalLink && isExternalLinkSaved ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <CustomFormLabel sx={{ mb: 0 }} value={t('productConfiguration.privacy.externalLink.label')!} />
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '12px',
                        }}
                      >
                        <UploadToCloudIcon />
                        <Box fontFamily='Open Sans' maxWidth='300px' fontSize='14px' marginLeft='4px'>
                          <OverflowTooltip text={externalLink} />
                        </Box>
                        <Tooltip
                          title={t('productConfiguration.privacy.externalLink.delete')}
                          placement='top-end'
                          disableInteractive
                          arrow
                          componentsProps={{
                            tooltip: {
                              style: { marginBottom: '8px' },
                            },
                          }}
                        >
                          <IconButton
                            sx={{ p: '0 5px', ml: 'auto' }}
                            onClick={() => {
                              setExternalLink('')
                              setIsExternalLinkSaved(false)
                            }}
                          >
                            <RemoveIcon color='action' />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  ) : (
                    <CustomTextField
                      sx={{
                        flex: 1,
                        '& p': {
                          fontWeight: isEditMode ? 400 : 600,
                          marginBottom: '16px',
                        },
                      }}
                      disabled={!!privacyFile}
                      value={externalLink}
                      onChange={(e) => setExternalLink(e.target.value)}
                      label={t('productConfiguration.privacy.externalLink.label')!}
                      placeholder={t('productConfiguration.privacy.externalLink.placeholder')!}
                      readonly={!isEditMode}
                      error={isSaved && !!externalLink && !externalLink.trim()}
                      inputProps={{ maxLength: 200 }}
                      highlightOnFocus
                    />
                  )}
                  <Typography
                    sx={{
                      padding: '0 16px 8px 16px',
                      fontSize: '14px',
                      marginTop: 'auto',
                    }}
                  >
                    {t('productConfiguration.privacy.or')}
                  </Typography>
                </>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <CustomFormLabel value={t('productConfiguration.privacy.document.label')!} />
                {privacyFile || (externalLink && !isEditMode) ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '4px',
                      ...(!isEditMode && { width: '400px' }),
                    }}
                  >
                    <UploadToCloudIcon />
                    <Box fontFamily='Open Sans' maxWidth='300px' fontSize='14px' marginLeft='4px'>
                      <OverflowTooltip text={privacyFile?.name || externalLink} />
                    </Box>
                    {isEditMode ? (
                      <Tooltip
                        title={t('productConfiguration.privacy.document.delete')}
                        placement='top-end'
                        disableInteractive
                        arrow
                        componentsProps={{
                          tooltip: {
                            style: { marginBottom: '8px' },
                          },
                        }}
                      >
                        <IconButton sx={{ p: '0 5px', ml: 'auto' }} onClick={() => setPrivacyFile(null)}>
                          <RemoveIcon color='action' />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <IconButton
                        sx={{ p: '0 5px', ml: 'auto' }}
                        onClick={() => {
                          privacyFile ? openPrivacyPreview(privacyFile) : openExternalLink()
                        }}
                      >
                        <NewTabIcon color='action' />
                      </IconButton>
                    )}
                  </Box>
                ) : (
                  <Button
                    disabled={!!externalLink}
                    sx={{
                      height: '36px',
                      background: externalLink ? colors.bgDisabled : 'transparent',
                    }}
                    variant='outlined'
                    startIcon={<UploadIcon sx={{ fontSize: '24px!important' }} />}
                    onClick={() => setShowFileUploadPopup(true)}
                    data-testid='upload-button'
                  >
                    {t('productConfiguration.privacy.document.upload')}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <MockFooterBlock />
      </Box>
      <FileUploadPopup
        acceptedFileTypes={acceptedFileTypes}
        maxFileSizeMB={maxFileSizeMB}
        open={showFileUploadPopup}
        onClose={() => setShowFileUploadPopup(false)}
        onSave={onSaveDocument}
      />
    </Box>
  )
}
