import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, SelectChangeEvent, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  ISection,
  Jurisdiction,
  ProductConfigurationSection,
  ProductGroupType,
  SectionName,
} from 'features/productConfiguration/constants'
import { ProductJurisdictionFilters } from 'features/productConfiguration/component/ProductJurisdictionFilters'
import { createProductGroup, fetchJurisdictions } from 'features/productConfiguration/services'
import { ProductDetails } from 'features/productConfiguration/component/ProductDetails'
import { AcceptableUsePolicy } from 'features/productConfiguration/component/AcceptableUsePolicy'
import { Privacy } from 'features/productConfiguration/component/Privacy'
import {
  resetProductConfigurationData,
  setCurrentSection,
  setIsEdit,
  setSectionsState,
  setSelectedJurisdiction,
} from 'features/productConfiguration/slice'
import { LicenseType, ProductFeature } from 'features/subscriptionRequest/constants/subscription'
import { LockIcon } from 'components/icons/Lock.icon'
import { ConfirmPopup, ConfirmPopupButton } from 'components/ConfirmPopup'
import { ProductConfigurationHeader } from '../ProductConfigurationHeader'
import { ProductPackages } from '../ProductPackages'
import { Integrations } from '../Integrations'
import { Locales } from 'constants/languageKeys'
import colors from 'theme/colors'

const sectionStyles = (section: ISection, isCurrent: boolean): SxProps => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 8px 12px 13px',
  borderBottom: '1px solid',
  borderBottomColor: colors.border,
  borderLeft: '3px solid transparent',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  lineHeight: '24px',
  cursor: 'default',
  ...(section.available &&
    !isCurrent && {
      cursor: 'pointer',
    }),
  ...(!section.available && {
    color: colors.silverSand,
  }),
  ...(isCurrent && {
    fontWeight: 600,
    color: 'primary.main',
    borderLeftColor: 'primary.main',
    background: colors.lightBlue,
  }),
})

export const ProductConfigurationContainer = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [showCancelPopup, setShowCancelPopup] = useState(false)
  const [showSavePopup, setShowSavePopup] = useState(false)
  const { currentSection, sectionsState, jurisdictions, productData, selectedJurisdiction } = useAppSelector(
    (state) => state.productConfiguration
  )

  const isCurrentSection = (i: number): boolean => currentSection === i + 1

  const cancelPopupButtons = useMemo((): ConfirmPopupButton[] => {
    return [
      {
        action: () => setShowCancelPopup(false),
        name: t('button.no'),
        style: {
          minWidth: '54px',
          borderRadius: '2px',
          marginRight: '16px',
        },
        type: 'outlined',
      },
      {
        action: () => {
          navigate('/manage/productConfiguration')
        },
        name: t('button.yes'),
        style: {
          minWidth: '56px',
          borderRadius: '2px',
        },
        type: 'contained',
      },
    ]
  }, [t, navigate])

  const onSaveProduct = useCallback(() => {
    const enCode = Locales['en-US'].code!,
      frCode = Locales['fr-CA'].code!
    const jurisdictionItems = productData.jurisdictions.map((jurisdictionName) => {
      return jurisdictions.find((jurisdiction) => jurisdiction.name === jurisdictionName)!
    })
    const features: ProductFeature[] = [
      ...(productData.askAreDeloitteClient ? [ProductFeature.AuditClient] : []),
      ...(productData.managingUsers ? [ProductFeature.UserList] : []),
    ]
    const payload = {
      id: crypto.randomUUID(),
      name: productData.name,
      shortName: productData.shortName,
      type: ProductGroupType.Single,
      locales: [enCode, frCode],
      features,
      jurisdictions: jurisdictionItems.map((jurisdictionItem) => {
        const _privacy = productData.privacy[jurisdictionItem.name] || productData.privacy[Jurisdiction.All]
        const _packages = [
          ...(productData.packages[jurisdictionItem.name] || []),
          ...(productData.packages[Jurisdiction.All] || []),
        ]
        const _policies = productData.policies[jurisdictionItem.name] || productData.policies[Jurisdiction.All]
        return {
          jurisdictionId: jurisdictionItem.id,
          packages: _packages.map((_package) => ({
            name: _package.name,
            description: [
              { text: _package.description, locale: enCode },
              { text: _package.descriptionFrench, locale: frCode },
            ],
            durations: _package.durations?.map((duration) => Number(duration)) || null,
            licenseType: _package.type === 'duration' ? LicenseType.SingleLicense : LicenseType.TokenBased,
          })),
          policies: [
            {
              title: _policies.title,
              text: _policies.file.data,
              locale: enCode,
            },
            {
              title: _policies.titleFrench,
              text: _policies.fileFrench.data,
              locale: frCode,
            },
          ],
          privacy: {
            privacyViewTypeId: _privacy.privacyViewType,
            localizations: [
              {
                title: _privacy.title,
                text: _privacy.document?.data || null,
                link: _privacy.externalLink,
                locale: enCode,
              },
            ],
          },
        }
      }),
      roles: productData.roles,
      applicationId: productData.integrations.appId,
      url: productData.integrations.homePageUrl,
    }

    createProductGroup(payload, jurisdictionItems).then(() => {
      navigate('/manage/productConfiguration')
    })
  }, [productData, jurisdictions])

  const savePopupButtons = useMemo((): ConfirmPopupButton[] => {
    return [
      {
        action: () => setShowSavePopup(false),
        name: t('button.no'),
        style: {
          minWidth: '54px',
          borderRadius: '2px',
          marginRight: '16px',
        },
        type: 'outlined',
      },
      {
        action: onSaveProduct,
        name: t('button.yes'),
        style: {
          minWidth: '56px',
          borderRadius: '2px',
        },
        type: 'contained',
      },
    ]
  }, [t, onSaveProduct])

  const handlerChangeJurisdictions = useCallback(
    (event: SelectChangeEvent) => {
      dispatch(setSelectedJurisdiction(event.target.value))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!jurisdictions.length) {
      dispatch(fetchJurisdictions())
    }

    return () => {
      dispatch(resetProductConfigurationData())
      dispatch(setIsEdit(false))
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(setIsEdit(false))
  }, [currentSection])

  useEffect(() => {
    const isPackagesValid =
      !!Object.values(productData.packages).length &&
      (productData.packages[Jurisdiction.All] ||
        productData.jurisdictions.every((jurisdiction) => productData.packages[jurisdiction]))
    const isPoliciesValid =
      !!Object.values(productData.policies).length &&
      (productData.policies[Jurisdiction.All] ||
        productData.jurisdictions.every((jurisdiction) => productData.policies[jurisdiction]))

    const isPrivacyValid =
      isPackagesValid &&
      isPoliciesValid &&
      (productData.privacy[Jurisdiction.All] ||
        productData.jurisdictions.every((jurisdiction) => productData.privacy[jurisdiction]))

    dispatch(
      setSectionsState(
        sectionsState.map((section) =>
          section.name === SectionName.Packages
            ? { ...section, available: productData.name }
            : section.name === SectionName.AcceptableUsePolicy
            ? { ...section, available: isPackagesValid }
            : section.name === SectionName.Privacy
            ? { ...section, available: isPackagesValid && isPoliciesValid }
            : section.name === SectionName.Integrations
            ? { ...section, available: isPrivacyValid }
            : section
        )
      )
    )
  }, [productData, currentSection])

  useEffect(() => {
    if (currentSection === ProductConfigurationSection.Integrations && selectedJurisdiction !== Jurisdiction.All) {
      dispatch(setSelectedJurisdiction(Jurisdiction.All))
    }
  }, [selectedJurisdiction, currentSection, dispatch])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
    >
      <ProductJurisdictionFilters onChangeJurisdictions={handlerChangeJurisdictions} />
      <ProductConfigurationHeader
        isCreateMode
        onCancel={() => setShowCancelPopup(true)}
        onSave={() => setShowSavePopup(true)}
      />
      <Box
        sx={{
          display: 'flex',
          height: 'calc(100% - 56px - 48px)',
        }}
      >
        <Box
          sx={{
            width: '240px',
            borderRight: '1px solid',
            borderColor: colors.border,
          }}
        >
          {sectionsState.map((section, i) => (
            <Box
              key={section.name}
              sx={sectionStyles(section, isCurrentSection(i))}
              onClick={() => section.available && dispatch(setCurrentSection(i + 1))}
            >
              <Box>{t(section.name)}</Box>
              {!section.available && <LockIcon />}
            </Box>
          ))}
        </Box>
        <Box flex={1}>
          {
            {
              [ProductConfigurationSection.Details]: <ProductDetails />,
              [ProductConfigurationSection.Packages]: <ProductPackages />,
              [ProductConfigurationSection.AcceptableUsePolicy]: <AcceptableUsePolicy />,
              [ProductConfigurationSection.Privacy]: <Privacy />,
              [ProductConfigurationSection.Integrations]: <Integrations />,
            }[currentSection]
          }
        </Box>
      </Box>

      <ConfirmPopup
        icon='warning'
        title={t('productConfiguration.cancelPopup.title')}
        message={t('productConfiguration.cancelPopup.message')}
        buttons={cancelPopupButtons}
        open={showCancelPopup}
        onClose={() => setShowCancelPopup(false)}
      />

      <ConfirmPopup
        title={t('productConfiguration.savePopup.title')}
        message={t('productConfiguration.savePopup.message')}
        buttons={savePopupButtons}
        open={showSavePopup}
        onClose={() => setShowSavePopup(false)}
      />
    </Box>
  )
}
