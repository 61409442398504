import React, { useMemo } from 'react'
import { Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Actions from '../PackageActions'

import { handleApproveSubModal, handleInactivateSubModal, handleRejectSubModal } from 'sharedSlices/ui'
import { useAppDispatch, useAppSelector } from 'app/hooks'

import { IPackageInfo } from 'features/managePackage/constants'
import { Permissions } from 'constants/permissions'
import { ProductFeature } from 'features/subscriptionRequest/constants/subscription'
import { PackageStatus } from 'features/manageProduct/constants'
import { getProductRoles, setSelectedPackage } from 'features/managePackage/slice'
import { reactivateSubscription } from 'features/managePackage/services'
import { useGetSubscriptions } from 'features/managePackage/hooks/useGetSubscriptions'
import { showMessage } from 'utils/errorMessageHandler'

interface Props {
  packageInfo: IPackageInfo
}

export const ManagePackageActions = ({ packageInfo }: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { getSubscriptionInfo } = useGetSubscriptions()
  const navigate = useNavigate()
  const productSelectedId = searchParams.get('productId')
  const jurCode = searchParams.get('code')

  const { prodJurisSelected } = useAppSelector((state) => state.managePackage)

  const isPendingStatus = packageInfo?.status === PackageStatus.Pending

  const hasProductRoleFeature = useMemo(() => {
    return prodJurisSelected!.features.includes(ProductFeature.Role)
  }, [prodJurisSelected])

  const showProductRole = productSelectedId && isPendingStatus && hasProductRoleFeature

  const selectSubscription = () => dispatch(setSelectedPackage(packageInfo))

  const handleReject = () => {
    selectSubscription()
    dispatch(handleRejectSubModal(true))
  }
  const handleApprove = () => {
    selectSubscription()
    showProductRole && dispatch(getProductRoles({ productId: productSelectedId, jurCode: jurCode! }))
    dispatch(handleApproveSubModal(true))
  }
  const handleEditAccountDetails = () => {
    navigate({
      pathname: `/subscribe/subscription/${packageInfo.subscriptionId}/account-details`,
      search: `?productId=${packageInfo.productId}&jurCode=${jurCode}`,
    })
  }
  const handleInactivate = () => {
    selectSubscription()
    dispatch(handleInactivateSubModal(true))
  }
  const handleReactivate = () => {
    const payload = {
      note: 'reactivation',
      subscriptionPackageIds: [packageInfo.subscriptionPackageId],
    }
    reactivateSubscription(packageInfo.subscriptionId, jurCode!, packageInfo.productId, payload)
      .then(() => {
        getSubscriptionInfo(packageInfo.productId, jurCode!)
        showMessage.success('mySnackbar', 'manageProduct.subscriptionMessage.success')
      })
      .catch(() => showMessage.error('mySnackbar', 'manageProduct.subscriptionMessage.error'))
  }

  const handleActionsVisualization = () => {
    const actions = [
      {
        permission: Permissions.ApproveRejectSubscriptions,
        component: (
          <>
            <Actions.Reject onClick={handleReject} title={t('managePackages.tooltip.reject')} />
            <Actions.Approve onClick={handleApprove} title={t('managePackages.tooltip.approve')} />
          </>
        ),
      },
      {
        permission: Permissions.InactivateSubscriptions,
        component: <Actions.Inactivate onClick={handleInactivate} title={t('managePackages.tooltip.inactivate')} />,
      },
      {
        permission: Permissions.ReactivateSubscriptions,
        component: <Actions.Reactivate onClick={handleReactivate} title={t('managePackages.tooltip.reactivate')} />,
      },
    ]

    const hasPermission = actions.some((action) => packageInfo.permissions.includes(action.permission))

    return (
      <>
        {hasPermission && <Divider sx={{ m: '8px 4px' }} orientation='vertical' variant='middle' flexItem />}

        {actions.map(
          ({ permission, component }) =>
            packageInfo.permissions.includes(permission) && (
              <React.Fragment key={permission}>{component}</React.Fragment>
            )
        )}
      </>
    )
  }

  return (
    <Actions>
      <Actions.ManageUsers onClick={handleReject} title={t('manageProduct.subscriptionActions.manageUser')} />
      <Actions.TransactionLog onClick={handleReject} title={t('manageProduct.subscriptionActions.manageUser')} />
      <Actions.AccountDetails onClick={handleEditAccountDetails} title={t('managePackages.tooltip.accountDetails')} />
      {handleActionsVisualization()}
    </Actions>
  )
}
