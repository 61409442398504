import axios from 'axios'

const httpLocalization = axios.create({
  baseURL: window.APP_CONFIG.LOCALIZATION_URL,
})

export default {
  get<T>(url: string, headers?: RequestHeaders): Promise<T> {
    return httpLocalization.get(url, headers).then((response) => response.data)
  }
}
