import colors from 'theme/colors'

const disabledFormElementsStyles = {
  '.MuiOutlinedInput-input': {
    ['-webkit-text-fill-color']: colors.mineShaft,
  },

  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}

export const autocompleteStyles = {
  mb: 0,
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: colors.white,
    border: 'none',
    marginLeft: '-10px',

    '> .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
    ...disabledFormElementsStyles,
  },
}

export const customSelectStyles = {
  '& .MuiInputBase-root.Mui-disabled': {
    '.MuiSvgIcon-root': {
      display: 'none',
    },
    '.MuiSelect-select': {
      paddingLeft: 0,
    },
    ...disabledFormElementsStyles,
  },
}

export const phoneFieldStyles = {
  position: 'absolute',
  width: '290px',
  right: '140px',
  bottom: '281px',
}

export const emptyFieldValue = '–'
