import { SvgIcon, SvgIconProps } from '@mui/material'

export const CommentIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        color='secondary'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.5 6C19.7761 6 20 6.22386 20 6.5V16.5C20 16.7761 19.7761 17 19.5 17H11.2269L7.87629
          20.8293C7.57227 21.1767 7 20.9617 7 20.5V17H4.5C4.22386 17 4 16.7761 4 16.5V6.5C4 6.22386
          4.22386 6 4.5 6H19.5ZM19 7H5V16H7.5C7.77614 16 8 16.2239 8 16.5V19.1693L10.6237
          16.1707C10.7187 16.0622 10.8558 16 11 16H19V7ZM8.5 11C8.77614 11 9 11.2239 9 11.5C9
          11.7761 8.77614 12 8.5 12H7.5C7.22386 12 7 11.7761 7 11.5C7 11.2239 7.22386 11 7.5
          11H8.5ZM12.5 11C12.7761 11 13 11.2239 13 11.5C13 11.7761 12.7761 12 12.5 12H11.5C11.2239
          12 11 11.7761 11 11.5C11 11.2239 11.2239 11 11.5 11H12.5ZM16.5 11C16.7761 11 17 11.2239 17
          11.5C17 11.7761 16.7761 12 16.5 12H15.5C15.2239 12 15 11.7761 15 11.5C15 11.2239 15.2239
          11 15.5 11H16.5Z'
        />
      </SvgIcon>
    </>
  )
}
