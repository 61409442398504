import { PackageStatus } from 'features/manageProduct/constants'
import { ISortingInfo, SortingDirection } from 'constants/sorting'

export type SubscriptionStatus = 'active' | 'under-review' | 'expired' | 'pending' | 'inactive'

export interface IPackageTerm {
  duration: number
  id: string
  packageId: string
}

export interface IPackage {
  subscriptionPackageId: string
  subscriptionId: string
  packagePriceId: string
  availableLicenseQuantity: number
  companyName: string
  endDate: string | null
  licenseQuantity: number | string
  packageDetails: IPackageDetails
  packageOrder: number
  licenseQuantityLimit: number
  licenseType: number
  packageName: string
  pendingLicenseQuantity: number
  productId: string
  startDate: string | null
  status: PackageStatus
  submittedDate: string
  term: IPackageTerm | null
}

export interface IPackageInfo extends IPackage {
  permissions: number[]
}

export interface IPackageInfoGroupedView extends IPackageInfo {
  packages: string
  licenseQuantities: string
  paymentType: string | null
  subscriptionDetails: ISubscriptionDetails
  packagePermissions: number[]
  managePermissions: number[]
}

export interface IPackageResponse extends IPackage {
  managePermissions: number[]
  packagePermissions: number[]
}

export interface IPackageGroupedViewResponse extends IPackageInfoGroupedView {
  managePermissions: number[]
  packagePermissions: number[]
}

export interface IPackageDetails {
  processedBy: string | null
  processedDate: string | null
  subscriberEmail: string
  lastName: string
  firstName: string
  trackingId: string
}

export interface ISubscriptionDetails extends IPackageDetails {
  subscriptionPackageIds: string[]
  subscriptionType: string
  isRenewed: boolean
  amount: number | null
  originalAmount: number | null
  totalBeforeTax: number
  tax: number | null
  taxExempt: boolean
  taxExemptCertificate: string | null
  discount: number | null
  promoDiscount: number | null
  discountCodes: string | null
  promoTitles: string | null
  transactionDate: string | null
  vatRegistrationNumber: string | null
}

export interface IPackageByProductId<T> {
  result: T[]
  total: number
  hasValuations: boolean
}

export const DEFAULT_SORTING: ISortingInfo = {
  column: 'submittedDate',
  direction: SortingDirection.DESC,
}

export interface IProductRole {
  id: number
  name: string
  productId: string
}

export enum ProductGroupType {
  Single = 1,
  All,
}

export enum TermType {
  Year = 'Year',
}

export interface ITerm {
  id: string
  packageId: string
  duration: number
  type: TermType
}

export enum ProductFeature {
  Role = 'ProductRole',
  AuditClient = 'AuditClient',
  DeloitteClient = 'DeloitteClient',
  Summary = 'Summary',
  Extendable = 'Extendable',
  UserList = 'UserList',
  CommonTerm = 'CommonTerm',
  Payment = 'Payment',
  Discount = 'Discount',
  DeloittePartner = 'DeloittePartner',
  InvoiceDetails = 'InvoiceDetails',
}

export interface ICountry {
  id: number
  name: string
  productId: string
  abbreviation: string
}

export interface IProductJurisdiction {
  productId: string
  jurisdictionId: string
  name: string
  code: string
  geoCode: string
  features: ProductFeature[]
}

export interface IProduct {
  id: string
  name: string
  hasTrial: boolean
  terms: ITerm[]
  alias: string
  features: ProductFeature[]
  countries: ICountry[] | null
  jurisdiction: {
    id: string
    name: string
    code: string
    geoCode: string
  }
  permissions: number[] | null
}

export type PartialProduct = Pick<IProduct, 'id' | 'name' | 'countries' | 'features'>

export interface IProductGroup {
  id: string
  name: string
  shortName: string
  type: ProductGroupType
  products: PartialProduct[]
  url?: string
  languages?: string[]
}
