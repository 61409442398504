import { Box, Button, ButtonProps } from '@mui/material'
import { useDropper } from 'hooks'
import { ChangeEvent, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'

export type DropperSelectedFileProps = { file: File; unselectFile: () => void }

interface IDropper {
  onChange: (file?: File) => void
  fileSelecteView: (args: DropperSelectedFileProps) => ReactNode
  noFileView: ReactNode
  file?: File
  name: string
}

interface ISearchFileButton extends ButtonProps<'label', { component?: 'label' }> {
  isDraggingOver: boolean
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void
  name?: string
}

const SearchFileButton = ({
  isDraggingOver,
  onFileChange,
  sx,
  name,
  ...buttonProps
}: ISearchFileButton) => {
  const { t } = useTranslation()
  return (
    <Button
      {...buttonProps}
      variant='text'
      component='label'
      sx={{
        alignSelf: 'center',
        mt: '16px',
        pointerEvents: !isDraggingOver ? 'initial' : 'none',
        ...sx,
      }}
    >
      {t('manageProduct.users.selectFile')}
      <input hidden type='file' onChange={onFileChange} name={name} />
    </Button>
  )
}

export const Dropper = ({ file, name, noFileView, fileSelecteView, onChange }: IDropper) => {
  const {
    isDraggingOver,
    handleDropCapture,
    handleDrageLeave,
    handleDragEnter,
    handleFileChange,
    unselectFile,
    stopEvent,
  } = useDropper({ onChange })

  return (
    <>
      {!file ? (
        <Box
          onDragOver={stopEvent}
          onDropCapture={handleDropCapture}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDrageLeave}
          sx={{ backgroundColor: colors.lightBlue, height: '100%', width: '100%', p: '8px' }}
          data-testid='dropper-input'
        >
          <Box
            sx={{
              border: !isDraggingOver
                ? `1px dashed ${colors.blue.main}`
                : `2px solid ${colors.blue.main}`,
              backgroundColor: !isDraggingOver ? 'transparent' : colors.white,
              height: '100%',
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              display: 'flex',
              '& *': { pointerEvents: 'none' },
            }}
          >
            {noFileView}
            <SearchFileButton
              onFileChange={handleFileChange}
              isDraggingOver={isDraggingOver}
              name={name}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ height: '100%' }}>{fileSelecteView({ file, unselectFile })}</Box>
      )}
    </>
  )
}
