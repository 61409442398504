import { Box } from '@mui/material'

import { ReloadIcon } from 'components/icons/Reload.icon'
import FooterForm from 'features/manageAdmins/components/footerForm'

interface Props {
  resetForm: () => void
}
export const ErrorView = ({ resetForm }: Props) => {
  return (
    <>
      <Box
        sx={{
          p: '16px',
          height: '206px',
          display: 'flex',
          alignContent: 'flex-end',
          boxSizing: 'border-box',
        }}
      ></Box>
      <FooterForm>
        <FooterForm.Button
          icon={<ReloadIcon />}
          text='button.tryAgain'
          onClick={resetForm}
          variant='outlined'
        />
        <FooterForm.Button text='button.finish' disabled={true} />
      </FooterForm>
    </>
  )
}
