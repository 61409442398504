import { useEffect, useMemo, useRef, useState } from 'react'
import { useHref } from 'react-router-dom'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setIsEdit, setPolicy, setSectionsState, setSelectedJurisdiction } from 'features/productConfiguration/slice'
import { acceptedFileTypes, Jurisdiction, SectionName } from 'features/productConfiguration/constants'
import { ProductSectionHeader } from 'features/manageProduct/components/ProductSectionHeader'
import { FileUploadPopup } from 'components/FileUploadPopup'
import { CustomTextField } from 'components/CustomTextField'
import { CustomFormLabel } from 'components/CustomFormLabel'
import { CustomAlert } from 'components/CustomAlert'
import { UploadIcon } from 'components/icons/Upload.icon'
import { RemoveIcon } from 'components/icons/Remove.icon'
import { UploadToCloudIcon } from 'components/icons/UploadToCloud.icon'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { NewTabIcon } from 'components/icons/NewTab.icon'
import { useContainerScroll } from 'utils/scrollableFooter'
import { MockFooterBlock } from 'components/Footer/mockFooterBlock'
import { Locales } from 'constants/languageKeys'
import colors from 'theme/colors'
import { IFile } from 'constants/file'

const headerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '56px',
  padding: '10px 16px',
  borderBottom: '1px solid',
  borderColor: colors.border,
}

export const AcceptableUsePolicy = () => {
  const { t } = useTranslation()
  const previewUrl = useHref('policy-preview')
  const dispatch = useAppDispatch()
  const { productData, sectionsState, jurisdictions, selectedJurisdiction, isEdit } = useAppSelector(
    (state) => state.productConfiguration
  )
  const [policyTitle, setPolicyTitle] = useState(productData.policies[selectedJurisdiction]?.title || '')
  const [policyFile, setPolicyFile] = useState<IFile | null>(productData.policies[selectedJurisdiction]?.file || null)
  const [policyTitleFrench, setPolicyTitleFrench] = useState(
    productData.policies[selectedJurisdiction]?.titleFrench || ''
  )
  const [policyFileFrench, setPolicyFileFrench] = useState<IFile | null>(
    productData.policies[selectedJurisdiction]?.fileFrench || null
  )
  const [isFrenchDocument, setIsFrenchDocument] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [areEmptyRequired, setAreEmptyRequired] = useState(false)
  const [showFileUploadPopup, setShowFileUploadPopup] = useState(false)
  const maxFileSizeMB = 20
  const containerRef = useRef<HTMLDivElement | null>(null)

  const isPolicyInvalid = useMemo(() => {
    return !productData.policies[selectedJurisdiction]
  }, [productData.policies, selectedJurisdiction])

  const isSaveDisabled = useMemo(() => {
    return !policyTitle || !policyTitleFrench || !policyFile || !policyFileFrench || areEmptyRequired
  }, [policyTitle, policyTitleFrench, policyFile, policyFileFrench, areEmptyRequired])

  const isEditMode = () => isEdit || isPolicyInvalid

  const onSave = () => {
    setIsSaved(true)
    if (!policyTitle.trim() || !policyTitleFrench.trim()) {
      setAreEmptyRequired(true)
    } else {
      setAreEmptyRequired(false)
      dispatch(
        setPolicy({
          title: policyTitle,
          titleFrench: policyTitleFrench,
          file: policyFile!,
          fileFrench: policyFileFrench!,
        })
      )
      dispatch(setIsEdit(false))
    }
  }

  const onEdit = () => {
    dispatch(setIsEdit(true))
    dispatch(
      setSectionsState(
        sectionsState.map((section) => {
          return [SectionName.Privacy, SectionName.Integrations].includes(section.name)
            ? { ...section, available: false }
            : section
        })
      )
    )
  }

  const onSaveDocument = (file: IFile) => {
    isFrenchDocument ? setPolicyFileFrench(file) : setPolicyFile(file)
    setShowFileUploadPopup(false)
  }

  const openPolicyPreview = (file: IFile, title: string, lng: string) => {
    const jurisdictionName =
      selectedJurisdiction === Jurisdiction.All ? productData.jurisdictions[0] : selectedJurisdiction

    const countryCode = jurisdictions.find((jurisdiction) => jurisdiction.name === jurisdictionName)!.code

    localStorage.setItem(
      'policyPreview',
      JSON.stringify({
        jurisdiction: selectedJurisdiction,
        data: file.data,
        countryCode,
        title,
        lng,
      })
    )
    window.open(previewUrl, '_blank')?.focus()
  }

  useContainerScroll(containerRef, dispatch)

  useEffect(() => {
    if (Object.keys(productData.policies).length) {
      if (selectedJurisdiction === Jurisdiction.All && !productData.policies[Jurisdiction.All]) {
        dispatch(setSelectedJurisdiction(productData.jurisdictions[0]))
      } else if (selectedJurisdiction !== Jurisdiction.All && !!productData.policies[Jurisdiction.All]) {
        dispatch(setSelectedJurisdiction(Jurisdiction.All))
      }
    }
  }, [])

  useEffect(() => {
    setAreEmptyRequired(false)
  }, [policyTitle, policyTitleFrench, policyFile, policyFileFrench])

  useEffect(() => {
    if (productData.policies[selectedJurisdiction]) {
      setIsEdit(false)
    } else {
      setIsSaved(false)
      setIsEdit(true)
    }
    setPolicyTitle(productData.policies[selectedJurisdiction]?.title || '')
    setPolicyTitleFrench(productData.policies[selectedJurisdiction]?.titleFrench || '')
    setPolicyFile(productData.policies[selectedJurisdiction]?.file || null)
    setPolicyFileFrench(productData.policies[selectedJurisdiction]?.fileFrench || null)
  }, [selectedJurisdiction])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
    >
      <ProductSectionHeader isEditMode={isEditMode()} isSaveDisabled={isSaveDisabled} onSave={onSave} onEdit={onEdit} />
      <Box
        ref={containerRef}
        sx={{
          padding: '16px',
          overflowY: 'auto',
          width: '100%',
        }}
      >
        <Box
          sx={{
            border: '1px solid',
            borderColor: colors.border,
            borderRadius: '4px',
          }}
        >
          <Typography sx={headerStyles} variant='h4' fontWeight='600'>
            {t('productConfiguration.policy.header')}
          </Typography>
          {areEmptyRequired && (
            <CustomAlert text={t('productConfiguration.form.completeRequired')!} severity={'error'} />
          )}
          <Box sx={{ padding: '16px', width: '832px' }}>
            <CustomTextField
              sx={{
                '& p': {
                  fontWeight: isEditMode() ? 400 : 600,
                  marginBottom: '16px',
                },
              }}
              readonly={!isEditMode()}
              error={isSaved && !policyTitle.trim()}
              limitation={200}
              label={t('productConfiguration.policy.title.label')!}
              placeholder={t('productConfiguration.policy.title.placeholder')!}
              value={policyTitle}
              highlightOnFocus
              multiline
              onChange={(e) => {
                setPolicyTitle(e.target.value)
              }}
            />

            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '12px' }}>
              <CustomFormLabel value={t('productConfiguration.policy.document.label')!} />
              {policyFile ? (
                <Box sx={{ width: '400px', display: 'flex', alignItems: 'center' }}>
                  <UploadToCloudIcon />
                  <Box fontFamily='Open Sans' maxWidth='300px' fontSize='14px' marginLeft='4px'>
                    <OverflowTooltip text={policyFile.name} />
                  </Box>
                  {isEditMode() ? (
                    <Tooltip
                      title={t('productConfiguration.policy.document.delete')}
                      placement='top-end'
                      disableInteractive
                      arrow
                      componentsProps={{
                        tooltip: {
                          style: { marginBottom: '8px' },
                        },
                      }}
                    >
                      <IconButton onClick={() => setPolicyFile(null)} sx={{ p: '0 5px', ml: 'auto' }}>
                        <RemoveIcon color='action' />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton
                      onClick={() => {
                        openPolicyPreview(policyFile, policyTitle, Locales['en-US'].code!)
                      }}
                      sx={{ p: '0 5px', ml: 'auto' }}
                    >
                      <NewTabIcon color='action' />
                    </IconButton>
                  )}
                </Box>
              ) : (
                <Button
                  sx={{ width: '400px' }}
                  variant='outlined'
                  startIcon={<UploadIcon sx={{ fontSize: '24px!important' }} />}
                  onClick={() => {
                    setShowFileUploadPopup(true)
                    setIsFrenchDocument(false)
                  }}
                  data-testid='upload-button'
                >
                  {t('productConfiguration.policy.document.upload')}
                </Button>
              )}
            </Box>

            <CustomTextField
              sx={{
                marginTop: '16px',
                '& p': {
                  fontWeight: isEditMode() ? 400 : 600,
                  marginBottom: '16px',
                },
              }}
              readonly={!isEditMode()}
              error={isSaved && !policyTitleFrench.trim()}
              limitation={200}
              label={t('productConfiguration.policy.title.labelFrench')!}
              placeholder={t('productConfiguration.policy.title.placeholder')!}
              value={policyTitleFrench}
              highlightOnFocus
              multiline
              onChange={(e) => {
                setPolicyTitleFrench(e.target.value)
              }}
            />

            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '12px' }}>
              <CustomFormLabel value={t('productConfiguration.policy.document.labelFrench')!} />
              {policyFileFrench ? (
                <Box sx={{ width: '400px', display: 'flex', alignItems: 'center' }}>
                  <UploadToCloudIcon />
                  <Box fontFamily='Open Sans' maxWidth='300px' fontSize='14px' marginLeft='4px'>
                    <OverflowTooltip text={policyFileFrench.name} />
                  </Box>
                  {isEditMode() ? (
                    <Tooltip
                      title={t('productConfiguration.policy.document.delete')}
                      placement='top-end'
                      disableInteractive
                      arrow
                      componentsProps={{
                        tooltip: {
                          style: { marginBottom: '8px' },
                        },
                      }}
                    >
                      <IconButton onClick={() => setPolicyFileFrench(null)} sx={{ p: '0 5px', ml: 'auto' }}>
                        <RemoveIcon color='action' />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton
                      onClick={() => {
                        openPolicyPreview(policyFileFrench, policyTitleFrench, Locales['fr-CA'].code!)
                      }}
                      sx={{ p: '0 5px', ml: 'auto' }}
                    >
                      <NewTabIcon color='action' />
                    </IconButton>
                  )}
                </Box>
              ) : (
                <Button
                  sx={{ width: '400px' }}
                  variant='outlined'
                  startIcon={<UploadIcon sx={{ fontSize: '24px!important' }} />}
                  onClick={() => {
                    setShowFileUploadPopup(true)
                    setIsFrenchDocument(true)
                  }}
                  data-testid='upload-button-fr'
                >
                  {t('productConfiguration.policy.document.upload')}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <MockFooterBlock />
      </Box>
      <FileUploadPopup
        acceptedFileTypes={acceptedFileTypes}
        maxFileSizeMB={maxFileSizeMB}
        open={showFileUploadPopup}
        onClose={() => setShowFileUploadPopup(false)}
        onSave={onSaveDocument}
      />
    </Box>
  )
}
