import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import baseApi from 'services/api/baseApi'
import { geoApi } from 'services/api/geoApi'
import { IRole, IPermission, IProductGroup } from '../../constants'
import { ManageAdminsSlice } from './constants'
import { endpoints } from 'constants/endpoints'
import { getUniqueById } from 'utils/getUniqueById'

export const MANAGE_ADMINS_INIT_STATE: ManageAdminsSlice = {
  employeeRoleId: null,
  roleSelected: null,
  roles: [],
  permissions: [],
  products: [],
  isLoading: false,
  errorMessage: '',
}

const getRoles = createAsyncThunk<IRole[][], void, { rejectValue: AxiosError }>(
  'manageAdmins/getRoles',
  async (_, { rejectWithValue }) => {
    try {
      return await geoApi.getAllGeo<IRole[]>('global/employees/roles')
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

const getPermissions = createAsyncThunk<IPermission[], { roleId: number }, { rejectValue: AxiosError }>(
  'manageAdmins/getPermissions',
  async ({ roleId }, { rejectWithValue }) => {
    try {
      const url = endpoints.getPermissions.replace(':roleId', String(roleId))
      return await baseApi.get<IPermission[]>(url)
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

const getProducts = createAsyncThunk<IProductGroup[][], void, { rejectValue: AxiosError }>(
  'manageAdmins/getProducts',
  async (_, { rejectWithValue }) => {
    try {
      return await geoApi.getAllGeo<IProductGroup[]>('product-group')
    } catch (err) {
      return rejectWithValue(err as AxiosError)
    }
  }
)

const _handlePending = (state: ManageAdminsSlice) => {
  state.isLoading = true
}

const manageAdminsSlice = createSlice({
  name: 'manageAdmins',
  initialState: MANAGE_ADMINS_INIT_STATE,
  reducers: {
    setEmployeeRoleId(state: ManageAdminsSlice, action) {
      state.employeeRoleId = action.payload
    },
    setRoleSelected(state: ManageAdminsSlice, action) {
      state.roleSelected = action.payload
    },
    resetAll(state: ManageAdminsSlice) {
      state.products = []
      state.permissions = []
    },
    resetPermissions(state: ManageAdminsSlice) {
      state.permissions = []
    },
    resetProducts(state: ManageAdminsSlice) {
      state.products = []
    },
  },
  extraReducers: (builder) => {
    // getRoles
    builder.addCase(getRoles.pending, _handlePending)
    builder.addCase(getRoles.fulfilled, (state: ManageAdminsSlice, action) => {
      state.roles = getUniqueById(action.payload.flat())
      state.isLoading = false
      state.errorMessage = ''
    })
    builder.addCase(getRoles.rejected, (state: ManageAdminsSlice, action) => {
      state.isLoading = false
      state.roles = []
      state.errorMessage = action.payload?.message
    })
    // getPermissions
    builder.addCase(getPermissions.pending, _handlePending)
    builder.addCase(getPermissions.fulfilled, (state: ManageAdminsSlice, action) => {
      state.permissions = action.payload
      state.isLoading = false
      state.errorMessage = ''
    })
    builder.addCase(getPermissions.rejected, (state: ManageAdminsSlice, action) => {
      state.isLoading = false
      state.permissions = []
      state.errorMessage = action.payload?.message
    })
    //getProducts
    builder.addCase(getProducts.pending, _handlePending)
    builder.addCase(getProducts.fulfilled, (state: ManageAdminsSlice, action) => {
      const productGroups = action.payload.flat().reduce((_productGroups, currentProductGroup) => {
        if (_productGroups[currentProductGroup.id]) {
          _productGroups[currentProductGroup.id].products.push(...currentProductGroup.products)
        } else {
          _productGroups[currentProductGroup.id] = currentProductGroup
        }
        return _productGroups
      }, {} as { [key: string]: IProductGroup })
      state.products = Object.values(productGroups)
      state.isLoading = false
      state.errorMessage = ''
    })
    builder.addCase(getProducts.rejected, (state: ManageAdminsSlice, action) => {
      state.isLoading = false
      state.products = []
      state.errorMessage = action.payload?.message
    })
  },
})

export const { resetPermissions, resetProducts, resetAll, setRoleSelected, setEmployeeRoleId } =
  manageAdminsSlice.actions

export { getRoles, getPermissions, getProducts }

export default manageAdminsSlice.reducer
