import { geoApi } from 'services/api/geoApi'
import { IEmployeeInformation, IProfileConfig } from 'sharedSlices/auth/constants'
import { SUPER_ADMIN } from 'features/manageAdmins/constants'

export const submitProfileSettings = (
  user: IEmployeeInformation,
  profileConfig: IProfileConfig
): Promise<void | void[]> => {
  const jurCodes = user.roles
    .filter((role) => role.id !== SUPER_ADMIN)
    .map((_role) => {
      return geoApi.jurMapping[_role.jurisdictionId].code
    })
  const isSuperAdmin = user.roles.length !== jurCodes.length
  if (isSuperAdmin) {
    return geoApi.postAllGeo('global/employees/current/profile', profileConfig)
  }
  return geoApi.postSpecificGeos('global/employees/current/profile', jurCodes, profileConfig)
}
