import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { RouterLayout } from 'components/layouts'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { InteractionStatus } from '@azure/msal-browser'
import { useAppSelector } from 'app/hooks'

export const RouterGuard = () => {
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()
  const { inProgress } = useMsal()
  const endUser = useAppSelector((state) => state.auth.endUserInformation)

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      navigate('/login')
    } else if (isAuthenticated && endUser && inProgress === InteractionStatus.None) {
      navigate('/')
    }
  }, [isAuthenticated, navigate, inProgress, endUser])

  return (
    <AuthenticatedTemplate>
      <RouterLayout />
    </AuthenticatedTemplate>
  )
}
