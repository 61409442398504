import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { AuthSlice } from 'sharedSlices/auth/constants'
import { IProduct } from 'features/manageAdmins/constants'
import { removeRepeated } from 'features/manageAdmins/utils'

const selectAuth = (store: RootState): AuthSlice => store.auth
const selectProduct = (store: RootState): IProduct | null => store.manageProduct.selectedProduct

export const selectPermissionIds = createSelector(
  selectAuth,
  selectProduct,
  ({ employeeInformation }: AuthSlice, selectedProduct: IProduct | null) => {
    let permissionIds: number[] = []

    if (employeeInformation && selectedProduct) {
      const allPermissions = employeeInformation.roles
        .filter((role) => role.productId === selectedProduct.id)
        .map((role) => role.permissions!)
        .flat()

      permissionIds = removeRepeated(allPermissions).map((p) => Number(p.id))
    }

    return permissionIds
  }
)
