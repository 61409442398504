import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'
import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import { useAppSelector } from 'app/hooks'

interface Props {
  isCreateMode?: boolean
  onCancel?: () => void
  onSave?: () => void
}

export const ProductConfigurationHeader: FC<Props> = ({ isCreateMode, onCancel, onSave }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { productData, isEdit } = useAppSelector((state) => state.productConfiguration)

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '56px',
        padding: '10px 16px',
        borderBottom: '1px solid',
        borderColor: colors.border,
      }}
    >
      <Typography variant='h4' fontWeight='600' fontSize='20px'>
        {t(`productConfiguration.header.${isCreateMode ? 'onboard' : 'default'}`)}
      </Typography>
      {isCreateMode ? (
        <Box>
          <Button variant='text' onClick={onCancel}>
            {t('productConfiguration.buttons.cancelOnboarding')}
          </Button>
          <Button
            sx={{ marginLeft: '16px' }}
            variant='contained'
            disabled={!productData.integrations.appId || isEdit}
            onClick={onSave}
          >
            {t('productConfiguration.buttons.saveProduct')}
          </Button>
        </Box>
      ) : (
        <Button
          variant='contained'
          onClick={() => {
            navigate('/manage/productConfiguration/create')
          }}
        >
          {t('productConfiguration.buttons.addProduct')}
        </Button>
      )}
    </Box>
  )
}
