import { createSlice } from '@reduxjs/toolkit'
import { IProductJurisdiction, IProductGroup } from 'features/managePackage/constants'
import { getProductJurisdictions, getProductGroups, getSubscriptions, getProductRoles } from './actions'
import { IPackageByProductId, IPackageInfo, IProductRole } from '../constants'
import { AxiosError } from 'axios'

export type ManagePackagesSlice = {
  prodJurisSelected?: IProductJurisdiction
  prodGroupSelected?: IProductGroup
  productJurisdictions: IProductJurisdiction[]
  productGroups: IProductGroup[]
  productRoles: IProductRole[]
  subscriptions: IPackageByProductId<IPackageInfo>
  selectedPackage?: IPackageInfo
  isLoading: boolean
  error?: AxiosError['response']
}

export const MANAGE_PACKAGES_INIT_STATE: ManagePackagesSlice = {
  productJurisdictions: [],
  productGroups: [],
  productRoles: [],
  subscriptions: { hasValuations: false, result: [], total: 0 },
  isLoading: false,
}

const _handlePending = (state: ManagePackagesSlice) => {
  state.isLoading = true
}

const managePackagesSlice = createSlice({
  name: 'managePackage',
  initialState: MANAGE_PACKAGES_INIT_STATE,
  reducers: {
    resetAll(state) {
      state.subscriptions = {
        result: [],
        total: 0,
        hasValuations: false,
      }
      state.productJurisdictions = []
      state.prodGroupSelected = undefined
      state.prodJurisSelected = undefined
    },
    setProdJuriSelected(state, action) {
      state.prodJurisSelected = action.payload
    },
    setProdGroupSelected(state, action) {
      state.prodGroupSelected = action.payload
    },
    setSelectedPackage(state, action) {
      state.selectedPackage = action.payload
    },
  },
  extraReducers: (builder) => {
    // getProductGroups
    builder.addCase(getProductGroups.pending, _handlePending)
    builder.addCase(getProductGroups.fulfilled, (state, action) => {
      const productGroups = action.payload.flat().reduce((_productGroups, currentProductGroup) => {
        if (_productGroups[currentProductGroup.id]) {
          _productGroups[currentProductGroup.id].products.push(...currentProductGroup.products)
        } else {
          _productGroups[currentProductGroup.id] = currentProductGroup
        }
        return _productGroups
      }, {} as { [key: string]: IProductGroup })
      state.productGroups = Object.values(productGroups)
      state.isLoading = false
      state.error = undefined
    })
    builder.addCase(getProductGroups.rejected, (state, action) => {
      state.isLoading = false
      state.productGroups = []
      state.error = action.payload?.response
    })
    // getSubscriptions
    builder.addCase(getSubscriptions.pending, _handlePending)
    builder.addCase(getSubscriptions.fulfilled, (state, action) => {
      state.subscriptions = action.payload
      state.isLoading = false
      state.error = undefined
    })
    builder.addCase(getSubscriptions.rejected, (state, action) => {
      state.isLoading = false
      state.subscriptions = MANAGE_PACKAGES_INIT_STATE.subscriptions
      state.error = action.payload?.response
    })
    builder.addCase(getProductJurisdictions.pending, _handlePending)
    builder.addCase(getProductJurisdictions.fulfilled, (state, action) => {
      state.productJurisdictions = action.payload
      state.isLoading = false
      state.error = undefined
    })
    builder.addCase(getProductJurisdictions.rejected, (state, action) => {
      state.isLoading = false
      state.productJurisdictions = []
      state.error = action.payload?.response
    })
    // getProductRoles
    builder.addCase(getProductRoles.pending, _handlePending)
    builder.addCase(getProductRoles.fulfilled, (state, action) => {
      state.productRoles = action.payload
      state.isLoading = false
      state.error = undefined
    })
    builder.addCase(getProductRoles.rejected, (state, action) => {
      state.isLoading = false
      state.productRoles = []
      state.error = action.payload?.response
    })
  },
})

export const { resetAll, setProdGroupSelected, setProdJuriSelected, setSelectedPackage } = managePackagesSlice.actions
export { getProductGroups, getProductJurisdictions, getSubscriptions, getProductRoles }
export default managePackagesSlice.reducer
