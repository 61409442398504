import { FC, memo } from 'react'
import { Box, Typography, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomForm from 'components/CustomForm'

import { IRole } from '../../constants/interfaces'

interface Props {
  roles: IRole[]
  onChange: (roleId: number) => void
  setValue: (fieldName: string, param: string | number) => void
  disabled?: boolean
}

const RoleField: FC<Props> = ({ setValue, roles, onChange, disabled = false }) => {
  const { t } = useTranslation()

  return (
    <>
      {roles.length > 0 && (
        <>
          <CustomForm.Label value='manageAdmins.roles' />
          <CustomForm.Autocomplete<IRole>
            id='role-auto'
            options={roles}
            disabled={disabled}
            value={roles.length === 1 ? roles[0] : undefined}
            getOptionLabel={(role) => t(role.name)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, role) => {
              if (role && !Array.isArray(role)) {
                onChange(role.id)
                setValue('roleId', role.id)
              }
            }}
            renderOption={(props, role) => (
              <Box component='span' {...props}>
                <Typography variant='body2'>{t(role.name)}</Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ p: 0, m: 0 }}
                fullWidth
                name='roleId'
                placeholder={t('manageAdmins.rolesPlaceHolder')!}
              />
            )}
          />
        </>
      )}
    </>
  )
}

export default memo(RoleField)
