import colors from 'theme/colors'

export interface ICustomPaginationProps {
  count: number
  rowsPerPage: number
  currentPage: number
  changeCurrentPage: ({ page, size }: { page?: number; size?: number }) => void
}

export const enum Key {
  Enter = 'Enter',
}

export const enum CurrentPagePosition {
  Start,
  Middle,
  End,
}

export const enum PaginationDirection {
  PreviousPage,
  FistPages,
  NextPages,
  LastPages,
}

export const numericValues = {
  initialValue: 0,
  oneItem: 1,
  itemsToShow: 3,
  twoItems: 2,
  initialPage: 1,
}

//sx styles
export const customPaginationStyles = {
  containerStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '48px',
    paddingRight: '15px',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: 'grey.100',

    fontFamily: 'Open Sans',

    button: {
      minHeight: '28px',
      minWidth: '28px',
      padding: '0',
      fontWeight: '400',

      '&.MuiButton-text': {
        padding: '0 2px',
        textTransform: 'none',
      },
    },
  },
  flexStyles: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },

  navigationButtonStyles: {
    'svg > path': { fill: '#007CB0' },
    '&.Mui-disabled svg > path': { fill: '#A3A3A3' },
    '&.Mui-disabled': { backgroundColor: '#F0F0F0' },
  },

  rightContainerStyles: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',

    fontSize: '14px',
    lineHeight: '24px',
    color: colors.mineShaft,
  },

  pageInputStyles: {
    width: '48px',

    '&&& ::-webkit-inner-spin-button': {
      MozAppearance: 'none',
      WebkitAppearance: 'none',
    },

    '&&& fieldset': {
      borderColor: 'grey.100',
      borderWidth: '1px',
      borderRadius: '2px',

      ':hover': {
        borderColor: 'inherit',
      },
    },
  },
}
