import { FC } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { handleAddAdminsModal } from 'sharedSlices/ui'
import { useAppDispatch, useAppSelector } from 'app/hooks'

import globalSearch from 'assets/images/dsgGlobalSearch.svg'
import { filteredRoleList } from 'features/manageAdmins/slices/manageAdmins/selectors'

export const NoAssignment: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const roleList = useAppSelector(filteredRoleList)

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: 'menuItemHover.main',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img src={globalSearch} alt='' />
      <Typography variant='body1' fontWeight={600} mt='30px'>
        {t('noAssignments.title')}
      </Typography>
      {roleList.length > 0 && (
        <>
          <Typography variant='body2' color={'grey.800'} mt='6px' mb='16px'>
            {t('noAssignments.description')}
          </Typography>
          <Button variant='contained' onClick={() => dispatch(handleAddAdminsModal(true))}>
            {t('button.assignAdmin')}
          </Button>
        </>
      )}
    </Box>
  )
}
