import { FC, memo } from 'react'
import { Box, IconButton, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { CloseIcon } from 'components/icons/Close.icon'

interface HeaderFormProps {
  style?: SxProps
  title: string
  icon?: React.ReactElement
  onClose?: () => void
}

const HeaderForm: FC<HeaderFormProps> = ({ style, title, onClose, icon }) => {
  const { t } = useTranslation()

  const modalHeaderStyle: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #D0D0CE',
  }

  return (
    <Box sx={style || modalHeaderStyle}>
      {icon}
      <Typography variant='body1' fontWeight={600}>
        {t(title)}
      </Typography>
      <IconButton sx={{ p: 0, ml: 'auto' }} onClick={onClose} data-testid={'close-btn'}>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

export default memo(HeaderForm)
