import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { TextField, Box, Popover, IconButton } from '@mui/material'
import { DatePickerIcon } from 'components/icons/DatePicker.icon'
import { CustomFormLabel } from 'components/CustomFormLabel'
import { maskOption, parseDateFormat, parseFormat, pureMask } from './constants'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'app/hooks'
import { getDateFormat } from 'sharedSlices/auth'
import { DateRangePicker } from 'components/DateRangePicker'
import { SelectedDate } from 'components/DateRangePicker/constants'
import { format as formatDate } from 'date-fns'
import IMask, { InputMask } from 'imask'
import MaskedPattern from 'imask/esm/masked/pattern'
import colors from 'theme/colors'

interface Props {
  required?: boolean
  error?: boolean
  label?: string
  value?: [string, string] | []
  onChange?: (value: [string, string] | []) => void
  dateDisabledThreshold?: Date
}

const DateRangePickerWithInput: React.FC<Props> = ({
  error,
  required,
  label,
  onChange,
  value = ['', ''],
  dateDisabledThreshold,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [dates, setDates] = useState<SelectedDate>(() => {
    const currentDate = new Date()
    const previousDate = new Date()
    previousDate.setMonth(previousDate.getMonth() - 1)

    return {
      endDate: previousDate,
      startDate: currentDate,
    }
  })

  const [inputValue, setInputValue] = useState({
    startDate: '',
    endDate: '',
  })

  const [isInvalidDate, setInvalidDate] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const maskRef = useRef<InputMask<MaskedPattern<string>>>()
  const dateFormat = useAppSelector(getDateFormat)
  const displayError = useRef('')
  const { t } = useTranslation()

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const handleDateChange = (_dates: SelectedDate) => {
    setDates(_dates)
    if (maskRef.current && dateFormat) {
      const format = parseFormat(dateFormat)
      const formattedDate = `${formatDate(_dates.startDate, format)} - ${formatDate(
        _dates.endDate,
        format
      )}`
      maskRef.current.value = formattedDate
      maskRef.current.updateValue()
      onChange && onChange([_dates.startDate.toDateString(), _dates.endDate.toDateString()])
    }
    handleClosePopover()
  }

  useEffect(() => {
    const inputNode = inputRef.current
    if (inputNode && !maskRef.current && inputRef.current) {
      const maskOptions = IMask.createMask({
        mask: 'from` - `to',
        lazy: true,
        blocks: {
          from: maskOption(),
          to: maskOption(),
        },
      })
      const dateRangeMask = IMask(inputRef.current, maskOptions)
      maskRef.current = dateRangeMask
      dateRangeMask.on('complete', () => {
        const _dates = dateRangeMask.value.split(' - ')
        setInputValue({
          startDate: _dates[0],
          endDate: _dates[1],
        })
      })
    }
  }, [])

  useEffect(() => {
    if (inputValue.endDate && inputValue.startDate) {
      const { startDate, endDate } = parseDateFormat(
        dateFormat,
        inputValue.startDate,
        inputValue.endDate
      )

      if (new Date(startDate).getTime() <= new Date(endDate).getTime()) {
        onChange && onChange([startDate, endDate])
        setDates({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        })
      } else {
        setInvalidDate(true)
        if (new Date(endDate).getTime() < new Date(startDate).getTime()) {
          displayError.current = 'reports.errors.secondDateRangeLower'
        }
      }
    }
  }, [inputValue.endDate, inputValue.startDate])

  useEffect(() => {
    if (value && value.length && maskRef.current && dateFormat) {
      const startDate = new Date(value[0])
      const endDate = new Date(value[1])
      value && setDates({ startDate, endDate })
      const format = parseFormat(dateFormat)
      const formattedDate = `${formatDate(startDate, format)} - ${formatDate(endDate, format)}`
      maskRef.current.value = formattedDate
      maskRef.current.updateControl()
    } else {
      const currentDate = new Date()
      const previousDate = new Date()
      previousDate.setMonth(previousDate.getMonth() - 1)
      setDates({
        startDate: currentDate,
        endDate: previousDate,
      })
      if (maskRef.current) {
        maskRef.current.value = pureMask[dateFormat ?? 'DD Mon YYYY']
        maskRef.current.updateControl()
      }
    }
  }, [value[0], value[1]])

  useEffect(() => {
    if (dateFormat) {
      if (maskRef.current) {
        maskRef.current.updateOptions({
          mask: 'from` - `to',
          lazy: true,
          blocks: {
            from: maskOption(dateFormat),
            to: maskOption(dateFormat),
          },
        })

        if (value && value.length) {
          const startDate = new Date(value[0])
          const endDate = new Date(value[1])
          const format = parseFormat(dateFormat)
          maskRef.current.value = `${formatDate(startDate, format)} - ${formatDate(
            endDate,
            format
          )}`
          maskRef.current.updateControl()
        }
      }
    }
  }, [dateFormat])

  const updateMask = useCallback(
    (options: Partial<MaskedPattern<string>>) => {
      return {
        mask: 'from` - `to',
        lazy: false,
        blocks: {
          from: maskOption(dateFormat),
          to: maskOption(dateFormat),
        },
        ...options,
      }
    },
    [dateFormat]
  )

  const onInputChange = useCallback(
    (event: FormEvent<HTMLDivElement>) => {
      if (isInvalidDate) {
        setInvalidDate(false)
      }

      if ((event.target as HTMLInputElement).value === pureMask[dateFormat]) {
        const currentDate = new Date()
        const previousDate = new Date()
        previousDate.setMonth(previousDate.getMonth() - 1)
        setDates({
          startDate: currentDate,
          endDate: previousDate,
        })
        onChange && onChange([])
      }
    },
    [dateFormat, isInvalidDate, onChange]
  )

  const onFocusInput = useCallback(() => {
    if (maskRef.current) {
      maskRef.current.updateOptions(
        updateMask({
          lazy: false,
        })
      )
    }
  }, [updateMask])

  const onBlurInput = useCallback(() => {
    if (maskRef.current) {
      maskRef.current.updateOptions(
        updateMask({
          lazy: true,
        })
      )
    }
  }, [updateMask])

  return (
    <Box
      sx={{
        input: {
          width: '100%',
          padding: '8px',
        },

        fieldset: {
          borderColor:
            (isInvalidDate ? `${colors['red']}` : `${colors['grey'][100]}`) + ' !important',
          borderWidth: '1px !important',
          borderRadius: '2px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        {label && (
          <CustomFormLabel value={label} required={required} error={isInvalidDate || error} />
        )}
        <TextField
          sx={{
            width: '100%',
          }}
          placeholder={dateFormat}
          onInput={onInputChange}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          inputRef={inputRef}
        />
        {isInvalidDate && (
          <Box
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              marginTop: '5px',
              color: 'error.main',
            }}
          >
            {displayError.current && t(displayError.current)}
          </Box>
        )}
        <IconButton
          sx={{
            position: 'absolute',
            padding: '6px',
            top: '25px',
            right: '0px',
          }}
          onClick={handleOpenPopover}
        >
          <DatePickerIcon
            sx={{
              path: {
                fill: isInvalidDate ? colors.red : colors.blue.main,
              },
            }}
          />
        </IconButton>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DateRangePicker
          value={dates}
          onChange={handleDateChange}
          dateDisabledThreshold={dateDisabledThreshold}
        />
      </Popover>
    </Box>
  )
}

export default DateRangePickerWithInput
