import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { AuthError, RedirectRequest } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { selectCountry, setBusinessUnits, setCountry, setEmail, setEmailError, setIsDeloitteClient } from '../../slice'

import {
  auditClientOptions,
  DeloitteClientType,
  ICountry,
  initialBusinessUnitState,
  ProductFeature,
  ProductGroupType,
} from 'features/subscriptionRequest/constants/subscription'
import {
  EmailValidationBehalfClientMsg,
  EmailValidationError,
  EmailValidationErrorMessage,
  TEmailValidationMsgKey,
} from 'features/subscriptionRequest/constants/email'
import { CustomTextField } from 'components/CustomTextField'
import { CustomRadioGroup } from 'components/CustomRadioGroup'
import { StaticFormField } from 'components/staticFormField'
import { CustomAlert } from 'components/CustomAlert'
import { BusinessUnitManager } from 'features/subscriptionRequest/components/subscriptionInit/components/BusinessUnitManager'
import { CountrySelector } from 'features/subscriptionRequest/components/subscriptionInit/components/CountrySelector'
import CustomForm from 'components/CustomForm'
import { loginError } from 'sharedSlices/auth'
import { handleFooter, handleBusinessUnitModal } from 'sharedSlices/ui'

export const SubscriptionInit: React.FC = () => {
  const [search] = useSearchParams()
  const { t } = useTranslation()
  const { instance } = useMsal()
  const dispatch = useAppDispatch()
  const {
    productGroup,
    countries,
    userInfo,
    subscription: { country: selectedCountry, email, emailError, isDeloitteClient, businessUnits },
  } = useAppSelector((state) => state.subscriptionRequest)
  const { endUserInformation } = useAppSelector((state) => state.auth)
  const { isBusinessUnitModalOpen } = useAppSelector((state) => state.ui)
  const [minHeight, setMinHeight] = useState(false)
  const [selectedBusinessUnitIndex, setSelectedBusinessUnitIndex] = useState<number | null>(null)
  const container = useRef<HTMLDivElement>(null)
  const [defaultCountry, setDefaultCountry] = useState<ICountry>()

  const subscribeToProduct = Boolean(search.get('subscribeToProduct'))
  const productId = search.get('productId')

  const onSelectCountry = useCallback(
    (country: ICountry | null) => {
      dispatch(selectCountry(country))
    },
    [dispatch]
  )

  const handleLogin = useCallback(() => {
    const loginRequest: RedirectRequest = {
      scopes: ['User.Read'],
      redirectUri: `${window.location.origin}/subscribe/${productGroup.shortName}`,
    }

    instance.loginRedirect(loginRequest).catch((e: AuthError) => {
      dispatch(loginError(e.errorMessage))
    })
  }, [productGroup.id, instance, dispatch])

  const hasAuditClientFeature = useMemo(() => {
    return (
      productGroup.products &&
      productGroup.products.flatMap((product) => product.features).includes(ProductFeature.AuditClient)
    )
  }, [productGroup])

  const hasDeloitteClientFeature = useMemo(() => {
    return (
      productGroup.products &&
      productGroup.products.flatMap((product) => product.features).includes(ProductFeature.DeloitteClient)
    )
  }, [productGroup])

  const deloitteClientOptions = useMemo(() => {
    return auditClientOptions.filter((i) => i.value !== DeloitteClientType.DontKnow)
  }, [])

  const scrollToBottom = (behavior: ScrollBehavior = 'smooth') => {
    setTimeout(() => {
      const stepContainer = document.getElementsByClassName('step-container')[0]
      stepContainer.scrollTo({ left: 0, top: stepContainer.scrollHeight, behavior })
    }, 16)
  }

  const showMessage = () => {
    if (subscribeToProduct) {
      if (
        isDeloitteClient !== DeloitteClientType.Yes &&
        (emailError === EmailValidationError.UnavailableCountry ||
          emailError === EmailValidationError.Exists ||
          emailError === EmailValidationError.UnderReview)
      ) {
        return (
          <CustomAlert
            sx={{ position: 'sticky', top: 0, zIndex: 1 }}
            text={t(EmailValidationBehalfClientMsg[emailError as TEmailValidationMsgKey])}
          />
        )
      }
      if (
        (isDeloitteClient !== DeloitteClientType.Yes &&
          emailError === EmailValidationError.SubscriptionExistsForAnotherProduct) ||
        emailError === EmailValidationError.SubscriptionReadyUpgrade
      ) {
        if (userInfo) {
          const countryFound = countries.find((country) => country.id === userInfo.countryId)
          if (!countryFound) dispatch(setEmailError(EmailValidationError.UnavailableCountry))

          return (
            <CustomAlert
              sx={{ position: 'sticky', top: 0, zIndex: 1 }}
              text={t(EmailValidationBehalfClientMsg[emailError as TEmailValidationMsgKey])}
            />
          )
        }
      }
      if (isDeloitteClient !== DeloitteClientType.Yes && emailError === EmailValidationError.Invalid) {
        return (
          <CustomAlert
            sx={{ position: 'sticky', top: 0, zIndex: 1 }}
            text={t(EmailValidationErrorMessage[emailError])!}
          />
        )
      }
    } else {
      return isDeloitteClient === DeloitteClientType.Yes && !hasDeloitteClientFeature ? (
        <CustomAlert
          sx={{ position: 'sticky', top: 0, zIndex: 1 }}
          text={t('subscriptionInit.isDeloitteAuditClient.warning')!}
        />
      ) : (
        emailError !== EmailValidationError.SubscriptionReadyUpgrade &&
          !!emailError &&
          (emailError === EmailValidationError.Exists ? (
            <CustomAlert sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
              <Trans i18nKey={EmailValidationErrorMessage[emailError]}>
                <Link to={'#'} onClick={handleLogin}>
                  login
                </Link>
              </Trans>
            </CustomAlert>
          ) : (
            <CustomAlert
              sx={{ position: 'sticky', top: 0, zIndex: 1 }}
              text={t(EmailValidationErrorMessage[emailError])!}
            />
          ))
      )
    }
  }

  useEffect(() => {
    if (isDeloitteClient === DeloitteClientType.Yes) {
      if (!businessUnits.length) {
        dispatch(handleBusinessUnitModal(true))
        dispatch(setBusinessUnits([initialBusinessUnitState]))
      } else {
        dispatch(setBusinessUnits(businessUnits))
      }
    } else {
      dispatch(handleBusinessUnitModal(false))
    }

    return () => {
      isDeloitteClient === DeloitteClientType.No && dispatch(setBusinessUnits([]))
    }
  }, [isDeloitteClient, businessUnits.length])

  useEffect(() => {
    if (subscribeToProduct && countries.length) {
      setDefaultCountry(countries.filter((country) => country.productId === productId)[0])
      dispatch(setCountry(countries.filter((country) => country.productId === productId)[0]))
    }
  }, [countries, dispatch, productId, subscribeToProduct])

  useEffect(() => {
    if (!container.current) return
    const stepContainer = document.getElementsByClassName('step-container')[0]

    setMinHeight(
      Array.from(container.current.children).reduce((prev, cur) => {
        return prev + cur.clientHeight
      }, 0) <= stepContainer?.clientHeight
    )
    dispatch(
      handleFooter(Math.ceil(stepContainer?.scrollTop + stepContainer?.clientHeight) >= stepContainer?.scrollHeight)
    )
  }, [businessUnits])

  useEffect(() => {
    if (userInfo) {
      const countryFound = countries.find((country) => country.id === userInfo.countryId)
      if (!countryFound) {
        const userCountry = {
          id: userInfo.countryId,
          name: userInfo.countryName,
          abbreviation: userInfo.countryAbbreviation,
          productId: productId!,
        }
        setDefaultCountry(userCountry)
      } else {
        setDefaultCountry(countries.find((country) => country.id === userInfo.countryId))
        dispatch(setCountry(countries.find((country) => country.id === userInfo.countryId)!))
      }
    }
  }, [userInfo, countries, dispatch])

  const handleCloseUnitPopup = () => {
    selectedBusinessUnitIndex !== null
      ? dispatch(setBusinessUnits(businessUnits))
      : dispatch(setBusinessUnits(businessUnits.slice(0, -1)))
    dispatch(handleBusinessUnitModal(false))
    setSelectedBusinessUnitIndex(null)

    if (businessUnits.length <= 1 && selectedBusinessUnitIndex === null) {
      dispatch(setIsDeloitteClient(DeloitteClientType.No))
    }
  }

  return countries.length ? (
    <Box
      ref={container}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...(minHeight && { minHeight: '100%' }),
      }}
    >
      {showMessage()}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Box
          sx={{
            width: '396px',
            padding: '8px 20px 16px 20px',
          }}
          display='flex'
          flexDirection='column'
        >
          <CustomForm>
            {productGroup.type === ProductGroupType.Single &&
              (subscribeToProduct ? (
                defaultCountry ? (
                  <StaticFormField
                    label='manageAdmins.jurisdiction'
                    value={t(`country.${defaultCountry.abbreviation.toLowerCase()}.name`)}
                    imgPath={`${process.env.PUBLIC_URL}/images/flags/${defaultCountry.abbreviation}.svg`}
                  />
                ) : null
              ) : (
                <>
                  <CustomForm.Label
                    styles={{
                      marginBottom: '8px',
                      fontFamily: 'Open Sans SemiBold',
                      fontSize: '12px',
                      color: 'grey.800',
                    }}
                    value={
                      endUserInformation && !emailError
                        ? 'subscriptionInit.country.readonlyLabel'
                        : 'subscriptionInit.country.label'
                    }
                  />
                  {endUserInformation && emailError !== EmailValidationError.UnavailableCountry ? (
                    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                      <Box
                        component='img'
                        sx={{ width: '24px', height: '24px' }}
                        src={`${process.env.PUBLIC_URL}/images/flags/${endUserInformation?.countryAbbreviation}.svg`}
                      />
                      <Typography sx={{ py: '8px' }} variant='subtitle2' fontWeight={600}>
                        {t(`country.${endUserInformation?.countryAbbreviation?.toLowerCase()}.name`)}
                      </Typography>
                    </Box>
                  ) : (
                    <CountrySelector
                      countries={countries}
                      disabled={
                        !!endUserInformation && (!emailError || emailError === EmailValidationError.UnavailableCountry)
                      }
                      selectedCountry={selectedCountry}
                      onSelectCountry={onSelectCountry}
                    />
                  )}
                </>
              ))}
          </CustomForm>
          <CustomTextField
            sx={{
              margin: '15px 0',
              '& p': {
                fontWeight: endUserInformation ? 600 : 400,
              },
            }}
            value={email || ''}
            error={
              subscribeToProduct
                ? ![
                    EmailValidationError.SubscriptionNotCreated,
                    EmailValidationError.UnavailableCountry,
                    EmailValidationError.SubscriptionExistsForAnotherProduct,
                    EmailValidationError.SubscriptionReadyUpgrade,
                  ].includes(emailError)
                : !endUserInformation && !!emailError
            }
            readonly={
              subscribeToProduct
                ? [
                    EmailValidationError.UnavailableCountry,
                    EmailValidationError.SubscriptionExistsForAnotherProduct,
                    EmailValidationError.SubscriptionReadyUpgrade,
                  ].includes(emailError)
                : !!endUserInformation
            }
            label={t('subscriptionInit.email.label')!}
            placeholder={t('subscriptionInit.email.placeholder')!}
            onChange={(e) => {
              dispatch(setEmail(e.target.value))
              subscribeToProduct && defaultCountry && dispatch(setCountry(defaultCountry))
            }}
          />
          {hasAuditClientFeature && (
            <CustomRadioGroup
              value={isDeloitteClient || ''}
              options={auditClientOptions}
              label={t('subscriptionInit.isDeloitteAuditClient.label')!}
              row
              onChange={(e) => dispatch(setIsDeloitteClient(e.target.value as DeloitteClientType))}
            />
          )}
          {hasDeloitteClientFeature && (
            <>
              <CustomRadioGroup
                value={isDeloitteClient || ''}
                options={deloitteClientOptions}
                label={t('subscriptionInit.isDeloitteClient.label')!}
                row
                onChange={(e) => dispatch(setIsDeloitteClient(e.target.value as DeloitteClientType))}
              />

              {isDeloitteClient === DeloitteClientType.Yes && (
                <>
                  <BusinessUnitManager
                    businessUnits={businessUnits}
                    isOpen={isBusinessUnitModalOpen}
                    onAdd={() => {
                      dispatch(handleBusinessUnitModal(true))
                      dispatch(setBusinessUnits([...businessUnits, initialBusinessUnitState]))
                      scrollToBottom()
                    }}
                    onEdit={(index) => {
                      setSelectedBusinessUnitIndex(index)
                      dispatch(handleBusinessUnitModal(true))
                    }}
                    onDelete={(index) => dispatch(setBusinessUnits(businessUnits.filter((_, i) => i !== index)))}
                    onClose={() => handleCloseUnitPopup()}
                    selectedIndex={selectedBusinessUnitIndex}
                    onResetIndex={() => setSelectedBusinessUnitIndex(null)}
                  />
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  )
}
