import { DateFormat } from 'constants/profileSettings'
import IMask, { Masked } from 'imask'
import MaskedDate from 'imask/esm/masked/date'

export const pureMask = {
  'DD Mon YYYY': '__ ___ ____ - __ ___ ____',
  'YYYY/MM/DD': '____/__/__ - ____/__/__',
  'MM/DD/YYYY': '__/__/____ - __/__/____',
  'DD/MM/YYYY': '__/__/____ - __/__/____',
}
const defaultMask = 'DD `MMM `YYYY'
export const defaultFormat = 'DD MMM YYYY'

export const parseFormat = (dateFormat: string) => {
  switch (dateFormat.toLowerCase()) {
    case 'dd mon yyyy':
      return 'dd MMM yyyy'
    case 'dd/mm/yyyy':
      return 'dd/MM/yyyy'
    case 'mm/dd/yyyy':
      return 'MM/dd/yyyy'
    case 'yyyy/mm/dd':
      return 'yyyy/MM/dd'
    default:
      return 'dd MMM yyyy'
  }
}

export const maskDateFormat = (dateFormat: string) => {
  switch (dateFormat) {
    case 'DD Mon YYYY': {
      return 'DD `MMM `YYYY'
    }
    case 'YYYY/MM/DD': {
      return 'YYYY/`MM/`DD'
    }
    case 'MM/DD/YYYY': {
      return 'MM/`DD/`YYYY'
    }
    case 'DD/MM/YYYY': {
      return 'DD/`MM/`YYYY'
    }
    default: {
      return defaultMask
    }
  }
}

export const parseDateFormat = (dateFormat: string, startDate: string, endDate: string) => {
  if (dateFormat === DateFormat.DDMMYYYY) {
    const _startDate = startDate.split('/')
    startDate = `${_startDate[2]}/${_startDate[1]}/${_startDate[0]}`
    const _startEnd = endDate.split('/')
    endDate = `${_startEnd[2]}/${_startEnd[1]}/${_startEnd[0]}`
  }
  return {
    startDate,
    endDate,
  }
}

export const maskOption = (dateFormat: string = defaultMask) => {
  return {
    mask: maskDateFormat(dateFormat),
    prepareChar: (str: string, masked: Masked<MaskedDate>) => {
      if (/J|F|M|A|S|O|N|D/gi.test(masked.value)) {
        return str
      }
      return /j|f|m|a|s|o|n|d/.test(str) ? str.toUpperCase() : str
    },
    blocks: {
      DD: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
      },
      YYYY: {
        mask: IMask.MaskedRange,
        from: 1970,
        to: 2099,
      },
      MMM: {
        mask: IMask.MaskedEnum,
        enum: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        maxLength: 3,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
      },
    },
  }
}
