import { FC } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EditProductIcon } from 'components/icons/EditProduct.icon'
import colors from 'theme/colors'
import { useAppSelector } from 'app/hooks'
import { ProductConfigurationSection } from 'features/productConfiguration/constants'
import { PlusIcon } from 'components/icons/Plus.icon'

interface Props {
  isEditMode: boolean
  isSaveDisabled: boolean
  onSave: () => void
  onEdit: () => void
  onAddPackage?: () => void
}

export const ProductSectionHeader: FC<Props> = ({
  isEditMode,
  isSaveDisabled,
  onAddPackage,
  onSave,
  onEdit,
}) => {
  const { t } = useTranslation()
  const { sectionsState, currentSection } = useAppSelector((state) => state.productConfiguration)

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '56px',
        padding: '10px 16px',
        borderBottom: '1px solid',
        borderColor: colors.border,
      }}
    >
      <Typography variant='h4' fontWeight='600'>
        {t(sectionsState[currentSection - 1].name)}
      </Typography>
      {isEditMode ? (
        <Box
          sx={{
            display: 'flex',
          }}
        >
          {currentSection === ProductConfigurationSection.Packages && (
            <Button
              variant='outlined'
              sx={{
                display: 'flex',
                marginRight: '16px',
              }}
              onClick={onAddPackage}
            >
              <PlusIcon />
              {t('productConfiguration.package.button.add')}
            </Button>
          )}
          <Button variant='contained' disabled={isSaveDisabled} onClick={onSave}>
            {t('productConfiguration.buttons.save')}
          </Button>
        </Box>
      ) : (
        <Button variant='text' onClick={onEdit} startIcon={<EditProductIcon />}>
          {t('productConfiguration.buttons.edit')}
        </Button>
      )}
    </Box>
  )
}
