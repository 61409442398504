import React from 'react'

import { Box, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import globalSearch from 'assets/images/dsgGlobalSearch.svg'

export const NoSubscriptions: React.FC = () => {
  const [searchParams] = useSearchParams()
  const productGroupName = searchParams.get('name')
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: 'menuItemHover.main',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        borderTop: '0px solid',
        borderTopColor: 'grey.100',
      }}
    >
      <img src={globalSearch} alt='' />
      <Typography variant='body1' fontWeight={600} mt='30px'>
        {t('manageProduct.subscriptions.noResult')}
      </Typography>
      <Typography variant='body2' color={'grey.800'} mt='6px' mb='16px'>
        <Trans
          i18nKey='manageProduct.subscriptions.select'
          values={{
            notSelected: t(
              `manageProduct.${!productGroupName ? 'productGroup' : 'product'}.label`
            ),
          }}
        />
      </Typography>
    </Box>
  )
}
