import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import isEqual from 'lodash.isequal'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { resetData, setAllAccountDetails, setIsPrivacyAccepted } from 'features/subscriptionRequest/slice'
import { IAccountDetails, ICountry, IState } from 'features/subscriptionRequest/constants/subscription'
import { getSubscriptionDetails } from 'features/manageProduct/services/subscription/subscription'
import { selectIsValidAccountDetails } from 'features/subscriptionRequest/slice/selectors'
import { fetchStates } from 'features/subscriptionRequest/slice/services'
import { UpperMenu } from 'components/layouts/routerLayout/upperMenu'
import { Footer } from 'components/Footer'
import { updateAccountDetails } from './services'
import { AccountDetails } from '../accountDetails'
import { useContainerScroll } from 'utils/scrollableFooter'
import colors from 'theme/colors'
import { IEditAccountDetailsPayload } from 'features/subscriptionRequest/components/editAccountDetails/constants'
import { requestSubscriptionSteps } from 'features/subscriptionRequest/constants/stepper'
import { getCountries } from 'features/subscriptionRequest/services/subscription'
import { MockFooterBlock } from 'components/Footer/mockFooterBlock'
import { showMessage } from 'utils/errorMessageHandler'

export const EditAccountDetails = () => {
  const { accountDetails, email } = useAppSelector((state) => state.subscriptionRequest.subscription)
  const isValidAccountDetails = useAppSelector(selectIsValidAccountDetails)
  const [search] = useSearchParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const groupId = useRef<string>('')
  const packageGroupName = useRef<string>('')
  const isShowInfoMessage = useRef<boolean>(false)
  const [jurisdiction, setJurisdiction] = useState<ICountry | null>(null)
  const [savedAccountDetailsData, setSavedAccountDetailsData] = useState<IAccountDetails | null>(null)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const productId = search.get('productId')
  const jurCode = search.get('jurCode')
  const selectedSubscriptionId = params['subscriptionId']
  const containerRef = useRef<HTMLDivElement | null>(null)

  useContainerScroll(containerRef, dispatch)

  const onSave = () => {
    const { state, ...details } = accountDetails
    const payload: IEditAccountDetailsPayload = {
      ...details,
      stateId: state!.id,
      countryId: jurisdiction!.id,
      groupId: groupId.current,
      email: email!,
    }
    updateAccountDetails(payload, jurCode!)
      .then(() => {
        navigate(-1)
        showMessage.success('mySnackbar', 'manageProduct.subscriptionMessage.success')
      })
      .catch(() => showMessage.error('mySnackbar', 'manageProduct.subscriptionMessage.error'))
  }

  useEffect(() => {
    if (selectedSubscriptionId && productId && jurCode) {
      Promise.all([
        getSubscriptionDetails(productId, selectedSubscriptionId, jurCode),
        getCountries(productId, jurCode),
      ]).then(([subscription, country]) => {
        packageGroupName.current = subscription.packages[0].packageGroupName

        groupId.current = subscription.groupId
        isShowInfoMessage.current = subscription.isMultiSubscriptionUser

        const filteredSelectedCountry: ICountry | undefined = country.find(
          (_country) => _country.name === subscription.countryName
        )

        if (filteredSelectedCountry) {
          const selectedCountryWithProductId: ICountry = { ...filteredSelectedCountry, productId }
          setJurisdiction(selectedCountryWithProductId)

          dispatch(fetchStates(filteredSelectedCountry.id)).then((state) => {
            let foundState = null
            if (state.payload && Array.isArray(state.payload)) {
              foundState = (state.payload.find((_state) => _state.name === subscription.stateName) as IState) ?? null
            }
            const _accountDetails = {
              address: subscription.address1,
              city: subscription.city,
              companyFullName: subscription.companyName,
              firstName: subscription.firstName,
              lastName: subscription.lastName,
              zipCode: subscription.zipCode,
              state: foundState,
              department: subscription.department,
              role: subscription.roleName,
              globalParentFullName: subscription.parentEntity,
            }

            setSavedAccountDetailsData(_accountDetails)

            dispatch(
              setAllAccountDetails({
                country: selectedCountryWithProductId,
                email: subscription.email,
                ..._accountDetails,
              })
            )
            dispatch(setIsPrivacyAccepted(true))
          })
        }
      })
    }

    return () => {
      dispatch(resetData(requestSubscriptionSteps))
    }
  }, [dispatch])

  useEffect(() => {
    if (savedAccountDetailsData && Object.keys(savedAccountDetailsData).length) {
      setIsSaveDisabled(isEqual(accountDetails, savedAccountDetailsData))
    }
  }, [accountDetails])

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <UpperMenu title={packageGroupName.current} />
      <Box
        ref={containerRef}
        sx={{
          height: '100%',
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        {jurisdiction && (
          <AccountDetails
            editMode
            showPrivacyPolicy={false}
            jurisdiction={jurisdiction}
            infoMessage={isShowInfoMessage.current ? 'account.associatedEmailMessage' : ''}
          />
        )}
        <MockFooterBlock />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '68px',
          padding: '16px',
          borderTop: '1px solid',
          borderColor: colors.border,
        }}
      >
        <Button variant='text' onClick={() => navigate(-1)}>
          {t('button.cancel')}
        </Button>
        <Button variant='contained' disabled={isSaveDisabled || !isValidAccountDetails} onClick={onSave}>
          {t('button.save')}
        </Button>
      </Box>
      <Footer />
    </Box>
  )
}
