import { SvgIcon, SvgIconProps } from '@mui/material'

export const HtmlIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.5858 4C13.851 4 14.1054 4.10536 14.2929 4.29289L17.7071 7.70711C17.8946 7.89464 18
          8.149 18 8.41421V10H20.5C20.7761 10 21 10.2239 21 10.5V16.5C21 16.7761 20.7761 17 20.5
          17H18V19.5C18 19.7761 17.7761 20 17.5 20H6.5C6.22386 20 6 19.7761 6 19.5V17H2.5C2.22386 17
          2 16.7761 2 16.5V10.5C2 10.2239 2.22386 10 2.5 10H6V4.5C6 4.22386 6.22386 4 6.5
          4H13.5858ZM17 19V17H7V19H17ZM13 4.999L7 5V10H17V9H14C13.4872 9 13.0645 8.61396 13.0067
          8.11662L13 8V4.999ZM14 5.414V8H16.586L14 5.414ZM20 15H18V11H17V16H20V15ZM4
          11V13.2929H5V11H6V16H5V14H4V16H3V11H4ZM10 12H9V16H8V12H7V11H10V12ZM13 16V12.5C13 12.2239
          12.7761 12 12.5 12C12.2545 12 12.0504 12.1769 12.0081 12.4101L12 12.5V16H11V12.5C11
          11.6716 11.6716 11 12.5 11C12.8842 11 13.2347 11.1445 13.5001 11.382C13.7644 11.1448
          14.1153 11 14.5 11C15.2797 11 15.9204 11.5949 15.9931 12.3555L16 12.5V16H15V12.5C15
          12.2239 14.7761 12 14.5 12C14.2545 12 14.0504 12.1769 14.0081 12.4101L14 12.5V16H13Z'
          fill='#f06529'
        />
      </SvgIcon>
    </>
  )
}
