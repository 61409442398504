import { useRef, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { UpperMenu } from 'components/layouts/routerLayout/upperMenu'
import { Footer as MainFooter } from 'components/Footer'
import { SuccessIcon } from 'components/icons/Success.icon'
import { MockFooterBlock } from 'components/Footer/mockFooterBlock'
import { useContainerScroll } from 'utils/scrollableFooter'
import { selectPackageProducts } from 'features/subscriptionRequest/slice/selectors'
import { Navigate, useParams } from 'react-router-dom'
import { PrintHeader } from 'features/subscriptionRequest/components/PrintHeader'
import { SubscriptionInfo } from 'features/subscriptionRequest/components/SubscriptionInfo/SubscriptionInfo'
import colors from 'theme/colors'
import { ProductSummary } from 'features/subscriptionRequest/components/SubscriptionOverview/componetns/ProductSummary/ProductSummary'
import { format } from 'date-fns'
import { Locales } from 'constants/languageKeys'

const footerStyles = {
  width: '100%',
  height: '68px',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px',
  backgroundColor: 'common.white',
  boxShadow: 'inset 0 1px 0',
  color: 'grey.100',
}

export const SubscriptionOverview: FC = () => {
  const { t } = useTranslation()
  const contentRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const dispatch = useAppDispatch()
  const { productGroup, subscription, isSubmissionSucceeded } = useAppSelector((state) => state.subscriptionRequest)
  const { languageSelected } = useAppSelector((state) => state.localization)
  const selectedPackageProducts = useAppSelector(selectPackageProducts)

  useContainerScroll(containerRef, dispatch)

  const { productGroupShortName } = useParams<{ productGroupShortName: string }>()

  const date = new Date()
  const DATE_FORMAT = 'dd MMM yyyy'
  const TIME_FORMAT = 'HH:mm'

  const submissionDate = format(date, DATE_FORMAT, {
    locale: Locales[languageSelected.localeCode],
  })
  const submissionTime = format(date, TIME_FORMAT, {
    locale: Locales[languageSelected.localeCode],
  })

  const subscriptionInfoColumns = [
    {
      header: 'subscriptionSummary.details.companyName',
      value: subscription.accountDetails.companyFullName,
    },
    {
      header: 'subscriptionSummary.details.subscriberName',
      value: `${subscription.accountDetails.firstName} ${subscription.accountDetails.lastName}`,
    },
    {
      header: 'subscriptionSummary.details.submissionDate',
      value: submissionDate,
    },
    {
      header: 'subscriptionSummary.details.submissionTime',
      value: submissionTime,
    },
  ]

  const onPreviewButtonClick = () => {
    productGroup.url && (location.href = productGroup.url)
  }

  if (!isSubmissionSucceeded) {
    return <Navigate to={`/subscribe/${productGroupShortName}`} replace />
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UpperMenu title={productGroup.name} />
      <Box
        ref={containerRef}
        sx={{
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '24px 0',
            width: '918px',
            margin: '0 auto',
          }}
        >
          <PrintHeader contentRef={contentRef} title={t('subscriptionSummary.title')} />
          <Box ref={contentRef} gap='24px'>
            <SubscriptionInfo columns={subscriptionInfoColumns} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '604px',
                m: '0 auto',
                gap: '24px',
                p: '24px 0',
              }}
            >
              {selectedPackageProducts.length > 1 && (
                <Typography sx={{ fontSize: '12px', color: 'grey.800' }}>
                  <Typography sx={{ display: 'inline', marginRight: '4px', color: colors.blue.main, fontSize: '12px' }}>
                    {t('subscriptionSummary.note.title')}
                  </Typography>
                  {t('subscriptionSummary.note.message')}
                </Typography>
              )}
              {selectedPackageProducts.map((product) => (
                <ProductSummary product={product} key={product.id} />
              ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                p: '16px 24px',
                m: '8px 0',
                gap: '8px',
                borderTop: '1px solid',
                borderColor: 'grey.100',
              }}
            >
              <Box display='flex'>
                <SuccessIcon />
              </Box>
              <Typography fontWeight='600'>{t('subscriptionSummary.submission.title')}</Typography>
            </Box>
            <Typography sx={{ p: '0 24px 16px', fontSize: '14px', lineHeight: '24px' }}>
              {t('subscriptionSummary.submission.message')}
            </Typography>
          </Box>
        </Box>
        <MockFooterBlock />
      </Box>
      <Box sx={footerStyles}>
        <Button
          variant={'contained'}
          sx={{
            minWidth: '54px',
            borderRadius: '2px',
            marginLeft: '16px',
          }}
          onClick={onPreviewButtonClick}
        >
          {t('subscriptionSummary.button.preview', { productGroupShortName })}
        </Button>
      </Box>
      <MainFooter />
    </Box>
  )
}
