import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { UpperMenu } from 'components/layouts/routerLayout/upperMenu'
import { Footer } from 'components/Footer'
import dsgCortexErrorIcon from 'assets/images/dsgCortexError.svg'

export const ServerError = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onBack = useCallback(() => navigate(-1), [navigate])

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UpperMenu title={'titlePage.globalSubService'} />
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: 'menuItemHover.main',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component='img' src={dsgCortexErrorIcon} alt='error icon'></Box>
        <Typography variant='body1' fontWeight={600} mt='51px'>
          {t('serverError.title')}
        </Typography>
        <Typography
          variant='body2'
          width={'394px'}
          textAlign={'center'}
          color={'grey.800'}
          mt='6px'
          mb='16px'
        >
          {t('serverError.description')}
        </Typography>
        <Button
          sx={{
            marginTop: '10px',
          }}
          variant='contained'
          onClick={onBack}
        >
          {t('button.back')}
        </Button>
      </Box>
      <Footer />
    </Box>
  )
}
