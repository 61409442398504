import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Checkbox, FormControlLabel, Typography, useTheme } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { fetchAcceptableUsePolicies, fetchAcceptableUsePolicy } from 'features/subscriptionRequest/slice/services'
import { ProductGroupType } from 'features/subscriptionRequest/constants/subscription'
import { setIsPolicyAccepted, setIsPolicyOptionsAccepted } from '../../slice'
import { InfoIcon } from 'components/icons/Info.icon'
import { CustomArrowTooltip } from 'components/CustomTooltip'
import colors from 'theme/colors'
import { PrintHeader } from 'features/subscriptionRequest/components/PrintHeader'

export interface IAcceptableUsePolicyOption {
  id: string
  text: string
  description: string
}

export interface IAcceptableUsePolicy {
  termsOfUseId: number
  title: string
  text: string
  termsVersion: string
  active: boolean
  createdDate: string
  modifiedDate: string
  options: IAcceptableUsePolicyOption[]
}

export function AcceptableUsePolicy(): JSX.Element {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const componentRef = useRef(null)

  const {
    isPolicyAccepted,
    acceptableUsePolicy,
    acceptableUsePolicies,
    isPolicyOptionsAccepted,
    activePolicyIndex,
    productGroup,
    subscription: { country: selectedCountry },
  } = useAppSelector((state) => state.subscriptionRequest)
  const { languageSelected } = useAppSelector((state) => state.localization)
  const printStyles = `<style>@page { margin: 24px!important; }</style>`

  const replaceCountryName = (acceptableUsePolicyText: string) => {
    return (
      acceptableUsePolicyText &&
      acceptableUsePolicyText.replace(
        '[country-name]',
        t(`country.${selectedCountry?.abbreviation.toLowerCase()}.name`)
      )
    )
  }

  useEffect(() => {
    if (!acceptableUsePolicies.length) {
      if (selectedCountry) {
        dispatch(fetchAcceptableUsePolicy(selectedCountry.productId))
      } else if (productGroup.type === ProductGroupType.All) {
        dispatch(fetchAcceptableUsePolicies())
      }
    }
  }, [acceptableUsePolicy, selectedCountry, dispatch])

  useEffect(() => {
    if (selectedCountry) {
      dispatch(fetchAcceptableUsePolicy(selectedCountry.productId))
    }
  }, [languageSelected, dispatch, selectedCountry])

  return acceptableUsePolicies.length ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        padding: '24px 0 24px 24px',
        position: 'relative',
        width: '918px',
        margin: '0 auto',

        '.wrapper': {
          justifyContent: 'center',
          '.content': {
            width: '918px',
          },
        },
      }}
    >
      <Box padding='8px 8px 0 8px'>
        <PrintHeader contentRef={componentRef} title={acceptableUsePolicies[activePolicyIndex]?.title} />
        {productGroup.type !== ProductGroupType.All && (
          <>
            <Typography fontSize='14px' fontWeight='400' color={colors.mainGrey}>
              {t('acceptableUsePolicy.header.country')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                margin: '10px 0',
              }}
            >
              <Avatar
                sx={{ width: 24, height: 24, mr: '10px', flexShrink: 0 }}
                src={`${process.env.PUBLIC_URL}/images/flags/${selectedCountry!.abbreviation}.svg`}
              />
              <Typography fontSize='14px' fontWeight='400' color={colors.shark}>
                {t(`country.${selectedCountry!.abbreviation.toLowerCase()}.name`)}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '100%',
        }}
        ref={componentRef}
        dangerouslySetInnerHTML={{
          __html: printStyles + replaceCountryName(acceptableUsePolicies[activePolicyIndex]?.text),
        }}
      ></Box>
      <Box sx={{ p: '12px 24px 0 24px' }}>
        <FormControlLabel
          sx={{
            '& span': {
              fontSize: 14,
              fontWeight: theme.fontWeight[500],
            },
          }}
          control={
            <Checkbox
              size='small'
              checked={isPolicyAccepted[activePolicyIndex]}
              onChange={() =>
                dispatch(
                  setIsPolicyAccepted({
                    index: activePolicyIndex,
                    isAccepted: !isPolicyAccepted[activePolicyIndex],
                  })
                )
              }
            />
          }
          label={t(`acceptableUsePolicy.checkBox.label${productGroup.type === ProductGroupType.All ? 'ToU' : ''}`)}
        />
      </Box>
      {acceptableUsePolicies[activePolicyIndex].options.map((option) => (
        <Box key={option.id} sx={{ display: 'flex', alignItems: 'center', p: '0 24px' }}>
          <FormControlLabel
            sx={{
              mr: 0,
              '& span': {
                fontSize: 14,
                fontWeight: theme.fontWeight[500],
              },
            }}
            control={
              <Checkbox
                size='small'
                checked={isPolicyOptionsAccepted[activePolicyIndex][option.id]}
                onChange={() =>
                  dispatch(
                    setIsPolicyOptionsAccepted({
                      policyIndex: activePolicyIndex,
                      optionId: option.id,
                      isAccepted: !isPolicyOptionsAccepted[activePolicyIndex][option.id],
                    })
                  )
                }
              />
            }
            label={option.text}
          />
          {option.description && (
            <CustomArrowTooltip placement='top' title={option.description}>
              <Box sx={{ pointerEvents: 'all', width: '24px', height: '24px' }}>
                <InfoIcon sx={{ fill: colors.blue.main }} />
              </Box>
            </CustomArrowTooltip>
          )}
        </Box>
      ))}
    </Box>
  ) : (
    <></>
  )
}
