import { IJurisdiction } from 'features/manageReports/constants'
import { geoApi } from 'services/api/geoApi'

export const areAllJurisdictionsSelected = (jurisdictions: IJurisdiction[], selectedJurisdictions: string[]) => {
  const selectedCodes = selectedJurisdictions.map((id) => geoApi.jurMapping[id].code) || []
  const selectedGeoCodes = new Set(selectedJurisdictions.map((id) => geoApi.jurMapping[id]?.geoCode))

  if (selectedCodes.length === 0) {
    return false
  }

  return Array.from(selectedGeoCodes).every((geoCode) => {
    const codesForGeoCode = jurisdictions.filter((jur) => jur.geoCode === geoCode).map((jur) => jur.code)
    return codesForGeoCode.every((code) => selectedCodes.includes(code))
  })
}
