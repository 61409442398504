import { SvgIcon, SvgIconProps } from '@mui/material'

export const DropdownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.501 10.5C16.9339 10.5 17.1622 11.0127 16.8726 11.3345L12.3726 16.3345C12.174 16.5552
        11.828 16.5552 11.6293 16.3345L7.12933 11.3345C6.83975 11.0127 7.0681 10.5 7.50098
        10.5H16.501Z'
      />
    </SvgIcon>
  )
}
