import { Modal, Box } from '@mui/material'

import { useAppSelector } from 'app/hooks'
import { InactivateSubForm } from 'features/managePackage/components/InactivateSubForm'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '640px',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0 0 2px 2px',
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.12)',
}

export const InactivateSubModal = () => {
  const { isInactivateModalOpen } = useAppSelector((state) => state.ui)

  return (
    <Modal open={isInactivateModalOpen}>
      <Box sx={modalStyle}>
        <InactivateSubForm />
      </Box>
    </Modal>
  )
}
