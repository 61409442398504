import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { selectRestrictedCountrySupportLink } from 'features/subscriptionRequest/slice/selectors'
import { useAppSelector } from 'app/hooks'
import { RestrictionPopup } from '../shared-components/RestrictionPopUp'

interface Props {
  onClose: () => void
  open: boolean
}

export const RestrictedCountryPopup: FC<Props> = ({ onClose, open }) => {
  const { t } = useTranslation()
  const supportLink = useAppSelector(selectRestrictedCountrySupportLink)

  return (
    <RestrictionPopup
      open={open}
      onClose={onClose}
      title={t('account.restrictedPopup.title')}
      link={supportLink}
      text2={t('account.restrictedPopup.description2')}
      buttonText={t('button.ok')}
      customLink={'here'}
      text={'account.restrictedPopup.description'}
    />
  )
}
