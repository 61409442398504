import { FC } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomForm from 'components/CustomForm'

import { IPermission } from 'features/manageAdmins/constants'

interface Props {
  permissions: IPermission[]
  permissionsSelected: number
  disabled?: boolean
  setValue: (fieldName: string, param: string | string[] | number) => void
}

const SelectAll: FC<Props> = ({ setValue, permissionsSelected, permissions, disabled }) => {
  const { t } = useTranslation()

  return (
    <>
      {permissions.length > 0 && (
        <>
          <Typography variant='body2'>{t('manageAdmins.selectActions')}</Typography>
          <CustomForm.CheckBox
            name='permissionIds'
            checked={permissionsSelected === permissions.length}
            indeterminate={permissionsSelected < permissions.length && permissionsSelected > 0}
            label='manageAdmins.selectAll'
            disabled={disabled}
            onChange={() =>
              setValue(
                'permissionIds',
                permissionsSelected <= permissions.length && permissionsSelected > 0
                  ? []
                  : permissions.map((p) => String(p.id))
              )
            }
          />
        </>
      )}
    </>
  )
}

export default SelectAll
