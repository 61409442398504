import { FC, SyntheticEvent, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash.debounce'

import CustomForm from 'components/CustomForm'
import { CardEmail } from '../cardEmail'

import { useSearchUsers } from '../../hooks'
import { useAppSelector } from 'app/hooks'

import { IAddAdminFormData, IGenericEmployee, SUPER_ADMIN } from 'features/manageAdmins/constants'

interface Props {
  isSearching: boolean
  employees: IGenericEmployee[]
  isDisabled?: boolean
  formValues: IAddAdminFormData
  setValue: (fieldName: string, param: string | string[] | number) => void
}

const EmployeeField: FC<Props> = ({ isDisabled, formValues, setValue, employees, isSearching }) => {
  const { t } = useTranslation()
  const [isOpenList, setIsOpenList] = useState(false)
  const [employeesSelected, setEmployeesSelected] = useState<IGenericEmployee[]>([])
  const [disabled, setDisabled] = useState(true)
  const { searchEmployees } = useSearchUsers({ formValues })
  // selectors
  const { roleSelected } = useAppSelector((state) => state.manageAdmins)

  const onChange = (
    _: SyntheticEvent<Element, Event>,
    employeeList: IGenericEmployee | IGenericEmployee[] | null
  ) => {
    if (Array.isArray(employeeList)) {
      setEmployeesSelected(employeeList)
      setValue(
        'employeeIds',
        employeeList.flatMap((e) => [e.id])
      )
      setIsOpenList(false)
    }
  }

  const onInputChange = useCallback(
    (e: SyntheticEvent<Element, Event>, value: string) => {
      if (value.length >= 3) {
        searchEmployees(value.trim())
        setIsOpenList(true)
      } else {
        setIsOpenList(false)
      }
    },
    [searchEmployees]
  )

  const debouncedInputChange = useMemo(() => debounce(onInputChange, 1000), [onInputChange])

  useEffect(() => {
    formValues.employeeIds.length === 0 && setEmployeesSelected([])
  }, [roleSelected, formValues.employeeIds])

  useEffect(() => {
    roleSelected === SUPER_ADMIN || !isDisabled ? setDisabled(false) : setDisabled(true)
  }, [roleSelected, isDisabled])

  return (
    <>
      <CustomForm.Label value='manageAdmins.employee' />
      <CustomForm.Autocomplete<IGenericEmployee>
        id='employee-auto'
        multiple
        disabled={disabled}
        forcePopupIcon={false}
        filterOptions={(x) => x}
        filterSelectedOptions
        value={employeesSelected}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={employees}
        loading={isSearching}
        open={isOpenList}
        getOptionLabel={(employee) => `${employee.firstName} ${employee.lastName}`}
        onChange={onChange}
        onInputChange={debouncedInputChange}
        renderOption={(props, employee) => (
          <Box component='span' {...props} key={employee.id}>
            <CardEmail
              name={employee.firstName}
              lastName={employee.lastName}
              value={employee.email}
            />
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ p: 0, m: 0 }}
            fullWidth
            name='employeeIds'
            placeholder={t('manageAdmins.employeePlaceHolder')!}
          />
        )}
      />
    </>
  )
}

export default memo(EmployeeField)
