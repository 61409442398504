import { createSlice } from '@reduxjs/toolkit'
import {
  getEmployeesByName,
  getEmployeesByRole,
  getEmployeesByJurisdiction,
  getEmployeeByEmployeeRoleId,
  getEmployeesByProductGroupIdAndJurisdictionId,
} from './actions'
import { EmployeesSlice } from './constants'

export const EMPLOYEES_INIT_STATE: EmployeesSlice = {
  employees: [],
  employeesFound: [],
  employeeSelected: null,
  employeeSelectedId: null,
  isSearching: false,
  isLoading: false,
  error: undefined,
}

const _handlePending = (state: EmployeesSlice) => {
  state.isSearching = true
}

const employeesSlice = createSlice({
  name: 'employees',
  initialState: EMPLOYEES_INIT_STATE,
  reducers: {
    resetEmployeesFound(state: EmployeesSlice) {
      state.employeesFound = []
    },
    resetEmployeeSelected(state: EmployeesSlice) {
      state.employeeSelected = null
    },
    resetEmployeeSelectedId(state: EmployeesSlice) {
      state.employeeSelectedId = null
    },
    setEmployeeSelectedId(state: EmployeesSlice, action) {
      state.employeeSelectedId = action.payload
    },
  },
  extraReducers: (builder) => {
    // getEmployeesByName
    builder.addCase(getEmployeesByName.pending, _handlePending)
    builder.addCase(getEmployeesByName.fulfilled, (state: EmployeesSlice, action) => {
      state.employeesFound = action.payload
      state.isSearching = false
      state.error = undefined
    })
    builder.addCase(getEmployeesByName.rejected, (state: EmployeesSlice, action) => {
      state.isLoading = false
      state.employeesFound = []
      state.error = action.payload?.response
    })
    // getEmployeesByJurisdiction
    builder.addCase(getEmployeesByJurisdiction.pending, _handlePending)
    builder.addCase(getEmployeesByJurisdiction.fulfilled, (state: EmployeesSlice, action) => {
      state.employeesFound = action.payload
      state.isSearching = false
      state.error = undefined
    })
    builder.addCase(getEmployeesByJurisdiction.rejected, (state: EmployeesSlice, action) => {
      state.isSearching = false
      state.employeesFound = []
      state.error = action.payload?.response
    })
    // getEmployeesByProductGroupIdAndJurisdictionId
    builder.addCase(getEmployeesByProductGroupIdAndJurisdictionId.pending, _handlePending)
    builder.addCase(getEmployeesByProductGroupIdAndJurisdictionId.fulfilled, (state: EmployeesSlice, action) => {
        state.employeesFound = action.payload
        state.isSearching = false
        state.error = undefined
      })
    builder.addCase(getEmployeesByProductGroupIdAndJurisdictionId.rejected, (state: EmployeesSlice, action) => {
        state.isSearching = false
        state.employeesFound = []
        state.error = action.payload?.response
      })
    // getEmployeeByEmployeeRoleId
    builder.addCase(getEmployeeByEmployeeRoleId.pending, _handlePending)
    builder.addCase(getEmployeeByEmployeeRoleId.fulfilled, (state: EmployeesSlice, action) => {
      state.employeeSelected = action.payload
      state.isSearching = false
      state.error = undefined
    })
    builder.addCase(getEmployeeByEmployeeRoleId.rejected, (state: EmployeesSlice, action) => {
      state.isSearching = false
      state.employeeSelected = null
      state.error = action.payload?.response
    })
    // getEmployeesByRole
    builder.addCase(getEmployeesByRole.pending, (state: EmployeesSlice) => {
      state.isLoading = true
    })
    builder.addCase(getEmployeesByRole.fulfilled, (state: EmployeesSlice, action) => {
      state.employees = action.payload
      state.isLoading = false
      state.error = undefined
    })
    builder.addCase(getEmployeesByRole.rejected, (state: EmployeesSlice, action) => {
      state.isLoading = false
      state.employees = []
      state.error = action.payload?.response
    })
  },
})

export const {
  resetEmployeesFound,
  resetEmployeeSelected,
  resetEmployeeSelectedId,
  setEmployeeSelectedId,
} = employeesSlice.actions

export {
  getEmployeesByName,
  getEmployeesByRole,
  getEmployeesByJurisdiction,
  getEmployeeByEmployeeRoleId,
  getEmployeesByProductGroupIdAndJurisdictionId,
}

export default employeesSlice.reducer
