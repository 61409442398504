export const enum DateFormat {
  DDMonYYYY = 'DD Mon YYYY',
  MMDDYYYY = 'MM/DD/YYYY',
  YYYYMMDD = 'YYYY/MM/DD',
  DDMMYYYY = 'DD/MM/YYYY',
}

export const enum MoneyFormat {
  Commas = '1,000,000.00',
  Dots = '1.000.000,00',
  Spaces = '1 000 000,00',
}

export const DATE_FORMATS: DateFormat[] = [
  DateFormat.DDMonYYYY,
  DateFormat.MMDDYYYY,
  DateFormat.YYYYMMDD,
  DateFormat.DDMMYYYY,
]

export const NUMBER_FORMATS: MoneyFormat[] = [
  MoneyFormat.Commas,
  MoneyFormat.Dots,
  MoneyFormat.Spaces,
]
