import { ICountry } from 'features/subscriptionRequest/constants/subscription'
import { ProductGroupType } from 'features/productConfiguration/constants'

export type AdminType = 'super' | 'jurisdiction'
export const ADMIN_TAB_PATH = '/manage/admin'

export const SUPER_ADMIN = 1
export const JURISDICTION_ADMIN = 2
export const PRODUCT_ADMIN = 3
export const DEMO_USER = 4
export const DEMO_USER_KEY = 'demouser'
export const SUPER_ADMIN_KEY = 'super'

export const ROLE_NAME: { [x: string]: string } = {
  super: 'superAdmin',
  jurisdiction: 'jurisdictionAdmin',
  product: 'productAdmin',
  demouser: 'demouser',
}

export const headers = {
  headers: {
    'x-action-origin': 'manageAdmins',
  },
}

export const ROLES_ENUM: { [x: number]: string } = {
  1: 'SuperAdmin',
  2: 'JurisdictionAdmin',
  3: 'ProductAdmin',
}

export interface IAddAdminFormData {
  roleId: number | null
  jurisdictionId?: string
  productId?: string
  employeeIds: string[]
  permissionIds: string[]
}

export interface IUpdateAdminFormData {
  permissionIds: string[]
}

export interface IUpdateEmployeePermissions {
  employeeRoleId: string
  permissions: string[]
  jurisdiction?: IJurisdiction
}

export interface IDeleteEmployeeRole {
  id: string
}

export interface IPermission {
  id: number
  name: string
}

export interface IProductGroup {
  id: string
  name: string
  shortName: string
  type: ProductGroupType
  products: IProduct[]
}

export interface IProduct {
  id: string
  name: string
  countries: ICountry[] | null
  group?: string
}

export interface IJurisdiction {
  id: string
  name: string
  code: string
}

export interface IRole {
  id: number
  name: string
}

export interface IGenericEmployee {
  id: string
  firstName: string
  lastName: string
  email?: string
}

export interface IEmployee extends IGenericEmployee {
  employeeRoleId: string
  roles?: number[]
  assignmentDate: string
  fullPermissions: boolean
  jurisdiction?: IJurisdiction
  productName?: string
  productGroupShortName?: string
  productGroupName?: string
  allowedPermissions: number[]
}

export interface IEmployeeResponse {
  data: IEmployee
  metadata: { allowedActions: number[] }
}

export const adminTabsNavigationData = [
  {
    id: SUPER_ADMIN,
    name: 'super',
    path: '/manage/admin/super',
  },
  {
    id: JURISDICTION_ADMIN,
    name: 'jurisdiction',
    path: '/manage/admin/jurisdiction',
  },
  {
    id: PRODUCT_ADMIN,
    name: 'product',
    path: '/manage/admin/product',
  },
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const columnsByRole: { [x: string]: any } = {
  super: {
    columns: [
      {
        key: 'adminName',
        title: 'manageAdmins.tableColumns.adminName',
        sorting: false,
        columStyle: { width: '19.6%' },
      },
      {
        key: 'email',
        title: 'manageAdmins.tableColumns.adminEmail',
        sorting: false,
        columStyle: { width: '19.6%' },
      },
      {
        key: 'roles',
        title: 'manageAdmins.tableColumns.additionalAdminRole',
        sorting: false,
        columStyle: { width: '16%' },
      },
      {
        key: 'assignmentDate',
        title: 'manageAdmins.tableColumns.assignmentDate',
        sorting: false,
        columStyle: { width: '14%' },
      },
      {
        key: 'actions',
        title: '',
        sorting: false,
      },
    ],
  },
  jurisdiction: {
    columns: [
      {
        key: 'adminName',
        title: 'manageAdmins.tableColumns.adminName',
        sorting: false,
        columStyle: { width: '19.6%' },
      },
      {
        key: 'email',
        title: 'manageAdmins.tableColumns.adminEmail',
        sorting: false,
        columStyle: { width: '19.6%' },
      },
      {
        key: 'jurisdiction',
        title: 'manageAdmins.tableColumns.jurisdiction',
        sorting: false,
        columStyle: { width: '13%' },
      },
      {
        key: 'roles',
        title: 'manageAdmins.tableColumns.additionalAdminRole',
        sorting: false,
        columStyle: { width: '16%' },
      },
      {
        key: 'assignmentDate',
        title: 'manageAdmins.tableColumns.assignmentDate',
        sorting: false,
        columStyle: { width: '14%' },
      },
      {
        key: 'actions',
        title: '',
        sorting: false,
      },
    ],
  },
  product: {
    columns: [
      {
        key: 'adminName',
        title: 'manageAdmins.tableColumns.adminName',
        sorting: false,
        columStyle: { width: '19.6%' },
      },
      {
        key: 'email',
        title: 'manageAdmins.tableColumns.adminEmail',
        sorting: false,
        columStyle: { width: '19.6%' },
      },
      {
        key: 'jurisdiction',
        title: 'manageAdmins.tableColumns.jurisdiction',
        sorting: false,
        columStyle: { width: '13%' },
      },
      {
        key: 'productGroupShortName',
        title: 'manageAdmins.tableColumns.product',
        sorting: false,
        columStyle: { width: '13%' },
      },
      {
        key: 'roles',
        title: 'manageAdmins.tableColumns.additionalAdminRole',
        sorting: false,
        columStyle: { width: '16%' },
      },
      {
        key: 'assignmentDate',
        title: 'manageAdmins.tableColumns.assignmentDate',
        sorting: false,
        columStyle: { width: '14%' },
      },
      {
        key: 'actions',
        title: '',
        sorting: false,
      },
    ],
  },
  demouser: {
    columns: [
      {
        key: 'userName',
        title: 'manageAdmins.tableColumns.userName',
        sorting: false,
        columStyle: { width: '19.6%' },
      },
      {
        key: 'email',
        title: 'manageAdmins.tableColumns.userEmail',
        sorting: false,
        columStyle: { width: '19.6%' },
      },
      {
        key: 'productGroupShortName',
        title: 'manageAdmins.tableColumns.product',
        sorting: false,
        columStyle: { width: '13%' },
      },
      {
        key: 'jurisdictionName',
        title: 'manageAdmins.tableColumns.jurisdiction',
        sorting: false,
        columStyle: { width: '13%' },
      },
      {
        key: 'assignmentDate',
        title: 'manageAdmins.tableColumns.assignmentDate',
        sorting: false,
        columStyle: { width: '14%' },
      },
      {
        key: 'actions',
        title: '',
        sorting: false,
      },
    ],
  },
}
