import React, { useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { CustomTable, IColumnType } from 'components/CustomTable'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ReportColumnType } from 'features/manageReports/constants'
import { formatDate } from 'utils/date'
import { getDateFormat } from 'sharedSlices/auth'
import { TermType } from 'features/subscriptionRequest/constants/subscription'
import { CustomPagination } from 'components/CustomPagination/reports'
import { setPagination } from 'features/manageReports/slice'
import { useTranslation } from 'react-i18next'
import { generateReport } from 'features/manageReports/services'
import { SortingDirection } from 'constants/sorting'

export const GeneratedReport: React.FC = () => {
  const { generatedReport, reportTypes, details } = useAppSelector((state) => state.manageReports)
  const dateFormat = useAppSelector(getDateFormat)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [columns, setColumns] = useState<IColumnType<any>[]>([])

  useEffect(() => {
    setColumns(
      reportTypes
        .find((type) => type.id === details.reportTypeId)!
        .columns.filter((item) => generatedReport.details!.columns.includes(item.name))
        .map((column) => {
          return {
            key: column.propertyName,
            title: column.name,
            render:
              column.type === ReportColumnType.Date
                ? (_, item) => (item[column.propertyName] ? formatDate(item[column.propertyName], dateFormat) : '')
                : column.type === ReportColumnType.Status
                ? (_, item) => t(`manageProduct.status.${item[column.propertyName]}`)
                : column.type === ReportColumnType.DurationValuations
                ? (_, item) => {
                    const durationValuations = item[column.propertyName].split(',')
                    return durationValuations.length === 1
                      ? `${durationValuations} ${TermType.Year}${Number(durationValuations) > 1 ? 's' : ''}`
                      : `${durationValuations[0]} of ${durationValuations[1]}`
                  }
                : undefined,
          }
        })
    )
  }, [generatedReport])

  const changeCurrentPage = ({ page, size }: { page?: number; size?: number }) => {
    if (page) {
      const pagination = {
        number: page,
        size: size || generatedReport.pagination.size,
      }
      dispatch(setPagination(pagination))
      dispatch(generateReport({ ...generatedReport.payload!, pagination }))
    }
  }

  return (
    <Box
      sx={{
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
      }}
    >
      <Box padding='24px'>
        <Box
          sx={{
            border: '1px solid',
            borderColor: 'grey.100',
            borderRadius: '4px',
          }}
        >
          <Typography padding='16px' variant='h4' fontWeight='600'>
            {generatedReport.filters!.name}
          </Typography>
          <Table
            sx={{
              tableLayout: 'fixed',
              borderTop: '1px solid',
              borderColor: 'grey.100',
              '& td, & th': { border: 0 },
              '& th': {
                fontWeight: 600,
                padding: '12px 16px 4px 16px',
              },
              '& td': {
                padding: '10px 16px',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '190px' }}>{t('reports.generatedFilters.reportType')!}</TableCell>
                <TableCell sx={{ width: '214px' }}>{t('reports.generatedFilters.reportPeriod')!}</TableCell>
                <TableCell sx={{ width: 'calc(100% - 280px)' }}>
                  {t('reports.generatedFilters.productAndJurisdiction.title')!}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{generatedReport.filters!.type}</TableCell>
                <TableCell>
                  {generatedReport.filters!.period.startDate && generatedReport.filters!.period.endDate
                    ? `${formatDate(generatedReport.filters!.period.startDate, dateFormat)} -
                         ${formatDate(generatedReport.filters!.period.endDate, dateFormat)}`
                    : '–'}
                </TableCell>
                <TableCell>
                  <OverflowTooltip
                    text={
                      generatedReport.filters!.productAndJurisdiction ||
                      t('reports.generatedFilters.productAndJurisdiction.all')!
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          overflowX: 'auto',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            td: {
              whiteSpace: 'nowrap',
            },
            th: {
              width: '190px',
            },
          }}
        >
          <CustomTable
            overflowLayout
            columns={columns}
            data={generatedReport.result!}
            sorting={{
              column: 'SubscriptionId',
              direction: SortingDirection.DESC,
            }}
            onSort={() => null}
          />
        </Box>
      </Box>
      {generatedReport.total > 10 && (
        <CustomPagination
          count={generatedReport.total}
          rowsPerPage={generatedReport.pagination.size}
          currentPage={generatedReport.pagination.number}
          changeCurrentPage={changeCurrentPage}
        />
      )}
    </Box>
  )
}
