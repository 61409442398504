import { ChangeEvent, FC } from 'react'
import { Box, Button, TextareaAutosize } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CustomFormLabel } from 'components/CustomFormLabel'
import CustomModal from 'components/CustomModal'
import colors from 'theme/colors'
import { WarningIcon } from 'components/icons/Warning.icon'
import { ErrorIcon } from 'components/icons/Error.icon'
import { InfoIcon } from 'components/icons/Info.icon'
import { SuccessIcon } from 'components/icons/Success.icon'
import { CloseIcon } from 'components/icons/Close.icon'

interface Props {
  icon?: 'warning' | 'error' | 'info' | 'success'
  header: string,
  message: string,
  subMessage?: string,
  label: string,
  comment: string
  onChangeComment: (event: ChangeEvent<HTMLTextAreaElement>) => void
  onClose: () => void
  onSubmit: () => void
  open: boolean
}

export const ProcessSubscriptionPopup: FC<Props> = ({
  icon,
  header,
  message,
  subMessage,
  label,
  comment,
  onChangeComment,
  onClose,
  onSubmit,
  open,
}) => {
  const { t } = useTranslation()
  return (
    <CustomModal open={open} onClose={onClose}>
      <CustomModal.Header>
        {icon &&
          {
            warning: <WarningIcon />,
            error: <ErrorIcon />,
            info: <InfoIcon />,
            success: <SuccessIcon />,
          }[icon]}
        <Box sx={{ marginRight: 'auto', marginLeft: '5px' }}>{t(header)}</Box>
        <Button
          sx={{
            minHeight: '24px',
            minWidth: '24px',
            padding: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon/>
        </Button>
      </CustomModal.Header>
      <CustomModal.Body
        style={{
          fontFamily: 'Open Sans',
          fontSize: 14,
          padding: '16px',
        }}
      >
        <Box>{t(message)}</Box>
        {subMessage && <Box sx={{
          paddingTop: '8px',
          fontWeight: '600'
        }}>{subMessage}</Box>}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',

            '& > textarea': {
              overflowY: 'auto !important',
              borderColor: colors.quillGray,

              ':focus-visible': {
                outline: 'none',
              },

              '::placeholder': {
                color: colors.silverChalice,
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
              },
            },
          }}
        >
          <CustomFormLabel sx={{ marginTop: subMessage ? '8px' : '16px' }} value={t(label)}></CustomFormLabel>
          <TextareaAutosize
            style={{
              borderColor: 'grey.100',
              borderRadius: '2px',
              fontFamily: 'Open Sans',
              fontSize: '14px',
              height: '110px',
              lineHeight: '24px',
              padding: '6px 8px',
              resize: 'none',
              width: '100%',
            }}
            value={comment}
            maxLength={250}
            onChange={onChangeComment}
            placeholder={t('processSubscriptionPopup.placeholder')!}
          />
          <Box
            sx={{
              color: 'grey.800',
              fontSize: '12px',
              lineHeight: '16px',
              marginLeft: 'auto',
              marginTop: '8px',
            }}
          >
            {comment.length}/250
          </Box>
        </Box>
      </CustomModal.Body>
      <CustomModal.Footer
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          padding: '16px',
        }}
      >
        <Button variant='outlined' onClick={onClose}>
          {t('button.no')}
        </Button>
        <Button
          disabled={!comment.trim().length}
          sx={{
            marginLeft: '16px',
          }}
          variant='contained'
          onClick={onSubmit}
        >
          {t('button.yes')}
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  )
}