import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useAppDispatch } from 'app/hooks'
import { zuoraService } from 'features/subscriptionRequest/services/zoura'
import colors from 'theme/colors'

export const CreditCard = ({
  productId,
  currencyId,
  jurCode,
}: {
  productId: string
  currencyId: number
  jurCode: string
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    zuoraService.init(productId, currencyId, jurCode, dispatch)
  }, [currencyId, jurCode, productId])

  return (
    <Box>
      <Box id='zuora_payment' />
      <Box
        sx={{
          color: 'grey.800',
          lineHeight: '16px',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          mt: '24px',
        }}
      >
        <Trans i18nKey={`paymentDetails.creditCard.note${jurCode}`}>
          <span style={{ color: colors.blue.main }}>note</span>
        </Trans>
      </Box>
      <Box
        sx={{
          color: 'grey.800',
          lineHeight: '24px',
          fontFamily: 'Open Sans',
          fontSize: '14px',
          pt: '24px',
          mt: '24px',
          borderTop: `1px solid ${colors.quillGray}`,
        }}
      >
        {jurCode === 'UK' ? (
          <Box>{t('paymentDetails.creditCard.police')}</Box>
        ) : (
          <>
            <Box>{t('paymentDetails.invoice.thirdParty')}</Box>
            <Box sx={{ mt: '24px' }}>{t('paymentDetails.invoice.note')}</Box>
          </>
        )}
      </Box>
    </Box>
  )
}
