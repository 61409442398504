import { SvgIcon, SvgIconProps } from '@mui/material'

export const EditIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.8533 4.14645L19.8533 6.14645C20.0486 6.34171 20.0486 6.65829 19.8533
           6.85355L8.35334 18.3536C8.28354 18.4234 8.19464 18.4709 8.09785 18.4903L5.59785
           18.9903C5.24799 19.0603 4.93953 18.7518 5.0095 18.4019L5.5095 15.9019C5.52886
           15.8051 5.57644 15.7162 5.64624 15.6464L17.1462 4.14645C17.3415 3.95118 17.6581
           3.95118 17.8533 4.14645ZM14.9995 7.707L6.46039 16.2465L6.13717 17.8626L7.75328
           17.5394L16.2925 9L14.9995 7.707ZM17.4998 5.20711L15.7065 7L16.9995 8.293L18.7927
           6.5L17.4998 5.20711Z'
      />
    </SvgIcon>
  )
}
