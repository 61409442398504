import { SvgIcon, SvgIconProps } from '@mui/material'

export const InactivateIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4
        7.58172 7.58172 4 12 4ZM5 12C5 15.866 8.13401 19 12 19C13.7531 19 15.3556 18.3556 16.5837
        17.2907L6.70933 7.4163C5.64443 8.64437 5 10.2469 5 12ZM12 5C10.2469 5 8.64437 5.64443 7.4163
        6.70933L17.2907 16.5837C18.3556 15.3556 19 13.7531 19 12C19 8.13401 15.866 5 12 5Z'
      />
    </SvgIcon>
  )
}
