import { ITableData } from 'components/CustomTable'
import { IMyReport } from 'features/manageReports/constants'

export const getJoinedReports = (reportList: ITableData<IMyReport>[]) => {
  const reportsData = reportList.reduce(
    (reports, currReport) => {
      return {
        ...reports,
        total: reports.total + currReport.total,
        result: [...reports.result, ...currReport.result],
      }
    },
    { result: [], total: 0 }
  )
  reportsData.result.sort((a, b) => new Date(b.generatedDate).getTime() - new Date(a.generatedDate).getTime())
  return reportsData
}

export const getPaginatedReports = (reports: IMyReport[], page: number, size: number) => {
  const startIndex = (page - 1) * size
  const endIndex = startIndex + size
  return reports.slice(startIndex, endIndex)
}
