export const enum StartWeek {
  Sun,
  Mon,
}

export const enum CalendarRange {
  Left,
  Right,
}

export const enum CalendarDirection {
  Previous,
  Next,
}

export const enum CalendarChange {
  Month,
  Year,
}

export const enum CalendarType {
  Decades,
  Months,
}

export interface IMonth {
  week: {
    day: Date
  }[]
}

export interface SelectedDate {
  startDate: Date
  endDate: Date
}

export const enum SelectedDateRange {
  StartDate,
  EndDate,
  None,
}

export const weekday = [
  'datePicker.days.sun',
  'datePicker.days.mon',
  'datePicker.days.tue',
  'datePicker.days.wed',
  'datePicker.days.thu',
  'datePicker.days.fri',
  'datePicker.days.sat',
]
export const monthsName = [
  'datePicker.months.jan',
  'datePicker.months.feb',
  'datePicker.months.mar',
  'datePicker.months.apr',
  'datePicker.months.may',
  'datePicker.months.jun',
  'datePicker.months.jul',
  'datePicker.months.aug',
  'datePicker.months.sep',
  'datePicker.months.oct',
  'datePicker.months.nov',
  'datePicker.months.dec',
]
