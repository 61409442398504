import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Checkbox, Radio, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  ICurrency,
  IPackage,
  IPackageGroup,
  ISelectedPackages,
  LicenseType,
  SelectedPackageDuration,
  SelectedPackageValuation,
  SubscriptionAction,
} from 'features/subscriptionRequest/constants/subscription'
import { selectPackage } from 'features/subscriptionRequest/slice'
import { Duration } from 'features/subscriptionRequest/components/PackageGroups/components/Duration'
import { Valuations } from 'features/subscriptionRequest/components/PackageGroups/components/Valuations'
import { getFormatedPrice } from 'features/subscriptionRequest/utils/getFormatedPrice'
import colors from 'theme/colors'

interface Props {
  packageGroup: IPackageGroup
  subscriptionAction: SubscriptionAction
  isSingle?: boolean
  currency?: ICurrency
  selectedPackages?: ISelectedPackages
  onSelectDuration?: (subscriptionId: string, termId: string) => void
}

export const PackageGroupDetails: FC<Props> = ({
  packageGroup,
  isSingle,
  currency,
  selectedPackages,
  subscriptionAction,
  onSelectDuration,
}) => {
  const { terms, subscription } = useAppSelector((state) => state.subscriptionRequest)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [showedDescription, setShowedDescription] = useState<string[]>([])
  const isShowCheckbox =
    subscriptionAction === SubscriptionAction.EditSubscription || packageGroup.packages[0].packageGroup.isMultiple
  const isSinglePackage = isSingle && packageGroup.packages.length === 1

  const toggleDescription = (id: string) => {
    if (showedDescription.includes(id)) {
      setShowedDescription(showedDescription.filter((i) => i !== id))
    } else {
      setShowedDescription([...showedDescription, id])
    }
  }

  const onSelectPackage = (_package: IPackage) => {
    !isSinglePackage && dispatch(selectPackage(_package))
  }

  const isSelectedPackage = useCallback(
    (_package: IPackage) => {
      return (
        subscription.products
          .find((packageProduct) => packageProduct.id === _package.productId)
          ?.packages.some((packageItem: IPackage) => packageItem.id === _package.id) || false
      )
    },
    [subscription.products]
  )

  const priceTemplate = useCallback(
    (_package: IPackage) => {
      const price = _package.prices.find((_price) => _price.currency.id === currency!.id)!

      return (
        <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'baseline', fontSize: '14px' }}>
          <Typography sx={{ fontFamily: 'Open Sans Bold', color: 'primary.main', mr: '4px' }}>
            {price.currency.symbol}
          </Typography>
          <Typography sx={{ fontFamily: 'Open Sans Bold' }}>{getFormatedPrice(price.amount)}</Typography>
          <Typography fontSize='12px' color={colors.silverChalice}>
            /{t('subscriptionRequest.packages.year')}
          </Typography>
        </Box>
      )
    },
    [currency]
  )

  return (
    <>
      {packageGroup.packages.map((_package) => {
        const isSelectedDisabled = !!(selectedPackages && selectedPackages[_package.id])

        return (
          <Box
            key={_package.id}
            sx={{
              backgroundColor: isSelectedDisabled ? colors.menuItemHover : 'common.white',
              pointerEvents: isSelectedDisabled ? 'none' : 'auto',
              '&:not(:last-child)': {
                borderBottom: '1px solid',
                borderColor: 'grey.100',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
                padding: '8px 16px 8px 48px',
                height: '64px',
              }}
            >
              {isShowCheckbox ? (
                <Checkbox
                  size='medium'
                  sx={{
                    padding: 0,
                    ...(isSinglePackage && { cursor: 'default' }),
                    ...(isSelectedDisabled && { '&&': { color: colors.silverChalice } }),
                  }}
                  checked={isSinglePackage || isSelectedDisabled || isSelectedPackage(_package)}
                  onClick={() => onSelectPackage(_package)}
                />
              ) : (
                <Radio
                  size='medium'
                  sx={{
                    padding: 0,
                    ...(isSinglePackage && { cursor: 'default' }),
                  }}
                  checked={isSelectedPackage(_package)}
                  onClick={() => onSelectPackage(_package)}
                />
              )}

              <Box sx={{ fontSize: '14px' }}>
                <Box
                  sx={{
                    color: isSelectedDisabled ? colors.silverChalice : 'common.black',
                    cursor: isSinglePackage ? 'default' : 'pointer',
                  }}
                  onClick={() => onSelectPackage(_package)}
                >
                  {_package.name}
                </Box>
                <Typography
                  variant='caption'
                  sx={{
                    fontSize: '14px',
                    color: 'primary.main',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                  }}
                  onClick={() => toggleDescription(_package.id)}
                >
                  {t(
                    `subscriptionRequest.packages.details.${showedDescription.includes(_package.id) ? 'hide' : 'show'}`
                  )}
                </Typography>
              </Box>
              {currency ? (
                priceTemplate(_package)
              ) : (
                <Box sx={{ ml: 'auto' }}>
                  {_package.licenseType === LicenseType.SingleLicense ? (
                    <Duration
                      isDisabled={isSelectedDisabled}
                      subscriptionPackage={_package}
                      selectedTerms={terms}
                      terms={packageGroup.terms[_package.id]}
                      subscribedDuration={
                        selectedPackages && (selectedPackages[_package.id] as SelectedPackageDuration)
                      }
                      onSelectDuration={onSelectDuration}
                    />
                  ) : (
                    <Valuations
                      isDisabled={isSelectedDisabled}
                      subscriptionPackage={_package}
                      selectedValuation={
                        selectedPackages && (selectedPackages[_package.id] as SelectedPackageValuation)
                      }
                    />
                  )}
                </Box>
              )}
            </Box>
            {showedDescription.includes(_package.id) && (
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  padding: '0 64px 16px 64px',
                  color: 'grey.800',
                  ul: {
                    paddingLeft: '24px',
                    lineHeight: '24px',
                    'li::marker': {
                      fontSize: '10px',
                    },
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: _package.description,
                }}
              ></Box>
            )}
          </Box>
        )
      })}
    </>
  )
}
