import { SvgIcon, SvgIconProps } from '@mui/material'

export const CaretRightIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 16.501C10 16.9339 10.5127 17.1622 10.8345 16.8726L15.8345
          12.3726C16.0552 12.174 16.0552 11.828 15.8345 11.6293L10.8345
          7.12933C10.5127 6.83975 10 7.0681 10 7.50098V16.501Z'
          fill='black'
        />
      </SvgIcon>
    </>
  )
}
