import { FC, FocusEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Chip, MenuItem, Select, SelectChangeEvent, SxProps } from '@mui/material'
import { CustomFormLabel } from 'components/CustomFormLabel'
import { DropdownIcon } from 'components/icons/Dropdown.icon'
import colors from 'theme/colors'
import { DeleteChipIcon } from 'components/icons/DeleteChip.icon'

interface Props {
  sx?: SxProps
  placeholder?: string
  children: ReactElement | ReactElement[]
  value?: string[]
  name?: string
  required?: boolean
  disabled?: boolean
  error?: boolean
  label?: string
  outline?: boolean
  selectAll?: boolean
  isSelectedAll?: boolean
  onChange: (event: SelectChangeEvent<string[]>) => void
  onDelete?: (item: string) => void
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void
  onClose?: () => void
  renderValue?: (item: string) => string
  onOpen?: () => void
  open?: boolean
  icon?: ReactElement
}

export const CustomSelectChips: FC<Props> = ({
  sx,
  value,
  name,
  required,
  disabled,
  error,
  label,
  selectAll,
  isSelectedAll,
  placeholder,
  outline = true,
  onChange,
  onDelete,
  onBlur,
  onClose,
  renderValue,
  children,
  onOpen,
  open,
  icon,
}) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '4px',
          '&&& .MuiSelect-select': {
            paddingRight: '38px',
          },
          '&&& .MuiSelect-icon': {
            color: disabled ? colors.silverSand : 'common.black',
          },
          ...sx,
        }}
      >
        {icon ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: '8px',
            }}
          >
            {label && <CustomFormLabel value={label} required={required} error={error} sx={{ mb: 0 }} />}
            {icon}
          </Box>
        ) : (
          label && <CustomFormLabel value={label} required={required} error={error} />
        )}
        <Select
          data-testid='custom-select-chips'
          sx={{
            lineHeight: '22px',
            borderRadius: '2px',
            '&.Mui-disabled': {
              backgroundColor: colors.bgDisabled,

              '&&& .MuiSelect-icon': {
                color: disabled ? colors.silverChalice : 'common.black',
              },
            },

            '&&& fieldset': disabled
              ? {
                  borderWidth: '2px',
                  borderStyle: 'dashed',
                  borderColor: outline ? colors.quillGray : 'transparent',
                  ':hover': {
                    borderColor: 'inherit',
                  },
                }
              : {
                  borderColor: outline ? 'grey.100' : 'transparent',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  ':hover': {
                    borderColor: 'inherit',
                  },
                },
            '&&&.Mui-error fieldset': {
              borderColor: 'error.main',
            },
          }}
          name={name}
          required={required}
          error={error}
          displayEmpty
          multiple
          disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onClose={onClose}
          onOpen={onOpen}
          open={open}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: '2px',
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.12)',
              },
            },
            autoFocus: false,
            sx: {
              top: '5px',
            },
          }}
          renderValue={() => <Box sx={{ color: colors.silverChalice }}>{placeholder || ''}</Box>}
          IconComponent={DropdownIcon}
        >
          {selectAll && (
            <MenuItem className={isSelectedAll ? 'Mui-selected' : ''} key='all' value='all'>
              {t('reports.details.columns.selectAll')}
            </MenuItem>
          )}
          {children}
        </Select>
      </Box>
      {value?.map((item) => (
        <Chip
          sx={{
            margin: '0 8px 8px 0',
          }}
          label={renderValue ? renderValue(item) : item}
          key={item}
          onDelete={() => onDelete && onDelete(item)}
          deleteIcon={
            <DeleteChipIcon
              sx={{
                width: '20px',
                height: '20px',
                marginRight: '4px',
                color: colors.silverChalice,
                cursor: 'pointer',
                '&:hover': {
                  color: 'grey.800',
                },
              }}
            />
          }
        />
      ))}
    </Box>
  )
}
