import { FC } from 'react'
import { Box, Modal, SxProps } from '@mui/material'

import { ModalHeaderProps, ModalBodyProps, ModalFooterProps, CustomModalProps } from './interfaces'

export const ModalHeader: FC<ModalHeaderProps> = ({ children, style }) => {
  const modalHeaderStyle: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',
    alignItems: 'center',
    borderBottom: '1px solid #D0D0CE',
  }

  return <Box sx={style || modalHeaderStyle}>{children}</Box>
}

export const ModalBody: FC<ModalBodyProps> = ({ children, style }) => {
  return <Box sx={style || { p: '16px 16px 0px 16px' }}>{children}</Box>
}

export const ModalFooter: FC<ModalFooterProps> = ({ children, style }) => {
  const footerStyles: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',
  }

  return <Box sx={style || footerStyles}>{children}</Box>
}

const CustomModal: FC<CustomModalProps> = ({ children, open, style, width, onClose }) => {
  const customModalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: width || '640px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '600px',
    outline: 'none',
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style || customModalStyle}>{children}</Box>
    </Modal>
  )
}

export default Object.assign(CustomModal, {
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
})
