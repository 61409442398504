import { ChangeEvent, FC } from 'react'
import { FormControlLabel, Radio, RadioGroup, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CustomFormLabel } from 'components/CustomFormLabel'

export interface Option {
  value: string
  label: string
}

interface Props {
  options: Option[]
  value?: string
  label?: string
  readonly?: boolean
  infoTooltip?: string
  sx?: SxProps
  row?: boolean
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

export const CustomRadioGroup: FC<Props> = ({ sx, value, readonly, infoTooltip, label, options, row, onChange }) => {
  const { t } = useTranslation()

  return (
    <>
      {label && <CustomFormLabel value={label} infoTooltip={infoTooltip} />}
      {readonly ? (
        <Typography sx={{ wordBreak: 'break-word', fontWeight: 600 }} variant='body2'>
          {t(options.find((option) => option.value === value)!.label)}
        </Typography>
      ) : (
        <RadioGroup sx={sx} row={row} value={value} onChange={onChange}>
          {options.map((option: Option) => (
            <FormControlLabel
              sx={{ '& span': { fontSize: '14px', fontWeight: 400 } }}
              key={option.value}
              value={option.value}
              control={<Radio size='small' disableRipple />}
              label={t(option.label)}
            />
          ))}
        </RadioGroup>
      )}
    </>
  )
}
