import { Option } from 'components/CustomRadioGroup'

export interface Package {
  type: PackageType
  name: string
  description: string
  descriptionFrench: string
  durations?: PackageDuration[]
}

export const enum PackageDuration {
  OneYear = '1',
  TwoYear = '2',
  ThreeYear = '3',
}

export const packageDurations: PackageDuration[] = [
  PackageDuration.OneYear,
  PackageDuration.TwoYear,
  PackageDuration.ThreeYear,
]

export const enum PackageField {
  Name = 'name',
  Description = 'description',
  DescriptionFrench = 'descriptionFrench',
  Durations = 'durations',
  DurationChip = 'durationChip',
  PackageType = 'type',
}

export const enum PackageType {
  Duration = 'duration',
  Valuation = 'valuation',
}

export const PackageTypeOptions: Option[] = [
  {
    label: 'productConfiguration.package.type.term',
    value: PackageType.Duration,
  },
  {
    label: 'productConfiguration.package.type.valuations',
    value: PackageType.Valuation,
  },
]

export const initialPackageState: Package = {
  type: PackageType.Duration,
  name: '',
  description: '',
  descriptionFrench: '',
  durations: [],
}
export interface PackageFormErrors {
  name: { isValid: boolean }
  description: { isValid: boolean }
  descriptionFrench: { isValid: boolean }
}

export const formValidationErrors: PackageFormErrors = {
  name: { isValid: false },
  description: { isValid: false },
  descriptionFrench: { isValid: false },
}

export type PackageFormErrorsMap = {
  [K in keyof PackageFormErrors]: { isValid: boolean }
}
