import { FC, useMemo } from 'react'
import { Box } from '@mui/material'
import { Header } from './common/Header'
import { CalendarDirection, CalendarRange, CalendarType, monthsName } from '../constants'
import { Grid } from './common/Grid'
import { useTranslation } from 'react-i18next'

interface Props {
  range: CalendarRange
  date: Date
  dateThreshold?: Date,
  month: string
  onShowDecades: () => void
  onChangeYear: (calendar: CalendarRange, direction: CalendarDirection) => void
  onChangeMonth: (month: string, range: CalendarRange) => void
}

export const Months: FC<Props> = ({
  date,
  dateThreshold,
  range,
  month,
  onChangeMonth,
  onChangeYear,
  onShowDecades,
}) => {
  const { t } = useTranslation()

  const months = useMemo(() => {
    return Array.from({ length: Math.ceil(monthsName.length / 3) }, (_, index) =>
      monthsName.slice(index * 3, (index + 1) * 3)
    )
  }, [])

  const dateMemo = useMemo(() => date, [])

  return (
    <Box
      sx={{
        width: '252px',
        height: '310px',
      }}
    >
      <Header
        year={date.getFullYear()}
        onNext={() => onChangeYear(range, CalendarDirection.Next)}
        onPrevious={() => onChangeYear(range, CalendarDirection.Previous)}
        onShowDecades={onShowDecades}
      ></Header>
      <Grid<string>
        date={date}
        dateThreshold={dateThreshold}
        dateMemo={dateMemo}
        range={range}
        type={CalendarType.Months}
        value={month}
        data={months}
        onChange={onChangeMonth}
        translation={t}
      />
    </Box>
  )
}
