import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, MenuItem, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { IPackageGroup, SubscriptionAction } from 'features/subscriptionRequest/constants/subscription'
import { PackageFilters } from 'features/subscriptionRequest/components/PackageGroups/components/PackageFilters'
import { setCurrency } from 'features/subscriptionRequest/slice'
import { PackageGroupDetails } from 'features/subscriptionRequest/components/PackageGroups/components/PackageGroupDetails'
import { Licenses } from 'features/subscriptionRequest/components/PackageGroups/components/Licenses'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { CustomSelect } from 'components/CustomSelect'
import { DropdownIcon } from 'components/icons/Dropdown.icon'
import { InfoIcon } from 'components/icons/Info.icon'
import { CustomArrowTooltip } from 'components/CustomTooltip'
import colors from 'theme/colors'
import { accordionSummaryStyles } from 'constants/styles'

interface Props {
  packageGroups: { [key: string]: IPackageGroup }
  subscriptionAction: SubscriptionAction
}

export const PackageGroups: FC<Props> = ({ packageGroups, subscriptionAction }) => {
  const { type, commonTermId, currencies } = useAppSelector((state) => state.subscriptionRequest)
  const [expanded, setExpanded] = useState<{ [p: string]: boolean }>(
    Object.keys(packageGroups).reduce(
      (acc, k) => ({
        ...acc,
        [k]: !!(commonTermId && type && currencies[packageGroups[k].productId]),
      }),
      {}
    )
  )
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const toggleExpanding = (name: string, state: boolean) => {
    setExpanded({ ...expanded, [name]: state })
  }

  return (
    <>
      <PackageFilters
        onComplete={() => {
          setExpanded(
            Object.keys(expanded).reduce((acc, key) => {
              return { ...acc, [key]: !!currencies[packageGroups[key].productId] }
            }, {})
          )
        }}
      />

      <Box>
        {Object.keys(packageGroups)
          .sort((a, b) => a.localeCompare(b))
          .sort((a) => (/[a-z]/.test(a[0]) ? -1 : 1)) // Temporary sorting until implementing order
          .map((packageGroupName) => {
            const packageGroup = packageGroups[packageGroupName]
            const prices = [...packageGroup.packages[0].prices].sort((a, b) =>
              a.currency.name.localeCompare(b.currency.name)
            )
            const soldBy = packageGroup.packages[0].packageGroup.legalName

            return (
              <Accordion
                sx={{
                  border: '1px solid',
                  borderColor: 'grey.100',
                  overflow: 'hidden',
                  '&:last-child': {
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px',
                  },
                  '&:first-child': {
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                  },
                  '&:not(:last-child)': {
                    borderBottom: 0,
                  },
                  '&::before': {
                    display: 'none',
                  },
                }}
                expanded={expanded[packageGroupName]}
                onChange={(e, state) => toggleExpanding(packageGroupName, state)}
                disabled={!(commonTermId && type && currencies[packageGroup.productId])}
                key={packageGroupName}
                disableGutters
                elevation={0}
                square
              >
                <AccordionSummary
                  sx={accordionSummaryStyles}
                  expandIcon={<DropdownIcon />}
                  aria-controls='panel1d-content'
                  id='panel1d-header'
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      gap: '12px',
                    }}
                  >
                    <Typography
                      sx={{
                        ml: '12px',
                        fontSize: '14px',
                        fontWeight: '600',
                        '.Mui-disabled &': {
                          color: colors.silverChalice,
                        },
                      }}
                    >
                      {packageGroupName}
                    </Typography>
                    <Typography sx={{ fontSize: '12px', color: 'grey.800' }}>
                      {t('subscriptionRequest.packages.soldBy')} {soldBy}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                    <Box
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                      }}
                    >
                      <CustomArrowTooltip
                        placement='top'
                        title={
                          t(`subscriptionRequest.packages.currency.tooltip.${prices.length === 1 ? 'only' : 'sync'}`, {
                            soldBy,
                            currency: prices[0].currency.name,
                          })!
                        }
                      >
                        <Box sx={{ pointerEvents: 'all', width: '24px', height: '24px' }}>
                          <InfoIcon sx={{ fill: colors.blue.main }} />
                        </Box>
                      </CustomArrowTooltip>
                      <Typography
                        sx={{
                          m: '0 4px',
                          fontSize: '14px',
                          fontWeight: '600',
                          cursor: 'default',
                          color: type && commonTermId && prices.length !== 1 ? 'common.black' : 'grey.800',
                        }}
                      >
                        {t('subscriptionRequest.packages.currency.label')}:
                      </Typography>
                      <CustomSelect
                        sx={{
                          width: '86px',
                          pointerEvents: 'auto',
                          '&&& .Mui-disabled': {
                            color: 'grey.800',
                            WebkitTextFillColor: colors.mainGrey,
                          },
                        }}
                        outline={false}
                        disabled={!(type && commonTermId) || prices.length === 1}
                        value={currencies[packageGroup.productId]?.name}
                        onChange={(e) => {
                          const currency = prices.find((price) => e.target.value === price.currency.name)!.currency
                          dispatch(setCurrency({ [packageGroup.productId]: currency }))
                          setExpanded(
                            Object.keys(expanded).reduce((acc, key) => {
                              return {
                                ...acc,
                                [key]: packageGroups[key].productId === packageGroup.productId ? true : expanded[key],
                              }
                            }, {})
                          )
                        }}
                        placeholder={t('subscriptionRequest.subscriptionType.placeholder')!}
                        selectedTemplated={() => (
                          <Box
                            sx={{
                              ...(type &&
                                commonTermId && {
                                  color: 'common.black',
                                  WebkitTextFillColor: colors.black.main,
                                }),
                            }}
                          >
                            {currencies[packageGroup.productId].symbol} {currencies[packageGroup.productId].name}
                          </Box>
                        )}
                      >
                        {prices.map((price) => (
                          <MenuItem key={price.id} value={price.currency.name}>
                            {price.currency.symbol} {price.currency.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </Box>

                    <Licenses packageGroup={packageGroup} />
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    borderTop: '1px solid',
                    borderColor: 'grey.100',
                    padding: 0,
                  }}
                >
                  <PackageGroupDetails
                    subscriptionAction={subscriptionAction}
                    currency={currencies[packageGroup.productId]}
                    packageGroup={packageGroup}
                  />
                </AccordionDetails>
              </Accordion>
            )
          })}
      </Box>
    </>
  )
}
