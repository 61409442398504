import { SvgIcon, SvgIconProps } from '@mui/material'

export const CsvIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.5858 4C13.851 4 14.1054 4.10536 14.2929 4.29289L17.7071 7.70711C17.8946 7.89464 18
          8.149 18 8.41421V10H19.5C19.7761 10 20 10.2239 20 10.5V16.5C20 16.7761 19.7761 17 19.5
          17H18V19.5C18 19.78 17.78 20 17.5 20H6.5C6.22 20 6 19.78 6 19.5V17H5.5C5.22386 17 5
          16.7761 5 16.5V10.5C5 10.2239 5.22386 10 5.5 10H6V4.50002C6 4.22002 6.22 4 6.5
          4H13.5858ZM10 12C10 11.4477 9.55228 11 9 11H8C7.44772 11 7 11.4477 7 12V15C7 15.5523
          7.44772 16 8 16H9C9.55228 16 10 15.5523 10 15V14.5H9V15H8V12H9V12.5H10V12ZM7
          17V19H17V17H7ZM13 5H7V10H17V9H14C13.4872 9 13.0645 8.61396 13.0067 8.11662L13 8V5ZM14
          5.415V8H16.586L14 5.415ZM11 16V15H13V14H12C11.4477 14 11 13.5523 11 13V12C11 11.4477
          11.4477 11 12 11H14V12H12V13H13C13.5523 13 14 13.4477 14 14V15C14 15.5523 13.5523 16 13
          16H11ZM16.5 14.7719C16.1638 14.3912 16 14.118 16 14V11H15V14C15 14.5129 15.3885 15.0956
          16.1464 15.8536C16.3417 16.0488 16.6583 16.0488 16.8536 15.8536C17.6115 15.0956 18 14.5129
          18 14V11H17V14C17 14.1049 16.8706 14.3324 16.6057 14.649L16.5 14.7719Z'
          fill='#046A38'
        />
      </SvgIcon>
    </>
  )
}
