import { Box, Button, SxProps } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  resetBillingDetails,
  setPaymentDetailsActiveStep,
  updatePaymentDetailsSteps,
} from 'features/subscriptionRequest/slice'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StepStatus } from 'features/subscriptionRequest/constants/stepper'
import { PAYMENT_DETAILS_FIXED_STEPS } from 'features/subscriptionRequest/components/paymentDetails/constants'
import { zuoraService } from 'features/subscriptionRequest/services/zoura'

interface IBillingInfoFooterProps {
  handleCollapseAccordion: Dispatch<SetStateAction<number | null>>
  isClearBtnHidden?: boolean
  id?: string
  sx?: SxProps
  isButtonDisabled: () => boolean
}

const PaymentFooter = ({
  handleCollapseAccordion,
  isClearBtnHidden,
  sx,
  id,
  isButtonDisabled,
}: IBillingInfoFooterProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { paymentDetailsActiveStep, zuoraRefIds } = useAppSelector((state) => state.subscriptionRequest)

  const { paymentDetailsSteps } = useAppSelector((state) => state.subscriptionRequest)

  const handleContinue = () => {
    const payload = {
      stepIndex: paymentDetailsActiveStep,
      currentStep: StepStatus.Completed,
      newStep: StepStatus.Available,
    }
    dispatch(updatePaymentDetailsSteps(payload))

    if (paymentDetailsActiveStep < paymentDetailsSteps.length) {
      handleCollapseAccordion(paymentDetailsSteps[paymentDetailsActiveStep].stepNumber)
      dispatch(setPaymentDetailsActiveStep(paymentDetailsSteps[paymentDetailsActiveStep].stepNumber))
    }
  }

  const handleClick = () => {
    if (PAYMENT_DETAILS_FIXED_STEPS.includes(paymentDetailsActiveStep)) {
      handleContinue()
    } else {
      zuoraService.submit()
    }
  }

  const handleClickClear = () => {
    dispatch(resetBillingDetails())
  }

  useEffect(() => {
    if (id && !PAYMENT_DETAILS_FIXED_STEPS.includes(paymentDetailsActiveStep) && zuoraRefIds[id]) {
      handleContinue()
    }
  }, [zuoraRefIds])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      {!isClearBtnHidden && (
        <Button variant='text' onClick={handleClickClear} disabled={isButtonDisabled()}>
          {t('button.clear')}
        </Button>
      )}
      <Button variant='contained' onClick={handleClick} disabled={isButtonDisabled()}>
        {t('button.continue')}
      </Button>
    </Box>
  )
}

export default PaymentFooter
