import { SvgIcon, SvgIconProps } from '@mui/material'

export const DownloadIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.5 7H10V8H6V18H19V8H15V7H19.5C19.7455 7 19.9496 7.17688 19.9919 7.41012L20 7.5V18.5C20
          18.7455 19.8231 18.9496 19.5899 18.9919L19.5 19H5.5C5.25454 19 5.05039 18.8231 5.00806 18.5899L5
          18.5V7.5C5 7.25454 5.17688 7.05039 5.41012 7.00806L5.5 7ZM12.4101 2.00806L12.5 2C12.7455 2
          12.9496 2.17688 12.9919 2.41012L13 2.5V13.292L15.1464 11.1464C15.32 10.9729 15.5894 10.9536
          15.7843 11.0886L15.8536 11.1464C16.0271 11.32 16.0464 11.5894 15.9114 11.7843L15.8536
          11.8536L12.8536 14.8536C12.68 15.0271 12.4106 15.0464 12.2157 14.9114L12.1464 14.8536L9.14645
          11.8536C8.95118 11.6583 8.95118 11.3417 9.14645 11.1464C9.32001 10.9729 9.58944 10.9536 9.78431
          11.0886L9.85355 11.1464L12 13.292V2.5C12 2.25454 12.1769 2.05039 12.4101 2.00806Z'
          fill='#007CB0'
        />
      </SvgIcon>
    </>
  )
}
