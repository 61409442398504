import { FC } from 'react'
import { Box, Avatar, Tooltip } from '@mui/material'
import { WarningIcon } from 'components/icons/Warning.icon'
import { OverflowTooltip } from 'components/OverflowTooltip'
import { useTranslation } from 'react-i18next'

interface Props {
  firstName: string
  lastName: string
  fullPermissions?: boolean
}

export const UserName: FC<Props> = ({ firstName, lastName, fullPermissions }) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Avatar
        sx={{
          width: '24px',
          height: '24px',
          fontSize: '10px',
          backgroundColor: 'common.black',
          textTransform: 'uppercase',
          mr: '8px',
        }}
      >
        {firstName.charAt(0) + lastName.charAt(0)}
      </Avatar>
      <Box sx={{ fontSize: '14px', flex: 1, overflow: 'hidden', whiteSpace: 'nowrap' }}>
        <OverflowTooltip text={firstName + ' ' + lastName} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignSelf: 'end',
          boxSizing: 'border-box',
          alignItems: 'center',
        }}
      >
        {fullPermissions === false && (
          <Tooltip
            title={t('manageAdmins.tooltip.partialPermitions')}
            placement='top-end'
            arrow
            disableInteractive
            componentsProps={{
              tooltip: {
                sx: { marginBottom: '8px!important' },
              },
            }}
          >
            <Box
              sx={{ m: 0, p: 0, boxSizing: 'border-box', display: 'inherit', cursor: 'pointer' }}
            >
              <WarningIcon sx={{ color: '#FFCD00' }} aria-label='warning icon' />
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}
