import { useEffect } from 'react'
import { MsalProvider } from '@azure/msal-react'
import { Outlet, useNavigate } from 'react-router'
import { EventType } from '@azure/msal-browser'

import { pca } from 'services/auth/authConfig'

import { CustomNavigation } from 'utils/customNavigation'
import { getUser, getEndUser } from 'sharedSlices/auth'
import { useAppDispatch } from 'app/hooks'

export const AuthContainer = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const customNavigation = new CustomNavigation(navigate)
  pca.setNavigationClient(customNavigation)

  useEffect(() => {
    const callbackIdClearBrowserStorage = pca.addEventCallback((event) => {
      if (
        [
          EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE,
          EventType.ACQUIRE_TOKEN_FAILURE,
          EventType.LOGIN_FAILURE,
          EventType.SSO_SILENT_FAILURE,
        ].includes(event.eventType)
      ) {
        pca['browserStorage'].clear()
      }
    })

    const callBackIdGetUser = pca.addEventCallback((event) => {
      if ([EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS].includes(event.eventType)) {
        dispatch(getUser())
        dispatch(getEndUser())

        callBackIdGetUser && pca.removeEventCallback(callBackIdGetUser)
      }
    })

    return () => {
      callbackIdClearBrowserStorage && pca.removeEventCallback(callbackIdClearBrowserStorage)
      callBackIdGetUser && pca.removeEventCallback(callBackIdGetUser)
    }
  }, [])

  return (
    <MsalProvider instance={pca}>
      <Outlet />
    </MsalProvider>
  )
}
