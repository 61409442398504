import { SvgIcon, SvgIconProps } from '@mui/material'

export const DetailsIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7929 4C13.9255 4 14.0527 4.05268 14.1464 4.14645L17.8536 7.85355C17.9473 7.94732
          18 8.0745 18 8.20711V19.5C18 19.7761 17.7761 20 17.5 20H6.5C6.22386 20 6 19.7761 6
          19.5V4.5C6 4.22386 6.22386 4 6.5 4H13.7929ZM13 4.999L7 5V19H17V9H14C13.4872 9 13.0645
          8.61396 13.0067 8.11662L13 8V4.999ZM15.5 16C15.7761 16 16 16.2239 16 16.5C16 16.7761
          15.7761 17 15.5 17H8.5C8.22386 17 8 16.7761 8 16.5C8 16.2239 8.22386 16 8.5 16H15.5ZM15.5
          14C15.7761 14 16 14.2239 16 14.5C16 14.7761 15.7761 15 15.5 15H8.5C8.22386 15 8 14.7761 8
          14.5C8 14.2239 8.22386 14 8.5 14H15.5ZM15.5 12C15.7761 12 16 12.2239 16 12.5C16 12.7761
          15.7761 13 15.5 13H8.5C8.22386 13 8 12.7761 8 12.5C8 12.2239 8.22386 12 8.5 12H15.5ZM14
          5.414V8H16.586L14 5.414Z"
        />
      </SvgIcon>
    </>
  )
}
