import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { getUsers } from '../slices/manageUsers'
import { handleAddUserModal } from 'sharedSlices/ui'
import {
  RegisteredUser,
  NO_ACTIVE_SUBSCRIPTION,
  ACTIVE_SUBSCRIPTION,
  ACTIVE_SUBSCRIPTION_IN_JUR,
  UserSubscriptionStatus,
} from '../slices/manageUsers/constants'
import { notDeloitteEmailRegEx } from 'features/subscriptionRequest/constants/email'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { addUser, getUserByEmail } from '../services'

import { showMessage } from 'utils/errorMessageHandler'

import { IAddUserFormData, UserRequest } from '../constants'
import { Severity, SeverityType } from 'constants/severity'

interface UserSubscriptionInfo {
  alertType?: Severity
  canUserBeAdded: boolean
  canEdit: boolean
  message?: string
}

const validationSchema = Yup.object().shape({
  id: Yup.string().required(),
  email: Yup.string().required(),
  lastName: Yup.string().required(),
  firstName: Yup.string().required(),
  isSameOrganization: Yup.boolean().required().isTrue(),
  role: Yup.number().required(),
})

const userSubscriptionInfoInititalState: UserSubscriptionInfo = {
  canUserBeAdded: true,
  canEdit: true,
}

const { warning, info, error } = SeverityType

const REGULAR_USER = 1
const initialValues = {
  id: '',
  email: '',
  lastName: '',
  firstName: '',
  isSameOrganization: false,
  role: REGULAR_USER,
}

const isValid = (email: string) => notDeloitteEmailRegEx.test(email)

export const useAddUser = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { subscriptionId } = useAppSelector((state) => state.manageUsers)
  const [searchParams] = useSearchParams()
  const jurCode = searchParams.get('jurCode')!
  const [userFound, setUserFound] = useState<RegisteredUser | undefined>(undefined)
  const [{ alertType, canUserBeAdded, canEdit, message }, setUserInfo] =
    useState<UserSubscriptionInfo>(userSubscriptionInfoInititalState)

  const responseMap: Record<UserSubscriptionStatus, string> = {
    [NO_ACTIVE_SUBSCRIPTION]: t('manageProduct.users.alreadyRegisteredUser'),
    [ACTIVE_SUBSCRIPTION]: t('manageProduct.users.alreadyAddedUser'),
    [ACTIVE_SUBSCRIPTION_IN_JUR]: t('manageProduct.users.userHasSubscriptionInAnotherJur'),
  }

  const handleClose = () => {
    dispatch(handleAddUserModal(false))
  }

  const showAlert = ({ userSubscriptionStatus }: RegisteredUser) => {
    const hasActiveSubscription = userSubscriptionStatus !== NO_ACTIVE_SUBSCRIPTION
    setUserInfo({
      canEdit: hasActiveSubscription,
      canUserBeAdded: !hasActiveSubscription,
      alertType: hasActiveSubscription ? warning : info,
      message: responseMap[userSubscriptionStatus],
    })
  }

  const addUserToSubscription = async ({ id, role, firstName, lastName, email }: UserRequest) => {
    if (subscriptionId) {
      await addUser({ id, role, firstName, lastName, email }, subscriptionId, jurCode)
      handleClose()
      dispatch(getUsers({ jurCode, subscriptionId }))
      showMessage.success('mySnackbar', 'manageAdmins.message.success')
    }
  }

  const submitFormData = async ({ id, role, firstName, lastName, email }: IAddUserFormData) => {
    if (!isValid(email)) {
      setUserInfo((state) => ({
        ...state,
        alertType: error,
        canUserBeAdded: false,
        message: t('manageProduct.users.invalidEmail'),
      }))
      return
    }
    try {
      const user = await getUserByEmail(email, jurCode!)
      if (user) {
        if (user.userSubscriptionStatus !== NO_ACTIVE_SUBSCRIPTION) {
          showAlert(user)
          return
        } else {
          if (userFound) {
            addUserToSubscription({ id, role, firstName, lastName, email })
          } else {
            showAlert(user)
          }
          setUserFound(user)
        }
      } else {
        addUserToSubscription({ id, role, firstName, lastName, email })
      }
    } catch (err) {
      showMessage.error('mySnackbar', 'manageAdmins.message.error')
    }
  }

  const formikProps = useFormik<IAddUserFormData>({
    initialValues,
    validationSchema,
    onSubmit: submitFormData,
  })

  const { setFieldValue, values } = formikProps

  const setValue = useCallback(
    (fieldName: string, param: string | string[] | number | boolean) =>
      setFieldValue(fieldName, param),
    [setFieldValue]
  )

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.currentTarget.name, e.currentTarget.value)
  }

  const handleCheckChange = () => {
    setValue('isSameOrganization', !formikProps.values.isSameOrganization)
  }

  useEffect(() => {
    setUserFound(undefined)
    setUserInfo((state) => ({
      ...state,
      canEdit: true,
      canUserBeAdded: true,
      alertType: undefined,
      message: undefined,
    }))
  }, [values.email])

  useEffect(() => {
    if (userFound) {
      setValue('firstName', userFound.firstName)
      setValue('lastName', userFound.lastName)
      if (userFound.userSubscriptionStatus === NO_ACTIVE_SUBSCRIPTION)
        setValue('isSameOrganization', false)
    }
  }, [userFound, setValue])

  useEffect(() => {
    if (subscriptionId) {
      setValue('id', subscriptionId)
    }
  }, [subscriptionId, setValue])

  return {
    alertType,
    canEdit,
    canUserBeAdded,
    formikProps,
    message,
    handleCheckChange,
    handleInputChange,
    handleClose,
    setValue,
  }
}
