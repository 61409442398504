import { SvgIcon, SvgIconProps } from '@mui/material'

export const DatePickerIcon = (props: SvgIconProps) => {
  return (
    <>
      <SvgIcon
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <g transform='translate(4, 4)'>
          <path
            d='M12.5 0C12.7761 0 13 0.223858 13 0.5L12.999 2H15.5C15.7761 2 16 2.22386 16
            2.5V15.5C16 15.7761 15.7761 16 15.5 16H0.5C0.223858 16 0 15.7761 0 15.5V2.5C0
            2.22386 0.223858 2 0.5 2H3V0.5C3 0.223858 3.22386 0 3.5 0C3.77614 0 4 0.223858
            4 0.5V2H11.999L12 0.5C12 0.223858 12.2239 0 12.5 0ZM3 2.999L1 3V15H15V3L12.999
            2.999L13 4.5C13 4.77614 12.7761 5 12.5 5C12.2239 5 12 4.77614 12 4.5L11.999
            2.999H4V4.5C4 4.77614 3.77614 5 3.5 5C3.22386 5 3 4.77614 3 4.5V2.999ZM4 11C4.55228
            11 5 11.4477 5 12C5 12.5523 4.55228 13 4 13C3.44772 13 3 12.5523 3 12C3 11.4477
            3.44772 11 4 11ZM8 11C8.55229 11 9 11.4477 9 12C9 12.5523 8.55229 13 8 13C7.44772
            13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11ZM12 11C12.5523 11 13 11.4477 13 12C13
            12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11ZM4
            7C4.55228 7 5 7.44772 5 8C5 8.55229 4.55228 9 4 9C3.44772 9 3 8.55229 3 8C3 7.44772
            3.44772 7 4 7ZM8 7C8.55229 7 9 7.44772 9 8C9 8.55229 8.55229 9 8 9C7.44772 9 7
            8.55229 7 8C7 7.44772 7.44772 7 8 7ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55229
            12.5523 9 12 9C11.4477 9 11 8.55229 11 8C11 7.44772 11.4477 7 12 7Z'
          />
        </g>
      </SvgIcon>
    </>
  )
}
