import { SvgIcon, SvgIconProps } from '@mui/material'

export const ArrowRight = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.646447 13.1464C0.451184 13.3417 0.451184 13.6583 0.646447 13.8536C0.841709 14.0488 1.15829 14.0488 1.35355
        13.8536L7.85355 7.35355C8.04882 7.15829 8.04882 6.84171 7.85355 6.64645L1.35355 0.146447C1.15829 -0.0488155 0.841709
        -0.0488155 0.646447 0.146447C0.451184 0.341709 0.451184 0.658292 0.646447 0.853554L6.79289 7L0.646447 13.1464Z'
      />
    </SvgIcon>
  )
}
