import { ElementType, FC, FunctionComponent, ReactNode, SVGProps, useCallback, useEffect, useState } from 'react'
import { Box, MenuItem, SelectChangeEvent } from '@mui/material'
import { CustomSelect } from 'components/CustomSelect'
import { useTranslation } from 'react-i18next'

interface IRowsPerPageProps {
  totalItems: number
  value: number
  onChange: (value: number) => void
  showText?: boolean
  iconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>
}

export const RowsPerPage = ({ totalItems, value, onChange, showText = true, iconComponent }: IRowsPerPageProps) => {
  const { t } = useTranslation()
  const [maxRowsPerPage, setMaxRowsPerPage] = useState<number[]>([10])

  const getMaxItemsToShow = useCallback((_totalItems: number) => {
    return _totalItems > 50 ? 4 : _totalItems >= 21 ? 3 : _totalItems > 10 && _totalItems <= 20 ? 2 : 1
  }, [])

  useEffect(() => {
    const filteredArray = [10, 20, 50, 100].slice(0, getMaxItemsToShow(totalItems))
    setMaxRowsPerPage(filteredArray)
  }, [getMaxItemsToShow, totalItems])

  const onChangeSelect = useCallback(
    (row: SelectChangeEvent) => {
      const size = Number(row.target.value)
      onChange(size)
    },
    [onChange]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Open Sans',
        fontSize: '14px',
      }}
    >
      {showText && t('sizePagination.show')}
      <CustomSelect
        sx={{
          margin: '0 15px',
        }}
        value={value.toString()}
        onChange={onChangeSelect}
        iconComponent={iconComponent}
      >
        {maxRowsPerPage.map((row) => (
          <MenuItem key={row} value={row.toString()}>
            {row}
          </MenuItem>
        ))}
      </CustomSelect>
      {showText && t('sizePagination.size')}
    </Box>
  )
}
